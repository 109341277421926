import request from '@/utils/request'

/**
 * 查询所有活动类型及执行
 */
export function getQueryactivity() {
  return request({
    method: 'post',
    url: 'activityType/getActivityTypes',
    params: {
      pageindex: 1,
      pagenum: 200
    }
  })
}

/**
 * 查询活动申请所有专家/讲者信息
 */
export function getActivetySpeaker(pageindex, pagenum, ls) {
  return request({
    method: 'post',
    url: `speaker/speakerListRef/${pageindex}/${pagenum}`,
    data: ls,
    customConfig: {
      withTimestamp: false,
      withSign: false
    }
  })
}

/**
 * 查询所有的推广物料
 */
export function getActivityBrandPre(pageindex, pagenum, ls) {
  return request({
    method: 'post',
    url: `brandPre/${pageindex}/${pagenum}`,
    data: ls
  })
}

/**
 * 根据活动申请id查询推广物料
 */
export function getActivityBrandPres(id) {
  return request({
    method: 'get',
    url: `ActivityBrandPre/${id}`
  })
}

/**
 * 保存活动申请数据
 * @param {Object} dot
 */
export function getActivityApp(dot) {
  return request({
    method: 'post',
    url: 'activityApp',
    data: dot
  })
}

/**
 * 根据id查询学术活动申请单
 */
export function getActivityAppById(pid) {
  return request({
    method: 'get',
    url: `activityApp/${pid}`
  })
}

/**
 * 根据总结单id查询活动申请单
 * @param {String} reimId
 */
export function getActivityAppByReimId(reimId) {
  return request({
    method: 'get',
    url: `activityApp/getActivityAppByReimId`,
    params: { reimId }
  })
}

/**
 * 查询所有枚举部门
 */
export function getQueryDepts() {
  return request({
    method: 'get',
    url: 'dept/querydepts'
  })
}

/**
 * 查询用户的学术活动申请单
 */
export function getAllActivityApp(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    data: conditions,
    // {
    //   applicant: uid,
    //   conferenceCode: conferenceCode,
    //   conferenceName: conferenceName
    // },
    url: `activityApp/${pageindex}/${pagenum}`
  })
}

/**
 * 更新活动申请信息
 * @param {Object} dot
 */
export function getEditActivityApp(dot) {
  return request({
    method: 'put',
    url: `activityApp/${dot.id}`,
    data: dot
  })
}

/**
 * 删除活动申请的表单
 * @param {String} pid 活动申请的表单id
 */
export function getDeleteActivityApp(pid) {
  return request({
    method: 'delete',
    url: `activityApp/${pid}`
  })
}

/**
 * 申请人用户部门
 * @param {String} uid
 * @returns
 */
export function getDeptByUid(uid) {
  return request({
    method: 'get',
    url: `activityApp/getDeptByUid`,
    params: { uid }
  })
}

/**
 * 提交OA审批
 * @param {Object} dot
 */
export function getSubmitXSSQ(dot) {
  return request({
    method: 'put',
    url: `oaflow/submitXSSQ`,
    data: dot
  })
}

/**
 * 获取oa审批流单据信息
 * @param {Sring} pk 审批单据主键
 */
export function getFlowInfoByPk(pk) {
  return request({
    method: 'get',
    url: `oaflow/getFlowInfoByPk`,
    params: {
      pk
    }
  })
}

/**
 * 获取oa流程图片
 * @param {String} id
 * @param {Array} nextapproveman
 */
export function getOAProcimg(id, nextapproveman) {
  return request({
    method: 'get',
    url: `oaflow/waitwork/procimg?id=${id}&nextapproveman=${nextapproveman}`,
    responseType: 'arraybuffer'
  }).then(response => {
    try {
      const enc = new TextDecoder('utf-8')
      const res = JSON.parse(enc.decode(new Uint8Array(response.data)))
      return res.data
    } catch {
      const imageData = btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )
      const contentType = response.headers['content-type'].toLowerCase()
      const src = `data:${contentType};base64,${imageData}`
      return src
    }
  })
}

/**
 * OA流程取消接口
 * @param {String} summmaryId
 */
export function getOACancel(summmaryId) {
  return request({
    method: 'PUT',
    url: `oaflow/cancel/${summmaryId}`
  })
}

/**
 * OA流程取消接口
 * @param {String} summmaryId
 */
export function getOACancel2(id, billType, summmaryId) {
  return request({
    method: 'PUT',
    url: `oaflow/cancel2/${summmaryId}`,
    params: {
      id,
      billType
    }
  })
}

/**
 * 变更撤回流程接口
 * @param {String} summaryId
 */
export function getForModifyCancel(summaryId) {
  return request({
    method: 'PUT',
    url: `oaflowForModifyApp/cancel/${summaryId}`
  })
}

/**
 *  审批流程信息
 */
export function getHandleInfo(billId) {
  return request({
    method: 'get',
    url: `oaflow/handleInfoByBillId`,
    params: {
      billId
    }
  })
}

/**
 * 保存审批信息回复接口
 * @param {Object} data
 * @returns
 */
export function saveApproveInfoReply(data) {
  return request({
    method: 'post',
    url: `oaflow/saveApproveInfoReply`,
    data
  })
}

// 付款单以外的单，关联单据审批意见的附件信息
export function saveApproveInfoReplyForOtherBill(data) {
  return request({
    method: 'post',
    url: `oaflow/saveApproveInfoReplyForOtherBill`,
    data
  })
}

/**
 * 审批处理
 * @param {Object} dot
 */
export function getHandle(dot) {
  return request({
    method: 'post',
    url: 'oaflow/handle',
    params: dot.isSkip ? { isSkip: true } : null,
    data: dot
  })
}

/**
 * 暂存代办
 * @param {Object} dot
 */
export function getTemporaryStorage(dot) {
  return request({
    method: 'post',
    url: 'oaflow/temporaryStorage',
    data: dot
  })
}

/**
 * 所属公司和业务类型对应关系列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getComBusiRelationList(pageindex, pagenum) {
  return request({
    method: 'post',
    url: `comBusiRelation/${pageindex}/${pagenum}`
  })
}

/**
 * 所属公司和业务类型对应关系列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getAccompanyPersonList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `app/accompanyPerson/refAccPerson/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 活动申请单作废
 * @param {String} id
 * @param {String} text
 * @returns
 */
export function invalidateActivityApplication(id, text) {
  return request({
    method: 'post',
    url: `activityApp/invalidate`,
    data: { id, invalidateReason: text }
  })
}
