<template>
  <div class="fee-info-table">
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="费用信息" key="1" class="customStyle">
          <div style="margin-left: 24px">
            <table cellspacing="0">
              <tr class="h-12" style="background-color: #f9f9f9">
                <th class="w-2/5">项目</th>
                <th class="w-1/5">
                  单价
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <th class="w-1/5">
                  数量
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <th class="w-1/5">
                  总价
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
              </tr>
              <tr v-if="conferenceTypeName !== '地区'" class="trBgClass">
                <td class="w-2/5 tr-header">展位(场地费)</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetBooth"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetBooth }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetBoothNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetBoothNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetBoothTotal }}
                </td>
              </tr>
              <tr>
                <td class="w-2/5 tr-header">会议室(场地费)</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetMeetingroom"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetMeetingroom }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetMeetingroomNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetMeetingroomNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetMeetingroomTotal }}
                </td>
              </tr>
              <tr class="trBgClass">
                <td class="w-2/5 tr-header">制作费</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetMake"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetMake }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetMakeNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetMakeNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetMakeTotal }}
                </td>
              </tr>
              <tr>
                <td class="w-2/5 tr-header">设计费</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetDesign"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetDesign }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetDesignNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetDesignNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetDesignTotal }}
                </td>
              </tr>
              <tr class="trBgClass">
                <td class="w-2/5 tr-header">飞机费</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetFlight"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetFlight }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetFlightNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetFlightNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetFlightTotal }}
                </td>
              </tr>
              <tr>
                <td class="w-2/5 tr-header">高铁费</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetTrain"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetTrain }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetTrainNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetTrainNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetTrainTotal }}
                </td>
              </tr>
              <tr class="trBgClass">
                <td class="w-2/5 tr-header">接送费用</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetReception"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetReception }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetReceptionNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetReceptionNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetReceptionTotal }}
                </td>
              </tr>
              <tr>
                <td class="w-2/5 tr-header">住宿费</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetAccommodation"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetAccommodation }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetAccommodationNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetAccommodationNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetAccommodationTotal }}
                </td>
              </tr>
              <tr class="trBgClass">
                <td class="w-2/5 tr-header">餐费</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetMeals"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetMeals }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetMealsNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetMealsNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetMealsTotal }}
                </td>
              </tr>

              <tr>
                <td class="w-2/5 tr-header">第三方会务人员费用</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetThirdparty"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetThirdparty }}
                  </span>
                </td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetThirdpartyNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetThirdpartyNum }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer">
                  {{ budgetThirdpartyTotal }}
                </td>
              </tr>

              <tr class="trBgClass">
                <td class="last-td w-2/5 tr-header">会务服务费、税费</td>
                <td class="last-td w-1/5 value-null">--</td>
                <td class="last-td w-1/5 value-null">--</td>
                <td class="last-td w-1/5">
                  <div
                    style="padding: 0 4px"
                    v-if="!isRead && getIsBudgetServiceTotal"
                  >
                    <a-input
                      v-model="feeInfo.budgetServiceTotal"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetServiceTotal }}
                  </span>
                </td>
              </tr>
              <tr v-if="conferenceTypeName !== '地区'">
                <td class="w-2/5 tr-header">协办费用</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5" v-if="natrue && natrue !== '自办'">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.budgetCooperationTotal"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.budgetCooperationTotal }}
                  </span>
                </td>
                <td class="w-1/5 tr-footer" v-else>
                  {{ feeInfo.budgetCooperationTotal }}
                </td>
              </tr>

              <tr class="trBgClass">
                <td class="w-2/5 tr-header">讲者咨询费</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 tr-footer">{{ consultTotal }}</td>
              </tr>
              <tr>
                <td class="w-2/5 tr-header">推广物料费</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 tr-footer">
                  {{ feeInfo.budgetBrandpreTotal }}
                </td>
              </tr>
              <tr>
                <td class="w-2/5 tr-header">其他费用</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.otherFee"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.otherFee }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="w-2/5 tr-header">注册费</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.registerFee"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.registerFee }}
                  </span>
                </td>
              </tr>
              <tr v-if="[3, 4].includes(getHostingType)">
                <td class="w-2/5 tr-header">信息发布费</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 tr-footer">
                  {{ infoPubAmount }}
                </td>
              </tr>
              <tr v-if="[1, 2, 4].includes(getHostingType)">
                <td class="w-2/5 tr-header">线上直播平台费用</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5 value-null">--</td>
                <td class="w-1/5">
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeInfo.onlineLiveBroadcastingFee"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeInfo.onlineLiveBroadcastingFee }}
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div class="mt-4 float-right text-sm font-bold">
            <div class="text-left" style="width: 200px">
              预付金额：
              <span class="text-red-600">{{ Number(preCost) }}</span>
            </div>
            <div class="text-left" style="width: 200px">
              合计金额：
              <span class="text-red-600">{{ feeInfo.budgetTotal }}</span>
            </div>
            <div class="text-left" style="width: 200px">
              批准总预算：
              <span class="text-red-600">{{ feeInfo.budgetApprovetotal }}</span>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import feeInfoMinxin from './mixins/feeInfoMixin'
export default {
  name: 'FeeInfo', //费用信息
  mixins: [feeInfoMinxin],
  props: {
    feeInfo: {
      type: Object,
      required: true
    },
    conferenceTypeName: [String, Number],
    budgetPreCost: [String, Number], //预付金额
    budgetConsultTotal: [String, Number], //讲者金额
    brandpreMoneytotal: [String, Number], //推广物料金额
    activityNatrue: String, //协办（为自办是协办费用为0，且不可编辑）
    hasconfContract: String, //会务合同
    status: Boolean,
    hostingTypeName: String, //举办类型
    infoPubAmount: [String, Number] // 品牌信息-信息发布费用
  },
  data() {
    return {
      consultTotal: 0, // 讲者费用
      preCost: 0, //预付金额
      natrue: '自办'
    }
  },
  computed: {
    getHostingType() {
      let active = false
      let purtive = false
      if (this.hostingTypeName.indexOf('学术推广活动') !== -1) active = true
      if (this.hostingTypeName.indexOf('品牌信息发布') !== -1) purtive = true

      if (active && !purtive) return 2 //举办类型为学术推广
      if (!active && purtive) return 3 //举办类型为品牌信息
      if (active && purtive) return 4 //举办类型为学术推广+品牌信息

      return 1 //举办类型为空
    },

    getIsBudgetServiceTotal() {
      if (this.activityNatrue === '自办' && this.hasconfContract === '否') {
        return false
      }

      return true
    }
  },

  methods: {
    submit() {
      this.calculate()
      this.$emit('update:feeInfo', this.feeInfo)
    },
    updateItem() {
      this.$emit('updateItem')
      this.submit()
    }
  }
}
</script>

<style lang="less" scoped>
.fee-info-table table {
  width: 100%;
}
.fee-info-table table th {
  font-size: 14px;

  font-weight: 600;
  color: #999999;
  padding: 16px;
}
.fee-info-table table tr {
  padding: 12px;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
}
.fee-info-table table tr td {
  font-size: 14px;

  font-weight: 500;
  color: #333333;
}

.tr-header {
  padding: 9px 16px;
}

.value-null {
  padding: 9px 24px !important;
}

.tr-footer {
  padding: 5px 16px;
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
