import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**************** 医生变更申请接口**********************/
/**
 * 获取医生变更申请的列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 */
export function getDoctorModifyList(pageindex, pagenum, condition = []) {
  return request({
    url: `/doctorModify/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 保存医生变更申请信息
 * @param {Object} data
 */
export function getSaveDoctorModify(data) {
  return request({
    url: `/doctorModify`,
    method: 'post',
    data: data
  })
}

/**
 * 更新医生变更申请信息
 * @param {String} id
 * @param {Object} data
 */
export function getUpdateDoctorModify(id, data) {
  return request({
    url: `/doctorModify/${id}`,
    method: 'put',
    data: data
  })
}

/**
 * 根据id查询医生变更申请
 * @param {String} id
 */
export function getDoctorModifyById(id) {
  return request({
    url: `/doctorModify/${id}`,
    method: 'get'
  })
}

/**
 * 删除医生变更申请信息
 * @param {String} id
 */
export function getDelDoctorModify(id) {
  return request({
    url: `/doctorModify/${id}`,
    method: 'delete'
  })
}

/**
 * 获取用户负责的医生
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 */
export function getDoctorListCharge(pageindex, pagenum, condition) {
  return request({
    url: `/doctorModify/doctorListInCharge/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 根据岗位来查询相应负责的额医生接口
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Boolean} isShared
 * @param {Array} condition
 */
export function getDoctorInChargeByPostId(
  pageindex,
  pagenum,
  isShared,
  condition
) {
  return request({
    url: `doctorModify/getDoctorInChargeByPostId/${pageindex}/${pagenum}`,
    method: 'post',
    params: { isShared },
    data: condition
  })
}

/**
 * 根据岗位id获取医生变更申请列表
 * @param {*} pageindex
 * @param {*} pagenum
 * @param {Boolean} isShared
 * @param {Array} condition
 */
export function getDoctorModifyListByPostIds(
  pageindex,
  pagenum,
  isShared,
  condition
) {
  return request({
    url: `/doctorModify/getDoctorModifyListByPostIds/${pageindex}/${pagenum}`,
    method: 'post',
    params: { isShared },
    data: condition
  })
}

/**
 *查询医生列表的接口，可以加search参数
 * @param {*} pageindex
 * @param {*} pagenum
 * @param {Array} condition
 */
export function getDoctorForModify(pageindex, pagenum, condition) {
  return request({
    url: `/doctorModify/getDoctorsForModify/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 提交医生变更申请
 * @param {Object} data
 */
export function getSubmitDoctorModity(data) {
  return request({
    url: `oaflow/submitDoctorModity`,
    method: 'put',
    data: data
  })
}

/**
 * 获取医生信息
 * @param {String} doctorId
 */
export function getDoctor(doctorId) {
  return request({
    url: `/doctor/${doctorId}`,
    method: 'get'
  })
}

/**
 * 医生变更校验，检查对应的医院和科室下是否有对应的医生
 * @param {String} postId
 * @param {String} hospitalId
 * @param {String} doctorName
 * @param {String} sectionId
 * @returns
 */
export function getCheckDoctor(postId, hospitalId, doctorName, sectionId) {
  return request({
    url: `/doctorModify/check`,
    method: 'post',
    data: {
      postId,
      hospitalId,
      doctorName,
      sectionId
    },
    customConfig: {
      withTimestamp: false,
      withSign: false
    }
  })
}

/**
 * 查询当前用户负责的区域医生信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 * @returns
 */
export function getDoctorsInCharge(pageindex, pagenum, condition) {
  return request({
    url: `/doctorModify/getDoctorsInCharge/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 终端批处理删除
 * @param {Array} data
 * @returns
 */
export function delBatchTransfer(data) {
  return request({
    url: `/hospitalModify/batch/batchTransferDel`,
    method: 'post',
    data: data
  })
}

/**
 * 导出医生变更信息
 * @param {Array} data 导出的条件
 */
export function getExportDoctorModify(data = []) {
  return request({
    url: `/doctorModify/exportExcel`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `医生变更申请信息`)
  })
}

/**************** 医院变更申请接口**********************/
/**
 * 获取医院变更申请的列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 */
export function getHospModifyList(pageindex, pagenum, condition = []) {
  return request({
    url: `/hospitalModify/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 保存医院变更申请信息
 * @param {Object} data
 */
export function getSaveHospModify(data) {
  return request({
    url: `/hospitalModify`,
    method: 'post',
    data: data
  })
}

/**
 * 更新医院变更申请信息
 * @param {String} id
 * @param {Object} data
 */
export function getUpdateHospModify(id, data) {
  return request({
    url: `/hospitalModify/${id}`,
    method: 'put',
    data: data
  })
}

/**
 * 根据id查询医院变更申请
 * @param {String} id
 */
export function getHospModifyById(id) {
  return request({
    url: `/hospitalModify/${id}`,
    method: 'get'
  })
}

/**
 * 删除医院变更申请信息
 * @param {String} id
 */
export function getDelHospModify(id) {
  return request({
    url: `/hospitalModify/${id}`,
    method: 'delete'
  })
}

/**
 * 获取用户负责的医院
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 */
export function getHospListCharge(pageindex, pagenum, condition) {
  return request({
    url: `/hospitalModify/getHospitalInCharge/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 获取用户的历史医院
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 */
export function getHospHistoryList(pageindex, pagenum, condition) {
  return request({
    url: `/hospitalModify/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 根据岗位来查询相应负责的额医院接口
 * @param {*} pageindex
 * @param {*} pagenum
 * @param {Array} condition (岗位查询放查询条件里了)
 */
export function getHospInChargeByPostId(
  pageindex,
  pagenum,
  isShared,
  condition
) {
  return request({
    url: `/hospitalModify/getHospitalInChargeByPostId/${pageindex}/${pagenum}`,
    method: 'post',
    params: {
      isShared
    },
    data: condition
  })
}

/**
 * 根据查询所有的医院
 * @param {*} pageindex
 * @param {*} pagenum
 * @param {Array} condition
 */
export function getHospitalListForModify(pageindex, pagenum, condition) {
  return request({
    url: `/hospitalModify/getHospitalListForModify/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 获取下属数据的列表
 * @param {String} postId
 */
export function getSubPosts(postId) {
  return request({
    url: `/post/getSubPosts/${postId}`,
    method: 'get'
  })
}

/**
 * 获取下属数据
 * @param {String} postId
 * @param {Object} params
 */
export function getSubPostsByPost(postId, params) {
  return request({
    url: `/post/getSubPostsByPostIdAndBasePostId`,
    method: 'post',
    data: { postId, ...params }
  })
}

/**
 * 查询所有医院变更申请信息，申请历史
 * @param {*} pageindex
 * @param {*} pagenum
 * @param {Array} condition
 */
export function getHospitalModifyListByPostIds(
  pageindex,
  pagenum,
  isShared,
  condition
) {
  return request({
    url: `/hospitalModify/getHospitalModifyListByPostIds/${pageindex}/${pagenum}`,
    method: 'post',
    params: {
      isShared
    },
    data: condition
  })
}

/**
 * 在医院变更里参照产品时调用的接口,分页查询产品列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Integer} condition
 */
export function getProductListForHosipModify(pageindex, pagenum, condition) {
  return request({
    url: `/hospitalModify/getProductListForHosipModify/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 提交医院变更申请信息(走流程)
 * @param {Object} data
 */
export function getSubmitHospitalModity(data) {
  return request({
    url: `oaflow/submitHospitalModity`,
    method: 'put',
    data: data
  })
}

/**
 * 获取产品列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getProductList(pageindex, pagenum, condition = []) {
  return request({
    url: `product/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 获取医院信息
 * @param {String} id
 */
export function getHospital(id) {
  return request({
    url: `hospital/${id}`,
    method: 'get'
  })
}

/**
 * 根据医院和岗位查询关联的区域医生
 * @param {Object} addition
 */
export function getAreaDoctByHospital(addition) {
  return request({
    url: `hospitalModify/getRelatedDoctorByHospId`,
    method: 'post',
    data: addition,
    auth: { withTimestamp: false, withSign: false }
  })
}

/**
 * 导出医院变更信息
 * @param {Array} data 导出的条件
 */
export function getExportHospModify(data = []) {
  return request({
    url: `/hospitalModify/exportExcel`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `医院变更申请信息`)
  })
}

/**
 * 查询审批后处理失败单据
 * @param {Integer} pageIndex
 * @param {Integer} pageNum
 * @returns
 */
export function getNeedTransferBills(pageIndex, pageNum) {
  return request({
    url: `hospitalModify/batch/getNeedTransferBills`,
    method: 'get',
    params: { pageIndex, pageNum }
  })
}

/**
 * 批量执行变更申请后处理
 * @param {String} usercode
 * @param {Array} list
 * @returns
 */
export function getBatchTransfer(usercode, list) {
  return request({
    url: `hospitalModify/batch/batchTransfer`,
    method: 'post',
    params: { usercode },
    data: list
  })
}

/**************** 讲者变更申请接口**********************/
/**
 * 获取讲者变更申请的列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 */
export function getSpeakerModifyList(pageindex, pagenum, condition = []) {
  return request({
    url: `/speakerModify/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 保存讲者变更申请信息
 * @param {Object} data
 */
export function getSaveSpeakerModify(data) {
  return request({
    url: `/speakerModify`,
    method: 'post',
    data: data
  })
}

/**
 * 更新讲者变更申请信息
 * @param {String} id
 * @param {Object} data
 */
export function getUpdateSpeakerModify(id, data) {
  return request({
    url: `/speakerModify/${id}`,
    method: 'put',
    data: data
  })
}

/**
 * 根据id查询讲者变更申请
 * @param {String} id
 */
export function getSpeakerModifyById(id) {
  return request({
    url: `/speakerModify/${id}`,
    method: 'get'
  })
}

/**
 * 删除讲者变更申请信息
 * @param {String} id
 */
export function getDelSpeakerModify(id) {
  return request({
    url: `/speakerModify/${id}`,
    method: 'delete'
  })
}

/**
 * 提交讲者变更申请信息
 * @param {Object} data
 */
export function getSubmitSpeakerModify(data) {
  return request({
    url: `/oaflow/submitSpeakerModify`,
    method: 'put',
    data: data
  })
}

/**
 * 查询讲者和医生
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Object} condition
 */
export function getSpeakerAndDoctorList(pageindex, pagenum, condition) {
  return request({
    url: `/speakerModify/getSpeakerAndDoctorList/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition,
    auth: { withSign: false, withTimestamp: false }
  })
}

/**
 * 查询非讲者的医生
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Object} condition
 * @returns
 */
export function getDoctorForSpeakerList(pageindex, pagenum, condition) {
  return request({
    url: `/speakerModify/getDoctorForSpeakerList/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition,
    auth: { withSign: false, withTimestamp: false }
  })
}

/**
 * 查询所有讲者变更申请信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 */
export function getSpeakerModifyListByPostIds(pageindex, pagenum, condition) {
  return request({
    url: `/speakerModify/getSpeakerModifyListByPostIds/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 根据id查询讲者信息
 * @param {String} id
 */
export function getSpeaker(id) {
  return request({
    url: `/speaker/${id}`,
    method: 'get'
  })
}

//获取附件列表
export function getSpeakerFile(speakerid, params = {}) {
  return request({
    url: `/nfile/getDatasByPID/${speakerid}`,
    method: 'get',
    params: {
      ...params
    }
  })
}

/**
 * 计算讲者级别
 * @param {Object} data
 */
export function getCalcSpeakerLevel(data) {
  return request({
    url: `/speakerModify/calcSpeakerLevel`,
    method: 'post',
    data: data
  })
}

/**
 * 导出讲者变更信息
 * @param {Array} data 导出的条件
 */
export function getExportSpeakerModify(data = []) {
  return request({
    url: `/speakerModify/exportExcel`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `讲者变更申请信息`)
  })
}

/**************** 拜访补签接口**********************/
/**
 * 变更申请模块中查看拜访补签所有单据
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 * @returns
 */
export function getAllVisitList(pageindex, pagenum, condition = []) {
  return request({
    url: `visit/pc/all/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 查询补签单详情
 * @param {String} id
 * @returns
 */
export function getVisitById(id) {
  return request({
    url: `visit/visitapp/detail/${id}`,
    method: 'get'
  })
}

/**************** 次月目标医生接口**********************/
/**
 * 变更申请模块中查看维护目标医生所有单据
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 * @returns
 */
export function getAllTargetDoctorList(pageindex, pagenum, condition = []) {
  return request({
    url: `targetDoctor/pc/all/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 查询审批详情
 * @param {String} id
 * @returns
 */
export function getTargetDoctorById(id) {
  return request({
    url: `targetDoctor/app/history/${id}`,
    method: 'get'
  })
}

/**************** 次月目标医院接口**********************/
/**
 * 查询目标医院审批信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 * @returns
 */
export function getAllTargetHospList(pageindex, pagenum, condition = []) {
  return request({
    url: `targetHospApp/queryAll/${pageindex}/${pagenum}`,
    method: 'post',
    data: condition
  })
}

/**
 * 根据ID来查询目标医院申请
 * @param {String} id
 * @returns
 */
export function getTargetHospById(id) {
  return request({
    url: `targetHospApprove/${id}`,
    method: 'get'
  })
}
