<template>
  <div>
    <a-modal
      v-model="visible"
      title="查询收款人"
      width="800px"
      class="rece-man clearfix"
      :mask="false"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      @cancel="handleClose"
    >
      <div class="rece-man-content w-full">
        <div class="w-full my-3 flex content-between">
          <div class="button-group" style="width: 150px">
            <button class="button mr-2" @click="prev" :disabled="!prePage">
              上一页
            </button>
            <button class="button" @click="next" :disabled="!nextPage">
              下一页
            </button>
          </div>
          <div class="tooltip" style="width: 390px">
            <p class="mx-2 text-center text-red-500">
              若无收款人信息，请在门户-个人信息中维护报销卡
            </p>
          </div>
          <div class="search" style="width: 256px">
            <a-input-search
              placeholder="收款人"
              @search="onSearch"
              enterButton="搜索"
              class="w-64"
            />
          </div>
        </div>
        <a-table
          size="small"
          rowKey="id"
          :dataSource="datas"
          :columns="columns"
          :pagination="false"
          :loading="loading"
          :locale="locale"
          :rowClassName="
            (record, index) => {
              return index % 2 === 0 ? 'trBgClass' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  dblclick: () => {
                    handleChoose(record)
                  }
                }
              }
            }
          "
        >
          <template slot="operation" slot-scope="text, record">
            <button class="button choose-btn" @click="handleChoose(record)">
              选择
            </button>
          </template>
          <template slot="detail" slot-scope="text, record">
            <a-icon
              type="eye"
              class="cursor-pointer hover:text-blue-500"
              @click="detailChoose(record)"
            />
          </template>
        </a-table>
      </div>
    </a-modal>
    <receman-detial-preview
      v-if="showDetial"
      :detialMsg="detialMsg"
      @close="showDetial = false"
    ></receman-detial-preview>
  </div>
</template>

<script>
import { getPrivateBankAcc } from '@/api/acdemicActive/acdemic-payment'
import RecemanDetialPreview from './ReceManDetialPreview'

const columns = [
  {
    title: '明细',
    key: 'detail',
    align: 'center',
    scopedSlots: { customRender: 'detail' }
  },
  {
    title: '收款人',
    align: 'center',
    dataIndex: 'accountName'
  },
  {
    title: '省区',
    align: 'center',
    dataIndex: 'province'
  },
  {
    title: '城市',
    dataIndex: 'city',
    align: 'center'
  },
  {
    title: '操作',
    key: 'operation',
    align: 'center',
    scopedSlots: { customRender: 'operation' }
  }
]
export default {
  name: 'ReceManPreview',
  components: {
    RecemanDetialPreview
  },
  data() {
    return {
      visible: true,
      loading: false,
      columns,
      current: 1, //当前页
      pageSize: 20, //每页显示的数据
      nextPage: true, //是否有上一页
      prePage: false, //是否有下一页
      datas: [],
      showDetial: false, //收款单据明细默认关闭
      detialMsg: '', //传入明细窗口的收款单位信息
      map: null, //模糊查询的条件
      locale: {
        emptyText: '暂无收款人信息，请先在门户-个人信息添加报销卡信息'
      }, //设置默认的文案信息
      collection: {
        receName: '',
        receBankName: '',
        receCommbineNum: '',
        receAccNum: '',
        receCode: ''
      }
    }
  },
  created() {
    this.getPrivateBankAcc(this.current, this.pageSize, this.map)
  },
  methods: {
    // 关闭收款窗
    handleClose() {
      this.$emit('close')
    },

    //选择的收款人信息
    handleChoose(value) {
      this.collection.receName = value.accountName
      this.collection.receBankName = value.receBankName
      this.collection.receCommbineNum = value.receCommbineNum
      this.collection.receAccNum = value.receAccNum
      this.collection.receCode = value.userCode
      this.$emit('receManInfo', this.collection)
      this.handleClose()
    },

    // 收款单位明细
    detailChoose(value) {
      this.detialMsg = value
      this.showDetial = true
    },

    // 搜索
    onSearch(value) {
      if (value === '') {
        this.map = null
      } else {
        this.map = {
          accountName: value
        }
      }
      this.current = 1
      this.getPrivateBankAcc(this.current, this.pageSize, this.map)
    },

    // 上一页
    prev() {
      this.getPrivateBankAcc(this.current - 1, this.pageSize, this.map)
    },

    // 下一页
    next() {
      this.getPrivateBankAcc(this.current + 1, this.pageSize, this.map)
    },

    // 获取收款单位的信息
    getPrivateBankAcc(current, pageSize, map) {
      this.loading = true
      getPrivateBankAcc(current, pageSize, map).then(response => {
        this.datas = response.datas
        this.current = response.currentPage
        this.prePage = response.prePage
        this.nextPage = response.nextPage
        for (let i = 0; i < this.datas.length; i++) {
          this.datas[i].id = i
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less">
.rece-man {
  top: 2vh !important ;
}
.rece-man .ant-modal-header {
  padding: 8px 8px;
  background-color: #4587f4;
}
.rece-man .ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.rece-man .ant-modal-close-x {
  width: 39px;
  height: 39px;
  line-height: 39px;
  color: #ffffff;
}
.rece-man .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.rece-man .ant-modal-body {
  padding: 0px;
  height: 60vh;
}
.rece-man .ant-btn-primary,
.rece-man .ant-btn-primary:hover {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
}
.rece-man-content {
  padding: 0 8px;
  height: 58vh;
  overflow: auto;
}

.rece-man-content .button {
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 0px 0px #f0f7fa;
  -moz-box-shadow: 0px 1px 0px 0px #f0f7fa;
  box-shadow: 0px 1px 0px 0px #f0f7fa;
  color: #606266;
  font-size: 8px;
  padding: 4px 8px 4px 8px;
  border: solid #a0aec0 1px;
  text-decoration: none;
  &.choose-btn {
    padding: 4px 15px 4px 15px;
  }
  &:focus {
    outline: 0;
  }
}

.rece-man-content .button:hover {
  border: solid #606266 1px;
  text-decoration: none;
}
.tooltip {
  line-height: 32px;
}
</style>
