import { render, staticRenderFns } from "./DeptmentModal.vue?vue&type=template&id=225e01df&scoped=true"
import script from "./DeptmentModal.vue?vue&type=script&lang=js"
export * from "./DeptmentModal.vue?vue&type=script&lang=js"
import style0 from "./DeptmentModal.vue?vue&type=style&index=0&id=225e01df&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225e01df",
  null
  
)

export default component.exports