import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 分页查询门店管理列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getStoreList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `storeManage/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 根据ID来查询门店
 * @param {String} id
 */
export function getStoreById(id) {
  return request({
    method: 'get',
    url: `storeManage/${id}`
  })
}

/**
 * 更新门店信息
 * @param {String} id
 * @param {Object} data
 */
export function updateStoreInfo(id, data) {
  return request({
    method: 'put',
    url: `storeManage/${id}`,
    data
  })
}

/**
 * 删除门店信息
 * @param {String} id
 */
export function delStore(id) {
  return request({
    method: 'delete',
    url: `storeManage/${id}`
  })
}

/**
 * 获取门店申请详情
 * @param {String} id
 */
export function getAppStoreById(id) {
  return request({
    method: 'get',
    url: `storeApp/${id}`
  })
}

/**
 * 导出门店信息数据
 * @param {Array} conditions
 */
export function getExportExcel(conditions) {
  return request({
    method: 'post',
    url: `storeManage/exportExcel`,
    data: conditions,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `干鲜草门店档案信息`)
  })
}
