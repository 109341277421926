<template>
  <div class="m-4">
    <div class="title" v-if="showAvatar">药连锁自有房产登记申请单详情</div>
    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      align="middle"
      class="text-right"
    >
      <a-button
        type="primary"
        :disabled="isFlow"
        :loading="dataLoading"
        @click="save"
      >
        保存
      </a-button>
      <a-button
        type="primary"
        class="ml-2"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="submit"
      >
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        :loading="dataLoading"
        @click="del"
      >
        删除
      </a-button>
      <a-button
        class="ml-2"
        :loading="dataLoading"
        :disabled="backDisabled"
        @click="getBack"
      >
        撤销
      </a-button>
      <a-divider type="vertical"></a-divider>
      <a-button
        :loading="dataLoading"
        @click="$router.push({ name: 'houseRegister' })"
      >
        返回
      </a-button>
    </a-row>

    <base-form>
      <base-form-title title="申请人信息" icon="user" />
      <base-form-items
        :formData="form"
        :formBaseList="formUserList"
        @select-change="onUserChange"
      ></base-form-items>

      <base-form-title title="自有住房基本信息" icon="home" />
      <base-form-items
        :formData="form"
        :formBaseList="formBaseList"
        :formFileList="formBaseFileList"
        @open-modal="openModal"
        @open-file="openFile"
      ></base-form-items>

      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDO"
        :status="isFlow"
        :summaryid="form.flowInfoDO.summaryid"
        :nextapproveman="form.flowInfoDO.nextapproveman"
        :curApprover="form.flowInfoDO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.applicant" :billId="form.id"></comment>
    </base-form>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>

    <DeptmentModal
      v-if="showModal"
      :visible="showModal"
      @choose-dept="chooseDept"
      @close="showModal = false"
    ></DeptmentModal>
  </div>
</template>

<script>
import BaseForm from '@/components/FieldSet/BaseForm'
import BaseFormTitle from '@/components/FieldSet/BaseFormTitle'
import BaseFormItems from '@/components/FieldSet/BaseFormItems'
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import DeptmentModal from '@/components/Modal/DeptmentModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
// api
import { default as cloneDeep } from 'lodash/cloneDeep'
import { isDisabled } from '@/utils/util'
import { getDoc } from '@/configs/doc-config'
import {
  saveRegisterHouse,
  updateRegisterHouse,
  delRegisterHouse,
  getRegisterHouse,
  submitRegisterHouse
} from '@/api/house/register-house'
import { getDeleteFiles } from '@/api/acdemicActive/upload'
import {
  getOACancel2,
  getDeptByUid
} from '@/api/acdemicActive/acdemic-application'

// mixin
import userMixin from '../mixins/user-mixin'
import verityMixin from '../mixins/verity-mixin'

export default {
  name: 'RegisterHouseForm',
  components: {
    BaseForm,
    BaseFormTitle,
    BaseFormItems,
    UploadModal,
    PreviewModal,
    DeptmentModal,
    Process,
    Comment
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  mixins: [userMixin, verityMixin],
  data() {
    return {
      /* eslint-disable prettier/prettier */
      curType: this.type || this.$route.query.type || 'add',
      curPid: this.pid || this.$route.query.pid,
      showModal: false,

      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: '',

      dataLoading: false, // 提交数据

      form: {
        appdate: '', // 申请日期
        applicant: '', // 申请人id
        applicantName: '', // 申请人名称
        post: '', //岗位
        contact: '', // 联系方式
        region: '', // 大区id
        regionName: '', // 大区名称
        province: '', // 省办id
        provinceName: '', // 省办名称
        locality: '', // 地办id
        localityName: '', // 地办名称
        applicantDepartment: '', //部门id

        applicationCode: '', // 申请单号
        useType: '', // 使用类型
        address: '', // 房产地址
        floorArea: '', // 面积（m²）
        houseDeptId: '', // 房产所属部门
        houseDeptName: '', // 房产所属部门名称
        houseOwner: '', // 房屋产权所有人
        operator: '', // 承租经办人
        houseStartDate: '', // 房产持有日期

        buyHouseContract: '', // 购房合同
        propertyCard: '', // 产权证
       
        flowInfoDO: { status: '1' } // 流程信息
      },

      formBaseList: [
        { label: '申请单号', prop: 'applicationCode', disabled: true },
        { label: '房产地址', prop: 'address', disabled: () => this.getCommonDisabled },
        { label: '房屋产权所有人', prop: 'houseOwner', disabled: () => this.getCommonDisabled, },
        { label: '经办人', prop: 'operator', disabled: () => this.getCommonDisabled, },
        { label: '房产持有日期', prop: 'houseStartDate', type: 'date', disabled: () => this.getCommonDisabled, disabledDate: () => () => false },
        { label: '使用类型', prop: 'useType', disabled: true, type: 'select', options: [] },
        { label: '面积（m²）', prop: 'floorArea', disabled: () => this.getCommonDisabled },
        { label: '房产所属部门', prop: 'houseDeptName', type: 'search', disabled: () => this.getCommonDisabled }
      ],

      formBaseFileList: [
        { label: '购房合同', notVerity: true, prop: 'buyHouseContract', disabled: false },
        { label: '产权证', notVerity: true, prop: 'propertyCard', disabled: false },
      ]
    }
  },

  computed: {
    showAvatar() {
      return this.$store.getters['protal']
    },

    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getCommonDisabled() {
      return this.isFlow || false
    },
  },

  created() {
    getDoc('061', res => {
      this.formBaseList[5].options = res
      if (this.curType === 'add') {
        this.form.useType = res.find(i => i.text === '办公').value
      }
    })
  
    if(this.curType !== 'add') {
      getRegisterHouse(this.curPid).then(res => {
        // 申请人部门列表
        getDeptByUid(res.applicant).then(depts => {
          this.formUserList[7].options = this.changeDeptProps(depts)
        })

        this.form = res

        // 单据状态(向上通知)
        const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
        this.$emit('status', status)
      })
    }
  },

  methods: {
    chooseDept(item) {
      this.form.houseDeptId = item.did 
      this.form.houseDeptName = item.deptname 
    },

    openModal(item) {
      switch (item.prop) {
        case 'houseDeptName':
          this.showModal = true
          break
        default:
          break
      }
    },

    // 附件上传或预览
    openFile(item) {
      const title = item.label
      const fileProp = item.prop

      if (this.isFlow) {
        this.previewTitle = title
        this.previewFileListInfo = this.form[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = this.form[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '购房合同':
          this.form.buyHouseContract = fileListInfo
          break
        case '产权证':
          this.form.propertyCard = fileListInfo
          break
        default:
          break
      }
    },

    filterData() {
      return cloneDeep(this.form)
    },

    //保存
    save(callback) {
      this.saveForm(callback)
    },

    saveForm() {
      const saveApi = this.form.id ? updateRegisterHouse : saveRegisterHouse

      const submitData = this.filterData()

      if (this.verityAppForm('save')) {
        this.dataLoading = true
        saveApi(submitData)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.$router.replace({ name: 'houseRegister' })
          }).finally(() => {
            this.dataLoading = false
          })
      } 
    },

    //提交
    submit() {
      const submitData = this.filterData()

      if (this.verityAppForm(true)) {
        this.dataLoading = true
        submitRegisterHouse(submitData).then(() => {
          this.delUploadFile()
          this.$message.success('提交成功')
          this.$router.replace({ name: 'houseRegister' })
        }).finally(() => {
            this.dataLoading = false
          })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该药连锁自有房产登记申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delRegisterHouse(this.form.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'houseRegister' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该药连锁自有房产登记申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'ZFDJ',
            this.form.flowInfoDO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDO.status = '5'
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    } 
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
}
</style>
