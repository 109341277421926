import debounce from 'lodash/debounce'

/* eslint-disable prettier/prettier */
export default {
  data() {
    return {
      choosePersonType: 'add',
      accompany: {
        accompanyExpense: 0, // 陪同合计
        appAccompanyingPersonsDtos: []
      },

      accompanyId: '',
      accompanyColumns: [
        { title: '序号', dataIndex: 'number', width: 72, scopedSlots: { customRender: 'number' } },
        { title: '陪同人员工号', dataIndex: 'personCode', scopedSlots: { customRender: 'code' } },
        { title: '人员姓名', dataIndex: 'personName' },
        { title: '所属大区', dataIndex: 'areaName' },
        { title: '所属省区', dataIndex: 'provName' },
        { title: '所属岗位', dataIndex: 'postName' },
        { dataIndex: 'accompanyBudget', slots: { title: 'budgetTitle' }, scopedSlots: { customRender: 'budget' } }
      ]
    }
  },

  methods: {
    calcBudget: debounce(function() {
      this.accompany.accompanyExpense = this.accompany.appAccompanyingPersonsDtos.reduce((prev, cur) => {
        return this.$np.plus(Number(cur.accompanyBudget || '0'), prev)
      }, 0)
    } ,800),

    // 选择陪同人员
    showPersonVisible(type, index) {
      if(type === 'update'){ 
        this.chooseSpeakerInx = index
      }

      this.choosePersonType = type
      this.$refs.accompanymodel.speakerIds = ''
      this.$refs.accompanymodel.searchText = ''
      this.$refs.accompanymodel.selectedRowKeys = [] //选择的行id
      this.$refs.accompanymodel.onSearch('')
      this.$refs.accompanymodel.visible = true
    },
    chooseAccPerson(person) {
      if(this.choosePersonType === 'update'){
        this.accompany.appAccompanyingPersonsDtos.splice(this.chooseSpeakerInx, 1, person)
      }
      
      else this.accompany.appAccompanyingPersonsDtos.push(person)
    },
    delLastAccPerson() {
      if(this.accompany.appAccompanyingPersonsDtos.length){
        if(this.accompanyId) {
          this.accompany.appAccompanyingPersonsDtos = this.accompany.appAccompanyingPersonsDtos.filter(item => item.personId !== this.accompanyId)
        }else {
          this.accompany.appAccompanyingPersonsDtos.pop()
        }
        
        this.calcBudget()
      }
    },
  }
}
