// 举办性质枚举类型
export const activityNatrueEnum = [
  { text: '自办', value: '自办' },
  { text: '协办', value: '协办' },
  { text: '协办+自办', value: '协办+自办' }
]

// 是否需要会务公司
export const hasconfContractEnum = [
  { text: '是', value: '是' },
  { text: '否', value: '否' }
]

// 是否为走进东阳光项目
export const whetherDygProjEnum = [
  { text: '是', value: '是' },
  { text: '否', value: '否' }
]

// 是否为总部统一筹划项目
export const whetherHeadQuartersProjEnum = [
  { text: '是', value: '是' },
  { text: '否', value: '否' }
]
// 判断显示是否走进东阳光项目的字段
export const perferWhetherDygProj = [
  '城市会',
  '跨城市学术会议',
  '专家研讨会',
  '全国专家咨询会',
  '客户交流会'
]
