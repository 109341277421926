<template>
  <div>
    <a-popover
      v-model="visible"
      overlayClassName="popover propver-anuce"
      placement="topLeft"
      trigger="click"
    >
      <div slot="content" style="width: 360px">
        <a-tabs :activeKey="aKey" :animated="false" @change="onTabChange">
          <a-tab-pane key="1" :tab="`消息(${getResultListLength})`">
            <div class="tab-content flex flex-col justify-between">
              <div class="overflow-y-auto" style="flex: 1 0">
                <Result ref="result"></Result>
                <!-- <a-empty class="mt-12" v-if="!getResultListLength">
                  <span slot="description" class="text-gray-500">暂无消息</span>
                </a-empty> -->
              </div>
              <div class="clear-notice-text p-2 flex">
                <span
                  class="footer-label border-r-2 text-gray-500"
                  @click="isRead"
                >
                  清空消息
                </span>
                <span class="footer-label text-gray-500" @click="moreNotice">
                  查看更多
                </span>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2" :tab="`公告(${getUnReadAunce})`">
            <div class="tab-content flex flex-col justify-between">
              <div class="overflow-y-auto" style="flex: 1 0">
                <anuce ref="anuce"></anuce>
                <!-- <a-empty class="mt-12" v-if="!getResultListLength">
                  <span slot="description" class="text-gray-500">暂无消息</span>
                </a-empty> -->
              </div>
              <!-- <div class="clear-notice-text p-2 flex">
                <span class="footer-label text-gray-500" @click="moreAnuce">
                  查看更多
                </span>
              </div> -->
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>

      <div class="px-6 cursor-pointer hover:bg-gray-100" @click="menuClick">
        <a-badge :count="noticeNum" dot>
          <a-icon type="bell" :style="{ fontSize: '18px' }" />
        </a-badge>
      </div>
    </a-popover>
  </div>
</template>

<script>
import Result from './notice/Result'
import Anuce from './notice/Anuce'
import { getAllIsRead } from '@/api/message'
export default {
  name: 'Notice',
  components: { Result, Anuce },
  data() {
    return {
      visible: false,
      noticeNum: 0, //消息数
      aKey: '1'
    }
  },

  computed: {
    getResultListLength() {
      return this.$store.getters.resultList.length
    },

    getUnReadAunce() {
      return this.$store.getters.unReadAnuce
    },

    getUnReadResult() {
      return this.$store.getters.unReadResult
    },

    getNoticeNum() {
      return this.getUnReadAunce + this.getUnReadResult
    }
  },

  created() {
    this.noticeNum = this.getNoticeNum
  },

  watch: {
    visible(nval) {
      if (this.$refs.anuce && this.$refs.anuce.visible && !nval) {
        this.visible = true
      }
    },

    getNoticeNum(nval, oval) {
      if (nval && oval > nval) return
      this.noticeNum = nval
    }
  },

  methods: {
    onTabChange(key) {
      this.aKey = key
    },

    /**清空消息（设为已读） */
    isRead() {
      getAllIsRead().then(() => {
        this.$store.commit('notice/CLEAR_RESULT_LIST')
      })
    },

    menuClick() {
      if (this.noticeNum) this.noticeNum = 0
    },

    moreNotice() {
      this.visible = false
      this.$router.push({ name: 'morenotice' })
    },

    refreshAunce() {
      if (this.$refs.anuce) this.$refs.anuce.refresh()
    }
  }
}
</script>

<style lang="less" scoped>
@height: 360px;
@primary: #4587f5;

.tab-content {
  height: @height;
}

.clear-notice-text {
  border-top: 1px solid #f0f0f0;
}

.footer-label {
  text-align: center;
  flex: 1;
  cursor: pointer;
  &:hover {
    color: @primary;
  }
}

/deep/.ant-tabs-bar {
  margin-bottom: 8px;
}
</style>
