<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="高尔夫情况" key="1" class="customStyle">
          <!-- 预算 -->
          <Table
            :columns="columns1"
            :data="golfCase.appGolfInfos"
            :dataTemplate="golfItme"
            :isDisabled="isAppDisabled"
          >
            <span slot="inputCell" slot-scope="{ record, column }">
              <InputCell
                :record="record"
                :column="column"
                :isDisabled="isAppDisabled"
              ></InputCell>
            </span>

            <span slot="datePickerCell" slot-scope="{ record, column }">
              <DatePickerCell
                :record="record"
                :column="column"
                :isDisabled="isAppDisabled"
              ></DatePickerCell>
            </span>

            <span slot="selectCell" slot-scope="{ record, column }">
              <SelectCell
                :record="record"
                :column="column"
                :isDisabled="isAppDisabled"
              ></SelectCell>
            </span>

            <span>
              <span class="font-bold">预算高尔夫金额:</span>
              <span class="text-red-500">
                ￥{{ golfCase.golfBudgetTotal || 0 }}
              </span>
            </span>
          </Table>

          <!-- 实际 -->
          <Table
            v-if="showAct"
            :columns="columns2"
            :data="golfCase.actGolfInfos"
            :dataTemplate="golfItme"
            :isDisabled="isActDisabled"
            @deleteItem="calcActGolfTotal('act')"
          >
            <span slot="inputCell" slot-scope="{ record, column }">
              <InputCell
                :record="record"
                :column="column"
                :isDisabled="isActDisabled"
              ></InputCell>
            </span>

            <span slot="datePickerCell" slot-scope="{ record, column }">
              <DatePickerCell
                :record="record"
                :column="column"
                :isDisabled="isActDisabled"
              ></DatePickerCell>
            </span>

            <span slot="selectCell" slot-scope="{ record, column }">
              <SelectCell
                :record="record"
                :column="column"
                :isDisabled="isActDisabled"
              ></SelectCell>
            </span>

            <span>
              <span class="font-bold">实际高尔夫金额：</span>
              <span class="text-red-500">
                ￥{{ golfCase.actGolfTotal || 0 }}
              </span>
            </span>
          </Table>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import Table from './case/Table'
import InputCell from './case/InputCell'
import DatePickerCell from './case/DatePickerCell'
import SelectCell from './case/SelectCell'

const golfItme = {
  dateTime: '',
  golfTime: '',
  golfType: '',
  id: '',
  pid: '',
  pepleNum: '',
  fee: ''
}

export default {
  name: 'GolfCase',
  props: {
    golfCase: {
      type: Object,
      required: true
    },
    showAct: {
      type: Boolean
    },
    status: {
      type: Boolean
    },
    isManageWrite: {
      type: Boolean
    }
  },
  components: {
    Table,
    InputCell,
    SelectCell,
    DatePickerCell
  },
  data() {
    return {
      columns1: [
        {
          title: '高尔夫预订',
          children: [
            {
              title: () => (
                <span>
                  <span class="mr-1">日期</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'dateTime',
              width: 72,
              scopedSlots: {
                customRender: 'datePicker'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">时间</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'golfTime',
              width: 100,
              list: [
                { text: '上午', value: '上午' },
                { text: '下午', value: '下午' }
              ],
              scopedSlots: {
                customRender: 'select'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">类型</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'golfType',
              width: 64,
              list: [
                { text: '练习场', value: '练习场' },
                { text: '观光', value: '观光' }
              ],
              scopedSlots: {
                customRender: 'select'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">人数</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'pepleNum',
              width: 64,
              scopedSlots: {
                customRender: 'input'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">费用</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'fee',
              width: 64,
              calc: this.calcActGolfTotal.bind(this, 'app'),
              scopedSlots: {
                customRender: 'input'
              }
            }
          ]
        }
      ],
      columns2: [
        {
          title: '高尔夫实际',
          children: [
            {
              title: () => (
                <span>
                  <span class="mr-1">日期</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'dateTime',
              width: 72,
              scopedSlots: {
                customRender: 'datePicker'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">时间</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'golfTime',
              width: 100,
              list: [
                { text: '上午', value: '上午' },
                { text: '下午', value: '下午' }
              ],
              scopedSlots: {
                customRender: 'select'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">类型</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'golfType',
              width: 100,
              list: [
                { text: '练习场', value: '练习场' },
                { text: '观光', value: '观光' }
              ],
              scopedSlots: {
                customRender: 'select'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">人数</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'pepleNum',
              width: 64,
              scopedSlots: {
                customRender: 'input'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">费用</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'fee',
              width: 64,
              calc: this.calcActGolfTotal.bind(this, 'act'),
              scopedSlots: {
                customRender: 'input'
              }
            }
          ]
        }
      ],
      golfItme
    }
  },

  computed: {
    isAppDisabled() {
      return this.status
    },

    isActDisabled() {
      return !this.isManageWrite && this.status
    }
  },

  methods: {
    calcActGolfTotal(type) {
      const TotalStr = type === 'app' ? 'golfBudgetTotal' : 'actGolfTotal'
      const golfnfos = this.golfCase[`${type}GolfInfos`]

      if (golfnfos && golfnfos.length) {
        this.golfCase[TotalStr] = golfnfos.reduce((prev, current) => {
          return this.$np.plus(prev || 0, current.fee || 0)
        }, 0)
        type === 'act' && this.$emit('calcActualCost')
        return
      }

      this.golfCase[TotalStr] = 0
      type === 'act' && this.$emit('calcActualCost')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
