<template>
  <div class="fee-info-table">
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="实际推广物料信息" key="1" class="customStyle">
          <div style="margin-left: 24px">
            <table cellspacing="0" v-if="showTable">
              <tr class="h-8" style="background-color: #f9f9f9">
                <th class="w-1/8">物料编号</th>
                <th class="w-1/8">类型</th>
                <th class="w-1/8">物料名称</th>
                <th class="w-1/8">物料标识</th>
                <th class="w-1/8">起订量单价</th>
                <th class="w-1/8">
                  实际数量
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <th class="w-1/8">金额</th>
                <th class="w-1/8">备注</th>
                <div v-if="!isRead" class="icon-group">
                  <a-icon
                    type="plus-circle"
                    class="icon"
                    @click="addBrandpre"
                  />
                  <a-icon
                    type="minus-circle"
                    class="icon mt-2"
                    @click="removeBrandpre"
                  />
                </div>
              </tr>
              <tr
                v-for="(item, index) in brandpreInfo.brandpreInfoDTOs"
                :key="`${index}`"
                :class="[index % 2 === 0 ? 'trBgClass' : '']"
              >
                <td class="w-1/8">
                  <brandpre-select
                    :code="item.materialCode"
                    :status="isRead"
                    :conferenceTypeName="conferenceTypeName"
                    @change="changeBrandpre(item, $event)"
                  ></brandpre-select>
                </td>
                <td class="w-1/8">{{ item.type }}</td>
                <td class="w-1/8">{{ item.materialName }}</td>
                <td class="w-1/8">{{ item.materialIdentification }}</td>
                <td class="w-1/8">{{ item.price }}</td>
                <td class="w-1/8">
                  <!-- <editable-cell
                    valType="string"
                    :text="item.brandpreNumApprove"
                    :onlyNumber="true"
                    :status="isRead"
                    @change="onCellChange(index, 'brandpreNumApprove', $event)"
                  ></editable-cell> -->
                  <span v-if="isRead || !item.brandpreId">
                    {{ item.brandpreNumApp }}
                  </span>
                  <a-input
                    v-else
                    v-model="item.brandpreNumApp"
                    @change="updateItem"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  />
                </td>
                <td class="w-1/8">
                  {{
                    $np.times(Number(item.price), Number(item.brandpreNumApp))
                  }}
                </td>
                <td class="w-1/8">{{ item.notes }}</td>
                <div v-if="!isRead"></div>
              </tr>
            </table>
          </div>
          <div class="mt-6 ml-2 text-right text-sm font-semibold">
            <div class="inline-block text-left" style="width: 280px">
              推广物料金额合计：
              <span class="text-red-600">
                {{ brandpreInfo.brandpreMoneytotal }}
              </span>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import BrandpreSelect from './BrandpreSelect'

export default {
  name: 'BrandpreInfo', //推广物料信息
  components: {
    BrandpreSelect
  },
  props: {
    brandpreInfo: {
      type: Object,
      required: true
    },
    conferenceTypeName: String,
    status: Boolean,
    isbrandpre: {
      type: String,
      default: '是'
    }
  },
  data() {
    return {
      brandpre: {
        id: '',
        pid: '',
        materialCode: '',
        brandpreId: '',
        materialIdentification: '',
        materialName: '',
        price: '',
        brandpreNumApp: '',
        brandpreMoneyamount: 0
      },
      showTable: true
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  methods: {
    // 添加一行
    // addLine() {
    //   this.brandpreInfo.brandpreInfoDTOs =
    //     this.brandpreInfo.brandpreInfoDTOs || []
    //   if (this.brandpreInfo.brandpreInfoDTOs.length === 0) {
    //     const brandpre = this.copy(this.brandpre)
    //     this.brandpreInfo.brandpreInfoDTOs.push(brandpre)
    //   } else {
    //     this.submit()
    //   }
    // },
    updateItem() {
      this.$emit('updateItem')
      this.submit()
    },
    submit() {
      this.brandpreInfo.brandpreMoneytotal = 0
      this.brandpreInfo.brandpreInfoDTOs.forEach(item => {
        this.brandpreInfo.brandpreMoneytotal = this.$np.plus(
          Number(this.brandpreInfo.brandpreMoneytotal),
          this.$np.times(Number(item.price), Number(item.brandpreNumApp))
        )
      })
      this.$emit('update:brandpreInfo', this.brandpreInfo)
    },

    //添加一条推广物料信息
    addBrandpre() {
      const brandpre = this.copy(this.brandpre)
      this.brandpreInfo.brandpreInfoDTOs.push(brandpre)
    },

    /**
     * 删除一条推广物料信息
     */
    removeBrandpre() {
      this.brandpreInfo.brandpreInfoDTOs.pop()
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
      this.updateItem()
    },

    // 推广物料信息改变
    changeBrandpre(item, value) {
      item.pid = value.pid
      item.id = value.id
      item.brandpreId = value.brandpreId
      item.materialCode = value.materialCode
      item.materialIdentification = value.materialIdentification
      item.materialName = value.materialName
      item.price = value.price
      item.type = value.type
      item.notes = value.notes
      this.updateItem()
    },

    /**
     * 深拷贝data中brandpre对象
     * @param {Object} brandpre data中的brandpre对象
     */
    copy(brandpre) {
      return JSON.parse(JSON.stringify(brandpre))
    }
  }
}
</script>

<style lang="less" scoped>
.fee-info-table table {
  width: 100%;
}
.fee-info-table table th {
  text-align: left;
  font-size: 14px;

  font-weight: 600;
  color: #999999;
  padding: 16px;
}
.fee-info-table table tr {
  padding: 12px;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
}
.fee-info-table table tr td {
  text-align: left;
  padding: 4px 16px;
}

.icon-group {
  position: absolute;
  right: 0px;
  display: flex;
  height: 3rem;
  flex-direction: column;
  justify-content: space-around;
  opacity: 1;
  align-content: space-between;
}
.icon {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
