<template>
  <div>
    <a-list item-layout="horizontal" :data-source="anuceList">
      <div
        slot="loadMore"
        :style="{
          textAlign: 'center',
          marginTop: '12px',
          height: '32px',
          lineHeight: '32px'
        }"
      >
        <a-spin v-if="showLoadingMore" />
        <a-button
          type="primary"
          v-if="!showLoadingMore && !isEnd"
          @click="onLoadMore"
        >
          查看更多
        </a-button>
      </div>
      <a-list-item
        slot="renderItem"
        slot-scope="item"
        class="list-item"
        @click="handldClick(item)"
      >
        <a-list-item-meta>
          <a slot="title" class="font-bold" @click.stop="handldClick(item)">
            {{ item.title }}
          </a>
          <div slot="description" class="anuce-content">
            {{ matchReg(item.msg) }}
          </div>
          <div
            slot="description"
            style="font-size: 12px"
            class="flex justify-between mt-2"
          >
            <span class="text-blue-300">
              {{ $date(item.creationtime).fromNow() }}
            </span>
            <a-tag :color="item.readSign ? '#48bb78' : '#ed8936'">
              {{ item.readSign ? '已读' : '未读' }}
            </a-tag>
          </div>
        </a-list-item-meta>
      </a-list-item>
    </a-list>

    <a-modal
      v-model="visible"
      width="1024px"
      style="top: 32px"
      destroyOnClose
      :title="anuce.title"
      :maskClosable="false"
      :footer="false"
      :bodyStyle="{
        padding: '24px',
        height: 'calc(100vh - 124px)',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column'
      }"
    >
      <div v-html="anuce.msg" class="flex-1 border rounded-lg p-4"></div>
      <div class="text-left mt-4">
        <a :style="{ color: hasFile ? '#4587f5' : '#666' }" @click="openFile">
          <a-icon type="link" />
          公告附件
        </a>

        <span v-show="showMsg" class="ml-4 text-xs text-orange-500">
          <a-icon type="exclamation-circle" />
          未上传公告附件
        </span>
      </div>
      <div class="text-right mt-4">
        <span>发布日期:</span>
        <span>{{ anuce.creationtime }}</span>
      </div>
    </a-modal>

    <a-modal
      v-model="fileVisible"
      title="公告附件"
      width="800px"
      :maskClosable="false"
      :footer="false"
      :bodyStyle="{
        padding: 0,
        paddingLeft: '24px',
        paddingRight: '24px'
      }"
    >
      <FileUpload :isEdit="true" :annex="enclosures"></FileUpload>
    </a-modal>
  </div>
</template>

<script>
import {
  getReadAnuceList,
  getAnuceById,
  getReadAnuceById
} from '@/api/sale/anuce'
import FileUpload from '@/views/product/productmanage/components/FileUpload'
export default {
  name: 'Anuce',
  components: { FileUpload },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      isEnd: true,
      showLoadingMore: false,

      anuce: {},
      enclosures: '',
      visible: false,
      fileVisible: false,
      showMsg: false
    }
  },

  created() {
    this.getList()
  },

  computed: {
    hasFile() {
      return this.anuce.enclosures && this.anuce.enclosures.length
    },

    anuceList() {
      return this.$store.getters.anuceList
    }
  },

  methods: {
    refresh() {
      this.pageIndex = 1
      this.pageSize = 10
      if (!this.anuceList.length) this.$store.commit('anuce/CLEAR_ANUCE_LIST')
      this.getList()
    },

    onLoadMore() {
      if (this.isEnd) return
      this.pageIndex++
      this.showLoadingMore = true
      this.getList()
    },

    getList() {
      setTimeout(() => {
        getReadAnuceList(this.pageIndex, this.pageSize)
          .then(res => {
            this.isEnd = !res.nextPage
            this.$store.commit('anuce/SET_ANUCE_LIST', res.datas)
          })
          .finally(() => {
            this.showLoadingMore = false
          })
      }, 1000)
    },

    // 只获取公告的文字
    matchReg(text) {
      const reg = /<\/?.+?\/?>/g
      const remove = /&nbsp;/g
      const sub = text.replace(reg, '')
      return sub.replace(remove, '')
    },

    async handldClick(item) {
      this.showMsg = false
      this.visible = true
      this.anuce = await getAnuceById(item.id)

      // 设置已读
      if (!item.readSign) {
        getReadAnuceById(item.bid).then(() => (item.readSign = true))
      }
    },

    openFile() {
      if (!(this.anuce.enclosures && this.anuce.enclosures.length)) {
        this.showMsg = true
        return
      }
      this.fileVisible = true
      this.enclosures = ''
      if (this.anuce.enclosures && this.anuce.enclosures.length) {
        setTimeout(() => {
          this.enclosures = JSON.stringify(this.anuce.enclosures)
        }, 0)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.anuce-content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis; //用省略号显示
  color: #909399;
  font-size: 12px;
}
</style>
