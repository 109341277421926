var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-detail"},[_c('div',{staticClass:"file-container rounded-lg common-bg px-4 pb-3 mb-4"},[_c('a',{staticClass:"preview-btn inline-block my-2",on:{"click":function($event){_vm.modalVisible = true}}},[_c('a-icon',{staticClass:"text-sm",attrs:{"type":"zoom-in"}}),_vm._v(" 放大查看 ")],1),(_vm.isImage)?_c('a',{staticClass:"preview-btn inline-block ml-2",on:{"click":_vm.turnRight}},[_c('a-icon',{staticClass:"text-sm",attrs:{"type":"redo"}}),_vm._v(" 旋转图片 ")],1):_vm._e(),_c('div',{staticClass:"file overflow-hidden text-center",staticStyle:{"background-color":"#525659"}},[(
          _vm.form.file && ['pdf', 'ofd'].includes(_vm.form.file.type.toLowerCase())
        )?_c('iframe',{staticClass:"bg-white h-full w-full",attrs:{"src":_vm.previewUrl,"frameborder":"1"}}):_c('img',{staticClass:"max-w-full max-h-full inline-block",style:({ transform: ("rotate(" + (_vm.rotate || 0) + "deg)") }),attrs:{"src":_vm.previewUrl}})]),_c('InvoiceForm',{ref:"bill",attrs:{"form":_vm.form,"isDup":_vm.isDup}})],1),_c('a-modal',{staticStyle:{"top":"32px"},attrs:{"title":"发票附件","width":"85%","footer":false,"bodyStyle":{
      padding: '8px',
      height: 'calc(100vh - 120px)',
      overflow: 'auto'
    }},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[(
        _vm.form.file && ['pdf', 'ofd'].includes(_vm.form.file.type.toLowerCase())
      )?_c('iframe',{staticClass:"bg-white h-full w-full",attrs:{"src":_vm.previewUrl,"frameborder":"1"}}):_c('div',{staticClass:"text-center h-full position-relative overflow-auto",staticStyle:{"background-color":"#525659"}},[_c('img',{ref:"image",staticClass:"max-w-full max-h-full inline-block",style:({ transform: ("rotate(" + (_vm.rotate || 0) + "deg)") }),attrs:{"src":_vm.previewUrl}}),_c('a-button',{staticClass:"el-carousel__arrow el-carousel__arrow-i-right",attrs:{"type":"button","shape":"circle"},on:{"click":_vm.imageIn}},[_c('a-icon',{attrs:{"type":"zoom-out"}})],1),_c('a-button',{staticClass:"el-carousel__arrow el-carousel__arrow-o-right",attrs:{"type":"button","shape":"circle"},on:{"click":_vm.imageOut}},[_c('a-icon',{attrs:{"type":"zoom-in"}})],1),_c('a-button',{staticClass:"el-carousel__arrow el-carousel__arrow-t-right",attrs:{"type":"button","shape":"circle"},on:{"click":_vm.turnRight}},[_c('a-icon',{attrs:{"type":"redo"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }