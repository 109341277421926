<template>
  <div>
    <div style="padding-left: 32px" class="my-4">
      <a-form
        size="small"
        laba-width="90px"
        laba-position="right"
        layout="vertical"
      >
        <a-collapse v-model="activeKey" :bordered="false">
          <template v-slot:expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel
            class="customStyle"
            v-for="{ title, active, children } in baseMsg"
            :header="title"
            :key="active"
          >
            <a-col :span="8" v-for="{ label, value } in children" :key="label">
              <a-form-item
                :label="label"
                :prop="value"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input
                  v-if="value === 'gender'"
                  :disabled="true"
                  :value="
                    formData[value]
                      ? formData[value] === 'W'
                        ? '女'
                        : '男'
                      : '无'
                  "
                ></a-input>
                <a-input
                  v-else-if="value === 'appStatus'"
                  :disabled="true"
                  :value="getStatusText(formData[value])"
                ></a-input>
                <a
                  v-else-if="value === 'qualificationInfo'"
                  :href="formData[value]"
                  target="_blank"
                  style="color: #4587f5"
                  :title="formData[value]"
                >
                  {{ formData[value] }}
                </a>
                <a-input
                  v-else
                  :disabled="true"
                  v-model="formData[value]"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-collapse-panel>
          <a-collapse-panel
            header="附件信息"
            key="FILE"
            class="customStyle"
            style="margin-bottom: 36px"
          >
            <div class="form-item form-upload">
              <span class="form-label" style="color: rgba(0, 0, 0, 0.85)">
                讲者附件:
              </span>
              <span v-if="formData.enclosures && formData.enclosures.length">
                <icon-font
                  :type="
                    formData.enclosures.length
                      ? 'icon_wenjianjia2'
                      : 'icon_wenjianjia1'
                  "
                  class="text-xl ml-4"
                  @click="preview(formData.enclosures, 'speaker')"
                />
              </span>
              <span v-else class="text-blue-400">无上传的附件</span>
            </div>
          </a-collapse-panel>
          <a-collapse-panel
            v-for="{ title, active, dataList, columns } in tableMsg"
            :header="title"
            :key="active"
            class="customStyle"
          >
            <a-table
              rowKey="filedName"
              :dataSource="formData[dataList]"
              :columns="columns"
              :pagination="false"
              :rowClassName="
                (record, index) => {
                  return index % 2 === 0 ? 'trBgClass' : ''
                }
              "
            ></a-table>
          </a-collapse-panel>
        </a-collapse>
        <process
          v-if="formData.flowInfoDTO"
          :summaryid="formData.flowInfoDTO.summaryid"
          :nextapproveman="formData.flowInfoDTO.nextapproveman"
          :curApprover="formData.flowInfoDTO.curApprover"
        ></process>
        <comment :billId="formData.id"></comment>
      </a-form>
    </div>
    <preview-modal
      v-if="showPreview"
      :uploadName="uploadName"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import PreviewModal from '@/components/Upload/PreviewModal'
import Process from '@/views/custmanage/modify/components/Process'
import { BASE, TABLE } from './speaker-config'
import { getSpeakerModifyById } from '@/api/customer/modify'
import { getStatusText } from '@/configs/bill-msg'
export default {
  name: 'SpeakerModifyBill',
  components: { Process, PreviewModal, Comment },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      value: 'a',
      showPreview: false, //预览文件的弹框默认关闭
      uploadName: '', //传入上传附件modal的字段名
      previewFileListInfo: '', //传入预览附件的文件信息
      formData: {},
      baseMsg: BASE,
      tableMsg: TABLE,
      activeKey: ['BASE', 'SELF', 'LEVEL', 'STATUS', 'DIFF', 'FILE'],
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getSpeakerModifyById(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData.appStatus)
    })
  },
  methods: {
    getStatusText,

    // 传递预览文件信息
    preview(previewFileListInfo, str) {
      this.previewFileListInfo = JSON.stringify(previewFileListInfo)
      this.uploadName = str
      this.showPreview = true
    }
  }
}
</script>
<style lang="less" scoped>
a {
  display: block;
  /*超出隐藏*/
  overflow: hidden;
  /*禁止换行*/
  white-space: nowrap;
  /*超出用省略号代替*/
  text-overflow: ellipsis;
}

/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
}
/deep/ .ant-btn-primary[disabled],
/deep/.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
/deep/.ant-btn-primary {
  background-color: #4587f4;
}
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}
</style>
