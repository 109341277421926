const FILETYPEMAPPER = {
  docx: 'application/pdf',
  doc: 'application/pdf',
  bin: 'application/octet-stream',
  exe: 'application/octet-stream',
  so: 'application/octet-stream',
  dll: 'application/octet-stream',
  pdf: 'application/pdf',
  ai: 'application/postscript',
  xls: 'text/html',
  xlsx: 'text/html',
  ppt: 'application/vnd.ms-powerpoint',
  dir: 'application/x-director',
  js: 'application/x-javascript',
  swf: 'application/x-shockwave-flash',
  xhtml: 'application/xhtml+xml',
  xht: 'application/xhtml+xml',
  zip: 'application/zip',
  mid: 'audio/midi',
  midi: 'audio/midi',
  mp3: 'audio/mpeg',
  rm: 'audio/x-pn-realaudio',
  rpm: 'audio/x-pn-realaudio-plugin',
  wav: 'audio/x-wav',
  bmp: 'image/bmp',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
  css: 'text/css',
  html: 'text/html',
  htm: 'text/html',
  txt: 'application/json',
  xsl: 'text/xml',
  xml: 'text/xml',
  mpeg: 'video/mpeg',
  mpg: 'video/mpeg',
  avi: 'video/x-msvideo',
  movie: 'video/x-sgi-movie'
}

export function getContentTypeHeader(type) {
  return FILETYPEMAPPER[type]
}

/**
 * 将文件流转为blob预览地址
 * @param {ArrayBuffer} data 文件流
 * @param {String} fileType 文件类型
 */
export function transferFileToBlob(data, fileType) {
  const blob = new Blob([data], {
    type: getContentTypeHeader(fileType)
  })
  const contentSRC = URL.createObjectURL(blob)
  return contentSRC
}

export function getOfficePreviewUrl() {
  const ENV = process.env.VUE_APP_MODE.toLowerCase()
  const base = `https://office-wps.dyg.com.cn/web-reader/reader?file=`
  const pUrl =
    ENV === 'production'
      ? `http://crm.dyg.com.cn/crm`
      : `https://crmtest.dyg.com.cn:7090`
  return `${base}${pUrl}`
}
