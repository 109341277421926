export default {
  computed: {
    isRead() {
      return this.status
    },
    budgetMealsTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetMeals),
        Number(this.feeReimInfo.budgetMealsNum)
      )
    },
    budgetAccommodationTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetAccommodation),
        Number(this.feeReimInfo.budgetAccommodationNum)
      )
    },
    budgetBoothTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetBooth),
        Number(this.feeReimInfo.budgetBoothNum)
      )
    },
    budgetMeetingroomTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetMeetingroom),
        Number(this.feeReimInfo.budgetMeetingroomNum)
      )
    },
    budgetFlightTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetFlight),
        Number(this.feeReimInfo.budgetFlightNum)
      )
    },
    budgetTrainTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetTrain),
        Number(this.feeReimInfo.budgetTrainNum)
      )
    },
    budgetReceptionTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetReception),
        Number(this.feeReimInfo.budgetReceptionNum)
      )
    },
    budgetMakeTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetMake),
        Number(this.feeReimInfo.budgetMakeNum)
      )
    },
    budgetDesignTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetDesign),
        Number(this.feeReimInfo.budgetDesignNum)
      )
    },
    budgetThirdpartyTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.budgetThirdparty),
        Number(this.feeReimInfo.budgetThirdpartyNum)
      )
    },

    reimbudgetMealsTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetMeals),
        Number(this.feeReimInfo.reimbudgetMealsNum)
      )
    },
    reimbudgetAccommodationTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetAccommodation),
        Number(this.feeReimInfo.reimbudgetAccommodationNum)
      )
    },
    reimbudgetBoothTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetBooth),
        Number(this.feeReimInfo.reimbudgetBoothNum)
      )
    },
    reimbudgetMeetingroomTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetMeetingroom),
        Number(this.feeReimInfo.reimbudgetMeetingroomNum)
      )
    },
    reimbudgetFlightTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetFlight),
        Number(this.feeReimInfo.reimbudgetFlightNum)
      )
    },
    reimbudgetTrainTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetTrain),
        Number(this.feeReimInfo.reimbudgetTrainNum)
      )
    },
    reimbudgetReceptionTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetReception),
        Number(this.feeReimInfo.reimbudgetReceptionNum)
      )
    },
    reimbudgetMakeTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetMake),
        Number(this.feeReimInfo.reimbudgetMakeNum)
      )
    },
    reimbudgetDesignTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetDesign),
        Number(this.feeReimInfo.reimbudgetDesignNum)
      )
    },
    reimbudgetThirdpartyTotal() {
      return this.$np.times(
        Number(this.feeReimInfo.reimbudgetThirdparty),
        Number(this.feeReimInfo.reimbudgetThirdpartyNum)
      )
    }
  },
  watch: {
    conferenceTypeName(nval) {
      if (nval === '地区') {
        this.feeReimInfo.budgetBooth = null
        this.feeReimInfo.budgetBoothNum = null
        this.submit()
      }
    },
    brandpreMoneytotal(nval) {
      if (nval) {
        this.feeReimInfo.budgetBrandpreTotal = Number(nval)
      } else {
        this.feeReimInfo.budgetBrandpreTotal = Number('0')
      }
      this.submit()
    },
    'feeReimInfo.budgetCooperationTotal'(nval) {
      if (nval) {
        this.feeReimInfo.budgetCooperationTotal = Number(nval)
      } else {
        this.feeReimInfo.budgetCooperationTotal = Number('0')
      }
      this.submit()
    },
    // 撤消时改变批准总报销清0
    'feeReimInfo.reimAmountApprove'(nval) {
      if (nval) {
        this.feeReimInfo.reimAmountApprove = Number(nval)
      } else {
        this.feeReimInfo.reimAmountApprove = Number('0')
      }
      this.submit()
    },
    budgetConsultTotal(nval) {
      if (nval) {
        this.feeReimInfo.budgetConsultTotal = Number(nval)
      } else {
        this.feeReimInfo.budgetConsultTotal = Number('0')
      }
      this.submit()
    },

    infoPubAmount(nval) {
      if (nval) {
        this.feeReimInfo.infoPubAmount = Number(nval)
      } else {
        this.feeReimInfo.infoPubAmount = Number('0')
      }
      this.submit()
    },

    feeReimInfo(nval) {
      this.butgetCalculate()
      this.feeReimInfo = nval
    }
  },
  methods: {
    // 计算实际花销合计
    calculate() {
      this.feeReimInfo.budgetTotal = 0
      this.feeReimInfo.budgetTotal = this.$np.plus(
        this.budgetMealsTotal,
        this.budgetAccommodationTotal,
        this.budgetBoothTotal,
        this.budgetMeetingroomTotal,
        this.budgetFlightTotal,
        this.budgetTrainTotal,
        this.budgetReceptionTotal,
        this.budgetMakeTotal,
        this.budgetDesignTotal,
        this.budgetThirdpartyTotal,
        Number(this.feeReimInfo.budgetBrandpreTotal),
        Number(this.feeReimInfo.budgetCooperationTotal),
        Number(this.feeReimInfo.budgetConsultTotal),
        Number(this.feeReimInfo.budgetServiceTotal),
        Number(this.feeReimInfo.infoPubAmount), //品牌信息费
        Number(this.feeReimInfo.otherFee), // 其他费用
        Number(this.feeReimInfo.registerFee), // 注册费用
        Number(this.feeReimInfo.onlineLiveBroadcastingFee) //线上直播平台费用
      )
    },

    // 计算预算总金额
    butgetCalculate() {
      this.budgetFee = 0
      this.budgetFee = this.$np.plus(
        this.reimbudgetMealsTotal,
        this.reimbudgetAccommodationTotal,
        this.reimbudgetBoothTotal,
        this.reimbudgetMeetingroomTotal,
        this.reimbudgetFlightTotal,
        this.reimbudgetTrainTotal,
        this.reimbudgetReceptionTotal,
        this.reimbudgetMakeTotal,
        this.reimbudgetDesignTotal,
        this.reimbudgetThirdpartyTotal,
        Number(this.feeReimInfo.reimbudgetBrandpreTotal), //推广物料
        Number(this.feeReimInfo.reimbudgetCooperationTotal), //协办金额
        Number(this.feeReimInfo.reimbudgetConsultTotal), //讲者咨询费
        Number(this.feeReimInfo.reimbudgetServiceTotal), //服务费
        Number(this.feeReimInfo.reimInfoPubAmount), //品牌信息费
        Number(this.feeReimInfo.reimOtherFee), // 其他费用
        Number(this.feeReimInfo.reimRegisterFee), // 注册费用
        Number(this.feeReimInfo.reimOnlineLiveBroadcastingFee) //线上直播平台费用
      )
    }
  }
}
