<template>
  <div class="m-4">
    <div class="title" v-if="showAvatar">丙肝送检公费核销单详情</div>
    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      align="middle"
      class="text-right"
    >
      <a-button
        type="primary"
        :disabled="isFlow"
        :loading="dataLoading"
        @click="save"
      >
        保存
      </a-button>
      <a-button
        type="primary"
        class="ml-2"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="submit"
      >
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        :loading="dataLoading"
        @click="del"
      >
        删除
      </a-button>
      <a-button
        class="ml-2"
        :loading="dataLoading"
        :disabled="backDisabled"
        @click="getBack"
      >
        撤销
      </a-button>
      <a-divider type="vertical"></a-divider>
      <a-button
        :loading="dataLoading"
        @click="$router.push({ name: 'hcvWriteOff' })"
      >
        返回
      </a-button>
    </a-row>

    <base-form>
      <base-form-title title="申请人信息" icon="user" />
      <base-form-items
        :formData="form"
        :formBaseList="formUserList"
      ></base-form-items>

      <base-form-title title="核销基本信息" icon="book" />
      <base-form-items
        :formData="form"
        :formBaseList="formBaseList"
        :formTextAreaList="formBaseTextAreaList"
      ></base-form-items>

      <base-form-title title="实际送检信息" icon="container" />
      <base-form-items
        :formData="form"
        :formBaseList="formTestList"
        :formTextAreaList="formTestTextAreaList"
        :formFileList="formTestFileList"
        @open-file="openFile"
      ></base-form-items>

      <base-form-title title="终端明细" icon="table" />
      <div class="p-4">
        <a-table
          bordered
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="form.hospitalList"
          :pagination="false"
          :rowClassName="
            record => {
              return hospId === record.id ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  click: () => {
                    hospId = record.id
                  }
                }
              }
            }
          "
        >
          <!-- 表格标题 -->
          <template slot="nameTitle">
            <span class="app-form-required">终端名称</span>
          </template>
          <template slot="hospTitle">
            <span class="app-form-required">标准终端编码</span>
          </template>

          <!-- 序号 -->
          <template slot="index" slot-scope="text, record, index">
            <span>{{ index + 1 }}</span>
          </template>
          <!-- 终端名称用户填写 -->
          <template slot="name" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.name }}
            </span>
            <a-input v-else v-model="record.name"></a-input>
          </template>
          <!-- 选择标准终端 -->
          <template slot="code" slot-scope="text, record">
            <span v-if="isFlow">
              {{ record.hospitalCode }}
            </span>
            <a-input v-else v-model="record.hospitalCode" readOnly>
              <a-icon slot="suffix" type="search" @click="openHosp(record)" />
            </a-input>
          </template>
        </a-table>
        <a-button-group class="mt-2" v-if="!isFlow">
          <a-button icon="plus" @click="addLastHosp">添加</a-button>
          <a-button icon="minus" type="danger" @click="delLastHosp">
            删除
          </a-button>
        </a-button-group>
      </div>

      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDTO"
        :status="isFlow"
        :summaryid="form.flowInfoDTO.summaryid"
        :nextapproveman="form.flowInfoDTO.nextapproveman"
        :curApprover="form.flowInfoDTO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.applicantId" :billId="form.id"></comment>
    </base-form>

    <!-- 终端(医院管理) -->
    <hosp-modal
      :visible="showModal"
      @choose="onChooseHosp"
      @close="showModal = false"
    ></hosp-modal>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </div>
</template>

<script>
import BaseForm from '@/components/FieldSet/BaseForm'
import BaseFormTitle from '@/components/FieldSet/BaseFormTitle'
import BaseFormItems from '@/components/FieldSet/BaseFormItems'
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import HospModal from '@/views/outer/flowBill/components/HospModal'
// api
import moment from 'moment'
import { default as cloneDeep } from 'lodash/cloneDeep'
import { isDisabled } from '@/utils/util'
import { randomString } from '@/utils'
import {
  saveHcvReim,
  updateHcvReim,
  delHcvReim,
  getHcvReim,
  submitHcvReim
} from '@/api/acdemicActive/hcv-reim'
import { getHcvApp } from '@/api/acdemicActive/hcv-application'
import { getDeleteFiles } from '@/api/acdemicActive/upload'
import { getOACancel2 } from '@/api/acdemicActive/acdemic-application'

// mixin
import userMixin from '../modules/mixins/user-mixin'
import verityMixin from '../modules/mixins/verity-mixin'

export default {
  name: 'HcvReimForm',
  components: {
    BaseForm,
    BaseFormTitle,
    BaseFormItems,
    UploadModal,
    PreviewModal,
    Process,
    Comment,
    HospModal
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  mixins: [userMixin, verityMixin],
  data() {
    return {
      /* eslint-disable prettier/prettier */
      curType: this.type || this.$route.query.type || 'add',
      curPid: this.pid || this.$route.query.pid,

      hospId: '',
      currentRow: {},
      showModal: false,
      columns: [
        { title: '序号', dataIndex: 'index', width: 72, scopedSlots: { customRender: 'index' } },
        { dataIndex: 'name', width: 300, slots: { title: 'nameTitle' }, scopedSlots: { customRender: 'name' } },
        { dataIndex: 'hospitalCode', width: 200, slots: { title: 'hospTitle' }, scopedSlots: { customRender: 'code' } },
        { title: '标准终端名称', dataIndex: 'hospitalName' },
        { title: '业务区域名称', dataIndex: 'chnlareaName' },
      ],

      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: '',

      dataLoading: false, // 提交数据

      form: {
        appdate: '', // 申请日期
        applicantId: '', // 申请人id
        applicantName: '', // 申请人名称
        postName: '', //岗位
        contact: '', // 联系方式
        regionId: '', // 大区id
        region: '', // 大区名称
        provinceId: '', // 省办id
        province: '', // 省办名称

        vbillno: '', // 核销单号
        appVbillno: '', // 申请单号
        agencyId: '', // 送检机构id
        agencyName: '', // 送检机构名称
        startDate: '', // 送检开始日期
        endDate: '', // 送检结束日期
        appReason: '', // 申请理由

        productId: '', // 产品id
        productPrice: '', // 产品单价
        productCode: '', // 产品code
        productName: '', // 产品name
        actualNucleicAcidNum: '', // 实际核酸检测份数
        actualGenotypingNum: '', // 实际基因分型份数
        actualPatientNum: '', // 实际1型患者人数
        actualPrescriptionNum: '', // 实际处方人数
        actualCvr: '', // 实际转化率
        actualInvest: '', // 实际投入（元）
        actualOutput: '', // 实际产出（元）
        actualIcor: '', // 实际投入产出比
        approvedSum: '', // 领导批准公费
        uploadInfo: '', // 上传文件
        notes: '', // 备注

        hospitalList: [], // 终端明细
       
        flowInfoDTO: { status: '1' } // 流程信息
      },

      formBaseList: [
        { label: '核销单号', prop: 'vbillno', disabled: true },
        { label: '申请单号', prop: 'appVbillno', disabled: true },
        { label: '送检机构', prop: 'agencyName', disabled: true },
        { label: '送检开始日期', prop: 'startDate', type: 'date', disabled: () => this.getCommonDisabled, disabledDate: () => this.disabledStDate },
        { label: '送检结束日期', prop: 'endDate', type: 'date', disabled: () => this.getCommonDisabled, disabledDate: () => this.disabledEndDate },
      ],

      formBaseTextAreaList: [
       { label: '申请理由', prop: 'appReason', disabled: () => this.getCommonDisabled },
      ],

      formTestList: [
        { label: '产品名称', prop: 'productName', disabled: true },
        { label: '产品单价', prop: 'productPrice', disabled: true },
        { label: '实际核酸检测份数', prop: 'actualNucleicAcidNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '实际基因分型份数', prop: 'actualGenotypingNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '实际1型患者人数', prop: 'actualPatientNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '实际处方人数', prop: 'actualPrescriptionNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '实际转化率', prop: 'actualCvr', disabled: true },
        { label: '实际投入（元）', prop: 'actualInvest', disabled: () => this.getCommonDisabled, validate: 'digit' },
        { label: '实际产出（元）', prop: 'actualOutput', disabled: true },
        { label: '实际投入产出比', prop: 'actualIcorStr', disabled: true },
        { label: '领导批准公费', prop: 'approvedSum', disabled: true },
      ],

      formTestFileList: [
        { label: '上传文件', notVerity: true, prop: 'uploadInfo', disabled: false },
      ],

      formTestTextAreaList: [
       { label: '备注', prop: 'notes', disabled: () => this.getCommonDisabled, required: false },
      ]
    }
  },

  computed: {
    showAvatar() {
      return this.$store.getters['protal']
    },

    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getCommonDisabled() {
      return this.isFlow || false
    },
  },

  created() {
    if(this.curType !== 'add') {
      getHcvReim(this.curPid).then(res => {
        this.form = res

        if(this.form.actualIcor && this.form.actualIcor > 0 ) this.form.actualIcorStr = `1 : ${ this.form.actualIcor }`

        // 单据状态(向上通知)
        const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
        this.$emit('status', status)
      })
    }
    else {
      // 新增
      getHcvApp(this.curPid).then(res => {
        this.form.agencyId = res.agencyId // 检测机构id
        this.form.agencyName = res.agencyName // 检测机构name
        this.form.productId = res.productId // 产品id
        this.form.productName = res.productName // 产品名称
        this.form.productPrice = res.productPrice // 产品价格
        this.form.appVbillno = res.vbillno // 申请单号
        this.form.pid = res.id // 申请单id
        // 终端明细
        this.form.hospitalList = res.hospitalList.map(item => ({...item, ...{ id: `hosp_${randomString(10)}`, hid: '' }}))
      })
    }
  },

  watch: {
    'form.productPrice': {
      handler(val) {
        this.calcActualOutput(this.form.actualPrescriptionNum, val)
      }
    },
    'form.actualPrescriptionNum': {
      handler(val) {
        this.calcActualOutput(val, this.form.productPrice)
        this.calcActualCvr(val, this.form.actualPatientNum)
      }
    },
    'form.actualInvest': {
      handler(val) {
        this.calcActualIcor(val, this.form.actualOutput)
      }
    },
    'form.actualOutput': {
      handler(val) {
        this.calcActualIcor(this.form.actualInvest, val)
      }
    },
    'form.actualPatientNum': {
      handler(val) {
        this.calcActualCvr(this.form.actualPrescriptionNum, val)
      }
    }
  },

  methods: {
    moment,

    /**
     * 计算实际产出（元）
     * 计算规则：实际处方人数*产品单价*3
     * @param {*} actualPrescriptionNum 实际处方人数
     * @param {*} productPrice 产品单价
     */
    calcActualOutput(actualPrescriptionNum, productPrice) {
      if(
        (actualPrescriptionNum || actualPrescriptionNum == 0) &&
        (productPrice || productPrice == 0)
       ){
        this.form.actualOutput = this.$np.times(
          actualPrescriptionNum || '0',
          this.$np.times(productPrice, 3)
        )
      }
    },

    /**
     * 计算投入产出比
     * 计算规则：实际产出/实际投入
     * @param {*} actualInvest 实际投入（元）
     * @param {*} actualOutput 实际产出（元）
     */
    calcActualIcor(actualInvest, actualOutput) {
      if(actualInvest === 0 || actualInvest === '0') { 
        this.form.actualIcor = ''
        this.form.actualIcorStr = ''
        return
      }
      
      if(actualInvest && (actualOutput || actualOutput == 0)){
        this.form.actualIcor = this.$np.round(this.$np.divide(
          actualOutput,
          actualInvest
        ), 2)

        this.form.actualIcorStr = `1 : ${this.form.actualIcor}`
      } else { 
        this.form.actualIcor = ''
        this.form.actualIcorStr = ''
        return
      }

    },

    /**
     * 计算转化率
     * 计算规则：实际处方人数/实际1型患者人数
     * @param {*} actualPrescriptionNum 实际处方人数
     * @param {*} actualPatientNum 实际1型患者人数
     */
    calcActualCvr(actualPrescriptionNum, actualPatientNum) {
      if(actualPatientNum === 0 || actualPatientNum === '0') return
      
      if(actualPatientNum && (actualPrescriptionNum|| actualPrescriptionNum == 0)){
        this.form.actualCvr = this.$np.round(this.$np.divide(
          actualPrescriptionNum,
          actualPatientNum
        ), 2)
      }
    },

    onChooseHosp(hosp) {
      this.currentRow.hospitalId = hosp.id // 医院主键
      this.currentRow.hospitalCode = hosp.hospitalCode // 医院code
      this.currentRow.hospitalName = hosp.hospitalName // 医院名称
      this.currentRow.pkChnlarea = hosp.pkChnlarea // 业务区域pk
      this.currentRow.chnlareaCode = hosp.chnlareaCode // 业务区域code
      this.currentRow.chnlareaName = hosp.chnlareaName // 业务区域名称
    },

    // 附件上传或预览
    openFile(item) {
      const title = item.label
      const fileProp = item.prop

      if (this.isFlow) {
        this.previewTitle = title
        this.previewFileListInfo = this.form[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = this.form[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '上传文件':
          this.form.uploadInfo = fileListInfo
          break
        default:
          break
      }
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
     disabledStDate(time) {
      return (
        time && time >= moment(this.form.endDate).endOf('day')
      )
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledEndDate(time) {
      return (
        time &&
        time <=
          moment(this.form.startDate).add(-1, 'day').endOf('day')
      )
    },

    openHosp(record) {
      this.currentRow = record
      this.showModal = true
    },

    addLastHosp() {
      const pk = `hosp_${randomString(10)}`

      this.form.hospitalList.push({
        id: pk,
        hid: '', // 核销单主键
        name: '', // 终端名称
        hospitalId: '', // 标准终端id
        hospitalName: '', // 标准终端名称
        hospitalCode: '', // 标准终端编码
        pkChnlarea: '', // 业务区域PK
        chnlareaCode: '', // 业务区域名称
        chnlareaName: '' // 业务区域编码
      })
    },

    // 删除终端
    delLastHosp() {
      if(this.form.hospitalList.length){
        if(this.hospId) {
          this.form.hospitalList = this.form.hospitalList.filter(item => item.id !== this.hospId)
        }else {
          this.form.hospitalList.pop()
        }
      }
    },

    verityHosp() {
      for (let index = 0; index < this.form.hospitalList.length; index++) {
        const item = this.form.hospitalList[index]
        if(!item.name) {
          this.$message.error(`行号${index + 1}: 请填写终端名称`)
          return false
        }

        if(!item.hospitalId) {
          this.$message.error(`行号${index + 1}: 请选择标准终端`)
          return false
        }
        
      }

      return true
    },

    filterData() {
      const cloneData = cloneDeep(this.form)

      for (const item of (cloneData.hospitalList || [])) {
        if(item.id.startsWith('hosp_')){
          item.id = ''
        }
      }

      return cloneData
    },

    //保存
    save(callback) {
      this.saveForm(callback)
    },

    saveForm() {
      const saveApi = this.form.id ? updateHcvReim : saveHcvReim

      const submitData = this.filterData()

      if (this.verityAppForm('save')) {

        if(this.form.id && !this.verityHosp()) return
        
        this.dataLoading = true
        saveApi(submitData)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.$router.replace({ name: 'hcvWriteOff' })
          }).finally(() => {
            this.dataLoading = false
          })
      } 
    },

    //提交
    submit() {
      const submitData = this.filterData()

      if (this.verityAppForm(true)) {

        if(!this.verityHosp()) return

        this.dataLoading = true
        submitHcvReim(submitData).then(() => {
          this.delUploadFile()
          this.$message.success('提交成功')
          this.$router.replace({ name: 'hcvWriteOff' })
        }).finally(() => {
            this.dataLoading = false
          })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该丙肝公费送检核销单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delHcvReim(this.form.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'hcvWriteOff' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该丙肝公费送检核销单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'GFHX',
            this.form.flowInfoDTO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDTO.status = '5'
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    } 
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
}
.app-form-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
