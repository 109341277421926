<template>
  <div class="ml-4 my-4">
    <a-steps
      :current="current"
      type="navigation"
      size="small"
      :style="stepStyle"
      @change="onChange"
    >
      <!-- 非会议类学术项目申请 -->
      <a-step
        title="非会议类学术项目申请"
        :status="stepStatus.first"
        :disabled="stepStatus.isFirstDiabled"
      >
        <span
          slot="description"
          :class="[stepStatus.firstStatusTextColor]"
          class="flex items-center"
        >
          <icon-font
            v-if="stepStatus.firstStatusIcon"
            :type="stepStatus.firstStatusIcon"
            class="text-base mr-1"
          ></icon-font>
          <span>{{ stepStatus.firstStatusText }}</span>
        </span>
      </a-step>
      <!-- 非会议类学术项目合同 -->
      <a-step
        title="非会议类学术项目合同"
        :status="stepStatus.second"
        :disabled="stepStatus.isSecondDiabled"
      >
        <span
          slot="description"
          :class="[stepStatus.secondStatusTextColor]"
          class="flex items-center"
        >
          <icon-font
            v-if="stepStatus.secondStatusIcon"
            :type="stepStatus.secondStatusIcon"
            class="text-base mr-1"
          ></icon-font>
          <span>{{ stepStatus.secondStatusText }}</span>
        </span>
      </a-step>
      <!-- 非会议类学术项目总结 -->
      <a-step
        title="非会议类学术项目总结"
        :status="stepStatus.third"
        :disabled="stepStatus.isThirdDiabled"
      >
        <span
          slot="description"
          :class="[stepStatus.thirdStatusTextColor]"
          class="flex items-center"
        >
          <icon-font
            v-if="stepStatus.thirdStatusIcon"
            :type="stepStatus.thirdStatusIcon"
            class="text-base mr-1"
          ></icon-font>
          <span>{{ stepStatus.thirdStatusText }}</span>
        </span>
      </a-step>
    </a-steps>

    <div class="text-right" v-if="!$route.query.todo && type !== 'waitwork'">
      <a-button :disabled="isFlow" @click="save">保存</a-button>
      <a-button type="primary" class="ml-2" :disabled="isFlow" @click="submit">
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        @click="del"
      >
        删除
      </a-button>
      <a-divider type="vertical"></a-divider>
      <a-button :disabled="backDisabled" @click="getBack">撤回</a-button>
      <a-divider type="vertical"></a-divider>
      <a-button @click="print">打印</a-button>
      <a-divider type="vertical"></a-divider>
      <a-button @click="$router.go(-1)">返回</a-button>
    </div>

    <div>
      <component
        v-bind="$attrs"
        ref="comp"
        :is="curComp"
        :form="form"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
        :labelTextAreaCol="labelTextAreaCol"
        :wrapperTextAreaCol="wrapperTextAreaCol"
        @comp-flow-info="compFlowInfo"
      ></component>
    </div>

    <!-- 学术申请打印 -->
    <print-wrapper ref="printWrapper" v-show="printVisible">
      <meet-print :datas="form"></meet-print>
    </print-wrapper>
  </div>
</template>

<script>
// components
import FirstPart from './components/FirstPart.vue'
import SecondPart from './components/SecondPart.vue'
import ThirdPart from './components/ThirdPart.vue'
import MeetPrint from './components/MeetPrint.vue'
import PrintWrapper from 'vue-easy-print'
// api
import {
  getNonMeetingAppById,
  updateNonMeetingApp,
  saveNonMeetingApp,
  deleteNonMeetingAppById,
  submitNonMeetingApp,
  getOneByPartId
} from '@/api/acdemicActive/non-meeting'
import { getBillMsg } from '@/configs/bill-msg'
import { getOACancel2 } from '@/api/acdemicActive/acdemic-application'
import { isDisabled } from '@/utils/util'
import isEmpty from 'lodash/isEmpty'
export default {
  name: 'NonMeetForm',
  components: { FirstPart, SecondPart, ThirdPart, MeetPrint, PrintWrapper },
  props: {
    type: {
      type: String,
      default: ''
    },

    pid: {
      type: String,
      default: ''
    },

    summaryId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      // 打印
      printVisible: false,
      current: 0,
      curComp: 'FirstPart',
      curFlowInfo: {},
      curCompFlowInfo: { status: '1' },
      labelCol: { span: 20 },
      wrapperCol: { span: 20 },
      labelTextAreaCol: { span: 20 },
      wrapperTextAreaCol: { span: 24 },

      stepStyle: {
        marginBottom: '24px',
        boxShadow: '0px -1px 0 0 #e8e8e8 inset'
      },

      form: {},
      stepStatus: {
        // 可选： wait | process | finish | error
        first: 'wait',
        second: 'wait',
        third: 'wait',
        firstStatusText: '待提交',
        secondStatusText: '待提交',
        thirdStatusText: '待提交',
        firstStatusIcon: '',
        secondStatusIcon: '',
        thirdStatusIcon: '',
        firstStatusTextColor: '',
        secondStatusTextColor: '',
        thirdStatusTextColor: '',
        isFirstDiabled: true,
        isSecondDiabled: true,
        isThirdDiabled: true
      }
    }
  },

  computed: {
    showAvatar() {
      return this.$store.getters.protal
    },

    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.curCompFlowInfo ? this.curCompFlowInfo.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.curCompFlowInfo ? this.curCompFlowInfo.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    // 打印
    // printDisabled() {
    //   const status = this.form.flowStatus || '1'
    //   return status !== '0'
    // },

    getPid() {
      return this.pid || this.$route.query.pid
    },

    routeType() {
      return this.type || this.$route.query.type
    }
  },

  async created() {
    if (this.routeType === 'check') {
      this.form = await getNonMeetingAppById(this.getPid)
      this.form.firstFlowInfoDTO = this.form.firstFlowInfoDTO || {}
      this.form.secondFlowInfoDTO = this.form.secondFlowInfoDTO || {}
      this.form.thirdFlowInfoDTO = this.form.thirdFlowInfoDTO || {}
      // 设置步骤条状态
      this.setStepStatus()
      // 设置显示的组件
      this.setComp()
    }

    if (this.routeType === 'waitwork') {
      this.form = await getOneByPartId(this.pid, this.summaryId)
      this.form.firstFlowInfoDTO = this.form.firstFlowInfoDTO || {}
      this.form.secondFlowInfoDTO = this.form.secondFlowInfoDTO || {}
      this.form.thirdFlowInfoDTO = this.form.thirdFlowInfoDTO || {}
      // 设置步骤条状态
      this.setStepStatus()
      // 设置显示的组件
      this.setComp()
      // 想父组件提交状态信息
      this.$emit('status', this.form.flowStatus)
    }
  },

  methods: {
    onChange(current) {
      this.current = current
      switch (current) {
        case 1:
          this.curComp = 'SecondPart'
          break
        case 2:
          this.curComp = 'ThirdPart'
          break
        default:
          this.curComp = 'FirstPart'
          break
      }
    },

    // 保存
    save() {
      const saveApi = this.form.id ? updateNonMeetingApp : saveNonMeetingApp
      const submitData = this.$refs.comp.mergeData()
      saveApi(submitData, this.form.id).then(() => {
        this.$message.success('保存成功')
        //门户保存crm单据时，直接关闭窗口
        if (this.showAvatar) window.close()
        this.$router.replace({ name: 'nonMeetingApp' })
      })
    },

    submit() {
      const submitData = this.$refs.comp.mergeData(true)

      if (!submitData) return
      submitNonMeetingApp(submitData).then(() => {
        this.$message.success('提交成功')
        //门户保存crm单据时，直接关闭窗口
        if (this.showAvatar) window.close()
        this.$router.replace({ name: 'nonMeetingApp' })
      })
    },

    // 撤销
    getBack() {
      this.$confirm({
        title: '是否要撤销该非会议类学术项目申请？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'BILL_NON_MEETING_APP',
            this.curFlowInfo.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.$refs.comp.setBackStatus()
            this.curFlowInfo.status = '5'
            this.setStatusInfo()
          })
        },
        onCancel: () => {}
      })
    },

    // 删除
    del() {
      this.$confirm({
        title: '是否要删除该非会议类学术项目申请？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          deleteNonMeetingAppById(this.form.id).then(() => {
            this.$message.success('删除成功')
            if (this.showAvatar) window.close()
            this.$router.replace({ name: 'nonMeetingApp' })
          })
        },
        onCancel: () => {}
      })
    },

    // 当前流程状态
    compFlowInfo(flowInfo) {
      this.curCompFlowInfo = flowInfo
    },

    // 设置状态信息
    setStatusInfo() {
      switch (this.current) {
        case 0:
          this.setStepsStatusInfo(this.curFlowInfo.status, 'first')
          break
        case 1:
          this.setStepsStatusInfo(this.curFlowInfo.status, 'second')
          break
        case 2:
          this.setStepsStatusInfo(this.curFlowInfo.status, 'third')
          break
      }
    },

    setStepsStatusInfo(status, type) {
      const statusInfo = getBillMsg(status)
      this.stepStatus[`${type}StatusText`] = statusInfo.text
      this.stepStatus[`${type}StatusIcon`] = statusInfo.icon
      this.stepStatus[`${type}StatusTextColor`] = statusInfo.color
    },

    // 设置步骤条状态
    setStepStatus() {
      const {
        appPartId,
        firstFlowInfoDTO,
        secondFlowInfoDTO,
        thirdFlowInfoDTO
      } = this.form
      // 没有证明还未提交流程
      if (!appPartId) return

      // 第三流程
      if (!isEmpty(thirdFlowInfoDTO)) {
        // 一二流程都完成了，才有可能第三流程
        this.curFlowInfo = thirdFlowInfoDTO // 流程信息
        this.stepStatus.first = 'finish'
        this.stepStatus.second = 'finish'
        this.stepStatus.isFirstDiabled = false
        this.stepStatus.isSecondDiabled = false
        this.stepStatus.isThirdDiabled = false
        this.setStepsStatusInfo(firstFlowInfoDTO.status, 'first')
        this.setStepsStatusInfo(secondFlowInfoDTO.status, 'second')
        this.setStepsStatusInfo(thirdFlowInfoDTO.status, 'third')
        // 流程结束
        if (thirdFlowInfoDTO.status === '0') {
          this.stepStatus.third = 'finish'
        } else if (thirdFlowInfoDTO.status === '15') {
          // 流程废除
          this.stepStatus.third = 'error'
        } else {
          this.stepStatus.third = 'process'
        }

        return
      }

      // 第二流程
      if (!isEmpty(secondFlowInfoDTO)) {
        // 一流程都完成了，才有可能第二流程
        this.curFlowInfo = secondFlowInfoDTO // 流程信息
        this.stepStatus.first = 'finish'
        this.stepStatus.isFirstDiabled = false
        this.stepStatus.isSecondDiabled = false
        this.setStepsStatusInfo(firstFlowInfoDTO.status, 'first')
        this.setStepsStatusInfo(secondFlowInfoDTO.status, 'second')
        // 流程结束
        if (secondFlowInfoDTO.status === '0') {
          this.stepStatus.second = 'finish'
        } else if (secondFlowInfoDTO.status === '15') {
          // 流程废除
          this.stepStatus.second = 'error'
        } else {
          this.stepStatus.second = 'process'
        }

        return
      }

      // 第一流程
      if (!isEmpty(firstFlowInfoDTO)) {
        this.curFlowInfo = firstFlowInfoDTO // 流程信息
        this.setStepsStatusInfo(firstFlowInfoDTO.status, 'first')
        // 流程结束
        if (firstFlowInfoDTO.status === '0') {
          this.stepStatus.first = 'finish'
          this.stepStatus.isFirstDiabled = false
        } else if (firstFlowInfoDTO.status === '15') {
          // 流程废除
          this.stepStatus.first = 'error'
          this.stepStatus.isFirstDiabled = false
        } else {
          this.stepStatus.first = 'process'
        }
      }
    },

    // 设置当前组件
    setComp() {
      const { firstFlowInfoDTO, secondFlowInfoDTO, thirdFlowInfoDTO } =
        this.form

      if (firstFlowInfoDTO) {
        this.current = 0
        this.curComp = 'FirstPart'
      }

      if (
        (firstFlowInfoDTO?.status === '0' && this.routeType !== 'waitwork') ||
        !isEmpty(secondFlowInfoDTO)
      ) {
        this.current = 1
        this.curComp = 'SecondPart'
        this.stepStatus.isSecondDiabled = false
      }

      if (
        (secondFlowInfoDTO?.status === '0' && this.routeType !== 'waitwork') ||
        !isEmpty(thirdFlowInfoDTO)
      ) {
        this.current = 2
        this.curComp = 'ThirdPart'
        this.stepStatus.isThirdDiabled = false
      }
    },

    // 打印
    print() {
      this.printVisible = true
      this.$nextTick(() => this.$refs['printWrapper'].print())
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
/deep/.ant-form-item-label {
  line-height: 24px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
}
</style>
