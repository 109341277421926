var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-4",staticStyle:{"padding-left":"32px"}},[_c('a-form',{attrs:{"size":"small","laba-width":"90px","laba-position":"right","layout":"vertical"}},[_c('a-collapse',{attrs:{"bordered":false},scopedSlots:_vm._u([{key:"expandIcon",fn:function(props){return [_c('a-icon',{attrs:{"type":"caret-right","rotate":props.isActive ? 90 : 0}})]}}]),model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_vm._l((_vm.baseMsg),function(ref){
var title = ref.title;
var active = ref.active;
var children = ref.children;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},_vm._l((children),function(ref){
var label = ref.label;
var value = ref.value;
return _c('a-col',{key:label,attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":label,"prop":value,"label-col":_vm.itemlay.labelCol,"wrapper-col":_vm.itemlay.wrapperCol}},[(!value)?_c('a-input',{attrs:{"disabled":true,"value":"维护次月目标医生 "}}):(value === 'status')?_c('a-input',{attrs:{"disabled":true,"value":_vm.getStatusText(_vm.formData[value])}}):_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.formData[value]),callback:function ($$v) {_vm.$set(_vm.formData, value, $$v)},expression:"formData[value]"}})],1)],1)}),1)}),_vm._l((_vm.tableMsg),function(ref){
var title = ref.title;
var active = ref.active;
var parantList = ref.parantList;
var dataList = ref.dataList;
var columns = ref.columns;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},_vm._l((_vm.formData[parantList]),function(item){return _c('div',{key:item.doctorId,staticClass:"mb-8"},[_c('div',{staticClass:"app-title"},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(item['doctorName']))]),_c('span',{staticClass:"mx-4"},[_vm._v(_vm._s(item['section']))]),_c('span',[_vm._v(_vm._s(item['hospitalName']))])]),_c('div',{staticClass:"p-4 border"},[_c('a-table',{attrs:{"rowKey":"productId","dataSource":item[dataList],"columns":columns,"pagination":false,"rowClassName":function (record, index) {
                    return index % 2 === 0 ? 'trBgClass' : ''
                  }},scopedSlots:_vm._u([{key:"bgType",fn:function(type){return _c('span',{style:({ color: _vm.getBgTypeColor(type) })},[_vm._v(" "+_vm._s(_vm.getBgTypeText(type))+" ")])}},{key:"question",fn:function(questionnaireDTOS, record){return _c('span',{},[_c('a',{staticStyle:{"color":"#4587f5"},on:{"click":function($event){return _vm.handleChooseQuestion(questionnaireDTOS, record)}}},[_vm._v(" 查看问卷 ")])])}},{key:"agreeUnbind",fn:function(agreeUnbind){return _c('span',{},[_c('span',{style:({ color: _vm.getAgreeUnbindColor(agreeUnbind) })},[_vm._v(" "+_vm._s(_vm.getAgreeUnbindText(agreeUnbind))+" ")])])}}],null,true)})],1)])}),0)})],2),(_vm.formData.flowInfoDTO)?_c('process',{attrs:{"summaryid":_vm.formData.flowInfoDTO.summaryid,"nextapproveman":_vm.formData.flowInfoDTO.nextapproveman,"curApprover":_vm.formData.flowInfoDTO.curApprover}}):_vm._e(),_c('comment',{attrs:{"billId":_vm.formData.id}})],1)],1),(_vm.showModal)?_c('modal',{attrs:{"title":"问卷详情","width":"640px","closable":true,"footer":null},on:{"close":function($event){_vm.showModal = false}}},[_c('question',{attrs:{"productLine":_vm.productLine,"questionList":_vm.questionList}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }