const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '申请类型', value: `` },
      { label: '申请状态', value: `status` },
      { label: '创建时间', value: `createTime` },
      { label: '创建人', value: `creatorName` },
      { label: '修改时间', value: `modifyTime` },
      { label: '修改人', value: `modifierName` }
    ]
  }
]
const TABLE = [
  {
    title: '常规申请',
    active: 'CGSQ',
    parantList: 'bodyDTOList',
    dataList: 'targetDoctorProductDTOS',
    columns: [
      {
        title: '变更类型',
        dataIndex: 'type',
        scopedSlots: {
          customRender: 'bgType'
        }
      },
      { title: '产品名称', dataIndex: 'productName' },
      { title: '医生分级', dataIndex: 'doctorGrade' },
      {
        title: '答卷信息',
        dataIndex: 'questionnaireDTOS',
        scopedSlots: {
          customRender: 'question'
        }
      }
    ]
  },
  {
    title: '解绑申请',
    active: 'JBSQ',
    parantList: 'unbindDTOList',
    dataList: 'targetDoctorProductDTOS',
    columns: [
      { title: '被解绑用户', dataIndex: 'oldPostUsername' },
      { title: '被解绑用户岗位', dataIndex: 'oldPostName' },
      { title: '产品名称', dataIndex: 'productName' },
      { title: '医生分级', dataIndex: 'doctorGrade' },
      {
        title: '答卷信息',
        dataIndex: 'questionnaireDTOS',
        scopedSlots: {
          customRender: 'question'
        }
      },
      {
        title: '解绑信息',
        dataIndex: 'agreeUnbind',
        scopedSlots: {
          customRender: 'agreeUnbind'
        }
      }
    ]
  }
]

export { BASE, TABLE }
