<template>
  <div>
    <section class="page">
      <h3 class="pt-4 text-center text-xl font-normal">{{ title }}</h3>
      <div class="print-main px-auto">
        <div id="qrcodebox" class="mt-0 mx-auto"></div>
        <div class="text-sm mt-4">
          {{ datas.billCode || '无效二维码，未生成单据' }}
        </div>
        <div class="mt-10">此为正面,请将票据贴在背面</div>
        <div class="text-base mt-6 w-full flex-1 content-center flex-no-wrap">
          <span class="w-1/5 mr-12">制单人:{{ name }}</span>
          <span class="w-1/5">经办人:{{ name }}</span>
        </div>
      </div>
      <div class="divide pt-1">请沿此线剪开</div>
    </section>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  name: 'QrCode',
  props: {
    title: String,
    datas: {
      type: Object
    },
    name: String
  },
  data() {
    return {
      qrcode: null
    }
  },
  watch: {
    datas: {
      handler() {
        const { billCode } = this.datas
        this.$nextTick(() => {
          this.qrcode.clear()
          this.qrcode.makeCode(billCode)
        })
      },
      immediate: true
    }
  },
  mounted() {
    this.qrcode = new QRCode('qrcodebox', {
      width: 100, //二维码的宽度
      height: 100, //二维码的高度
      text: this.datas.billCode, // 二维码地址
      colorDark: '#000', //二维码颜色
      colorLight: '#fff', //二维码背景颜色
      correctLevel: QRCode.CorrectLevel.H
    })
  }
}
</script>

<style lang="less" scoped>
@page {
  size: auto A4;
  margin: 16px 0 16px 0;
}

.page {
  position: relative;
  width: 21cm;
  overflow: hidden;
  padding-left: 1cm;
  padding-right: 1cm;
}

.divide {
  position: fixed;
  left: -1cm;
  width: 23cm;
  top: 12.6cm;
  height: 1px;
  border-top: 1px dashed #000;
  font-size: 12px;
  transform: scale(0.875);
  text-align: center;
}
.print-main {
  text-align: center;
  font-size: 22;
  font-weight: nomal;
  margin-top: 2rem;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
