<template>
  <div class="text-xs">
    <partner :pid="pid" @status="nowStatus($event)"></partner>
  </div>
</template>

<script>
import Partner from './partner/PARTNER'

export default {
  name: 'PARTNERBill',
  components: {
    Partner
  },
  props: {
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
