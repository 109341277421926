import { message } from 'ant-design-vue'

export default {
  methods: {
    verityAppForm(isSubmit) {
      if (isSubmit === 'save') return true

      const canVerityFormItem = [
        ...(this.formUserList || []),
        ...(this.formBaseList || []),
        ...(this.formBaseTextAreaList || []),
        ...(this.formAccountList || []),
        ...(this.formPayList || [])
      ].filter(item => {
        if (typeof item.disabled === 'boolean') {
          return !item.disabled
        } else {
          return !item.disabled()
        }
      })

      // 用户信息、预租房基本信息
      for (const formItem of canVerityFormItem) {
        if (formItem.show && !formItem.show()) continue

        if (!this.form[formItem.prop] && this.form[formItem.prop] != '0') {
          this.$message.error(`${formItem.label}不能为空`)
          return false
        }
      }

      // 特殊审批校验
      if (
        !isSubmit &&
        this.form.flowInfoDO &&
        this.form.flowInfoDO.nodePolicy === '特殊审批'
      ) {
        // 报销凭证
        for (const formItem of this.formReimList) {
          if (formItem.show && !formItem.show()) continue

          if (!this.form[formItem.prop] && this.form[formItem.prop] != '0') {
            this.$message.error(`${formItem.label}不能为空`)
            return false
          }
        }

        // 报销凭证附件
        for (const formFileItem of this.formReimFileList) {
          if (
            formFileItem.disabled &&
            formFileItem.show &&
            !formFileItem.show()
          )
            continue

          if (formFileItem.notVerity) continue

          if (
            !this.form[formFileItem.prop] ||
            this.form[formFileItem.prop] === '[]'
          ) {
            this.$message.error(`请上传${formFileItem.label}附件`)
            return false
          }
        }
      }

      return true
    }
  }
}

/**
 * 待办审批的时候校验
 * @param {Object} form
 * @returns
 */
export const verityAppTodoForm = form => {
  if (form.flowInfoDO && form.flowInfoDO.nodePolicy === '特殊审批') {
    if (!form.rentalAddress) {
      message.error(
        '请完善报销凭证信息, 菜单位置：房产管理-药连锁办事处用房租赁申请, 打开对应单据填写'
      )
      return false
    }

    const formReimList = [
      { label: '租赁地址', prop: 'rentalAddress' },
      { label: '承租方', prop: 'tenant' },
      { label: '承租经办人', prop: 'operator' },
      { label: '房屋产权所有人', prop: 'houseOwner' },
      { label: '合同期限（月）', prop: 'contractTerm' },
      { label: '合同开始日期', prop: 'contractStartDate' },
      { label: '合同结束时间', prop: 'contractEndDate' }
    ]
    const formReimFileList = [
      { label: '租赁合同', prop: 'rentalContractId' },
      { label: '房产证复印件', prop: 'housePropertyId' },
      { label: '房东身份证', prop: 'houseOwnerIDCardId' }
    ]

    // 报销凭证
    for (const formItem of formReimList) {
      if (!form[formItem.prop] && form[formItem.prop] != '0') {
        message.error(`${formItem.label}不能为空`)
        return false
      }
    }

    // 报销凭证附件
    for (const formFileItem of formReimFileList) {
      if (!form[formFileItem.prop] || form[formFileItem.prop] === '[]') {
        message.error(`请上传${formFileItem.label}附件`)
        return false
      }
    }
  }

  return true
}
