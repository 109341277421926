<template>
  <div>
    <section class="page">
      <h3 class="pt-4 text-center text-xl font-normal">{{ title }}</h3>
      <barcode
        :value="barCodeMsg.value"
        :width="barCodeMsg.width"
        :height="barCodeMsg.height"
        :fontSize="barCodeMsg.fontSize"
        :textAlign="barCodeMsg.textAlign"
      >
        未提交单据，条形码显示错误！
      </barcode>
      <table class="table-header">
        <tr>
          <td class="label">单据号: {{ datas.billCode }}</td>
          <td class="label">基地: 总部</td>
          <td class="label">制单人: {{ name }}</td>
          <td class="label">制单日期: {{ datas.makeTime }}</td>
        </tr>
      </table>

      <table class="table-main">
        <tr>
          <td colspan="1" class="label">房产编号</td>
          <td colspan="2" class="label-val">{{ datas.houseCode }}</td>
          <td colspan="1" class="label">房产类型</td>
          <td colspan="2" class="label-val">
            {{ datas.houseTypeName }}
          </td>
          <td colspan="1" class="label">使用类型</td>
          <td colspan="1" class="label-val">
            {{ datas.useTypeName }}
          </td>
        </tr>
        <tr>
          <td colspan="1" class="label">房产管理部门</td>
          <td colspan="3" class="label-val">{{ datas.houseDeptName }}</td>
          <td colspan="1" class="label">房产地址</td>
          <td colspan="3" class="label-val">
            {{ datas.address }}
          </td>
        </tr>
        <tr>
          <td colspan="1" class="label">报销人</td>
          <td colspan="3" class="label-val">{{ datas.receName }}</td>
          <td colspan="1" class="label">结算公司</td>
          <td colspan="3" class="label-val">
            {{ datas.payName }}
          </td>
        </tr>
        <tr>
          <td colspan="1">银行账号</td>
          <td colspan="3">{{ datas.receAccNum }}</td>
          <td colspan="1">开户行</td>
          <td colspan="3">{{ datas.receBankName }}</td>
        </tr>
        <tr>
          <td colspan="1">银行行号</td>
          <td colspan="7">{{ datas.receCommbineNum }}</td>
        </tr>
        <tr>
          <td colspan="1">费用类型</td>
          <td colspan="1">月份</td>
          <td colspan="2">金额</td>
          <td colspan="4">备注说明</td>
        </tr>
        <tr v-for="item in datas.housePaymentBodys || []" :key="item.bid">
          <td colspan="1">{{ item.houseFeeTypeName }}</td>
          <td colspan="1">{{ item.reimMonth }}</td>
          <td colspan="2">{{ item.amount }}</td>
          <td colspan="4">{{ item.vnote }}</td>
        </tr>
        <tr>
          <td colspan="1"></td>
          <td colspan="1"></td>
          <td colspan="2"></td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colspan="1"></td>
          <td colspan="1"></td>
          <td colspan="2"></td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colspan="1"></td>
          <td colspan="1"></td>
          <td colspan="2"></td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td colspan="1">合计金额大写</td>
          <td colspan="3">{{ upper }}</td>
          <td colspan="1">人民币</td>
          <td colspan="3">{{ datas.applyMoney }}元</td>
        </tr>
        <tr>
          <td colspan="1">备注</td>
          <td colspan="7">{{ datas.note }}</td>
        </tr>

        <tr>
          <td class="label">部门主管</td>
          <td class="label">
            {{ auditInfo ? auditInfo.deptManger || '' : '' }}
          </td>
          <td class="label">领导审批</td>
          <td class="label">{{ auditInfo ? auditInfo.leader || '' : '' }}</td>
          <td class="label">会计</td>
          <td class="label">
            {{ auditInfo ? auditInfo.accountant || '' : '' }}
          </td>
          <td class="label">财务主管</td>
          <td class="label">
            {{ auditInfo ? auditInfo.financeOffer || '' : '' }}
          </td>
        </tr>
      </table>

      <div class="divide pt-1">请沿此线剪开</div>
    </section>
  </div>
</template>

<script>
import Barcode from 'vue-barcode'
import { capitalAmount } from '@/utils/util'
export default {
  name: 'DsfkPrint',
  components: {
    Barcode
  },
  props: {
    title: String,
    datas: Object,
    auditInfo: Object,
    name: String
  },
  watch: {
    datas: {
      handler(nval) {
        this.barCodeMsg.value = nval.billCode
      },
      deep: true
    }
  },
  data() {
    return {
      barCodeMsg: {
        value: this.datas.billCode,
        width: 1,
        height: 40,
        fontSize: 14,
        textAlign: 'left'
      }
    }
  },
  computed: {
    upper() {
      if (!this.datas.applyMoney) return '零'
      return this.capitalAmount(this.datas.applyMoney)
    }
  },
  methods: {
    capitalAmount
  }
}
</script>

<style lang="less" scoped>
@page {
  size: auto A4;
  margin: 16px 0 16px 0;
}

.page {
  position: relative;
  width: 21cm;
  overflow: hidden;
  padding-left: 1cm;
  padding-right: 1cm;
}
table {
  width: 100%;
  table-layout: fixed;
  & td {
    border: 1px solid #000;
    text-align: center;
    height: 0.5cm;
    font-size: 12px;
    & .label {
      width: 12.5%;
    }
    & .label-val {
      width: 37.5%;
    }
  }
}

.table-header td {
  border: 0px solid #000;
  text-align: left;
}

.divide {
  position: fixed;
  left: -1cm;
  width: 23cm;
  top: 12.6cm;
  height: 1px;
  border-top: 1px dashed #000;
  font-size: 12px;
  transform: scale(0.875);
  text-align: center;
}
</style>
