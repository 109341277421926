<template>
  <div>
    <a-table
      :columns="getColumns"
      :rowKey="record => record.pk"
      :dataSource="billList"
      :pagination="false"
      :loading="loading"
      :components="components"
      @change="getList"
      :scroll="total >= 10 ? { y: 405 } : {}"
      :rowSelection="
        chooseValue === '2'
          ? {
              columnWidth: '36px',
              onChange: onSelectChange,
              type: 'radio',
              selectedRowKeys: selectedRowKeys
            }
          : null
      "
      :customRow="
        record => {
          return {
            on: {
              dblclick: () => {
                openModal(record)
              },
              click: () => {
                openModal(record)
              }
            }
          }
        }
      "
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column
        }"
        class="custom-filter-dropdown"
      >
        <a-input
          v-ant-ref="c => (searchInput = c)"
          placeholder="查询待办标题"
          :value="selectedKeys[0]"
          ref="input"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm, column)"
          style="width: 180px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          @click="() => handleSearch(selectedKeys, confirm)"
          icon="search"
          style="width: 80px; margin-right: 12px"
        >
          查询
        </a-button>
        <a-button
          @click="() => handleReset(clearFilters, column)"
          style="width: 80px"
        >
          重置
        </a-button>
      </div>

      <a-icon
        slot="filterIcon"
        type="search"
        :style="{ color: searchText ? '#4587f5' : undefined }"
      />

      <template slot="subjectItem" slot-scope="subject, record">
        <a @click="openModal(record)">
          {{ subject }}
        </a>
      </template>
      <template slot="dateItem" slot-scope="date">
        <span>{{ getTime(date) }}</span>
      </template>
      <template slot="receiveTimeItem" slot-scope="receiveTime">
        <span>
          {{ receiveTime === null ? '无' : getTime(receiveTime) }}
        </span>
      </template>
      <template slot="billtypeItem" slot-scope="billtype">
        <span>
          {{ getBillTypeName(billtype) }}
        </span>
      </template>
      <template slot="sendnameItem" slot-scope="curname">
        <span>
          {{ curname || '无' }}
        </span>
      </template>
      <span slot="status" slot-scope="text">
        <a-badge
          :status="text | statusTypeFilter"
          :text="text | statusFilter"
        />
      </span>
    </a-table>
  </div>
</template>

<script>
import { getHandle } from '@/api/acdemicActive/acdemic-application'
import { NOT_ACTIVE_COLUMNS } from '@/configs/bill-config'
import { getResizeableTitle } from '@/utils/table'
import {
  getListProxyWaitWork,
  getListDoneWaitWork
} from '@/api/acdemicActive/workplace'
import moment from 'moment'

const statusMap = {
  0: { status: 'success', text: '审批通过' },
  1: { status: 'default', text: '待发' },
  3: { status: 'processing', text: '待处理' },
  4: { status: 'warning', text: '处理中' },
  5: { status: 'default', text: '撤销' },
  15: { status: 'error', text: '终止' },
  98: { status: 'error', text: '回退' },
  99: { status: 'warning', text: '作废' },
  96: { status: 'processing', text: '挂起' }
}

export default {
  name: 'ProxyList',

  props: {
    chooseValue: {
      type: String
    }
  },

  data() {
    this.components = getResizeableTitle(NOT_ACTIVE_COLUMNS)
    return {
      column: NOT_ACTIVE_COLUMNS,
      loading: false,
      total: 0,
      searchText: '',
      content: '',
      billList: [],
      selectedRowKeys: []
    }
  },

  computed: {
    getColumns() {
      return NOT_ACTIVE_COLUMNS
    }
  },

  watch: {
    chooseValue(nval) {
      if (nval) this.getList()
    }
  },

  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].status
    }
  },

  created() {
    this.getList()
  },

  methods: {
    moment,

    getBillTypeName(type) {
      if (type === 'BILL_NON_MEETING_APP') return '非会议类学术项目'
    },

    /**
     * 转换时间
     * @param {String} date //时间戳
     */

    getTime(date) {
      return moment(parseInt(date)).format('YYYY-MM-DD HH:mm:ss')
    },

    // 取回的单据
    onSelectChange(value) {
      this.selectedRowKeys = value
    },

    // 标题关键字搜索
    handleSearch(selectedKeys, confirm) {
      if (selectedKeys[0]) {
        this.content = selectedKeys[0]
      } else {
        this.content = ''
      }

      confirm()
      this.searchText = selectedKeys[0]
    },

    // 重置标题关键字搜索
    handleReset(clearFilters) {
      this.content = ''
      this.searchText = ''
      clearFilters()
    },

    getList() {
      this.loading = true
      if (this.chooseValue === '1') this.getWaitWorkList()
      if (this.chooseValue === '2') this.getDoneWorkList()
    },

    /**
     * 待审批列表
     */
    getWaitWorkList() {
      getListProxyWaitWork(1, 50, this.content)
        .then(res => {
          this.billList = res.datas
          this.total = res.totalNum
        })
        .finally(() => (this.loading = false))
    },

    /**
     * 待审批列表
     */
    getDoneWorkList() {
      getListDoneWaitWork(1, 50, this.content)
        .then(res => {
          this.billList = res.datas
          this.total = res.totalNum
        })
        .finally(() => (this.loading = false))
    },

    // 取回单据
    handldConfirm() {
      if (this.selectedRowKeys && this.selectedRowKeys.length) {
        const billId = this.selectedRowKeys[0]
        const target = this.billList.find(item => billId === item.pk)
        if (target.state === '0')
          return this.$message.warning('单据已审批通过,无法取回')
        else {
          target.optype = '7'
          getHandle(target).then(() => {
            this.getList()
            this.$message.success('取回成功')
          })
        }
      } else {
        this.$message.warning('请选择要取回的单据')
      }
    },

    openModal(record) {
      this.$emit('open-modal', record)
    }
  }
}
</script>

<style lang="less" scoped></style>
