<template>
  <a-select
    style="width: 100%"
    :disabled="isDisabled"
    :value="record[column.dataIndex]"
    @change="onSelectChange($event, record, column)"
  >
    <a-select-option
      v-for="{ text, value } in column.list"
      :key="value"
      :value="value"
    >
      {{ text }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'InputCell',
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    column: {
      type: Object,
      default: () => ({})
    },
    isDisabled: Boolean
  },

  methods: {
    onSelectChange(key, record, column) {
      record[column.dataIndex] = key
    }
  }
}
</script>

<style lang="less" scoped></style>
