import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 根据id获取非会议项目详情
 * @param {String} id
 */
export function getNonMeetingAppById(id) {
  return request({
    method: 'get',
    url: `nonMeetingApp/${id}`
  })
}

/**
 * 审批时查询
 * @param {String} partId
 * @param {String} summaryId
 * @returns
 */
export function getOneByPartId(partId, summaryId) {
  return request({
    method: 'get',
    url: `nonMeetingApp/getOneByPartId`,
    params: { partId, summaryId }
  })
}

/**
 * 更新非会议类项目申请信息
 */
export function updateNonMeetingApp(data, id) {
  return request({
    method: 'put',
    url: `nonMeetingApp/${id}`,
    data
  })
}

/**
 * 保存非会议类项目申请信息
 */
export function saveNonMeetingApp(data) {
  return request({
    method: 'post',
    url: `nonMeetingApp`,
    data
  })
}

/**
 * 提交非会议类项目申请信息
 * @param {Object} data
 * @returns
 */
export function submitNonMeetingApp(data) {
  return request({
    method: 'put',
    url: `oaflow/submitNonMeetingApp`,
    data
  })
}

/**
 * 删除非会议类项目申请信息
 */
export function deleteNonMeetingAppById(id) {
  return request({
    method: 'delete',
    url: `nonMeetingApp/${id}`
  })
}

/**
 * 获取非会议类项目申请详情
 * @param {Number} pageindex
 * @param {Number} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getNonMeetingAppList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `nonMeetingApp/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 查询模块
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getQueryList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `nonMeetingApp/getQueryList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 *导出csv信息请求（返回文件id）
 * @param {Array} fileId 文件id
 * @param {String} billName 导出的单据名
 */
export function getExportCsv(conditons) {
  return request({
    method: 'post',
    url: 'nonMeetingApp/export',
    responseType: 'arraybuffer',
    data: conditons
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res, true)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    if (!data.resultCode) {
      downLoadFile(data, 'csv', `非会议学术项目申请表导出`)
      return
    }

    return data.resultCode
  })
}

/**
 * 获取用户信息
 * @param {String} id
 * @returns
 */
export function getUserInfoById(id) {
  return request({
    method: 'get',
    url: `nonMeetingApp/getUserInfo/${id}`
  })
}
