<template>
  <div class="text-xs">
    <Mbyysq
      :type="type"
      :pid="pid"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></Mbyysq>
  </div>
</template>

<script>
import Mbyysq from './mbyysq/MBYYSQ'

export default {
  name: 'MBYYSQBill',
  components: {
    Mbyysq
  },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
