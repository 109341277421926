const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '单据号', value: `vbillno` },
      { label: '业务日期', value: `vbilldate` },
      { label: '业务员', value: `clerkName` },
      { label: '新零售责任人', value: 'personRespName' },
      { label: '备注', value: 'remark', span: 18 }
    ]
  }
]
const TABLE = [
  {
    title: '信息列表',
    active: 'TABLE',
    dataList: 'bodyDtos',
    columns: [
      { title: '行号', dataIndex: 'rowd', width: 48 },
      {
        title: '核心连锁',
        dataIndex: 'coreChain',
        width: 64,
        scopedSlots: { customRender: 'coreChain' }
      },
      {
        title: '终端编码',
        dataIndex: 'terminalCode',
        ellipsis: true,
        width: 120
      },
      {
        title: '终端名称',
        dataIndex: 'terminalName',
        ellipsis: true,
        width: 120
      },
      {
        title: '品种编码',
        dataIndex: 'standardProdCode',
        ellipsis: true,
        width: 120
      },
      {
        title: '品种名称',
        dataIndex: 'standardProdName',
        ellipsis: true,
        width: 120
      },
      {
        title: '规格',
        dataIndex: 'standardProdSpec',
        ellipsis: true,
        width: 120
      },
      { title: '开票价', dataIndex: 'priceintax', width: 72 },
      { title: '单盒费用', dataIndex: 'perboxCost', width: 72 },
      { title: '二次议价', dataIndex: 'twiceCost', width: 72 },
      {
        title: '单盒开发奖金',
        width: 72,
        dataIndex: 'bonus'
      },
      {
        title: '生效日期',
        dataIndex: 'effectiveMonth',
        ellipsis: true,
        width: 72
      },
      {
        title: '失效日期',
        dataIndex: 'expireMonth',
        ellipsis: true,
        width: 72
      }
    ]
  }
]

export { BASE, TABLE }
