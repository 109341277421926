export function resetDrag() {
  const dragDom = document.querySelector('body .ant-modal-content')
  if (dragDom) {
    dragDom.style.left = ''
    dragDom.style.top = ''
  }
}

export function Drag(Selector) {
  //获取弹框头部（这部分可双击全屏）
  document.querySelector(`body .ant-modal-wrap${Selector}`).style.overflow =
    'hidden'
  //弹窗
  const dragDom = Selector
    ? document.querySelector(`body ${Selector} .ant-modal-content`)
    : document.querySelector('body .ant-modal-content')

  const dialogHeaderEl = Selector
    ? document.querySelector(`body ${Selector} .ant-modal-header`)
    : document.querySelector('body .ant-modal-header')

  //给弹窗加上overflow auto；不然缩小时框内的标签可能超出dialog；

  //清除选择头部文字效果

  dialogHeaderEl.onselectstart = new Function('return false')

  //头部加上可拖动cursor

  dialogHeaderEl.style.cursor = 'move'

  // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);

  const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)

  let moveDown = e => {
    // 鼠标按下，计算当前元素距离可视区的距离
    const disX = e.clientX - dialogHeaderEl.offsetLeft

    const disY = e.clientY - dialogHeaderEl.offsetTop

    // 获取到的值带px 正则匹配替换

    let styL, styT

    // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px

    if (sty.left.includes('%')) {
      styL = +document.body.clientWidth * (+sty.left.replace(/\\%/g, '') / 100)

      styT = +document.body.clientHeight * (+sty.top.replace(/\\%/g, '') / 100)
    } else {
      styL = +sty.left.replace(/\px/g, '')
      styT = +sty.top.replace(/\px/g, '')
    }

    document.onmousemove = function (e) {
      // 通过事件委托，计算移动的距离

      const l = e.clientX - disX

      const t = e.clientY - disY

      // 移动当前元素

      dragDom.style.left = `${l + styL}px`

      dragDom.style.top = `${t + styT}px`
    }

    document.onmouseup = function () {
      document.onmousemove = null

      document.onmouseup = null
    }
  }

  dialogHeaderEl.onmousedown = moveDown

  dragDom.onmousemove = function (e) {
    if (
      e.clientX > dragDom.offsetLeft + dragDom.clientWidth - 22 ||
      dragDom.offsetLeft + 22 > e.clientX
    ) {
      dragDom.style.left = dragDom.offsetLeft
    } else if (
      dragDom.scrollTop + e.clientY >
      dragDom.offsetTop + dragDom.clientHeight - 22
    ) {
      dragDom.style.cursor = 's-resize'
    } else {
      dragDom.style.cursor = 'default'
      dragDom.onmousedown = null
    }
  }
}
