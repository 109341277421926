import request from '@/utils/request'
// /**
//  * 文件上传
//  * @param {File} file
//  */
// export function getUpload(file, pid = '') {
//   return request({
//     method: 'post',
//     url: 'uploadBillsForPayment/upload',
//     params: pid ? { pid } : {},
//     data: file
//   })
// }

// /**
//  * 批量文件上传
//  * @param {Array[file]} files
//  */
// export function getBatch(files, pid = '') {
//   return request({
//     method: 'post',
//     url: 'uploadBillsForPayment/batchUpload',
//     params: pid ? { pid } : {},
//     data: files
//   })
// }

// /**
//  * 删除附件id
//  * @param {String} id
//  */
// export function getDeleteFile(id) {
//   return request({
//     method: 'delete',
//     url: `uploadBillsForPayment/${id}`
//   })
// }

// export function getFileById(id) {
//   return request({
//     method: 'get',
//     url: `uploadBillsForPayment/${id}`
//   })
// }

// /**
//  *批量下载的文件id
//  * @param {String} name
//  * @param {Array} ids
//  */
// export function getDownloadFiles(name, ids) {
//   return request({
//     method: 'post',
//     url: `uploadBillsForPayment/batchDownload`,
//     data: ids,
//     responseType: 'blob'
//   }).then(res => {
//     if (res.data.type === 'application/json') {
//       message.error('导出失败!')
//       return
//     }
//     const link = document.createElement('a')
//     let blob = new Blob([res.data], { type: 'application/zip' })
//     link.style.display = 'none'
//     link.href = URL.createObjectURL(blob)
//     link.setAttribute('download', `${name}附件` + '.zip')
//     document.body.appendChild(link)
//     link.click()
//     document.body.removeChild(link)
//   })
// }

// /**
//  * 批量删除附件
//  * @param {Array} ids
//  */
// export function getDeleteFiles(ids) {
//   return request({
//     method: 'post',
//     url: `uploadBillsForPayment/multiFiles`,
//     data: ids
//   })
// }

// /**
//  * 获取 pdf 文件的 url
//  * @params {String} id 要打开文件的 id
//  * @returns {String} pdf 的路径
//  */
// export function getPDFURL(id) {
//   return request({
//     method: 'get',
//     url: `uploadBillsForPayment/preView/${id}`,
//     responseType: 'blob'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `application/pdf` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// /**
//  * 获取 img 文件的 url
//  * @param {String} id 要打开文件的id
//  */
// export function getIMGURL(id) {
//   return request({
//     method: 'get',
//     url: `uploadBillsForPayment/preView/${id}`,
//     responseType: 'arraybuffer'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `image/png` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// /**
//  * 获取 EXCEL文件的 url
//  * @param {String} id 要打开文件的id
//  */
// export function getEXCELURL(id) {
//   return request({
//     method: 'get',
//     url: `uploadBillsForPayment/preView/${id}`,
//     responseType: 'blob'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `application/vnd.ms-excel` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// /**
//  * 获取 word文件的 url
//  * @param {String} id 要打开文件的id
//  */
// export function getWORDURL(id) {
//   return request({
//     method: 'get',
//     url: `uploadBillsForPayment/preView/${id}`,
//     responseType: 'blob'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `application/msword` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// /**
//  * 预览文件(包括word/excel等)
//  * @param {String} id
//  */
// export function preViewTransformFile(id) {
//   return request({
//     method: 'get',
//     url: `uploadBillsForPayment/preViewTransformFile`,
//     params: { id },
//     responseType: 'blob'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `application/pdf` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// 采用mongodb
/**
 * 文件上传
 * @param {File} file
 */
export function getUpload(file, pid = '') {
  return request({
    method: 'post',
    url: 'nUploadForPayment/upload',
    params: pid ? { pid } : {},
    data: file
  })
}

/**
 * 批量文件上传
 * @param {Array[file]} files
 */
export function getBatch(files, pid = '') {
  return request({
    method: 'post',
    url: 'nUploadForPayment/batchUpload',
    params: pid ? { pid } : {},
    data: files
  })
}

/**
 * 删除附件id
 * @param {String} id
 */
export function getDeleteFile(id) {
  return request({
    method: 'delete',
    url: `nUploadForPayment/${id}`
  })
}

export function getFileById(id) {
  return request({
    method: 'get',
    url: `nUploadForPayment/${id}`
  })
}

/**
 * 批量删除附件
 * @param {Array} ids
 */
export function getDeleteFiles(ids) {
  return request({
    method: 'post',
    url: `nUploadForPayment/multiFiles`,
    data: ids
  })
}

/**
 * 获取 img 文件的 url
 * @param {String} id 要打开文件的id
 */
export function getIMGURL(id) {
  return request({
    method: 'get',
    url: `nUploadForPayment/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `image/png` })
    const url = URL.createObjectURL(blob)
    return url
  })
}

/**
 * 获取 pdf 文件的 url
 * @params {String} id 要打开文件的 id
 * @returns {String} pdf 的路径
 */
export function getPDFURL(id) {
  return request({
    method: 'get',
    url: `nUploadForPayment/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `application/pdf` })
    const url = URL.createObjectURL(blob)
    return url
  })
}

/**
 * 获取 EXCEL文件的 url
 * @param {String} id 要打开文件的id
 */
export function getEXCELURL(id) {
  return request({
    method: 'get',
    url: `nUploadForPayment/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `application/vnd.ms-excel` })
    const url = URL.createObjectURL(blob)
    return url
  })
}

/**
 * 获取 word文件的 url
 * @param {String} id 要打开文件的id
 */
export function getWORDURL(id) {
  return request({
    method: 'get',
    url: `nUploadForPayment/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `application/msword` })
    const url = URL.createObjectURL(blob)
    return url
  })
}

/**
 * 预览文件(包括word/excel等)(弃用)
 * @param {String} id
 */
export function preViewTransformFile(id) {
  return request({
    method: 'get',
    url: `nUploadForPayment/preViewTransformFile`,
    params: { id },
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `application/pdf` })
    const url = URL.createObjectURL(blob)
    return url
  })
}
