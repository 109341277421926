export default {
  namespaced: true,
  state: {
    dom: {},
    scrollDom: null
  },
  mutations: {
    SCROLL_TO_END(state) {
      if (state.scrollDom) {
        state.scrollDom.scrollTop = state.scrollDom.scrollHeight
      } else {
        window.scrollTo({ left: 0, top: state.dom.scrollHeight })
      }
    },

    SET_DOM(state, dom) {
      state.dom = dom
    },

    SET_SCROLL_DOM(state, dom) {
      state.scrollDom = dom
    },

    REMOVE_SCROLL_DOM(state) {
      state.scrollDom = null
    }
  }
}
