import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 药连锁公司驻地宿舍申请单列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getHouseDormitoryList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `houseDormitory/getHouseDormitoryAppList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新药连锁公司驻地宿舍申请单
 * @param {Object} data
 * @returns
 */
export function updateHouseDormitory(data) {
  return request({
    method: 'post',
    url: `houseDormitory/updateHouseDormitoryApp`,
    data
  })
}

/**
 * 保存药连锁公司驻地宿舍申请单
 * @param {Object} data
 * @returns
 */
export function saveHouseDormitory(data) {
  return request({
    method: 'post',
    url: `houseDormitory/saveHouseDormitoryApp`,
    data
  })
}

/**
 * 提交药连锁公司驻地宿舍申请单
 * @param {Object} data
 * @returns
 */
export function submitHouseDormitory(data) {
  return request({
    method: 'post',
    url: `oaflow/submitHouseDormitoryApp`,
    data
  })
}

/**
 * 保存药连锁公司驻地宿舍申请单
 * @param {String} id
 * @returns
 */
export function delHouseDormitory(id) {
  return request({
    method: 'post',
    url: `houseDormitory/delHouseDormitoryApp/${id}`
  })
}

/**
 * 获取药连锁公司驻地宿舍申请单
 * @param {String} id
 * @returns
 */
export function getHouseDormitory(id) {
  return request({
    method: 'get',
    url: `houseDormitory/${id}`
  })
}

/**
 * 获取当前员工的住房信息
 * @param {String} id
 * @returns
 */
export function getNewInHouseInfoByUid() {
  return request({
    method: 'get',
    url: `houseDormitory/getNewInHouseInfoByUid`
  })
}

/**
 * 导出药连锁公司驻地宿舍申请单
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/houseDormitory/exportData`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `药连锁公司驻地宿舍申请单`)
  })
}
