<template>
  <div class="text-xs" style="padding-left: 32px">
    <component
      :is="component"
      :type="type"
      :pid="pid"
      @status="nowStatus($event)"
    ></component>
  </div>
</template>

<script>
import AcademicTable from '@/views/activity/academicApplication/academic-v2/AppFormDetail'
import AcademicForm from '@/views/activity/academicApplication/AcademicApplicationTable'

import { ACTIVITY_DATE } from '@/configs/date-config'

export default {
  name: 'XSSQBill',
  components: {
    AcademicTable,
    AcademicForm
  },
  props: {
    type: String,
    appDate: String,
    pid: String
  },
  data() {
    return {
      status: '',
      component: ''
    }
  },
  created() {
    const appdateDate = this.$date(this.appDate).format('YYYY-MM-DD')
    const newVersionDate = this.$date(ACTIVITY_DATE).format('YYYY-MM-DD')

    if (this.$date(newVersionDate).isBefore(appdateDate)) {
      this.component = 'AcademicTable'
    } else this.component = 'AcademicForm'
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
