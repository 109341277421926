import request from '@/utils/request'

//医院列表
export function loadTableData(page, data) {
  return request({
    url: `/hospital/${page.current}/${page.pageSize}`,
    method: 'post',
    data: data
  })
}
//加载医院详情
export function loadHospInfo(hospid, params) {
  return request({
    url: `/hospital/${hospid}`,
    method: 'get',
    data: {
      ...params
    }
  })
}

// 新增医院
export function addHospital(data) {
  return request({
    url: `/hospital`,
    method: 'post',
    data: data
  })
}

//更新医院详情
export function updateHospInfo(hospid, data) {
  return request({
    url: `/hospital/${hospid}`,
    method: 'put',
    data: data
  })
}

//删除医院
export function deleteHosp(hospid) {
  return request({
    url: `/hospital/${hospid}`,
    method: 'delete'
  })
}
