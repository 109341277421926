<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false" @change="changeColl">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel key="1" class="customStyle">
          <template slot="header">
            <span>发起人附言</span>
            <!-- <a
              class="ml-6 text-xs font-normal text-blue-600"
              v-if="
                uid === $store.getters['uid'] &&
                openCollapse.length !== 0 &&
                pid
              "
              @click.stop="addPostScript"
            >
              新增附言
            </a> -->
          </template>
          <!-- 附言附件列表 -->
          <div v-if="commentList && commentList.length !== 0">
            <a-divider style="margin-left: 24px"></a-divider>
            <div
              style="margin-left: 24px"
              class="mb-4"
              v-for="{ id, addTime, appendixes, comment } in commentList"
              :key="id"
            >
              <p>{{ comment }}</p>
              <div class="mb-2 mr-6 text-gray-500">
                <span>时间 : {{ addTime }}</span>
                <a-divider type="vertical" />
                <span>
                  附件 ：
                  <icon-font
                    :type="appendixes ? 'icon_wenjianjia2' : 'icon_wenjianjia1'"
                    class="text-xl cursor-pointer"
                    @click="preview(appendixes)"
                  ></icon-font>
                </span>
              </div>
              <a-divider></a-divider>
            </div>
            <div
              class="text-center"
              v-if="!showPostScriptArea && uid === $store.getters['uid'] && pid"
            >
              <a-button @click.stop="addPostScript">新增附言</a-button>
            </div>
          </div>
          <div
            v-if="
              !(commentList && commentList.length !== 0) && !showPostScriptArea
            "
            class="text-center"
          >
            <icon-svg icon="post" size="200px"></icon-svg>
            <p class="text-gray-500" style="font-size: 12px">未发起附言</p>
            <a-button
              class="mt-4"
              v-if="uid === $store.getters['uid'] && pid"
              @click.stop="addPostScript"
            >
              新增附言
            </a-button>
          </div>
          <!-- 添加附言附件信息 -->
          <div
            class="w-full flex flex-wrap justify-start"
            style="padding-left: 24px"
            v-if="showPostScriptArea"
          >
            <a-textarea
              placeholder="限300字"
              :rows="6"
              v-model="comment.comment"
            />
            <div
              class="w-full border border-gray-400 border-t-0 flex content-center justify-between"
              style="background-color: #eff3f5"
            >
              <div class="ml-4">
                <icon-font
                  :type="
                    comment.appendixes ? 'icon_wenjianjia2' : 'icon_wenjianjia1'
                  "
                  class="text-xl"
                  @click="uploadFile(comment.appendixes)"
                ></icon-font>
              </div>
              <div class="my-1 mr-4">
                <a-button type="primary" class="mr-4" @click="submit">
                  提交
                </a-button>
                <a-button @click="cancel">取消</a-button>
              </div>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <postscript-upload-modal
      v-if="showUpload"
      uploadName="附言"
      :uploadFilelistInfo="uploadFilelistInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></postscript-upload-modal>
    <preview-modal
      v-if="showPreview"
      uploadName="附言"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </div>
</template>

<script>
import {
  getAddCommentList,
  getSaveComment
} from '@/api/acdemicActive/add-comment'

import { getDeleteFiles } from '@/api/acdemicActive/upload'

import PreviewModal from '@/components/Upload/PreviewModal'
import PostscriptUploadModal from '@/components/Upload/UploadModal'
export default {
  name: 'PostScript',
  components: { PreviewModal, PostscriptUploadModal },
  props: {
    pid: { type: String },
    billType: { type: String },
    uid: { type: String }
  },
  data() {
    return {
      showUpload: false, //文件上传的弹框默认关闭
      showPreview: false, //预览文件的弹框默认关闭
      showPostScriptArea: false, //添加附言附件信息默认关闭
      commentList: [], //附言附件信息列表
      openCollapse: ['1'], //当前激活 tab 面板的 key数组(默认打开key=1)
      comment: {
        appendixes: '',
        comment: '',
        relatedBillId: this.pid,
        relatedBillType: this.billType
      }
    }
  },
  created() {
    this.getAddCommentList()
  },
  methods: {
    changeColl(val) {
      this.openCollapse = val
    },
    /**
     * 新增附言
     */
    addPostScript() {
      this.showPostScriptArea = true
    },

    /**
     * 提交附言
     */
    submit() {
      getSaveComment(this.comment).then(() => {
        this.$message.success('提交成功！')
        this.getAddCommentList()
        this.closeComment()
      })
    },

    /**
     * 获取单据的附言附件信息列表
     */
    getAddCommentList() {
      getAddCommentList(this.pid).then(res => {
        this.commentList = res.datas
      })
    },

    /**
     * 文件上传
     * @param {String} fileInfo //传入的文件信息
     */
    uploadFile(filesInfo) {
      this.showUpload = true
      this.uploadFilelistInfo = filesInfo
    },

    /**
     * 传递预览文件信息
     * @param {String} fileInfo
     */
    preview(previewFileListInfo) {
      this.previewFileListInfo = previewFileListInfo
      this.showPreview = true
    },

    //文件信息添加到对应的字段
    hasUpload(file) {
      this.comment.appendixes = file.fileListInfo
    },

    // 成功后关闭附言区域
    closeComment() {
      this.showPostScriptArea = false
      // 清空textarea的值和文件上传的值
      this.comment.comment = ''
      this.comment.appendixes = ''
    },

    /**
     * 关闭添加附言区
     */
    cancel() {
      this.showPostScriptArea = false
      // 清空textarea的值和文件上传的值
      this.comment.comment = ''
      if (this.comment.appendixes) {
        const fileList = []
        const appendixes = JSON.parse(this.comment.appendixes)

        for (let item of appendixes) {
          fileList.push(item.id)
        }
        getDeleteFiles(fileList)
          .then(() => {
            this.$store.dispatch('uploadFile/clearFiles')
            this.comment.appendixes = ''
          })
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

/deep/textarea.ant-input {
  border-radius: 0px;
}

/deep/.anticon {
  line-height: normal;
}

/deep/.ant-divider-horizontal {
  margin: 12px 0;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
