<template>
  <div>
    <div class="text-right my-4">
      <a-button class="mr-2" @click="goodsPrint">发货申请单打印</a-button>

      <a-button style="margin-right: 5.6%" @click="billPrint">
        开票申请单打印
      </a-button>
    </div>

    <div style="padding-left: 32px" class="my-4">
      <a-form
        size="small"
        laba-width="90px"
        laba-position="right"
        layout="vertical"
      >
        <a-collapse v-model="activeKey" :bordered="false">
          <template v-slot:expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel
            class="customStyle"
            v-for="{ title, active, children } in baseMsg"
            :header="title"
            :key="active"
          >
            <a-col
              :span="8"
              v-for="{ label, type, value } in children"
              :key="label"
            >
              <a-form-item
                :label="label"
                :prop="value"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-button v-if="type === 'btn'" @click="preview(value)">
                  附件预览
                </a-button>
                <a-textarea
                  v-else-if="type === 'textarea'"
                  v-model="formData[value]"
                  :rows="4"
                  :disabled="true"
                ></a-textarea>
                <a-input
                  v-else
                  :disabled="true"
                  v-model="formData[value]"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-collapse-panel>
          <a-collapse-panel
            v-for="{ title, active, dataList, columns } in tableMsg"
            :header="title"
            :key="active"
            class="customStyle"
          >
            <a-table
              rowKey="pkDigitContrB"
              bordered
              :dataSource="formData[dataList]"
              :columns="columns"
              :pagination="false"
              :scroll="{ x: 1920 }"
              :components="components"
              :rowClassName="
                (record, index) => {
                  return index % 2 === 0 ? 'trBgClass' : ''
                }
              "
            ></a-table>
          </a-collapse-panel>
        </a-collapse>
        <process
          v-if="formData.flowInfoDTO"
          :summaryid="formData.flowInfoDTO.summaryid"
          :nextapproveman="formData.flowInfoDTO.nextapproveman"
          :curApprover="formData.flowInfoDTO.curApprover"
        ></process>
        <comment :billId="formData.pkDigitContrH"></comment>
      </a-form>
    </div>

    <preview-modal
      v-if="showPreview"
      uploadName="合同及汇款单"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>

    <print-wrapper ref="printGoodsWrapper" v-show="showGoodsPrint">
      <goods-print :form="formData" :datas="datas" :tel="tel"></goods-print>
    </print-wrapper>

    <print-wrapper ref="printBillWrapper" v-show="showBillPrint">
      <bill-print :form="formData" :datas="datas"></bill-print>
    </print-wrapper>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import Process from '@/views/custmanage/modify/components/Process'
import PreviewModal from '@/components/Upload/PreviewModal'
import { BASE, TABLE } from './outer-comp'
import { getOuterCompBill } from '@/api/outer/componyBill'
import { getResizeableTitle } from '@/utils/table'

import GoodsPrint from '@/views/outer/componyBill/components/GoodsPrint'
import BillPrint from '@/views/outer/componyBill/components/BillPrint'
import PrintWrapper from 'vue-easy-print'

import { getUserOne } from '@/api/sysadmin/users'

export default {
  name: 'OUTERCOMP',
  components: {
    Process,
    Comment,
    PreviewModal,
    GoodsPrint,
    BillPrint,
    PrintWrapper
  },
  props: {
    type: String,
    pid: String
  },
  data() {
    this.components = getResizeableTitle(TABLE[0].columns)
    return {
      formData: {},
      datas: [],
      baseMsg: BASE,
      tableMsg: TABLE,
      activeKey: ['BASE', 'TABLE'],
      showPreview: false,

      showGoodsPrint: false,
      showBillPrint: false,
      tel: '',

      previewFileListInfo: '',
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getOuterCompBill(this.pid).then(res => {
      this.formData = res
      this.datas = res.bodyDtos || []
      this.$emit('status', this.formData.flowInfoDTO.status)
    })
  },

  methods: {
    preview(prop) {
      this.showPreview = true
      this.previewFileListInfo = this.formData[prop]
    },

    // 发货申请单打印
    async goodsPrint() {
      const user = await getUserOne(this.formData.creator)
      this.tel = user ? user.contactinfo : ''
      this.showGoodsPrint = true
      this.$nextTick(() => this.$refs['printGoodsWrapper'].print())
    },

    // 开票申请单打印
    billPrint() {
      this.showBillPrint = true
      this.$nextTick(() => this.$refs['printBillWrapper'].print())
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
