<template>
  <div v-if="isRead">
    <div class="border-b py-4 text-sm font-semibold">
      <a-icon type="apartment" />
      <span class="ml-2">流程信息</span>
      <span class="ml-2 text-xs" v-if="curApprover && curApprover !== '[]'">
        (
        <span style="color: #4587f4">当前审批人：</span>
        <span class="text-red-500">{{ curApprover }}</span>
        )
      </span>
    </div>
    <div class="w-full pl-4 overflow-auto">
      <canvas ref="canvas"></canvas>
    </div>
  </div>
</template>

<script>
import { getOAProcimg } from '@/api/acdemicActive/acdemic-application'
export default {
  name: 'OaProcess',
  props: {
    summaryid: String,
    nextapproveman: String,
    status: Boolean,
    curApprover: String
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  watch: {
    summaryid(nval) {
      if (this.isRead && nval) this.getOAProcimg(nval, this.nextapproveman)
    }
  },

  created() {
    if (this.summaryid && this.nextapproveman)
      this.getOAProcimg(this.summaryid, this.nextapproveman)
  },

  methods: {
    getImage(OAImage) {
      const canvas = this.$refs['canvas']
      const canvasContext = canvas.getContext('2d')
      const image = new Image()
      this.isLoading = true
      image.src = OAImage
      image.onload = () => {
        canvas.width = image.width - 200
        canvas.height = image.height - 180
        canvasContext.drawImage(
          image,
          100,
          30,
          image.width,
          image.height,
          0,
          0,
          image.width,
          image.height
        )
      }
    },

    getOAProcimg(_summaryid, nextapproveman) {
      const nextapprove = encodeURIComponent(nextapproveman).toLowerCase()
      getOAProcimg(this.summaryid, nextapprove).then(response => {
        const OAImage = response
        if (this.isRead) this.getImage(OAImage)
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
