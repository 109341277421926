import { message } from 'ant-design-vue'

const baseValidateProp = [
  { prop: 'dept', text: '部门' },
  { prop: 'contact', text: '联系方式' },
  { prop: 'arriveTime', text: '到店时间' },
  { prop: 'leaveTime', text: '离店时间' },
  { prop: 'payType', text: '付款方式' },
  { prop: 'corp', text: '所属公司' },
  { prop: 'peopleNum', text: '团队人数' },
  { prop: 'peopleNum', text: '接送情况-费用合计' }
]

const roomValidateProp = [
  { prop: 'dateTime', text: '日期' },
  { prop: 'mainSinglePrice', text: '主楼单间-单价' },
  { prop: 'mainSingleNum', text: '主楼单间-数量' },
  { prop: 'mainStandardPrice', text: '主楼标间-单价' },
  { prop: 'mainStandardNum', text: '主楼标间-数量' },
  { prop: 'qjStandardPrice', text: '清江楼标–单价' },
  { prop: 'qjStandardNum', text: '清江楼标-数量' },
  { prop: 'sundry', text: '杂项收费' }
]

const appMealsValidateProp = [
  { prop: 'appDate', text: '日期' },
  { prop: 'appTime', text: '用餐时间' },
  { prop: 'appMealType', text: '用餐类型' },
  { prop: 'appMealStandard', text: '用餐标准' },
  { prop: 'appPeopleNum', text: '用餐人数' }
]

const actMealsValidateProp = [
  { prop: 'actDate', text: '日期' },
  { prop: 'actTime', text: '用餐时间' },
  { prop: 'actMealType', text: '用餐类型' },
  { prop: 'actMealStandard', text: '用餐标准' },
  { prop: 'actPeopleNum', text: '用餐人数' },
  { prop: 'tableNum', text: '桌数' },
  { prop: 'price', text: '用餐费用' }
]

const appConfsValidateProp = [
  { prop: 'appDate', text: '日期' },
  { prop: 'appTime', text: '时间' },
  { prop: 'teaBreak', text: '茶歇' },
  { prop: 'teaBreakStandard', text: '茶歇标准' },
  { prop: 'appPlace', text: '地点' }
]

const actConfsValidateProp = [
  { prop: 'actDate', text: '日期' },
  { prop: 'actTime', text: '时间' },
  { prop: 'actPlace', text: '地点' },
  { prop: 'teaBreak', text: '茶歇' },
  { prop: 'teaBreakStandard', text: '茶歇标准' },
  { prop: 'price', text: '会务费用' }
]

const golfInfosValidateProp = [
  { prop: 'dateTime', text: '日期' },
  { prop: 'golfTime', text: '时间' },
  { prop: 'golfType', text: '类型' },
  { prop: 'pepleNum', text: '人数' },
  { prop: 'fee', text: '费用' }
]

const receptValidateProp = [
  { prop: 'transPrice', text: '接送单价' },
  { prop: 'transNum', text: '接送次数' },
  { prop: 'otherCost', text: '其他费用' }
]

const validateProp = {
  base: baseValidateProp,
  approoms: roomValidateProp,
  actrooms: roomValidateProp,
  appMeals: appMealsValidateProp,
  actMeals: actMealsValidateProp,
  appConfs: appConfsValidateProp,
  actConfs: actConfsValidateProp,
  appGolfs: golfInfosValidateProp,
  actGolfs: golfInfosValidateProp,
  recept: receptValidateProp
}

const validatePropEnmu = {
  approoms: '申请用房',
  actrooms: '实际用房',
  appMeals: '申请用餐',
  actMeals: '实际用餐',
  appConfs: '会务申请',
  actConfs: '会务实际',
  appGolfs: '高尔夫预定',
  actGolfs: '高尔夫实际'
}

/**
 * 普通的校验信息
 * @param {Onject} bill 校验的单据信息
 * @param {String} validateStr 校验的字段信息
 * @returns
 */
function commonValidate(bill, validateStr) {
  for (const { prop, text } of validateProp[validateStr]) {
    const boolProp = bill[prop] === 0 ? false : !bill[prop]
    if (boolProp) {
      message.error(`${text}不能为空`)
      return false
    }
  }

  return true
}

/**
 * 列表的校验信息
 * @param {Onject} bill 校验的单据信息
 * @param {String} propStr 校验的单据字段信息
 * @param {String} validateStr 校验的字段信息
 * @returns
 */
function listValidate(bill, propStr, validateStr) {
  if (!bill[propStr] && !bill[propStr].length) return true

  for (const [index, item] of bill[propStr].entries()) {
    for (const { prop, text } of validateProp[validateStr]) {
      const boolProp = item[prop] === 0 ? false : !item[prop]
      if (boolProp) {
        message.error(
          `${validatePropEnmu[validateStr] || ''} : 第${
            index + 1
          }行, ${text}不能为空`
        )
        return false
      }
    }
  }

  return true
}

/**
 * 判断接待申请单中必填的字段是否有填(填写阶段)
 * @param {Object} apply
 */
export function receptValidate(bill) {
  if (!commonValidate(bill, 'base')) return false

  if (!listValidate(bill, 'appRoomsInfos', 'approoms')) return false

  if (!listValidate(bill, 'appMealsInfos', 'appMeals')) return false

  if (!listValidate(bill, 'appConfsInfos', 'appConfs')) return false

  if (!listValidate(bill, 'appGolfInfos', 'appGolfs')) return false

  return true
}

/**
 * 判断接待申请单中实际信息中必填的（审批阶段）
 */
export function receptActValidate(bill) {
  if (!listValidate(bill, 'actRoomsInfos', 'actrooms')) return false

  if (!listValidate(bill, 'actMealsInfos', 'actMeals')) return false

  if (!listValidate(bill, 'actConfsInfos', 'actConfs')) return false

  if (!listValidate(bill, 'actGolfInfos', 'actGolfs')) return false

  if (!commonValidate(bill, 'recept')) return false

  return true
}
