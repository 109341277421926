import { getDoc } from '@/configs/doc-config'

export default {
  data() {
    return {
      showInvioce: false,
      chooseFeeItem: {}, // 发票选择的费用项
      feeItemInvioces: [], // 费用项发票
      cooptBillInfos: [], // 发票的所有信息
      feeProjectEmunItem: {}, // 费用项枚举的pk
      feeProjectList: [], // 费用项的枚举项

      fee: {
        trafficExpense: '', // 交通费单价
        reimtrafficExpense: '', // 交通费单价（申请单带出）
        trafficExpenseNum: '', // 交通费数量
        reimtrafficExpenseNum: '', // 交通费数量（申请单带出）
        trafficExpenseTotal: '', // 交通费合计
        trafficExpenseDocs: '', // 交通费附件

        budgetReception: '', // 接送费单价
        reimbudgetReception: '', // 接送费单价（申请单带出）
        budgetReceptionNum: '', // 接送费数量
        reimbudgetReceptionNum: '', // 接送费数量（申请单带出）
        budgetReceptionTotal: '', // 接送费合计
        budgetReceptionDocs: '', // 接送费附件

        budgetAccommodation: '', // 住宿费单价
        reimbudgetAccommodation: '', // 住宿费单价（申请单带出）
        budgetAccommodationNum: '', // 住宿费数量
        reimbudgetAccommodationNum: '', // 住宿费数量（申请单带出）
        budgetAccommodationTotal: '', // 住宿费合计
        budgetAccommodationDocs: '', // 住宿费附件

        budgetMeals: '', // 餐费单价
        reimbudgetMeals: '', // 餐费单价（申请单带出）
        budgetMealsNum: '', // 餐费数量
        reimbudgetMealsNum: '', // 餐费数量（申请单带出）
        budgetMealsDocs: '', // 餐费附件

        meetingMaterialExpense: '', // 会务物料制作费单价
        reimmeetingMaterialExpense: '', // 会务物料制作费单价（申请单带出）
        meetingMaterialExpenseNum: '', // 会务物料制作费数量
        reimmeetingMaterialExpenseNum: '', // 会务物料制作费数量（申请单带出）
        meetingMaterialExpenseDocs: '', //会务物料制作费附件
        meetingMaterialExpenseTotal: '', // 会务物料制作费合计

        budgetThirdparty: '', // 第三方会务人员费用单价
        reimbudgetThirdparty: '', // 第三方会务人员费用单价（申请单带出）
        budgetThirdpartyNum: '', // 第三方会务人员费用数量
        reimbudgetThirdpartyNum: '', // 第三方会务人员费用数量（申请单带出）
        budgetThirdpartyTotal: '', // 第三方会务人员费用合计
        budgetThirdpartyDocs: '', // 第三方会务人员附件

        exhibitionExpense: '', // 展台布展单价
        reimexhibitionExpense: '', // 展台布展单价（申请单带出）
        exhibitionExpenseNum: '', // 展台布展数量
        reimexhibitionExpenseNum: '', // 展台布展数量（申请单带出）
        exhibitionExpenseTotal: '', // 展台布展合计
        exhibitionExpenseDocs: '', // 展台布展附件

        // accompanyExpense: '', // 陪同费用
        reimaccompanyExpense: '', // 陪同费用（申请单带出）

        // budgetConsultTotal: '', // 讲者劳务费
        budgetConsultDocs: '', // 讲者劳务费附件
        reimbudgetConsultTotal: '', // 讲者劳务费（申请单带出）

        onlineLiveBroadcastingFee: '', // 线上平台直播费用（申请单带出）
        reimOnlineLiveBroadcastingFee: '', // 线上平台直播费用

        venueExpense: '', // 场地费单价
        venueExpenseNum: '', // 场地费数量
        venueExpenseTotal: '', // 场地费合计
        reimvenueExpense: '', // 场地费单价（申请单带出）
        reimvenueExpenseNum: '', // 场地费数量（申请单带出）
        venueExpenseDocs: '', // 场地费附件

        registerFee: '', // 注册费
        reimRegisterFee: '', // 注册费（申请单带出）
        registerFeeDocs: '', // 注册费附件

        budgetServiceTotal: '', // 会务服务费、税费
        reimbudgetServiceTotal: '', // 会务服务费、税费（申请单带出）
        budgetServiceDocs: '', // 会务服务费、税费附件

        budgetCooperationTotal: '', // 第三方协办费用（申请单带出）
        reimbudgetCooperationTotal: '', // 第三方协办费用（申请单带出）

        /** 废弃 */
        budgetBooth: '', // 展位(场地费)单价
        reimbudgetBooth: '', // 展位(场地费)单价（申请单带出）
        budgetBoothNum: '', // 展位(场地费)数量
        reimbudgetBoothNum: '', // 展位(场地费)数量（申请单带出）

        budgetMeetingroom: '', // 会议室(场地费) 单价
        reimbudgetMeetingroom: '', // 会议室(场地费) 单价（申请单带出）
        budgetMeetingroomNum: '', // 会议室(场地费)数量
        reimbudgetMeetingroomNum: '', // 会议室(场地费)数量（申请单带出）

        budgetMake: '', // 制作费单价
        reimbudgetMake: '', // 制作费单价（申请单带出）
        budgetMakeNum: '', // 制作费数量
        reimbudgetMakeNum: '', // 制作费数量（申请单带出）

        budgetDesign: '', // 设计费单价
        reimbudgetDesign: '', // 设计费单价（申请单带出）
        budgetDesignNum: '', // 设计费数量
        reimbudgetDesignNum: '', // 设计费数量（申请单带出）

        budgetFlight: '', // 飞机费单价
        reimbudgetFlight: '', // 飞机费单价（申请单带出）
        budgetFlightNum: '', // 飞机费数量
        reimbudgetFlightNum: '', // 飞机费数量（申请单带出）

        budgetTrain: '', // 高铁费单价
        reimbudgetTrain: '', // 高铁费单价（申请单带出）
        budgetTrainNum: '', // 高铁费数量
        reimbudgetTrainNum: '', // 高铁费数量（申请单带出）

        budgetBrandpreTotal: '', // 推广物料费
        reimbudgetBrandpreTotal: '', // 推广物料费（申请单带出）

        otherFee: '', // 其他费用
        reimOtherFee: '', // 其他费用（申请单带出）

        // 预算合计
        budgetTotal: 0, // 实际花销合计
        reimbudgetTotal: 0, // 预算花销合计

        budgetPreCost: 0, // 已预付金额
        reimAmountApprove: 0, // 批准报销金额
        financialApprovalAmount: 0 // 财务批准报销金额
      },

      feeDatas: [
        {
          title: '交通费',
          price: '~',
          num: '~',
          total: 'trafficExpenseTotal',
          reimPrice: 'reimtrafficExpense',
          reimNum: 'reimtrafficExpenseNum',
          reimTotal: () => this.reimtrafficExpenseTotal,
          upload: 'trafficExpenseDocs'
        },
        {
          title: '接送费',
          price: '~',
          num: '~',
          total: 'budgetReceptionTotal',
          reimPrice: 'reimbudgetReception',
          reimNum: 'reimbudgetReceptionNum',
          reimTotal: () => this.reimbudgetReceptionTotal,
          upload: 'budgetReceptionDocs'
        },
        {
          title: '住宿费',
          price: '~',
          num: '~',
          total: 'budgetAccommodationTotal',
          reimPrice: 'reimbudgetAccommodation',
          reimNum: 'reimbudgetAccommodationNum',
          reimTotal: () => this.reimbudgetAccommodationTotal,
          upload: 'budgetAccommodationDocs'
        },
        {
          title: '餐费',
          price: 'budgetMeals',
          num: 'budgetMealsNum',
          total: () => this.budgetMealsTotal,
          reimPrice: 'reimbudgetMeals',
          reimNum: 'reimbudgetMealsNum',
          reimTotal: () => this.reimbudgetMealsTotal,
          upload: 'budgetMealsDocs'
        },
        {
          title: '场地费',
          price: '~',
          num: '~',
          total: 'venueExpenseTotal',
          reimPrice: 'reimvenueExpense',
          reimNum: 'reimvenueExpenseNum',
          reimTotal: () => this.reimvenueExpenseTotal,
          upload: 'venueExpenseDocs'
        },
        {
          title: '会务物料制作费',
          price: '~',
          num: '~',
          total: 'meetingMaterialExpenseTotal',
          reimPrice: 'reimmeetingMaterialExpense',
          reimNum: 'reimmeetingMaterialExpenseNum',
          reimTotal: () => this.reimmeetingMaterialExpenseTotal,
          upload: 'meetingMaterialExpenseDocs'
        },
        {
          title: '展台布展',
          price: '~',
          num: '~',
          total: 'exhibitionExpenseTotal',
          reimPrice: 'reimexhibitionExpense',
          reimNum: 'reimexhibitionExpenseNum',
          reimTotal: () => this.reimexhibitionExpenseTotal,
          upload: 'exhibitionExpenseDocs'
        },
        {
          title: '第三方会务人员费用',
          price: '~',
          num: '~',
          total: 'budgetThirdpartyTotal',
          reimPrice: 'reimbudgetThirdparty',
          reimNum: 'reimbudgetThirdpartyNum',
          reimTotal: () => this.reimbudgetThirdpartyTotal,
          upload: 'budgetThirdpartyDocs'
        },
        {
          title: '注册费',
          price: '~',
          num: '~',
          total: 'registerFee',
          reimPrice: '~',
          reimNum: '~',
          reimTotal: 'reimRegisterFee',
          upload: 'registerFeeDocs'
        },
        {
          title: '第三方协办费用',
          price: '~',
          num: '~',
          total: 'budgetCooperationTotal',
          reimPrice: '~',
          reimNum: '~',
          reimTotal: 'reimbudgetCooperationTotal',
          upload: 'budgetCooperationDocs'
        },
        {
          title: '会务服务费、税费',
          price: '~',
          num: '~',
          total: 'budgetServiceTotal',
          reimPrice: '~',
          reimNum: '~',
          reimTotal: 'reimbudgetServiceTotal',
          upload: 'budgetServiceDocs'
        },
        {
          title: '线上平台直播费用',
          price: '~',
          num: '~',
          total: 'onlineLiveBroadcastingFee',
          reimPrice: '~',
          reimNum: '~',
          reimTotal: 'reimOnlineLiveBroadcastingFee',
          upload: 'onlineLiveBroadcastingFeeDocs'
        },
        {
          title: '讲者劳务费',
          price: '~',
          num: '~',
          total: () => this.budgetConsultTotal,
          reimPrice: '~',
          reimNum: '~',
          reimTotal: 'reimbudgetConsultTotal',
          notVerity: true,
          upload: 'budgetConsultDocs'
        },
        {
          title: '陪同费用',
          price: '~',
          num: '~',
          total: () => this.accompanyExpense,
          reimPrice: '~',
          reimNum: '~',
          reimTotal: 'reimaccompanyExpense',
          notVerity: true,
          upload: 'accompanyExpenseDocs'
        },
        // 废弃
        {
          title: '展位(场地费)',
          price: 'budgetBooth',
          num: 'budgetBoothNum',
          total: () => this.budgetBoothTotal,
          reimPrice: 'reimbudgetBooth',
          reimNum: 'reimbudgetBoothNum',
          reimTotal: () => this.reimbudgetBoothTotal,
          notVerity: true,
          deprecated: true
        },
        {
          title: '会议室(场地费)',
          price: 'budgetMeetingroom',
          num: 'budgetMeetingroomNum',
          total: () => this.budgetMeetingroomTotal,
          reimPrice: 'reimbudgetMeetingroom',
          reimNum: 'reimbudgetMeetingroomNum',
          reimTotal: () => this.reimbudgetMeetingroomTotal,
          notVerity: true,
          deprecated: true
        },
        {
          title: '制作费',
          price: 'budgetMake',
          num: 'budgetMakeNum',
          total: () => this.budgetMakeTotal,
          reimPrice: 'reimbudgetMake',
          reimNum: 'reimbudgetMakeNum',
          reimTotal: () => this.reimbudgetMakeTotal,
          notVerity: true,
          deprecated: true
        },
        {
          title: '设计费',
          price: 'budgetDesign',
          num: 'budgetDesignNum',
          total: () => this.budgetDesignTotal,
          reimPrice: 'reimbudgetDesign',
          reimNum: 'reimbudgetDesignNum',
          reimTotal: () => this.reimbudgetDesignTotal,
          notVerity: true,
          deprecated: true
        },
        {
          title: '飞机费',
          price: 'budgetFlight',
          num: 'budgetFlightNum',
          total: () => this.budgetFlightTotal,
          reimPrice: 'reimbudgetFlight',
          reimNum: 'reimbudgetFlightNum',
          reimTotal: () => this.reimbudgetFlightTotal,
          notVerity: true,
          deprecated: true
        },
        {
          title: '高铁费',
          price: 'budgetTrain',
          num: 'budgetTrainNum',
          total: () => this.budgetTrainTotal,
          reimPrice: 'reimbudgetTrain',
          reimNum: 'reiimbudgetTrainNum',
          reimTotal: () => this.reimbudgetTrainTotal,
          notVerity: true,
          deprecated: true
        },
        {
          title: '推广物料费',
          price: '~',
          num: '~',
          total: 'budgetBrandpreTotal',
          reimPrice: '~',
          reimNum: '~',
          reimTotal: 'reimbudgetBrandpreTotal',
          notVerity: true,
          deprecated: true
        },
        {
          title: '其他费用',
          price: '~',
          num: '~',
          total: 'otherFee',
          reimPrice: '~',
          reimNum: '~',
          reimTotal: 'reimOtherFee',
          notVerity: true,
          deprecated: true
        }
      ],

      feeColumns: [
        { title: '项目', dataIndex: 'title' },
        {
          title: '预算单价',
          dataIndex: 'reimPrice',
          width: '13%',
          scopedSlots: { customRender: 'reimPrice' }
        },
        {
          title: '预算数量',
          dataIndex: 'reimNum',
          width: '13%',
          scopedSlots: { customRender: 'reimNum' }
        },
        {
          title: '预算总价',
          dataIndex: 'reimTotal',
          width: '13%',
          scopedSlots: { customRender: 'reimTotal' }
        },
        {
          dataIndex: 'price',
          width: '13%',
          slots: { title: 'priceTitle' },
          scopedSlots: { customRender: 'price' }
        },
        {
          dataIndex: 'num',
          width: '13%',
          slots: { title: 'numTitle' },
          scopedSlots: { customRender: 'num' }
        },
        {
          dataIndex: 'total',
          width: '13%',
          slots: { title: 'totalTitle' },
          scopedSlots: { customRender: 'total' }
        },
        {
          title: '附件上传',
          dataIndex: 'upload',
          width: '76px',
          scopedSlots: { customRender: 'upload' }
        }
      ]
    }
  },

  computed: {
    /**预算合计 */
    // 场地费
    reimvenueExpenseTotal() {
      const price = Number(this.fee.reimvenueExpense || '0')
      const num = Number(this.fee.reimvenueExpenseNum || '0')
      return this.$np.times(price, num)
    },
    // 交通费
    reimtrafficExpenseTotal() {
      const price = Number(this.fee.reimtrafficExpense || '0')
      const num = Number(this.fee.reimtrafficExpenseNum || '0')
      return this.$np.times(price, num)
    },
    // 接送费
    reimbudgetReceptionTotal() {
      const price = Number(this.fee.reimbudgetReception || '0')
      const num = Number(this.fee.reimbudgetReceptionNum || '0')
      return this.$np.times(price, num)
    },
    // 住宿费
    reimbudgetAccommodationTotal() {
      const price = Number(this.fee.reimbudgetAccommodation || '0')
      const num = Number(this.fee.reimbudgetAccommodationNum || '0')
      return this.$np.times(price, num)
    },
    // 餐费
    reimbudgetMealsTotal() {
      const price = Number(this.fee.reimbudgetMeals || '0')
      const num = Number(this.fee.reimbudgetMealsNum || '0')
      return this.$np.times(price, num)
    },
    // 会务物料制作费
    reimmeetingMaterialExpenseTotal() {
      const price = Number(this.fee.reimmeetingMaterialExpense || '0')
      const num = Number(this.fee.reimmeetingMaterialExpenseNum || '0')
      return this.$np.times(price, num)
    },
    // 展台布展
    reimexhibitionExpenseTotal() {
      const price = Number(this.fee.reimexhibitionExpense || '0')
      const num = Number(this.fee.reimexhibitionExpenseNum || '0')
      return this.$np.times(price, num)
    },
    // 第三方会务人员费用
    reimbudgetThirdpartyTotal() {
      const price = Number(this.fee.reimbudgetThirdparty || '0')
      const num = Number(this.fee.reimbudgetThirdpartyNum || '0')
      return this.$np.times(price, num)
    },

    // 废弃
    // 展位(场地费)
    reimbudgetBoothTotal() {
      const price = Number(this.fee.reimbudgetBooth || '0')
      const num = Number(this.fee.reimbudgetBoothNum || '0')
      return this.$np.times(price, num)
    },
    // 会议室(场地费)
    reimbudgetMeetingroomTotal() {
      const price = Number(this.fee.reimbudgetMeetingroom || '0')
      const num = Number(this.fee.reimbudgetMeetingroomNum || '0')
      return this.$np.times(price, num)
    },
    // 制作费
    reimbudgetMakeTotal() {
      const price = Number(this.fee.reimbudgetMake || '0')
      const num = Number(this.fee.reimbudgetMakeNum || '0')
      return this.$np.times(price, num)
    },
    // 设计费
    reimbudgetDesignTotal() {
      const price = Number(this.fee.reimbudgetDesign || '0')
      const num = Number(this.fee.reimbudgetDesignNum || '0')
      return this.$np.times(price, num)
    },
    // 飞机费
    reimbudgetFlightTotal() {
      const price = Number(this.fee.reimbudgetFlight || '0')
      const num = Number(this.fee.reimbudgetFlightNum || '0')
      return this.$np.times(price, num)
    },
    // 高铁费
    reimbudgetTrainTotal() {
      const price = Number(this.fee.reimbudgetTrain || '0')
      const num = Number(this.fee.reimbudgetTrainNum || '0')
      return this.$np.times(price, num)
    },

    /** 实际合计 */
    // 餐费
    budgetMealsTotal() {
      const price = Number(this.fee.budgetMeals || '0')
      const num = Number(this.fee.budgetMealsNum || '0')
      return this.$np.times(price, num)
    },

    // 废弃
    // 展位(场地费)
    budgetBoothTotal() {
      const price = Number(this.fee.budgetBooth || '0')
      const num = Number(this.fee.budgetBoothNum || '0')
      return this.$np.times(price, num)
    },
    // 会议室(场地费)
    budgetMeetingroomTotal() {
      const price = Number(this.fee.budgetMeetingroom || '0')
      const num = Number(this.fee.budgetMeetingroomNum || '0')
      return this.$np.times(price, num)
    },
    // 制作费
    budgetMakeTotal() {
      const price = Number(this.fee.budgetMake || '0')
      const num = Number(this.fee.budgetMakeNum || '0')
      return this.$np.times(price, num)
    },
    // 设计费
    budgetDesignTotal() {
      const price = Number(this.fee.budgetDesign || '0')
      const num = Number(this.fee.budgetDesignNum || '0')
      return this.$np.times(price, num)
    },
    // 飞机费
    budgetFlightTotal() {
      const price = Number(this.fee.budgetFlight || '0')
      const num = Number(this.fee.budgetFlightNum || '0')
      return this.$np.times(price, num)
    },
    // 高铁费
    budgetTrainTotal() {
      const price = Number(this.fee.budgetTrain || '0')
      const num = Number(this.fee.budgetTrainNum || '0')
      return this.$np.times(price, num)
    },

    /** 特殊 */
    // 讲者劳务费(讲者信息 模块)
    budgetConsultTotal() {
      return this.speaker.budgetConsultTotal || '0'
    },
    // 陪同费用(陪同信息 模块)
    accompanyExpense() {
      return this.accompany.accompanyExpense || '0'
    }
  },

  watch: {
    budgetMealsTotal: function () {
      this.calcFeeTotal()
    }, // 餐费
    accompanyExpense: function () {
      this.calcFeeTotal()
    }, // 陪同费用
    budgetConsultTotal: function () {
      this.calcFeeTotal()
    }, // 讲者劳务费
    'fee.trafficExpenseTotal': function () {
      this.calcFeeTotal()
    }, // 交通费
    'fee.budgetReceptionTotal': function () {
      this.calcFeeTotal()
    }, // 接待费
    'fee.budgetAccommodationTotal': function () {
      this.calcFeeTotal()
    }, // 住宿费
    'fee.meetingMaterialExpenseTotal': function () {
      this.calcFeeTotal()
    }, // 会务物料制作费
    'fee.venueExpenseTotal': function () {
      this.calcFeeTotal()
    }, // 场地费
    'fee.exhibitionExpenseTotal': function () {
      this.calcFeeTotal()
    }, // 展台布展
    'fee.budgetThirdpartyTotal': function () {
      this.calcFeeTotal()
    }, // 第三方会务人员费用
    'fee.budgetServiceTotal': function () {
      this.calcFeeTotal()
    }, // 会务服务费、税费
    'fee.registerFee': function () {
      this.calcFeeTotal()
    }, // 注册费
    'fee.budgetCooperationTotal': function () {
      this.calcFeeTotal()
    }, // 第三方协办费用
    'fee.onlineLiveBroadcastingFee': function () {
      this.calcFeeTotal()
    } // 线上平台直播费用
  },

  created() {
    getDoc('052', res => (this.feeProjectList = res))
  },

  methods: {
    // 实际金额合计
    calcFeeTotal() {
      this.fee.budgetTotal = this.$np.plus(
        Number(this.budgetMealsTotal || '0'), // 餐费合计
        Number(this.venueExpenseTotal || '0'), // 场地费合计
        Number(this.accompanyExpense || '0'), // 陪同人员合计
        Number(this.budgetConsultTotal || '0'), // 讲者合计
        Number(this.fee.venueExpenseTotal || '0'),
        Number(this.fee.trafficExpenseTotal || '0'),
        Number(this.fee.budgetReceptionTotal || '0'),
        Number(this.fee.budgetAccommodationTotal || '0'),
        Number(this.fee.meetingMaterialExpenseTotal || '0'),
        Number(this.fee.exhibitionExpenseTotal || '0'),
        Number(this.fee.budgetThirdpartyTotal || '0'),
        Number(this.fee.onlineLiveBroadcastingFee || '0'),
        Number(this.fee.budgetServiceTotal || '0'),
        Number(this.fee.registerFee || '0'),
        Number(this.fee.budgetCooperationTotal || '0')
      )
    },

    // 发票预览
    openInvioce(item) {
      this.chooseFeeItem = item

      // 当前费用枚举项
      this.feeProjectEmunItem = this.feeProjectList.find(
        i => i.text === item.title
      )
      // 费用项包含的发票
      this.feeItemInvioces = this.cooptBillInfos.filter(i => {
        return i.invoiceFeeType === this.feeProjectEmunItem.value
      })

      this.showInvioce = true
    },
    // 移除费用项的发票
    removeCooptBillInfos(pk) {
      this.cooptBillInfos = this.cooptBillInfos.filter(i => i.pk !== pk)
    },

    chooseInvioces(row) {
      this.cooptBillInfos = this.cooptBillInfos.filter(
        i => i.invoiceFeeType !== this.feeProjectEmunItem.value
      )

      for (const item of row) {
        this.cooptBillInfos.push({
          ...item,
          ...{
            invoiceFeeType: this.feeProjectEmunItem.value,
            invoiceFeeTypeCode: this.feeProjectEmunItem.code,
            invoiceFeeTypeName: this.feeProjectEmunItem.text
          }
        })
      }
    }
  }
}
