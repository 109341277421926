<template>
  <div class="speaker">
    <a-form labelAlign="left">
      <div class="flex">
        <div class="speaker-avatar">
          <a-avatar :size="128">
            <a-icon slot="icon" type="user" />
          </a-avatar>
          <div class="flex items-center mt-2">
            <a-button
              type="link"
              icon="caret-left"
              title="上一位讲者"
              :disabled="speakerInx === 0"
              @click="prev"
            ></a-button>
            <p class="flex-1 text-center font-bold mt-1">
              {{ speakerInfo.name }}
            </p>
            <a-button
              type="link"
              icon="caret-right"
              title="下一位讲者"
              :disabled="speakerInx === speakers.length - 1"
              @click="next"
            ></a-button>
          </div>
        </div>
        <div class="flex-1">
          <a-form-item
            v-for="item in speaker.base.list"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :label="item.label"
            :key="item.prop"
            labelAlign="right"
          >
            {{ speakerInfo[item.prop] || '无' }}
          </a-form-item>
        </div>
      </div>

      <div class="info">
        <p class="py-2 border-b font-bold text-base border-gray-300">
          {{ speaker.info.title }}
        </p>
        <a-row class="py-4">
          <a-col :span="12" v-for="item in speaker.info.list" :key="item.prop">
            <a-form-item
              :label-col="bodyLabelCol"
              :wrapper-col="bodyWrapperCol"
              :label="item.label"
            >
              {{ speakerInfo[item.prop] || '无' }}
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="person">
        <p class="py-2 border-b font-bold text-base border-gray-300">
          {{ speaker.person.title }}
        </p>
        <a-row class="py-4">
          <a-col
            :span="12"
            v-for="item in speaker.person.list"
            :key="item.prop"
          >
            <a-form-item
              :label-col="bodyLabelCol"
              :wrapper-col="bodyWrapperCol"
              :label="item.label"
            >
              {{ speakerInfo[item.prop] || '无' }}
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="level">
        <p class="py-2 border-b font-bold text-base border-gray-300">
          {{ speaker.level.title }}
        </p>
        <a-row class="py-4">
          <a-col :span="12" v-for="item in speaker.level.list" :key="item.prop">
            <a-form-item
              v-if="item.prop === 'consultingFee'"
              :label-col="bodyLabelCol"
              :wrapper-col="bodyWrapperCol"
              :label="item.label"
            >
              {{
                speakerInfo[item.prop] ? `≤ ${speakerInfo[item.prop]}` : '无'
              }}
            </a-form-item>
            <a-form-item
              v-else
              :label-col="bodyLabelCol"
              :wrapper-col="bodyWrapperCol"
              :label="item.label"
            >
              {{ speakerInfo[item.prop] || '无' }}
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="status">
        <p class="py-2 border-b font-bold text-base border-gray-300">
          {{ speaker.status.title }}
        </p>
        <a-row class="py-4">
          <a-col
            :span="12"
            v-for="item in speaker.status.list"
            :key="item.prop"
          >
            <a-form-item
              :label-col="bodyLabelCol"
              :wrapper-col="bodyWrapperCol"
              :label="item.label"
            >
              <span v-if="item.type === 'link'">
                <a
                  v-if="speakerInfo[item.prop]"
                  target="_blank"
                  style="color: #4587f5"
                  @click="alink(speakerInfo[item.prop])"
                >
                  {{ speakerInfo[item.prop] }}
                </a>
                <span v-else>暂无资质信息</span>
              </span>
              <span v-else>{{ speakerInfo[item.prop] || '无' }}</span>
            </a-form-item>
          </a-col>
        </a-row>
      </div>

      <div class="files">
        <p class="py-2 border-b font-bold text-base border-gray-300">
          {{ speaker.files.title }}
        </p>

        <div class="card-containers mt-4">
          <div class="clearfix">
            <div
              class="file-list-card"
              v-if="speakerInfo['files'] && speakerInfo['files'].length !== 0"
            >
              <div
                class="file-list"
                :title="file.oname"
                v-for="file in speakerInfo['files']"
                :key="file.fileId || file.id"
              >
                <div class="file-info">
                  <div class="file-mask">
                    <span class="file-type">
                      <a-icon
                        type="eye"
                        class="text-preview"
                        title="预览文件"
                        @click="handleFileView(file)"
                      />
                    </span>
                  </div>
                  <img :src="file.src" />
                </div>
                <div class="file-name">{{ file.oname }}</div>
              </div>
            </div>
            <div v-else class="text-center">
              <icon-svg icon="preview_null" size="150px" />
              <p class="text-gray-500 text-xs">暂无附件</p>
            </div>
          </div>
        </div>
      </div>
    </a-form>

    <image-preview
      v-if="showPreviewImage"
      :imageList="imageList"
      :imageIndex="imageIndex"
      @close="showPreviewImage = false"
    ></image-preview>
    <excel-preview
      v-if="showPreviewExcel"
      :fileId="fileId"
      @close="showPreviewExcel = false"
    ></excel-preview>
  </div>
</template>

<script>
import { loadSpeakInfo } from '@/api/customer/speakermanage'
import { getSpeakerAttachs } from '@/api/customer/speakermanage'
import { getIMGURL } from '@/api/acdemicActive/upload'

import ImagePreview from '@/components/Preview/ImagePreview'
import ExcelPreview from '@/components/Preview/ExcelPreview'
const cache = new Map()
export default {
  name: 'SpeakerDrawer',
  components: { ImagePreview, ExcelPreview },
  props: {
    speakers: { type: Array, default: () => [] },
    currentIdx: { type: Number, default: 0 }
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },

      bodyLabelCol: { span: 20 },
      bodyWrapperCol: { span: 20 },

      speakerInx: 9999,
      speakerInfo: {},

      showPreviewPDF: false, //默认打开pdf的modal是关闭的
      showPreviewImage: false, //默认打开image的modal是关闭的
      showPreviewExcel: false, //默认打开image的modal是关闭的
      fileId: '', //传入pdf预览框的文件id
      imageList: [], //所有图片的集合列表
      imageIndex: 0, //打开图片预览的索引

      // 查看讲者的信息
      speaker: {
        base: {
          title: '',
          list: [
            { label: '性别', prop: 'gender' },
            { label: '讲者编号', prop: 'code' },
            { label: '单位名称', prop: 'departmentName' },
            { label: '单位编码', prop: 'departmentCode' },
            { label: '科室', prop: 'section' }
          ]
        },

        info: {
          title: '基本信息',
          list: [
            { label: '学术头衔', prop: 'academicTitle' },
            { label: '职业医师证书编码', prop: 'certificationCode' },
            { label: '医生专长', prop: 'doctorExpertise' },
            { label: '医生状态', prop: 'doctorStatus' },
            { label: '相关产品/治疗领域', prop: 'relativeArea' },
            { label: '行政职务', prop: 'adminPost' },
            { label: '职称', prop: 'profTitle' }
          ]
        },

        person: {
          title: '个人信息',
          list: [
            { label: '证件类型', prop: 'idTypeName' },
            { label: '证件号', prop: 'idCode' },
            { label: '银行卡号', prop: 'bankCardNumber' },
            { label: '开户支行', prop: 'bankName' },
            { label: '开户人姓名', prop: 'accountOwner' }
          ]
        },

        level: {
          title: '级别评定',
          list: [
            { label: '讲者职称', prop: 'speakerTitleName' },
            { label: '单位级别', prop: 'departmentLevelName' },
            { label: '学历', prop: 'ebName' },
            { label: '学会任职', prop: 'academicPostName' },
            { label: '核心期刊发表', prop: 'publication' },
            { label: '咨询费标准', prop: 'consultingFee' },
            { label: '讲者级别', prop: 'speakerLevelName' }
          ]
        },

        status: {
          title: '讲者状态',
          list: [
            { label: '讲者状态', prop: 'speakerStatusName' },
            { label: '生效日期', prop: 'effectivexDate' },
            { label: '失效日期', prop: 'expirationxDate' },
            { label: '资质信息网', prop: 'qualificationInfo', type: 'link' },
            { label: '备注', prop: 'remarks' }
          ]
        },

        files: {
          title: '讲者附件'
        }
      }
    }
  },

  watch: {
    currentIdx: {
      handler(index) {
        this.speakerInx = index
        this.handlerSpeaker(this.speakerInx)
      },
      immediate: true
    }
  },

  methods: {
    // 资质信息网跳转外部链接
    alink(url) {
      const rex = /^http(s):\/\//
      if (rex.test(url)) window.open(url, '_blank')
      else window.open('https://' + url, '_blank')
    },

    prev() {
      this.speakerInx--
      this.handlerSpeaker(this.speakerInx)
    },

    next() {
      this.speakerInx++
      this.handlerSpeaker(this.speakerInx)
    },

    handlerSpeaker(index) {
      const sId = this.speakers[index].speakerId
      if (!sId) return

      if (cache.has(sId)) this.speakerInfo = cache.get(sId)
      else this.getSpeakerInfo(sId)
    },

    /**
     * 查看文件信息
     * @param {Object} file
     */
    handleFileView(file) {
      if (['xls', 'xlsx', 'doc', 'docx', 'pdf'].includes(file.type)) {
        this.fileId = file.id
        this.showPreviewExcel = true
      } else {
        this.imageList = [file]
        this.showPreviewImage = true
      }
    },

    /**
     * 获取文件的图片
     * @param {File} file
     */
    async fileImg(file) {
      if (file.type === 'pdf') {
        file.src = require('@/assets/upload/pdf.png')
      } else if (file.type === 'docx' || file.type === 'doc') {
        file.src = require('@/assets/upload/word.png')
      } else if (file.type === 'xls' || file.type === 'xlsx') {
        file.src = require('@/assets/upload/excel.png')
      } else {
        file.src = await getIMGURL(file.id)
      }
    },

    getSpeakerInfo(id) {
      loadSpeakInfo(id).then(res => {
        // 性别
        res.gender = res.gender ? (res.gender === 'M' ? '男' : '女') : '未填写'
        // 证件类型
        if (res.idType) this.$set(res, 'idTypeName', res.idTypeDTO.name)
        // 单位级别
        if (res.departmentLevel)
          this.$set(res, 'departmentLevelName', res.departmentLevelDTO.name)
        // 学历
        if (res.educationalBackground)
          this.$set(res, 'ebName', res.educationalBackgroundDTO.name)
        // 学会任职
        if (res.academicPost)
          this.$set(res, 'academicPostName', res.academicPostDTO.name)
        // 讲者级别
        if (res.speakerLevel)
          this.$set(res, 'speakerLevelName', res.speakerLevelDTO.name)
        // 讲者状态
        if (res.speakerStatus)
          this.$set(res, 'speakerStatusName', res.speakerStatusDTO.name)

        getSpeakerAttachs(res.id, {}).then(attachs => {
          for (const file of attachs) {
            this.$set(file, 'src', '')
            this.fileImg(file)
          }
          this.$set(res, 'files', attachs)
        })

        this.speakerInfo = res
        cache.set(res.id, res)
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-control {
  line-height: 24px;
}

/deep/.ant-form-item-label > label {
  font-weight: bold;
}
</style>
