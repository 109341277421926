var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"app-message mt-3"},[_c('a-collapse',{attrs:{"defaultActiveKey":"1","bordered":false},on:{"change":_vm.changeColl},scopedSlots:_vm._u([{key:"expandIcon",fn:function(props){return [_c('a-icon',{attrs:{"type":"caret-right","rotate":props.isActive ? 90 : 0}})]}}])},[_c('a-collapse-panel',{key:"1",staticClass:"customStyle"},[_c('template',{slot:"header"},[_c('span',[_vm._v("发起人附言")])]),(_vm.commentList && _vm.commentList.length !== 0)?_c('div',[_c('a-divider',{staticStyle:{"margin-left":"24px"}}),_vm._l((_vm.commentList),function(ref){
var id = ref.id;
var addTime = ref.addTime;
var appendixes = ref.appendixes;
var comment = ref.comment;
return _c('div',{key:id,staticClass:"mb-4",staticStyle:{"margin-left":"24px"}},[_c('p',[_vm._v(_vm._s(comment))]),_c('div',{staticClass:"mb-2 mr-6 text-gray-500"},[_c('span',[_vm._v("时间 : "+_vm._s(addTime))]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('span',[_vm._v(" 附件 ： "),_c('icon-font',{staticClass:"text-xl cursor-pointer",attrs:{"type":appendixes ? 'icon_wenjianjia2' : 'icon_wenjianjia1'},on:{"click":function($event){return _vm.preview(appendixes)}}})],1)],1),_c('a-divider')],1)}),(!_vm.showPostScriptArea && _vm.uid === _vm.$store.getters['uid'] && _vm.pid)?_c('div',{staticClass:"text-center"},[_c('a-button',{on:{"click":function($event){$event.stopPropagation();return _vm.addPostScript.apply(null, arguments)}}},[_vm._v("新增附言")])],1):_vm._e()],2):_vm._e(),(
            !(_vm.commentList && _vm.commentList.length !== 0) && !_vm.showPostScriptArea
          )?_c('div',{staticClass:"text-center"},[_c('icon-svg',{attrs:{"icon":"post","size":"200px"}}),_c('p',{staticClass:"text-gray-500",staticStyle:{"font-size":"12px"}},[_vm._v("未发起附言")]),(_vm.uid === _vm.$store.getters['uid'] && _vm.pid)?_c('a-button',{staticClass:"mt-4",on:{"click":function($event){$event.stopPropagation();return _vm.addPostScript.apply(null, arguments)}}},[_vm._v(" 新增附言 ")]):_vm._e()],1):_vm._e(),(_vm.showPostScriptArea)?_c('div',{staticClass:"w-full flex flex-wrap justify-start",staticStyle:{"padding-left":"24px"}},[_c('a-textarea',{attrs:{"placeholder":"限300字","rows":6},model:{value:(_vm.comment.comment),callback:function ($$v) {_vm.$set(_vm.comment, "comment", $$v)},expression:"comment.comment"}}),_c('div',{staticClass:"w-full border border-gray-400 border-t-0 flex content-center justify-between",staticStyle:{"background-color":"#eff3f5"}},[_c('div',{staticClass:"ml-4"},[_c('icon-font',{staticClass:"text-xl",attrs:{"type":_vm.comment.appendixes ? 'icon_wenjianjia2' : 'icon_wenjianjia1'},on:{"click":function($event){return _vm.uploadFile(_vm.comment.appendixes)}}})],1),_c('div',{staticClass:"my-1 mr-4"},[_c('a-button',{staticClass:"mr-4",attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(" 提交 ")]),_c('a-button',{on:{"click":_vm.cancel}},[_vm._v("取消")])],1)])],1):_vm._e()],2)],1)],1),(_vm.showUpload)?_c('postscript-upload-modal',{attrs:{"uploadName":"附言","uploadFilelistInfo":_vm.uploadFilelistInfo},on:{"close":function($event){_vm.showUpload = false},"hasUpload":function($event){return _vm.hasUpload($event)}}}):_vm._e(),(_vm.showPreview)?_c('preview-modal',{attrs:{"uploadName":"附言","previewFileListInfo":_vm.previewFileListInfo},on:{"close":function($event){_vm.showPreview = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }