<template>
  <div>
    <a-table
      class="app-list"
      :rowKey="tableId"
      :dataSource="dataList"
      :columns="columns"
      :pagination="false"
      :loading="loading"
      :scroll="{ x: scroll }"
      :rowClassName="
        (record, index) => {
          if (record.deprecated) return 'deprecated-row'
          return index % 2 === 0 ? '' : 'trBgClass'
        }
      "
    >
      <span slot="file" slot-scope="text, record">
        <template v-if="billType !== 'Reim'">
          <a
            v-if="record.fileProp"
            style="color: #4587f5"
            @click="$emit('preview', record)"
          >
            附件查看
            <span>
              (
              {{ record.fileNum }}
              )
            </span>
          </a>
          <span v-else>~</span>
        </template>
      </span>
      <!-- 单价 -->
      <span slot="price" slot-scope="text, record">
        <a-tag v-if="record.price === '--'">--</a-tag>
        <template v-else>
          <template v-if="isChange(record.priceProp)">
            <a-tag color="#f50">
              原值：{{ originData[record.priceProp] || 0 }}
            </a-tag>
            <a-tag color="#87d068">现值：{{ record.price || 0 }}</a-tag>
          </template>
          <template v-else>
            <a-tag color="#87d068">现值：{{ record.price || 0 }}</a-tag>
          </template>
        </template>
      </span>
      <!-- 数量 -->
      <span slot="num" slot-scope="text, record">
        <a-tag v-if="record.num === '--'">--</a-tag>
        <template v-else>
          <template v-if="isChange(record.numProp)">
            <a-tag color="#f50">
              原值：{{ originData[record.numProp] || 0 }}
            </a-tag>

            <a-tag color="#87d068">现值：{{ record.num || 0 }}</a-tag>
          </template>
          <template v-else>
            <a-tag color="#87d068">现值：{{ record.num || 0 }}</a-tag>
          </template>
        </template>
      </span>
      <!-- 总价 -->
      <span slot="total" slot-scope="text, record">
        <template v-if="record.totalProp">
          <template v-if="isChange(record.totalProp)">
            <a-tag color="#f50">原值：{{ originData[record.totalProp] }}</a-tag>
            <a-tag color="#87d068">现值：{{ record.total || 0 }}</a-tag>
          </template>
          <template v-else>
            <a-tag color="#87d068">现值：{{ record.total || 0 }}</a-tag>
          </template>
        </template>

        <template v-else>
          <template v-if="isTotalChange(record.priceProp, record.numProp)">
            <a-tag color="#f50">
              原值：{{
                $np.times(
                  originData[record.priceProp] || 0,
                  originData[record.numProp] || 0
                ) || 0
              }}
            </a-tag>
            <a-tag color="#87d068">现值：{{ record.total || 0 }}</a-tag>
          </template>
          <template v-else>
            <a-tag color="#87d068">现值：{{ record.total || 0 }}</a-tag>
          </template>
        </template>
      </span>
    </a-table>
  </div>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
export default {
  name: 'FeeTable',
  props: {
    tableId: {
      type: String,
      default: 'id'
    },
    dataList: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    billType: {
      type: String,
      default: '',
      required: false
    },
    scroll: {
      type: String,
      default: null
    },
    originData: {
      type: Object,
      default: () => ({})
    },
    curData: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    // 附件信息
    onPreviewClick(title, previewInfo) {
      this.$emit('previewFile', title, previewInfo)
    },

    isChange(prop) {
      if (isEmpty(this.originData) && isEmpty(this.curData)) return

      return this.originData[prop] !== this.curData[prop]
    },

    isTotalChange(priceProp, numProp) {
      if (isEmpty(this.originData) && isEmpty(this.curData)) return

      return (
        this.originData[priceProp] !== this.curData[priceProp] ||
        this.originData[numProp] !== this.curData[numProp]
      )
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .deprecated-row {
  background: #d9d9d9;
}
/deep/.ant-table-tbody
  > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td {
  background: transparent;
}
</style>
