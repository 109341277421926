<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="单据信息" key="1" class="customStyle">
          <div class="ml-2 mt-3 flex flex-wrap justify-start">
            <div class="form-item">
              <span class="form-label">单据号</span>
              <a-input
                class="form-input"
                v-model="receipts.billCode"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">单据日期</span>
              <a-input
                class="form-input"
                v-model="receipts.billDate"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">制单人</span>
              <a-input
                class="form-input"
                v-model="billMakerName"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">制单时间</span>
              <a-input
                class="form-input"
                v-model="receipts.makeTime"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">修改人</span>
              <a-input
                class="form-input"
                v-model="modiferName"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">修改时间</span>
              <a-input
                class="form-input"
                v-model="receipts.modiftime"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">单据状态</span>
              <a-input
                class="form-input"
                v-model="billStatus"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">支付状态</span>
              <a-input
                class="form-input"
                :value="receipts.payStatus ? '已支付' : '未支付'"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">退款标记</span>
              <a-input
                class="form-input"
                v-model="receipts.refundSign"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">交易明细退款号</span>
              <a-input
                class="form-input"
                :value="receipts.refundDetailNo"
                :disabled="true"
              />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import { getUserOne } from '@/api/sysadmin/users'
import moment from 'moment'
export default {
  name: 'receiptInfo',
  props: {
    receipts: {
      type: Object,
      required: true
    },
    type: String,
    uid: String,
    username: String,
    status: String
  },
  data() {
    return {
      billMakerName: '', //制单人名称
      modiferName: '' //修改人名称
    }
  },
  computed: {
    billStatus() {
      return this.queryStatus(this.status)
    }
  },
  watch: {
    receipts() {
      this.billMakerName = this.username
      if (this.receipts.modifer) {
        getUserOne(this.receipts.modifer).then(response => {
          this.modiferName = response.username
        })
      }
    },
    username(nval) {
      this.billMakerName = nval
    }
  },
  created() {
    if (this.type !== 'check') {
      this.receipts.billMaker = this.uid
      this.billMakerName = this.username
      this.submit()
    }
  },
  methods: {
    submit() {
      this.$emit('update:receipts', this.receipts)
    },

    //单据状态查询
    queryStatus(status) {
      if (status === '1') return '待发'
      if (status === '3') return '待处理'
      if (status === '4') return '处理中'
      if (status === '5') return '撤销'
      if (status === '15') return '终止'
      if (status === '0') return '审批通过'
      if (status === '98') return '回退'
      if (status === '99') return '作废'
    },

    moment
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
