var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{staticClass:"active-modal",attrs:{"title":"选择活动类型及执行标准","width":"800px","visible":_vm.visible,"keyboard":false},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel}},[_c('div',{staticClass:"active-content w-full"},[_c('a-table',{staticClass:"active-table",attrs:{"rowKey":"actID","rowSelection":{
          onChange: _vm.onSelectChange,
          type: 'radio',
          selectedRowKeys: _vm.selectedRowKeys
        },"pagination":false,"columns":_vm.columns,"centered":true,"dataSource":_vm.activeTypeList,"loading":_vm.loading,"rowClassName":function (record, index) {
            return index % 2 === 0 ? 'trBgClass' : ''
          },"customRow":function (record) {
            return {
              on: {
                dblclick: function () {
                  _vm.handleClick(record)
                },
                click: function () {
                  _vm.sclick(record)
                }
              }
            }
          }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }