import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**************** 区域外时间接口**********************/
/**
 * 获取区域外时间列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} condition
 */
export function getOutSideTimeList(
  pageindex,
  pagenum,
  condition = [],
  isShared = false
) {
  return request({
    url: `/outsideTime/pc/${pageindex}/${pagenum}`,
    method: 'POST',
    data: condition,
    params: {
      isShared
    }
  })
}
/**
 * 根据id查询区域外时间详情
 * @param {String} id
 */
export function getOutSideTimeDetail(id) {
  return request({
    url: `/outsideTime/${id}`,
    method: 'GET'
  })
}
/**
 * 新增区域外时间
 * @param {Boolean} status
 */
export function addOutSideTime(status, data) {
  return request({
    url: `/outsideTime/${status}`,
    method: 'POST',
    data
  })
}
/**
 * 更新区域外时间
 * @param {Boolean} status
 */
export function updateOutSideTime(status, data) {
  return request({
    url: `/outsideTime/${status}`,
    method: 'PUT',
    data
  })
}

/**
 * 导出区域外时间数据
 */
export function getExportExcel(conditions) {
  return request({
    method: 'post',
    url: `outsideTime/exportExcel`,
    responseType: 'arraybuffer',
    data: conditions
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `区域外时间信息`)
  })
}
