<template>
  <div>
    <div style="padding-left: 32px" class="my-4">
      <a-form
        size="small"
        laba-width="90px"
        laba-position="right"
        layout="vertical"
      >
        <a-collapse v-model="activeKey" :bordered="false">
          <template v-slot:expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel
            class="customStyle"
            v-for="{ title, active, children } in baseMsg"
            :header="title"
            :key="active"
          >
            <a-col :span="8" v-for="{ label, value } in children" :key="label">
              <a-form-item
                :label="label"
                :prop="value"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input
                  v-if="!value"
                  :disabled="true"
                  value="拜访补签"
                ></a-input>
                <a-input
                  v-else-if="value === 'appStatus'"
                  :disabled="true"
                  :value="getStatusText(formData[value])"
                ></a-input>
                <a-input
                  v-else
                  :disabled="true"
                  v-model="formData[value]"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-collapse-panel>
          <a-collapse-panel
            class="customStyle"
            header="产品关键信息"
            key="PRODUCT"
            v-if="formData.keyInfoDTOList && formData.keyInfoDTOList.length"
          >
            <div
              v-for="(item, index) in formData.keyInfoDTOList"
              :key="index"
              class="mb-8"
            >
              <div class="app-title">
                <span style="font-weight: 600">{{ item.productName }}</span>
              </div>
              <div
                class="border"
                v-if="item.keyInfoList && item.keyInfoList.length"
              >
                <div class="app-content">
                  <p v-for="subItem in item.keyInfoList" :key="subItem.id">
                    {{ subItem.content }}
                  </p>
                </div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <process
          v-if="formData.flowInfoDTO"
          :summaryid="formData.flowInfoDTO.summaryid"
          :nextapproveman="formData.flowInfoDTO.nextapproveman"
          :curApprover="formData.flowInfoDTO.curApprover"
        ></process>
        <comment :billId="formData.id"></comment>
      </a-form>
    </div>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import Process from '@/views/custmanage/modify/components/Process'
import { BASE } from './visit-config'
import { getVisitRetroactById } from '@/api/sale/visit'
import { getStatusText } from '@/configs/bill-msg'
export default {
  name: 'SpeakerModifyBill',
  components: { Process, Comment },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      value: 'a',
      formData: {},
      baseMsg: BASE,
      activeKey: ['BASE', 'PRODUCT'],
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getVisitRetroactById(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData.appStatus)
    })
  },
  methods: {
    getStatusText
  }
}
</script>
<style lang="less" scoped>
a {
  display: block;
  /*超出隐藏*/
  overflow: hidden;
  /*禁止换行*/
  white-space: nowrap;
  /*超出用省略号代替*/
  text-overflow: ellipsis;
}

/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
}
/deep/ .ant-btn-primary[disabled],
/deep/.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
/deep/.ant-btn-primary {
  background-color: #4587f4;
}
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.app-title {
  padding: 16px;
  background-color: #fafafa;
  border-width: 1px 1px 0 1px;
  border-radius: 4px 4px 0 0;
}

.app-content {
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  &:last-child {
    border: none;
  }
}
</style>
