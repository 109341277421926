import request from '@/utils/request'

/**
 * 机构检测单价列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getHcvAgencyList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `/hcv/agencyDoc/page/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新机构检测单价
 * @param {Object} data
 * @returns
 */
export function updateHcvAgency(data) {
  return request({
    method: 'post',
    url: `/hcv/agencyDoc/update`,
    data
  })
}

/**
 * 保存机构检测单价
 * @param {Object} data
 * @returns
 */
export function saveHcvAgency(data) {
  return request({
    method: 'post',
    url: `/hcv/agencyDoc/add`,
    data
  })
}

/**
 * 删除机构检测单价
 * @param {String} id
 * @returns
 */
export function delHcvAgency(id) {
  return request({
    method: 'post',
    url: `/hcv/agencyDoc/del/${id}`
  })
}

/**
 * 获取机构检测单价
 * @param {String} id
 * @returns
 */
export function getHcvAgency(id) {
  return request({
    method: 'get',
    url: `/hcv/agencyDoc/${id}`
  })
}
