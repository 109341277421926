import enquireJs from 'enquire.js'

/**
 * 1024*600 （常见8.9寸电脑使用）
 * 1024*768（常用10.4、12.1、14.1、15寸电脑使用）4:3
 * 1280*1024（常用14.1、15寸电脑使用）5:4
 * 1600 * 900 16:9 (非主流)
 * 1440*1050（常用15、16.1寸电脑使用）4:3
 * 1600*1200（常用15、16.1寸电脑使用）4:3
 * 1280*800（常见10.8、12.1、15.4寸电脑使用）16:10
 * 1366*768（常见15.2寸电脑使用）15:10 （主流）
 * 1280*854（不常见）16:9
 * 1440*900（仅苹果17寸电脑使用）16:10
 * 1600*1024（不常见）14:9
 * 1680*1050（常见15.4、20寸电脑使用）16:10
 * 1920*1080 16:9 （主流）
 * 1920*1200（常见20寸电脑使用）16:10
 *
 * 1024~1279 min
 * 1280~1359 small
 * 1360~1439 medium
 * 1440~1599 big
 * 1600~1920 large
 */

export const DEVICE_TYPE = {
  MIN: { size: 'min', length: 16 },
  SMALL: { size: 'small', length: 24 },
  MEDIUM: { size: 'medium', length: 36 },
  BIG: { size: 'big', length: 40 },
  LARGE: { size: 'large', length: 50 }
}

export const deviceEnquire = function (callback) {
  const matchMin = {
    match: () => {
      callback && callback(DEVICE_TYPE.MIN)
    }
  }

  const matchSmall = {
    match: () => {
      callback && callback(DEVICE_TYPE.SMALL)
    }
  }

  const matchMedium = {
    match: () => {
      callback && callback(DEVICE_TYPE.MEDIUM)
    }
  }

  const matchBig = {
    match: () => {
      callback && callback(DEVICE_TYPE.BIG)
    }
  }

  const matchLarge = {
    match: () => {
      callback && callback(DEVICE_TYPE.LARGE)
    }
  }

  enquireJs
    .register(
      'screen and (min-width: 1024px) and (max-width: 1279px)',
      matchMin
    )
    .register(
      'screen and (min-width: 1280px) and (max-width: 1359px)',
      matchSmall
    )
    .register(
      'screen and (min-width: 1360px) and (max-width: 1439px)',
      matchMedium
    )
    .register(
      'screen and (min-width: 1440px) and (max-width: 1599px)',
      matchBig
    )
    .register(
      'screen and (min-width: 1600px) and (max-width: 1920px)',
      matchLarge
    )
}
