import request from '@/utils/request'

/**
 * 获取用户菜单
 */
export function getMenus() {
  return request({
    method: 'get',
    url: 'menu/getMenus'
  })
}
/**
 * 根据角色获取菜单
 */
export function getMenuPmsByRole(params) {
  return request({
    method: 'post',
    url: 'rolemenu/getMenuByRoleID',
    data: params
  })
}
/**
 * 通过角色关联菜单权限
 */
export function roleAsignMenu(roleid, menus) {
  return request({
    method: 'post',
    url: 'rolemenu/asignRoleMenu',
    params: {
      roleId: roleid
    },
    data: menus
  })
}

/**
 *递归处理添加key,title,icon
 */
export function transMenuItems(menus, itemsmap) {
  for (let menu of menus) {
    menu = Object.assign(menu, {
      key: menu.menuId,
      title: menu.name,
      type: 1,
      disabled: false,
      slots: {
        icon: 'folder'
      }
    })
    itemsmap.set(menu.menuId, {
      id: menu.menuId,
      parentId: menu.parentId,
      type: 1,
      obj: menu,
      hassubMenus: menu.subMenus === null ? false : true
    })
    if (menu.subMenus && menu.subMenus !== null) {
      transMenuItems(menu.subMenus, itemsmap)
    } else if (menu.permissions && menu.permissions !== null) {
      transPromiseItems(menu, itemsmap)
    }
  }
}

/**
 *处理权限子节点
 */
function transPromiseItems(menu, itemsmap) {
  for (let permission of menu.permissions) {
    itemsmap.set(permission.pid, {
      id: permission.pid,
      parentId: menu.menuId,
      obj: menu,
      permission: permission,
      type: 2
    })
    permission = Object.assign(permission, {
      key: permission.pid,
      type: 2,
      parentId: menu.menuId,
      title: permission.name,
      disabled: false,
      slots: {
        icon: 'folder'
      }
    })
  }
}

/**
 *递归处理添加children
 */
export function addMenuChildren(menus, lenmap) {
  for (let menu of menus) {
    menu = Object.assign(menu, {
      children: menu.subMenus
    })
    if (menu.subMenus && menu.subMenus !== null) {
      lenmap.set(menu.menuId, menu.subMenus.length)
      addMenuChildren(menu.subMenus, lenmap)
    } else if (menu.permissions && menu.permissions !== null) {
      lenmap.set(menu.menuId, menu.permissions.length)
      menu = Object.assign(menu, {
        children: menu.permissions
      })
    }
  }
}

/**
 *将所有父节点扔进展开数组
 */
export function expandParents(expeandkeys, tree, deep = 1) {
  if (!deep) return

  const deepLevel = deep - 1
  for (let node of tree) {
    if (node.children && node.children !== null) {
      expeandkeys.push(node.key)
      expandParents(expeandkeys, node.children, deepLevel)
    }
  }
}

/**
 * 组装树结构表单数据
 */

export function potSubmitData(checkedKeys, itemsmap) {
  let checkedMenus = []
  //当前数据
  for (let key of checkedKeys) {
    let item = itemsmap.get(key)
    if (item.type === 1 && !item.hassubMenus) {
      //处理菜单选中
      let menua = checkedMenus.filter(menuitema => {
        return menuitema.menuId === item.id
      })
      if (menua.length === 0) {
        let menuax = {
          menuId: item.id,
          parentId: item.parentId,
          subMenus: null,
          permissions: []
        }
        checkedMenus.push(menuax)
      }
    } else if (item.type === 2) {
      let menub = checkedMenus.filter(menuitemb => {
        return menuitemb.menuId === item.parentId
      })
      if (menub.length === 0) {
        let menubx = {
          menuId: item.parentId,
          parentId: item.obj.parentId,
          subMenus: null,
          permissions: [item.permission]
        }
        checkedMenus.push(menubx)
      } else {
        menub[0].permissions.push(item.permission)
      }
    }
  }
  return checkedMenus
}
