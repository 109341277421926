export default {
  computed: {
    isRead() {
      return this.status
    },
    budgetMealsTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetMeals),
        Number(this.feeInfo.budgetMealsNum)
      )
    },
    budgetAccommodationTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetAccommodation),
        Number(this.feeInfo.budgetAccommodationNum)
      )
    },
    budgetBoothTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetBooth),
        Number(this.feeInfo.budgetBoothNum)
      )
    },
    budgetMeetingroomTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetMeetingroom),
        Number(this.feeInfo.budgetMeetingroomNum)
      )
    },
    budgetFlightTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetFlight),
        Number(this.feeInfo.budgetFlightNum)
      )
    },
    budgetTrainTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetTrain),
        Number(this.feeInfo.budgetTrainNum)
      )
    },
    budgetReceptionTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetReception),
        Number(this.feeInfo.budgetReceptionNum)
      )
    },
    budgetMakeTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetMake),
        Number(this.feeInfo.budgetMakeNum)
      )
    },
    budgetDesignTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetDesign),
        Number(this.feeInfo.budgetDesignNum)
      )
    },
    budgetThirdpartyTotal() {
      return this.$np.times(
        Number(this.feeInfo.budgetThirdparty),
        Number(this.feeInfo.budgetThirdpartyNum)
      )
    }
  },
  watch: {
    conferenceTypeName(nval) {
      if (nval === '地区') {
        this.feeInfo.budgetBooth = null
        this.feeInfo.budgetBoothNum = null
        this.submit()
      }
    },

    //协办（为自办是协办费用为0，且不可编辑）
    activityNatrue(nval) {
      this.natrue = nval
      if (nval === '自办') {
        this.feeInfo.budgetCooperationTotal = 0
        if (!this.isRead && this.hasconfContract === '否') {
          this.feeInfo.budgetServiceTotal = 0
        }
      } else {
        if (!this.feeInfo.budgetCooperationTotal)
          this.feeInfo.budgetCooperationTotal = null

        if (!this.isRead) {
          this.feeInfo.budgetServiceTotal = null
        }
      }
      this.submit()
    },

    hasconfContract(nval) {
      if (!this.isRead) {
        if (nval === '否' && this.activityNatrue === '自办') {
          this.feeInfo.budgetServiceTotal = 0
        } else {
          this.feeInfo.budgetServiceTotal = null
        }
        this.submit()
      }
    },

    // 推广物料金额
    brandpreMoneytotal(nval) {
      if (nval) {
        this.feeInfo.budgetBrandpreTotal = Number(nval)
      } else {
        this.feeInfo.budgetBrandpreTotal = Number('0')
      }
      this.submit()
    },

    // 预付金额
    budgetPreCost(nval) {
      if (nval) {
        this.preCost = Number(nval)
      } else {
        this.preCost = Number('0')
      }
      this.submit()
    },

    infoPubAmount() {
      this.submit()
    },

    // 撤消时改变批准总预算清0
    'feeInfo.budgetApprovetotal'(nval) {
      if (nval) {
        this.feeInfo.budgetApprovetotal = Number(nval)
      } else {
        this.feeInfo.budgetApprovetotal = Number('0')
      }
      this.submit()
    },
    // 讲者金额
    budgetConsultTotal(nval) {
      if (nval) {
        this.consultTotal = Number(nval)
      } else {
        this.consultTotal = Number('0')
      }
      this.submit()
    }
  },
  methods: {
    // 计算费用合计
    calculate() {
      this.feeInfo.budgetTotal = 0
      this.feeInfo.budgetTotal = this.$np.plus(
        this.budgetMealsTotal,
        this.budgetAccommodationTotal,
        this.budgetBoothTotal,
        this.budgetMeetingroomTotal,
        this.budgetFlightTotal,
        this.budgetTrainTotal,
        this.budgetReceptionTotal,
        this.budgetMakeTotal,
        this.budgetDesignTotal,
        this.budgetThirdpartyTotal,
        Number(this.feeInfo.budgetBrandpreTotal),
        Number(this.feeInfo.budgetCooperationTotal),
        Number(this.consultTotal),
        Number(this.infoPubAmount),
        Number(this.feeInfo.budgetServiceTotal),
        Number(this.feeInfo.otherFee), // 其他费用
        Number(this.feeInfo.registerFee), // 注册费用
        Number(this.feeInfo.onlineLiveBroadcastingFee)
      )
    }
  }
}
