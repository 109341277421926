<template>
  <div class="text-xs" style="padding-left: 32px">
    <HcvReimForm
      :type="type"
      :pid="pid"
      @status="nowStatus($event)"
    ></HcvReimForm>
  </div>
</template>

<script>
import HcvReimForm from '@/views/activity/hcvWriteOff/HcvReimForm'

export default {
  name: 'GFHX_BILL',
  components: {
    HcvReimForm
  },
  props: {
    type: String,
    pid: String
  },

  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
