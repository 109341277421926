<template>
  <a-form layout="horizontal" :model="form" size="small" class="mt-2">
    <a-row>
      <a-col
        v-for="{ label, type, prop, span, options, value } in getFprops"
        :key="prop"
        :span="span ? 12 * span : 12"
      >
        <a-form-item
          :label="label"
          :labelCol="getLabelCol(span)"
          :wrapperCol="getWrapperCol(span)"
        >
          <a-input
            v-if="type === 'input'"
            :value="prop && !value ? form[prop] : value"
            disabled
          />
          <a-select v-else-if="type === 'select'" v-model="form[prop]" disabled>
            <a-select-option
              v-for="{ label: subLabel, value, color } in options"
              :key="value"
              :value="value"
            >
              <span :class="color">{{ subLabel }}</span>
            </a-select-option>
          </a-select>
          <a-textarea
            disabled
            v-else-if="type === 'textarea'"
            v-model="form[prop]"
          />
          <a-popover
            placement="left"
            v-else-if="type === 'slot'"
            overlayClassName="popover"
            trigger="click"
          >
            <template slot="content">
              <div class="p-2">
                <p class="mb-2">
                  <label class="mr-2 font-bold">单据号:</label>
                  <span>{{ bill.billCode || '无' }}</span>
                </p>
                <p class="mb-2">
                  <label class="mr-2 font-bold">制单人:</label>
                  <span>{{ bill.creationUserName || '无' }}</span>
                </p>
                <p class="mb-2">
                  <label class="mr-2 font-bold">制单时间:</label>
                  <span>{{ bill.creationTime || '无' }}</span>
                </p>
                <p class="mb-2">
                  <label class="mr-2 font-bold">单据类型:</label>
                  <span>{{ bill.type || '无' }}</span>
                </p>
                <p>
                  <label class="mr-2 font-bold">单据状态:</label>
                  <span>
                    {{ bill.status ? getStatusText(bill.status) : '无' }}
                  </span>
                </p>
              </div>
            </template>
            <a-button class="border-blue-400">查看单据详情</a-button>
          </a-popover>

          <span v-else>{{ form[prop] }}</span>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import { getRelatedBill } from '@/api/acdemicActive/acdemic-payment'
import { getStatusText } from '@/configs/bill-msg'
const col = {
  1: { labelCol: { span: 6 }, wrapperCol: { span: 16 } },
  2: { labelCol: { span: 3 }, wrapperCol: { span: 20 } }
}
/**
 * 是否重复票据对应关系
 */
const DUP_OPTIONS = [
  { value: 0, label: '否', color: 'text-green-600' },
  { value: 1, label: '是', theme: 'danger', color: 'text-red-600' },
  { value: 2, label: '疑似重复', theme: 'warning', color: 'text-orange-600' }
]

/**
 * 来源方式对应关系
 */
const SOURCE_OPTIONS = [
  { value: 0, label: '自动识别', color: 'text-green-500' },
  { value: 1, label: '手记一笔', color: 'text-blue-500' },
  { value: 2, label: '手动修改', color: 'text-orange-500' }
]

export default {
  name: 'InvoiceForm',
  props: {
    form: {
      type: Object,
      default: () => {}
    },

    isDup: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      fprops: [
        { label: '发票类型', type: 'input', prop: 'typename' },
        {
          label: '是否重复票据',
          type: 'select',
          prop: 'status',
          options: DUP_OPTIONS
        },
        {
          label: '来源方式',
          type: 'select',
          prop: 'source',
          options: SOURCE_OPTIONS
        },
        { label: '类别', type: 'input', prop: 'kind' },
        { label: '金额', type: 'input', prop: 'total' },
        { label: '创建时间', type: 'input', prop: 'createtime' },
        { label: '开票日期', type: 'input', prop: 'ddate' },
        { label: '发票号码', type: 'input', prop: 'nnumber' },
        { label: '发票代码', type: 'input', prop: 'code' },
        { label: '校验码', type: 'input', prop: 'checkCode' },
        { label: '机器码', type: 'input', prop: 'machineCode' },
        { label: '税前金额', type: 'input', prop: 'pretaxAmount' },
        { label: '税额', type: 'input', prop: 'tax' },
        { label: '购买方名称', type: 'input', prop: 'buyer' },
        { label: '购买方税号', type: 'input', prop: 'buyerTaxId' },
        { label: '销售方名称', type: 'input', prop: 'seller' },
        { label: '销售方税号', type: 'input', prop: 'sellerTaxId' },
        { label: '车次号', type: 'input', prop: 'trainNumber' },
        { label: '出发日期', type: 'input', prop: 'fromDate' },
        { label: '出发城市', type: 'input', prop: 'fromCity' },
        { label: '到达城市', type: 'input', prop: 'toCity' },
        { label: '舱位等级', type: 'input', prop: 'className' },
        { label: '航班号', type: 'input', prop: 'flightNumber' },
        { label: '备注', type: 'textarea', prop: 'remark', span: 2 }
      ],

      //查看门户的单据信息或crm的单据信息
      bill: {
        billCode: '',
        creationTime: '',
        creationUserName: '',
        status: '',
        type: ''
      }
    }
  },

  computed: {
    getFprops() {
      const { pkSrc: hpk, usercode, username } = this.form || {}
      const fprops = this.fprops
      if (this.isDup && fprops.length <= 18) {
        // 调用查看门户或crm的报销单
        if (hpk) this.getRelatedBill(hpk)
        fprops.splice(
          4,
          0,
          { label: '用户名', type: 'input', value: username || usercode },
          {
            label: '关联报销单',
            type: hpk ? 'slot' : 'input',
            value: '未制单'
          }
        )
      }
      return fprops
    }
  },

  methods: {
    getStatusText,

    /**
     * 获取label的宽度
     */
    getLabelCol(span) {
      if (!span) return col['1'].labelCol
      return col[span].labelCol
    },
    /**
     * 获取wapper的宽度
     */
    getWrapperCol(span) {
      if (!span) return col['1'].wrapperCol
      return col[span].wrapperCol
    },

    getRelatedBill(pk) {
      if (!pk) {
        this.bill.billCode = ''
        this.bill.creationTime = ''
        this.bill.creationUserName = ''
        this.bill.status = ''
        this.bill.type = ''
        return
      }
      getRelatedBill(pk).then(res => {
        Object.assign(this.bill, res || {})
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 0px;
}

/deep/ .ant-form-item-label label {
  font-size: 12px;
  color: #666;
  font-weight: 600;
}
</style>
