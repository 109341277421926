import request from '@/utils/request'

// 部门接口
/**
 * 创建部门
 * @param {String} deptcode
 * @param {String} deptname
 * @param {String} deptdesc
 * @param {String} did
 */
export function addDeptInfo(data) {
  return request({
    method: 'post',
    url: 'dept/createdept',
    data: data
  })
}

/**
 * 更新部门 put
 * @param {String} deptcode
 * @param {String} deptname
 * @param {String} deptdesc
 * @param {String} did
 */
export function updateDeptInfo(data) {
  return request({
    method: 'put',
    url: 'dept/upddept',
    data: data,
    customConfig: {
      widthToken: true
    }
  })
}

/**
 * 删除部门
 * @param {String} deptcode
 * @param {String} deptname
 * @param {String} deptdesc
 * @param {String} did
 */
export function deleteDept(params) {
  return request({
    method: 'put',
    url: 'dept/removedept',
    params: {
      ...params
    }
  })
}

//部门详情
export function loadDeptInfo(params) {
  return request({
    url: `dept/getdeptbyId`,
    method: 'get',
    params: {
      ...params
    }
  })
}
/**
 * 查询所有部门
 */
export function getDeptList() {
  return request({
    method: 'get',
    url: 'dept/querydepts',
    customConfig: {
      widthToken: true
    }
  })
}

/**
 * 查询所有部门 (列表)
 */
export function getDepts(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: 'dept/querydeptsByType',
    params: {
      pageindex,
      pagenum,
      type: 'dq|sq'
    },
    data: conditions
  })
}

/**
 * 根据id获取上级部门信息
 * @param {String} did
 */
export function getParentDeptsById(did) {
  return request({
    method: 'get',
    url: 'dept/getParentDeptsById',
    params: { did }
  })
}

/**
 * 分层次分别查询所有的大区，所有的省区，所有的地办和所有的部门
 * @param {Object} params(dq:大区,sq:省区,db:地办)
 */
export function queryDeptsByType(page, type, data = []) {
  const types = type ? { type: type } : {}
  return request({
    method: 'post',
    url: 'dept/querydeptsByType',
    params: { pageindex: page.current, pagenum: page.pageSize, ...types },
    data: data
  })
}

/**
 *递归处理添加key,title,value
 */
export function addDeptSelectValue(depts) {
  for (let dept of depts) {
    dept = Object.assign(dept, {
      key: dept.did,
      title: dept.deptname,
      value: dept.did
    })
    if (dept.subDepts && dept.subDepts !== null) {
      addDeptSelectValue(dept.subDepts)
    }
  }
}
/**
 *递归处理添加key,title,icon
 */
export function addDeptIcon(depts) {
  for (let dept of depts) {
    dept = Object.assign(dept, {
      key: dept.did,
      title: dept.deptname,
      disabled: false,
      slots: {
        icon: 'folder'
      }
    })
    if (dept.subDepts && dept.subDepts !== null) {
      addDeptIcon(dept.subDepts)
    }
  }
}
/**
 *递归处理添加children
 */
export function addDeptChildren(depts) {
  for (let dept of depts) {
    dept = Object.assign(dept, {
      children: dept.subDepts
    })
    if (dept.subDepts && dept.subDepts !== null) {
      addDeptChildren(dept.subDepts)
    }
  }
}

/**
 *递归处理禁用children
 */
function disabledChildren(depts) {
  for (let dept of depts) {
    dept = Object.assign(dept, {
      disabled: true
    })
    if (dept.children && dept.children !== null) {
      disabledChildren(dept.children)
    }
  }
}

/**
 *递归处理启用children
 */
function startChildren(depts) {
  for (let dept of depts) {
    dept = Object.assign(dept, {
      disabled: false
    })
    if (dept.children && dept.children !== null) {
      startChildren(dept.children)
    }
  }
}

/**
 *禁用自己及以下节点
 */
export function disabledDepts(depts, self) {
  let isfind = false
  for (let dept of depts) {
    if (dept.did == self.did) {
      disabledChildren(Array.of(dept))
      isfind = true
    } else {
      if (isfind) {
        startChildren(Array.of(dept))
      } else {
        dept = Object.assign(dept, {
          disabled: false
        })
        if (
          dept.children &&
          dept.children !== null &&
          dept.children.length > 0
        ) {
          disabledDepts(dept.children, self)
        }
      }
    }
  }
}

/**
 *将所有父节点扔进展开数组
 */
export function expandParents(expeandkeys, depts) {
  for (let dept of depts) {
    if (dept.subDepts && dept.subDepts !== null) {
      expeandkeys.push(dept.did)
      expandParents(expeandkeys, dept.subDepts)
    }
  }
}
