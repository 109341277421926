<template>
  <div>
    <div class="mt-4">
      <span
        slot="extra"
        class="font-medium"
        v-if="checkUnitInfo && checkUnitInfo.length"
      >
        不一致票据
        <a-popover placement="bottomRight" overlayClassName="popover">
          <template slot="content">
            <div class="px-2 popover-content" style="">
              <p v-for="item in checkUnitInfo" :key="item.pk">
                {{ item.nnumber }}
              </p>
            </div>
          </template>
          <template slot="title">
            <span>票据发票号码</span>
          </template>
          <a-icon type="down" />
        </a-popover>
      </span>
      <div>
        <a-table
          size="small"
          :rowKey="record => record.pk || record.id"
          :dataSource="getBillInfos"
          :columns="getColumns"
          :pagination="false"
          :scroll="{ x: '4800px' }"
          :rowClassName="
            record => {
              return checkUnitInfos.includes(record.nnumber)
                ? 'choose-active'
                : ''
            }
          "
        >
          <template slot="reimBindRender" slot-scope="text, record">
            <span
              :class="[!!record.reimBind ? 'text-green-600' : 'text-red-600']"
            >
              {{ !!record.reimBind ? '是' : '否' }}
            </span>
          </template>
          <template slot="sourceRender" slot-scope="text">
            <span :class="getSourceColor(text)">
              {{ getSourceLabel(text) }}
            </span>
          </template>
          <template slot="statusRender" slot-scope="text, record">
            <span
              :class="[
                getRepeatConfig(text, 'color'),
                text == 1 && record.exceptions
                  ? ' hover:text-blue-600 cursor-pointer'
                  : ''
              ]"
              @click="showRepeatInvoice(text, record)"
            >
              {{ getRepeatConfig(text, 'label') }}
            </span>
          </template>
          <template slot="operatRender" slot-scope="text, record">
            <a-button
              title="查看票据"
              type="link"
              size="small"
              icon="eye"
              @click="showInvoiceDetail(record)"
            ></a-button>

            <a-popconfirm
              overlayClassName="popover"
              title="是否删除当前票据?"
              ok-text="是"
              cancel-text="否"
              @confirm="delItem(record)"
            >
              <a-button
                v-if="!isRead"
                title="删除"
                type="link"
                class="text-red-500"
                size="small"
                icon="delete"
              ></a-button>
            </a-popconfirm>
          </template>
          <template slot="validatedRender" slot-scope="text">
            <span v-if="text == -1" class="text-orange-500">未验证</span>
            <span v-else-if="text == 1" class="text-green-500">真</span>
            <span v-else-if="text == 0" class="text-red-500">假</span>
            <span v-else class="text-blue-500">未知</span>
          </template>
          <template slot="footer" slot-scope="currentPageData">
            <div class="text-right">
              <p class="text-xs text-gray-600 font-bold">
                发票金额合计:
                <span class="text-red-500 px-2">
                  {{
                    currentPageData.reduce((aval, cval) => {
                      return $np.plus(Number(aval), Number(cval.total))
                    }, 0)
                  }}
                </span>
                元
              </p>
            </div>
          </template>
        </a-table>

        <div class="mt-4" v-show="!isRead">
          <a-button class="border-blue-400" @click="checkInvoice">
            选择票据
          </a-button>
        </div>
        <div
          class="mt-4"
          v-has="`invoice:validate`"
          v-if="isRead && isValidatedInvoice"
        >
          <a-button
            :loading="validatLoading"
            class="border-blue-400"
            @click="validatedInvoice"
          >
            发票验真
          </a-button>
        </div>
      </div>
    </div>

    <!-- 选择票据 -->
    <a-modal
      v-model="visible"
      title="请选择票据"
      width="80%"
      :bodyStyle="{ padding: '12px' }"
      @ok="chooseInvoice"
    >
      <a-table
        size="small"
        rowKey="pk"
        :dataSource="datas"
        :columns="billType === 'XSBX' ? getColumns.slice(1) : getColumns"
        :pagination="false"
        :scroll="{ x: '4800px' }"
        :row-selection="{
          columnWidth: 36,
          selectedRowKeys,
          onSelect,
          onSelectAll
        }"
      >
        <template slot="reimBindRender" slot-scope="text, record">
          <span
            :class="[!!record.reimBind ? 'text-green-600' : 'text-red-600']"
          >
            {{ !!record.reimBind ? '是' : '否' }}
          </span>
        </template>
        <template slot="sourceRender" slot-scope="text">
          <span :class="[getSourceColor(text)]">
            {{ getSourceLabel(text) }}
          </span>
        </template>
        <template slot="statusRender" slot-scope="text">
          <span :class="[getRepeatConfig(text, 'color')]">
            {{ getRepeatConfig(text, 'label') }}
          </span>
        </template>
        <template slot="operatRender" slot-scope="text, record">
          <a-button
            title="查看票据"
            type="link"
            size="small"
            icon="eye"
            @click="showInvoiceDetail(record)"
          ></a-button>
        </template>
      </a-table>
      <a-pagination
        showSizeChanger
        showQuickJumper
        class="text-right mt-4"
        :current="current"
        :pageSize="pageSize"
        :total="total"
        :show-total="total => `共 ${total} 条`"
        @change="pageChange"
        @showSizeChange="showSizeChange"
      />
    </a-modal>

    <!-- 票据明细预览 -->
    <a-modal
      v-model="invoicVisible"
      title="票据详情"
      :zIndex="9999"
      width="1024px"
      style="top: 32px"
      :maskClosable="false"
      :footer="false"
      :bodyStyle="{
        padding: '12px',
        height: 'calc(100vh - 124px)',
        overflow: 'auto'
      }"
    >
      <invoice-preview :form="invoiceForm"></invoice-preview>
    </a-modal>

    <a-modal
      v-model="repeatInvoicVisible"
      title="查看重复票"
      width="94%"
      style="top: 12px"
      :maskClosable="false"
      :footer="false"
      :bodyStyle="{
        padding: '8px',
        height: 'calc(100vh - 80px)',
        overflow: 'auto'
      }"
    >
      <repeat-invoice
        :dataSource="repeatInvoices"
        :currPK.sync="currRepeatPk"
      ></repeat-invoice>
    </a-modal>
  </div>
</template>

<script>
import { default as last } from 'lodash/last'
import { default as initial } from 'lodash/initial'
import { default as pullAll } from 'lodash/pullAll'
import { default as pullAllBy } from 'lodash/pullAllBy'
import { default as chunk } from 'lodash/chunk'
// import { getDoc } from '@/configs/doc-config'
import {
  getInvoiceList,
  getValidateBatch
} from '@/api/acdemicActive/acdemic-payment'
import {
  convertKey,
  DUP_OPTIONS,
  SOURCE_OPTIONS
} from '@/configs/invioce-config'
import InvoicePreview from '@/views/activity/modules/expense/InvoicePreview'
import RepeatInvoice from '@/views/activity/modules/expense/RepeatInvoice'

import { has } from '@/api/user/btn-permission'

export default {
  name: 'InvoiceInfo', //备注
  directives: { has },
  components: { InvoicePreview, RepeatInvoice },
  props: {
    billInfos: {
      type: Array,
      required: true
    },
    checkUnitInfo: {
      type: Array,
      required: true
    },
    status: {
      type: Boolean
    },
    billType: {
      type: String,
      default: ''
    },
    isFatch: {
      type: Boolean
    }
  },
  computed: {
    isRead() {
      return this.status
    },

    getBillInfos() {
      return this.billInfos
    },

    repeatInvoices() {
      if (!this.billInfos) return {}
      const dupList = this.billInfos.filter(
        i => i.exceptions && i.exceptions.length
      )
      return dupList
    },

    getColumns() {
      let columns = this.columns
      const lastItem = last(this.columns)
      // isRead => false  =>待发状态
      if (!this.isRead && lastItem.dataIndex === 'validated') {
        columns = initial(columns)
      }

      if (
        this.isRead &&
        (!lastItem.dataIndex || lastItem.dataIndex !== 'validated')
      ) {
        columns.push({
          title: '验真结果',
          width: 56,
          fixed: 'right',
          dataIndex: 'validated',
          scopedSlots: {
            customRender: 'validatedRender'
          }
        })
      }

      return columns
    },

    checkUnitInfos() {
      return this.checkUnitInfo ? this.checkUnitInfo.map(i => i.nnumber) : []
    },

    isValidatedInvoice() {
      if (!this.billInfos) return false
      const list = this.billInfos.filter(v => v.validated == -1)
      return !!list.length
    }
  },

  // created() {
  //   // 新增的时候
  //   this.getList()
  // },

  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,

      rcurrent: 1,
      rpageSize: 10,
      rtotal: 0,

      visible: false,
      invoicVisible: false,
      repeatInvoicVisible: false,

      currRepeatPk: '', //查看的重复单据pk
      sourceOptions: SOURCE_OPTIONS,
      dupOptions: DUP_OPTIONS,
      validatLoading: false,
      datas: [],

      invoiceForm: {}, // 发票的明细
      selectedRowKeys: [], // 选择的列表key
      selectedRows: [], // 选择的列表

      columns: [
        {
          title: '是否重复',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          fixed: 'left',
          width: 84,
          scopedSlots: {
            customRender: 'statusRender'
          }
        },
        {
          title: '金额',
          dataIndex: 'total',
          ellipsis: true,
          width: 72
        },
        {
          title: '发票号码',
          dataIndex: 'nnumber',
          ellipsis: true
        },
        {
          title: '发票代码',
          dataIndex: 'code',
          ellipsis: true
        },
        {
          title: '来源方式',
          dataIndex: 'source',
          ellipsis: true,
          width: 84,
          scopedSlots: {
            customRender: 'sourceRender'
          }
        },
        {
          title: '开票日期',
          dataIndex: 'ddate',
          ellipsis: true
        },
        {
          title: '类别',
          dataIndex: 'kind',
          ellipsis: true,
          width: 72
        },
        {
          title: '发票类型',
          dataIndex: 'typename',
          ellipsis: true
        },
        {
          title: '购买方名称',
          dataIndex: 'buyer',
          ellipsis: true
        },
        {
          title: '购买方税号',
          dataIndex: 'buyerTaxId',
          ellipsis: true
        },
        {
          title: '销售方名称',
          dataIndex: 'seller',
          ellipsis: true
        },
        {
          title: '销售方税号',
          dataIndex: 'sellerTaxId',
          ellipsis: true
        },
        {
          title: '车次号',
          dataIndex: 'trainNumber',
          ellipsis: true
        },
        {
          title: '出发日期',
          dataIndex: 'fromDate',
          ellipsis: true
        },
        {
          title: '出发城市',
          dataIndex: 'fromCity',
          ellipsis: true
        },
        {
          title: '到达城市',
          dataIndex: 'toCity',
          ellipsis: true
        },
        {
          title: '舱位等级',
          dataIndex: 'className',
          ellipsis: true
        },
        {
          title: '航班号',
          dataIndex: 'flightNumber',
          ellipsis: true
        },
        {
          title: '税前金额',
          dataIndex: 'pretaxAmount',
          ellipsis: true,
          width: 84
        },
        {
          title: '税额',
          dataIndex: 'tax',
          ellipsis: true,
          width: 84
        },
        {
          title: '操作',
          width: 96,
          fixed: 'right',
          scopedSlots: {
            customRender: 'operatRender'
          }
        }
      ]
    }
  },

  watch: {
    isFatch(nval) {
      if (nval && !this.datas.lenght) {
        this.getList()
      }
    }
  },

  methods: {
    /**
     * 获取发票来源方式中文
     */
    getSourceLabel(val) {
      const item = this.sourceOptions.find(i => i.value === val)
      return item ? item.label : val
    },
    /**
     * 获取发票来源方式字色
     */
    getSourceColor(val) {
      const item = this.sourceOptions.find(i => i.value === val)
      return item ? item.color : val
    },
    /**
     * 获取重复票的 label 和 底纹色
     */
    getRepeatConfig(val, prop) {
      const item = this.dupOptions.find(i => i.value === val)
      return item ? item[prop] : ''
    },

    pageChange(current) {
      this.current = current
      this.getList()
    },

    showSizeChange(current, pageSize) {
      this.pageSize = pageSize
      this.getList()
    },

    onSelect(record, selected) {
      if (selected) {
        this.selectedRowKeys.push(record.pk)
        this.selectedRows.push(record)
      } else {
        this.selectedRowKeys = [...pullAll(this.selectedRowKeys, [record.pk])]
        this.selectedRows = [...pullAllBy(this.selectedRows, [record], 'pk')]
      }
    },

    onSelectAll(selected, selectedRows, changeRows) {
      const pkList = changeRows.map(item => item.pk)
      if (selected) {
        this.selectedRowKeys = [...this.selectedRowKeys, ...pkList]
        this.selectedRows = [...this.selectedRows, ...changeRows]
      } else {
        this.selectedRowKeys = [...pullAll(this.selectedRowKeys, [...pkList])]
        this.selectedRows = [
          ...pullAllBy(this.selectedRows, [...changeRows], 'pk')
        ]
      }
    },

    checkInvoice() {
      if (
        this.billInfos &&
        this.billInfos.length &&
        !this.selectedRowKeys.length &&
        !this.selectedRows.length
      ) {
        this.selectedRowKeys = [...this.billInfos.map(i => i.pk)]
        this.selectedRows = [...this.billInfos]
      }

      this.visible = true
    },

    // 确定选择的票据信息
    chooseInvoice() {
      this.$emit('update:billInfos', this.selectedRows)
      this.visible = false
    },

    /**
     * 查看重复票据
     * @param {String} status 是否重复（只有1时才能查看重复票据）
     * @param {Object} record //发票明细
     */
    showRepeatInvoice(status, record) {
      if (status != 1 && !record.exceptions && !record.exceptions.length) return
      this.currRepeatPk = record.pk
      this.repeatInvoicVisible = true
    },

    // 查看票据明细
    showInvoiceDetail(record) {
      this.invoiceForm = record
      this.invoicVisible = true
    },

    // 删除某项
    delItem(record) {
      this.selectedRowKeys = pullAll(this.selectedRowKeys, [record.pk])
      this.$emit(
        'update:billInfos',
        this.billInfos.filter(item => item.pk !== record.pk)
      )
      this.selectedRows = this.selectedRows.filter(
        item => item.pk !== record.pk
      )
    },

    // 发票验证
    validatedInvoice() {
      const list = this.billInfos
        .filter(v => v.validated == -1)
        .map(f => {
          return {
            checkCode: f.checkCode,
            code: f.code,
            date: f.ddate,
            number: f.nnumber,
            payInvoiceId: f.pk,
            pretaxAmount: f.pretaxAmount,
            type: f.type
          }
        })
      if (list && list.length) {
        this.$message.loading({ content: '正在发票验真...', key: 'key' })
        this.validatLoading = true

        const chunkList = chunk(list, 3)
        const chunkPromise = []

        for (const chunk of chunkList) {
          chunkPromise.push(
            new Promise((resolve, reject) => {
              getValidateBatch(chunk)
                .then(res => resolve(res))
                .catch(() => reject())
            })
          )
        }

        Promise.all(chunkPromise)
          .then(resList => {
            for (const res of resList) {
              res.forEach(({ payInvoiceId: pk, validated }) => {
                const target = this.billInfos.find(item => item.pk === pk)
                if ([1, 0].includes(validated)) {
                  target.validated = validated
                }
              })
            }
            this.$message.success(`验真完成`)
          })
          .catch(() => {
            this.$message.error({ content: '验证失败', key: 'key' })
          })
          .finally(() => {
            this.validatLoading = false
          })
      }
    },

    /**
     * 获取票据的列表信息
     */
    getList() {
      if (!this.isRead) {
        getInvoiceList(this.current, this.pageSize).then(res => {
          this.datas = convertKey(res.datas || [])
          this.total = res.totalNum
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .choose-active {
  background: #faf1f0;
}

.popover-content {
  max-height: 240px;
  overflow: auto;
}
</style>
