<template>
  <div class="text-center overflow-hidden">
    <div
      v-for="(item, index) in result"
      :key="index"
      :style="{ height: index === result.length - 1 ? 'auto' : '50%' }"
    >
      <div class="mb-1 mt-8">{{ title }}</div>
      <a-row class="px-12">
        <a-col v-for="{ title, prop } in columns" :key="prop" :span="8">
          <label>{{ title }}</label>
          <span>
            {{ form[prop] }}
          </span>
        </a-col>
      </a-row>

      <div class="px-24 mt-4">
        <table class="w-full">
          <tr>
            <th v-for="tiele in thTitles" :key="tiele">{{ tiele }}</th>
          </tr>
          <tr v-for="subItem in item" :key="subItem.srcBid">
            <td>{{ subItem.invoiceno }}</td>
            <td>{{ subItem.outOfStockBillno }}</td>
            <td>{{ subItem.issuancedate.split(' ')[0] }}</td>
            <td>{{ subItem.priceintax }}</td>
            <td>{{ subItem.cancelno }}</td>
            <td>{{ subItem.cancelMny }}</td>
            <td>{{ form.appFee }}</td>
            <td>{{ subItem.appExpenses }}</td>
          </tr>
          <tr>
            <td colspan="4">小计</td>
            <td>{{ resultItemSum[index].cancelnoSum }}</td>
            <td>{{ resultItemSum[index].cancelMnySum }}</td>
            <td></td>
            <td>{{ resultItemSum[index].sellingExpensesSum }}</td>
          </tr>
          <tr>
            <td colspan="4">合计</td>
            <td>{{ cancelnoAllSum }}</td>
            <td>{{ cancelMnyAllSum }}</td>
            <td></td>
            <td>{{ sellingExpensesAllSum }}</td>
          </tr>
        </table>
        <a-row class="my-2">
          <a-col :span="8">
            <label>制单人</label>
            <span>{{ creator }}</span>
          </a-col>
          <a-col :span="8">
            <label>审批人</label>
            <span>{{ curAuditor }}</span>
          </a-col>
          <a-col :span="8">
            <label>审批日期</label>
            <span>{{ auditTime.split(' ')[0] }}</span>
          </a-col>
        </a-row>
        <div class="text-right">
          第 {{ index + 1 }} 页 &nbsp;&nbsp; 共 {{ resultItemSum.length }} 页
        </div>
      </div>
    </div>
    <div class="px-12 text-left" v-show="options.includes('comment')">
      <div class="border-b py-4 text-sm font-semibold">审批信息</div>
      <comment :handleInfoList="handleInfoList"></comment>
    </div>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/print/components/CommentItem'
export default {
  name: 'PrintForm',
  components: { Comment },
  props: {
    title: {
      type: String,
      default: ''
    },
    creator: {
      type: String,
      default: ''
    },
    curAuditor: {
      type: String,
      default: ''
    },
    auditTime: {
      type: String,
      default: ''
    },
    form: {
      type: Object,
      default: () => ({})
    },
    handleInfoList: {
      type: Array,
      default: () => []
    },
    datas: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => ['comment']
    }
  },

  data() {
    return {
      cancelnoAllSum: 0, // 总核销数量
      cancelMnyAllSum: 0, // 总核销金额
      sellingExpensesAllSum: 0, // 总销售费用

      result: [],
      resultItemSum: [],

      columns: [
        { title: '单据号', prop: 'vbillno' },
        { title: '业务日期', prop: 'vbilldate' },
        { title: '客户', prop: 'busiCompName' },
        { title: '品种名称', prop: 'materialName' },
        { title: '规格', prop: 'materialSpec' }
      ],

      thTitles: [
        '发票号',
        '发货单号',
        '发货日期',
        '单价',
        '数量',
        '金额',
        '折让单价',
        '折让金额'
      ]
    }
  },

  watch: {
    datas(val) {
      if (!val || !val.length) return

      this.result = []
      this.resultItemSum = []

      for (let i = 0; i < this.datas.length; i = i + 15) {
        this.result.push(this.datas.slice(i, i + 15))
      }

      // 本页核销数量、核销金额、销售费用计算
      for (let i = 0; i < this.result.length; i++) {
        let cancelnoSum = 0,
          cancelMnySum = 0,
          sellingExpensesSum = 0

        this.result[i].forEach(item => {
          cancelnoSum = this.$np.plus(cancelnoSum, Number(item.cancelno || '0'))
          cancelMnySum = this.$np.plus(
            cancelMnySum,
            Number(item.cancelMny || '0')
          )
          sellingExpensesSum = this.$np.plus(
            sellingExpensesSum,
            Number(item.appExpenses || '0')
          )
        })

        this.resultItemSum.push({
          cancelnoSum,
          cancelMnySum,
          sellingExpensesSum
        })
      }

      // 总的核销数量、核销金额、销售费用计算
      for (let i = 0; i < this.resultItemSum.length; i++) {
        this.cancelnoAllSum = this.$np.plus(
          this.cancelnoAllSum,
          this.resultItemSum[i].cancelnoSum
        )

        this.cancelMnyAllSum = this.$np.plus(
          this.cancelMnyAllSum,
          this.resultItemSum[i].cancelMnySum
        )

        this.sellingExpensesAllSum = this.$np.plus(
          this.sellingExpensesAllSum,
          this.resultItemSum[i].sellingExpensesSum
        )
      }
    }
  }
}
</script>

<style lang="less" scoped>
table,
th,
td {
  font-size: 12px;
  font-weight: 500;
  border: 1px solid;
  text-align: center;
}

label::after {
  content: ':';
  margin: 0 4px;
}
</style>
