const BASE = [
  {
    title: '基本信息',
    active: 'BASE',
    children: [
      { label: '单据号', value: `vbillno` },
      { label: '业务日期', value: `vbilldate` },
      { label: '制单人', value: `billmakerName` }
    ]
  }
]
const TABLE = [
  {
    title: '信息列表',
    active: 'TABLE',
    dataList: 'bodyDtos',
    columns: [
      { title: '行号', dataIndex: 'rowno', width: 72 },
      {
        title: '终端编码',
        dataIndex: 'chnlmemberCode',
        ellipsis: true,
        width: 120
      },
      {
        title: '终端名称',
        dataIndex: 'chnlmemberName',
        ellipsis: true,
        width: 120
      },
      { title: '品种编码', dataIndex: 'specCode', ellipsis: true, width: 120 },
      { title: '品种名称', dataIndex: 'specName', ellipsis: true, width: 120 },
      {
        title: '规格',
        dataIndex: 'specSpecifications',
        ellipsis: true,
        width: 120
      },
      { title: '数量', dataIndex: 'num', width: 72 },
      { title: '盘存日期', dataIndex: 'invdateStr', ellipsis: true, width: 72 },
      {
        title: '上次盘存日期',
        dataIndex: 'lastinvdateStr',
        ellipsis: true,
        width: 72
      }
    ]
  }
]

export { BASE, TABLE }
