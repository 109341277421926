import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 药连锁办事处用房租赁申请单列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getRentHouseAppList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `rentHouseApp/getRentHouseAppList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新药连锁办事处用房租赁申请单
 * @param {Object} data
 * @returns
 */
export function updateRentHouse(data) {
  return request({
    method: 'post',
    url: `rentHouseApp/updateRentHouseApp`,
    data
  })
}

/**
 * 保存药连锁办事处用房租赁申请单
 * @param {Object} data
 * @returns
 */
export function saveRentHouse(data) {
  return request({
    method: 'post',
    url: `rentHouseApp/saveRentHouseApp`,
    data
  })
}

/**
 * 提交药连锁办事处用房租赁申请单
 * @param {Object} data
 * @returns
 */
export function submitRentHouse(data) {
  return request({
    method: 'post',
    url: `oaflow/submitRentHouseApp`,
    data
  })
}

/**
 * 保存药连锁办事处用房租赁申请单
 * @param {String} id
 * @returns
 */
export function delRentHouse(id) {
  return request({
    method: 'post',
    url: `rentHouseApp/delRentHouseApp/${id}`
  })
}

/**
 * 获取药连锁办事处用房租赁申请单
 * @param {String} id
 * @returns
 */
export function getRentHouse(id) {
  return request({
    method: 'get',
    url: `rentHouseApp/${id}`
  })
}

/**
 * 导出药连锁办事处用房租赁申请单
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/rentHouseApp/exportData`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `药连锁办事处用房租赁申请单`)
  })
}
