import request from '@/utils/request'

//医生列表
export function handleBatch(billLists) {
  return request({
    url: `oaFlowBatch/handleBatch`,
    method: 'post',
    data: billLists
  })
}
