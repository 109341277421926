import request from '@/utils/request'
import { message } from 'ant-design-vue'

// /**
//  * 文件上传
//  * @param {File} file
//  */
// export function getUpload(file) {
//   return request({
//     method: 'post',
//     url: 'file/upload',
//     data: file
//   })
// }

// /**
//  * 批量文件上传
//  * @param {Array[file]} files
//  */
// export function getBatch(files) {
//   return request({
//     method: 'post',
//     url: 'file/batchUpload',
//     data: files
//   })
// }

// /**
//  * 删除附件id
//  * @param {String} id
//  */
// export function getDeleteFile(id) {
//   return request({
//     method: 'delete',
//     url: `file/${id}`
//   })
// }

// export function getFileById(id) {
//   return request({
//     method: 'get',
//     url: `file/${id}`
//   })
// }

// /**
//  *批量下载的文件id
//  * @param {String} name
//  * @param {Array} ids
//  */
// export function getDownloadFiles(name, ids) {
//   return request({
//     method: 'post',
//     url: `file/batchDownload`,
//     data: ids,
//     responseType: 'blob'
//   }).then(res => {
//     if (res.data.type === 'application/json') {
//       message.error('导出失败!')
//       return
//     }
//     const link = document.createElement('a')
//     let blob = new Blob([res.data], { type: 'application/zip' })
//     link.style.display = 'none'
//     link.href = URL.createObjectURL(blob)
//     link.setAttribute('download', `${name}附件` + '.zip')
//     document.body.appendChild(link)
//     link.click()
//     document.body.removeChild(link)
//   })
// }

// /**
//  * 批量删除附件
//  * @param {Array} ids
//  */
// export function getDeleteFiles(ids) {
//   return request({
//     method: 'post',
//     url: `file/multiFiles`,
//     data: ids
//   })
// }

// /**
//  * 获取 pdf 文件的 url
//  * @params {String} id 要打开文件的 id
//  * @returns {String} pdf 的路径
//  */
// export function getPDFURL(id) {
//   return request({
//     method: 'get',
//     url: `file/preView/${id}`,
//     responseType: 'blob'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `application/pdf` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// /**
//  * 获取 img 文件的 url
//  * @param {String} id 要打开文件的id
//  */
// export function getIMGURL(id) {
//   return request({
//     method: 'get',
//     url: `file/preView/${id}`,
//     responseType: 'arraybuffer'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `image/png` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// /**
//  * 获取 EXCEL文件的 url
//  * @param {String} id 要打开文件的id
//  */
// export function getEXCELURL(id) {
//   return request({
//     method: 'get',
//     url: `file/preView/${id}`,
//     responseType: 'blob'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `application/vnd.ms-excel` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// /**
//  * 获取 word文件的 url
//  * @param {String} id 要打开文件的id
//  */
// export function getWORDURL(id) {
//   return request({
//     method: 'get',
//     url: `file/preView/${id}`,
//     responseType: 'blob'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `application/msword` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// /**
//  * 预览文件(包括word/excel等)
//  * @param {String} id
//  */
// export function preViewTransformFile(id) {
//   return request({
//     method: 'get',
//     url: `file/preViewTransformFile`,
//     params: { id },
//     responseType: 'blob'
//   }).then(response => {
//     const blob = new Blob([response.data], { type: `application/pdf` })
//     const url = URL.createObjectURL(blob)
//     return url
//   })
// }

// 改用mongodb
/**
 * 文件上传
 * @param {File} file
 */
export function getUpload(file) {
  return request({
    method: 'post',
    url: 'nfile/upload',
    data: file
  })
}

/**
 * 批量文件上传
 * @param {Array[file]} files
 */
export function getBatch(files) {
  return request({
    method: 'post',
    url: 'nfile/batchUpload',
    data: files
  })
}

/**
 * 删除附件id
 * @param {String} id
 */
export function getDeleteFile(id) {
  return request({
    method: 'delete',
    url: `nfile/${id}`
  })
}

export function getFileById(id) {
  return request({
    method: 'get',
    url: `nfile/${id}`
  })
}

/**
 *批量下载的文件id
 * @param {String} name
 * @param {Array} ids
 */
export function getDownloadFiles(name, ids) {
  return request({
    method: 'post',
    url: `nfile/batchDownload`,
    data: ids,
    responseType: 'blob'
  }).then(res => {
    if (res.data.type === 'application/json') {
      message.error('导出失败!')
      return
    }
    const link = document.createElement('a')
    let blob = new Blob([res.data], { type: 'application/zip' })
    link.style.display = 'none'
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', `${name}附件` + '.zip')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

/**
 * 批量删除附件
 * @param {Array} ids
 */
export function getDeleteFiles(ids) {
  return request({
    method: 'post',
    url: `nfile/multiFiles`,
    data: ids
  })
}

/**
 * 获取 pdf 文件的 url
 * @params {String} id 要打开文件的 id
 * @returns {String} pdf 的路径
 */
export function getPDFURL(id) {
  return request({
    method: 'get',
    url: `nfile/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `application/pdf` })
    const url = URL.createObjectURL(blob)
    return url
  })
}

/**
 * 获取 img 文件的 url
 * @param {String} id 要打开文件的id
 */
export function getIMGURL(id) {
  return request({
    method: 'get',
    url: `nfile/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `image/png` })
    const url = URL.createObjectURL(blob)
    return url
  })
}

/**
 * 转成base64的图片
 * @param {*} id
 * @returns
 */
export function getBase64IMG(id) {
  return request({
    method: 'get',
    url: `nfile/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    return (
      'data:image/png;base64,' +
      btoa(
        new Uint8Array(response.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ''
        )
      )
    )
  })
}

/**
 * 获取 EXCEL文件的 url
 * @param {String} id 要打开文件的id
 */
export function getEXCELURL(id) {
  return request({
    method: 'get',
    url: `nfile/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `application/vnd.ms-excel` })
    const url = URL.createObjectURL(blob)
    return url
  })
}

/**
 * 获取 word文件的 url
 * @param {String} id 要打开文件的id
 */
export function getWORDURL(id) {
  return request({
    method: 'get',
    url: `nfile/preView/${id}`,
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `application/msword` })
    const url = URL.createObjectURL(blob)
    return url
  })
}

/**
 * 预览文件(包括word/excel等)(弃用)
 * @param {String} id
 */
export function preViewTransformFile(id) {
  return request({
    method: 'get',
    url: `file/preViewTransformFile`,
    params: { id },
    responseType: 'arraybuffer'
  }).then(response => {
    const blob = new Blob([response.data], { type: `application/pdf` })
    const url = URL.createObjectURL(blob)
    return url
  })
}
