<template>
  <div class="bill-form">
    <a-back-top />

    <div class="example" v-if="loading">
      <loading-stuff title="学术活动变更申请单加载中..." class="loading-icon" />
    </div>

    <a-card :bordered="false">
      <!-- 申请变更单的标题 -->
      <div slot="title" v-if="!$route.query.todo && type !== 'waitwork'">
        学术推广活动申请变更单详情
      </div>
      <span slot="extra" v-if="!$route.query.todo && type !== 'waitwork'">
        <div
          class="flex justify-center content-center"
          style="line-height: 32px"
        >
          <a-button
            type="danger"
            class="ml-2"
            :disabled="isFlow || !billMsg.id"
            @click="del"
          >
            删除
          </a-button>
          <a-button class="ml-2" :disabled="backDisabled" @click="getBack">
            撤销
          </a-button>

          <span class="ml-8 inline-flex items-center">
            <icon-font
              :type="getStatus.icon"
              class="text-2xl"
              style="line-height: 26px"
            ></icon-font>
            <span class="bill-status" :class="getStatus.color">
              {{ getStatus.text }}
            </span>
          </span>
        </div>
      </span>
      <!-- 申请信息、活动信息和活动类型及执行标准 -->
      <div v-for="{ title, icon, children } in appBill" :key="title">
        <detail-list :useNewTitle="true" :icon="icon" :title="title">
          <span
            v-for="{ label, value, subValue, type, col, show } in children"
            :key="label"
          >
            <detail-list-item
              v-if="!show || show.call(getVueIntance)"
              :term="label"
              :col="col || 3"
              :showPopover="isChange(subValue, value)"
              :originalValue="getValue(subValue, value, type, originBillMsg)"
            >
              <ellipsis
                v-if="type !== 'file'"
                :length="col ? 1024 : $store.state.screen.length"
              >
                {{ getValue(subValue, value, type, billMsg) }}
              </ellipsis>

              <div v-else class="flex items-center">
                <span
                  class="relative"
                  style="top: 7px"
                  :title="`${label}附件信息`"
                >
                  <span
                    class="absolute text-white cursor-pointer"
                    style="top: 5px; right: 10px"
                    @click="preview(billMsg[value], value)"
                  >
                    {{
                      billMsg[value] ? JSON.parse(billMsg[value]).length : '0'
                    }}
                  </span>
                  <icon-svg
                    icon="icon_wenjianjia3"
                    class="text-3xl ml-2 cursor-pointer"
                    @click="preview(billMsg[value], value)"
                  />
                </span>
              </div>
            </detail-list-item>
          </span>
        </detail-list>
      </div>
      <!-- 陪同人员信息 -->
      <div
        v-for="{ title, icon, dataList, columns } in appAccTable"
        :key="icon"
      >
        <div>
          <div class="border-b my-4 py-4 text-sm font-semibold">
            <a-icon :type="icon" />
            <span class="ml-2">{{ title }}</span>
            <span
              class="ml-3 cursor-pointer text-orange-400 hover:text-orange-500"
              title="点击查看变更的内容"
              @click="showDiffList(dataList)"
            >
              <a-icon type="info-circle" />
            </span>
          </div>

          <a-table
            :rowKey="record => record.personId"
            :dataSource="billMsg[dataList] || []"
            :columns="columns"
            :pagination="false"
            :rowClassName="
              (_record, index) => {
                return index % 2 === 0 ? '' : 'trBgClass'
              }
            "
          >
            <span slot="number" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
            <template slot="budget" slot-scope="text, record">
              <div class="relative">
                <span>{{ record.accompanyBudget }}</span>
                <a-avatar
                  v-if="getDiffAccomMsg.updateInfo[record.personId]"
                  shape="square"
                  title="修改"
                  :size="18"
                  :style="{
                    ...signIconStyle,
                    ...{ backgroundColor: '#ed8936' }
                  }"
                >
                  改
                </a-avatar>
                <a-avatar
                  v-if="getDiffAccomMsg.addInfo.includes(record.personId)"
                  shape="square"
                  title="新增"
                  :size="18"
                  :style="{
                    ...signIconStyle,
                    ...{ backgroundColor: '#2edfa3' }
                  }"
                >
                  增
                </a-avatar>
              </div>
            </template>
          </a-table>
        </div>
      </div>

      <!-- 讲者信息 -->
      <div
        v-for="{ title, icon, dataList, columns } in appSpeakerTable"
        :key="icon"
      >
        <div>
          <div class="border-b my-4 py-4 text-sm font-semibold">
            <a-icon :type="icon" />
            <span class="ml-2">{{ title }}</span>
            <span
              class="ml-3 cursor-pointer text-orange-400 hover:text-orange-500"
              title="点击查看变更的内容"
              @click="showDiffList(dataList)"
            >
              <a-icon type="info-circle" />
            </span>
          </div>

          <a-table
            :rowKey="record => record.speakerId"
            :dataSource="billMsg[dataList] || []"
            :columns="columns"
            :pagination="false"
            :rowClassName="
              (_record, index) => {
                return index % 2 === 0 ? '' : 'trBgClass'
              }
            "
          >
            <span slot="number" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
            <template slot="lengthoflecture" slot-scope="text, record">
              <div class="relative">
                <span>{{ record.lengthoflecture }}</span>
                <a-avatar
                  v-if="getDiffSpeaker.updateInfo[record.speakerId]"
                  shape="square"
                  title="修改"
                  :size="18"
                  :style="{
                    ...signIconStyle,
                    ...{ backgroundColor: '#ed8936' }
                  }"
                >
                  改
                </a-avatar>
                <a-avatar
                  v-if="getDiffSpeaker.addInfo.includes(record.speakerId)"
                  shape="square"
                  title="新增"
                  :size="18"
                  :style="{
                    ...signIconStyle,
                    ...{ backgroundColor: '#2edfa3' }
                  }"
                >
                  增
                </a-avatar>
              </div>
            </template>
          </a-table>
        </div>
      </div>

      <!-- 费用信息 -->
      <div class="border-b my-4 py-4 text-sm font-semibold">
        <a-icon type="pay-circle" />
        <span class="ml-2">费用信息</span>
      </div>
      <fee-table
        style="margin-bottom: 32px"
        :dataList="feeData"
        :columns="feeColumns"
        :curData="billMsg"
        :originData="originBillMsg"
        @preview="previewFile"
      ></fee-table>

      <div class="px-6 py-2 mb-4 rounded" style="background: #ddebf7">
        <div class="text-right font-semibold text-black">
          <span>
            预付金额 :
            <span class="text-red-600">￥{{ billMsg.budgetPreCost || 0 }}</span>
          </span>
          <a-divider type="vertical" style="background: #a0aec0" class="mx-4" />
          <span>
            金额合计 ：
            <span class="text-red-600">￥{{ feeTotal || 0 }}</span>
          </span>
          <a-divider
            type="vertical"
            v-if="getStatus.status === '0'"
            class="mx-4"
            style="background: #a0aec0"
          />
          <span v-if="getStatus.status === '0'">
            批准总预算：
            <span class="text-red-600">
              ￥{{ billMsg.budgetApprovetotal || 0 }}
            </span>
          </span>
        </div>
      </div>

      <!-- 流程信息 -->
      <process
        v-if="billMsg.flowInfoDTO"
        :status="isFlow"
        :summaryid="billMsg.flowInfoDTO.summaryid"
        :nextapproveman="billMsg.flowInfoDTO.nextapproveman"
        :curApprover="billMsg.flowInfoDTO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="billMsg.applicant" :billId="billMsg.id"></comment>
    </a-card>
    <diff-file-modal
      v-if="showPreview"
      :uploadName="uploadName"
      :curFile="curPreviewFileListInfo"
      :originFile="originPreviewFileListInfo"
      @close="showPreview = false"
    ></diff-file-modal>

    <diff-accomany-person-modal
      v-if="showAccomany"
      :diffData="diffAccomMsg"
      :columns="appAccTable[0].columns"
      @close="showAccomany = false"
    ></diff-accomany-person-modal>
    <diff-speaker-modal
      v-if="showSpeaker"
      :diffData="diffSpeaker"
      :columns="appSpeakerTable[0].columns"
      @close="showSpeaker = false"
    ></diff-speaker-modal>
  </div>
</template>

<script>
import DetailList from '@/components/Description/DescriptionList'
import DetailListItem from '@/components/Description/DetailListItem'
// import PreviewModal from '@/components/Upload/PreviewModal'
import DiffFileModal from './DiffFileModal'
import Ellipsis from '@/components/Ellipsis'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import LoadingStuff from '@/components/spin/LoadingStuff'

import FeeTable from './FeeTable'
import DiffAccomanyPersonModal from './DiffAccomanyPersonModal'
import DiffSpeakerModal from './DiffSpeakerModal'

import { isDisabled } from '@/utils/util'
import {
  getActivityModifyById,
  delActivityModify
} from '@/api/acdemicActive/acdemic-modify'
import {
  getActivityAppById,
  getOACancel2
} from '@/api/acdemicActive/acdemic-application'
import { billStatus } from '@/views/query/util/util'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import {
  APP_BILL,
  APP_TABLE,
  getAppFee
} from '@/views/activity/modules/academic-modify'
import { perferWhetherDygProj } from '@/shared/activity'

export default {
  name: 'AcademicModifyDetail',
  components: {
    DetailList,
    DetailListItem,
    FeeTable,
    Ellipsis,
    DiffFileModal,
    Process,
    Comment,
    DiffAccomanyPersonModal,
    DiffSpeakerModal,
    LoadingStuff
  },

  props: {
    type: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    }
  },

  async created() {
    let pid = ''
    this.loading = true

    await getActivityModifyById(this.id).then(res => {
      this.billMsg = res
      pid = this.billMsg.activityAppId

      // 单据状态(向上通知)
      const status = this.billMsg.flowInfoDTO
        ? this.billMsg.flowInfoDTO.status
        : '1'
      this.$emit('status', status)

      // 活动信息讲者人数区间
      const { speakernumGt, speakernumLt } =
        this.billMsg.activityTypeStdDTO || {}
      if (speakernumGt || speakernumGt == 0 || speakernumLt) {
        this.billMsg.activityTypeStdDTO.speakernum = `${
          speakernumGt.toString() || '?'
        } ~ ${speakernumLt || '?'}`
      }

      const { columns, dataList } = getAppFee()

      this.feeData = dataList
      this.feeColumns = columns

      // 费用信息计算
      for (const item of this.feeData) {
        const { price, num, total, fileProp } = item
        if (price === '--' && num === '--')
          item.total = this.billMsg[total] || 0
        else {
          item.price = this.billMsg[price] || 0
          item.num = this.billMsg[num] || 0
          item.total = this.$np.times(Number(item.price), Number(item.num))
        }

        if (fileProp)
          item.fileNum = JSON.parse(this.billMsg[fileProp] || '[]').length

        if (item.deprecated) continue
        this.feeTotal = this.$np.plus(Number(this.feeTotal), Number(item.total))
      }
    })

    await getActivityAppById(pid).then(res => {
      this.originBillMsg = res
      // 活动信息讲者人数区间
      const { speakernumGt, speakernumLt } =
        this.originBillMsg.activityTypeStdDTO || {}
      if (speakernumGt || speakernumGt == 0 || speakernumLt) {
        this.originBillMsg.activityTypeStdDTO.speakernum = `${
          speakernumGt.toString() || '?'
        } ~ ${speakernumLt || '?'}`
      }
    })

    this.diffAccomList()
    this.diffSpeakerList()
    this.loading = false
  },

  data() {
    return {
      billMsg: {},
      originBillMsg: {},
      loading: false,
      showAccomany: false,
      showSpeaker: false,
      showPreview: false,
      curPreviewFileListInfo: '',
      originPreviewFileListInfo: '',
      uploadName: '',

      perferWhetherDygProj,

      appBill: APP_BILL,
      appAccTable: APP_TABLE.accompany,
      appSpeakerTable: APP_TABLE.speaker,
      feeColumns: [],
      feeData: [],
      feeTotal: 0,

      diffAccomMsg: {
        updateInfo: {}, // 存key-value， key为pk，value为修改的prop
        addInfo: [], // 存pk
        deleteInfo: [] // 存新增的陪同人员信息
      }, // 陪同人员差异信息
      diffSpeaker: {
        updateInfo: {}, // 存key-value， key为pk，value为修改的prop
        addInfo: [], // 存pk
        deleteInfo: [] // 存新增的陪同人员信息
      },

      signIconStyle: {
        position: 'absolute',
        verticalAlign: 'middle',
        right: '-8px',
        top: '-8px'
      }
    }
  },
  computed: {
    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.billMsg.flowInfoDTO
        ? this.billMsg.flowInfoDTO.status
        : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.billMsg.flowInfoDTO
        ? this.billMsg.flowInfoDTO.status
        : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getStatus() {
      const status = this.billMsg.flowInfoDTO
        ? this.billMsg.flowInfoDTO.status
        : '1'
      const statusInfo = this.billStatus(status)
      return statusInfo
    },

    getVueIntance() {
      return this
    },

    // 直播显示
    isShowOnline() {
      const text = this.billMsg.activityFormDto?.name
      if (!text) return false
      return text.indexOf('+直播') > -1
    },
    // 协办显示
    isShowCo() {
      const activityNatrue = this.billMsg?.activityNatrue || ''
      return activityNatrue.indexOf('协办') > -1
    },
    // 会务公司显示
    isShowContract() {
      return this.billMsg.hasconfContract === '是'
    },
    // 是否为总部统一筹划项目
    isShowWHQProj() {
      return this.billMsg.whetherHeadQuartersProj === '是'
    },
    // 是否为走进东阳光项目
    isShowDygProj() {
      const { activitytype = '', activityTypeStdDTO = {} } = this.billMsg
      const { acttype = '' } = activityTypeStdDTO

      let show = false
      if (activitytype && acttype) {
        for (const activityName of this.perferWhetherDygProj) {
          if (acttype.indexOf(activityName) > -1) {
            show = true
            break
          }
        }
      }

      return show
    },

    getDiffAccomMsg() {
      const diffData = cloneDeep(this.diffAccomMsg)
      return diffData
    },

    getDiffSpeaker() {
      const diffData = cloneDeep(this.diffSpeaker)
      return diffData
    }
  },
  methods: {
    billStatus,

    previewFile(record) {
      this.openPreviewModal(
        record.projectName,
        this.billMsg[record['fileProp']],
        record['fileProp']
      )
    },

    /**
     * 传递预览文件信息
     * @param {String} fileInfo
     */
    preview(previewFileListInfo, value) {
      this.openPreviewModal(value, previewFileListInfo, value)
    },

    openPreviewModal(title, fileData, prop) {
      switch (prop) {
        case 'participantsList':
          this.uploadName = '参会人员名单'
          break
        case 'activityInvitation':
          this.uploadName = '邀请函/会议议程'
          break
        case 'sponsorQualification':
          this.uploadName = '主办方资质证明'
          break
        case 'sponsorInvitation':
          this.uploadName = '主办方邀请函/招商函'
          break
        case 'contract':
          this.uploadName = '协办合同'
          break
        case 'onlineStreamingPlatformAgreement':
          this.uploadName = '线上直播平台服务协议/报价单'
          break
        case 'contractScan':
          this.uploadName = '会务合同'
          break
        case 'meetingMaterialExpenseDocs':
          this.uploadName = title
          break
        default:
          break
      }

      this.originPreviewFileListInfo = this.originBillMsg[prop]
      this.curPreviewFileListInfo = fileData
      this.showPreview = true
    },

    isChange(subValue, value) {
      if (isEmpty(this.billMsg) || isEmpty(this.originBillMsg)) return

      if (subValue) {
        return this.billMsg[value] && this.originBillMsg[value]
          ? this.billMsg[value][subValue] !==
              this.originBillMsg[value][subValue]
          : this.billMsg[value] !== this.originBillMsg[value]
      } else {
        return this.billMsg[value] !== this.originBillMsg[value]
      }
    },

    getValue(subValue, value, type, billMsg) {
      if (isEmpty(billMsg)) return

      return subValue
        ? billMsg[value]
          ? billMsg[value][subValue] || '无'
          : '无'
        : billMsg[value] || (type === 'number' ? '0' : '无')
    },

    diffAccomList() {
      const diffProps = [
        'personCode',
        'personName',
        'areaName',
        'provName',
        'postName',
        'accompanyBudget'
      ]

      this.diff(
        'diffAccomMsg',
        'appAccompanyingPersonsDtos',
        'personId',
        diffProps
      )
    },

    diffSpeakerList() {
      const diffProps = [
        'code',
        'name',
        'departmentName',
        'speakerLevelName',
        'appConsultingFee',
        'topicname',
        'lengthoflecture'
      ]

      this.diff('diffSpeaker', 'speakers', 'speakerId', diffProps)
    },

    // 陪同人员diff
    diff(collectionProps, listProp, diffId, diffProps) {
      if (!isEmpty(this.originBillMsg) && !isEmpty(this.billMsg)) {
        const originList = this.originBillMsg[listProp] || []
        const currentList = this.billMsg[listProp] || []

        for (const item of currentList) {
          const target = originList.find(i => i[diffId] === item[diffId])
          if (!target) {
            // 删除
            this[collectionProps].addInfo.push(item[diffId])
          } else {
            // 对比差异（修改）
            for (const prop of diffProps) {
              if (item[prop] !== target[prop]) {
                if (!this[collectionProps].updateInfo[item[diffId]]) {
                  this[collectionProps].updateInfo[item[diffId]] = [prop]
                } else {
                  this[collectionProps].updateInfo[item[diffId]].push(prop)
                }
              }
            }
          }
        }
        // 新增
        for (const item of originList) {
          const target = currentList.find(i => i[diffId] === item[diffId])
          if (!target) {
            this[collectionProps].deleteInfo.push(cloneDeep(item))
          }
        }
      }
    },

    showDiffList(prop) {
      switch (prop) {
        case 'appAccompanyingPersonsDtos':
          this.showAccomany = true
          break
        case 'speakers':
          this.showSpeaker = true
          break
        default:
          break
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该学术推广活动申请变更单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delActivityModify(this.billMsg.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'activityApplication' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该学术活动申请变更单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.billMsg.id,
            'XSHYBG',
            this.billMsg.flowInfoDTO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.billMsg.flowInfoDTO.status = '5'
          })
        },
        onCancel: () => {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bill-form {
  margin: 0 0 0 16px;
  position: relative;
}
.example {
  text-align: center;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
.bill-status {
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
}

/deep/ .ant-card-head {
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 0;
  height: 48px;
}

/deep/ .ant-card-body {
  padding: 16px 0;
}

/deep/ .description-list .term {
  line-height: 36px;
}
</style>
