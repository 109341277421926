<template>
  <section class="page">
    <h3 class="pt-4 text-center text-xl font-normal">非会议类项目申请表</h3>

    <table class="table-main">
      <template v-for="(item, index) in tableRaw">
        <tr v-if="item.type === 'header'" :key="index">
          <td :colspan="item.colspan" class="header-msg">{{ item.label }}</td>
        </tr>

        <tr v-if="item.type === 'raw'" :key="index">
          <td
            v-for="(subItem, subIndex) in item.children"
            :colspan="subItem.colspan"
            :key="subIndex"
            :class="[subIndex % 2 ? 'label-val' : 'label']"
          >
            {{
              typeof subItem.value === 'function'
                ? subItem.value()
                : subItem.value
            }}
          </td>
        </tr>
        <tr v-if="item.type === 'raw-th'" :key="index">
          <th
            v-for="subItem in item.children"
            :colspan="subItem.colspan"
            :key="subItem.value"
          >
            {{ subItem.value }}
          </th>
        </tr>

        <template v-if="item.type === 'data'">
          <tr v-for="data in datas[item.prop]" :key="data.id">
            <td
              v-for="(subItem, subIndex) in item.children"
              :colspan="subItem.colspan"
              :key="subIndex"
            >
              {{ data[subItem.value] }}
            </td>
          </tr>
        </template>
      </template>
    </table>

    <section v-if="datas.firstFlowInfoDTO">
      <oa-process
        imgId="first-img"
        :summaryid="datas.firstFlowInfoDTO.summaryid"
        :nextapproveman="datas.firstFlowInfoDTO.nextapproveman"
        :curApprover="datas.firstFlowInfoDTO.curApprover"
      />
    </section>

    <section v-if="datas.secondFlowInfoDTO">
      <oa-process
        imgId="second-img"
        :summaryid="datas.secondFlowInfoDTO.summaryid"
        :nextapproveman="datas.secondFlowInfoDTO.nextapproveman"
        :curApprover="datas.secondFlowInfoDTO.curApprover"
      />
    </section>

    <section v-if="datas.thirdFlowInfoDTO">
      <oa-process
        imgId="third-img"
        :summaryid="datas.thirdFlowInfoDTO.summaryid"
        :nextapproveman="datas.thirdFlowInfoDTO.nextapproveman"
        :curApprover="datas.thirdFlowInfoDTO.curApprover"
      />
    </section>

    <section>
      <comment :billId="datas.appPartId" />
    </section>

    <section>
      <comment :billId="datas.contractPartId" />
    </section>

    <section>
      <comment :billId="datas.outcomePartId" />
    </section>
  </section>
</template>

<script>
import OaProcess from '@/views/activity/modules/print/components/OaProcess'
import Comment from '@/views/activity/modules/print/components/CommentList'

export default {
  name: 'MeetPrint',
  components: {
    OaProcess,
    Comment
  },
  props: {
    datas: Object
  },
  data() {
    return {
      tableRaw: [
        { type: 'header', label: '申请人信息', colspan: '8' },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '申请人' },
            { colspan: '1', value: () => this.datas.applicantName },
            { colspan: '1', value: '岗位' },
            { colspan: '1', value: () => this.datas.vnote },
            { colspan: '1', value: '联系方式' },
            { colspan: '1', value: () => this.datas.contact },
            { colspan: '1', value: '申请日期' },
            { colspan: '1', value: () => this.datas.appDate }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '部门' },
            { colspan: '3', value: () => this.datas.userDepartmentName },
            { colspan: '1', value: '流水号' },
            { colspan: '3', value: () => this.datas.serialNumber }
          ]
        },

        { type: 'header', label: '项目信息', colspan: '8' },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '合作商名称' },
            { colspan: '7', value: () => this.datas.partnerName }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '项目选择' },
            {
              colspan: '2',
              value: () => this.datas.projectChooseName
            },
            { colspan: '1', value: '项目类型' },
            {
              colspan: '2',
              value: () => this.datas.projectTypeName
            },
            { colspan: '1', value: '项目预算' },
            {
              colspan: '1',
              value: () => this.datas.projectBudget || '0'
            }
          ]
        },

        { type: 'header', label: '合同信息', colspan: '8' },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '是否需要预付' },
            {
              colspan: '1',
              value: () => (this.datas.needPrepaid ? '是' : '否')
            },
            { colspan: '1', value: '预付款金额' },
            { colspan: '5', value: () => this.datas.prepaidAmount || '0' }
          ]
        },

        { type: 'header', label: '项目成果', colspan: '8' },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '实际项目费用' },
            {
              colspan: '7',
              value: () => this.datas.budgetMeals || '0'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@page {
  size: auto A4;
  margin: 16px 0 16px 0;
}

.page {
  position: relative;
  width: 24cm;
  overflow: hidden;
  padding-left: 0.66cm;
  padding-right: 0.68cm;
}
table {
  width: 100%;
  table-layout: fixed;
  &.layout-auto {
    table-layout: auto;
  }
  & th {
    border: 1px solid #000;
    height: 0.5cm;
    font-size: 12px;
    text-align: center;
  }
  & td {
    border: 1px solid #000;
    height: 0.5cm;
    font-size: 12px;
    text-align: center;
    &.label {
      font-weight: 600;
    }
    &.label-val {
      text-align: left;
      padding: 0 8px;
    }
  }
}

.header-msg {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
</style>
