<template>
  <div class="">
    <a-list item-layout="horizontal" :data-source="getResultList">
      <a-list-item
        slot="renderItem"
        slot-scope="item"
        class="list-item"
        :class="item.unread ? '' : 'isread'"
        @click="handldClick(item)"
      >
        <a-list-item-meta>
          <a slot="title" @click.stop="handldClick(item)">{{ item.title }}</a>
          <a-avatar
            size="large"
            slot="avatar"
            :style="{ backgroundColor: `${getAvatarColor(item.title)}` }"
          >
            {{ getAvatar(item.title) }}
          </a-avatar>
          <span slot="description">
            <p>{{ item.msg }}</p>
            <p>{{ $date(item.sendTime).fromNow() }}</p>
          </span>
        </a-list-item-meta>
        <div>
          <a-tag
            :color="
              statusInfo[item.status] ? statusInfo[item.status].color : 'blue'
            "
          >
            {{
              statusInfo[item.status]
                ? statusInfo[item.status].text
                : '未知状态'
            }}
          </a-tag>
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>

<script>
import { getMessageList, getIsRead } from '@/api/message'
const avatar = [
  { rex: /流向/, value: 'flow', avatar: '流向' },
  { rex: /库存/, value: 'stock', avatar: '库存' },
  { rex: /入库/, value: 'ware', avatar: '入库' },
  { rex: /医院/, value: 'hosp', avatar: '医院' },
  { rex: /岗位/, value: 'post', avatar: '岗位' },
  { rex: /费用/, value: 'fee', avatar: '费用' }
]
const colorInfo = {
  flow: '#87d068',
  stock: '#7265e6',
  ware: '#4587f5',
  hosp: '#ff9966',
  post: '#4299e1',
  fee: '#38b2ac'
}
const statusInfo = {
  success: { text: '执行完成', color: 'green' },
  running: { text: '任务执行中', color: 'orange' },
  error: { text: '发生异常', color: 'red' }
}

export default {
  name: 'Result',
  data() {
    return {
      pageIndex: 1,
      statusInfo
    }
  },

  created() {
    this.getMessage()
  },

  computed: {
    getResultList() {
      return this.$store.getters.resultList
    }
  },

  methods: {
    /**
     * 点击标记为已读
     */
    handldClick(item) {
      getIsRead(item.id).then(() => {
        item.unread = false
      })
    },

    getAvatar(title) {
      const arr = title.split('-')
      const module = arr[0]
      const target = avatar.find(item => item.rex.test(module))
      if (target) return target.avatar

      return title[1]
    },

    getAvatarColor(title) {
      const arr = title.split('-')
      const module = arr[0]
      const target = avatar.find(item => item.rex.test(module))
      if (target) return colorInfo[target.value]

      return '#4587f5'
    },

    getMessage() {
      getMessageList(this.pageIndex).then(res => {
        if (res.datas && res.datas.length) {
          this.pageIndex = res.currentPage
          this.$store.commit('notice/SET_RESULT_LIST', res.datas)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-list-item {
  padding: 12px 8px;
}

.list-item {
  cursor: pointer;
  &:hover {
    background: #e6f7ff;
  }
  &.isread {
    opacity: 0.4;
  }
}
</style>
