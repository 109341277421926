<template>
  <div>
    <a-modal
      title="借款信息"
      width="1000px"
      okText="保存"
      class="cjk-modal"
      :visible="visible"
      :keyboard="false"
      :mask="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="h-full overflow-auto flex flex-col">
        <div class="p-3 text-lg font-medium">借款人:{{ username }}</div>
        <div class="px-3 flex-1">
          <a-table
            rowKey="billCode"
            :dataSource="datas.bodyList"
            :columns="columns"
            :pagination="false"
            :loading="loading"
            :rowClassName="
              (record, index) => {
                return index % 2 === 0 ? 'trBgClass' : ''
              }
            "
          >
            <div slot="total" slot-scope="cjkTotal, record">
              <editable-cell
                valType="float"
                :text="cjkTotal"
                :onlyNumber="true"
                @change="onCellChange(record, $event)"
              ></editable-cell>
            </div>
          </a-table>
        </div>
        <div class="p-3">
          <div class="px-6 py-2 rounded" style="background: #ddebf7">
            总记录条数 :
            <span class="font-semibold text-black">
              {{ length }}
            </span>
            条
            <div class="float-right">
              <span>
                合计支款金额 ：
                <span class="font-semibold text-black">
                  {{ datas.ajkTotal }}
                </span>
              </span>
              <a-divider type="vertical" />
              <span>
                合计支款余额 ：
                <span class="font-semibold text-black">{{ datas.ajkye }}</span>
              </span>
              <a-divider type="vertical" />
              <span>
                合计冲支款 ：
                <span class="font-semibold text-black">
                  {{ datas.acjkTotal || 0 }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import EditableCell from '@/views/activity/modules/activebill/EditableCell'
import { getJKInfo, saveCJKData } from '@/api/acdemicActive/acdemic-payment'

const columns = [
  {
    title: '单据编号',
    key: 'detail',
    dataIndex: 'billCode'
  },
  {
    title: '申请日期',
    dataIndex: 'billDate'
  },
  {
    title: '单据类型',
    dataIndex: 'billTypePk'
  },
  {
    title: '币种',
    dataIndex: 'bz'
  },
  {
    title: '支款金额',
    dataIndex: 'jkTotal'
  },
  {
    title: '支款余额',
    dataIndex: 'jkye'
  },
  {
    title: '本次冲支款',
    dataIndex: 'cjkTotal',
    width: 150,
    scopedSlots: { customRender: 'total' }
  }
  // {
  //   title: '操作',
  //   key: 'operation',
  //   align: 'center',
  //   scopedSlots: { customRender: 'operation' }
  // }
]
export default {
  name: 'CjkModal',
  components: {
    EditableCell
  },
  props: {
    username: {
      type: String
    },
    hpk: {
      type: String
    },
    payMentId: {
      type: String
    }
  },
  data() {
    return {
      visible: true,
      loading: false,
      datas: [],
      length: 0,
      columns
    }
  },
  created() {
    this.loading = true
    getJKInfo(this.hpk).then(res => {
      this.datas = res
      this.datas.payMentId = this.payMentId
      this.length = res.bodyList ? res.bodyList.length : 0
      this.loading = false
    })
  },
  methods: {
    // 保存
    handleOk() {
      saveCJKData(this.datas).then(res => {
        this.$emit('cjk', res) //重写冲款信息
        this.$message.success('保存成功')
        this.handleCancel()
      })
    },

    //关闭
    handleCancel() {
      this.$emit('close')
    },

    // 修改本次冲支款金额
    onCellChange(record, value) {
      record.cjkTotal = value
      this.datas.acjkTotal = 0
      for (let item of this.datas.bodyList) {
        this.datas.acjkTotal = this.$np.plus(
          Number(this.datas.acjkTotal),
          Number(item.cjkTotal)
        )
      }
    }
  }
}
</script>

<style lang="less">
.cjk-modal .ant-modal-header {
  padding: 8px 40px;
  background-color: #4587f4;
}
.cjk-modal .ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.cjk-modal .ant-modal-close-x {
  width: 36px;
  height: 36px;
  padding-right: 8px;
  line-height: 39px;
  color: #ffffff;
}
.cjk-modal .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.cjk-modal .ant-modal-body {
  padding: 0px;
  height: 60vh;
}
.cjk-modal .ant-btn-primary,
.cjk-modal .ant-btn-primary:hover {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
}
.cjk-modal .ant-divider {
  background: #a0aec0;
  margin: 0 16px;
}

// .cjk-modal .ant-card-head-title {
//   padding: 9px 0;
// }

// .cjk-modal .ant-table-thead > tr > th {
//   padding: 12px 8px !important;
// }

// .cjk-modal .ant-table-tbody > tr > td {
//   padding: 4px 8px !important;
// }
</style>
