import Vue from 'vue'

import VueDraggableResizable from 'vue-draggable-resizable'
import IconSvg from '@/components/IconSvg'
import VueAMap from 'vue-amap'

Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '6104d68f462587f94c91aa310d8262c9',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Scale',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor'
  ],
  v: '1.4.4'
})

const Components = {
  IconSvg,
  VueDraggableResizable
}

Object.keys(Components).forEach(key => {
  Vue.component(key, Components[key])
})

const req = require.context('../', true, /\.svg$/)
const requireAll = requireContext => requireContext.keys().map(requireContext)
requireAll(req)
