import request from '@/utils/request'

/**
 * 查询所有附言附件信息
 * @param {Object} dto
 */
export function getAddCommentList(pid) {
  return request({
    method: 'post',
    url: `addComment/1/20`,
    data: [{ column: 'relatedBillId', opt: '=', value: pid }]
  })
}

/**
 * 保存附言附件信息
 * @param {Object} dto
 */
export function getSaveComment(dto) {
  return request({
    method: 'post',
    url: `addComment`,
    data: dto
  })
}

/**
 * 根据id查询附言附件信息
 * @param {String} id
 */
export function getGetComment(id) {
  return request({
    method: 'get',
    url: `addComment/${id}`
  })
}

/**
 * 根性附言附件信息
 * @param {String} id
 */
export function getUpdateComment(id) {
  return request({
    method: 'put',
    url: `addComment/${id}`
  })
}

/**
 * 删除附言附件信息
 * @param {String} id
 */
export function getDelComment(id) {
  return request({
    method: 'delete',
    url: `addComment/${id}`
  })
}
