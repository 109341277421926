<template>
  <div>
    <a-modal
      bordered
      title="选择活动类型及执行标准"
      width="1000px"
      class="active-select-modal"
      v-model="visible"
      :keyboard="false"
      @ok="handleOk"
    >
      <div class="w-full">
        <a-table
          bordered
          rowKey="actID"
          :pagination="false"
          :columns="columns"
          :centered="true"
          :dataSource="activeTypeList"
          :loading="loading"
          :rowSelection="{
            onChange: onSelectChange,
            type: 'radio',
            selectedRowKeys: selectedRowKeys
          }"
          :rowClassName="
            (record, index) => {
              return index % 2 === 1 ? 'trBgClass' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  click: () => {
                    sclick(record)
                  }
                }
              }
            }
          "
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
import { loadTableData } from '@/api/acdemicActive/activity-types'

export default {
  name: 'chooseActiveModal',
  props: {
    conferenceType: { type: String }
  },
  data() {
    return {
      visible: false,
      loading: false,
      activeTypeList: [],
      selectedRowKeys: [], //选择的行id
      activeType: '',
      columns: [
        {
          title: '活动类型',
          dataIndex: 'acttype'
        }
      ]
    }
  },

  watch: {
    conferenceType(val) {
      if (!val) return
      loadTableData({
        current: 1,
        pageSize: 200,
        onlyEnable: true,
        stdtype: val
      }).then(res => {
        this.activeTypeList = res.datas
      })
    }
  },

  methods: {
    handleOk() {
      this.visible = false
      const activeType = this.activeTypeList.find(
        item => item.actID === this.activeType
      )
      if (activeType) {
        // 解决model闪烁的问题
        setTimeout(() => this.$emit('activeType', activeType), 500)
      }
    },

    /**
     * 选择的单击事件（直接选择，不关闭弹框）
     * @param {Object} item 自选的活动类型及执行标准
     */
    sclick(item) {
      const activeType = []
      activeType.push(item.actID)
      this.onSelectChange(activeType)
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.activeType = selectedRowKeys[0]
    }
  }
}
</script>

<style lang="less">
.active-select-modal .ant-modal-body {
  padding: 8px 16px;
}
</style>
