<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handClose"
      @cancel="handClose"
      width="56%"
      :title="`${uploadName}附件预览`"
      :closable="false"
      :keyboard="false"
      :mask="false"
      :maskClosable="false"
      :bodyStyle="{ padding: '0 24px' }"
      cancelText="关闭"
      dialogClass="upload-preview-file"
    >
      <div class="flex items-stretch">
        <div class="flex-1 pb-6">
          <a-divider>变更单 {{ uploadName }} 附件</a-divider>
          <a-table
            :rowKey="record => record.fileId || record.id"
            :dataSource="fileList"
            :columns="columns"
            :pagination="false"
            :scroll="{ y: 480 }"
            :rowClassName="
              (_record, index) => {
                return index % 2 === 0 ? '' : 'trBgClass'
              }
            "
          >
            <template slot="fileSrc" slot-scope="text, record">
              <div class="flex items-center justify-between">
                <span
                  class="inline-flex items-center hover:text-blue-600 cursor-pointer"
                  @click="preview(record, 'current')"
                >
                  <span class="inline-block border border-gray-300 rounded p-1">
                    <img :src="record.src" width="24" />
                  </span>
                  <span class="ml-2">{{ record.oname }}</span>
                </span>
                <span
                  class="inline-flex items-center hover:text-blue-600 cursor-pointer"
                >
                  <a-icon type="arrow-down" @click="download(record)" />
                  <span class="ml-1">下载</span>
                </span>
              </div>
            </template>
          </a-table>
        </div>

        <a-divider type="vertical" :dashed="true" class="h-auto" />

        <div class="flex-1 pb-6">
          <a-divider>原申请单 {{ uploadName }} 附件</a-divider>
          <a-table
            :rowKey="record => record.fileId || record.id"
            :dataSource="oFileList"
            :columns="columns"
            :pagination="false"
            :scroll="{ y: 480 }"
            :rowClassName="
              (_record, index) => {
                return index % 2 === 0 ? '' : 'trBgClass'
              }
            "
          >
            <template slot="fileSrc" slot-scope="text, record">
              <div class="flex items-center justify-between">
                <span
                  class="inline-flex items-center hover:text-blue-600 cursor-pointer"
                  @click="preview(record, 'origin')"
                >
                  <span class="inline-block border border-gray-300 rounded p-1">
                    <img :src="record.src" width="24" />
                  </span>
                  <span class="ml-2">{{ record.oname }}</span>
                </span>
                <span
                  class="inline-flex items-center hover:text-blue-600 cursor-pointer"
                >
                  <a-icon type="arrow-down" @click="download(record)" />
                  <span class="ml-1">下载</span>
                </span>
              </div>
            </template>
          </a-table>
        </div>
      </div>
    </a-modal>
    <image-preview
      v-if="showPreviewImage"
      :imageList="imageList"
      :imageIndex="imageIndex"
      @close="showPreviewImage = false"
    ></image-preview>
    <excel-preview
      v-if="showPreviewExcel"
      :fileId="fileId"
      @close="showPreviewExcel = false"
    ></excel-preview>
  </div>
</template>

<script>
import {
  getIMGURL,
  getPDFURL,
  getWORDURL,
  getEXCELURL
} from '@/api/acdemicActive/upload'
import ImagePreview from '@/components/Preview/ImagePreview'
import ExcelPreview from '@/components/Preview/ExcelPreview'

import { saveAs } from 'file-saver'

export default {
  name: 'uploadModal',
  props: {
    uploadName: {
      type: String
    },
    curFile: {
      type: String
    },
    originFile: {
      type: String
    }
  },
  components: {
    ImagePreview,
    ExcelPreview
  },
  data() {
    return {
      visible: true,
      showPreviewExcel: false, //默认打开excel的modal是关闭的
      showPreviewImage: false, //默认打开image的modal是关闭的
      fileId: '', //传入pdf预览框的文件id
      fileList: [], //上传成功的文件列表
      imageList: [], //所有图片的集合列表

      oFileList: [], // 源上传成功的文件列表
      oImageList: [], // 源所有图片的集合列表
      imageIndex: 0, //打开图片预览的索引

      columns: [
        {
          title: '文件',
          dataIndex: 'src',
          key: 'src',
          scopedSlots: { customRender: 'fileSrc' }
        }
      ]
    }
  },

  created() {
    if (this.curFile) {
      this.imageList = []
      this.fileList = JSON.parse(this.curFile)
      this.initFile(this.imageList, this.fileList)
    }

    if (this.originFile) {
      this.oImageList = []
      this.oFileList = JSON.parse(this.originFile)
      this.initFile(this.oImageList, this.oFileList)
    }
  },
  methods: {
    handClose() {
      this.$emit('close')
    },

    initFile(imageList, fileList) {
      for (let file of fileList) {
        if (!['pdf', 'doc', 'docx', 'xlsx', 'xls'].includes(file.type)) {
          this.$set(file, 'src', '')
          getIMGURL(file.id).then(response => {
            file.src = response
            imageList.push(file)
          })
        } else {
          if (!file.src) {
            if (file.type === 'pdf') {
              file.src = require('@/assets/upload/pdf.png')
            } else if (file.type === 'docx' || file.type === 'doc') {
              file.src = require('@/assets/upload/word.png')
            } else if (file.type === 'xls' || file.type === 'xlsx') {
              file.src = require('@/assets/upload/excel.png')
            }
          }
        }
      }
    },

    //预览pdf
    preview(file, type) {
      if (['doc', 'docx', 'xls', 'xlsx', 'pdf'].includes(file.type)) {
        this.fileId = file.id
        this.showPreviewExcel = true
      } else {
        const imageList = type === 'origin' ? this.oImageList : this.imageList

        this.imageIndex = imageList.findIndex(i => i.id === file.id)
        this.showPreviewImage = true
      }
    },

    //文件下载
    download(file) {
      if (file.type === 'pdf') {
        getPDFURL(file.id)
          .then(response => {
            this.createDownload(response, file.oname)
          })
          .catch(() => {
            this.$message('下载失败')
          })
      } else if (file.type === 'doc' || file.type === 'docx') {
        getWORDURL(file.id)
          .then(response => {
            this.createDownload(response, file.oname)
          })
          .catch(() => {
            this.$message('下载失败')
          })
      } else if (file.type === 'xlsx' || file.type === 'xls') {
        getEXCELURL(file.id)
          .then(response => {
            this.createDownload(response, file.oname)
          })
          .catch(() => {
            this.$message('下载失败')
          })
      } else {
        this.createDownload(file.src, file.oname)
      }
    },

    /**
     * 文件下载
     * @param {String} src //blob对象的url
     * @param {String} fileName 下载的文件名称
     */
    createDownload(src, fileName) {
      saveAs(src, fileName)
    }
  }
}
</script>

<style lang="less"></style>
