<template>
  <div class="text-xs">
    <qywsj :type="type" :pid="pid" @status="nowStatus($event)"></qywsj>
  </div>
</template>

<script>
import Qywsj from './qywsj/QYWSJ'

export default {
  name: 'QYWSJBill',
  components: {
    Qywsj
  },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
