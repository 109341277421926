import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 药连锁房产续租/变更申请列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getHouseModifyList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `houseModify/getHouseModifyList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新药连锁房产续租/变更申请
 * @param {Object} data
 * @returns
 */
export function updateHouseModify(data) {
  return request({
    method: 'post',
    url: `houseModify/updateHouseModify`,
    data
  })
}

/**
 * 保存药连锁房产续租/变更申请
 * @param {Object} data
 * @returns
 */
export function saveHouseModify(data) {
  return request({
    method: 'post',
    url: `houseModify/saveHouseModify`,
    data
  })
}

/**
 * 提交药连锁房产续租/变更申请
 * @param {Object} data
 * @returns
 */
export function submitHouseModify(data) {
  return request({
    method: 'post',
    url: `oaflow/submitHouseModify`,
    data
  })
}

/**
 * 保存药连锁房产续租/变更申请
 * @param {String} id
 * @returns
 */
export function delHouseModify(id) {
  return request({
    method: 'post',
    url: `houseModify/delHouseModify/${id}`
  })
}

/**
 * 获取药连锁房产续租/变更申请
 * @param {String} id
 * @returns
 */
export function getHouseModify(id) {
  return request({
    method: 'get',
    url: `houseModify/${id}`
  })
}

/**
 * 导出药连锁房产续租/变更申请
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/houseModify/exportData`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `药连锁房产续租/变更申请`)
  })
}
