<!-- 单据查询模块 -->
<template>
  <!-- 内容区域 -->
  <div>
    <transition name="fade-transform" mode="out-in">
      <keep-alive
        :include="[
          'ApplicationList',
          'DgList',
          'DsList',
          'ReimList',
          'YfkList',
          'MonthSaleList',
          'ReceptList',
          'RentHouseList',
          'RegistryHouseList',
          'HouseDGList',
          'HouseDSList',
          'DormitorList',
          'HouseModifyList'
        ]"
      >
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'query',
  data() {
    return {}
  }
}
</script>
