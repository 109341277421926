<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="备注" key="1" class="customStyle">
          <div
            class="w-full flex flex-wrap justify-start"
            style="padding-left: 24px"
          >
            <div class="w-full">
              <a-textarea
                :rows="4"
                :disabled="isRead"
                v-model="note.remarks"
                @blur="submit"
              />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Note', //备注
  props: {
    note: {
      type: Object,
      required: true
    },
    status: Boolean
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  methods: {
    submit() {
      this.$emit('updateItem')
      this.$emit('update:note', this.note)
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
