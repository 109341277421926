<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="付款信息" key="1" class="customStyle">
          <div class="ml-2 mt-3 flex flex-wrap justify-start">
            <div class="form-item">
              <span class="form-label">
                付款单位
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="payment.payName"
                :disabled="isRead"
                @focus="choosePayName"
              />
            </div>
            <div class="form-item" :title="payment.payBankName">
              <span class="form-label">付款银行名称</span>
              <a-input
                class="form-input"
                v-model="payment.payBankName"
                :disabled="true"
              />
            </div>
            <div class="form-item" :title="payment.payCommbineNum">
              <span class="form-label">付款银行行号</span>
              <a-input
                class="form-input"
                v-model="payment.payCommbineNum"
                :disabled="true"
              />
            </div>
            <div class="form-item" :title="payment.payAccNum">
              <span class="form-label">付款银行账号</span>
              <a-input
                class="form-input"
                v-model="payment.payAccNum"
                :disabled="true"
              />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <payname-preview
      v-if="showPreview"
      @payNameInfo="payNameInfo($event)"
      @close="showPreview = false"
    ></payname-preview>
  </div>
</template>

<script>
import PaynamePreview from './PayNamePreview'

export default {
  name: 'paymentInfo',
  components: {
    PaynamePreview
  },
  props: {
    payment: {
      type: Object,
      required: true
    },
    status: Boolean
  },
  data() {
    return {
      showPreview: false
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  methods: {
    submit() {
      this.$emit('update:payment', this.payment)
    },

    choosePayName() {
      this.showPreview = true
    },

    // // 收款单位改变回调
    // payNameChange(value) {
    //   for (let item of this.payerList) {
    //     if (value.indexOf(item.city) !== -1) {
    //       this.payment.payName = item.payName
    //       this.payment.payBankName = item.payBankName
    //       this.payment.payCode = item.payCode
    //       this.payment.payCommbineNum = item.payCommbineNum
    //       this.payment.payAccNum = item.payAccNum
    //       this.submit()
    //       break
    //     }
    //   }
    // }

    // 收款单位改变回调
    payNameInfo(value) {
      this.payment.payId = value.payId
      this.payment.payName = value.payName
      this.payment.payBankName = value.payBankName
      this.payment.payCode = value.payCode
      this.payment.payCommbineNum = value.payCommbineNum
      this.payment.payAccNum = value.payAccNum
      this.submit()
    }
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
