<!-- 流向直连 -->
<template>
  <!-- 内容区域 -->
  <div class="h-full">
    <transition :name="noTransition ? '' : 'fade-transform'" mode="out-in">
      <keep-alive include="flowToGrab,stockGrab,warehouseGrab,BusinessContent">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'flowdirect',
  computed: {
    noTransition() {
      const noTransitionList = [
        'flowToGrab',
        'flowLogDetail',
        'stockGrab',
        'stockLogDetail',
        'warehouseGrab',
        'warehouseLogDetail'
      ]
      return noTransitionList.includes(this.$route.name)
    }
  }
}
</script>
