<!-- 客户管理 -->
<template>
  <!-- 内容区域 -->
  <div>
    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'custManage',
  data() {
    return {}
  }
}
</script>
