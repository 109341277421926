var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{staticClass:"pl-6",attrs:{"size":"small","laba-width":"90px","laba-position":"right","layout":"vertical"}},[_c('a-collapse',{attrs:{"bordered":false},scopedSlots:_vm._u([{key:"expandIcon",fn:function(props){return [_c('a-icon',{attrs:{"type":"caret-right","rotate":props.isActive ? 90 : 0}})]}}]),model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_vm._l((_vm.baseMsg),function(ref){
var title = ref.title;
var active = ref.active;
var children = ref.children;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},_vm._l((children),function(ref){
var label = ref.label;
var value = ref.value;
return _c('a-col',{key:label,attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":label,"prop":value,"label-col":_vm.itemlay.labelCol,"wrapper-col":_vm.itemlay.wrapperCol}},[(value === 'appStatus')?_c('a-input',{attrs:{"disabled":true,"value":_vm.getStatusText(_vm.formData[value])}}):_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.formData[value]),callback:function ($$v) {_vm.$set(_vm.formData, value, $$v)},expression:"formData[value]"}})],1)],1)}),1)}),_vm._l((_vm.tableMsg),function(ref){
var title = ref.title;
var active = ref.active;
var dataList = ref.dataList;
var columns = ref.columns;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},[_c('a-table',{attrs:{"rowKey":"field","dataSource":_vm.formData[dataList],"columns":columns,"pagination":false,"rowClassName":function (record, index) {
              return index % 2 === 0 ? 'trBgClass' : ''
            }}})],1)}),_vm._l((_vm.productList),function(ref){
            var title = ref.title;
            var active = ref.active;
            var dataList = ref.dataList;
            var columns = ref.columns;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},_vm._l((_vm.formData[dataList]),function(item,index){return _c('div',{key:index,staticClass:"product-list"},[_c('div',{staticClass:"product-item"},[_vm._v(" "+_vm._s(item.productName)+" "),_c('span',{staticClass:"float-right mr-4",class:_vm.getColor(item.prodInfoStatus)},[_vm._v(" "+_vm._s(_vm.getText(item.prodInfoStatus))+" ")])]),_c('a-table',{staticClass:"product",attrs:{"rowKey":"id","dataSource":item.saPostInfoDTOs,"columns":columns,"pagination":false},scopedSlots:_vm._u([{key:"indicator",fn:function(indicatorProportion, record){return [_c('span',{staticClass:"mx-1"},[_vm._v("旧:")]),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(record.oriIndicatorProportion || '0')+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("新:")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(indicatorProportion || '0'))])]}},{key:"sales",fn:function(salesProportion, record){return [_c('span',{staticClass:"mx-1"},[_vm._v("旧:")]),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(record.oriSalesProportion || '0')+" ")]),_c('span',{staticClass:"mx-1"},[_vm._v("新:")]),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(salesProportion || '0')+" ")])]}}],null,true)})],1)}),0)})],2),(_vm.formData.flowInfoDTO)?_c('process',{attrs:{"summaryid":_vm.formData.flowInfoDTO.summaryid,"nextapproveman":_vm.formData.flowInfoDTO.nextapproveman,"curApprover":_vm.formData.flowInfoDTO.curApprover}}):_vm._e(),_c('comment',{attrs:{"billId":_vm.formData.id}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }