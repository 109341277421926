import debounce from 'lodash/debounce'

/* eslint-disable prettier/prettier */
export default {
  data() {
    return {
      chooseSpeakerType: 'add',
      drawerInx: 9999,
      chooseSpeakerInx: 0,
      speakerVisible: false, // 讲者右弹窗

      speakerId: '',
      speaker: {
        budgetConsultTotal: 0, // 讲者劳务费合计
        speakers: []
      },

      speakerColumns: [
        { title: '序号', dataIndex: 'number', width: 72, scopedSlots: { customRender: 'number' } },
        { title: '讲者姓名', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
        { title: '讲者编号', dataIndex: 'code'},
        { title: '讲者单位', dataIndex: 'departmentName' },
        { title: '讲者级别', dataIndex: 'speakerLevelName' },
        { dataIndex: 'appConsultingFee', slots: { title: 'feeTitle' }, scopedSlots: { customRender: 'fee' } },
        { dataIndex: 'topicname', slots: { title: 'topicTitle' }, scopedSlots: { customRender: 'topic' } },
        { dataIndex: 'lengthoflecture', slots: { title: 'lectureTitle' }, scopedSlots: { customRender: 'lecture' } }
      ]
    }
  },

  methods: {
    // 查看讲者信息
    showSpeakerInfo(index){
      this.drawerInx = index
      this.speakerVisible = true
    },

    // 选择讲者
    showSpeakerVisible(type, index) {
      if(type === 'update'){ 
        this.chooseSpeakerInx = index
      }

      this.chooseSpeakerType = type
      this.$refs.speakermodel.speakerIds = ''
      this.$refs.speakermodel.searchText = ''
      this.$refs.speakermodel.selectedRowKeys = [] //选择的行id
      this.$refs.speakermodel.onSearch('')
      this.$refs.speakermodel.visible = true
    },
    chooseSpeaker(speaker) {
      if(this.chooseSpeakerType === 'update'){
        this.speaker.speakers.splice(this.chooseSpeakerInx, 1, speaker)

        // 处理修改讲者信息查看不更新的问题
        if(this.$refs.speakerDrawer){
          const isChange = this.$refs.speakerDrawer.speakerInx === this.chooseSpeakerInx
          if(isChange) this.$refs.speakerDrawer.handlerSpeaker(this.chooseSpeakerInx)
        }
      }
      
      else this.speaker.speakers.push(speaker)
    },
    delLastSpeaker() {
      if(this.speaker.speakers.length){
        if(this.speakerId) {
          this.speaker.speakers = this.speaker.speakers.filter(item => item.speakerId !== this.speakerId)
        }else {
          this.speaker.speakers.pop()
        }
        
        this.calcFee()
      }
    },

    // 计算讲者咨询费
    calcFee: debounce(function() {
      this.speaker.budgetConsultTotal = this.speaker.speakers.reduce((prev, cur) => {
        return this.$np.plus(Number(cur.appConsultingFee || '0'), prev)
      }, 0)
    }, 800)
  }
}
