<template>
  <div class="card-containers">
    <div class="clearfix">
      <div class="file-list-card">
        <div
          class="file-list"
          :title="file.oname"
          v-for="file in fileList"
          :key="file.id"
        >
          <!-- <a-icon
            type="close-circle"
            class="icon-close-btn"
            title="删除"
            @click="remove(file.id)"
          /> -->
          <div class="file-info">
            <div class="file-mask">
              <span class="file-type">
                <a-icon
                  type="eye"
                  class="text-preview"
                  title="预览文件"
                  @click="preview(file)"
                />
                <a-icon
                  v-if="!isEdit"
                  type="delete"
                  class="text-download"
                  title="删除文件"
                  @click="remove(file.id)"
                />
              </span>
            </div>
            <img :src="file.src" />
          </div>
          <div class="file-name">{{ file.oname }}</div>
        </div>
      </div>
      <div v-if="!isEdit" class="add-file" @click="chooseFile">
        <span class="file-btn">
          <input
            type="file"
            :multiple="true"
            accept="
                image/gif, image/jpeg, 
                image/png, application/pdf,application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            style="display: none"
            @change="fileChange"
            ref="file"
          />
          <div>
            <a-icon type="plus" />
            <div class="ant-upload-text">添加文件</div>
          </div>
        </span>
      </div>
    </div>
    <excel-preview
      v-if="showPreviewExcel"
      :fileId="fileId"
      @close="showPreviewExcel = false"
    ></excel-preview>
    <image-preview
      v-if="showPreviewImage"
      :imageList="imageList"
      :imageIndex="imageIndex"
      @close="closeImagePreview"
    ></image-preview>
  </div>
</template>

<script>
import {
  getBatch,
  getUpload,
  getIMGURL,
  getDeleteFile
} from '@/api/acdemicActive/upload'

import ExcelPreview from '@/components/Preview/ExcelPreview'
import ImagePreview from '@/components/Preview/ImagePreview'
export default {
  name: 'FileUpload',
  components: { ImagePreview, ExcelPreview },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    annex: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fileId: '', //传入pdf预览框的文件id
      title: '', //modal的title
      fileList: [], //上传成功的文件列表
      imageList: [], //所有图片的集合列表
      imageIndex: 0, //打开图片预览的索引
      showPreviewExcel: false, // 默认打开excel的modal是关闭的
      showPreviewImage: false //默认打开image的modal是关闭的
    }
  },
  watch: {
    annex(val) {
      if (val) {
        this.imageList = []
        this.fileList = JSON.parse(val)
        this.fileList.forEach((file, index) => {
          if (!['pdf', 'doc', 'docx', 'xlsx', 'xls'].includes(file.type)) {
            getIMGURL(file.id).then(response => {
              this.$set(this.fileList[index], 'src', response)
              this.imageList.push(file)
            })
          } else {
            if (!file.src) {
              if (file.type === 'pdf') {
                file.src = require('@/assets/upload/pdf.png')
              } else if (file.type === 'docx' || file.type === 'doc') {
                file.src = require('@/assets/upload/word.png')
              } else if (file.type === 'xls' || file.type === 'xlsx') {
                file.src = require('@/assets/upload/excel.png')
              }
            }
          }
        })
      }
    }
  },
  methods: {
    chooseFile() {
      this.$refs.file.value = null
      this.$refs.file.click()
    },

    // 更新产品的附件信息接口
    updateAnnex() {
      this.$emit('updateAnnex', JSON.stringify(this.fileList))
    },

    // 文件上传
    fileChange() {
      let myfile = this.$refs.file
      if (myfile.files[0] === undefined) {
        this.$message.warning('未上传任何文件！')
      } else {
        // 上传单个文件
        if (myfile.files.length === 1) {
          let formData = new FormData()
          formData.append('file', myfile.files[0])
          this.uploadFile(formData)
        } else {
          // 批量上传文件
          let formData = new FormData()
          for (let item of myfile.files) {
            formData.append('files', item)
          }
          this.uploadBatchFile(formData)
        }
      }
    },

    //删除某个文件
    remove(id) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].id === id) {
          this.$message.loading(`正在删除${this.fileList[i].oname}...`, 0)
          getDeleteFile(id)
            .then(() => {
              this.$message.destroy()
              this.$message.success(`${this.fileList[i].oname}删除成功`)
              this.fileList.splice(i, 1)
              this.updateAnnex()
            })
            .catch(() => {
              this.$message.destroy()
              this.$message.error(`${this.fileList[i].oname}删除失败`)
            })
          break
        }
      }
    },

    //预览pdf
    preview(file) {
      if (['doc', 'docx', 'pdf', 'xlsx', 'xls'].includes(file.type)) {
        this.fileId = file.id
        this.showPreviewExcel = true
      } else {
        for (let i = 0; i < this.imageList.length; i++) {
          if (this.imageList[i].id === file.id) {
            this.imageIndex = i
            break
          }
        }
        this.showPreviewImage = true
      }
    },

    //关闭image预览窗口
    closeImagePreview() {
      this.showPreviewImage = false
    },

    /**
     * 上传单个文件
     * @param {File} file
     */
    uploadFile(file) {
      this.$message.loading(`正在上传文件...`, 0)
      getUpload(file)
        .then(response => {
          const file = response
          if (file.type === 'pdf') {
            file.src = require('@/assets/upload/pdf.png')
            this.fileList.push(file)
          } else if (file.type === 'docx' || file.type === 'doc') {
            file.src = require('@/assets/upload/word.png')
            this.fileList.push(file)
          } else if (file.type === 'xls' || file.type === 'xlsx') {
            file.src = require('@/assets/upload/excel.png')
            this.fileList.push(file)
          } else {
            this.fileList.push(file)
          }
          this.updateAnnex()
          this.$message.destroy()
          this.$message.success(`${file.oname}上传成功`)
        })
        .catch(() => {
          this.$message.destroy()
          this.$message.error('上传失败')
        })
    },

    /**
     * 批量上传文件
     * @param {Array} fileList
     */
    uploadBatchFile(fileList) {
      this.$message.loading(`正在批量上传文件...`, 0)
      getBatch(fileList)
        .then(response => {
          const list = response
          for (let file of list) {
            if (file.type === 'pdf') {
              file.src = require('@/assets/upload/pdf.png')
              this.fileList.push(file)
            } else if (file.type === 'docx' || file.type === 'doc') {
              file.src = require('@/assets/upload/word.png')
              this.fileList.push(file)
            } else if (file.type === 'xls' || file.type === 'xlsx') {
              file.src = require('@/assets/upload/excel.png')
              this.fileList.push(file)
            } else {
              this.fileList.push(file)
            }
          }
          this.updateAnnex()
          this.$message.destroy()
          this.$message.success('上传成功')
        })
        .catch(() => {
          this.$message.destroy()
          this.$message.error('上传失败')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.card-containers {
  padding: 24px 0;
}

.file-list-card {
  float: left;
  &:before {
    content: '';
    display: table;
  }
}

.file-list {
  float: left;
  width: 120px;
  height: 120px;
  margin: 0 8px 24px 0;
  background: transparent;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    border: 1px solid #90cdf4;
  }
}

.file-info {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.file-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
}

.file-type {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  white-space: nowrap;
  transition: all 0.3;
}

.file-name {
  margin-top: 10px;
  font-size: 8px;
  text-align: center;
  max-width: 120px;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

.card-containers .icon-close-btn {
  color: #f56565;
  cursor: pointer;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: 100;
  &:hover {
    color: red;
  }
}

.card-containers .add-file {
  width: 120px;
  height: 120px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  background-color: #fafafa;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease;
  vertical-align: top;
  margin-right: 8px;
  margin-bottom: 8px;
  display: table;
  &:hover {
    border: 1px dashed #40a9ff;
  }
}

.file-btn {
  width: 100%;
  height: 100%;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  padding: 8px;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  box-sizing: border-box;
  margin: 0;
}
</style>
