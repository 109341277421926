<template>
  <div>
    <a-modal
      title="附件预览"
      v-model="visible"
      :mask="false"
      dialogClass="preview-file-modal clearfix"
      :maskClosable="false"
      width="85%"
      @cancel="handleClose"
      :footer="null"
    >
      <div class="file-content w-full flex flex-row">
        <!-- 左侧相似度预览 -->
        <div v-if="seeSign" style="width: 256px; height: 100%">
          <a-card
            style="width: 256px; height: 100%"
            :bordered="false"
            :bodyStyle="{ padding: '0 16px', height: '100%' }"
          >
            <div class="w-full h-full flex flex-col">
              <!-- 标题 -->
              <a-popover :visible="showSimilarVisible" placement="rightTop">
                <template slot="content">
                  <div class="image-preview flex flex-col">
                    <p class="text-sm">相似度图片查看</p>
                    <div
                      class="flex-1 flex justify-center p-4 rounded"
                      style="background-color: rgba(0, 0, 0, 0.5)"
                    >
                      <img
                        :src="previewImg"
                        alt="相似度图片"
                        style="height: 360px"
                        class=""
                      />
                    </div>
                    <div class="mt-2">
                      <a-button type="primary" block @click="previewBigImg">
                        查看大图
                      </a-button>
                      <a-button class="mt-2" block @click="closeSimilarVisible">
                        关闭
                      </a-button>
                    </div>
                  </div>
                </template>
                <p class="font-bold text-base">会议相似照片</p>
              </a-popover>
              <!-- 相似度列表 -->
              <div class="flex-1 mt-2 overflow-y-auto">
                <div
                  class="similar-card"
                  :class="[similarImgId === item.id ? 'active' : '']"
                  v-for="item in images[viewerIndex].similarityLs || []"
                  :key="item.id"
                  @click="getBase64Image(item.id)"
                >
                  <div class="text-xs">{{ item.conferenceName }}</div>
                  <div class="text-xs mt-2 text-gray-500">
                    {{ item.conferenceCode }}
                  </div>
                  <div class="text-xs mt-2 text-gray-500">
                    相似度: {{ $np.times($np.round(item.score, 4), 100) }} %
                  </div>
                </div>

                <a-empty
                  v-if="
                    !images[viewerIndex].similarityLs ||
                    !images[viewerIndex].similarityLs.length
                  "
                  :image="simpleImage"
                />
              </div>
            </div>
          </a-card>
        </div>
        <!-- 右侧图片预览 -->
        <div class="relative flex-1 w-full h-full">
          <viewer :images="images" ref="viewer">
            <img
              v-for="(file, index) in images"
              :src="file.src"
              :key="`${index}`"
              style="display: none"
            />
          </viewer>

          <div
            v-if="seeSign"
            class="absolute px-2"
            style="right: 0; bottom: 52px"
          >
            <span class="text-teal-200">
              活动点距离 :
              {{
                images[viewerIndex].distance
                  ? `${images[viewerIndex].distance} km`
                  : '无法计算'
              }}
            </span>
            <br />
            <span class="text-teal-200">
              照片人数 :
              {{
                images[viewerIndex].peopleCount === null
                  ? '旧照未计算人数'
                  : images[viewerIndex].peopleCount || 0
              }}
            </span>
            <br />
            <span class="text-teal-200">
              拍照时间 :
              {{ images[viewerIndex].creationtime || images[viewerIndex].ts }}
            </span>
            <br />
            <span class="text-teal-200" v-if="images[viewerIndex].location">
              拍照地址 : {{ images[viewerIndex].location }}
            </span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getBase64IMG } from '@/api/acdemicActive/upload'
import { Empty } from 'ant-design-vue'
export default {
  name: 'ImagePreview',
  props: {
    imageList: {
      type: Array,
      required: true
    },
    seeSign: {
      type: Boolean,
      default: false
    },
    imageIndex: {
      type: Number,
      default: 1
    }
  },

  watch: {
    'viewer.index': function (nval) {
      if (nval > -1) {
        this.viewerIndex = nval
      }
    }
  },

  beforeCreate() {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },

  data() {
    return {
      viewer: { index: -1 },
      viewerIndex: this.imageIndex,
      visible: true,
      images: [],

      similarImgId: '',
      showSimilarVisible: false,
      loading: false,
      previewImg: '', // 相似度预览的base64图片
      similarImageMap: new Map()
    }
  },
  created() {
    for (let file of this.imageList) {
      this.images.push(file)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.viewer.$viewer.view(this.viewerIndex)
      this.viewer = this.$refs.viewer.$viewer
    })
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },

    getBase64Image(id) {
      this.similarImgId = id
      if (this.similarImageMap.has(id)) {
        this.previewImg = this.similarImageMap.get(id)
        this.showSimilarVisible = true
        return
      }

      this.loading = true
      getBase64IMG(id)
        .then(res => {
          this.previewImg = res
          this.showSimilarVisible = true
          this.similarImageMap.set(id, res)
        })
        .finally(() => {
          this.loading = false
        })
    },

    previewBigImg() {
      this.$hevueImgPreview(this.previewImg)
    },

    closeSimilarVisible() {
      this.similarImgId = ''
      this.showSimilarVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.similar-card {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  margin-bottom: 12px;
  &:hover {
    background-color: #e8e8e8;
  }
  &.active {
    background-color: #e8e8e8;
  }
}

.image-preview {
  width: 500px;
  height: 500px;
  padding: 8px;
}
</style>
