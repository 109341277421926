import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 根据申请单id查询会议签到信息
 */
export function getListHead(id) {
  return request({
    method: 'post',
    url: 'actAppSignIn/getListHead',
    params: { appId: id }
  })
}

/**
 * 根据申请单id查询会议签到信息
 * @returns
 */
export function getListBody(id, pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `actAppSignIn/getListBody/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 查询所有申请单(仅用户自己)
 * @returns
 */
export function getAppList() {
  return request({
    method: 'post',
    url: 'actAppSignIn/getAppList'
  })
}

/**
 * 导出会议签到
 */
export function getExportExcel(conditions, name) {
  return request({
    method: 'post',
    url: `actAppSignIn/export`,
    data: conditions,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `${name}`)
  })
}
