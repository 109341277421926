import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 分页查询外部公司发货单
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getOuterCompBillList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `outerCompBill/page/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 分页查询外部回款发货核销单(回款)表体
 * @param {String} hid
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getOuterCompBodyList(hid, pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `outerCompBill/body/${pageindex}/${pagenum}`,
    data: conditions,
    params: { hid }
  })
}

/**
 * 分页查询收货地址
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getAddrList(bid) {
  return request({
    method: 'post',
    url: `outerCompBill/getAddrList`,
    params: { busiCompId: bid }
  })
}

/**
 * 分页查询开户行
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getAcconutList(bid) {
  return request({
    method: 'post',
    url: `outerCompBill/getAccountInfo`,
    params: { busiCompId: bid }
  })
}

/**
 * 删除外部公司发货单
 * @param {String} id
 * @returns
 */
export function delOuterCompBill(id) {
  return request({
    method: 'post',
    url: `outerCompBill/del/${id}`
  })
}

/**
 * 根据ID来外部公司发货单
 * @param {String} id
 * @returns
 */
export function getOuterCompBill(id) {
  return request({
    method: 'get',
    url: `outerCompBill/${id}`
  })
}

/**
 * 保存外部公司发货单
 * @param {Object} data
 * @returns
 */
export function addOuterCompBill(data) {
  return request({
    method: 'post',
    url: `outerCompBill/add`,
    data
  })
}

/**
 * 更新外部公司发货单
 * @param {Object} data
 * @returns
 */
export function updateOuterCompBill(data) {
  return request({
    method: 'post',
    url: `outerCompBill/update`,
    data
  })
}

/**
 * 提交外部公司发货单
 * @param {Object} data
 * @returns
 */
export function submitOuterCompBill(data) {
  return request({
    method: 'put',
    url: `oaflow/submitOuterCompBill`,
    data
  })
}

/**
 * 导出外部公司发货单
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/outerCompBill/export`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `外部公司发货单`)
  })
}
