<template>
  <section class="page">
    <h3 class="pt-4 text-center text-xl font-normal">{{ title }}</h3>

    <table class="table-main">
      <tr>
        <td colspan="6" class="header-msg">申请人信息</td>
      </tr>
      <tr>
        <td colspan="1" class="label">申请人</td>
        <td colspan="1" class="label-val">
          {{ datas.applicantDTO ? datas.applicantDTO.username : '' }}
        </td>
        <td colspan="1" class="label">岗位</td>
        <td colspan="1" class="label-val">{{ datas.post }}</td>
        <td colspan="1" class="label">联系方式</td>
        <td colspan="1" class="label-val">{{ datas.contact }}</td>
      </tr>
      <tr>
        <td colspan="1" class="label">申请日期</td>
        <td colspan="1" class="label-val">{{ datas.appdate }}</td>
        <td colspan="1" class="label">大区/部门</td>
        <td colspan="1" class="label-val">
          {{ datas.regionDTO ? datas.regionDTO.deptname : '' }}
        </td>
        <td colspan="1" class="label">省办</td>
        <td colspan="1" class="label-val">
          {{ datas.provinceDTO ? datas.provinceDTO.deptname : '' }}
        </td>
      </tr>
      <tr>
        <td colspan="1" class="label">地办</td>
        <td colspan="1" class="label-val">
          {{ datas.localityDTO ? datas.localityDTO.deptname : '' }}
        </td>
        <td colspan="1" class="label">部门</td>
        <td colspan="3" class="label-val">
          {{ datas.departmentDTO ? datas.departmentDTO.deptname : '' }}
        </td>
      </tr>

      <tr>
        <td colspan="6" class="header-msg">活动信息</td>
      </tr>
      <tr>
        <td colspan="1" class="label">会议名称</td>
        <td colspan="3" class="label-val">{{ datas.conferenceName }}</td>
        <td colspan="1" class="label">会议编号</td>
        <td colspan="1" class="label-val">{{ datas.conferenceCode }}</td>
      </tr>
      <tr>
        <td colspan="1" class="label">所属公司</td>
        <td colspan="1" class="label-val">
          {{ datas.conferenceTypeDTO ? datas.conferenceTypeDTO.name : '' }}
        </td>
        <td colspan="1" class="label">业务类型</td>
        <td colspan="1" class="label-val">
          {{ datas.businessTypeDTO ? datas.businessTypeDTO.name : '' }}
        </td>
        <td colspan="1" class="label">产品</td>
        <td colspan="1" class="label-val">
          {{ datas.productDTO ? datas.productDTO.name : '' }}
        </td>
      </tr>
      <tr v-if="getConferenceType && getConferenceType === '总部'">
        <td colspan="1" class="label">举办类型</td>
        <td colspan="5" class="label-val">
          {{ datas.hostingTypeDTO ? datas.hostingTypeDTO.name : '' }}
        </td>
      </tr>
      <tr v-if="[1, 2, 4].includes(getHostingType)">
        <td colspan="1" class="label">实际活动地点</td>
        <td colspan="3" class="label-val">{{ datas.actualActivityPlace }}</td>
        <td colspan="1" class="label">参会客户到场人数</td>
        <td colspan="1" class="label-val">{{ datas.reimTurnout }}</td>
      </tr>
      <tr v-if="[1, 2, 4].includes(getHostingType)">
        <td colspan="1" class="label">内部人员到场人数</td>
        <td colspan="1" class="label-val">{{ datas.internalnum }}</td>
        <td colspan="1" class="label">实际开始日期</td>
        <td colspan="1" class="label-val">{{ datas.actualActivityStdate }}</td>
        <td colspan="1" class="label">实际结束日期</td>
        <td colspan="1" class="label-val">{{ datas.actualActivityEddate }}</td>
      </tr>
      <tr v-if="[3, 4].includes(getHostingType)">
        <td colspan="1" class="label">实际开始日期</td>
        <td colspan="2" class="label-val">{{ datas.actualActivityStdate }}</td>
        <td colspan="1" class="label">实际结束日期</td>
        <td colspan="2" class="label-val">{{ datas.actualActivityEddate }}</td>
      </tr>
    </table>

    <!-- 讲者信息 -->
    <table
      class="layout-auto"
      v-if="
        [1, 2, 4].includes(getHostingType) &&
        datas.speakers &&
        datas.speakers.length
      "
    >
      <tr>
        <td colspan="7" class="header-msg">讲者信息</td>
      </tr>
      <tr>
        <th>讲者编号</th>
        <th>名称</th>
        <th>单位</th>
        <th>级别</th>
        <th>咨询费</th>
        <th>课题名称</th>
        <th>讲课时长/分钟</th>
      </tr>
      <tr v-for="item in datas.speakers" :key="item.id">
        <td>{{ item.code }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.departmentName }}</td>
        <td>{{ item.speakerLevelName }}</td>
        <td>{{ item.appConsultingFee }}</td>
        <td>{{ item.topicname }}</td>
        <td>{{ item.lengthoflecture }}</td>
      </tr>
    </table>

    <!-- 推广物料信息 -->
    <table
      class="layout-auto"
      v-if="
        [1, 2, 4].includes(getHostingType) &&
        datas.brandpreInfoDTOs &&
        datas.brandpreInfoDTOs.length
      "
    >
      <tr>
        <td colspan="8" class="header-msg">推广物料信息</td>
      </tr>
      <tr>
        <th>物料编号</th>
        <th>类型</th>
        <th>物料名称</th>
        <th>标识</th>
        <th>起订单价</th>
        <th>申请数量</th>
        <th>金额</th>
      </tr>
      <tr v-for="item in datas.brandpreInfoDTOs" :key="item.id">
        <td>{{ item.materialCode }}</td>
        <td>{{ item.type }}</td>
        <td>{{ item.materialName }}</td>
        <td>{{ item.materialIdentification }}</td>
        <td>{{ item.price }}</td>
        <td>{{ item.brandpreNumApp }}</td>
        <td>{{ calculatePrice(item.price, item.brandpreNumApp) }}</td>
      </tr>
    </table>

    <!-- 品牌信息发布详情 -->
    <table class="layout-auto" v-if="[3, 4].includes(getHostingType)">
      <tr>
        <td colspan="8" class="header-msg">实际品牌信息发布明细</td>
      </tr>
      <tr>
        <th>序号</th>
        <th>合作方名称</th>
        <th>合作金额</th>
      </tr>
      <tr v-for="item in datas.expenseDetailDTOs" :key="item.id">
        <td>{{ item.seriNumber }}</td>
        <td>{{ item.partnerName }}</td>
        <td>{{ item.copAmount }}</td>
      </tr>
    </table>

    <table>
      <!-- 费用信息 -->
      <tr>
        <td colspan="7" class="header-msg">费用信息</td>
      </tr>
      <tr>
        <th rowspan="2">项目</th>
        <th colspan="3">预算</th>
        <th colspan="3">实际花销</th>
      </tr>
      <tr>
        <th>单价</th>
        <th>数量</th>
        <th>总价</th>
        <th>单价</th>
        <th>数量</th>
        <th>总价</th>
      </tr>
      <tr
        v-for="{
          id,
          projectName,
          reimPrice,
          reimNum,
          reimTotal,
          price,
          num,
          total
        } in feeTable.dataList"
        :key="id"
      >
        <template v-if="checkConferenceType(id) && checkHostingType(id)">
          <td class="label">{{ projectName }}</td>
          <td class="label">
            {{ reimPrice === '--' ? reimPrice || 0 : datas[reimPrice] }}
          </td>
          <td class="label">
            {{ reimNum === '--' ? reimNum || 0 : datas[reimNum] }}
          </td>
          <td class="label">
            {{
              reimPrice === '--'
                ? datas[reimTotal] || 0
                : calculatePrice(datas[reimPrice], datas[reimNum])
            }}
          </td>
          <td class="label">
            {{ price === '--' ? price || 0 : datas[price] }}
          </td>
          <td class="label">{{ num === '--' ? num || 0 : datas[num] }}</td>
          <td class="label">
            {{
              price === '--'
                ? datas[total] || 0
                : calculatePrice(datas[price], datas[num])
            }}
          </td>
        </template>
      </tr>
      <tr>
        <td colspan="1" class="label">金额合计>></td>
        <td colspan="2" class="label">预算总金额</td>
        <td colspan="1">{{ '￥' + (reimFeeTotal || 0) }}</td>
        <td colspan="2" class="label">实际花销合计</td>
        <td colspan="1">{{ '￥' + (feeTotal || 0) }}</td>
      </tr>
    </table>

    <table class="table-footer">
      <tr>
        <td class="label">
          已预付金额: {{ '￥' + (datas.budgetPreCost || 0) }}
        </td>
        <td class="label">
          批准报销金额：{{ '￥' + (datas.reimAmountApprove || 0) }}
        </td>
        <td class="label">
          财务批准报销金额: {{ '￥' + (datas.financialApprovalAmount || 0) }}
        </td>
      </tr>
    </table>

    <section v-if="datas.flowInfoDTO && options.includes('flow')">
      <oa-process
        :summaryid="datas.flowInfoDTO.summaryid"
        :nextapproveman="datas.flowInfoDTO.nextapproveman"
        :curApprover="datas.flowInfoDTO.curApprover"
      />
    </section>

    <section v-if="options.includes('ps')">
      <ps :pid="datas.id"></ps>
    </section>

    <section v-show="options.includes('comment')">
      <comment :billId="datas.id" />
    </section>
  </section>
</template>

<script>
import OaProcess from './components/OaProcess'
import Comment from './components/CommentList'
import Ps from './components/PS'
import { REIM_FEE } from '@/views/query/util/reim-config'
export default {
  name: 'XssqPrint',
  components: {
    OaProcess,
    Comment,
    Ps
  },
  props: {
    title: String,
    datas: Object,
    options: {
      type: Array,
      default: () => ['flow', 'ps', 'comment']
    }
  },
  data() {
    return {
      reimFeeTotal: 0,
      feeTotal: 0,
      feeTable: REIM_FEE
    }
  },
  computed: {
    getHostingType() {
      const hostingType = this.datas.hostingTypeDTO
      if (!hostingType) return 1

      let active = false
      let purtive = false
      if (hostingType.name.indexOf('学术推广活动') !== -1) active = true
      if (hostingType.name.indexOf('品牌信息发布') !== -1) purtive = true

      if (active && !purtive) return 2 //举办类型为学术推广
      if (!active && purtive) return 3 //举办类型为品牌信息
      if (active && purtive) return 4 //举办类型为学术推广+品牌信息

      return 1 //举办类型为空
    },

    getConferenceType() {
      const conferenceType = this.datas.conferenceTypeDTO
        ? this.datas.conferenceTypeDTO.name
        : ''

      return conferenceType
    }
  },
  watch: {
    datas: {
      handler(nval) {
        if (nval && Object.keys(nval).length) {
          this.feeTotal = 0
          this.reimFeeTotal = 0
          this.feeTable.dataList.forEach(item => {
            if (item.price === '--' && item.num === '--') {
              const reimTotal = nval[item.reimTotal] || 0
              const total = nval[item.total] || 0

              // 预算金额
              this.reimFeeTotal = this.$np.plus(
                Number(this.reimFeeTotal),
                Number(reimTotal)
              )
              // 实际花费的金额
              this.feeTotal = this.$np.plus(
                Number(this.feeTotal),
                Number(total)
              )
            } else {
              const reimPrice = nval[item.reimPrice] || 0
              const reimNum = nval[item.reimNum] || 0
              const reimTotal = this.$np.times(
                Number(reimPrice),
                Number(reimNum)
              )
              const price = nval[item.price] || 0
              const num = nval[item.num] || 0
              const total = this.$np.times(Number(price), Number(num))

              // 预算金额
              this.reimFeeTotal = this.$np.plus(
                Number(this.reimFeeTotal),
                Number(reimTotal)
              )
              // 实际花费的金额
              this.feeTotal = this.$np.plus(
                Number(this.feeTotal),
                Number(total)
              )
            }
          })
        }
      },
      deep: true
    }
  },

  methods: {
    calculatePrice(num1, num2) {
      if (num1 && num2) {
        const num = this.$np.times(Number(num1), Number(num2))
        return num
      }
      return 0
    },

    checkConferenceType(id) {
      if (!['Booth', 'Cooperation'].includes(id)) return true
      if (!this.datas.conferenceTypeDTO) return true
      if (this.datas.conferenceTypeDTO.name === '地区') return false
      return true
    },

    checkHostingType(id) {
      if ('InfoPubAmount' !== id) return true
      if ([1, 2].includes(this.getHostingType)) return false
      if ([3, 4].includes(this.getHostingType)) return true
    }
  }
}
</script>

<style lang="less" scoped>
@page {
  size: auto A4;
  margin: 16px 0 16px 0;
}

.page {
  position: relative;
  width: 24cm;
  overflow: hidden;
  padding-left: 0.66cm;
  padding-right: 0.68cm;
}
table {
  width: 100%;
  table-layout: fixed;
  &.layout-auto {
    table-layout: auto;
  }
  & th {
    border: 1px solid #000;
    height: 0.5cm;
    font-size: 12px;
    text-align: center;
  }
  & td {
    border: 1px solid #000;
    height: 0.5cm;
    font-size: 12px;
    text-align: center;
    &.label {
      font-weight: 600;
    }
    &.label-val {
      text-align: left;
      padding: 0 8px;
    }
  }
}
.table-footer td {
  border: 0px solid #000;
  text-align: left;
  font-size: 14px;
}

.header-msg {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
</style>
