<template>
  <div class="speaker">
    <a-form labelAlign="left">
      <a-row>
        <a-col :span="24" v-for="item in standard.list" :key="item.prop">
          <a-form-item
            v-if="item.type === 'between' && avtivityType[item.propGt]"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :label="item.label"
          >
            {{
              `${avtivityType[item.propGt] || '?'} ~ ${
                avtivityType[item.propLt] || '?'
              }`
            }}
          </a-form-item>
          <a-form-item
            v-else
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :label="item.label"
          >
            {{ avtivityType[item.prop] || '无' }}
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  name: 'StandardDrawer',
  props: {
    avtivityType: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },

      // 查看讲者的信息
      standard: {
        title: '个人信息',
        list: [
          { label: '活动类型', prop: 'acttype' },
          { label: '参会客户人数（不含讲者）', prop: 'customernum' },
          { label: '内部人员数', prop: 'insidersNum' },
          {
            label: '讲者人数',
            prop: 'speakernum',
            propGt: 'speakernumGt',
            propLt: 'speakernumLt',
            type: 'between'
          },
          { label: '讲者劳务费(元/人)', prop: 'speakerConsultationFee' },
          { label: '讲者讲课时间 (分钟/人)', prop: 'lectureTime' },
          { label: '交通费(元)', prop: 'trafficExpense' },
          { label: '接送费(元)', prop: 'transportExpense' },
          { label: '场地费(元/天)', prop: 'venuefee' },
          { label: '住宿费(元/人/天)', prop: 'hotelfee' },
          { label: '餐费(元/人/天)', prop: 'mealsfee' },
          { label: '会议时长(天)', prop: 'meetingLength' },
          { label: '备注', prop: 'notes' }
        ]
      }
    }
  },

  methods: {}
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-control {
  line-height: 24px;
}

/deep/.ant-form-item-label > label {
  font-weight: bold;
}
</style>
