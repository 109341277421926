<template>
  <div>
    <div style="padding-left: 32px" class="my-4">
      <a-form
        size="small"
        laba-width="90px"
        laba-position="right"
        layout="vertical"
      >
        <a-collapse v-model="activeKey" :bordered="false">
          <template v-slot:expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel
            class="customStyle"
            v-for="{ title, active, children } in baseMsg"
            :header="title"
            :key="active"
          >
            <a-col :span="8" v-for="{ label, value } in children" :key="label">
              <a-form-item
                :label="label"
                :prop="value"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input
                  v-if="!value"
                  :disabled="true"
                  value="维护次月目标医生 "
                ></a-input>
                <a-input
                  v-else-if="value === 'status'"
                  :disabled="true"
                  :value="getStatusText(formData[value])"
                ></a-input>
                <a-input
                  v-else
                  :disabled="true"
                  v-model="formData[value]"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-collapse-panel>

          <a-collapse-panel
            v-for="{ title, active, parantList, dataList, columns } in tableMsg"
            :header="title"
            :key="active"
            class="customStyle"
          >
            <div
              v-for="item in formData[parantList]"
              :key="item.doctorId"
              class="mb-8"
            >
              <div class="app-title">
                <span style="font-weight: 600">{{ item['doctorName'] }}</span>
                <span class="mx-4">{{ item['section'] }}</span>
                <span>{{ item['hospitalName'] }}</span>
              </div>
              <div class="p-4 border">
                <a-table
                  rowKey="productId"
                  :dataSource="item[dataList]"
                  :columns="columns"
                  :pagination="false"
                  :rowClassName="
                    (record, index) => {
                      return index % 2 === 0 ? 'trBgClass' : ''
                    }
                  "
                >
                  <span
                    slot="bgType"
                    slot-scope="type"
                    :style="{ color: getBgTypeColor(type) }"
                  >
                    {{ getBgTypeText(type) }}
                  </span>
                  <span slot="question" slot-scope="questionnaireDTOS, record">
                    <a
                      style="color: #4587f5"
                      @click="handleChooseQuestion(questionnaireDTOS, record)"
                    >
                      查看问卷
                    </a>
                  </span>
                  <span slot="agreeUnbind" slot-scope="agreeUnbind">
                    <span :style="{ color: getAgreeUnbindColor(agreeUnbind) }">
                      {{ getAgreeUnbindText(agreeUnbind) }}
                    </span>
                  </span>
                </a-table>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <process
          v-if="formData.flowInfoDTO"
          :summaryid="formData.flowInfoDTO.summaryid"
          :nextapproveman="formData.flowInfoDTO.nextapproveman"
          :curApprover="formData.flowInfoDTO.curApprover"
        ></process>
        <comment :billId="formData.id"></comment>
      </a-form>
    </div>
    <modal
      v-if="showModal"
      title="问卷详情"
      width="640px"
      :closable="true"
      :footer="null"
      @close="showModal = false"
    >
      <question
        :productLine="productLine"
        :questionList="questionList"
      ></question>
    </modal>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import Process from '@/views/custmanage/modify/components/Process'
import Modal from '@/components/Modal'
import Question from '@/components/Question'
import { BASE, TABLE } from './mbys-config'
import { getTDHistoryById } from '@/api/sale/targetdoctor'
import { getStatusText } from '@/configs/bill-msg'
export default {
  name: 'MBYSBill',
  components: { Process, Comment, Modal, Question },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      value: 'a',
      showModal: false,
      formData: {},
      productLine: '', //问卷中的产品线
      questionList: '', //问卷中的问题
      baseMsg: BASE,
      tableMsg: TABLE,
      activeKey: ['BASE', 'CGSQ', 'JBSQ'],
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getTDHistoryById(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData.status)
    })
  },
  methods: {
    getStatusText,

    /**
     * 获取关系类型文本
     * @param {String} type 产品与医生的关系类型
     */
    getBgTypeText(type) {
      if (type == 1) return '新增'
      if (type == 2) return '修改'
      if (type == 3) return '删除'
      if (type == 4) return '解绑'
    },

    /**
     * 获取关系类型颜色
     * @param {String} type 产品与医生的关系类型
     */
    getBgTypeColor(type) {
      if (type == 1) return '#67C37D'
      if (type == 2) return '#4587f5'
      if (type == 3) return '#FF3B30'
      if (type == 4) return '#000000'
    },

    /**
     * 解绑信息的文字
     * @param {Boolen} unBind
     */
    getAgreeUnbindText(unBind) {
      if (unBind === null) return '处理中'
      if (unBind) return '同意'
      if (!unBind) return '拒绝'
    },

    /**
     * 获取解绑信息的颜色
     * @param {Boolen} unBind
     */
    getAgreeUnbindColor(unBind) {
      if (unBind === null) return '#4587f5'
      if (unBind === true) return '#67C37D'
      if (unBind === false) return '#FF3B30'
    },

    /**
     * 查看问卷
     * @param {Array} question
     * @param {Object} record 每条记录的详情信息
     */
    handleChooseQuestion(question, record) {
      this.productLine = record.productLine
      this.questionList = question
      this.showModal = true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
}
/deep/ .ant-btn-primary[disabled],
/deep/.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
/deep/.ant-btn-primary {
  background-color: #4587f4;
}
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.app-title {
  padding: 16px 8px;
  background-color: #fafafa;
  border-width: 1px 1px 0 1px;
  border-radius: 4px 4px 0 0;
}
</style>
