import Vue from 'vue'
import store from '@/store'
/**权限指令**/
const has = Vue.directive('has', {
  bind: function (el, binding) {
    // 获取按钮权限
    if (!haspermission(binding.value)) {
      el.setAttribute('style', 'display:none')
    }
  }
})
// 权限检查方法
function haspermission(value) {
  let allbtnpermissions = allbtnPermissions()
  return (
    allbtnpermissions.findIndex(btnpermission => {
      return btnpermission.url === value
    }) !== -1
  )
}
//获取用户拥有的所有按钮权限
function allbtnPermissions() {
  let permissions = []
  let menus = store.getters['usermenus']
  menus.map(menu => {
    if (menu.permissions && menu.permissions !== null)
      permissions = permissions.concat(menu.permissions)
  })
  return permissions
}
export { has }
