<template>
  <div>
    <div class="text-right my-4">
      <a-button style="margin-right: 5.6%" @click="showModal = true">
        打印
      </a-button>
    </div>
    <div style="padding-left: 32px" class="my-4">
      <a-form
        size="small"
        laba-width="90px"
        laba-position="right"
        layout="vertical"
      >
        <a-collapse v-model="activeKey" :bordered="false">
          <template v-slot:expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel
            class="customStyle"
            v-for="{ title, active, children } in baseMsg"
            :header="title"
            :key="active"
          >
            <template v-for="{ label, value, type, options, show } in children">
              <a-col v-if="!show || show(formData)" :span="8" :key="label">
                <a-form-item
                  :label="label"
                  :prop="value"
                  :label-col="itemlay.labelCol"
                  :wrapper-col="itemlay.wrapperCol"
                >
                  <a-button v-if="type === 'btn'" @click="preview(value)">
                    附件预览
                  </a-button>
                  <a-select
                    v-else-if="type === 'select'"
                    v-model="formData[value]"
                    :disabled="true"
                  >
                    <a-select-option
                      v-for="{ value, text } in options"
                      :key="value"
                      :value="value"
                    >
                      {{ text }}
                    </a-select-option>
                  </a-select>
                  <a-input
                    v-else
                    :disabled="true"
                    v-model="formData[value]"
                  ></a-input>
                </a-form-item>
              </a-col>
            </template>
          </a-collapse-panel>
          <a-collapse-panel
            v-for="{ title, active, dataList, columns } in tableMsg"
            :header="title"
            :key="active"
            class="customStyle"
          >
            <a-table
              rowKey="bid"
              bordered
              :dataSource="formData[dataList]"
              :columns="columns"
              :pagination="false"
              :scroll="{ x: 1920 }"
              :components="components"
              :rowClassName="
                (record, index) => {
                  return index % 2 === 0 ? 'trBgClass' : ''
                }
              "
            ></a-table>
          </a-collapse-panel>
        </a-collapse>
        <post-script
          v-if="formData.flowInfoDTO"
          billType="PARTNER_AGREE_REFUND"
          :pid="formData.id"
          :uid="formData.creator"
        ></post-script>
        <process
          v-if="formData.flowInfoDTO"
          :summaryid="formData.flowInfoDTO.summaryid"
          :nextapproveman="formData.flowInfoDTO.nextapproveman"
          :curApprover="formData.flowInfoDTO.curApprover"
        ></process>
        <comment :billId="formData.id" @list="getCommentList"></comment>
      </a-form>
    </div>

    <print-wrapper ref="printWrapper" v-show="printVisible">
      <print-form
        :form="formData"
        :datas="formData.bodyDtos || []"
        :creator="formData.creatorName"
        :options="options"
        :curAuditor="curAuditor"
        :auditTime="auditTime"
        :handleInfoList="handleInfoList"
        title="新零售红票折让申请表"
      ></print-form>
    </print-wrapper>

    <preview-modal
      v-if="showPreview"
      uploadName="partner"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>

    <!-- 审批意见 -->
    <modal
      v-if="showModal"
      title="选择打印的信息"
      @handldClick="handldPrintlick"
      @close="showModal = false"
    >
      <a-checkbox-group
        v-model="options"
        name="checkboxgroup"
        :options="plainOptions"
      />
    </modal>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import Process from '@/views/custmanage/modify/components/Process'
import PostScript from '@/views/activity/modules/activebill/PostScript'
import PreviewModal from '@/components/Upload/PreviewModal'
import PrintWrapper from 'vue-easy-print'
import PrintForm from '@/views/sale/partner/components/PrintForm'
import Modal from '@/components/Modal'
import { BASE, TABLE } from './partner-config'
import { getPartner } from '@/api/sale/partner'
import { getResizeableTitle } from '@/utils/table'
export default {
  name: 'Partner',
  components: {
    Process,
    Comment,
    PreviewModal,
    PostScript,
    PrintWrapper,
    PrintForm,
    Modal
  },
  props: {
    type: String,
    pid: String
  },
  data() {
    this.components = getResizeableTitle(TABLE[0].columns)
    return {
      formData: {},
      baseMsg: BASE,
      tableMsg: TABLE,
      activeKey: ['BASE', 'TABLE'],

      printVisible: false,
      curAuditor: '',
      auditTime: '',
      handleInfoList: [],

      showModal: false,
      options: ['comment'],
      plainOptions: [{ label: '审批意见', value: 'comment' }],

      showPreview: false,
      previewFileListInfo: '',

      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getPartner(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData.flowInfoDTO.status)
    })
  },
  methods: {
    preview(prop) {
      this.showPreview = true
      this.previewFileListInfo = this.formData[prop]
    },

    handldPrintlick() {
      this.print()
      this.showModal = false
    },

    print() {
      this.printVisible = true
      this.$nextTick(() => this.$refs['printWrapper'].print())
    },

    getCommentList(list) {
      if (list.length) {
        const lastHanlde = list[list.length - 1]
        this.curAuditor = lastHanlde.handleman
        this.auditTime = this.$date(lastHanlde.ts).format('YYYY-MM-DD')
        this.handleInfoList = list
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
