<template>
  <div class="text-center overflow-hidden">
    <div class="mb-1 mt-4 text-base">
      {{ form.outerCompanyName }}
    </div>
    <div class="my-4 text-base">开票申请单</div>
    <a-row class="px-12">
      <a-col
        v-for="{ title, prop, span } in columns"
        :key="prop"
        :span="span"
        class="flex"
      >
        <label>{{ title }}</label>
        <span class="flex-1 justify-center">
          {{
            prop === 'vbilldate'
              ? $date(form[prop]).format('YYYY年MM月DD日')
              : form[prop]
          }}
        </span>
      </a-col>
    </a-row>

    <div class="px-12 mt-2">
      <table class="w-full">
        <tr>
          <th rowspan="2">开票单位</th>
          <th colspan="2">名称</th>
          <th colspan="2">{{ form.busiCompName }}</th>
          <th>税号</th>
          <th colspan="2">{{ form.taxNumber }}</th>
        </tr>

        <tr>
          <th colspan="2">开票地址、电话</th>
          <th colspan="2">{{ form.billingAddressAndPhoneNumber }}</th>
          <th>开户行、账号</th>
          <th colspan="2">
            {{ form.bankAcountName }}{{ form.bankAcountNumber }}
          </th>
        </tr>

        <tr>
          <th v-for="tiele in thTitles" :key="tiele">{{ tiele }}</th>
        </tr>
        <tr v-for="item in results" :key="item.bid">
          <td>{{ item.idx }}</td>
          <td>{{ item.materialName }}</td>
          <td>{{ item.materialSpec }}</td>
          <td>{{ item.unit }}</td>
          <td>{{ item.num }}</td>
          <td>{{ item.unitPrice }}</td>
          <td>{{ item.amount }}</td>
          <td v-if="item.idx === 1" :rowspan="`${results.length}`">
            {{ form.note }}
          </td>
        </tr>
        <tr>
          <td colspan="2">合计(大写)</td>
          <td colspan="3">{{ upperAmountTotal }}</td>
          <td></td>
          <td>{{ amountTotal }}</td>
          <td></td>
        </tr>
      </table>

      <div class="my-8">
        <a-row :gutter="[24, 48]" class="text-left">
          <a-col v-for="({ title }, index) in infos" :key="index" :span="12">
            <div class="flex justify-between">
              <label class="inline-block text-justify" style="width: 96px">
                {{ title }}
              </label>
              <div class="flex-1 text-center border-b border-black"></div>
            </div>
          </a-col>
        </a-row>
      </div>

      <div style="border-top: 2px dashed black"></div>

      <div class="flex mt-4" style="border: 1px solid black">
        <div
          class="flex-1 flex justify-center items-center p-8 border-r border-black"
        >
          发票寄件地址、邮编、收件人及电话 ( 必填 )
        </div>
        <div class="flex-1 flex justify-center items-center p-8">
          {{ form.billToAddr }}、{{ form.checkRecipient }}、{{
            form.consigneePhone
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalAmount } from '@/utils/util'

export default {
  name: 'GoodsPrint',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    datas: {
      type: Array,
      default: () => []
    },
    tel: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      amountTotal: 0, // 金额总计
      upperAmountTotal: '', // 金额总计大写

      results: [],

      columns: [
        { title: '客户', prop: 'busiCompName', span: 16 },
        { title: '申请日期', prop: 'vbilldate', span: 8 }
      ],

      infos: [
        { title: '开票申请人', prop: '' },
        { title: '开票批准人', prop: '' },
        { title: '业务总监批准', prop: '' },
        { title: '财务审批人', prop: '' },
        { title: '总经理批准', prop: '' }
      ],

      thTitles: ['序号', '品名', '规格', '单位', '数量', '单价', '金额', '备注']
    }
  },

  watch: {
    datas(val) {
      if (!val || !val.length) return

      this.amountTotal = 0

      for (let index = 0; index < this.datas.length; index++) {
        this.results.push({ idx: index + 1, ...this.datas[index] })
        this.amountTotal = this.$np.plus(
          this.amountTotal,
          Number(this.datas[index].amount || '0')
        )
      }

      this.upperAmountTotal = `RMB${capitalAmount(this.amountTotal)}`
    }
  }
}
</script>

<style lang="less" scoped>
table {
  border-width: 4px;
  border-style: double;
  border-color: black;
  /* 消除单元格之间的空隙 */
  border-collapse: collapse;
}

th,
td {
  font-size: 12px;
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 8px;
}

label::after {
  content: ':';
  margin: 0 4px;
}
</style>
