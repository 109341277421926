<template>
  <div class="text-xs">
    <clerk :pid="pid" @status="nowStatus($event)"></clerk>
  </div>
</template>

<script>
import Clerk from './clerk/CLERK'

export default {
  name: 'CLERKBill',
  components: {
    Clerk
  },
  props: {
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
