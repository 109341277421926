<template>
  <div class="invoice-detail">
    <div class="file-container rounded-lg common-bg px-4 pb-3 mb-4">
      <a @click="modalVisible = true" class="preview-btn inline-block my-2">
        <a-icon type="zoom-in" class="text-sm" />
        放大查看
      </a>
      <a
        v-if="isImage"
        @click="turnRight"
        class="preview-btn inline-block ml-2"
      >
        <a-icon type="redo" class="text-sm" />
        旋转图片
      </a>
      <div
        class="file overflow-hidden text-center"
        style="background-color: #525659"
      >
        <iframe
          v-if="
            form.file && ['pdf', 'ofd'].includes(form.file.type.toLowerCase())
          "
          :src="previewUrl"
          frameborder="1"
          class="bg-white h-full w-full"
        ></iframe>
        <img
          v-else
          class="max-w-full max-h-full inline-block"
          :src="previewUrl"
          :style="{ transform: `rotate(${rotate || 0}deg)` }"
        />
      </div>
      <InvoiceForm ref="bill" :form="form" :isDup="isDup"></InvoiceForm>
    </div>

    <a-modal
      v-model="modalVisible"
      title="发票附件"
      width="85%"
      style="top: 32px"
      :footer="false"
      :bodyStyle="{
        padding: '8px',
        height: 'calc(100vh - 120px)',
        overflow: 'auto'
      }"
    >
      <iframe
        v-if="
          form.file && ['pdf', 'ofd'].includes(form.file.type.toLowerCase())
        "
        :src="previewUrl"
        frameborder="1"
        class="bg-white h-full w-full"
      ></iframe>
      <!-- <img v-else class="max-w-full max-h-full" :src="previewUrl" /> -->
      <div
        v-else
        class="text-center h-full position-relative overflow-auto"
        style="background-color: #525659"
      >
        <img
          ref="image"
          class="max-w-full max-h-full inline-block"
          :style="{ transform: `rotate(${rotate || 0}deg)` }"
          :src="previewUrl"
        />
        <a-button
          type="button"
          shape="circle"
          class="el-carousel__arrow el-carousel__arrow-i-right"
          @click="imageIn"
        >
          <a-icon type="zoom-out" />
        </a-button>
        <a-button
          type="button"
          shape="circle"
          class="el-carousel__arrow el-carousel__arrow-o-right"
          @click="imageOut"
        >
          <a-icon type="zoom-in" />
        </a-button>
        <a-button
          type="button"
          shape="circle"
          class="el-carousel__arrow el-carousel__arrow-t-right"
          @click="turnRight"
        >
          <a-icon type="redo" />
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getInvoicePreview } from '@/api/acdemicActive/acdemic-payment'
import { getContentTypeHeader, getOfficePreviewUrl } from '@/configs/filetype'
import InvoiceForm from './InvoiceForm'
export default {
  name: 'InvoicePreview',
  components: { InvoiceForm },
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {}
    },
    isDup: {
      type: Boolean,
      default: false
    }
  },

  created() {
    this.createPreviewUrl()
  },

  data() {
    return {
      previewLoading: false,
      fileCatch: {}, //文件缓存
      previewUrl: '',
      modalVisible: false,
      rotate: 0
    }
  },

  computed: {
    /**
     * 附件是否为图片
     */
    isImage() {
      const { file } = this.form || {}
      if (!file || !file.type) return false
      return !['pdf', 'ofd'].includes(this.form.file.type.toLowerCase())
    }
  },

  watch: {
    // 切换发票的时候重新获取预览文件
    'form.file': {
      handler(val, pre) {
        const { pk } = val || {}
        const { pk: oldPK } = pre || {}
        if (pk !== oldPK) {
          this.rotate = 0
          this.createPreviewUrl()
        }
      }
    }
  },

  methods: {
    // 更新重复单据的详情
    refreshRelatedBill(pk) {
      if (this.isDup) this.$refs.bill.getRelatedBill(pk)
    },

    async createPreviewUrl() {
      this.previewUrl = ''
      const { pk, type } = this.form.file || {}
      const pkSrc = this.form.pkSrc
      const pkGroup = this.form.pkGroup
      const userCode = this.$store.getters.userCode
      if (!pk) return
      // 优先取已存的文件地址
      if (this.fileCatch[pk]) {
        this.previewUrl = this.fileCatch[pk]
        return
      }

      if (type !== 'ofd') {
        this.previewLoading = true
        const resp = await this.getFilePreviewBlob(pk, pkSrc, pkGroup, userCode)

        const blob = new Blob([resp.data], {
          type: getContentTypeHeader(type.toLowerCase())
        })
        const contentSRC = URL.createObjectURL(blob)
        // 把文件地址存起来，后续切换的时候无需再次调接口
        this.fileCatch[pk] = contentSRC
        // 避免快速切换时 预览的文件错乱
        if (this.form.file.pk !== pk) return
        this.previewUrl = contentSRC
        this.previewLoading = false
      } else {
        this.previewUrl = this.getOfdUrl(pk, pkSrc, pkGroup, userCode)
        // 把文件地址存起来，后续切换的时候无需再次调接口
        this.fileCatch[pk] = this.previewUrl
      }
    },
    async getFilePreviewBlob(pk, pkSrc, pkGroup, userCode, times = 0) {
      let resp = {}
      times++
      if (times > 2) return
      try {
        //附件一上传就预览会报错情况处理
        resp = await getInvoicePreview(pk, pkSrc, pkGroup, userCode)
        const { data, headers = {} } = resp || {}
        const { type } = data || {}
        let blobtext = ''
        if (type === 'application/json')
          blobtext = this.readArrayBufferToJson(data)
        // 如果是文件，返回的type有可能也是json，比如txt和html、pdf，
        // 但headers会多一个content-disposition，可用它来判断是不是文件
        const isFile = headers && headers['content-disposition']
        if ((type === 'application/json' || blobtext === 'error') && !isFile) {
          await this.sleep(1000)
          resp = await this.getFilePreviewBlob(
            pk,
            pkSrc,
            pkGroup,
            userCode,
            times
          )
        }
      } catch (error) {
        await this.sleep(1000)
        resp = await this.getFilePreviewBlob(
          pk,
          pkSrc,
          pkGroup,
          userCode,
          times
        )
      }
      return resp
    },
    sleep(ds) {
      return new Promise(resolve => setTimeout(resolve, ds))
    },

    getOfdUrl(pk, pkSrc, pkGroup, userCode) {
      const url = getOfficePreviewUrl()
      const base = '/api/invoice/preView?'
      const path = pkSrc
        ? `pk=${pk}&payId=${pkSrc}&pkGroup=${pkGroup}&userCode=${userCode}`
        : `pk=${pk}&pkGroup=${pkGroup}&userCode=${userCode}`
      return `${url}${base}${encodeURIComponent(path)}`
    },

    /**
     * 将blob读成json
     * @param {}} data
     */
    ReadBlobToText(data) {
      return new Promise((resolve, reject) => {
        let content = ''
        const reader = new FileReader()
        //等到完成
        reader.onloadend = function () {
          content = reader.result
          resolve(content)
        }
        //确保处理错误状态
        reader.onerror = function (e) {
          reject(e)
        }
        reader.readAsText(data)
      })
    },
    /**
     * 将arrayBuffer转为json对象
     */
    readArrayBufferToJson(data) {
      try {
        // 如果 arrayBuffer 大于1m，判断为文件，直接返回
        if (data.byteLength > 1 * 1024 * 1024) return ''
        // evt.data是ArrayBuffer
        // 将其转换为uint8字节流
        let uint8Msg = new Uint8Array(data)
        // 解码成字符串
        let decodedString = String.fromCharCode.apply(null, uint8Msg)
        // parse,转成json数据
        const content = JSON.parse(decodedString)
        return content
      } catch {
        return ''
      }
    },
    // 缩小图片
    imageIn() {
      let imgItem = this.$refs['image']
      if (!imgItem) return
      imgItem.height *= 0.8
      imgItem.width *= 0.8
    },
    // 放大图片
    imageOut() {
      let imgItem = this.$refs['image']
      if (!imgItem) return
      imgItem.style.cssText +=
        'max-width:300% !important;max-height:300% !important;'
      imgItem.height *= 1.2
      imgItem.width *= 1.2
    },
    // 向右旋转
    turnRight() {
      this.rotate = (this.rotate || 0) + 90
    }
  }
}
</script>

<style lang="less" scoped>
.invoice-detail {
  .common-bg {
    background: #f8f8f8;
    border: 1px solid #e8e8e8;
  }
  .file-container {
    .file {
      height: 450px;
    }
    .preview-btn,
    .preview-btn .icon {
      color: #666 !important;
      &:hover {
        color: #4587f5 !important;
      }
    }
  }
}

.el-carousel__arrow,
.el-carousel__arrow:hover {
  color: black;
  font-weight: 600;
  background-color: #f2f2f2;
  box-shadow: 0px 2px 1px 0px rgba(23, 20, 20, 0.26);
  right: 46px;
  top: auto;
  position: absolute;
  &.el-carousel__arrow-i-right {
    bottom: 30px;
  }
  &.el-carousel__arrow-o-right {
    bottom: 80px;
  }
  &.el-carousel__arrow-t-right {
    bottom: 130px;
  }
}
</style>
