<template>
  <div style="margin-left: 24px">
    <a-collapse v-model="activeKey" :bordered="false">
      <template v-slot:expandIcon="props">
        <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
      </template>
      <a-collapse-panel
        v-for="{ title, active, dataList, columns } in tableMsg"
        :header="title"
        :key="active"
        class="customStyle"
      >
        <vxe-table
          round
          show-footer
          show-overflow
          size="mini"
          max-height="480"
          :auto-resize="true"
          :data="formData[dataList]"
          :footer-method="footerMethod"
          :footer-cell-class-name="footerCellClassName2"
        >
          <vxe-table-column
            v-for="{ title, dataIndex } in columns"
            :key="dataIndex"
            :title="title"
            :field="dataIndex"
          ></vxe-table-column>
        </vxe-table>
      </a-collapse-panel>
    </a-collapse>
    <process
      v-if="formData.flowInfoDTO"
      :summaryid="formData.flowInfoDTO.summaryid"
      :nextapproveman="formData.flowInfoDTO.nextapproveman"
      :curApprover="formData.flowInfoDTO.curApprover"
    ></process>
    <comment :billId="formData.id"></comment>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import Process from '@/views/custmanage/modify/components/Process'
import { TABLE } from './lcostapp-config'
import { getLocalCostAppById } from '@/api/fee/local-fee-app'
import { getStatusText } from '@/configs/bill-msg'
export default {
  name: 'LCOSTAPP',
  components: { Process, Comment },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      formData: {},
      tableMsg: TABLE,
      activeKey: ['FeeInfo'],
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getLocalCostAppById(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData.flowInfoDTO.status)
    })
  },
  methods: {
    getStatusText,

    footerCellClassName2({ column, columnIndex }) {
      if (columnIndex === 0) {
        return 'col-blue'
      } else {
        if (
          column.property === 'ticketRemainingThisMonth' ||
          column.property === 'wagesAppThisMonth'
        ) {
          return 'col-red'
        }
      }
    },
    sumNum(list, property) {
      let total = 0
      list.forEach(item => {
        total = this.$np.plus(total, item[property])
      })
      return total
    },
    footerMethod({ columns, data }) {
      const sums = []
      columns.forEach((column, columnIndex) => {
        if (columnIndex === 0) {
          sums.push('总计')
        } else {
          let sumCell = null
          switch (column.property) {
            case 'ticketRemainingThisMonth':
              sumCell = this.sumNum(data, column.property)
              break
            case 'wagesAppThisMonth':
              sumCell = this.sumNum(data, column.property)
              break
            default:
              sumCell = '--'
          }

          sums.push(sumCell)
        }
      })
      // 返回一个二维数组的表尾合计
      return [sums]
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
}
/deep/ .ant-btn-primary[disabled],
/deep/.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
/deep/.ant-btn-primary {
  background-color: #4587f4;
}
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}
/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

/deep/.col-blue {
  color: #4587f5;
}

/deep/.col-red {
  color: red;
}
</style>
