import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 查询所有活动报销专家讲者信息
 */
export function getActivityReimbursementSpeaker() {
  const pageindex = 1
  const pagenum = 200
  return request({
    method: 'post',
    url: `speaker/${pageindex}/${pagenum}`
  })
}

/**
 * 保存学术活动报销单
 * @param {Object} dot 保存学术活动报销单的数据
 */
export function getActivityReimbursement(dot) {
  return request({
    method: 'post',
    url: 'ActivityReimbursement',
    data: dot
  })
}

/**
 * 获取所有活动报销信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {String} uid
 */
export function getAllActivityReimbursement(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `ActivityReimbursement/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新活动报销信息
 * @param {String} id
 * @param {Object} dot
 */
export function getEditActivityReimbursement(dot) {
  return request({
    method: 'put',
    url: `ActivityReimbursement/${dot.id}`,
    data: dot
  })
}

/**
 * 删除活动信息
 * @param {String} id
 */
export function getDeleteActivityReimbursement(id) {
  return request({
    method: 'delete',
    url: `ActivityReimbursement/${id}`
  })
}

/**
 * 根据id查询活动报销
 * @param {String} id
 */
export function getActivityReimbursementById(id) {
  return request({
    method: 'get',
    url: `ActivityReimbursement/${id}`
  })
}

/**
 * 提交学术报销流程
 * @param {Object} dot
 */
export function getSubmitXSBX(dot) {
  return request({
    method: 'put',
    url: `oaflow/submitXSBX`,
    data: dot
  })
}

/**
 * 检查是否有报销单
 * @param {String} activityAppId
 */
export function getCheckActivityReimbursement(activityAppId) {
  return request({
    method: 'post',
    url: `ActivityReimbursement/checkActivityReimbursement`,
    data: { activityAppId }
  })
}

/**
 * 活动总结单作废
 * @param {String} id
 * @param {String} text
 * @returns
 */
export function invalidateActivityReimbursement(id, text) {
  return request({
    method: 'post',
    url: `ActivityReimbursement/invalidate`,
    data: { id, invalidateReason: text }
  })
}

/**
 * 导出会议签到
 */
export function getInvoiceExport(id, feeId) {
  return request({
    method: 'post',
    url: `ActivityReimbursement/invoiceExport`,
    params: { id },
    data: [{ column: 'invoiceFeeType', opt: '=', value: feeId }],
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `总结单发票`)
  })
}
