<template>
  <div v-if="JSON.stringify(this.handleInfoList) !== '[]'">
    <div class="border-b py-4 text-sm font-semibold">
      <a-icon type="profile" />
      <span class="ml-2">审批意见</span>
    </div>
    <comment-list
      :uid="uid"
      :billId="billId"
      :isXSBX="isXSBX"
      :status="status"
      :handleInfoList="handleInfoList"
    ></comment-list>
  </div>
</template>

<script>
import { getHandleInfo } from '@/api/acdemicActive/acdemic-application'
import { attitudeEnum } from '@/configs/bill-msg'
import CommentList from '@/views/activity/academicApplication/Comment'
export default {
  name: 'Comment',
  components: {
    CommentList
  },
  props: {
    billId: {
      type: [String, Number],
      default: ''
    },
    uid: {
      type: String,
      default: ''
    },
    isXSBX: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      handleInfoList: []
    }
  },
  watch: {
    billId(nval) {
      if (nval) this.getHandleInfo(nval)
    }
  },
  methods: {
    getHandleInfo(billId) {
      getHandleInfo(billId).then(response => {
        this.handleInfoList = response
        this.addHandleCommentTag(this.handleInfoList)
      })
    },

    addHandleCommentTag(list) {
      list.forEach(comment => {
        this.$set(comment, 'showSubComment', false)
        if (comment.attitude == attitudeEnum.HAND_UP) {
          this.$set(comment, 'showFlag', false)
        }

        if (comment.subApproveInfos && Array.isArray(comment.subApproveInfos)) {
          this.addHandleCommentTag(comment.subApproveInfos)
        }
      })
    },

    showLastComment() {
      const len = this.handleInfoList.length
      const lastComment = this.handleInfoList[len - 1]
      lastComment.showSubComment = true
    }
  }
}
</script>

<style lang="less" scoped></style>
