<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="活动总结信息" key="1" class="customStyle">
          <div
            class="w-full flex flex-wrap justify-start"
            style="margin-left: 24px"
          >
            <div class="form-item">
              <span class="form-label">会议名称</span>
              <a-input
                :title="activityRemInfo.conferenceName"
                v-model="activityRemInfo.conferenceName"
                class="form-input"
                :disabled="true"
                @blur="submit"
              />
            </div>
            <div class="form-item">
              <span class="form-label">会议编号</span>
              <a-input
                :title="activityRemInfo.conferenceCode"
                class="form-input"
                :disabled="true"
                v-model="activityRemInfo.conferenceCode"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                所属公司
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityRemInfo.conferenceType"
                :disabled="true"
              >
                <a-select-option :key="item.id" v-for="item in meetTypeList">
                  {{ item.name !== null ? item.name : '' }}
                </a-select-option>
              </a-select>
            </div>
            <div class="form-item" v-if="getConferenceTypeName === '总部'">
              <span class="form-label">
                举办类型
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityRemInfo.hostingType"
                :disabled="true"
              >
                <a-select-option :key="item.id" v-for="item in hostingList">
                  {{ item.name !== null ? item.name : '' }}
                </a-select-option>
              </a-select>
            </div>
            <div class="form-item">
              <span class="form-label">
                业务类型
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityRemInfo.businessType"
                :disabled="true"
              >
                <a-select-option
                  :key="item.id"
                  v-for="item in businessTypeList"
                >
                  {{ item.name || '' }}
                </a-select-option>
              </a-select>
            </div>
            <div class="form-item" v-if="activityRemInfo.product">
              <span class="form-label">
                产品
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityRemInfo.product"
                :disabled="true"
              >
                <a-select-option :key="item.id" v-for="item in productList">
                  {{ item.name || '' }}
                </a-select-option>
              </a-select>
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                举办性质
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityRemInfo.activityNatrue"
                :disabled="true"
              >
                <a-select-option value="协办">协办</a-select-option>
                <a-select-option value="自办">自办</a-select-option>
                <a-select-option value="协办+自办">协办+自办</a-select-option>
              </a-select>
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                参会客户到场人数
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="activityRemInfo.reimTurnout"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                内部人员到场人数
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="activityRemInfo.internalnum"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                实际活动地点
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :title="activityRemInfo.actualActivityPlace"
                v-model="activityRemInfo.actualActivityPlace"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                实际开始日期
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-date-picker
                v-if="activityRemInfo.actualActivityStdate"
                class="form-input"
                :allowClear="false"
                :value="moment(activityRemInfo.actualActivityStdate)"
                :disabled="isRead"
                :disabledDate="disabledStDate"
                @change="activityStdateChange"
              />
              <a-date-picker
                v-else
                class="form-input"
                :allowClear="false"
                :disabled="isRead"
                :disabledDate="disabledStDate"
                @change="activityStdateChange"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                实际结束日期
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-date-picker
                v-if="activityRemInfo.actualActivityEddate"
                class="form-input"
                :allowClear="false"
                :value="moment(activityRemInfo.actualActivityEddate)"
                :disabled="isRead"
                :disabledDate="disabledEndDate"
                @change="activityEddateChange"
              />
              <a-date-picker
                v-else
                class="form-input"
                :allowClear="false"
                :disabled="isRead"
                :disabledDate="disabledEndDate"
                @change="activityEddateChange"
              />
            </div>
            <div class="mt-4 w-full flex flex-wrap justify-start">
              <div
                class="form-item form-upload"
                v-if="[1, 2, 4].includes(getHostingType)"
              >
                <span class="form-label">
                  签到表
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                  :
                </span>
                <span class="text-center ml-8">
                  <span v-if="!isRead">
                    <icon-font
                      :type="
                        activityRemInfo.signinForm
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        uploadFile('signinForm', activityRemInfo.signinForm)
                      "
                    ></icon-font>
                  </span>
                  <span v-else-if="activityRemInfo.signinForm">
                    <icon-font
                      :type="
                        activityRemInfo.signinForm
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="preview(activityRemInfo.signinForm, 'signinForm')"
                    />
                  </span>
                  <span v-else class="text-blue-400">无上传的附件</span>
                </span>
              </div>
              <div
                class="form-item form-upload"
                v-if="[1, 2, 4].includes(getHostingType)"
              >
                <span class="form-label">
                  现场照片
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                  :
                </span>
                <span class="text-center ml-8">
                  <span v-if="!isRead">
                    <icon-font
                      :type="
                        isAfterPictures || activityRemInfo.pictures
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="uploadFile('pictures', activityRemInfo.pictures)"
                    ></icon-font>
                  </span>
                  <span v-else-if="isAfterPictures || activityRemInfo.pictures">
                    <icon-font
                      type="icon_wenjianjia2"
                      class="text-2xl"
                      @click="preview(activityRemInfo.pictures, 'pictures')"
                    />
                  </span>
                  <span v-else class="text-blue-400">无上传的附件</span>
                </span>
              </div>
              <div
                class="form-item form-upload"
                v-if="[1, 2, 4].includes(getHostingType)"
              >
                <span class="form-label">讲者资费凭证 :</span>
                <span class="text-center ml-8">
                  <span v-if="!isRead">
                    <icon-font
                      :type="
                        activityRemInfo.consultationFeeProof
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        uploadFile(
                          'consultationFeeProof',
                          activityRemInfo.consultationFeeProof
                        )
                      "
                    ></icon-font>
                  </span>
                  <span v-else-if="activityRemInfo.consultationFeeProof">
                    <icon-font
                      :type="
                        activityRemInfo.consultationFeeProof
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        preview(
                          activityRemInfo.consultationFeeProof,
                          'consultationFeeProof'
                        )
                      "
                    />
                  </span>
                  <span v-else class="text-blue-400">无上传的附件</span>
                </span>
              </div>
              <div
                class="form-item form-upload"
                v-if="[1, 2, 4].includes(getHostingType)"
              >
                <span class="form-label">
                  活动总结
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                  :
                </span>
                <span class="text-center ml-8">
                  <span v-if="!isRead">
                    <icon-font
                      :type="
                        activityRemInfo.activitySummary
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        uploadFile(
                          'activitySummary',
                          activityRemInfo.activitySummary
                        )
                      "
                    ></icon-font>
                  </span>
                  <span v-else-if="activityRemInfo.activitySummary">
                    <icon-font
                      :type="
                        activityRemInfo.activitySummary
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        preview(
                          activityRemInfo.activitySummary,
                          'activitySummary'
                        )
                      "
                    />
                  </span>
                  <span v-else class="text-blue-400">无上传的附件</span>
                </span>
              </div>
              <div
                class="form-item form-upload"
                v-if="[3, 4].includes(getHostingType)"
              >
                <span class="form-label">
                  信息发布-文章网络连接
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                  :
                </span>
                <span class="text-center ml-8">
                  <span v-if="!isRead">
                    <icon-font
                      :type="
                        activityRemInfo.articleNetworkLink
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        uploadFile(
                          'articleNetworkLink',
                          activityRemInfo.articleNetworkLink
                        )
                      "
                    ></icon-font>
                  </span>
                  <span v-else-if="activityRemInfo.articleNetworkLink">
                    <icon-font
                      :type="
                        activityRemInfo.articleNetworkLink
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        preview(
                          activityRemInfo.articleNetworkLink,
                          'articleNetworkLink'
                        )
                      "
                    />
                  </span>
                  <span v-else class="text-blue-400">无上传的附件</span>
                </span>
              </div>
              <div
                class="form-item form-upload"
                v-if="[3, 4].includes(getHostingType)"
              >
                <span class="form-label">
                  信息发布-项目总结
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                  :
                </span>
                <span class="text-center ml-8">
                  <span v-if="!isRead">
                    <icon-font
                      :type="
                        activityRemInfo.projectSummary
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        uploadFile(
                          'projectSummary',
                          activityRemInfo.projectSummary
                        )
                      "
                    ></icon-font>
                  </span>
                  <span v-else-if="activityRemInfo.projectSummary">
                    <icon-font
                      :type="
                        activityRemInfo.projectSummary
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        preview(
                          activityRemInfo.projectSummary,
                          'projectSummary'
                        )
                      "
                    />
                  </span>
                  <span v-else class="text-blue-400">无上传的附件</span>
                </span>
              </div>
              <div
                class="form-item form-upload"
                v-if="
                  [1, 2, 4].includes(getHostingType) &&
                  activityRemInfo.activityNatrue &&
                  activityRemInfo.activityNatrue.indexOf('协办') !== -1
                "
              >
                <span class="form-label">
                  协办第三方资料
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                  :
                </span>
                <span class="text-center ml-8">
                  <span v-if="!isRead">
                    <icon-font
                      :type="
                        activityRemInfo.cooperationInfo
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        uploadFile(
                          'cooperationInfo',
                          activityRemInfo.cooperationInfo
                        )
                      "
                    ></icon-font>
                  </span>
                  <span v-else-if="activityRemInfo.cooperationInfo">
                    <icon-font
                      :type="
                        activityRemInfo.cooperationInfo
                          ? 'icon_wenjianjia2'
                          : 'icon_wenjianjia1'
                      "
                      class="text-2xl"
                      @click="
                        preview(
                          activityRemInfo.cooperationInfo,
                          'cooperationInfo'
                        )
                      "
                    />
                  </span>
                  <span v-else class="text-blue-400">无上传的附件</span>
                </span>
              </div>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadName"
      :uploadFilelistInfo="uploadFilelistInfo"
      @close="showUploadClose"
      @hasUpload="hasUpload($event)"
    ></upload-modal>
    <cooperation-upload-modal
      v-if="showCooUpload"
      :uploadName="uploadName"
      :uploadFilelistInfo="uploadFilelistInfo"
      @close="() => (showCooUpload = false)"
      @hasUpload="hasUpload($event)"
    ></cooperation-upload-modal>
    <preview-modal
      v-if="showPreview"
      :uploadName="uploadName"
      :previewFileListInfo="previewFileListInfo"
      @close="closePreview"
    ></preview-modal>
  </div>
</template>

<script>
import moment from 'moment'
import PreviewModal from '@/components/Upload/PreviewModal'
import UploadModal from '@/components/Upload/UploadModal'
import CooperationUploadModal from '@/components/Upload/CooperationUpload'
import { loadDictTypes } from '@/api/customer/dictmanage'
import { getMeetingPhoto } from '@/api/acdemicActive/meeting'
import { PICTURES_DATE } from '@/configs/date-config'

export default {
  name: 'ActivityRemInfo', //活动报销信息
  components: {
    PreviewModal,
    UploadModal,
    CooperationUploadModal
  },
  props: {
    activityRemInfo: {
      type: Object,
      required: true
    },
    status: Boolean,
    conferenceTypeName: [String, Number],
    hostingTypeName: String
  },
  data() {
    return {
      docList: [], //所有的枚举类型列表
      meetTypeList: [], //会议类型列表
      businessTypeList: [], //业务类型列表
      productList: [], //产品列表
      hostingList: [], //举办类型列表
      showUpload: false, //文件上传的弹框默认关闭
      showCooUpload: false, // 协办第三方的附件上传
      showPreview: false, //预览文件的弹框默认关闭
      showCooPreview: false, // 协办第三方的附件预览
      uploadName: '', //传入上传附件modal的字段名
      uploadFilelistInfo: '', //传入上传附件的文件信息
      previewFileListInfo: '', //传入预览附件的文件信息

      picturesPriview: '' // 现场照片预览的文件信息
    }
  },
  computed: {
    isRead() {
      return this.status
    },
    getConferenceTypeName() {
      return this.conferenceTypeName
    },
    getHostingType() {
      if (!this.hostingTypeName) return 1

      let active = false
      let purtive = false
      if (this.hostingTypeName.indexOf('学术推广活动') !== -1) active = true
      if (this.hostingTypeName.indexOf('品牌信息发布') !== -1) purtive = true

      if (active && !purtive) return 2 //举办类型为学术推广
      if (!active && purtive) return 3 //举办类型为品牌信息
      if (active && purtive) return 4 //举办类型为学术推广+品牌信息

      return 1 //举办类型为空
    },
    // 上线时间作为起始时间，此功能对起始时间后所有的会议申请生效，不影响生效日期前发起的历史单据
    // 现场照片
    isAfterPictures() {
      return this.$date(this.activityRemInfo.activityAppdate).isAfter(
        this.$date(PICTURES_DATE)
      )
    }
  },
  created() {
    loadDictTypes().then(response => {
      this.queryDocByName(
        response,
        '标准类型(活动类型及执行标准)',
        '业务类型',
        '产品',
        '举办类型'
      )
    })
  },

  methods: {
    moment,

    /**
     * @param {String} str //传入的字段名称
     * @param {String} fileInfo //传入的文件信息
     */
    uploadFile(str, filesInfo) {
      if (str === 'pictures' && this.isAfterPictures) {
        if (!this.picturesPriview) {
          getMeetingPhoto(this.activityRemInfo.activityAppId).then(res => {
            this.picturesPriview = JSON.stringify([
              ...(res.activPanoramaList || []),
              ...(res.otherList || []),
              ...(res.pptList || [])
            ])
            this.preview(this.picturesPriview, str)
          })
          return
        } else {
          this.preview(this.picturesPriview, str)
          return
        }
      }

      if (str === 'cooperationInfo') {
        this.showCooUpload = true
        this.uploadName = str
        this.uploadFilelistInfo = filesInfo
        return
      }

      this.showUpload = true
      this.uploadName = str
      this.uploadFilelistInfo = filesInfo
    },

    // 传递预览文件信息
    preview(previewFileListInfo, str) {
      if (str === 'pictures' && this.isAfterPictures) {
        if (!this.picturesPriview) {
          getMeetingPhoto(this.activityRemInfo.activityAppId).then(res => {
            this.picturesPriview = JSON.stringify([
              ...(res.activPanoramaList || []),
              ...(res.otherList || []),
              ...(res.pptList || [])
            ])
            this.previewFileListInfo = this.picturesPriview
            this.uploadName = str
            this.showPreview = true
          })
          return
        } else {
          this.previewFileListInfo = this.picturesPriview
          this.uploadName = str
          this.showPreview = true
          return
        }
      }

      this.previewFileListInfo = previewFileListInfo
      this.uploadName = str
      this.showPreview = true
    },

    //文件信息添加到对应的字段
    hasUpload(file) {
      if (file.uploadName === 'signinForm') {
        this.activityRemInfo.signinForm = file.fileListInfo
      }
      if (file.uploadName === 'pictures')
        this.activityRemInfo.pictures = file.fileListInfo
      if (file.uploadName === 'consultationFeeProof')
        this.activityRemInfo.consultationFeeProof = file.fileListInfo
      if (file.uploadName === 'activitySummary')
        this.activityRemInfo.activitySummary = file.fileListInfo
      if (file.uploadName === 'articleNetworkLink')
        this.activityRemInfo.articleNetworkLink = file.fileListInfo
      if (file.uploadName === 'projectSummary')
        this.activityRemInfo.projectSummary = file.fileListInfo
      if (file.uploadName === 'cooperationInfo')
        this.activityRemInfo.cooperationInfo = file.fileListInfo
      this.updateItem()
    },

    /**
     * 查询会议类型、业务类型枚举
     * @param {Array} docList //枚举列表
     * @param {String} meetType //枚举名称（会议类型）
     * @param {String} businessType //枚举名称（业务类型）
     * @param {String} product //枚举名称(产品)
     * @param {String} hostingType //枚举名称(举办类型)
     */
    queryDocByName(docList, meetType, businessType, product, hostingType) {
      docList.forEach(item => {
        if (item.name === meetType) this.meetTypeList = item.defDocDTOs
        if (item.name === businessType) this.businessTypeList = item.defDocDTOs
        if (item.name === product) this.productList = item.defDocDTOs
        if (item.name === hostingType) this.hostingList = item.defDocDTOs
      })
    },

    // 开始日期函数回调
    activityStdateChange(date, dateString) {
      this.activityRemInfo.actualActivityStdate = dateString
      this.updateItem()
    },

    // 结束日期函数回调
    activityEddateChange(date, dateString) {
      this.activityRemInfo.actualActivityEddate = dateString
      this.updateItem()
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledStDate(time) {
      return (
        time &&
        time >= moment(this.activityRemInfo.actualActivityEddate).endOf('day')
      )
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledEndDate(time) {
      return (
        time &&
        time <=
          moment(this.activityRemInfo.actualActivityStdate)
            .add(-1, 'day')
            .endOf('day')
      )
    },

    //关闭文件上传
    showUploadClose() {
      this.showUpload = false
    },

    // 关闭预览附件的窗口
    closePreview() {
      this.showPreview = false
    },

    // 检查是否有更改信息
    updateItem() {
      this.$emit('updateItem')
      this.submit()
    },

    submit() {
      this.$emit('update:activityRemInfo', this.activityRemInfo)
    }
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 25%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
}

.form-upload {
  display: flex;
  flex-direction: row;
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
