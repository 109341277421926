import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * PC端查询拜访列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {String} version
 * @param {Array} conditions
 */
export function getVisitList(pageindex, pagenum, version, conditions) {
  return request({
    method: 'post',
    url: `visit/pc/${version}/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 获取拜访的版本接口
 */
export function getVisitVersion() {
  return request({
    method: 'get',
    url: `visit/versions`
  }).then(res => {
    return res
  })
}

/**
 * 删除拜访信息
 * @param {String} id 分配的拜访id
 */
export function getDelVisit(id) {
  return request({
    method: 'delete',
    url: `visit/${id}`
  })
}

/**
 * 新建拜访信息
 * @param {Object} data
 */
export function getSaveVisit(data) {
  return request({
    method: 'post',
    url: `visit`,
    data: data
  })
}

/**
 * 更新拜访信息
 * @param {Object} data
 */
export function getUpdateVisit(data) {
  return request({
    method: 'post',
    url: `visit`,
    data: data
  })
}

/**
 * 查询拜访详情
 * @param {String} id 拜访的id
 */
export function getVisitById(id) {
  return request({
    method: 'get',
    url: `visit/${id}`
  })
}

/**
 * 查询补签单详情
 * @param {String} id
 */
export function getVisitRetroactById(id) {
  return request({
    method: 'get',
    url: `visit/visitapp/detail/${id}`
  })
}

/**
 * 导出拜访数据
 * @param {String} version
 */
export function getExportExcel(version, condition = []) {
  return request({
    method: 'post',
    url: `visit/exportExcel/${version}`,
    data: condition,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `${version}版拜访记录信息`)
  })
}

/**
 * 查询岗位医生
 */
export function seachPostDoctors(pageindex, pagenum, postId, search) {
  return request({
    method: 'post',
    url: `visit/doctorList/${postId}`,
    data: { pageindex, pagenum, search }
  })
}

/**
 * 查询产品关键信息数据
 */
export function seachKeyInfoList(doctorId, postId, data) {
  return request({
    method: 'post',
    url: `visit/productKeyInfoList/${doctorId}/${postId}`,
    data: data
  })
}

/**
 * 提交拜访补签单 (走流程)
 * @param {Object} condition
 */
export function getSubmitVisit(condition) {
  return request({
    method: 'put',
    url: `oaflow/submitVisit`,
    data: condition
  })
}
