<template>
  <div>
    <a-form
      size="small"
      class="pl-6"
      laba-width="90px"
      laba-position="right"
      layout="vertical"
    >
      <a-collapse v-model="activeKey" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          v-for="{ title, active, dataList, columns } in tableMsg"
          :header="title"
          :key="active"
          class="customStyle"
        >
          <div
            v-for="item in formData[dataList]"
            :key="item.productGroupId"
            class="product-list"
          >
            <div class="product-item">
              {{ item.productGroupName }}
            </div>
            <a-table
              rowKey="id"
              :dataSource="item.hospitalInfoDTOs"
              :columns="columns"
              :pagination="false"
              class="product"
            >
              <span slot="status" slot-scope="text">
                {{
                  text == 0
                    ? '待审核'
                    : text == 1
                    ? '已审核'
                    : text == 2
                    ? '非目标'
                    : '未知'
                }}
              </span>
            </a-table>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <process
        v-if="formData.flowInfoDTO"
        :summaryid="formData.flowInfoDTO.summaryid"
        :nextapproveman="formData.flowInfoDTO.nextapproveman"
        :curApprover="formData.flowInfoDTO.curApprover"
      ></process>
      <comment :billId="formData.id"></comment>
    </a-form>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import Process from '@/views/custmanage/modify/components/Process'
import { TABLE } from './tg-hosp'
import { getTgHospById } from '@/api/sale/target-hosp'

export default {
  name: 'DoctorModifyBill',
  props: {
    type: String,
    pid: String
  },
  components: { Process, Comment },
  data() {
    return {
      value: 'a',
      formData: {},
      tableMsg: TABLE,
      activeKey: ['TGHOSP'],
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getTgHospById(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData.appStatus)
    })
  }
}
</script>

<style lang="less" scoped>
.del-text {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
}
/deep/ .ant-btn-primary[disabled],
/deep/.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
/deep/.ant-btn-primary {
  background-color: #4587f4;
}
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}
/deep/.product .ant-table-thead > tr > th {
  background-color: #ffffff;
}
/deep/.product .ant-table-tbody > tr > td {
  padding: 16px !important;
}

.product-list {
  margin-bottom: 24px;
  border: 1px solid #e8e8e8;
  .product-item {
    width: 100%;
    padding: 16px;
    font-weight: 600;
    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;
  }
}
</style>
