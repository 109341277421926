<template>
  <div v-if="showAct">
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="接送情况" key="1" class="customStyle">
          <div class="ml-2 mt-3">
            <a-table
              :columns="columns"
              :data-source="data"
              :pagination="false"
              bordered
              size="small"
            >
              <span slot="transPrice">
                <span v-if="isActDisabled">
                  {{ receptCase.transPrice }}
                </span>
                <a-input
                  v-else
                  v-model="receptCase.transPrice"
                  @change="calcFee"
                />
              </span>
              <span slot="transNum">
                <span v-if="isActDisabled">
                  {{ receptCase.transNum }}
                </span>
                <a-input
                  v-else
                  v-model="receptCase.transNum"
                  @change="calcFee"
                />
              </span>
              <span slot="settlementExpenses">
                {{ receptCase.settlementExpenses || 0 }}
              </span>
              <span slot="otherCost">
                <span v-if="isActDisabled">
                  {{ receptCase.otherCost }}
                </span>
                <a-input
                  v-else
                  v-model="receptCase.otherCost"
                  @change="calcFee"
                />
              </span>
              <span slot="transTotal">
                {{ receptCase.transTotal || 0 }}
              </span>
            </a-table>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import { default as debounce } from 'lodash/debounce'

const data = []

data.push({
  key: 1,
  transPrice: 0,
  transNum: 0,
  settlementExpenses: 0,
  otherCost: 0,
  transTotal: 0
})

export default {
  name: 'ReceptCase',
  props: {
    receptCase: {
      type: Object,
      required: true
    },
    showAct: {
      type: Boolean
    },
    status: {
      type: Boolean
    },
    isManageWrite: {
      type: Boolean
    }
  },

  data() {
    return {
      columns: [
        {
          title: () => (
            <span>
              <span class="mr-1">接送单价(元/次)</span>
              <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
            </span>
          ),
          dataIndex: 'transPrice',
          width: 100,
          scopedSlots: { customRender: 'transPrice' }
        },
        {
          title: () => (
            <span>
              <span class="mr-1">接送次数</span>
              <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
            </span>
          ),
          dataIndex: 'transNum',
          width: 100,
          scopedSlots: { customRender: 'transNum' }
        },
        {
          title: '接送费用',
          dataIndex: 'settlementExpenses',
          width: 100,
          scopedSlots: { customRender: 'settlementExpenses' }
        },
        {
          title: () => (
            <span>
              <span class="mr-1">其他</span>
              <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
            </span>
          ),
          dataIndex: 'otherCost',
          width: 100,
          scopedSlots: { customRender: 'otherCost' }
        },
        {
          title: '费用合计',
          dataIndex: 'transTotal',
          width: 100,
          scopedSlots: { customRender: 'transTotal' }
        }
      ],
      data
    }
  },

  computed: {
    isActDisabled() {
      return !this.isManageWrite && this.status
    }
  },

  methods: {
    calcFee: debounce(function () {
      const transPrice = this.receptCase.transPrice || 0
      const transNum = this.receptCase.transNum || 0
      const otherCost = this.receptCase.otherCost || 0

      this.receptCase.settlementExpenses = this.$np.times(transPrice, transNum)
      this.receptCase.transTotal = this.$np.plus(
        this.receptCase.settlementExpenses || 0,
        otherCost
      )

      this.$emit('calcActualCost')
    }, 800)
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
