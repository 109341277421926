import { getDoc } from '@/configs/doc-config'

export default {
  data() {
    return {
      formUserList: [
        /* eslint-disable prettier/prettier */
        { label: '单据号', prop: 'billCode', disabled: true },
        { label: '单据状态', prop: 'billStatus', disabled: true },
        { label: '申请人', prop: 'applyManName', disabled: true },
        { label: '岗位', prop: 'post', disabled: true },
        { label: '申请日期', prop: 'billDate', disabled: true },
        { label: '联系方式', prop: 'phone', disabled: () => this.getCommonDisabled },
        { label: '申请金额', prop: 'applyMoney', disabled: () => true, validate: 'digit' },
        { label: '结算方式', prop: 'settleType', disabled: () => this.getCommonDisabled , type: 'select', options: []},
        { label: '用途', prop: 'note', disabled: () => this.getCommonDisabled},
      ],
    }
  },

  created() {
    // 结算方式
    getDoc('100', res => {
      this.formUserList[7].options = res
      this.form.settleType = res[0].value
    })

    if (this.curType === 'add') {
      this.form.applyMan = this.$store.getters.uid
      this.form.applyManName = this.$store.getters.username
      this.form.phone = this.$store.getters.contactinfo
      this.form.post = this.$store.getters.vnote
      this.form.billDate = this.$date().format('YYYY-MM-DD')
    }
  },
}
