export default {
  namespaced: true,
  state: {
    docList: []
  },
  mutations: {
    SET_DOC_LIST(state, value) {
      state.anuceList = value
    },

    CLEAR_DOC_LIST(state) {
      state.docList = []
    }
  },

  actions: {}
}
