import MenuManage from '@/views/menumanage'
import ShareManage from '@/views/menumanage/sharemanage'
export default {
  path: 'menuManage',
  name: 'menuManage',
  component: MenuManage,
  redirect: 'menuManage/permissionManage',
  meta: { title: '系统管理' },
  children: [
    {
      // 菜单列表管理
      path: 'menusManage',
      name: 'menusManage',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/menumanage/menusmanage/MenuList'
        ),
      meta: { title: '菜单列表管理', menuKey: 'menusManage' }
    },
    {
      // 菜单详情
      path: 'menuform/:menuid',
      name: 'menuform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/menumanage/menusmanage/MenuForm'
        ),
      meta: { title: '菜单详情', menuKey: 'menusManage' }
    },
    {
      // 权限列表管理
      path: 'permissionManage',
      name: 'permissionManage',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/menumanage/promisemanage/PromiseList'
        ),
      meta: { title: '权限列表管理', menuKey: 'permissionManage' }
    },
    {
      // 权限详情
      path: 'promiseform',
      name: 'promiseform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/menumanage/promisemanage/PromiseForm'
        ),
      meta: { title: '权限详情', menuKey: 'permissionManage' }
    },
    {
      // 枚举类型管理
      path: 'dictTypeManage',
      name: 'defDocListManage',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/menumanage/dictmanage/DicttypeList'
        ),
      meta: { title: '枚举类型管理', menuKey: 'defDocListManage' }
    },
    {
      // 枚举类型详情
      path: 'dicttypeform/:dicttypeid',
      name: 'dicttypeform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/menumanage/dictmanage/DicttypeForm'
        ),
      meta: { title: '枚举类型详情', menuKey: 'defDocListManage' }
    },
    {
      // 枚举管理
      path: 'dictManage',
      name: 'defDocManage',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/menumanage/dictmanage/DictList'
        ),
      meta: { title: '枚举管理', menuKey: 'defDocManage' }
    },
    {
      // 枚举详情
      path: 'dictform',
      name: 'dictform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/menumanage/dictmanage/DictForm'
        ),
      meta: { title: '枚举详情', menuKey: 'defDocManage' }
    },
    {
      // 数据共享
      path: 'shareManage',
      name: 'shareManage',
      component: ShareManage,
      meta: { title: '数据共享' },
      redirect: 'shareManage/shareIndex',
      children: [
        {
          path: 'shareIndex',
          name: 'shareIndex',
          component: () => import('@/views/menumanage/sharemanage/ShareList'),
          meta: { title: '共享列表', menuKey: 'shareManage' }
        },
        {
          // 数据共享详情
          path: 'shareDetail/:id',
          name: 'shareDetail',
          component: () => import('@/views/menumanage/sharemanage/ShareForm'),
          meta: { title: '共享详情', menuKey: 'shareManage' }
        }
      ]
    }
    // {
    //   // 产品医生覆盖率
    //   path: 'coveragelist',
    //   name: 'reportFormSettings',
    //   component: () =>
    //     import(/* webpackChunkName: "" */ '@/views/menumanage/coverageset/CoverageList'),
    //   meta: { title: '目标医生覆盖率', menuKey: 'reportFormSettings' }
    // },
    // {
    //   // 覆盖率详情
    //   path: 'coverageform/:id',
    //   name: 'coverageform',
    //   component: () =>
    //     import(/* webpackChunkName: "" */ '@/views/menumanage/coverageset/CoverageForm'),
    //   meta: { title: '目标医生覆盖率详情', menuKey: 'reportFormSettings' }
    // }
  ]
}
