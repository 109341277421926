export default {
  methods: {
    // 按条件查询
    handleSearch(selectedKeys, confirm, column) {
      if (selectedKeys[0]) {
        const target = this.conditions.find(i => i.column === column.dataIndex)

        if (!target) {
          this.conditions = [
            ...this.conditions,
            {
              column: column.dataIndex,
              opt: 'like',
              value: selectedKeys[0]
            }
          ]
        } else {
          this.current = 1
          target.value = selectedKeys[0]
        }
      } else {
        this.conditions = this.conditions.filter(
          item => item.column !== column.dataIndex
        )
      }

      confirm()
    },

    // 重置
    handleReset(clearFilters, column) {
      this.conditions = this.conditions.filter(
        item => item.column !== column.dataIndex
      )
      clearFilters()
    },

    onDateChange(args, setSelectedKeys) {
      setSelectedKeys(args[1])
    },

    handleDateSearch(selectedKeys, confirm, column) {
      if (!selectedKeys || !selectedKeys.length) {
        return
      }

      if (selectedKeys[0]) {
        this.conditions = [
          ...this.conditions,
          {
            column: column.dataIndex,
            opt: 'between',
            value: selectedKeys
          }
        ]
      } else {
        this.conditions = this.conditions.filter(
          item => item.column !== column.dataIndex
        )
      }

      confirm()
    },

    // 重置
    handleDateReset(clearFilters, column) {
      this.$refs.picker.$refs.picker.setValue([null, null])

      this.conditions = this.conditions.filter(
        item => item.column !== column.dataIndex
      )
      clearFilters()
    },

    // 当前页改变请求
    pageChange(current) {
      this.current = current
      this.getList()
    },

    // 当前页的size改变请求
    showSizeChange(current, size) {
      this.current = current
      this.pageSize = size
      this.getList()
    }
  }
}
