import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 分页查询仿制药数字合同
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getDigitList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `genericDrugsContract/page/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 分页查询仿制药数字合同表体
 * @param {String} hid
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getDigitBodyList(hid, pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `genericDrugsContract/body/${pageindex}/${pagenum}`,
    data: conditions,
    params: { hid }
  })
}

/**
 * 删除仿制药数字合同
 * @param {String} id
 * @returns
 */
export function delDigit(id) {
  return request({
    method: 'post',
    url: `genericDrugsContract/del/${id}`
  })
}

/**
 * 根据ID获取仿制药数字合同
 * @param {String} id
 * @returns
 */
export function getDigit(id) {
  return request({
    method: 'get',
    url: `genericDrugsContract/${id}`
  })
}

/**
 * 保存仿制药数字合同
 * @param {Object} data
 * @returns
 */
export function addDigit(data) {
  return request({
    method: 'post',
    url: `genericDrugsContract/add`,
    data
  })
}

/**
 * 根据ID取消审批数字合同
 * @param {String} id
 * @returns
 */
export function unapproveDigit(id) {
  return request({
    method: 'post',
    url: `genericDrugsContract/unapprove/${id}`
  })
}

/**
 * 更新仿制药数字合同
 * @param {Object} data
 * @returns
 */
export function updateDigit(data) {
  return request({
    method: 'post',
    url: `genericDrugsContract/update`,
    data
  })
}

/**
 * 设置失效月份
 * @param {Array} data
 * @returns
 */
export function setExpireMonth(data) {
  return request({
    method: 'post',
    url: `genericDrugsContract/expire`,
    data
  })
}

/**
 * 提交仿制药数字合同
 * @param {Object} data
 * @returns
 */
export function submitDigit(data) {
  return request({
    method: 'post',
    url: `oaflow/submitGenericDrugsContract`,
    data
  })
}

/**a
 * 导出仿制药数字合同
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/genericDrugsContract/export`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `仿制药数字合同`)
  })
}
