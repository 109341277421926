<template>
  <a-config-provider :locale="zhCN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
import { deviceEnquire } from '@/utils/enquire'
moment.locale('zh-cn')
export default {
  data() {
    return {
      zhCN
    }
  },
  mounted() {
    const { $store } = this
    deviceEnquire(deviceType => {
      $store.dispatch('screen/setSizeLen', deviceType)
    })
  }
}
</script>
