<template>
  <div class="h-full card">
    <div class="leadingloose">
      <div class="text-base">数据共享</div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'shareManage'
}
</script>
