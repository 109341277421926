<template>
  <div>
    <a-modal
      v-model="visible"
      title="付款单位信息"
      :mask="false"
      class="pay-detial-modal clearfix"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      @cancel="handleClose"
    >
      <div class="pay-detial-content w-full">
        <div class="flex justify-start">
          <div class="m-1 text-right" style="width: 7rem">付款单位编码：</div>
          <div class="m-1">{{ detialMsg.payCode }}</div>
        </div>
        <div class="flex justify-start">
          <div class="m-1 text-right" style="width: 7rem">付款单位名称：</div>
          <div class="m-1">{{ detialMsg.payName }}</div>
        </div>
        <div class="flex justify-start">
          <div class="m-1 text-right" style="width: 7rem">付款银行名称：</div>
          <div class="m-1">{{ detialMsg.payBankName }}</div>
        </div>
        <div class="flex justify-start">
          <div class="m-1 text-right" style="width: 7rem">付款银行行号：</div>
          <div class="m-1">{{ detialMsg.payCommbineNum }}</div>
        </div>
        <div class="flex justify-start">
          <div class="m-1 text-right" style="width: 7rem">付款银行账号：</div>
          <div class="m-1">{{ detialMsg.payAccNum }}</div>
        </div>
        <div class="flex justify-start">
          <div class="m-1 text-right" style="width: 7rem">付款单位省区：</div>
          <div class="m-1">{{ detialMsg.province }}</div>
        </div>
        <div class="flex justify-start">
          <div class="m-1 text-right" style="width: 7rem">付款单位城市：</div>
          <div class="m-1">{{ detialMsg.city }}</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'PayNameDetialPreview',
  props: {
    detialMsg: Object
  },
  data() {
    return {
      visible: true
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less">
.pay-detial-modal {
  top: 2vh !important ;
}
.pay-detial-modal .ant-modal-header {
  padding: 8px 8px;
  background-color: #4587f4;
}
.pay-detial-modal .ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.pay-detial-modal .ant-modal-close-x {
  width: 39px;
  height: 39px;
  line-height: 39px;
  color: #ffffff;
}
.pay-detial-modal .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.pay-detial-modal .ant-modal-body {
  padding: 8px;
}
.pay-detial-content {
  width: 100%;
  height: 100%;
}
</style>
