var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-row',{staticClass:"container p-2 bg-white"},[_c('a-col',{staticClass:"pr-2",attrs:{"span":12}},[_c('p',{staticClass:"text-blue-600 text-sm tracking-wide mb-2 font-medium"},[_vm._v(" 本单据发票信息 ")]),_c('ul',{staticClass:"menu pl-4 py-2 mb-4 el-menu"},_vm._l((_vm.dataSource),function(ref){
var pk = ref.pk;
var kind = ref.kind;
var nnumber = ref.nnumber;
return _c('li',{key:pk,class:['el-menu-item', { 'is-active': _vm.currPK === pk }],on:{"click":function($event){return _vm.showDetail(pk, false)}}},[_vm._v(" "+_vm._s(kind)+"_"+_vm._s(nnumber)+" ")])}),0),_c('invoice-preview',{attrs:{"form":_vm.currDetail}})],1),_c('a-col',{staticClass:"pl-2",attrs:{"span":12}},[_c('p',{staticClass:"text-blue-600 text-sm tracking-wide mb-2 font-medium"},[_vm._v(" 重复票信息 ")]),_c('ul',{staticClass:"menu pl-4 py-2 mb-4 el-menu"},_vm._l((_vm.dupList),function(ref){
var pk = ref.pk;
var kind = ref.kind;
var nnumber = ref.nnumber;
return _c('li',{key:pk,class:['el-menu-item', { 'is-active': _vm.currDupPK === pk }],on:{"click":function($event){return _vm.showDetail(pk, true)}}},[_vm._v(" "+_vm._s(kind)+"_"+_vm._s(nnumber)+" ")])}),0),_c('invoice-preview',{ref:"info",attrs:{"form":_vm.currDupDetail,"isDup":true}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }