<template>
  <a-modal
    title="选择部门"
    width="800px"
    style="top: 48px"
    :visible="visible"
    :bodyStyle="{ padding: '16px', height: '560px', overflow: 'auto' }"
    @cancel="$emit('close')"
  >
    <div class="loading-area-data" v-if="loading">
      <a-spin class="loading-spin" tip="正在加载数据..." />
    </div>
    <div style="margin: 8px 0; padding: 0 8px">
      <a-input-search
        :value="searchKey"
        placeholder="请输入部门名称"
        @search="onSearchChange"
        @pressEnter="onSearchChange"
      />
    </div>
    <a-tree
      v-if="showTreeData.length > 0"
      show-icon
      :tree-data="showTreeData"
      :expanded-keys="expandedKeys"
      :selectedKeys.sync="defaultSelectedKeys"
      :auto-expand-parent="autoExpandParent"
      :replaceFields="{ children: 'subDepts', title: 'deptname', key: 'did' }"
      @select="selectNode"
      @expand="onExpand"
    >
      <a-icon slot="Icon" type="apartment" style="color: #4587f5" />
      <template slot="title" slot-scope="{ did, deptname }">
        <span v-if="deptname.indexOf(searchKey) > -1">
          {{ deptname.substr(0, deptname.indexOf(searchKey)) }}
          <span :style="{ color: chooseNodeDataRef.did === did ? '' : '#f50' }">
            {{ searchKey }}
          </span>
          {{ deptname.substr(deptname.indexOf(searchKey) + searchKey.length) }}
        </span>
        <span v-else>{{ deptname }}</span>
      </template>
    </a-tree>

    <div slot="footer" class="dialog-footer">
      <a-button @click="$emit('close')">取 消</a-button>
      <a-button type="primary" @click="chooseArea">确 定</a-button>
    </div>
  </a-modal>
</template>

<script>
import { deepClone } from '@/utils'
// import { getAreaManageTree } from '@/api/flowdirect/areaManage'
import { getDeptList } from '@/api/sysadmin/depts/depts'
export default {
  name: 'DeptmentModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchKey: '',
      loading: false,
      treeData: [],
      showTreeData: [],
      defaultSelectedKeys: [],

      currentNode: {},
      chooseNodeDataRef: {},

      expandedKeys: [],
      autoExpandParent: true,
      dataList: [],
      generateList: [] //获取所有节点信息
    }
  },

  async created() {
    this.loading = true
    this.treeData = await getDeptList().finally(() => {
      this.loading = false
    })
    this.treeData.pop() // 移除旧部门

    this.treeData = [
      {
        did: '~',
        deptname: '部门组织',
        subDepts: this.treeData
      }
    ]
    this.addIcon(this.treeData)
    this.getGenerateList(this.treeData)
    this.showTreeData = deepClone(this.treeData)
  },

  watch: {
    treeData: {
      handler(val) {
        if (!val) return
        this.showTreeData = deepClone(this.treeData)
        this.expandParents(this.expandedKeys, this.showTreeData, 1)
      },
      immediate: true,
      deep: true
    },

    searchKey: {
      handler(nvalue, ovalue) {
        if (nvalue === ovalue) return
        const result = this.getFilterTreeData()
        const { newTreeData, expandedKeys } = result
        this.showTreeData = newTreeData
        this.$set(this, 'expandedKeys', expandedKeys)
      }
    }
  },

  methods: {
    onSearchChange(e) {
      this.searchKey = e.target.value
    },

    /**
     * 递归插入图标和title搜索信息
     * @param {Array} tree
     */
    addIcon(tree) {
      if (!Array.isArray(tree)) {
        this.addIconSlots(tree)
        return
      }

      for (let node of tree) {
        this.addIconSlots(node)
      }
    },

    addIconSlots(node) {
      this.$set(node, 'slots', { icon: 'Icon' })
      this.$set(node, 'scopedSlots', { title: 'title' })
      if (node.subDepts && node.subDepts.length) {
        this.addIcon(node.subDepts)
      }
    },

    /**
     * 展开时回调
     */
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },

    /**
     * 获取所有节点信息
     */
    getGenerateList(tree) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        const id = node.did
        const name = node.deptname
        this.dataList.push({ id, name })
        if (node.subDepts) {
          this.getGenerateList(node.subDepts)
        }
      }
    },

    /**
     * 查找父节点id
     * @param {String} id
     * @param {Array} tree
     */
    getParentId(id, tree) {
      let parentId
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.subDepts) {
          if (node.subDepts.some(item => item.did === id)) {
            parentId = node.did
          } else if (this.getParentId(id, node.subDepts)) {
            parentId = this.getParentId(id, node.subDepts)
          }
        }
      }
      return parentId
    },

    /**
     * 选择的行政区域id
     */
    selectNode(keys, { node }) {
      this.$emit('chooseAid', keys[0])
      if (node.dataRef.did === this.chooseNodeDataRef.did) {
        this.chooseNodeDataRef = {}
        this.currentNode = {}
        return
      }

      this.currentNode = node
      this.chooseNodeDataRef = node.dataRef
    },

    expandParents(expeandkeys, posts, grades = 2, curgrade = 0) {
      curgrade++
      for (let post of posts) {
        if (post.subDepts && post.subDepts !== null && curgrade <= grades) {
          expeandkeys.push(post.did)
          this.expandParents(expeandkeys, post.subDepts, grades, curgrade)
        }
      }
    },

    getFilterTreeData() {
      const treeData = deepClone(this.treeData)
      if (!this.searchKey) {
        this.expandedKeys = []
        this.expandParents(this.expandedKeys, treeData, 1) //展开默认层级
        return { newTreeData: treeData, expandedKeys: this.expandedKeys }
      }
      const result = this.rebuildData(this.searchKey, treeData)
      return result
    },

    rebuildData(searchKey, treeData) {
      const data = deepClone(treeData)
      const expandedKeys = new Set()

      function deepRebuild(data) {
        data.forEach(item => {
          if (item.subDepts) {
            deepRebuild(item.subDepts)
            item.subDepts = item.subDepts.filter(
              subItem =>
                subItem.deptname.indexOf(searchKey) > -1 ||
                (subItem.subDepts && subItem.subDepts.length)
            )

            if (item.subDepts && item.subDepts.length) {
              expandedKeys.add(item.did)
            }
          }
        })
      }

      deepRebuild(data)

      return {
        newTreeData: data,
        expandedKeys: Array.from(expandedKeys)
      }
    },

    chooseArea() {
      this.$emit('choose-dept', this.chooseNodeDataRef)
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.loading-area-data {
  width: 100%;
  height: 99%;
  background-color: hsla(0, 0%, 100%, 0.9);
  position: absolute;
  z-index: 100;

  .loading-spin {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/deep/.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #4587f5;
  font-weight: 600;
}
</style>
