import request from '@/utils/request'

/**
 * 分页查询清算盘存信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getHeaderClerkMonthInvList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `chs/clerkMonthInv/head/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 根据表头id获取清算盘存表体列表(分页)
 * @param {String} id
 * @param {Integer} pageindex
 * @param {Interger} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getBodyClerkMonthInvList(id, pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `chs/clerkMonthInv/body/${pageindex}/${pagenum}`,
    params: { hid: id },
    data: conditions
  })
}

/**
 * 删除清算盘存
 * @param {String} id
 * @returns
 */
export function delClerkMonthInv(id) {
  return request({
    method: 'post',
    url: `chs/clerkMonthInv/del/${id}`
  })
}

/**
 * 根据ID来查询清算盘存信息
 * @param {String} id
 * @returns
 */
export function getClerkMonthInv(id) {
  return request({
    method: 'get',
    url: `chs/clerkMonthInv/head/${id}`
  })
}

/**
 * 保存清算盘存
 * @param {Object} data
 * @returns
 */
export function saveClerkMonthInv(data) {
  return request({
    method: 'post',
    url: `chs/clerkMonthInv/save`,
    data
  })
}

/**
 * 更新清算盘存
 * @param {Object} data
 * @returns
 */
export function updateClerkMonthInv(data) {
  return request({
    method: 'post',
    url: `chs/clerkMonthInv/update`,
    data
  })
}

/**
 * 提交清算盘存
 * @param {Object} data
 * @returns
 */
export function submitClerkMonthInv(data) {
  return request({
    method: 'put',
    url: `oaflow/submitNcClerkMonthInv`,
    data
  })
}

/**
 * 取消审批
 * @param {String} id
 * @returns
 */
export function repealClerkMonthInv(id) {
  return request({
    method: 'post',
    url: `chs/clerkMonthInv/unapprove/${id}`
  })
}
