/**
 * 添加 dayjs.isIntersection 方法
 */
export default (option, dayjsClass, dayjs) => {
  dayjs.isIntersection = isIntersection
}

/**
 * 判断时段 1 和时段 2 的日期之间是否有交集
 * @param {*} begin1 时段 1 的开始日期
 * @param {*} end1 时段 1 的结束日期
 * @param {*} begin2 时段 2 的开始日期
 * @param {*} end2 时段 2 的结束日期
 * @returns {boolean} true: 有交集，false: 没有交集
 */
function isIntersection(begin1, end1, begin2, end2) {
  const beginDate1 = createDate(begin1)
  const endDate1 = createDate(end1)
  const beginDate2 = createDate(begin2)
  const endDate2 = createDate(end2)

  if (beginDate1 >= beginDate2 && beginDate1 <= endDate2) {
    return true
  }

  if (endDate1 >= beginDate2 && endDate1 <= endDate2) {
    return true
  }
  return false
}

function createDate(date) {
  return new Date(typeof date === 'number' ? date : date.replace(/-/g, '/'))
}
