const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '申请类型', value: `appTypeName` },
      { label: '申请状态', value: `appStatus` },
      { label: '医生姓名', value: `name` },
      { label: '性别', value: `gender` },
      { label: '医院名称', value: `hosName` },
      { label: '医院编码', value: `hosCode` },
      { label: '科室', value: `sectionName` },
      { label: '医生专长', value: `doctorExpertise` },
      { label: '职称', value: `profTitleName` },
      { label: '行政职务', value: `adminPostName` },
      { label: '学术头衔', value: `academicTitleName` },
      { label: '执业医师证书编码', value: `certificationCode` },
      // { label: '重合订阅指数', value: `coinSubsIndexName` },
      { label: '备注', value: `remarks` },
      // { label: 'VID', value: `vid` },
      // { label: '已删除', value: `isdeleted` },
      { label: '创建时间', value: `creationtime` },
      { label: '创建人', value: `creatorName` },
      { label: '修改时间', value: `modifytime` },
      { label: '修改人', value: `modifierName` }
    ]
  }
]
const TABLE = [
  {
    title: '差异信息',
    active: 'DIFF',
    dataList: 'differDTOs',
    columns: [
      { title: '字段名称', dataIndex: 'filedName' },
      { title: '数据变更前', dataIndex: 'beforeValue' },
      { title: '数据变更后', dataIndex: 'afterValue' }
    ]
  }
]

export { BASE, TABLE }
