<template>
  <div>
    <div style="padding-left: 32px" class="my-4">
      <a-form
        size="small"
        laba-width="90px"
        laba-position="right"
        layout="vertical"
      >
        <a-collapse v-model="activeKey" :bordered="false">
          <template v-slot:expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel
            class="customStyle"
            v-for="{ title, active, children } in baseMsg"
            :header="title"
            :key="active"
          >
            <a-col :span="8" v-for="{ label, value } in children" :key="label">
              <a-form-item
                :label="label"
                :prop="value"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input
                  v-if="!value"
                  :disabled="true"
                  value="维护次月目标医生 "
                ></a-input>
                <a-input
                  v-else-if="value === 'status'"
                  :disabled="true"
                  :value="getStatusText(formData[value])"
                ></a-input>
                <a-input
                  v-else
                  :disabled="true"
                  v-model="formData[value]"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-collapse-panel>

          <a-collapse-panel
            v-for="{ title, active, dataList, columns } in tableMsg"
            :header="title"
            :key="active"
            class="customStyle"
          >
            <a-table
              rowKey="id"
              :dataSource="formData[dataList]"
              :columns="columns"
              :pagination="false"
              :rowClassName="
                (record, index) => {
                  return index % 2 === 0 ? 'trBgClass' : ''
                }
              "
            >
              <span slot="question" slot-scope="questionnaireDTOS, record">
                <a
                  style="color: #4587f5"
                  @click="handleChooseQuestion(questionnaireDTOS, record)"
                >
                  查看问卷
                </a>
              </span>
              <span slot="agreeUnbind" slot-scope="agreeUnbind, recond">
                <a-radio-group
                  v-model="recond.agreeUnbind"
                  :disabled="chooseValue !== '1'"
                >
                  <a-radio :value="true">同意</a-radio>
                  <a-radio :value="false">拒绝</a-radio>
                </a-radio-group>
              </span>
            </a-table>
          </a-collapse-panel>
        </a-collapse>
        <div class="text-center my-8" v-if="chooseValue === '1'">
          <a-button type="primary" style="width: 120px" @click="submit">
            提交
          </a-button>
        </div>
      </a-form>
    </div>
    <modal
      v-if="showModal"
      title="问卷详情"
      width="640px"
      :closable="true"
      :footer="null"
      @close="showModal = false"
    >
      <question
        :productLine="productLine"
        :questionList="questionList"
      ></question>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import Question from '@/components/Question'
import { BASE, TABLE } from './mbyu-config'
import { getTDHistoryUnbindById } from '@/api/sale/targetdoctor'
import { getStatusText } from '@/configs/bill-msg'
import { getHandle } from '@/api/acdemicActive/acdemic-application'
export default {
  name: 'MBYUBill',
  components: { Modal, Question },
  inject: ['workForm', 'chooseValue'],
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      value: 'a',
      showModal: false,
      waitWorkForm: this.workForm,
      formData: {},
      productLine: '', //问卷中的产品线
      questionList: '', //问卷中的问题
      baseMsg: BASE,
      tableMsg: TABLE,
      activeKey: ['BASE', 'JBSQ'],
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  computed: {
    getStatus() {
      return this.formData.status
    }
  },
  created() {
    getTDHistoryUnbindById(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData.status)
    })
  },
  methods: {
    getStatusText,

    /**
     * 查看问卷
     * @param {Array} question
     * @param {Object} record 每条记录的详情信息
     */
    handleChooseQuestion(question, record) {
      this.productLine = record.productLine
      this.questionList = question
      this.showModal = true
    },

    /**
     * 提交解绑信息
     */
    submit() {
      const target = this.formData.bodyDTOS.find(
        item => item.agreeUnbind === null
      )
      if (target) {
        this.$message.warning('您还有未填写的解绑信息，请重新填写')
        return
      }

      this.waitWorkForm.optype = '1'
      this.waitWorkForm.unbindAppHeadDTO = this.formData

      // 提交解绑信息
      getHandle(this.waitWorkForm)
        .then(() => {
          this.$message.success('提交成功')
          this.$emit('close')
        })
        .catch(() => {
          this.$message.error('数据提交错误')
        })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
}
/deep/ .ant-btn-primary[disabled],
/deep/.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
/deep/.ant-btn-primary {
  background-color: #4587f4;
}
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}
</style>
