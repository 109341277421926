<template>
  <div class="m-4">
    <div class="title" v-if="showAvatar">药连锁对私付款单详情</div>
    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      align="middle"
      class="text-right"
    >
      <a-button v-if="!isFlow" @click="upload" :loading="dataLoading">
        上传附件
      </a-button>
      <a-button v-else @click="preview">查看附件</a-button>

      <a-button
        type="primary"
        class="ml-2"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="save"
      >
        保存
      </a-button>
      <a-button
        type="primary"
        class="ml-2"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="submit"
      >
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        :loading="dataLoading"
        @click="del"
      >
        删除
      </a-button>
      <a-button
        class="ml-2"
        :loading="dataLoading"
        :disabled="backDisabled"
        @click="getBack"
      >
        撤销
      </a-button>
      <a-divider type="vertical"></a-divider>

      <a-button @click="print">单据打印</a-button>

      <a-divider type="vertical"></a-divider>

      <a-button
        :loading="dataLoading"
        @click="$router.push({ name: 'housePaymentDS' })"
      >
        返回
      </a-button>
    </a-row>

    <div class="text-right mt-4" v-else>
      <a-button type="primary" class="mr-4" @click="preview">查看附件</a-button>
      <a-button class="mr-4" @click="print">单据打印</a-button>
    </div>

    <base-form>
      <base-form-title title="申请人信息" icon="user" />
      <base-form-items
        :formData="form"
        :formBaseList="formUserList"
      ></base-form-items>

      <base-form-title title="申请住房基本信息" icon="home" />
      <base-form-items
        :formData="form"
        :formBaseList="formBaseList"
        @open-modal="openModal"
      ></base-form-items>

      <base-form-title title="报销明细" icon="container" />
      <a-table
        bordered
        rowKey="bid"
        size="small"
        class="mt-4"
        :columns="columns"
        :dataSource="form.housePaymentBodys"
        :pagination="false"
        :rowClassName="
          (record, index) => {
            return chooseIndex === index ? 'bg-blue-200' : ''
          }
        "
        :customRow="
          (record, index) => {
            return {
              on: {
                click: () => {
                  chooseIndex = index
                }
              }
            }
          }
        "
      >
        <!-- 表格标题 -->
        <template slot="typeTitle">
          <span class="app-form-required">费用类型</span>
        </template>
        <template slot="monthTitle">
          <span class="app-form-required">月份</span>
        </template>
        <template slot="feeTitle">
          <span class="app-form-required">金额</span>
        </template>

        <!-- 序号 -->
        <template slot="number" slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
        <!-- 费用类型 -->
        <template slot="type" slot-scope="text, record">
          <span v-if="isFlow">
            {{ record.houseFeeTypeName }}
          </span>
          <a-select
            v-model="record.houseFeeType"
            v-else
            @change="feeTypeChange($event, record)"
          >
            <a-select-option
              v-for="item in feeTypeEmun"
              :value="item.value"
              :key="item.value"
            >
              {{ item.text }}
            </a-select-option>
          </a-select>
        </template>
        <!-- 月份 -->
        <template slot="month" slot-scope="text, record">
          <span v-if="isFlow">
            {{ record.reimMonth }}
          </span>
          <a-month-picker
            v-else
            :value="
              record.reimMonth ? moment(record.reimMonth, 'YYYY-MM') : null
            "
            @change="onMonthChange(arguments, record)"
          />
        </template>
        <!-- 备注 -->
        <template slot="remark" slot-scope="text, record">
          <span v-if="isFlow">
            {{ record.vnote }}
          </span>
          <a-input
            placeholder="限50个字"
            :maxLength="50"
            v-else
            v-model="record.vnote"
          ></a-input>
        </template>
        <!-- 金额 -->
        <template slot="fee" slot-scope="text, record">
          <span v-if="isFlow">
            {{ record.amount }}
          </span>
          <a-input
            v-else
            v-model="record.amount"
            :oninput="oninput['digit']"
            @change="calcFee"
          ></a-input>
        </template>
      </a-table>
      <a-button-group class="mt-2" v-if="!isFlow">
        <a-button icon="plus" title="添加" @click="addListItem">添加</a-button>
        <a-button icon="minus" type="danger" title="删除" @click="delListItem">
          删除
        </a-button>
      </a-button-group>
      <div class="mt-2 text-right">
        <span class="font-bold">金额合计 :</span>
        <span class="ml-2 text-red-600">
          {{ form.applyMoney }}
        </span>
      </div>

      <base-form-title title="发票明细（从票夹选择发票）" icon="book" />
      <InvoiceInfo
        :billInfos.sync="form.billInfos"
        :checkUnitInfo="form.checkUnitInfo"
        :status="isFlow"
        :isFatch="isBillFinish && !isFlow"
        :billType="`ZFDS`"
        :pkSrc="form.id"
      ></InvoiceInfo>

      <base-form-title title="收款信息" icon="account-book" />
      <base-form-items
        :formData="form"
        :formBaseList="formAccountList"
        @open-modal="openModal"
      ></base-form-items>

      <base-form-title title="付款信息" icon="money-collect" />
      <base-form-items
        :formData="form"
        :formBaseList="formPayList"
        @open-modal="openModal"
      ></base-form-items>

      <base-form-title title="单据信息" icon="team" />
      <base-form-items
        :formData="form"
        :formBaseList="formBillInfoList"
      ></base-form-items>

      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDTO"
        :status="isFlow"
        :summaryid="form.flowInfoDTO.summaryid"
        :nextapproveman="form.flowInfoDTO.nextapproveman"
        :curApprover="form.flowInfoDTO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.billMaker" :billId="form.id"></comment>

      <print-wrapper ref="printWrapper" v-show="printVisible">
        <private-print
          :datas="form"
          :name="form.billMakerName"
          :auditInfo="auditInfo"
          title="药连锁房产对私报销单"
        ></private-print>
      </print-wrapper>
    </base-form>

    <!-- 收款人 -->
    <rece-man-preview
      v-if="showReceModal"
      @receManInfo="receManInfo"
      @close="showReceModal = false"
    ></rece-man-preview>

    <!-- 付款单位 -->
    <pay-name-preview
      v-if="showPayModal"
      @payNameInfo="payNameInfo"
      @close="showPayModal = false"
    ></pay-name-preview>

    <HouseArchivesModal
      v-if="showModal"
      :visible="showModal"
      :isAll="true"
      @choose="chooseHouseArchives"
      @close="showModal = false"
    ></HouseArchivesModal>

    <upload-modal
      v-if="showUpload"
      uploadName="药连锁对私付款"
      :pid="form.id"
      :uploadFilelistInfo="uploadFilelistInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>
    <preview-modal
      v-if="showPreview"
      uploadName="药连锁对私付款"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </div>
</template>

<script>
import BaseForm from '@/components/FieldSet/BaseForm'
import BaseFormTitle from '@/components/FieldSet/BaseFormTitle'
import BaseFormItems from '@/components/FieldSet/BaseFormItems'
import HouseArchivesModal from '@/components/Modal/HouseArchivesModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import PreviewModal from '@/components/Upload/PayPreviewModal'
import UploadModal from '@/components/Upload/PayUploadModal'
import PayNamePreview from '@/views/activity/modules/expense/PayNamePreview'
import ReceManPreview from '@/views/activity/modules/expense/ReceManPreview'
import InvoiceInfo from '@/components/flow/InvoiceInfo'
import PrintWrapper from 'vue-easy-print'
import PrivatePrint from './PrivatePrint'
// api
import moment from 'moment'
import debounce from 'lodash/debounce'
import { oninput } from '@/utils/validate'
import { default as cloneDeep } from 'lodash/cloneDeep'
import { isDisabled } from '@/utils/util'
import { getDoc } from '@/configs/doc-config'
import { randomKey } from '@/utils/util'
import {
  getAddPayment,
  getUpdatePayment,
  getDelPayment,
  getPayment,
  getSubmitZFDS,
  getPrintInfo,
  getPrivateBankAcc
} from '@/api/acdemicActive/acdemic-payment'
import { getUserOne } from '@/api/sysadmin/users'
import { getOACancel2 } from '@/api/acdemicActive/acdemic-application'
import { getStatusText } from '@/configs/bill-msg'

// mixin
import userMixin from '../mixins/bill-user-mixin'
import verityMixin from '../mixins/verity-mixin'

export default {
  name: 'HouseDormitoryForm',
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseForm,
    BaseFormTitle,
    BaseFormItems,
    HouseArchivesModal,
    PrintWrapper,
    PrivatePrint,
    PayNamePreview,
    ReceManPreview,
    Process,
    Comment,
    InvoiceInfo,
    PreviewModal,
    UploadModal
  },
  mixins: [userMixin, verityMixin],
  data() {
    return {
      curType: this.type || this.$route.query.type || 'add',
      curPid: this.pid || this.$route.query.pid,
      isBillFinish: false, // 单据请求是否完成
      auditInfo: {}, //打印的审批信息
      showModal: false,
      showReceModal: false,
      showPayModal: false,
      showUpload: false,
      showPreview: false,
      feeTypeEmun: [],
      chooseIndex: '',
      dataLoading: false, // 提交数据
      printVisible: false, // 是否显示打印弹窗
      oninput, // 选择的收款信息索引

      /* eslint-disable prettier/prettier */
      form: {
        billCode: '', // 单据号
        billStatus: '', // 单据状态
        billDate: '', // 申请日期
        applyMan: '', // 申请人id
        applyManName: '', // 申请人名称
        post: '', //岗位
        phone: '', // 联系方式
        applyMoney: '', // 申请金额
        settleType: '', // 结算方式
        note: '', // 用途

        billType: '', // 单据类型
        housePropertyArchiveId: '', // 房产id
        housePropertyArchiveInfo: null, // 房产信息
        houseCode: '', // 房产编号
        address: '', // 房产地址
        houseTypeName: '', // 房产类型名称
        useTypeName: '', // 使用类型名称
        houseDeptName: '', // 房产所属部门名称

        payId: '', //付款单位id
        payAccNum: '', //付款银行账号
        payBankName: '', //付款银行名称
        payCode: '', //付款单位编码
        payCommbineNum: '', //付款银行行号
        payDate: '', //最后付款日期
        payName: '', //付款单位名称
        pkSrc: '', //来源pk
        receAccNum: '', //收款银行账号
        receBankName: '', //收款银行名称
        receCode: '', //收款单位编码
        receCommbineNum: '', //收款银行行号
        receName: '', //收款单位名称

        billMaker: '', //制单人
        billMakerName: '', // 制单人姓名
        makeTime: '', //制单时间
        modifer: '', //修改人
        modiftime: '', //修改时间
        payStatus: '', //支付状态
        payStatusName: '', // 支付状态名称
        refundSign: '', // 退款标记
        refundDetailNo: '', // 交易明细退款号
        
        listBillFiles: [], // 附件
        billInfos: [], // 发票明细
        checkUnitInfo: [], // 不一致票据
        housePaymentBodys: [], // 报销明细
        flowInfoDTO: { status: '1' } // 流程信息
      },

      formBaseList: [
        { label: '房产编号', prop: 'houseCode', type: 'search', disabled: () => this.getCommonDisabled },
        { label: '房产地址', prop: 'address', disabled: true },
        { label: '房产类型', prop: 'houseTypeName', disabled: true },
        { label: '使用类型', prop: 'useTypeName', disabled: true },
        { label: '房产所属部门', prop: 'houseDeptName', disabled: true },
      ],

      formAccountList: [
        { label: '收款人', prop: 'receName', disabled: true},
        { label: '收款银行名称', prop: 'receBankName', disabled: true },
        { label: '收款银行行号', prop: 'receCommbineNum', disabled: true },
        { label: '收款银行账号', prop: 'receAccNum', disabled: true },
      ],

      formPayList: [
        { label: '付款单位', prop: 'payName', type: 'search', disabled: () => this.getCommonDisabled },
        { label: '付款银行名称', prop: 'payBankName', disabled: true },
        { label: '付款银行行号', prop: 'payCommbineNum', disabled: true },
        { label: '付款银行账号', prop: 'payAccNum', disabled: true },
      ],

      formBillInfoList: [
        { label: '制单人', prop: 'billMakerName', disabled: true },
        { label: '制单时间', prop: 'makeTime', disabled: true },
        { label: '修改人', prop: 'modiferName', disabled: true },
        { label: '修改时间', prop: 'modiftime', disabled: true },
        { label: '支付状态', prop: 'payStatusName', disabled: true },
        { label: '返款标记', prop: 'refundSign', disabled: true },
        { label: '交易明细退款单号', prop: 'refundDetailNo', disabled: true },
      ],

      columns: [
        { title: '序号', width: 72, dataIndex: 'no', scopedSlots: { customRender: 'number' } },
        { dataIndex: 'type', width: 200, slots: { title: 'typeTitle' }, scopedSlots: { customRender: 'type' } },
        { dataIndex: 'month', width: 200, slots: { title: 'monthTitle' }, scopedSlots: { customRender: 'month' } },
        { dataIndex: 'fee', width: 200, slots: { title: 'feeTitle' }, scopedSlots: { customRender: 'fee' } },
        { title: '备注说明', dataIndex: 'remark', scopedSlots: { customRender: 'remark' } },
        
      ]
    }
  },

  computed: {
    showAvatar() {
      return this.$store.getters['protal']
    },

    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getCommonDisabled() {
      return this.isFlow || false
    },

    hasFile() {
      if (this.form.listBillFiles && this.form.listBillFiles.length)
        return true
      return false
    }
  },

  created() {
    // 房产类型
    getDoc('064', res => (this.formBaseList[2].options = res))
    // 使用类型
    getDoc('061', res => (this.formBaseList[3].options = res))
    // 费用类型
    getDoc('067', res => (this.feeTypeEmun = res))

    if(this.curType !== 'add') {
      getPayment(this.curPid).then(res => {
        this.form = Object.assign(this.form, res)
        
        const billStatus = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'

        this.form.applyManName = this.form.applyManDTO.username
        this.form.billMakerName = this.form.billMakerDTO.username
        this.form.housePropertyArchiveInfo = this.form.housePropertyArchiveInfo || {}
        this.form.billInfos = this.form.billInfos || []
        this.form.checkUnitInfo = this.form.checkUnitInfo || []
        this.form.housePaymentBodys = this.form.housePaymentBodys || []
        this.form.payStatusName = this.form.payStatus ? '已支付' : '未支付'
        this.form.billStatus = getStatusText(billStatus)

        // 房产信息
        this.form.houseCode = this.form.housePropertyArchiveInfo.houseCode
        this.form.address = this.form.housePropertyArchiveInfo.address
        this.form.houseTypeName = this.form.housePropertyArchiveInfo.houseTypeName
        this.form.useTypeName = this.form.housePropertyArchiveInfo.useTypeName
        this.form.houseDeptName = this.form.housePropertyArchiveInfo.houseDeptName
        
        // 计算金额合计
        this.calcFee()

        // 修改人
        if(this.form.modifer) {
          this.$set(this.form, 'modiferName', this.form.modiferDTO.username)
        }

        getUserOne(res.billMaker).then(res => {
          this.form.billMakerName = res.username
        })

        // 单据状态(向上通知)
        const status = this.form.flowInfoDTO ? this.form.flowInfoDTO.status : '1'
        this.$emit('status', status)
      }).finally(() => {
        this.isBillFinish = true
      })
    } else {

      setTimeout(() => {
        this.isBillFinish = true
      }, 0)
      
      this.form.billType = 'ZFDS'
      getUserOne(this.$store.getters['uid']).then(res => {
        this.form.billMaker = this.$store.getters['uid']
        this.form.billMakerName = res.username
      })
      // 默认带出收款人信息
      getPrivateBankAcc(1, 1, {
        userCode: this.$store.getters['userCode']
      }).then(res => {
        const privateAcc = res.datas[0]
        if (privateAcc) {
          const {
            userCode,
            accountName,
            receAccNum,
            receBankName,
            receCommbineNum
          } = privateAcc
          this.form.receCode = userCode
          this.form.receName = accountName
          this.form.receAccNum = receAccNum
          this.form.receBankName = receBankName
          this.form.receCommbineNum = receCommbineNum
        } else {
          this.$message.warn('收款人信息获取失败')
        }
      })
    }
  },

  methods: {
    moment,

     // 收款单位改变
     payNameInfo(item) {
      this.form.payId = item.payId
      this.form.payName = item.payName
      this.form.payBankName = item.payBankName
      this.form.payCode = item.payCode
      this.form.payCommbineNum = item.payCommbineNum
      this.form.payAccNum = item.payAccNum
    },

     //选择返回的收款信息
     receManInfo(item) {
      this.form.receName = item.receName
      this.form.receBankName = item.receBankName
      this.form.receCommbineNum = item.receCommbineNum
      this.form.receAccNum = item.receAccNum
      this.form.receCode = item.userCode
    },

    chooseHouseArchives(item) {
      this.$set(this.form, 'housePropertyArchiveId', item.id) // 住房id
      this.$set(this.form, 'houseCode', item.houseCode) // 申请住房
      this.$set(this.form, 'address', item.address) // 房产编号
      this.$set(this.form, 'houseDeptName', item.houseDeptName) // 房产所属部门名称
      this.$set(this.form, 'houseTypeName', item.houseTypeName) // 房产类型名称
      this.$set(this.form, 'useTypeName', item.useTypeName) // 使用类型
    },

    // 上传后的文件信息
    hasUpload(files) {
      const fileList = JSON.parse(files.fileListInfo)
      if (this.form.id) {
        for (let item of fileList) {
          item.pid = this.form.id
        }
      }
      this.form.listBillFiles = fileList
    },

    // 单据打印
    print() {
      const summaryid = this.form.flowInfoDTO
        ? this.form.flowInfoDTO.summaryid
        : ''
      if (summaryid) {
        getPrintInfo(summaryid).then(res => {
          this.auditInfo = res
          this.printVisible = true
          this.$nextTick(() => this.$refs['printWrapper'].print())
        })
      } else {
        this.printVisible = true
        this.$nextTick(() => this.$refs['printWrapper'].print())
      }
    },

    // 上传附件
    upload() {
      this.uploadFilelistInfo = JSON.stringify(this.form.listBillFiles)
      this.showUpload = true
    },

    // 预览附件
    preview() {
      if (!this.hasFile) {
        this.$message.warning('未上传附件信息')
        return
      }

      this.previewFileListInfo = JSON.stringify(this.form.listBillFiles)
      this.showPreview = true
    },

    openModal(item) {
      switch (item.prop) {
        case 'houseCode':
          this.showModal = true
          break
        case 'payName':
          this.showPayModal = true
          break
        case 'receName':
          this.showReceModal = true
          break
        default:
          break
      }
    },

    // 月份更改
    onMonthChange([, dateString], recoed) {
      recoed.reimMonth = dateString
    },

    addListItem() {
      this.form.housePaymentBodys.push({
        bid: `crm_${randomKey(15)}`,
        houseFeeType: '',
        houseFeeTypeName: '',
        reimMonth: '',
        vnote: '',
        amount: ''
      })
    },

    delListItem() {
      if(this.form.housePaymentBodys.length) {

        if(this.chooseIndex !== '') {
          this.form.housePaymentBodys.splice(this.chooseIndex, 1)
          this.chooseIndex = ''
        }else {
          this.form.housePaymentBodys.pop()
        }

        this.calcFee()
      }
    },

     // 计算报销费用
     calcFee: debounce(function() {
      this.form.applyMoney = this.form.housePaymentBodys.reduce((prev, cur) => {
        return this.$np.plus(Number(cur.amount || '0'), prev)
      }, 0)
    }, 800),

    filterData() {
      const submitData = cloneDeep(this.form)
      // 移除自定义id
      for (const item of submitData.housePaymentBodys) {
        if(item.bid.startsWith('crm_')) item.bid = ''
      }

      return submitData
    },

    // 报销明细费用类型回调
    feeTypeChange(value, record) {
      const target = this.feeTypeEmun.find(i => i.value === value)
      record.houseFeeType = target.value
      record.houseFeeTypeName = target.text
    },

    // 校验报销明细
    verityFee(){
      const set = new Set()

      for (const item of this.form.housePaymentBodys) {
        const key = `${item.houseFeeType}-${item.reimMonth}`
        if(set.has(key)) {
          this.$message.error(`费用类型-${item.houseFeeTypeName} + 报销月份-${item.reimMonth} 不能重复`)
          return false
        } else {
          set.add(key)
        }
      }

      return true
    },

    //保存
    save(callback) {
      this.saveForm(callback)
    },

    saveForm() {
      const saveApi = this.form.id ? getUpdatePayment : getAddPayment

      const submitData = this.filterData()

      submitData.modifer = this.$store.getters['uid']
      submitData.modiftime = this.$date().format('YYYY-MM-DD HH:mm:ss')

      if (this.verityAppForm(this.form.id ? false : 'save')) {

        if(this.form.id && !this.verityFee()) return

        this.dataLoading = true
        saveApi(submitData)
          .then(() => {
            this.$message.success('保存成功')
            this.$router.replace({ name: 'housePaymentDS' })
          }).finally(() => {
            this.dataLoading = false
          })
      } 
    },

    //提交
    submit() {
      const submitData = this.filterData()

      submitData.modifer = this.$store.getters['uid']
      submitData.modiftime = this.$date().format('YYYY-MM-DD HH:mm:ss')

      if (this.verityAppForm(true)) {

        if(!this.verityFee()) return

        this.dataLoading = true
        getSubmitZFDS(submitData).then(() => {
          this.$message.success('提交成功')
          this.$router.replace({ name: 'housePaymentDS' })
        }).finally(() => {
            this.dataLoading = false
          })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该药连锁对私付款申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getDelPayment(this.form.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'housePaymentDS' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该药连锁对私付款申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'ZFDS',
            this.form.flowInfoDTO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDTO.status = '5'
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
}

.app-form-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 12px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
