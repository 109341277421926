const BASE = [
  {
    title: '基本信息',
    active: 'BASE',
    children: [
      { label: '申请类型', value: `` },
      { label: '申请状态', value: `status` },
      { label: '拜访类型', value: 'visitType' },
      { label: '拜访医生', value: 'doctorName' },
      { label: '医院', value: 'hospitalName' },
      { label: '科室', value: 'section' },
      { label: '拜访日期', value: 'visitDate' },
      { label: '客户反馈', value: 'feedback' },
      { label: '备注', value: 'remark' },
      { label: '创建时间', value: `createTime` },
      { label: '创建人', value: `creatorName` },
      { label: '岗位编码', value: `postCode` },
      { label: '岗位名称', value: `postName` },
      { label: '修改时间', value: `modifyTime` },
      { label: '修改人', value: `modifierName` }
    ]
  }
]

export { BASE }
