import { render, staticRenderFns } from "./SpeakerInfo.vue?vue&type=template&id=12310199&scoped=true"
import script from "./SpeakerInfo.vue?vue&type=script&lang=js"
export * from "./SpeakerInfo.vue?vue&type=script&lang=js"
import style0 from "./SpeakerInfo.vue?vue&type=style&index=0&id=12310199&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12310199",
  null
  
)

export default component.exports