<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="活动信息" key="1" class="customStyle">
          <div class="ml-2 mt-3 flex flex-wrap justify-start">
            <div class="form-item">
              <span class="form-label">会议名称</span>
              <a-input
                class="form-input"
                v-model="application.conferenceName"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                申请金额
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="application.applyMoney"
                :disabled="isRead"
                oninput="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                @blur="submit"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                结算方式
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="application.settleType"
                :disabled="isRead"
                @change="settleTypeChange"
              >
                <a-select-option :key="item.id" v-for="item in settleTypeList">
                  {{ item.name !== null ? item.name : '' }}
                </a-select-option>
              </a-select>
            </div>
            <div class="form-item" v-if="payType === 'public'">
              <span class="form-label">最后付款日期</span>
              <a-date-picker
                v-if="application.payDate"
                class="form-input"
                :disabled="isRead"
                :allowClear="false"
                :value="moment(application.payDate)"
                @change="payDateChange"
              />
              <a-date-picker
                v-else
                class="form-input"
                :allowClear="false"
                :disabled="isRead"
                @change="payDateChange"
              />
            </div>
            <div class="form-item">
              <span class="form-label" v-if="payType === 'public'">经办人</span>
              <span class="form-label" v-else>报销人</span>
              <a-input
                class="form-input"
                v-model="applyManName"
                :disabled="true"
              />
            </div>
            <div class="form-item" v-if="payType === 'public'">
              <span class="form-label">经办人电话</span>
              <a-input
                class="form-input"
                v-model="application.phone"
                :disabled="isRead"
                @blur="submit"
              />
            </div>
            <div class="form-item">
              <span class="form-label">付款说明</span>
              <a-input
                class="form-input"
                v-model="application.note"
                :disabled="isRead"
                :title="application.note"
                @blur="submit"
              />
            </div>
            <div
              class="flex flex-col-reverse"
              v-if="payType === 'public' || payType === 'private'"
            >
              <span class="mb-2">
                <span>单位不一致：</span>
                <span
                  class="ml-1 font-bold text-xl"
                  :class="[
                    application.incompatible ? 'text-red-500' : 'text-green-500'
                  ]"
                >
                  {{
                    application.incompatible === null
                      ? ''
                      : application.incompatible
                      ? '√'
                      : '×'
                  }}
                </span>
              </span>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import { loadDictTypes } from '@/api/customer/dictmanage'
import moment from 'moment'
export default {
  name: 'applicationInfo',
  props: {
    application: {
      type: Object,
      required: true
    },
    payType: String,
    type: String,
    pkSrc: String,
    conferenceName: String,
    uid: String,
    username: String,
    status: Boolean
  },
  data() {
    return {
      applyManName: '', //经办人名称
      settleTypeList: []
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  watch: {
    application() {
      this.queryUser()
    },
    username(nval) {
      this.applyManName = nval
    }
  },
  created() {
    loadDictTypes().then(response => {
      this.queryDocByName(response, '结算方式')
    })
    if (this.type !== 'check') {
      this.application.conferenceName = this.conferenceName
      this.application.applyMan = this.uid
      this.applyManName = this.username
      this.queryUser()
    }
  },
  methods: {
    submit() {
      this.$emit('update:application', this.application)
    },

    //最后付款日期改变回调
    payDateChange(date, dateString) {
      this.application.payDate = dateString
    },

    //结算方式改变回调
    settleTypeChange(value) {
      this.application.settleType = value
      const target = this.settleTypeList.find(item => item.id === value)
      if (target) {
        this.application.settleTypeDTO = target
      }
      this.submit()
    },

    /**
     * 查询所属公司、业务类型枚举
     * @param {Array} docList //枚举列表
     * @param {String} settleType //枚举名称（结算方式）
     */
    queryDocByName(docList, settleType) {
      docList.forEach(item => {
        if (item.name === settleType) this.settleTypeList = item.defDocDTOs
      })
    },

    //获取用户名称
    queryUser() {
      this.application.applyMan = this.uid
      this.applyManName = this.username
    },

    moment
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
