import request from '@/utils/request'

/**
 * 查询所有会议拍照信息
 * @param {Object} id
 */
export function getMeetingPhoto(id) {
  return request({
    method: 'get',
    url: `meetingPhoto/${id}`
  })
}

/**
 * 查询自己会议拍照信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getMeetingPhotoList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `meetingPhoto/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 查询所有会议拍照信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getAllMeetingPhotoList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `meetingPhoto/getAllList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 保存会议拍照信息
 * @param {Object} data
 * @returns
 */
export function saveMeetingPhoto(data) {
  return request({
    method: 'post',
    url: `meetingPhoto/save`,
    data: data
  })
}

/**
 * 更新会议拍照信息
 * @param {Object} data
 * @returns
 */
export function updateAddPayment(data) {
  return request({
    method: 'post',
    url: `meetingPhoto/update`,
    data: data
  })
}

/**
 * 分配会议拍照权限
 * @param {Array} data
 * @returns
 */
export function getAssignMeetingPhotoRight(data) {
  return request({
    method: 'post',
    url: `meetingPhoto/assignMeetingPhotoRight`,
    data
  })
}
