const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '单据号', value: `vbillno` },
      { label: '业务日期', value: `vbilldate` },
      {
        label: '单据类型',
        value: `refundBillType`,
        type: 'select',
        options: [
          { value: 'C162', text: '新零售费用计提单' },
          { value: 'C165', text: '非学术费用计提单' }
        ]
      },
      { label: '商业公司', value: `busiCompName` },
      { label: '品种编码', value: `materialCode` },
      { label: '品种名称', value: `materialName` },
      { label: '规格', value: `materialSpec` },
      {
        label: '兑付方式',
        value: `refundType`,
        type: 'select',
        options: [
          { value: 'GF', text: '高返' },
          { value: 'PZ', text: '票折' }
        ]
      },
      { label: '核销数量', value: `verifyNum` },
      { label: '计提费用合计', value: `calcFeeTotal` },
      { label: '省办', value: 'pkProvName' },
      { label: '组织', value: 'orgName' }
    ]
  }
]
const TABLE = [
  {
    title: '信息列表',
    active: 'TABLE',
    dataList: 'bodyDtos',
    columns: [
      {
        title: '来源单据号',
        dataIndex: 'srcBillno',
        ellipsis: true,
        width: 150
      },
      {
        title: '发票号',
        dataIndex: 'invoiceno',
        ellipsis: true,
        width: 150
      },
      {
        title: '金税票号',
        dataIndex: 'vgoldtaxcode',
        ellipsis: true,
        width: 84
      },
      {
        title: '发货单号',
        dataIndex: 'outOfStockBillno',
        ellipsis: true,
        width: 150
      },
      {
        title: '发货日期',
        dataIndex: 'issuancedate',
        width: 128
      },
      {
        title: '商业公司',
        dataIndex: 'busiCompName',
        width: 128
      },
      {
        title: '物料编码',
        dataIndex: 'materialCode',
        ellipsis: true,
        width: 108
      },
      {
        title: '物料',
        dataIndex: 'materialName',
        ellipsis: true,
        width: 128
      },
      {
        title: '规格',
        dataIndex: 'materialSpec',
        width: 128
      },
      {
        title: '开票价',
        dataIndex: 'priceintax',
        width: 64
      },
      {
        title: '核销数量',
        dataIndex: 'cancelno',
        width: 64
      },
      {
        title: '核销金额',
        dataIndex: 'cancelMny',
        width: 64
      },
      {
        title: '单盒费用',
        dataIndex: 'perboxCost',
        width: 64
      },
      {
        title: '销售费用',
        dataIndex: 'sellingExpenses',
        width: 64
      }
    ]
  }
]

export { BASE, TABLE }
