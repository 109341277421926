/**
 * // 拷贝模块信息
 * @param {Object} object //父对象的所有信息
 * @param  {...any} attr //从父对象copy的属性数组
 */
export function copyObj(object, ...attr) {
  let target = {}
  for (let item of attr) {
    target[item] = object[item]
  }
  return target
}

/**
 * 获取申请人信息和执行人信息属性
 * @param {Object} object
 */
export function getUserInfo(object) {
  return copyObj(
    object,
    'appdate',
    'applicant',
    'post',
    'contact',
    'region',
    'province',
    'locality',
    'department',
    'localityDTO',
    'regionDTO',
    'provinceDTO',
    'departmentDTO'
  )
}

/**
 * 获取活动类型及执行标准属性
 * @param {Object} object
 */
export function getActivityType(object) {
  return copyObj(object, 'activitytype', 'activityTypeStdDTO')
}

/**
 * 获取活动信息的属性
 * @param {Object} object
 */
export function getActivityInfo(object) {
  return copyObj(
    object,
    'conferenceCode', //会议编号
    'conferenceName', //会议名称
    'conferenceType', //所属公司
    'product', //产品
    'hostingType', //举办类型
    'pubTheme', //品牌信息-发布主题
    'pubPurpose', //品牌信息-发布目的
    'pubScheme', //品牌信息-发布方案
    'partnerContract', //品牌信息-合作方合同
    'businessType', //业务类型
    'otherContract', // 其他合同
    'conferenceTypeDTO',
    'businessTypeDTO',
    'hostingTypeDTO',
    'productDTO',
    'activityPlace',
    'activityTheme',
    'activityPurpose',
    'activityNatrue',
    'activityStdate',
    'activityEddate',
    'speakerNum',
    'preinvitednum',
    'internalnum',
    'participantsList',
    'activityInvitation',
    'hasconfContract',
    'confCompany',
    'contractMoney',
    'contractScan',
    'sponsor',
    'sponsorEstdate',
    'budgetPreCost',
    'sponsorQualification',
    'sponsorInvitation',
    'contract',
    'isinvitedspeaker',
    'isbrandpre'
  )
}

/**
 * 获取费用信息
 * @param {Object} object
 */
export function getFeeInfo(object) {
  return copyObj(
    object,
    'otherFee', // 其他费用
    'registerFee', // 注册费用
    'budgetMeals',
    'budgetMealsNum',
    'budgetAccommodation',
    'budgetAccommodationNum',
    'budgetBooth',
    'budgetBoothNum',
    'budgetMeetingroom',
    'budgetMeetingroomNum',
    'budgetFlight',
    'budgetFlightNum',
    'budgetTrain',
    'budgetTrainNum',
    'budgetReception',
    'budgetReceptionNum',
    'budgetMake',
    'budgetMakeNum',
    'budgetDesign',
    'budgetDesignNum',
    'budgetThirdparty',
    'budgetThirdpartyNum',
    'budgetServiceTotal',
    'budgetBrandpreTotal', //推广物料的合计
    'budgetCooperationTotal', //协办金额
    'budgetTotal',
    'budgetApprovetotal',
    'onlineLiveBroadcastingFee'
  )
}

/**
 * 获取费用信息
 * @param {Object} object
 */
export function getFeeRemInfo(object) {
  return copyObj(
    object,
    'otherFee', // 其他费用
    'registerFee', // 注册费用
    'budgetMeals',
    'budgetMealsNum',
    'budgetAccommodation',
    'budgetAccommodationNum',
    'budgetBooth',
    'budgetBoothNum',
    'budgetMeetingroom',
    'budgetMeetingroomNum',
    'budgetFlight',
    'budgetFlightNum',
    'budgetTrain',
    'budgetTrainNum',
    'budgetReception',
    'budgetReceptionNum',
    'budgetMake',
    'budgetMakeNum',
    'budgetDesign',
    'budgetDesignNum',
    'budgetThirdparty',
    'budgetThirdpartyNum',
    'budgetServiceTotal',
    'onlineLiveBroadcastingFee', // 线上直播费用
    'budgetConsultTotal', //总结单的讲者咨询费
    'budgetCooperationTotal', //协办费用
    'budgetBrandpreTotal', //总结单的推广物料合计
    'infoPubAmount', //品牌信息发布费用

    'reimOtherFee', // 其他费用
    'reimRegisterFee', // 注册费
    'reimbudgetMeals',
    'reimbudgetMealsNum',
    'reimbudgetAccommodation',
    'reimbudgetAccommodationNum',
    'reimbudgetBooth',
    'reimbudgetBoothNum',
    'reimbudgetMeetingroom',
    'reimbudgetMeetingroomNum',
    'reimbudgetFlight',
    'reimbudgetFlightNum',
    'reimbudgetTrain',
    'reimbudgetTrainNum',
    'reimbudgetReception',
    'reimbudgetReceptionNum',
    'reimbudgetMake',
    'reimbudgetMakeNum',
    'reimbudgetDesign',
    'reimbudgetDesignNum',
    'reimbudgetThirdparty',
    'reimbudgetThirdpartyNum',
    'reimbudgetServiceTotal',
    'reimOnlineLiveBroadcastingFee',
    'reimbudgetConsultTotal', //申请单的专家讲者咨询费
    'reimbudgetCooperationTotal', //协办费用
    'reimbudgetBrandpreTotal', //申请单的推广物料费
    'reimInfoPubAmount', //品牌信息发布费用

    'financialApprovalAmount', //财务批准报销金额
    'budgetPreCost', //已预付的金额
    'budgetTotal', //合计
    'reimAmountApprove'
  )
}

/**
 * 获取讲者信息
 * @param {Object} object
 */
export function getSpeakerInfo(object) {
  return copyObj(object, 'speakers', 'budgetConsultTotal')
}

/**
 * 获取品牌信息发布费用明细
 * @param {Object} object
 */
export function getExpenseInfo(object) {
  return copyObj(
    object,
    'expenseDetailDTOs',
    'infoPubAmount' //品牌信息-信息发布费用
  )
}

/**
 * 获取推广物料信息
 * @param {Object} object
 */
export function getBrandpreInfo(object) {
  return copyObj(
    object,
    'brandpreInfoDTOs',
    'brandpreMoneytotal', //实际的推广物料金额
    'reimbrandpreMoneytotal' //预算的推广物料金额
  )
}

/**
 * 获取备注信息
 * @param {Object} object
 */
export function getNote(object) {
  return copyObj(object, 'remarks')
}

/**
 * 获取活动总结的活动信息
 * @param {Object} object
 */
export function getActivityRemInfo(object) {
  return copyObj(
    object,
    'activityAppdate', // 申请单的申请日期
    'activityAppId', // 申请单id
    'hostingType', //举办类型
    'activityNatrue', //举办性质
    'conferenceCode',
    'conferenceName',
    'conferenceType',
    'businessType',
    'product',
    'reimTurnout',
    'internalnum',
    'signinForm',
    'pictures',
    'actualActivityPlace',
    'actualActivityStdate',
    'actualActivityEddate',
    'consultationFeeProof',
    'articleNetworkLink', //信息发布-文章网络连接
    'projectSummary', //信息发布-费用
    'cooperationInfo', // 协办方资料
    'activitySummary',
    'reimAmountApprove',
    'capitalForm',
    'conferenceTypeDTO',
    'businessTypeDTO',
    'productDTO',
    'hostingTypeDTO'
  )
}

/**
 * 获取申请信息的数据
 * @param {Object} object
 */
export function getApplication(object) {
  return copyObj(
    object,
    'applyMan',
    'applyMoney',
    'settleType',
    'payDate',
    'conferenceName',
    'phone',
    'note',
    'settleTypeDTO',
    'incompatible'
  )
}

/**
 * 获取收款款信息的数据
 * @param {Object} object
 */
export function getCollection(object) {
  return copyObj(
    object,
    'receName',
    'receBankName',
    'receAccNum',
    'receCode',
    'receCommbineNum'
  )
}

/**
 * 获取付款信息的数据
 * @param {Object} object
 */
export function getPayMsg(object) {
  return copyObj(
    object,
    'payId',
    'payName',
    'payCommbineNum',
    'payCode',
    'payBankName',
    'payAccNum'
  )
}

/**
 * 获取单据信息的数据
 * @param {Object} object
 */
export function getReceipts(object) {
  return copyObj(
    object,
    'billCode',
    'billDate',
    'billMaker',
    'makeTime',
    'modifer',
    'modiftime',
    'payStatus',
    'refundSign',
    'refundDetailNo'
  )
}

/**
 * 获取单据的票据信息的数据
 * @param {Object} object
 */
export function getInvoice(object) {
  return copyObj(object, 'billInfos', 'checkUnitInfo')
}

/**
 * 获取冲借款回写信息
 * @param {Object} object
 */
export function getRewriteCjk(object) {
  return copyObj(object, 'ajkye', 'acjkTotal')
}

/**
 * 接待申请的单据信息字段
 * @param {Object} object
 * @returns
 */
export function getBillMsgByJDSQ(object) {
  return copyObj(
    object,
    'billno',
    'applicant',
    'applicantName',
    'vnote',
    'appDate',
    'dept',
    'deptCode',
    'deptName',
    'contact',
    'actualCost'
  )
}

/**
 * 接待申请的申请信息字段
 * @param {Object} object
 * @returns
 */
export function getApplicationByJDSQ(object) {
  return copyObj(
    object,
    'confName',
    'arriveTime',
    'leaveTime',
    'payType',
    'corp',
    'peopleNum',
    'appRemarks'
  )
}

/**
 * 接待申请的住房情况字段
 * @param {Object} object
 * @returns
 */
export function getHouseCaseByJDSQ(object) {
  return copyObj(
    object,
    'appRoomsInfos',
    'actRoomsInfos',
    'roomsBudget',
    'actRoomsCostTotal'
  )
}

/**
 * 接待申请的餐饮情况字段
 * @param {Object} object
 * @returns
 */
export function getCaterCaseByJDSQ(object) {
  return copyObj(object, 'appMealsInfos', 'actMealsInfos', 'actMealsTotal')
}

/**
 * 接待申请的会务情况
 * @param {Object} object
 * @returns
 */
export function getConferenceCaseByJDSQ(object) {
  return copyObj(object, 'appConfsInfos', 'actConfsInfos', 'actConferenceTotal')
}

/**
 * 接待申请高尔夫情况
 * @param {Object} object
 * @returns
 */
export function getGolfeCaseByJDSQ(object) {
  return copyObj(
    object,
    'appGolfInfos',
    'actGolfInfos',
    'golfBudgetTotal',
    'actGolfTotal'
  )
}

/**
 * 接待申请的接送情况字段
 * @param {Object} object
 * @returns
 */
export function getReceptCaseByJDSQ(object) {
  return copyObj(
    object,
    'transPrice',
    'transNum',
    'settlementExpenses',
    'otherCost',
    'transTotal'
  )
}

/**
 * 接待申请的备注情况字段
 * @param {Object} object
 * @returns
 */
export function getRemarkByJDSQ(object) {
  return copyObj(object, 'transRemarks')
}

// 总结单费用新对应的枚举id
export const feeEnumIds = {
  booth: '7b6ea267a08c4a75a86600a636c08ea5', //展位费
  meetingroom: '15860b8c230341939541dd462390f8b8', //会议室
  make: '8580e02906d34cf6947713d320b37ee6', //制作费
  design: '4e69755766ff4164b54a29e31d481073', // 设计费
  flight: '266a4df6544e40dfb2d6621526f7ef2c', // 飞机费
  train: 'ff10c80a30454d44946e188709d96c4c', //高铁费
  reception: 'b29af06c171e44889a31cd83180d4604', // 接送费用
  accommodation: '35c8824f88584c5a8b87c8e2577a8e16', // 住宿费
  meals: '29b5bcb099424706838f55258c635295', // 餐费
  thirdparty: '3abc10aa2af34e12a1f1d108c58e6612', // 第三方会务人员费用
  service: '3ccf95c8ba594015a4abc3aaf09bb0df', // 会务服务费、税费
  cooperation: 'c05af4ed11f2453885938a033f5df334', // 协办费用
  consult: '2750a840b7ee412d97b367a5c57f6821', // 讲者咨询费
  brandpre: 'bf3d13606d2347a9abe0a173cbe2ae37', // 推广物料费
  infoPubAmount: '8f6100f204e44c54bd15296f9aa15558', //信息发布费
  onlineLiveBroadcastingFee: '6ae809dd0cb044119d5746514005af7f', // 线上直播平台费用
  otherFee: 'f8e34959209342228bbef8d93d80e1b4', // 其他费用
  registerFee: 'f7a6a3b2e2f24131a4b53fe8ff1acf3b' // 注册费用
}

/**
 * crm的代理地址
 */
export function getCrmHttp() {
  const ENV = process.env.VUE_APP_MODE.toLowerCase()
  switch (ENV) {
    case 'production':
      return 'http://crm.dyg.com.cn'
    case 'development':
      return 'https://crmtest.dyg.com.cn:7090'
    case 'test':
      return 'https://crmtest.dyg.com.cn:7090'
    default:
      return 'https://crmtest.dyg.com.cn:7090'
  }
}
