var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-menu',{staticClass:"menu-list",attrs:{"selectedKeys":_vm.selectedKeys,"default-open-keys":['workplace'],"mode":"inline","theme":"dark","openKeys":_vm.openKeys},on:{"update:openKeys":function($event){_vm.openKeys=$event},"update:open-keys":function($event){_vm.openKeys=$event}}},[_vm._l((_vm.authMenus),function(ref){
var key = ref.key;
var icon = ref.icon;
var svgicon = ref.svgicon;
var title = ref.title;
var submenus = ref.submenus;
return [(submenus && submenus.length > 0)?_c('a-sub-menu',{key:key},[_c('span',{attrs:{"slot":"title"},slot:"title"},[(icon)?_c('icon-font',{attrs:{"type":icon}}):_vm._e(),_c('span',[_vm._v(_vm._s(title))])],1),_vm._l((submenus),function(ref){
var subKey = ref.key;
var subTitle = ref.title;
return _c('a-menu-item',{key:subKey,staticClass:"sub-item",on:{"click":function($event){return _vm.handleMenuClick(subKey)}}},[_c('span',[_vm._v(_vm._s(subTitle))])])})],2):[_c('a-menu-item',{key:key,on:{"click":function($event){return _vm.handleMenuClick(key)}}},[(icon)?_c('icon-font',{attrs:{"type":icon}}):_vm._e(),_c('span',[(svgicon)?_c('icon-svg',{attrs:{"icon":svgicon,"size":"14px"}}):_vm._e(),_c('span',[_vm._v(_vm._s(title))])],1)],1)]]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }