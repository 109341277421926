<!-- 药连锁房产管理模块 -->
<template>
  <!-- 内容区域 -->
  <div>
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="[]">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'house'
}
</script>
