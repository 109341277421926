import { message } from 'ant-design-vue'

/**
 * 函数防抖 (只执行最后一次点击)
 * @param fn
 * @param delay
 * @returns {Function}
 * @constructor
 */
export const debounce = (fn, t) => {
  let delay = t || 500
  let timer
  return function () {
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      timer = null
      fn.apply(this, args)
    }, delay)
  }
}

/**
 * 函数节流(在规定的时间间隔内执行一次)
 * @param fn
 * @param interval
 * @returns {Function}
 * @constructor
 */
export const throttle = (fn, t) => {
  let last
  let timer
  let interval = t || 500
  return function () {
    let args = arguments
    let now = +new Date()
    if (last && now - last < interval) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        last = now
        fn.apply(this, args)
      }, interval)
    } else {
      last = now
      fn.apply(this, args)
    }
  }
}

export function randomKey(length) {
  let key = ''
  const str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  for (let i = 0; i < length; i++) {
    key += str[Math.floor(Math.random() * str.length)]
  }
  return key
}

/**
 * 将金额的阿拉伯数字转换成大写
 * @param {String} m 金额的小写
 */
export function capitalAmount(money) {
  //汉字的数字
  let cnNums = new Array(
    '零',
    '壹',
    '贰',
    '叁',
    '肆',
    '伍',
    '陆',
    '柒',
    '捌',
    '玖'
  )
  //基本单位
  let cnIntRadice = new Array('', '拾', '佰', '仟')
  //对应整数部分扩展单位
  let cnIntUnits = new Array('', '万', '亿', '兆')
  //对应小数部分单位
  let cnDecUnits = new Array('角', '分', '毫', '厘')
  //整数金额时后面跟的字符
  let cnInteger = '整'
  //整型完以后的单位
  let cnIntLast = '元'
  //最大处理的数字
  // eslint-disable-next-line no-loss-of-precision
  let maxNum = 999999999999999.9999
  //金额整数部分
  let integerNum
  //金额小数部分
  let decimalNum
  //输出的中文金额字符串
  let chineseStr = ''
  //分离金额后用的数组，预定义
  let parts
  if (money == '') {
    return ''
  }
  money = parseFloat(money)
  if (money >= maxNum) {
    //超出最大处理数字
    return ''
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  //转换为字符串
  money = money.toString()
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0
    let IntLen = integerNum.length
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1)
      let p = IntLen - i - 1
      let q = p / 4
      let m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        //归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    chineseStr += cnIntLast
  }
  //小数部分
  if (decimalNum != '') {
    let decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1)
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}

/**
 * 判断数组或对象是否为空
 * @param {Object} value
 */
export function isEmpty(value) {
  return (
    (Array.isArray(value) && value.length === 0) ||
    (Object.prototype.isPrototypeOf.call(value) &&
      Object.keys(value).length === 0)
  )
}

/**
 * 活动申请单和报销单是否可以点击,状态为1,5时，不可点击
 * @param {String} status
 */
export function isDisabled(status) {
  if (status && status !== '1' && status !== '5' && status !== '98') return true
  else return false
}

//根据业务类型判断产品
export function checkBusinessTypeName(value) {
  if (value) {
    return true
  }

  return true
}

/**
 * 判断学术推广活动和品牌信息发布（1：表示学术推广活动）
 * @param {Object} hostingTypeDto
 */
export function getHostingType(hostingTypeDto) {
  if (!hostingTypeDto) return 1

  let active = false
  let purtive = false
  if (hostingTypeDto.name.indexOf('学术推广活动') !== -1) active = true
  if (hostingTypeDto.name.indexOf('品牌信息发布') !== -1) purtive = true

  if (active && !purtive) return 2 //举办类型为学术推广
  if (!active && purtive) return 3 //举办类型为品牌信息
  if (active && purtive) return 4 //举办类型为学术推广+品牌信息

  return 1 //举办类型为空
}

/**
 * 判断申请单中必填的字段是否有填
 * @param {Object} apply
 */
export function validator(apply) {
  const ht = getHostingType(apply.hostingTypeDTO)
  const isActive = [1, 2, 4].includes(ht)
  const isPurtive = [3, 4].includes(ht)

  if (!apply.applicant) {
    message.error('申请人不能为空')
    return false
  }
  if (!apply.post) {
    message.error('岗位不能为空')
    return false
  }
  if (!apply.contact) {
    message.error('联系方式不能为空')
    return false
  }
  if (!apply.department) {
    message.error('部门不能为空')
    return false
  }
  if (!apply.conferenceType) {
    message.error('所属公司不能为空')
    return false
  }
  if (apply.conferenceTypeDTO.name === '总部' && !apply.hostingType) {
    message.error('举办类型不能为空')
    return false
  }
  if (!apply.businessType) {
    message.error('业务类型不能为空')
    return false
  } else {
    if (checkBusinessTypeName(apply.businessTypeDTO)) {
      if (!apply.product) {
        message.error('产品不能为空')
        return false
      }
    }
  }
  if (!apply.activityStdate) {
    message.error('开始日期不能为空')
    return false
  }
  if (!apply.activityEddate) {
    message.error('结束日期不能为空')
    return false
  }

  if (isActive && !apply.activityPlace) {
    message.error('活动地点不能为空')
    return false
  }
  if (isActive && !apply.activityTheme) {
    message.error('活动主题不能为空')
    return false
  }
  if (isActive && !apply.activityPurpose) {
    message.error('活动目的不能为空')
    return false
  }
  if (isActive && !apply.activityNatrue) {
    message.error('举办性质不能为空')
    return false
  }
  if (
    isActive &&
    apply.activityNatrue.indexOf('协办') !== -1 &&
    !apply.sponsor
  ) {
    message.error('主办方名称不能为空')
    return false
  }
  if (
    isActive &&
    apply.activityNatrue.indexOf('协办') !== -1 &&
    !apply.sponsorEstdate
  ) {
    message.error('成立日期不能为空')
    return false
  }
  if (
    isActive &&
    apply.activityNatrue.indexOf('协办') !== -1 &&
    !apply.budgetCooperationTotal
  ) {
    message.error('协办金额不能为空')
    return false
  }
  if (
    isActive &&
    apply.activityNatrue.indexOf('协办') !== -1 &&
    !apply.sponsorQualification
  ) {
    message.error('请上传主办方资质证明附件')
    return false
  }
  if (
    isActive &&
    apply.activityNatrue.indexOf('协办') !== -1 &&
    !apply.sponsorInvitation
  ) {
    message.error('请上传主办方邀请函附件')
    return false
  }
  if (
    isActive &&
    apply.activityNatrue.indexOf('协办') !== -1 &&
    !apply.contract
  ) {
    message.error('请上传已签批协办协议/合同附件')
    return false
  }

  if (isActive && !apply.preinvitednum && apply.preinvitednum != 0) {
    message.error('预邀客户不能为空')
    return false
  }
  if (
    isActive &&
    !(
      apply.activityNatrue === '自办' &&
      apply.hasconfContract === '否' &&
      apply.otherContract === '否'
    )
  ) {
    if (!apply.budgetPreCost && apply.budgetPreCost != 0) {
      {
        message.error('预付金额不能为空')
        return false
      }
    }
  }

  if (isActive && !apply.internalnum && apply.internalnum != 0) {
    message.error('内部人员人数不能为空')
    return false
  }
  if (isPurtive && !apply.pubTheme) {
    message.error('请填写品牌信息-发布主题')
    return false
  }
  if (isPurtive && !apply.pubPurpose) {
    message.error('请填写品牌信息-发布目的')
    return false
  }
  if (isActive && !apply.participantsList) {
    message.error('请上传预邀参会人员名单附件')
    return false
  }
  if (isActive && !apply.activityInvitation) {
    message.error('请上传自办活动议程和邀请函附件')
    return false
  }
  if (isPurtive && !apply.pubScheme) {
    message.error('请上传品牌信息-发布方案附件')
    return false
  }
  if (isPurtive && !apply.partnerContract) {
    message.error('请上传品牌信息-发布合同附件')
    return false
  }
  if (isActive && !apply.hasconfContract) {
    message.error('是否有会务合同选项不能为空')
    return false
  }
  if (isActive && apply.hasconfContract === '是' && !apply.confCompany) {
    message.error('会务公司名称不能为空')
    return false
  }
  if (isActive && apply.hasconfContract === '是' && !apply.contractMoney) {
    message.error('合同金额不能为空')
    return false
  }
  if (
    isActive &&
    (apply.hasconfContract === '是' || apply.otherContract === '是') &&
    !apply.contractScan
  ) {
    message.error('请上传已签批合同扫描件附件')
    return false
  }

  if (isActive) {
    if (!apply.speakerNum && apply.speakerNum != 0) {
      message.error('讲者人数不能为空')
      return false
    } else {
      for (let item of apply.speakers) {
        const { appConsultingFee, topicname, lengthoflecture, name } = item
        if (appConsultingFee === null || appConsultingFee === '') {
          message.error(`讲者信息-${name}：咨询费不能为空`)
          return false
        }
        if (!topicname) {
          message.error(`讲者信息-${name}：课题名称不能为空`)
          return false
        }
        if (!lengthoflecture) {
          message.error(`讲者信息-${name}：讲课时长不能为空`)
          return false
        }
      }
    }
  }
  if (isActive) {
    if (apply.brandpreInfoDTOs && apply.isbrandpre === '是') {
      for (let item of apply.brandpreInfoDTOs) {
        const { brandpreNumApp, materialName } = item
        if (!brandpreNumApp || brandpreNumApp === '0') {
          message.error(`推广物料-${materialName}：申请数量不能为空`)
          return false
        }
      }
    }
  }

  if (isPurtive) {
    if (apply.expenseDetailDTOs && apply.expenseDetailDTOs.length) {
      for (let item of apply.expenseDetailDTOs) {
        const { copAmount, partnerName } = item
        if (!partnerName) {
          message.error(`合作方名称不能为空`)
          return false
        }
        if (copAmount != 0 && !copAmount) {
          message.error(`合作金额不能为空`)
          return false
        }
      }
    } else {
      message.error(`未填写合作信息`)
      return false
    }
  }

  if (isActive && !apply.activitytype) {
    message.error('请选择活动类型及执行标准')
    return false
  }

  if (
    !apply.budgetBooth &&
    apply.budgetBooth != 0 &&
    apply.conferenceTypeDTO.name !== '地区'
  ) {
    message.error('展位费单价不能为空')
    return false
  }
  if (
    !apply.budgetBoothNum &&
    apply.budgetBoothNum != 0 &&
    apply.conferenceTypeDTO.name !== '地区'
  ) {
    message.error('展位费数量不能为空')
    return false
  }
  if (!apply.budgetMeetingroom && apply.budgetMeetingroom != 0) {
    message.error('会议费单价不能为空')
    return false
  }
  if (!apply.budgetMeetingroomNum && apply.budgetMeetingroomNum != 0) {
    message.error('会议费数量不能为空')
    return false
  }
  if (!apply.budgetMake && apply.budgetMake != 0) {
    message.error('制作费单价不能为空')
    return false
  }
  if (!apply.budgetMakeNum && apply.budgetMakeNum != 0) {
    message.error('制作费数量不能为空')
    return false
  }
  if (!apply.budgetDesign && apply.budgetDesign != 0) {
    message.error('设计费单价不能为空')
    return false
  }
  if (!apply.budgetDesignNum && apply.budgetDesignNum != 0) {
    message.error('设计费数量不能为空')
    return false
  }
  if (!apply.budgetFlight && apply.budgetFlight != 0) {
    message.error('飞机费单价不能为空')
    return false
  }
  if (!apply.budgetFlightNum && apply.budgetFlightNum != 0) {
    message.error('飞机费数量不能为空')
    return false
  }
  if (!apply.budgetTrain && apply.budgetTrain != 0) {
    message.error('高铁费单价不能为空')
    return false
  }
  if (!apply.budgetTrainNum && apply.budgetTrainNum != 0) {
    message.error('高铁费数量不能为空')
    return false
  }
  if (!apply.budgetReception && apply.budgetReception != 0) {
    message.error('接送费单价不能为空')
    return false
  }
  if (!apply.budgetReceptionNum && apply.budgetReceptionNum != 0) {
    message.error('接送费数量不能为空')
    return false
  }
  if (!apply.budgetAccommodation && apply.budgetAccommodation != 0) {
    message.error('住宿费单价不能为空')
    return false
  }
  if (!apply.budgetAccommodationNum && apply.budgetAccommodationNum != 0) {
    message.error('住宿费数量不能为空')
    return false
  }
  if (!apply.budgetMeals && apply.budgetMeals != 0) {
    message.error('餐费单价不能为空')
    return false
  }
  if (!apply.budgetMealsNum && apply.budgetMealsNum != 0) {
    message.error('餐费数量不能为空')
    return false
  }
  if (!apply.budgetThirdparty && apply.budgetThirdparty != 0) {
    message.error('第三方会务人员费用单价不能为空')
    return false
  }
  if (!apply.budgetThirdpartyNum && apply.budgetThirdpartyNum != 0) {
    message.error('第三方会务人员费用数量不能为空')
    return false
  }
  if (!apply.budgetServiceTotal && apply.budgetServiceTotal != 0) {
    message.error('会务服务费、税费不能为空')
    return false
  }
  if (!apply.otherFee && apply.otherFee != 0) {
    message.error('其他费用不能为空')
    return false
  }
  if (!apply.registerFee && apply.registerFee != 0) {
    message.error('注册费不能为空')
    return false
  }
  if (isActive) {
    if (
      !apply.onlineLiveBroadcastingFee &&
      apply.onlineLiveBroadcastingFee != 0
    ) {
      message.error('线上直播平台不能为空')
      return false
    }
  }
  if (
    !apply.budgetCooperationTotal &&
    apply.budgetCooperationTotal != 0 &&
    apply.activityNatrue !== '自办' &&
    apply.conferenceTypeDTO.name !== '地区'
  ) {
    message.error('协办费用不能为空')
    return false
  }
  return true
}

/**
 * 判断总结单中必填的字段是否有填
 * @param {Object} apply
 */
export function verifier(apply) {
  const ht = getHostingType(apply.hostingTypeDTO)
  const isActive = [1, 2, 4].includes(ht)
  const isPurtive = [3, 4].includes(ht)

  if (!apply.department) {
    message.error('部门不能呢为空')
    return false
  }
  if (!apply.conferenceType) {
    message.error('会议类型不能呢为空')
    return false
  }
  if (!apply.businessType) {
    message.error('业务类型不能为空')
    return false
  }
  if (!apply.applicant) {
    message.error('申请人不能为空')
    return false
  }
  if (!apply.post) {
    message.error('岗位不能为空')
    return false
  }
  if (!apply.contact) {
    message.error('联系方式不能为空')
    return false
  }
  if (!apply.actualActivityStdate) {
    message.error('实际开始时间不能为空')
    return false
  }
  if (!apply.actualActivityEddate) {
    message.error('实际结束时间不能为空')
    return false
  }
  if (isActive && !apply.reimTurnout && apply.reimTurnout != 0) {
    message.error('参会人员到场人数不能为空')
    return false
  }
  if (isActive && !apply.internalnum && apply.internalnum != 0) {
    message.error('内部人员到场人数不能为空')
    return false
  }
  if (isActive && !apply.actualActivityPlace) {
    message.error('实际活动地点不能为空')
    return false
  }
  if (isActive && !apply.signinForm) {
    message.error('请上传签到表附件')
    return false
  }
  // if (isActive && !apply.pictures) {
  //   message.error('请上传现场照片附件')
  //   return false
  // }
  if (isActive && !apply.activitySummary) {
    message.error('请上传活动总结附件')
    return false
  }

  if (
    isActive &&
    apply.activityNatrue.indexOf('协办') !== -1 &&
    !apply.cooperationInfo
  ) {
    message.error('请上传协办第三方资料附件')
    return false
  }

  if (isPurtive && !apply.articleNetworkLink) {
    message.error('请信息发布-文章网络连接附件')
    return false
  }
  if (isPurtive && !apply.projectSummary) {
    message.error('请信息发布-项目总结附件')
    return false
  }

  // if (apply.billInfos && apply.billInfos.length) {
  //   for (let index = 0; index < apply.billInfos.length; index++) {
  //     if (!apply.billInfos[index].invoiceFeeType) {
  //       message.error(`发票信息-费用项目信息第${index + 1}项不能为空`)
  //       return false
  //     }
  //   }
  // }

  if (isActive) {
    if (apply.speakers && apply.speakers.length !== 0) {
      for (let item of apply.speakers) {
        const { appConsultingFee, topicname, lengthoflecture, name } = item
        if (appConsultingFee === null || appConsultingFee === '') {
          message.error(`实际到场的讲者信息-${name}：咨询费不能为空`)
          return false
        }
        if (!topicname) {
          message.error(`实际到场的讲者信息-${name}：课题名称不能为空`)
          return false
        }
        if (!lengthoflecture) {
          message.error(`实际到场的讲者信息-${name}：讲课时长不能为空`)
          return false
        }
      }
    }
  }

  if (isActive) {
    if (apply.brandpreInfoDTOs && apply.brandpreInfoDTOs.length !== 0) {
      for (let item of apply.brandpreInfoDTOs) {
        const { brandpreNumApp, materialName } = item
        if (!brandpreNumApp || brandpreNumApp === '0') {
          message.error(`实际推广物料-${materialName}：实际数量不能为0或空`)
          return false
        }
      }
    }
  }

  // if (isActive && apply.activityNatrue.indexOf('协办') !== -1) {
  //   if (!apply.cooptBillInfos || !apply.cooptBillInfos.length) {
  //     message.error('请添加协办第三方发票')
  //     return false
  //   }
  // }

  if (isPurtive) {
    if (apply.expenseDetailDTOs && apply.expenseDetailDTOs.length) {
      for (let item of apply.expenseDetailDTOs) {
        const { copAmount, partnerName } = item
        if (!partnerName) {
          message.error(`合作方名称不能为空`)
          return false
        }
        if (copAmount != 0 && !copAmount) {
          message.error(`合作金额不能为空`)
          return false
        }
      }
    } else {
      message.error(`未填写合作信息`)
      return false
    }
  }

  if (
    !apply.budgetBooth &&
    apply.budgetBooth != 0 &&
    apply.conferenceTypeDTO.name !== '地区'
  ) {
    message.error('展位费单价不能为空')
    return false
  }
  if (
    !apply.budgetBoothNum &&
    apply.budgetBoothNum != 0 &&
    apply.conferenceTypeDTO.name !== '地区'
  ) {
    message.error('展位费数量不能为空')
    return false
  }
  if (!apply.budgetMeetingroom && apply.budgetMeetingroom != 0) {
    message.error('会议费单价不能为空')
    return false
  }
  if (!apply.budgetMeetingroomNum && apply.budgetMeetingroomNum != 0) {
    message.error('会议费数量不能为空')
    return false
  }
  if (!apply.budgetMake && apply.budgetMake != 0) {
    message.error('制作费单价不能为空')
    return false
  }
  if (!apply.budgetMakeNum && apply.budgetMakeNum != 0) {
    message.error('制作费数量不能为空')
    return false
  }
  if (!apply.budgetDesign && apply.budgetDesign != 0) {
    message.error('设计费单价不能为空')
    return false
  }
  if (!apply.budgetDesignNum && apply.budgetDesignNum != 0) {
    message.error('设计费数量不能为空')
    return false
  }
  if (!apply.budgetFlight && apply.budgetFlight != 0) {
    message.error('飞机费单价不能为空')
    return false
  }
  if (!apply.budgetFlightNum && apply.budgetFlightNum != 0) {
    message.error('飞机费数量不能为空')
    return false
  }
  if (!apply.budgetTrain && apply.budgetTrain != 0) {
    message.error('高铁费单价不能为空')
    return false
  }
  if (!apply.budgetTrainNum && apply.budgetTrainNum != 0) {
    message.error('高铁费数量不能为空')
    return false
  }
  if (!apply.budgetReception && apply.budgetReception != 0) {
    message.error('接送费单价不能为空')
    return false
  }
  if (!apply.budgetReceptionNum && apply.budgetReceptionNum != 0) {
    message.error('接送费数量不能为空')
    return false
  }
  if (!apply.budgetAccommodation && apply.budgetAccommodation != 0) {
    message.error('住宿费单价不能为空')
    return false
  }
  if (!apply.budgetAccommodationNum && apply.budgetAccommodationNum != 0) {
    message.error('住宿费数量不能为空')
    return false
  }
  if (!apply.budgetMeals && apply.budgetMeals != 0) {
    message.error('餐费单价不能为空')
    return false
  }
  if (!apply.budgetMealsNum && apply.budgetMealsNum != 0) {
    message.error('餐费数量不能为空')
    return false
  }
  if (!apply.budgetThirdparty && apply.budgetThirdparty != 0) {
    message.error('第三方会务人员费用单价不能为空')
    return false
  }
  if (!apply.budgetThirdpartyNum && apply.budgetThirdpartyNum != 0) {
    message.error('第三方会务人员费用数量不能为空')
    return false
  }
  if (!apply.budgetServiceTotal && apply.budgetServiceTotal != 0) {
    message.error('会务服务费、税费不能为空')
    return false
  }
  if (!apply.otherFee && apply.otherFee != 0) {
    message.error('其他费用不能为空')
    return false
  }
  if (!apply.registerFee && apply.registerFee != 0) {
    message.error('注册费不能为空')
    return false
  }
  if (isActive) {
    if (
      !apply.onlineLiveBroadcastingFee &&
      apply.onlineLiveBroadcastingFee != 0
    ) {
      message.error('会务服务费、税费不能为空')
      return false
    }
  }
  return true
}

/**
 * 判断预付款单、对公付款单和对私付款单中必填的字段是否有填
 * @param {Object} apply
 */
export function payVerifier(bill, type) {
  if (bill.applyMoney === '') {
    message.error('申请金额不能为空')
    return false
  }
  if (!bill.settleType) {
    message.error('结算方式不能为空')
    return false
  }
  if (type === 'DSFK') {
    if (!bill.receName) {
      message.error('收款人不能为空')
      return false
    }
    if (!bill.payName) {
      message.error('付款单位不能为空')
      return false
    }
  } else {
    if (!bill.receName) {
      message.error('收款单位不能为空')
      return false
    }
    if (!bill.payName) {
      message.error('付款单位不能为空')
      return false
    }
  }
  return true
}
