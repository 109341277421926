import { render, staticRenderFns } from "./XsbxPrint.vue?vue&type=template&id=dc1ee254&scoped=true"
import script from "./XsbxPrint.vue?vue&type=script&lang=js"
export * from "./XsbxPrint.vue?vue&type=script&lang=js"
import style0 from "./XsbxPrint.vue?vue&type=style&index=0&id=dc1ee254&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc1ee254",
  null
  
)

export default component.exports