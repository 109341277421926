export const SIDER_MENU_LIST = [
  {
    key: 'workplace',
    title: '工作台',
    icon: 'icon_workspace_'
  },
  {
    key: 'user',
    title: '用户',
    icon: 'icon_user_',
    submenus: [
      {
        key: 'groupManage',
        title: '组织管理'
      },
      {
        key: 'userManage',
        title: '用户管理'
      },
      {
        key: 'roleManage',
        title: '角色管理'
      },
      {
        key: 'postManage',
        title: '岗位管理'
      }
    ]
  },
  {
    key: 'customer',
    title: '客户',
    icon: 'icon_customer_',
    submenus: [
      {
        key: 'hospitalManage',
        title: '医院管理'
      },
      {
        key: 'doctorManage',
        title: '医生管理'
      },
      {
        key: 'speakerManage',
        title: '讲者管理'
      },
      {
        key: 'hospitalPcApp',
        title: '医院变更申请'
      },
      {
        key: 'doctorPcApp',
        title: '医生变更申请'
      },
      {
        key: 'speakerPcApp',
        title: '讲者变更申请'
      },
      {
        key: 'appModify',
        title: '变更申请查询'
      },
      {
        key: 'dataMerge',
        title: '数据合并'
      },
      {
        key: 'chnlArea',
        title: '学术业务区域'
      }
    ]
  },
  {
    key: 'queryApproveBills',
    title: '查询',
    icon: 'icon_search_',
    submenus: [
      {
        key: 'queryActivityApplication',
        title: '学术推广活动申请单'
      },
      {
        key: 'queryActivityReimbursement',
        title: '学术推广活动总结单'
      },
      {
        key: 'nonMeetingAppQuery',
        title: '非会议学术项目申请单'
      },
      {
        key: 'queryYFPayment',
        title: '预付款单'
      },
      {
        key: 'queryDGPayment',
        title: '对公付款单'
      },
      {
        key: 'queryDSPayment',
        title: '对私报销单'
      },
      {
        key: 'jxBxQuery',
        title: '对公付款会计审核'
      },
      {
        key: 'monthlySalesTargetQuery',
        title: '月销量指标查询'
      },
      {
        key: 'chsClerkCostDetail',
        title: '推广费用明细查询'
      },
      {
        key: 'recAppQuery',
        title: '接待申请查询'
      },
      {
        key: 'rentHouseAppQuery',
        title: '药连锁办事处用房租赁申请单'
      },
      {
        key: 'houseRegisterQuery',
        title: '药连锁自有房产登记单'
      },
      {
        key: 'houseDormitoryQuery',
        title: '药连锁公司驻地宿舍申请单'
      },
      {
        key: 'housePaymentDGQuery',
        title: '药连锁房产对公付款单'
      },
      {
        key: 'housePaymentDSQuery',
        title: '药连锁房产对私报销单'
      },
      {
        key: 'housePropertyArchiveModifyQuery',
        title: '药连锁房产续租/变更申请单'
      },
      {
        key: 'hcvApplicationQuery',
        title: '丙肝送检公费申请查询'
      },
      {
        key: 'hcvWriteOffQuery',
        title: '丙肝送检公费核销查询'
      }
    ]
  },
  {
    key: 'product',
    title: '产品',
    icon: 'icon_product_',
    submenus: [
      {
        key: 'productManage',
        title: '产品线管理'
      },
      {
        key: 'productKeyInfoManage',
        title: '产品关键信息'
      },
      {
        key: 'drugForm',
        title: '产品管理'
      }
    ]
  },
  {
    key: 'saleManage',
    title: '销售管理',
    icon: 'icon_salemanager_',
    submenus: [
      {
        key: 'monthlySalesTarget',
        title: '月销量指标'
      },
      {
        key: 'provinceIndex',
        title: '各省产品销售指标'
      },
      {
        key: 'indexMonthProportion',
        title: '各省产品销售指标月份占比'
      },
      {
        key: 'hospitalSaleIndex',
        title: '目标医院销售指标'
      },
      {
        key: 'receiveMnyConfirm',
        title: '收款确认'
      },
      {
        key: 'chsDigitContract',
        title: '数字合同'
      },
      {
        key: 'chsClerkMonthInv',
        title: '业务员月盘存'
      },
      {
        key: 'chsClearInv',
        title: '清算盘存'
      },
      {
        key: 'businessRefundContract',
        title: '非学术商业返款协议'
      },
      {
        key: 'partnerAgreementRefund',
        title: '新零售销售费用'
      },
      {
        key: 'nonAcademicRefundContract',
        title: '非学术协议返款'
      },
      {
        key: 'proofOfDelivery',
        title: '物流回执'
      },
      {
        key: 'salesArea',
        title: '销售区域'
      },
      {
        key: 'postDoctor',
        title: '岗位医生'
      },
      {
        key: 'targetDoctorManage',
        title: '目标医生管理'
      },
      {
        key: 'visit',
        title: '拜访'
      },
      {
        key: 'followUpVisit',
        title: '随访'
      },
      {
        key: 'buqianPcApp',
        title: '补签申请'
      },
      {
        key: 'outsideTime',
        title: '区域外时间'
      },
      {
        key: 'storeManage',
        title: '干鲜草门店档案'
      },
      {
        key: 'targetHospApprove',
        title: '目标医院'
      },
      {
        key: 'documentManage',
        title: '文档管理'
      },
      {
        key: 'messageManagement',
        title: '公告消息'
      }
    ]
  },
  {
    key: 'genericDrugs',
    title: '仿制药管理',
    icon: 'icon_yaowan',
    submenus: [
      {
        key: 'genericDrugsOpeningInventory',
        title: '商业初期库存表'
      },
      {
        key: 'genericDrugsFlow',
        title: '仿制药流向单'
      },
      {
        key: 'genericDrugsAlloc',
        title: '仿制药调拨单'
      },
      {
        key: 'genericDrugsChnlIndex',
        title: '仿制药终端月指标'
      },
      {
        key: 'genericDrugsZg',
        title: '仿制药省总规'
      },
      {
        key: 'genericDrugsChnlLs',
        title: '仿制药连锁终端清单'
      },
      {
        key: 'genericDrugsDigitContr',
        title: '仿制药数字合同'
      },
      {
        key: 'genericDrugsDevBonusArchive',
        title: '仿制药开发奖金档案'
      },
      {
        key: 'genericDrugsSettleNumCalc',
        title: '仿制药结算数量计提'
      },
      {
        key: 'genericDrugsFeeCalc',
        title: '仿制药费用计提'
      }
    ]
  },
  {
    key: 'flowdirect',
    title: '流向直连',
    icon: 'icon_xiaoshou',
    submenus: [
      {
        key: 'businessManage',
        title: '商业档案管理'
      },
      {
        key: 'varietyBusinessLineManage',
        title: '品种业务线档案'
      },
      {
        key: 'productExceptionManage',
        title: '异常产品档案'
      },
      {
        key: 'areaManage',
        title: '中国行政区域'
      },
      {
        key: 'flowToGrab',
        title: '流向抓取'
      },
      {
        key: 'stockGrab',
        title: '商业库存抓取'
      },
      {
        key: 'warehouseGrab',
        title: '商业入库抓取'
      },
      {
        key: 'synonymManage',
        title: '档案异名管理'
      },
      {
        key: 'hospAppAnalysis',
        title: '医院单据清洗'
      },
      {
        key: 'ncCrmTermiClean',
        title: 'NC-CRM医院清洗'
      },
      {
        key: 'hospModBatch',
        title: '终端同步批处理'
      }
    ]
  },

  {
    key: 'outerSpec',
    title: '外部品种',
    icon: 'icon_pinzhong',
    submenus: [
      {
        key: 'externalShippingCompany',
        title: '外部发货公司'
      },
      {
        key: 'extCompShippingBill',
        title: '外部公司发货单'
      },
      {
        key: 'extSpecBackBill',
        title: '外部品种回款单'
      },
      {
        key: 'extVerificationBill',
        title: '外部回款发货核销单'
      },
      {
        key: 'outerInventory',
        title: '外部品种商业期初'
      },
      {
        key: 'outerHospSpec',
        title: '外部终端品种档案'
      },
      {
        key: 'outerFlowBill',
        title: '外部流向单'
      },
      {
        key: 'outerAllocateBill',
        title: '外部调拨单'
      },
      {
        key: 'outerBackFlowVerify',
        title: '外部发货回款流向核销'
      },
      {
        key: 'outerBusiFeeCalc',
        title: '外部品种业务费用计提'
      },
      {
        key: 'genericDrugsPayAgre',
        title: '付款协议'
      }
    ]
  },
  {
    key: 'activity',
    title: '活动',
    icon: 'icon_activity_',
    submenus: [
      {
        key: 'activityApplication',
        title: '学术推广活动申请'
      },
      {
        key: 'activityReimbursement',
        title: '学术推广活动总结'
      },
      {
        key: 'nonMeetingApp',
        title: '非会议类学术项目申请'
      },
      {
        key: 'activityAppModify',
        title: '学术推广活动变更申请'
      },
      {
        key: 'hcvApplication',
        title: '丙肝送检公费申请单'
      },
      {
        key: 'hcvWriteOff',
        title: '丙肝公费送检核销单'
      },
      {
        key: 'photoManage',
        title: '学术会议照片库'
      },
      {
        key: 'photoManageSimQuery',
        title: '学术会议照片库相似度'
      },
      {
        key: 'activityPhotoRight',
        title: '会议拍照权限'
      },
      {
        key: 'hcvAgencyDoc',
        title: '检测机构单价'
      },
      {
        key: 'brandPresentation',
        title: '推广物料'
      },
      {
        key: 'activityType',
        title: '活动类型及执行标准'
      },
      {
        key: 'datasControlByRole',
        title: '活动类型控制'
      },
      {
        key: 'billHandedOver',
        title: '单据移交'
      },
      {
        key: 'proxyApprove',
        title: '审批移交'
      },
      {
        key: 'batchSync',
        title: '同步批处理'
      }
    ]
  },
  {
    key: 'housePropertyManage',
    title: '房产管理',
    icon: 'icon_home_top',
    submenus: [
      {
        key: 'housePropertyArchive',
        title: '药连锁房产档案'
      },
      {
        key: 'rentHouseApp',
        title: '药连锁办事处用房租赁申请单'
      },
      {
        key: 'houseRegister',
        title: '药连锁自有房产登记单'
      },
      {
        key: 'houseDormitory',
        title: '药连锁公司驻地宿舍申请单'
      },
      {
        key: 'housePropertyArchiveModify',
        title: '药连锁房产续租/变更申请'
      },
      {
        key: 'houseRentFeeCalc',
        title: '药连锁宿舍房租计提'
      },
      {
        key: 'housePaymentDG',
        title: '药连锁对公付款申请单'
      },
      {
        key: 'housePaymentDS',
        title: '药连锁对私付款申请单'
      }
    ]
  },
  {
    key: 'costManage',
    title: '费用发放',
    icon: 'icon_salezone_',
    submenus: [
      {
        key: 'costDeductCompensation',
        title: '业务员费用扣补明细'
      },
      {
        key: 'costSummary',
        title: '业务员费用汇总'
      },
      {
        key: 'localFeeApp',
        title: '省办费用申请'
      },
      {
        key: 'expenseReport',
        title: '费用报表'
      },
      {
        key: 'feeAppQuery',
        title: '费用申请查询'
      }
    ]
  },
  {
    key: 'menuManage',
    title: '系统管理',
    icon: 'icon_systemanage_',
    submenus: [
      {
        key: 'menusManage',
        title: '菜单列表'
      },
      {
        key: 'permissionManage',
        title: '权限管理'
      },
      {
        key: 'defDocListManage',
        title: '枚举类型管理'
      },
      {
        key: 'defDocManage',
        title: '枚举管理'
      },
      {
        key: 'shareManage',
        title: '数据共享'
      }
      // {
      //   key: 'reportFormSettings',
      //   title: '报表设置'
      // }
    ]
  }
]
