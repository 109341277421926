import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

// 目标医院审批接口
/**
 * 查询目标医院审批信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getTargetHospAppList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `targetHospApprove/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 添加目标医院
 * @param {Array} data
 * @returns
 */
export function getAddTargetHosp(data) {
  return request({
    method: 'post',
    url: `targetHospApprove/addTargetHosp`,
    data: data
  })
}

/**
 * 非目标医院
 * @param {Array} data
 * @returns
 */
export function getNotTargetHosp(data) {
  return request({
    method: 'post',
    url: `targetHospApprove/notTargetHosp`,
    data: data
  })
}

// 目标医院管理接口
/**
 * 查询目标医院审批信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getTargetHospMngList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `targetHospMng/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 删除目标医院管理
 * @param {Array} data
 * @returns
 */
export function getDelTargetHospMng(data) {
  return request({
    method: 'post',
    url: `targetHospMng/del`,
    data: data
  })
}

/**
 * 根据id查询目标医院申请
 * @param {String} id
 */
export function getTgHospById(id) {
  return request({
    url: `/targetHospApp/${id}`,
    method: 'get'
  })
}

/**
 * 导出目标医院管理信息
 * @returns
 */
export function exportTargetHospMng(conditions) {
  return request({
    method: 'post',
    url: `targetHospMng/export`,
    responseType: 'arraybuffer',
    data: conditions
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `目标医院管理`)
  })
}
