export default {
  methods: {
    verityAppForm() {
      const canVerityFormItem = [
        ...this.userForm,
        ...this.activityForm,
        ...this.activityTextareaForm,
        ...[{ label: '活动类型', prop: 'activitytype', disabled: false }]
      ].filter(item => !item.disabled)

      // 用户信息、活动信息和活动类型及执行标准校验
      for (const formItem of canVerityFormItem) {
        if (formItem.show && !formItem.show()) continue

        if (!this.form[formItem.prop] && this.form[formItem.prop] != '0') {
          this.$message.error(`${formItem.label}不能为空`)
          return false
        }
      }

      // 活动信息附件校验
      for (const formFileItem of this.activityFileForm) {
        if (formFileItem.disabled && formFileItem.show && !formFileItem.show())
          continue

        if (formFileItem.notVerity) continue

        if (
          !this.form[formFileItem.prop] ||
          this.form[formFileItem.prop] === '[]'
        ) {
          this.$message.error(`请上传${formFileItem.label}附件`)
          return false
        }
      }

      // 实际陪同人员校验
      for (const accompany of this.form.appAccompanyingPersonsDtos) {
        if (accompany.accompanyBudget === '') {
          this.$message.error(
            `${accompany.personName}(${accompany.personCode}) - 陪同预算不能为空`
          )
          return false
        }
      }

      // 实际讲者校验
      for (const speaker of this.form.speakers) {
        if (!speaker.appConsultingFee && speaker.appConsultingFee != '0') {
          this.$message.error(
            `${speaker.name}(${speaker.code}) - 讲者劳务费不能为空`
          )
          return false
        }

        if (!speaker.topicname) {
          this.$message.error(
            `${speaker.name}(${speaker.code}) - 讲者课题不能为空`
          )
          return false
        }

        if (!speaker.lengthoflecture && speaker.lengthoflecture != '0') {
          this.$message.error(
            `${speaker.name}(${speaker.code}) - 讲者时长不能为空`
          )
          return false
        }
      }

      // 费用信息校验
      for (const feeItem of this.feeDatas.filter(item => !item.notVerity)) {
        if (feeItem.price === '~') {
          if (!this.form[feeItem.total] && this.form[feeItem.total] != '0') {
            this.$message.error(`${feeItem.title}总价不能为空`)
            return false
          }
        } else {
          if (!this.form[feeItem.price] && this.form[feeItem.price] != '0') {
            this.$message.error(`${feeItem.title}单价不能为空`)
            return false
          }

          if (!this.form[feeItem.num] && this.form[feeItem.num] != '0') {
            this.$message.error(`${feeItem.title}数量不能为空`)
            return false
          }
        }
      }

      return true
    }
  }
}
