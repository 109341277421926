import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 分页查询非学术协议返款信息（表头）
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getNonacademicList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `nonAcademicRefundContract/app/head/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 查询非学术协议返款信息(表体)
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getNonacademicBodyList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `nonAcademicRefundContract/app/body/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 分页查询非学术协议返款信息（申请列表）
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getNonacademicApplicationList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `nonAcademicRefundContract/head/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 删除非学术协议返款
 * @param {String} id
 * @returns
 */
export function delNonacademic(id) {
  return request({
    method: 'post',
    url: `nonAcademicRefundContract/del/${id}`
  })
}

/**
 * 根据ID来非学术协议返款信息
 * @param {String} id
 * @returns
 */
export function getNonacademic(id) {
  return request({
    method: 'get',
    url: `nonAcademicRefundContract/${id}`
  })
}

/**
 * 保存非学术协议返款
 * @param {Object} data
 * @returns
 */
export function saveNonacademic(data) {
  return request({
    method: 'post',
    url: `nonAcademicRefundContract/save`,
    data
  })
}

/**
 * 更新非学术协议返款
 * @param {Object} data
 * @returns
 */
export function updateNonacademic(data) {
  return request({
    method: 'post',
    url: `nonAcademicRefundContract/update`,
    data
  })
}

/**
 * 提交非学术协议返款
 * @param {Object} data
 * @returns
 */
export function submitNonacademic(data) {
  return request({
    method: 'put',
    url: `oaflow/submitNonAcademicRefundContract`,
    data
  })
}

/**
 * 导出非学术协议返款信息
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/nonAcademicRefundContract/export`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `非学术协议返款列表`)
  })
}
