<template>
  <a-date-picker
    style="width: 100%"
    :disabled="isDisabled"
    :allowClear="false"
    :value="
      record[column.dataIndex]
        ? moment(record[column.dataIndex], 'YYYY-MM-DD')
        : ''
    "
    @change="onDateChange(arguments, record, column)"
  />
</template>

<script>
import moment from 'moment'
export default {
  name: 'InputCell',
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    column: {
      type: Object,
      default: () => ({})
    },

    isDisabled: Boolean
  },

  methods: {
    moment,

    onDateChange(args, record, column) {
      const dateString = args[1]
      record[column.dataIndex] = dateString
    }
  }
}
</script>

<style lang="less" scoped></style>
