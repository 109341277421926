import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 丙肝公费送检核销单列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getHcvReimList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `hcv/writeOff/page/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 丙肝公费送检核销单列表(查询模块中使用)
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getHcvReimQueryList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `hcv/writeOff/getQueryList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新丙肝公费送检核销单
 * @param {Object} data
 * @returns
 */
export function updateHcvReim(data) {
  return request({
    method: 'post',
    url: `hcv/writeOff/update`,
    data
  })
}

/**
 * 保存丙肝公费送检核销单
 * @param {Object} data
 * @returns
 */
export function saveHcvReim(data) {
  return request({
    method: 'post',
    url: `hcv/writeOff/add`,
    data
  })
}

/**
 * 提交丙肝公费送检核销单
 * @param {Object} data
 * @returns
 */
export function submitHcvReim(data) {
  return request({
    method: 'post',
    url: `oaflow/submitHCVWriteOff`,
    data
  })
}

/**
 * 删除丙肝公费送检核销单
 * @param {String} id
 * @returns
 */
export function delHcvReim(id) {
  return request({
    method: 'post',
    url: `hcv/writeOff/del/${id}`
  })
}

/**
 * 获取丙肝公费送检核销单
 * @param {String} id
 * @returns
 */
export function getHcvReim(id) {
  return request({
    method: 'get',
    url: `hcv/writeOff/${id}`
  })
}

/**
 * 导出丙肝公费送检核销单
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `hcv/writeOff/export`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `丙肝公费送检核销单`)
  })
}
