var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-left":"24px"}},[_c('a-collapse',{attrs:{"bordered":false},scopedSlots:_vm._u([{key:"expandIcon",fn:function(props){return [_c('a-icon',{attrs:{"type":"caret-right","rotate":props.isActive ? 90 : 0}})]}}]),model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},_vm._l((_vm.tableMsg),function(ref){
var title = ref.title;
var active = ref.active;
var dataList = ref.dataList;
var columns = ref.columns;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},[_c('vxe-table',{attrs:{"round":"","show-footer":"","show-overflow":"","size":"mini","max-height":"480","auto-resize":true,"data":_vm.formData[dataList],"footer-method":_vm.footerMethod,"footer-cell-class-name":_vm.footerCellClassName2}},_vm._l((columns),function(ref){
var title = ref.title;
var dataIndex = ref.dataIndex;
return _c('vxe-table-column',{key:dataIndex,attrs:{"title":title,"field":dataIndex}})}),1)],1)}),1),(_vm.formData.flowInfoDTO)?_c('process',{attrs:{"summaryid":_vm.formData.flowInfoDTO.summaryid,"nextapproveman":_vm.formData.flowInfoDTO.nextapproveman,"curApprover":_vm.formData.flowInfoDTO.curApprover}}):_vm._e(),_c('comment',{attrs:{"billId":_vm.formData.id}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }