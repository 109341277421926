import Outer from '@/views/outer'
export default {
  path: 'outer',
  name: 'outer',
  component: Outer,
  redirect: 'outer/externalShippingCompany',
  meta: { title: '外部品种' },
  children: [
    {
      // 外部发货公司
      path: 'externalShippingCompany',
      name: 'externalShippingCompany',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/componyManage'
        ),
      meta: { title: '外部发货公司', menuKey: 'externalShippingCompany' }
    },
    {
      // 新增外部发货公司
      path: 'addCompManage',
      name: 'addCompManage',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/componyManage/AddCompManage'
        ),
      meta: { title: '新增外部发货公司', menuKey: 'externalShippingCompany' }
    },
    {
      // 外部发货公司详情
      path: 'compManageDetail',
      name: 'compManageDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/componyManage/CompManageDetail'
        ),
      meta: { title: '外部发货公司详情', menuKey: 'externalShippingCompany' }
    },

    {
      // 外部公司发货单列表
      path: 'extCompShippingBill',
      name: 'extCompShippingBill',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/componyBill'
        ),
      meta: { title: '外部公司发货单', menuKey: 'extCompShippingBill' }
    },
    {
      // 外部公司发货单详情
      path: 'componyBillForm',
      name: 'componyBillForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/componyBill/ComponyBillForm'
        ),
      meta: { title: '外部公司发货单详情', menuKey: 'extCompShippingBill' }
    },

    {
      // 外部品种回款单列表
      path: 'extSpecBackBill',
      name: 'extSpecBackBill',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/specbackBill'
        ),
      meta: { title: '外部品种回款单', menuKey: 'extSpecBackBill' }
    },
    {
      // 外部品种回款单详情
      path: 'specbackBillForm',
      name: 'specbackBillForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/specbackBill/SpecbackBillForm'
        ),
      meta: { title: '外部品种回款单详情', menuKey: 'extSpecBackBill' }
    },
    // 外部回款发货核销单
    {
      // 外部回款发货核销单列表
      path: 'extVerificationBill',
      name: 'extVerificationBill',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/verificationBill'
        ),
      meta: { title: '外部回款发货核销单', menuKey: 'extVerificationBill' }
    },
    {
      // 外部回款发货核销单详情
      path: 'verficationBillForm',
      name: 'verficationBillForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/verificationBill/VerficationBillForm'
        ),
      meta: { title: '外部回款发货核销单详情', menuKey: 'extVerificationBill' }
    },

    // 外部品种商业期初
    {
      // 外部品种商业期初列表
      path: 'outerInventory',
      name: 'outerInventory',
      component: () =>
        import(/* webpackChunkName: "group-outer" */ '@/views/outer/inventory'),
      meta: { title: '外部品种商业期初', menuKey: 'outerInventory' }
    },
    {
      // 外部品种商业期初详情
      path: 'outerInventoryForm',
      name: 'outerInventoryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/inventory/InventoryForm'
        ),
      meta: { title: '外部品种商业期初详情', menuKey: 'outerInventory' }
    },

    // 外部终端品种档案
    {
      // 外部终端品种档案列表
      path: 'outerHospSpec',
      name: 'outerHospSpec',
      component: () =>
        import(/* webpackChunkName: "group-outer" */ '@/views/outer/hospSpec'),
      meta: { title: '外部终端品种档案', menuKey: 'outerHospSpec' }
    },
    {
      // 外部终端品种档案详情
      path: 'hospSpecForm',
      name: 'hospSpecForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/hospSpec/HospSpecForm'
        ),
      meta: { title: '外部终端品种档案详情', menuKey: 'outerHospSpec' }
    },

    // 外部流向单
    {
      // 外部流向单列表
      path: 'outerFlowBill',
      name: 'outerFlowBill',
      component: () =>
        import(/* webpackChunkName: "group-outer" */ '@/views/outer/flowBill'),
      meta: { title: '外部流向单列表', menuKey: 'outerFlowBill' }
    },
    {
      // 外部流向单详情
      path: 'flowBillForm',
      name: 'flowBillForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/flowBill/FlowBillForm'
        ),
      meta: { title: '外部流向单详情', menuKey: 'outerFlowBill' }
    },
    // 外部调拨单
    {
      // 外部调拨单列表
      path: 'outerAllocateBill',
      name: 'outerAllocateBill',
      component: () =>
        import(/* webpackChunkName: "group-outer" */ '@/views/outer/allotBill'),
      meta: { title: '外部调拨单列表', menuKey: 'outerAllocateBill' }
    },
    {
      // 外部调拨单详情
      path: 'allotBillForm',
      name: 'allotBillForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/allotBill/AllotBillForm'
        ),
      meta: { title: '外部调拨单详情', menuKey: 'outerAllocateBill' }
    },

    // 发货回款核销流向核销
    {
      // 发货回款流向核销列表
      path: 'outerBackFlowVerify',
      name: 'outerBackFlowVerify',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/backFlowBill'
        ),
      meta: {
        title: '发货回款流向核销列表',
        menuKey: 'outerBackFlowVerify'
      }
    },
    {
      // 发货回款核销流向核销单详情
      path: 'backFlowBillForm',
      name: 'backFlowBillForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/backFlowBill/BackFlowBillForm'
        ),
      meta: {
        title: '发货回款流向核销详情',
        menuKey: 'outerBackFlowVerify'
      }
    },

    // 外部品种业务费用计提
    {
      // 外部品种业务费用计提列表
      path: 'outerBusiFeeCalc',
      name: 'outerBusiFeeCalc',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/busiFeeBill'
        ),
      meta: {
        title: '外部品种业务费用计提列表',
        menuKey: 'outerBusiFeeCalc'
      }
    },
    {
      // 外部品种业务费用计提详情
      path: 'busiFeeBillForm',
      name: 'busiFeeBillForm',
      component: () =>
        import(
          /* webpackChunkName: "group-outer" */ '@/views/outer/busiFeeBill/BusiFeeBillForm'
        ),
      meta: {
        title: '外部品种业务费用计提详情',
        menuKey: 'outerBusiFeeCalc'
      }
    },
    {
      // 付款协议
      path: '/genericDrugsPayAgre',
      name: 'genericDrugsPayAgre',
      component: () =>
        import(/* webpackChunkName: "group-generic" */ '@/views/outer/pay'),
      meta: {
        title: '付款协议',
        menuKey: 'genericDrugsPayAgre'
      }
    },
    {
      // 新增付款协议
      path: '/addPay',
      name: 'addPay',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/outer/pay/AddPay'
        ),
      meta: {
        title: '新增付款协议',
        menuKey: 'genericDrugsPayAgre'
      }
    },
    {
      // 付款协议详情
      path: '/payDetail',
      name: 'payDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/outer/pay/PayDetail'
        ),
      meta: {
        title: '付款协议详情',
        menuKey: 'genericDrugsPayAgre'
      }
    }
  ]
}
