<template>
  <div class="notification" v-if="show">
    <div
      class="notification-info"
      :style="{
        position: type === 'waitwork' ? 'fixed' : '',
        left: type === 'waitwork' ? 'calc(50% - 460px)' : ''
      }"
    >
      <a-card class="notification-card">
        <span>
          {{ $store.getters.username }} 你好!
          当前单据已被[共享中心-会计]挂起并通知，请您根据审批意见，及时回复处理!
        </span>

        <div class="mt-6 text-right">
          <a-button type="primary" @click="replay">好的，去回复</a-button>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReplayNotify',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    replay() {
      this.$emit('replay')
    }
  }
}
</script>

<style lang="less" scoped>
.notification {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 999;

  & .notification-info {
    position: fixed;
    width: 640px;
    top: 300px;
    left: calc(50% - 220px);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  & .notification-card {
    border-radius: 8px;
  }
}
</style>
