export default {
  namespaced: true,
  state: {
    delFiles: [],
    addFiles: []
  },
  mutations: {
    PUSH_DELFILE(state, value) {
      state.delFiles.push(value)
    },
    PUSH_ADDFILE(state, value) {
      state.addFiles.push(value)
    },
    DEL_ADDFILE_ALL(state) {
      state.addFiles = []
    },
    DEL_DELFILE_ALL(state) {
      state.delFiles = []
    }
  },
  actions: {
    clearFiles({ commit }) {
      commit('DEL_ADDFILE_ALL')
      commit('DEL_DELFILE_ALL')
    },
    pushDelFiles({ commit }, file) {
      commit('PUSH_DELFILE', file)
    },
    pushAddFiles({ commit }, file) {
      commit('PUSH_ADDFILE', file)
    }
  }
}
