<template>
  <div>
    <a-modal
      v-model="visible"
      title="附件预览"
      dialogClass="preview-file-modal clearfix"
      width="80%"
      :mask="false"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      @cancel="handleClose"
    >
      <div class="file-content border w-full">
        <iframe :src="url" width="100%" height="100%" frameborder="1"></iframe>
      </div>
    </a-modal>
  </div>
</template>

<script>
const pUrl =
  process.env.VUE_APP_MODE === 'production'
    ? 'http://crm.dyg.com.cn/static/web/viewer.html?file='
    : 'http://192.168.22.92:7090/static/web/viewer.html?file='
export default {
  name: 'preview',
  props: {
    fileId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      visible: true,
      url: ''
    }
  },
  created() {
    const Api = encodeURIComponent(`/crm/api/nfile/image/${this.fileId}`)
    this.url = pUrl + Api
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less"></style>
