<template>
  <div class="loader JS_on">
    <span class="binary"></span>
    <span class="binary"></span>
    <span class="getting-there">{{ title }}.</span>
  </div>
</template>

<script>
export default {
  name: 'LoadingStuff',
  props: {
    title: {
      type: String,
      default: 'LOADING STUFF...'
    }
  }
}
</script>

<style lang="less" scoped>
.loader {
  width: 240px;
  height: 300px;
  display: inline-block;
  position: relative;
  // background: rgba(black, 0.2); // testing

  &::before,
  &::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: 30px;
    left: 45px;
    z-index: 1;
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;
    border-bottom: 20px solid darken(#a7c5f8, 10%);
    transform: scale(0);
    transition: all 0.2s ease;
  }
  &::after {
    border-right: 15px solid transparent;
    border-bottom: 20px solid darken(#a7c5f8, 13%);
  }

  .getting-there {
    width: 120%;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: -7%;
    font-size: 12px;
    letter-spacing: 2px;
    color: #4587f5;
  }

  .binary {
    width: 100%;
    height: 270px;
    display: block;
    color: white;
    position: absolute;
    top: 0;
    left: 75px;
    z-index: 2;
    overflow: hidden;

    &::before,
    &::after {
      font-size: 24px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    &:nth-child(1) {
      &::before {
        content: '正';
        color: #333;
        animation: a 1.5s linear infinite;
      }
      &::after {
        content: '在';
        color: #333;
        animation: b 1.3s linear infinite;
      }
    }
    &:nth-child(2) {
      &::before {
        content: '加';
        color: #333;
        animation: c 0.9s linear infinite;
      }
      &::after {
        content: '载';
        color: #333;
        animation: d 0.7s linear infinite;
      }
    }
  }

  // ACTIVATE
  &.JS_on {
    &::before,
    &::after {
      transform: scale(1);
    }
  }
}

@keyframes a {
  0% {
    transform: translate(30px, 0) rotate(30deg);
    opacity: 0;
  }
  100% {
    transform: translate(30px, 250px) rotate(-50deg);
    opacity: 1;
  }
}
@keyframes b {
  0% {
    transform: translate(50px, 0) rotate(-40deg);
    opacity: 0;
  }
  100% {
    transform: translate(40px, 250px) rotate(80deg);
    opacity: 1;
  }
}
@keyframes c {
  0% {
    transform: translate(70px, 0) rotate(10deg);
    opacity: 0;
  }
  100% {
    transform: translate(60px, 250px) rotate(70deg);
    opacity: 1;
  }
}
@keyframes d {
  0% {
    transform: translate(30px, 0) rotate(-50deg);
    opacity: 0;
  }
  100% {
    transform: translate(45px, 250px) rotate(30deg);
    opacity: 1;
  }
}
</style>
