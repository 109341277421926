<template>
  <a-collapse defaultActiveKey="1" :bordered="false">
    <template v-slot:expandIcon="props">
      <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
    </template>
    <span class="col-header" v-if="curApprover && curApprover !== '[]'">
      (
      <span style="color: #4587f4">当前审批人：</span>
      <span class="text-red-500">{{ curApprover }}</span>
      )
    </span>
    <a-collapse-panel header="审批流信息" key="1" class="customStyle">
      <div class="w-full overflow-auto">
        <canvas v-if="!isEmpty" ref="canvas"></canvas>
        <a-empty v-else>
          <span slot="description">暂无流程信息</span>
        </a-empty>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { getOAProcimg } from '@/api/acdemicActive/acdemic-application'
export default {
  name: 'ProcessImg',
  props: {
    summaryid: {
      type: String,
      default: ''
    },
    nextapproveman: {
      type: String,
      default: ''
    },
    curApprover: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isEmpty: false,
      showSkeleton: true
    }
  },
  created() {
    this.getOAProcimg(this.summaryid, this.nextapproveman)
  },
  methods: {
    getImage(OAImage) {
      const canvas = this.$refs['canvas']
      const canvasContext = canvas.getContext('2d')
      const image = new Image()
      image.src = OAImage
      image.onload = () => {
        canvas.width = image.width - 200
        canvas.height = image.height - 180
        canvasContext.drawImage(
          image,
          100,
          30,
          image.width,
          image.height,
          0,
          0,
          image.width,
          image.height
        )
      }

      image.error = () => {
        this.isEmpty = true
      }
    },

    getOAProcimg(summaryid, nextapproveman) {
      const nextapprove = encodeURIComponent(nextapproveman).toLowerCase()
      getOAProcimg(this.summaryid, nextapprove).then(response => {
        if (response) {
          const OAImage = response
          this.showSkeleton = false
          this.getImage(OAImage)
          this.$emit('finish')
        } else {
          this.isEmpty = true
          this.$emit('finish')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.app-message {
  position: relative;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}

.col-header {
  position: absolute;
  top: 0px;
  padding: 12px 0 12px 40px;
  left: 100px;
}
</style>
