<template>
  <div class="mt-2 mr-3" style="width: 99%">
    <div
      class="h-auto bg-gray-200 clearfix my-3 rounded-lg"
      v-for="(item, index) in handleInfoList"
      :key="`${index}`"
      style="border: 1px solid rgba(0, 0, 0, 0.25)"
    >
      <div class="w-1/12 float-left h-auto">
        <div
          class="lineMax w-12 h-12 mt-2 rounded-full m-auto text-center text-xs"
          style="border: 1px solid rgba(0, 0, 0, 0.25)"
        >
          {{ getAttitude(item.attitude) }}
        </div>
      </div>
      <div class="float-left inline w-128">
        <div class="my-2 text-xs">
          {{ item.handleman }}
          <span v-if="item.attitude === 97" class="ml-4">暂存待办</span>
        </div>
        <div class="my-1 text-xs">
          {{
            item.vnote === ''
              ? item.attitude === 1
                ? '同意'
                : '不同意'
              : item.vnote
          }}
        </div>
        <span class="text-xs text-gray-500 mr-2">
          {{ item.ts }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'comment',
  props: ['handleInfoList'],
  data() {
    return {
      leadingMax: {
        'line-height': '3rem'
      }
    }
  },
  methods: {
    getAttitude(attitude) {
      if (attitude == 1) return '同意'
      if (attitude == 97) return '暂存待办'
      if (attitude == 78) return '移交'

      return '不同意'
    }
  }
}
</script>

<style lang="less" scoped>
.lineMax {
  line-height: 3rem;
}
</style>
