<template>
  <div>
    <non-meet-form
      :type="type"
      :pid="pid"
      :summaryId="summaryId"
      @status="nowStatus($event)"
    ></non-meet-form>
  </div>
</template>

<script>
import NonMeetForm from '@/views/activity/nonMeeting/NonMeetForm'

export default {
  name: 'NONMEETINGBill',
  components: {
    NonMeetForm
  },
  props: {
    type: String,
    pid: String,
    summaryId: String
  },
  data() {
    return {
      status: ''
    }
  },

  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
