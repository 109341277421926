const TABLE = [
  {
    title: '产品组-目标医院',
    active: 'TGHOSP',
    dataList: 'productGroupInfoDTOs',
    columns: [
      { title: '医院名称', dataIndex: 'hospitalName' },
      { title: '医院编码', dataIndex: 'hospitalCode' },
      { title: '标准终端类型', dataIndex: 'chnlmemtypeStdName' },
      {
        title: '状态',
        dataIndex: 'checkStatus',
        scopedSlots: {
          customRender: 'status'
        }
      }
    ]
  }
]

export { TABLE }
