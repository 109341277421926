var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{staticClass:"mt-2",attrs:{"layout":"horizontal","model":_vm.form,"size":"small"}},[_c('a-row',_vm._l((_vm.getFprops),function(ref){
var label = ref.label;
var type = ref.type;
var prop = ref.prop;
var span = ref.span;
var options = ref.options;
var value = ref.value;
return _c('a-col',{key:prop,attrs:{"span":span ? 12 * span : 12}},[_c('a-form-item',{attrs:{"label":label,"labelCol":_vm.getLabelCol(span),"wrapperCol":_vm.getWrapperCol(span)}},[(type === 'input')?_c('a-input',{attrs:{"value":prop && !value ? _vm.form[prop] : value,"disabled":""}}):(type === 'select')?_c('a-select',{attrs:{"disabled":""},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}},_vm._l((options),function(ref){
var subLabel = ref.label;
var value = ref.value;
var color = ref.color;
return _c('a-select-option',{key:value,attrs:{"value":value}},[_c('span',{class:color},[_vm._v(_vm._s(subLabel))])])}),1):(type === 'textarea')?_c('a-textarea',{attrs:{"disabled":""},model:{value:(_vm.form[prop]),callback:function ($$v) {_vm.$set(_vm.form, prop, $$v)},expression:"form[prop]"}}):(type === 'slot')?_c('a-popover',{attrs:{"placement":"left","overlayClassName":"popover","trigger":"click"}},[_c('template',{slot:"content"},[_c('div',{staticClass:"p-2"},[_c('p',{staticClass:"mb-2"},[_c('label',{staticClass:"mr-2 font-bold"},[_vm._v("单据号:")]),_c('span',[_vm._v(_vm._s(_vm.bill.billCode || '无'))])]),_c('p',{staticClass:"mb-2"},[_c('label',{staticClass:"mr-2 font-bold"},[_vm._v("制单人:")]),_c('span',[_vm._v(_vm._s(_vm.bill.creationUserName || '无'))])]),_c('p',{staticClass:"mb-2"},[_c('label',{staticClass:"mr-2 font-bold"},[_vm._v("制单时间:")]),_c('span',[_vm._v(_vm._s(_vm.bill.creationTime || '无'))])]),_c('p',{staticClass:"mb-2"},[_c('label',{staticClass:"mr-2 font-bold"},[_vm._v("单据类型:")]),_c('span',[_vm._v(_vm._s(_vm.bill.type || '无'))])]),_c('p',[_c('label',{staticClass:"mr-2 font-bold"},[_vm._v("单据状态:")]),_c('span',[_vm._v(" "+_vm._s(_vm.bill.status ? _vm.getStatusText(_vm.bill.status) : '无')+" ")])])])]),_c('a-button',{staticClass:"border-blue-400"},[_vm._v("查看单据详情")])],2):_c('span',[_vm._v(_vm._s(_vm.form[prop]))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }