import { Message } from 'ant-design-vue'
export { default as debounce } from 'lodash/debounce'
export { default as throttle } from 'lodash/throttle'
export { default as deepClone } from 'fast-copy'
export { default as uniq } from 'lodash/uniq'
import router from '@/router'
import store from '@/store'
import request from './request'

/**
 * 异常登出
 * @param {String} content 提示的信息
 */
export function logout(prompt) {
  const token = store.state.user.user.token
  if (prompt) {
    if (!token && prompt.indexOf('token') > 0) return //多个请求异步发生token无效 只弹出存在token时的第一次
    Message.error(prompt, 2)
    // return
  }
  sessionStorage.clear()
  store.commit('user/SET_USER', {})
  store.dispatch('tagsView/delAllViews')
  router.replace({ name: 'login' })
  return
}

/**
 *注销登录
 */
export function rlogout() {
  const token = store.state.user.user.token
  if (token) {
    request({
      url: `user/logout`,
      method: 'post',
      headers: { token: store.state.user.user.token }
    })
      .then(() => {
        sessionStorage.clear()
        store.commit('user/SET_USER', {})
        store.dispatch('tagsView/delAllViews')
        router.replace({ name: 'login' })
      })
      .catch(() => {
        sessionStorage.clear()
        store.commit('user/SET_USER', {})
        store.dispatch('tagsView/delAllViews')
        router.replace({ name: 'login' })
      })
  } else {
    logout('token无效,请重新登录!')
  }
}
/**
 * 产生指定范围的随机整数，[min, max)
 * @param {Integer} max 最大值
 * @param {Integer} min 最小值，默认为 0
 */
export function randomInt(max, min = 0) {
  return Math.floor(Math.random() * (max - min) + min)
}
/**
 * 产生指定位数的随机字符串
 * @param {Integer} length 产生字符串的长度，默认 10
 * @param {String} pool 产生字符串的长度，默认 [A-Za-z0-9]
 */
export function randomString(length = 10, pool) {
  let str = ''
  pool =
    pool || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  Array.from({ length }).forEach(() => {
    str += pool.charAt(randomInt(pool.length))
  })
  return str
}
/**

 * 将指定的对象的键按照 key 的自然顺序进行排序
 * @param {Object} source - 待排序的对象
 * @returns {Object} 已排序的新的对象
 */
export function sortObject(source) {
  const sortedObject = {}
  Object.keys(source)
    .sort()
    .forEach(key => {
      sortedObject[key] = source[key]
      // 递归调用，排序数组内的对象
      if (Array.isArray(source[key])) {
        source[key].forEach((child, index) => {
          if (typeof child === 'object') {
            sortedObject[key][index] = sortObject(child)
          }
        })
      }
    })
  return sortedObject
}
/*
 * 门户单点登录
 */
export function logged() {
  const token = store.state.user.user.token
  if (token) {
    request({
      url: `user/getUserInfo`,
      method: 'get',
      headers: { token: store.state.user.user.token }
    }).then(user => {
      user.token = token
      store.commit('user/SET_USER', user)
      router.push({ name: 'workplace' })
    })
  } else {
    logout('token无效,请重新登录!')
  }
}

/**
 * 去除用户username的用户信息
 */
export function getUserMsg(route, params) {
  const token = store.state.user.user.token
  if (token) {
    request({
      url: `user/getUserInfo`,
      method: 'get',
      headers: { token: store.state.user.user.token }
    }).then(user => {
      user.token = token
      // 从门户过来的用户信息
      user.protal = true
      store.commit('user/SET_USER', user)

      if (params.needProxyApprove === 'true') {
        params.proxyApproveRelDto = JSON.stringify(
          params.proxyApproveRelDto || {}
        )
      } else if (params.needProxyApprove === 'false') {
        params.proxyApproveRelDto = params.proxyApproveRelDto || null
      }

      router.push({ name: route, query: params })
    })
  } else {
    logout('token无效,请重新登录!')
  }
}

export function mdPwd() {
  router.push({ name: 'mdpwd' })
}

/**
 * 挑选一个对象的指定的属性，并包装成对象返回
 * @param {Object} obj 要挑选属性的原对象
 * @param {String Array Object} keys 要抽取的属性，可以是字符串、数组、对象
 * @param {Boolean} reverse 是否反转 keys 的 key-value，目前只对数组生效
 * @returns {Object} 挑选的属性，是一个新对象
 */
export function pick(obj, keys, reverse = false) {
  if (!typeof obj === 'object' || !obj) {
    return {}
  }

  if (!keys) {
    return Object.assign({}, obj)
  }

  const returnObj = {}

  /* 数组 */
  if (Array.isArray(keys)) {
    keys.forEach(key => {
      const names = key.replace(/ /g, '').split(':')
      const sourceKey = names[Number(names.length > 1 && reverse)]
      const returnKey = names[Number(!reverse)]
      if (sourceKey in obj) {
        returnObj[returnKey || sourceKey] = obj[sourceKey]
      }
    })
    return returnObj
  }

  /* 单个字符串 */
  if (typeof keys === 'string') {
    if (keys in obj) {
      returnObj[keys] = obj[keys]
    }
    return returnObj
  }

  /* 对象 */
  if (typeof obj === 'object') {
    Object.keys(keys).forEach(key => {
      if (key in obj) {
        returnObj[keys[key]] = obj[key]
      }
    })
  }
  return returnObj
}
