<template>
  <div>
    <a-form
      size="small"
      class="pl-6"
      laba-width="90px"
      laba-position="right"
      layout="vertical"
    >
      <a-collapse v-model="activeKey" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          class="customStyle"
          v-for="{ title, active, children } in baseMsg"
          :header="title"
          :key="active"
        >
          <a-col :span="8" v-for="{ label, value } in children" :key="label">
            <a-form-item
              :label="label"
              :prop="value"
              :label-col="itemlay.labelCol"
              :wrapper-col="itemlay.wrapperCol"
            >
              <a-input
                v-if="value === 'appStatus'"
                :disabled="true"
                :value="getStatusText(formData[value])"
              ></a-input>
              <a-input
                v-else
                :disabled="true"
                v-model="formData[value]"
              ></a-input>
            </a-form-item>
          </a-col>
        </a-collapse-panel>
        <a-collapse-panel
          v-for="{ title, active, dataList, columns } in tableMsg"
          :header="title"
          :key="active"
          class="customStyle"
        >
          <a-table
            rowKey="field"
            :dataSource="formData[dataList]"
            :columns="columns"
            :pagination="false"
            :rowClassName="
              (record, index) => {
                return index % 2 === 0 ? 'trBgClass' : ''
              }
            "
          ></a-table>
        </a-collapse-panel>
        <a-collapse-panel
          v-for="{ title, active, dataList, columns } in productList"
          :header="title"
          :key="active"
          class="customStyle"
        >
          <div
            v-for="(item, index) in formData[dataList]"
            :key="index"
            class="product-list"
          >
            <div class="product-item">
              {{ item.productName }}
              <span
                class="float-right mr-4"
                :class="getColor(item.prodInfoStatus)"
              >
                {{ getText(item.prodInfoStatus) }}
              </span>
            </div>
            <a-table
              rowKey="id"
              :dataSource="item.saPostInfoDTOs"
              :columns="columns"
              :pagination="false"
              class="product"
            >
              <template
                slot="indicator"
                slot-scope="indicatorProportion, record"
              >
                <span class="mx-1">旧:</span>
                <span class="mx-1">
                  {{ record.oriIndicatorProportion || '0' }}
                </span>
                <span class="mx-1">新:</span>
                <span class="ml-1">{{ indicatorProportion || '0' }}</span>
              </template>
              <template slot="sales" slot-scope="salesProportion, record">
                <span class="mx-1">旧:</span>
                <span class="mx-1">
                  {{ record.oriSalesProportion || '0' }}
                </span>
                <span class="mx-1">新:</span>
                <span class="mx-1">
                  {{ salesProportion || '0' }}
                </span>
              </template>
            </a-table>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <process
        v-if="formData.flowInfoDTO"
        :summaryid="formData.flowInfoDTO.summaryid"
        :nextapproveman="formData.flowInfoDTO.nextapproveman"
        :curApprover="formData.flowInfoDTO.curApprover"
      ></process>
      <comment :billId="formData.id"></comment>
    </a-form>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import Process from '@/views/custmanage/modify/components/Process'
import { BASE, TABLE, PRODUCT_TABLE } from './hospital'
import { getHospModifyById } from '@/api/customer/modify'
import { getStatusText } from '@/configs/bill-msg'
export default {
  name: 'DoctorModifyBill',
  props: {
    type: String,
    pid: String
  },
  components: { Process, Comment },
  data() {
    return {
      value: 'a',
      formData: {},
      baseMsg: BASE,
      tableMsg: TABLE,
      productList: PRODUCT_TABLE,
      activeKey: ['BASE', 'HMSI', 'DIFF'],
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getHospModifyById(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData.appStatus)
    })
  },
  methods: {
    getStatusText,

    /**
     * 获取医院和产品的状态文本
     * @param {Integer} status 医院和产品的状态（0、新增；1、删除；2、修改）
     */
    getText(status) {
      if (status == 0) return '新增'
      if (status == 2) return '修改'
      if (status == 1) return '删除'
    },

    /**
     * 获取医院和产品的状态颜色
     * @param {Integer} status 医院和产品的状态（0、新增；1、删除；2、修改）
     */
    getColor(status) {
      if (status == 0) return 'text-green-500'
      if (status == 2) return 'text-blue-500'
      if (status == 1) return 'text-red-500'
    }
  }
}
</script>

<style lang="less" scoped>
.del-text {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
}
/deep/ .ant-btn-primary[disabled],
/deep/.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
/deep/.ant-btn-primary {
  background-color: #4587f4;
}
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}
/deep/.product .ant-table-thead > tr > th {
  background-color: #ffffff;
}
/deep/.product .ant-table-tbody > tr > td {
  padding: 16px !important;
}

.product-list {
  margin-bottom: 24px;
  border: 1px solid #e8e8e8;
  .product-item {
    width: 100%;
    padding: 16px;
    font-weight: 600;
    background-color: #fafafa;
    border-bottom: 1px solid #e8e8e8;
  }
}
</style>
