import request from '@/utils/request'

/**
 * 高德输入提示
 * @param {Object} params
 * @returns
 */
export function getTips(params) {
  return request({
    url: `/amap/assistant/inputtips`,
    method: 'get',
    params: { ...params }
  })
}
