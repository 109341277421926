import request from '@/utils/request'

/**
 * 获取待办接口(请求类型为get；在审批移交里调用)
 */
export function getListWaitWork(
  pageindex,
  pagenum,
  paramObj = {},
  condition = []
) {
  return request({
    method: 'post',
    url: `oaflow/listwaitwork`,
    params: { pageindex, pagenum, ...paramObj },
    data: condition
  })
}

/**
 * 获取已发待办接口
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getListSendWork(
  pageindex,
  pagenum,
  paramObj = {},
  condition = []
) {
  return request({
    method: 'post',
    url: `oaflow/listsendwork`,
    params: { pageindex, pagenum, ...paramObj },
    data: condition
  })
}

/**
 * 获取已办待办列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getListDoneWork(
  pageindex,
  pagenum,
  paramObj = {},
  condition = []
) {
  return request({
    method: 'post',
    url: `oaflow/listdonework`,
    params: { pageindex, pagenum, ...paramObj },
    data: condition
  })
}

/**
 * 获取控制台中的单据条数
 */
export function getCounts() {
  return request({
    method: 'get',
    url: `oaflow/getCounts`
  })
}

/**
 * 获取代理审批待办接口
 */
export function getListProxyWaitWork(pageindex, pagenum, condition = '') {
  return request({
    method: 'post',
    url: `oaflow/proxywaitwork`,
    params: { pageindex, pagenum, content: condition }
  })
}

/**
 * 获取代理审批已办接口
 */
export function getListDoneWaitWork(pageindex, pagenum, condition = '') {
  return request({
    method: 'post',
    url: `oaflow/getProxyDoneWorkVOs`,
    params: { pageindex, pagenum, content: condition }
  })
}
