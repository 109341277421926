<template>
  <a-modal
    :visible="visible"
    title="选择终端"
    width="1024px"
    closable
    wrapClassName="hosp"
    :maskClosable="false"
    :bodyStyle="{ padding: '0' }"
    @ok="submit"
    @cancel="$emit('close')"
  >
    <div class="modal-content">
      <div class="m-4">
        <div class="mb-4">
          <a-input-search
            style="width: 256px"
            v-model="searchStr"
            @change="searchHosp"
          ></a-input-search>
        </div>
        <a-table
          rowKey="id"
          :dataSource="data"
          :columns="columns"
          :pagination="false"
          :loading="loading"
          :rowClassName="
            record => {
              return chooseHosp.id === record.id ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            (record, index) => {
              return {
                on: {
                  click: () => (chooseHosp = record),
                  dblclick: () => {
                    chooseHosp = record
                    submit()
                  }
                }
              }
            }
          "
        ></a-table>
        <div class="text-right mt-4">
          <a-pagination
            size="small"
            showQuickJumper
            :hideOnSinglePage="true"
            :current="current"
            :pageSize="pageSize"
            :total="total"
            @change="pageChange"
            @showSizeChange="showSizeChange"
          />
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import listMix from '@/globalMix/listMix'
import { Drag, resetDrag } from '@/utils/directives/drag'
import { loadTableData as getHospList } from '@/api/customer/hospmanage'
import { default as isEmpty } from 'lodash/isEmpty'
import { default as debounce } from 'lodash/debounce'
import { default as pullAllBy } from 'lodash/pullAllBy'
export default {
  name: 'HospModal',
  mixins: [listMix],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: 1,
      pageSize: 12,
      total: 0,
      loading: false,
      data: [],
      conditions: [],

      searchStr: '', // 搜索

      chooseHosp: {}, // 选择终端
      columns: [
        {
          title: '医院CRM编码',
          dataIndex: 'hospitalCode',
          ellipsis: true
        },
        {
          title: '医院NC编码',
          dataIndex: 'ncCode',
          ellipsis: true
        },
        {
          title: '医院名称',
          dataIndex: 'hospitalName',
          ellipsis: true
        },
        {
          title: '业务区域',
          dataIndex: 'chnlareaName',
          ellipsis: true
        }
      ]
    }
  },

  created() {
    this.getList()
  },

  destroyed() {
    this.$nextTick(() => {
      resetDrag()
    })
  },

  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          Drag('.hosp')
        })
      }
    }
  },

  methods: {
    getList() {
      this.loading = true
      getHospList(
        { current: this.current, pageSize: this.pageSize },
        this.conditions
      ).then(res => {
        this.loading = false
        this.data = res.datas
        this.total = res.totalNum
      })
    },

    searchHosp: debounce(function () {
      pullAllBy(this.conditions, [{ column: 'search' }], 'column')

      if (this.searchStr) {
        this.conditions.push({
          column: 'search',
          opt: 'like',
          value: this.searchStr
        })
      }

      this.current = 1
      this.chooseHosp = {}
      this.getList()
    }, 2000),

    submit() {
      if (isEmpty(this.chooseHosp)) {
        this.$message.error('请选择终端')
        return
      }

      this.$emit('choose', this.chooseHosp)
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.modal-content {
  height: 560px;
  padding: 0;
  &.choose-active {
    background-color: #4587f5;
    color: #fff;
  }
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
