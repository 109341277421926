import store from '@/store'
export default {
  created() {
    this.initWebSocket()
  },

  data() {
    return {
      kscoket: null
    }
  },

  destroyed() {
    if (this.kscoket) this.kscoket.close()
  },

  methods: {
    initWebSocket() {
      const webSocketUrl =
        process.env.VUE_APP_MODE.toLowerCase() === 'production'
          ? 'ws://crm.dyg.com.cn'
          : 'wss://crmtest.dyg.com.cn:7090'
      this.$connect(
        `${webSocketUrl}/websocket/waitWorkInfo/${store.getters['userCode']}`,
        {
          reconnection: true, // (Boolean)是否自动重连，默认false
          reconnectionAttempts: 5, // 重连次数
          reconnectionDelay: 3000 // 再次重连等待时常(1000)
        }
      )
      //初始化weosocket,这些onopen是一个事件，当
      this.$socket.onopen = this.websocketonopen
      this.$socket.onerror = this.websocketonerror
      this.$socket.onmessage = this.websocketonmessage
      this.$socket.onclose = this.websocketclose
    },

    websocketonopen() {
      //  链接ws服务器，e.target.readyState = 0/1/2/3
    },

    websocketonerror() {
      //错误
    },

    websocketonmessage(e) {
      //数据接收
      const data = this
      try {
        const message = JSON.parse(e.data)
        const { waitWorkNumData, heartInfo } = message

        data.kscoket = e.target

        if (heartInfo) {
          e.target.send(`{ "heartInfo": "reply" }`)
          return
        }

        data.waitWorkNumData = waitWorkNumData
      } catch {
        this.$message('JSON错误')
      }
    },

    websocketsend(e) {
      //数据发送
      this.$socket.send(e)
    },

    websocketclose() {
      //关闭链接时触发
    }
  }
}
