<template>
  <div v-if="isRead">
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <span class="col-header" v-if="curApprover && curApprover !== '[]'">
          (
          <span style="color: #4587f4">当前审批人：</span>
          <span class="text-red-500">{{ curApprover }}</span>
          )
        </span>
        <a-collapse-panel header="审批流信息" key="1" class="customStyle">
          <div class="w-full overflow-auto" style="margin-left: 24px">
            <canvas ref="canvas"></canvas>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import { getOAProcimg } from '@/api/acdemicActive/acdemic-application'
export default {
  name: 'OaProcess',
  props: {
    summaryid: String,
    nextapproveman: String,
    status: Boolean,
    curApprover: String
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  watch: {
    summaryid(nval) {
      if (this.isRead) {
        if (nval) {
          this.getOAProcimg(nval, this.nextapproveman)
        }
      }
    }
  },
  methods: {
    getImage(OAImage) {
      const canvas = this.$refs['canvas']
      const canvasContext = canvas.getContext('2d')
      const image = new Image()
      this.isLoading = true
      image.src = OAImage
      image.onload = () => {
        canvas.width = image.width - 200
        canvas.height = image.height - 180
        canvasContext.drawImage(
          image,
          100,
          30,
          image.width,
          image.height,
          0,
          0,
          image.width,
          image.height
        )
      }
    },

    getOAProcimg(summaryid, nextapproveman) {
      const nextapprove = encodeURIComponent(nextapproveman).toLowerCase()
      getOAProcimg(this.summaryid, nextapprove).then(response => {
        const OAImage = response
        if (this.isRead) this.getImage(OAImage)
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.app-message {
  position: relative;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}

.col-header {
  position: absolute;
  top: 0px;
  padding: 12px 0 12px 40px;
  left: 100px;
}
</style>
