<template>
  <keep-alive v-if="keeyAlive">
    <router-view />
  </keep-alive>
  <router-view v-else />
</template>

<script>
export default {
  name: 'RouteView',
  computed: {
    keeyAlive() {
      const meta = this.$route.meta
      return meta.keeyAlive
    }
  }
}
</script>

<style lang="less" scoped></style>
