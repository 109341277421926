<template>
  <div class="text-xs">
    <clear :pid="pid" @status="nowStatus($event)"></clear>
  </div>
</template>

<script>
import Clear from './clear/CLEAR'

export default {
  name: 'CLEARBill',
  components: {
    Clear
  },
  props: {
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
