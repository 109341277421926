import request from '@/utils/request'

/**
 * 添加对公付款单或预付款
 * @param {Object} paymentDTO
 */
export function getAddPayment(paymentDTO) {
  return request({
    method: 'post',
    url: `payment/addPayment`,
    data: paymentDTO
  })
}

/**
 * 删除对公付款或预付款
 * @param {String} id
 */
export function getDelPayment(id) {
  return request({
    method: 'delete',
    url: `payment/delPayment`,
    params: {
      id
    }
  })
}

/**
 * 查看对公付款单和预付款单
 * @param {Integer} pageindex
 * @param {Integer} pageNum
 * @param {String} pkSrc
 */
export function getListPayment(
  pageIndex,
  pageNum,
  pkSrc,
  billType,
  conditions = {}
) {
  return request({
    method: 'post',
    url: `payment/listPayment`,
    params: {
      pageIndex,
      pageNum,
      pkSrc,
      billType
    },
    data: conditions,
    customConfig: {
      withTimestamp: false,
      withSign: false
    }
  })
}

/**
 * 查看付款单明细
 * @param {Integer} id 单据id
 */
export function getPayment(id) {
  return request({
    method: 'get',
    url: `payment/getPayment`,
    params: {
      id
    }
  })
}

/**
 * 修改对公付款单和预付款单
 * @param {Onject} paymentDTO
 */
export function getUpdatePayment(paymentDTO) {
  return request({
    method: 'put',
    url: `payment/updatePayment`,
    data: paymentDTO
  })
}

/**
 * 获取NC的付款单位
 */
export function getPayer() {
  return request({
    method: 'get',
    url: `ncDatas/getPayer`,
    params: {}
  })
}

/**
 * 获取NC的收款单位
 * @param {Integer} pageIndex
 * @param {Integer} pageNum
 * @param {Object} map
 */
export function getReceive(pageIndex, pageNum, map = null) {
  return request({
    method: 'post',
    url: `ncDatas/getReceive`,
    params: { pageIndex, pageNum },
    headers: { 'Content-Type': 'application/json' },
    data: map,
    customConfig: {
      withTimestamp: false,
      withSign: false
    }
  })
}

/**
 * 获取NC的收款人信息
 * @param {Integer} pageIndex
 * @param {Integer} pageNum
 * @param {Object} map
 */
export function getPrivateBankAcc(pageIndex, pageNum, map = null) {
  return request({
    method: 'post',
    url: `ncDatas/getPrivateBankAcc`,
    params: { pageIndex, pageNum },
    headers: { 'Content-Type': 'application/json' },
    data: map,
    customConfig: {
      withTimestamp: false,
      withSign: false
    }
  })
}

/**
 * 对公付款单提交接口
 * @param {Object} paymentDTO
 */
export function getSubmitDGFK(paymentDTO) {
  return request({
    method: 'put',
    url: `oaflow/submitDGFK`,
    data: paymentDTO
  })
}

/**
 * 私付款单提交接口
 * @param {Object} paymentDTO
 */
export function getSubmitDSFK(paymentDTO) {
  return request({
    method: 'put',
    url: `oaflow/submitDSFK`,
    data: paymentDTO
  })
}

/**
 * 预付款单提交接口
 * @param {Object} paymentDTO
 */
export function getSubmitYFKD(paymentDTO) {
  return request({
    method: 'put',
    url: `oaflow/submitYFKD`,
    data: paymentDTO
  })
}

/**
 * 对房产公付款单提交接口
 * @param {Object} paymentDTO
 */
export function getSubmitZFDG(paymentDTO) {
  return request({
    method: 'post',
    url: `oaflow/submitHousePaymentDGFK`,
    data: paymentDTO
  })
}

/**
 * 房产私付款单提交接口
 * @param {Object} paymentDTO
 */
export function getSubmitZFDS(paymentDTO) {
  return request({
    method: 'post',
    url: `oaflow/submitHousePaymentDSFK`,
    data: paymentDTO
  })
}

/**
 * 打印-根据流程id查找信息
 * @param {String} summaryId
 */
export function getPrintInfo(summaryId) {
  return request({
    method: 'get',
    url: `oaDatas/getPrintInfo`,
    params: { summaryId }
  })
}

/**
 * 获取可冲销的借款单
 * @param {String} hpk 对私付款的单据号
 */
export function getJKInfo(hpk) {
  return request({
    method: 'get',
    url: `cjk/getJKInfo`,
    params: { hpk }
  })
}

/**
 * 冲借款保存接口
 * @param {Object} aggDTO
 */
export function saveCJKData(aggDTO) {
  return request({
    method: 'post',
    url: `cjk/saveCJKData`,
    data: aggDTO
  })
}

/**
 * 检查付款单报销人信息
 */
export function checkPersonInfo() {
  return request({
    method: 'post',
    url: `payment/checkPersonInfo`
  })
}

/**
 * 查询上传过的发票明细，参照
 * @param {Integer} pageIndex
 * @param {Integer} pageNum
 * @param {Object} data
 * @returns
 */
export function getInvoiceList(pageIndex, pageNum, data = {}) {
  return request({
    method: 'post',
    url: `invoice/get`,
    params: { pageIndex, pageNum },
    data
  })
}

/**
 * 发票附件预览
 * @param {String} pk
 * @returns
 */
export function getInvoicePreview(pk, pkSrc, pkGroup, userCode) {
  return request({
    method: 'post',
    url: `invoice/preView`,
    params: pkSrc
      ? { pk, payId: pkSrc, pkGroup, userCode }
      : { pk, pkGroup, userCode },
    responseType: 'arraybuffer'
  })
}

/**
 * 发票验真
 * @param {Array} list
 * @returns
 */
export function getValidateBatch(list) {
  return request({
    method: 'post',
    url: `invoice/validateBatch`,
    data: list
  })
}

/**
 * 发票关联单据查询
 * @param {String} pk
 * @returns
 */
export function getRelatedBill(pk) {
  return request({
    method: 'post',
    url: `invoice/getRelatedBill`,
    params: { pkSrc: pk }
  })
}

/**
 * 获取总结单发票
 * @param {Onject} paymentDTO
 */
export function getReimInvoiceDtos(reimId, pageIndex, pageNum) {
  return request({
    method: 'post',
    url: `payment/getReimInvoiceDtos`,
    params: {
      reimId,
      pageIndex,
      pageNum
    },
    data: {}
  })
}
