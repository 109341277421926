import axios from 'axios'
import store from '@/store'
import { message } from 'ant-design-vue'
import { logout, sortObject } from '@/utils'
import { MD5, decryptAES } from '@/utils/cryption'
const { PREFIX, ENCRYPTED } = getProxy()
export const IS_DEV_ENV = process.env.VUE_APP_MODE === 'development'

export const BASE_URL = '/crm/api'

const request = axios.create({
  baseURL: PREFIX,
  timeout: 25000
})

const DEBUG = sessionStorage.getItem('DEBUG') === 'true'

/** 请求拦截器 */
request.interceptors.request.use(
  config => {
    const { customConfig = {} } = config
    const {
      withBaseURL = true,
      withToken = true,
      withTimestamp = !!config.data,
      withSign = !!config.data
    } = customConfig
    // 添加 token
    if (withToken) {
      const token = store.getters['token']
      if (token) {
        config.headers.token = token
      } else {
        logout('您的账号已在其他地方登录，请重新登录')
      }
    }
    // 添加 baseURL
    if (withBaseURL) {
      config.baseURL += BASE_URL
    }
    // 添加 timestamp 属性
    if (withTimestamp) {
      config.data.timestamp = (Date.now() / 1000).toFixed(0)
    }
    // 添加签名属性
    if (withSign) {
      config.data.sign = MD5(JSON.stringify(sortObject(config.data)))
    }
    return config
  },
  error => Promise.reject(error)
)

/** 响应拦截器 */
request.interceptors.response.use(
  ({ data, headers, config }) => {
    if (data instanceof ArrayBuffer || data instanceof Blob) {
      return { data, headers }
    }
    if (ENCRYPTED) {
      data = JSON.parse(decryptAES(data))
      if (DEBUG) console.log(`%c${config.url} 请求：`, 'color: blue', data)
    }
    const resultCode = Number(data.resultCode)
    if (resultCode === 0) {
      return data.data
    } else if (resultCode === 110) {
      logout('token无效,请重新登录')
      return
    }
    if (data.data && data.data !== null) {
      //错误提示的时候后台,后台将错误信息放在data中
      if (data.resultMsg && data.resultMsg.indexOf(data.data) === -1)
        data.resultMsg = `${data.resultMsg},${data.data}`
    }

    const { customConfig = {} } = config
    const { errorMsg = true } = customConfig
    // const error = new Error(data.resultMsg)
    // error.name = resultCode
    if (errorMsg) message.error(data.resultMsg)
    return Promise.reject()
  },
  error => Promise.reject(error)
)

/**
 * 获取代理跨域配置
 */
export function getProxy() {
  const ENV = process.env.VUE_APP_MODE.toLowerCase()
  switch (ENV) {
    case 'production': // npm run build
      return { PREFIX: '', ENCRYPTED: true }
    case 'prod':
      return { PREFIX: '/prod', ENCRYPTED: true }
    case 'test': //npm run build-mode test
      return { PREFIX: '', ENCRYPTED: false }
    case 'development':
      return { PREFIX: '/test', ENCRYPTED: false }
    default:
      return { PREFIX: '', ENCRYPTED: false }
  }
}

/**
 * 文件上传
 * @param {*} token
 * @param {*} url
 * @param {*} formData
 * @param {*} cancelToken
 * @param {*} callback
 * @returns
 */
export function uploadFile(
  token,
  url,
  formData,
  cancelToken,
  callback,
  params = {}
) {
  return new Promise((resolve, reject) => {
    axios({
      baseURL: `${getProxy().PREFIX}/crm/api`,
      method: 'post',
      url,
      params: params || {},
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data', token: token },
      cancelToken, // 取消事件
      onUploadProgress(progressEvent) {
        // 上传进度条事件
        if (progressEvent.lengthComputable) {
          callback(progressEvent)
        }
      }
    })
      .then(res => {
        let data

        if (ENCRYPTED) data = JSON.parse(decryptAES(res.data))
        else data = res.data
        const resultCode = Number(data.resultCode)

        if (resultCode === 0) {
          resolve(data.data)
        } else if (resultCode === 110) {
          reject('token无效,上传失败')
        }

        if (data.data && data.data !== null) {
          //错误提示的时候后台,后台将错误信息放在data中
          if (data.resultMsg && data.resultMsg.indexOf(data.data) === -1)
            data.resultMsg = `${data.resultMsg},${data.data}`
        }

        reject(data.resultMsg)
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          reject(error)
        } else {
          reject(error)
        }
      })
  })
}

/**
 * 文件导出或下载（excel）
 * @param {*} token
 * @param {*} url
 * @param {*} conditions
 * @param {*} cancelToken
 * @param {*} callback
 * @returns
 */
export function downloadFile(
  token,
  url,
  conditions = [],
  type,
  cancelToken,
  callback
) {
  const fileType = type === 'excel' ? 'application/vnd.ms-excel' : 'text/csv'
  return new Promise((resolve, reject) => {
    axios({
      baseURL: `${getProxy().PREFIX}/crm/api`,
      method: 'post',
      url,
      data: conditions,
      responseType: 'blob',
      headers: { token },
      cancelToken, // 取消事件

      onDownloadProgress(progressEvent) {
        // 导出进度条事件
        if (progressEvent.lengthComputable) {
          callback(progressEvent)
        }
      }
    })
      .then(res => {
        const blob = new Blob([res.data], { type: fileType })
        const url = URL.createObjectURL(blob)
        resolve(url)
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          reject(error)
        } else {
          reject(error)
        }
      })
  })
}

export default request
