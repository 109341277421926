const REIM_BILL = [
  {
    title: '申请人信息',
    titleVerify: [1, 2, 3, 4],
    children: [
      { label: '申请人', value: `applicantDTO`, subValue: 'username' },
      { label: '岗位', value: `post` },
      { label: '联系方式', value: `contact` },
      { label: '申请日期', value: `appdate` },
      { label: '大区/部门', value: `regionDTO`, subValue: 'deptname' },
      { label: '省办', value: `provinceDTO`, subValue: 'deptname' },
      { label: '地办', value: `localityDTO`, subValue: 'deptname' },
      { label: '部门', value: `departmentDTO`, subValue: 'deptname' }
    ]
  },
  {
    title: '活动总结信息',
    titleVerify: [1, 2, 3, 4],
    children: [
      { label: '会议名称', value: `conferenceName` },
      { label: '会议编号', value: `conferenceCode` },
      { label: '所属公司', value: `conferenceTypeDTO`, subValue: 'name' },
      { label: '业务类型', value: `businessTypeDTO`, subValue: 'name' },
      { label: '产品', value: `productDTO`, subValue: 'name' },
      { label: '参会客户到场人数', value: `reimTurnout`, verify: [1, 2, 4] },
      { label: '内部人员到场人数', value: `internalnum`, verify: [1, 2, 4] },
      {
        label: '实际活动地点',
        value: `actualActivityPlace`,
        verify: [1, 2, 4]
      },
      { label: '实际开始日期', value: `actualActivityStdate` },
      { label: '实际结束日期', value: `actualActivityEddate` },
      { label: '签到表', value: `signinForm`, type: 'file', verify: [1, 2, 4] },
      { label: '现场照片', value: `pictures`, type: 'file', verify: [1, 2, 4] },
      {
        label: '讲者资费凭证',
        value: `consultationFeeProof`,
        type: 'file',
        verify: [1, 2, 4]
      },
      {
        label: '活动总结',
        value: `activitySummary`,
        type: 'file',
        verify: [1, 2, 4]
      },
      {
        label: '协办第三方资料',
        value: `cooperationInfo`,
        type: 'file',
        verify: [1, 2, 4]
      },
      {
        label: '信息发布-文章网络连接',
        value: `articleNetworkLink`,
        type: 'file',
        verify: [3, 4]
      },
      {
        label: '信息发布-项目总结',
        value: `projectSummary`,
        type: 'file',
        verify: [3, 4]
      }
    ]
  }
]

const REIM_TABLE = [
  {
    title: '讲者信息',
    titleVerify: [1, 2, 4],
    dataList: 'speakers',
    columns: [
      { title: '编号', dataIndex: 'code' },
      { title: '名称', dataIndex: 'name' },
      { title: '单位', dataIndex: 'departmentName' },
      { title: '级别', dataIndex: 'speakerLevelName' },
      { title: '咨询费', dataIndex: 'appConsultingFee' },
      { title: '课题费', dataIndex: 'topicname' },
      { title: '讲课时长/分钟', dataIndex: 'lengthoflecture' }
    ]
  },
  {
    title: '推广物料信息',
    titleVerify: [1, 2, 4],
    dataList: 'brandpreInfoDTOs',
    columns: [
      { title: '编号', dataIndex: 'materialCode' },
      { title: '类型', dataIndex: 'type' },
      { title: '物料名称', dataIndex: 'materialName' },
      { title: '物料标识', dataIndex: 'materialIdentification' },
      { title: '起订量单价', dataIndex: 'price' },
      { title: '申请数量', dataIndex: 'brandpreNumApp' },
      {
        title: '金额',
        dataIndex: 'brandpreMoneyamount',
        scopedSlots: { customRender: 'countPrice' }
      },
      { title: '备注', dataIndex: 'notes' }
    ]
  },
  {
    title: '实际品牌信息发布费用明细',
    titleVerify: [3, 4],
    dataList: 'expenseDetailDTOs',
    columns: [
      { title: '序号', dataIndex: 'seriNumber' },
      { title: '合作方名称', dataIndex: 'partnerName' },
      { title: '合作金额', dataIndex: 'copAmount' }
    ]
  }
]

const REIM_FEE = {
  title: '费用信息',
  columns: [
    {
      title: '项目',
      dataIndex: 'projectName'
    },
    {
      title: '预算',
      children: [
        {
          title: '单价',
          dataIndex: 'reimPrice'
        },
        {
          title: '数量',
          dataIndex: 'reimNum'
        },
        {
          title: '总价',
          dataIndex: 'reimTotal'
        }
      ]
    },
    {
      title: '实际花销',
      children: [
        {
          title: '单价',
          dataIndex: 'price'
        },
        {
          title: '数量',
          dataIndex: 'num'
        },
        {
          title: '总价',
          dataIndex: 'total'
        }
      ]
    }
  ],
  dataList: [
    {
      id: 'Booth',
      projectName: '展位(场地费)',
      reimPrice: 'reimbudgetBooth',
      reimNum: 'reimbudgetBoothNum',
      reimTotal: '',
      price: 'budgetBooth',
      num: 'budgetBoothNum',
      total: ''
    },
    {
      id: 'Meetingroom',
      projectName: '会议室(场地费)',
      reimPrice: 'reimbudgetMeetingroom',
      reimNum: 'reimbudgetMeetingroomNum',
      reimTotal: '',
      price: 'budgetMeetingroom',
      num: 'budgetMeetingroomNum',
      total: ''
    },
    {
      id: 'Make',
      projectName: '制作费',
      reimPrice: 'reimbudgetMake',
      reimNum: 'reimbudgetMakeNum',
      reimTotal: '',
      price: 'budgetMake',
      num: 'budgetMakeNum',
      total: ''
    },
    {
      id: 'Design',
      projectName: '设计费',
      reimPrice: 'reimbudgetDesign',
      reimNum: 'reimbudgetDesignNum',
      reimTotal: '',
      price: 'budgetDesign',
      num: 'budgetDesignNum',
      total: ''
    },
    {
      id: 'Flight',
      projectName: '飞机费',
      reimPrice: 'reimbudgetFlight',
      reimNum: 'reimbudgetFlightNum',
      reimTotal: '',
      price: 'budgetFlight',
      num: 'budgetFlightNum',
      total: ''
    },
    {
      id: 'Train',
      projectName: '高铁费',
      reimPrice: 'reimbudgetTrain',
      reimNum: 'reimbudgetTrainNum',
      reimTotal: '',
      price: 'budgetTrain',
      num: 'budgetTrainNum',
      total: ''
    },
    {
      id: 'Reception',
      projectName: '接送费',
      reimPrice: 'reimbudgetReception',
      reimNum: 'reimbudgetReceptionNum',
      reimTotal: '',
      price: 'budgetReception',
      num: 'budgetReceptionNum',
      total: ''
    },
    {
      id: 'Accommodation',
      projectName: '住宿费',
      reimPrice: 'reimbudgetAccommodation',
      reimNum: 'reimbudgetAccommodationNum',
      reimTotal: '',
      price: 'budgetAccommodation',
      num: 'budgetAccommodationNum',
      total: ''
    },
    {
      id: 'Meals',
      projectName: '餐费',
      reimPrice: 'reimbudgetMeals',
      reimNum: 'reimbudgetMealsNum',
      reimTotal: '',
      price: 'budgetMeals',
      num: 'budgetMealsNum',
      total: ''
    },
    {
      id: 'Thirdparty',
      projectName: '第三方会务人员费用',
      reimPrice: 'reimbudgetThirdparty',
      reimNum: 'reimbudgetThirdpartyNum',
      reimTotal: '',
      price: 'budgetThirdparty',
      num: 'budgetThirdpartyNum',
      total: ''
    },
    {
      id: 'Service',
      projectName: '会务服务费、税费',
      reimPrice: '--',
      reimNum: '--',
      reimTotal: 'reimbudgetServiceTotal',
      price: '--',
      num: '--',
      total: 'budgetServiceTotal'
    },
    {
      id: 'Cooperation',
      projectName: '协办费',
      reimPrice: '--',
      reimNum: '--',
      reimTotal: 'reimbudgetCooperationTotal',
      price: '--',
      num: '--',
      total: 'budgetCooperationTotal'
    },
    {
      id: 'Consult',
      projectName: '讲者咨询费',
      reimPrice: '--',
      reimNum: '--',
      reimTotal: 'reimbudgetConsultTotal',
      price: '--',
      num: '--',
      total: 'budgetConsultTotal'
    },
    {
      id: 'Brandpre',
      projectName: '推广物料费',
      reimPrice: '--',
      reimNum: '--',
      reimTotal: 'reimbudgetBrandpreTotal',
      price: '--',
      num: '--',
      total: 'budgetBrandpreTotal'
    },
    {
      id: 'OtherFee',
      projectName: '其他费用',
      reimPrice: '--',
      reimNum: '--',
      reimTotal: 'reimOtherFee',
      price: '--',
      num: '--',
      total: 'otherFee'
    },
    {
      id: 'RegisterFee',
      projectName: '注册费',
      reimPrice: '--',
      reimNum: '--',
      reimTotal: 'reimRegisterFee',
      price: '--',
      num: '--',
      total: 'registerFee'
    },
    {
      id: 'InfoPubAmount',
      projectName: '信息发布费',
      reimPrice: '--',
      reimNum: '--',
      reimTotal: 'reimInfoPubAmount',
      price: '--',
      num: '--',
      total: 'infoPubAmount'
    },
    {
      id: 'OnlineLiveBroadcastingFee',
      projectName: '线上直播平台费用',
      reimPrice: '--',
      reimNum: '--',
      reimTotal: 'reimOnlineLiveBroadcastingFee',
      price: '--',
      num: '--',
      total: 'onlineLiveBroadcastingFee'
    }
  ]
}

export { REIM_BILL, REIM_TABLE, REIM_FEE }
