// 非学术商业返款协议
import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 分页查询非学术商业返款协议信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function geBusinessList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `businessRefundContract/head/${pageindex}/${pagenum}`,
    data: conditions
  })
}

export function geBusinessApplicationList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `businessRefundContract/app/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 删除非学术商业返款协议
 * @param {String} id
 * @returns
 */
export function delBusiness(id) {
  return request({
    method: 'post',
    url: `businessRefundContract/del/${id}`
  })
}

/**
 * 根据ID来非学术商业返款协议信息
 * @param {String} id
 * @returns
 */
export function getBusiness(id) {
  return request({
    method: 'get',
    url: `businessRefundContract/${id}`
  })
}

/**
 * 保存非学术商业返款协议
 * @param {Object} data
 * @returns
 */
export function saveBusiness(data) {
  return request({
    method: 'post',
    url: `businessRefundContract/save`,
    data
  })
}

/**
 * 更新非学术商业返款协议
 * @param {Object} data
 * @returns
 */
export function updateBusiness(data) {
  return request({
    method: 'post',
    url: `businessRefundContract/update`,
    data
  })
}

/**
 * 提交非学术商业返款协议
 * @param {Object} data
 * @returns
 */
export function submitBusiness(data) {
  return request({
    method: 'put',
    url: `oaflow/submitBusiRefundContract`,
    data
  })
}

/**
 * 导出非学术商业返款协议信息
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/businessRefundContract/export`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `非学术商业返款协议列表`)
  })
}
