import FlowDirect from '@/views/flowdirect'
export default {
  path: 'flowdirect',
  name: 'flowdirect',
  component: FlowDirect,
  redirect: 'flowdirect/businessManage',
  meta: { title: '流向直连' },
  children: [
    {
      path: 'businessManage',
      name: 'businessManage',
      component: () => import('@/views/flowdirect/businessManage'),
      meta: { title: '商业档案', menuKey: 'businessManage' }
    },
    {
      path: 'addBusiness',
      name: 'addBusiness',
      component: () => import('@/views/flowdirect/businessManage/AddBusiness'),
      meta: { title: '新增商业档案', menuKey: 'businessManage' }
    },
    {
      path: 'businessDetail',
      name: 'businessDetail',
      component: () =>
        import('@/views/flowdirect/businessManage/BusinessDetail'),
      meta: { title: '商业档案详情', menuKey: 'businessManage' }
    },
    // 中国行政区域
    {
      path: 'areaManage',
      name: 'areaManage',
      component: () => import('@/views/flowdirect/areaManage'),
      meta: { title: '中国行政区域', menuKey: 'areaManage' }
    },
    // 异常产品档案
    {
      path: 'productExceptionManage',
      name: 'productExceptionManage',
      component: () => import('@/views/flowdirect/productExceptionManage'),
      meta: { title: '异常产品档案', menuKey: 'productExceptionManage' }
    },
    {
      path: 'addProductException',
      name: 'addProductException',
      component: () =>
        import(
          '@/views/flowdirect/productExceptionManage/AddProductionException'
        ),
      meta: {
        title: '新增异常产品档案',
        menuKey: 'productExceptionManage'
      }
    },
    {
      path: 'productExceptionDetail',
      name: 'productExceptionDetail',
      component: () =>
        import(
          '@/views/flowdirect/productExceptionManage/ProductionExceptionDetail'
        ),
      meta: {
        title: '异常产品档案详情',
        menuKey: 'productExceptionManage'
      }
    },
    // 品种业务线档案
    {
      path: 'varietyBusinessLineManage',
      name: 'varietyBusinessLineManage',
      component: () => import('@/views/flowdirect/varietyBusinessLineManage'),
      meta: { title: '品种业务线档案', menuKey: 'varietyBusinessLineManage' }
    },
    {
      path: 'addVarietyBusinessLine',
      name: 'addVarietyBusinessLine',
      component: () =>
        import(
          '@/views/flowdirect/varietyBusinessLineManage/AddVarietyBusiness'
        ),
      meta: {
        title: '新增品种业务线档案',
        menuKey: 'varietyBusinessLineManage'
      }
    },
    {
      path: 'varietyBusinessLineDetail',
      name: 'varietyBusinessLineDetail',
      component: () =>
        import(
          '@/views/flowdirect/varietyBusinessLineManage/VarietyBusinessDetail'
        ),
      meta: {
        title: '品种业务线档案详情',
        menuKey: 'varietyBusinessLineManage'
      }
    },

    // 档案异名管理
    {
      path: 'synonymManage',
      name: 'synonymManage',
      component: () => import('@/views/flowdirect/synonymManage'),
      meta: { title: '档案异名管理', menuKey: 'synonymManage' }
    },
    {
      path: 'addCumstomerSynonym',
      name: 'addCumstomerSynonym',
      component: () =>
        import('@/views/flowdirect/synonymManage/customer/AddCumstomerSynonym'),
      meta: { title: '新增客户异名信息', menuKey: 'synonymManage' }
    },
    {
      path: 'cumstomerSynonymDetail',
      name: 'cumstomerSynonymDetail',
      component: () =>
        import(
          '@/views/flowdirect/synonymManage/customer/CumstomerSynonymDetail'
        ),
      meta: { title: '客户异名信息详情', menuKey: 'synonymManage' }
    },
    {
      path: 'addProductSynonym',
      name: 'addProductSynonym',
      component: () =>
        import('@/views/flowdirect/synonymManage/product/AddProductSynonym'),
      meta: { title: '新增产品异名信息', menuKey: 'synonymManage' }
    },
    {
      path: 'productSynonymDetail',
      name: 'productSynonymDetail',
      component: () =>
        import('@/views/flowdirect/synonymManage/product/ProductSynonymDetail'),
      meta: { title: '产品异名信息详情', menuKey: 'synonymManage' }
    },

    // 流向抓取
    {
      path: 'flowToGrab',
      name: 'flowToGrab',
      component: () => import('@/views/flowdirect/flowToGrab'),
      meta: { title: '流向抓取', menuKey: 'flowToGrab' }
    },
    {
      path: 'flowLogDetail',
      name: 'flowLogDetail',
      component: () => import('@/views/flowdirect/flowToGrab/LogDetail'),
      meta: { title: '流向抓取日志详情', menuKey: 'flowToGrab' }
    },
    {
      path: 'exportFlowDetail',
      name: 'exportFlowDetail',
      component: () => import('@/views/flowdirect/flowToGrab/ExportFlowDetail'),
      meta: { title: '流向抓取导入流向详情', menuKey: 'flowToGrab' }
    },
    {
      path: 'formalDetail',
      name: 'formalDetail',
      component: () => import('@/views/flowdirect/flowToGrab/FormalDetail'),
      meta: { title: '正式流向详情', menuKey: 'flowToGrab' }
    },
    {
      path: 'formalFlowDetail',
      name: 'formalFlowDetail',
      component: () => import('@/views/flowdirect/flowToGrab/FormalFlowDetail'),
      meta: { title: '流向抓取正式流向详情', menuKey: 'flowToGrab' }
    },

    // 商业库存抓取
    {
      path: 'stockGrab',
      name: 'stockGrab',
      component: () => import('@/views/flowdirect/stockGrab'),
      meta: { title: '商业库存抓取', menuKey: 'stockGrab' }
    },
    {
      path: 'stockLogDetail',
      name: 'stockLogDetail',
      component: () => import('@/views/flowdirect/stockGrab/LogDetail'),
      meta: { title: '商业库存抓取日志详情', menuKey: 'stockGrab' }
    },
    {
      path: 'formalStockDetail',
      name: 'formalStockDetail',
      component: () => import('@/views/flowdirect/stockGrab/FormalStockDetail'),
      meta: { title: '商业库存抓取正式流向详情', menuKey: 'stockGrab' }
    },

    // 商业入库抓取
    {
      path: 'warehouseGrab',
      name: 'warehouseGrab',
      component: () => import('@/views/flowdirect/warehouseGrab'),
      meta: { title: '商业入库抓取', menuKey: 'warehouseGrab' }
    },
    {
      path: 'warehouseLogDetail',
      name: 'warehouseLogDetail',
      component: () => import('@/views/flowdirect/warehouseGrab/LogDetail'),
      meta: { title: '商业入库抓取日志详情', menuKey: 'warehouseGrab' }
    },
    {
      path: 'formalWarehouseDetail',
      name: 'formalWarehouseDetail',
      component: () =>
        import('@/views/flowdirect/warehouseGrab/FormalWarehouseDetail'),
      meta: { title: '商业入库抓取正式流向详情', menuKey: 'warehouseGrab' }
    },

    // 医院单据清洗
    {
      path: 'hospAppAnalysis',
      name: 'hospAppAnalysis',
      component: () => import('@/views/flowdirect/hospAppAnalysis'),
      meta: { title: '医院单据清洗', menuKey: 'hospAppAnalysis' }
    },

    // NC-CRM医院清洗
    {
      path: 'ncCrmTermiClean',
      name: 'ncCrmTermiClean',
      component: () => import('@/views/flowdirect/ncCrmTermiClean'),
      meta: { title: 'NC-CRM医院清洗', menuKey: 'ncCrmTermiClean' }
    },
    {
      // 终端同步批处理
      path: 'hospModBatch',
      name: 'hospModBatch',
      component: () => import('@/views/flowdirect/hospModBatch'),
      meta: { title: '终端同步批处理', menuKey: 'hospModBatch' }
    }
  ]
}
