import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Viewer from 'v-viewer'
import hevueImgPreview from 'hevue-img-preview'

import '@/core'
import '@/styles/index.less'
import 'viewerjs/dist/viewer.css'
import 'hevue-img-preview/css/theme-dark.css'

Vue.use(hevueImgPreview)
Vue.use(Viewer, {
  defaultOptions: {
    initialViewIndex: 2,
    inline: true,
    button: false, //右上角按钮
    navbar: true, //底部缩略图
    title: false, //当前图片标题
    toolbar: true, //底部工具栏
    tooltip: true, //显示缩放百分比
    movable: true, //是否可以移动
    zoomable: true, //是否可以缩放
    rotatable: true, //是否可旋转
    scalable: true, //是否可翻转
    transition: true, //使用 CSS3 过度
    fullscreen: true, //播放时是否全屏
    keyboard: true //是否支持键盘
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
