import Activity from '@/views/activity'
export default {
  path: 'activity',
  component: Activity,
  redirect: 'activityApplication',
  name: 'activity',
  meta: { title: '活动' },
  children: [
    {
      path: 'activityApplication',
      name: 'activityApplication',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicApplication/MyAdemicApplicationTable'
        ),
      meta: { title: '学术推广活动申请列表', menuKey: 'activityApplication' }
    },
    {
      path: 'activityReimbursement',
      name: 'activityReimbursement',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicExpense/MyAdemicExpenseTable'
        ),
      meta: { title: '学术推广活动总结列表', menuKey: 'activityReimbursement' }
    },
    {
      path: 'academicApplicationForm',
      name: 'academicApplicationForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicApplication/AcademicApplicationForm'
        ),
      meta: { title: '学术推广活动申请单', menuKey: 'activityApplication' }
    },
    {
      path: 'appForm',
      name: 'appForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicApplication/academic-v2/AppForm'
        ),
      meta: { title: '学术推广活动申请单', menuKey: 'activityApplication' }
    },
    {
      path: 'activityReimbursementForm',
      name: 'activityReimbursementForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicExpense/AcademicExpenseForm'
        ),
      meta: { title: '学术推广活动总结单', menuKey: 'activityReimbursement' }
    },
    {
      path: 'exReimForm',
      name: 'exReimForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicExpense/academic-v2/ReimForm'
        ),
      meta: { title: '学术推广活动总结单', menuKey: 'activityReimbursement' }
    },
    {
      path: 'modifyForm',
      name: 'modifyForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicApplication/modify/ModifyForm'
        ),
      meta: {
        title: '学术推广活动申请变更单',
        menuKey: 'activityApplication'
      }
    },
    {
      path: 'activityAppModify',
      name: 'activityAppModify',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicModify/AcademicModifyList'
        ),
      meta: {
        title: '学术推广活动申请变更单列表',
        menuKey: 'activityAppModify'
      }
    },
    {
      path: 'academicModifyForm',
      name: 'academicModifyForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicModify/AcademicModifyForm'
        ),
      meta: {
        title: '学术推广活动申请变更单详情',
        menuKey: 'activityAppModify'
      }
    },
    {
      path: 'nonMeetingApp',
      name: 'nonMeetingApp',
      component: () =>
        import(/* webpackChunkName: '' */ '@/views/activity/nonMeeting'),
      meta: {
        title: '非会议类学术项目申请列表',
        menuKey: 'nonMeetingApp'
      }
    },
    {
      path: 'nonMeetForm',
      name: 'nonMeetForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/nonMeeting/NonMeetForm'
        ),
      meta: { title: '非会议类学术项目申请', menuKey: 'nonMeetingApp' }
    },
    {
      // 学术会议照片库
      path: 'photoManage',
      name: 'photoManage',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/activity/photoManager'
        ),
      meta: { title: '学术会议照片库', menuKey: 'photoManage' }
    },
    {
      // 学术会议照片库相似度查询
      path: 'photoManageSimQuery',
      name: 'photoManageSimQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/activity/photoManagerSim'
        ),
      meta: { title: '学术会议照片库相似度', menuKey: 'photoManageSimQuery' }
    },
    {
      path: 'activityType',
      name: 'activityType',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/activityType/ActivityTypeList'
        ),
      meta: { title: '活动类型及执行标准列表', menuKey: 'activityType' }
    },
    // 会议拍照权限
    {
      path: 'activityPhotoRight',
      name: 'activityPhotoRight',
      component: () =>
        import(/* webpackChunkName: '' */ '@/views/activity/photoPerm'),
      meta: { title: '会议拍照权限', menuKey: 'activityPhotoRight' }
    },
    {
      path: 'activityTypeForm',
      name: 'activityTypeForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/activityType/ActivityTypeForm'
        ),
      meta: { title: '活动类型及执行标准详情', menuKey: 'activityType' }
    },
    {
      path: 'brandPresentation',
      name: 'brandPresentation',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/activityMaterial/PresentationList'
        ),
      meta: { title: '推广物料列表', menuKey: 'brandPresentation' }
    },
    {
      path: 'presentationForm',
      name: 'presentationForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/activityMaterial/PresentationForm'
        ),
      meta: { title: '推广物料详情', menuKey: 'brandPresentation' }
    },
    {
      path: 'datasControlByRole',
      name: 'datasControlByRole',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/activeTypeByRole/ActTypeRoleList'
        ),
      meta: { title: '活动控制列表', menuKey: 'datasControlByRole' }
    },
    {
      path: 'addActTypeRole',
      name: 'addActTypeRole',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/activeTypeByRole/AddActTypeRole'
        ),
      meta: { title: '新增活动控制', menuKey: 'datasControlByRole' }
    },
    {
      path: 'actTypeRoleDetial',
      name: 'actTypeRoleDetial',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/activeTypeByRole/ActTypeRoleDetial'
        ),
      meta: { title: '活动控制详情', menuKey: 'datasControlByRole' }
    },
    {
      path: 'batchSync',
      name: 'batchSync',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/batchTransfer/BatchTransferList'
        ),
      meta: { title: '同步批处理列表', menuKey: 'batchSync' }
    },
    {
      path: 'billHandedOver',
      name: 'billHandedOver',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/handedOver/HandedOverList'
        ),
      meta: { title: '单据移交', menuKey: 'billHandedOver' }
    },
    {
      path: 'handedOverRecord',
      name: 'handedOverRecord',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/handedOver/HandedOverRecordList'
        ),
      meta: { title: '移交记录', menuKey: 'billHandedOver' }
    },
    {
      path: 'proxyApprove',
      name: 'proxyApprove',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/proxyApprove/ProxyApproveList'
        ),
      meta: { title: '审批移交', menuKey: 'proxyApprove' }
    },
    {
      path: 'proxyRecord',
      name: 'proxyRecord',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/proxyApprove/ProxyRecordList'
        ),
      meta: { title: '移交记录', menuKey: 'proxyApprove' }
    },
    {
      path: 'academicImprest',
      name: 'academicImprest',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicApplication/imprest/AcademicImprest'
        ),
      meta: { title: '预付款单', menuKey: 'activityApplication' }
    },
    {
      path: 'privateExpenseMsg',
      name: 'privateExpenseMsg',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicExpense/private/PrivateExpenseMsg'
        ),
      meta: { title: '对私付款单', menuKey: 'activityReimbursement' }
    },
    {
      path: 'pubilcExpenseMsg',
      name: 'pubilcExpenseMsg',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicExpense/public/PublicExpenseMsg'
        ),
      meta: { title: '对公付款单', menuKey: 'activityReimbursement' }
    },
    {
      path: 'recept-msg',
      name: 'receptMsg',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/academicApplication/recept/ReceptMsg'
        ),
      meta: { title: '接待申请单', menuKey: 'activityApplication' }
    },

    // 检测机构单价
    {
      path: 'hcvAgencyDoc',
      name: 'hcvAgencyDoc',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/hcvAgencyDoc/HcvAgencyList'
        ),
      meta: { title: '检测机构单价', menuKey: 'hcvAgencyDoc' }
    },
    {
      path: 'hcvAgencyForm',
      name: 'hcvAgencyForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/hcvAgencyDoc/HcvAgencyForm'
        ),
      meta: { title: '检测机构单价详情', menuKey: 'hcvAgencyDoc' }
    },

    // 丙肝公费送检申请单
    {
      path: 'hcvApplication',
      name: 'hcvApplication',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/hcvApplication/HcvAppList'
        ),
      meta: { title: '丙肝公费送检申请单', menuKey: 'hcvApplication' }
    },
    {
      path: 'hcvAppForm',
      name: 'hcvAppForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/hcvApplication/HcvAppForm'
        ),
      meta: { title: '丙肝公费送检申请单详情', menuKey: 'hcvApplication' }
    },

    // 丙肝公费送检核销单
    {
      path: 'hcvWriteOff',
      name: 'hcvWriteOff',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/hcvWriteOff/HcvReimList'
        ),
      meta: { title: '丙肝公费送检核销单', menuKey: 'hcvWriteOff' }
    },
    {
      path: 'hcvWriteOffForm',
      name: 'hcvWriteOffForm',
      component: () =>
        import(
          /* webpackChunkName: '' */ '@/views/activity/hcvWriteOff/HcvReimForm'
        ),
      meta: { title: '丙肝公费送检核销单详情', menuKey: 'hcvWriteOff' }
    }
  ]
}
