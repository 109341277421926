import request from '@/utils/request'

/**
 * 查询接待费用申请
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {String} pkSrc
 * @returns
 */
export function getReceptList(pageindex, pagenum, pkSrc) {
  return request({
    url: `/recApp/${pageindex}/${pagenum}`,
    method: 'post',
    params: {
      pkSrc
    }
  })
}

/**
 * 接待费用申请，新增前校验
 * @param {String} pkSrc 申请单的id
 * @returns
 */
export function checkRecApp(pkSrc) {
  return request({
    url: `/recApp/check`,
    method: 'post',
    params: { pkSrc }
  })
}

/**
 * 添加接待费用申请
 * @param {Object} data
 * @returns
 */
export function addReceptBill(data) {
  return request({
    url: `/recApp/add`,
    method: 'post',
    data
  })
}

/**
 * 删除接待费用申请
 * @param {Object} data
 * @returns
 */
export function delReceptBill(id) {
  return request({
    url: `/recApp/del`,
    method: 'post',
    params: { id }
  })
}

/**
 * 获取接待费用申请
 * @param {String} id
 * @returns
 */
export function getReceptBill(id) {
  return request({
    url: `/recApp/getOne`,
    method: 'get',
    params: { id }
  })
}

/**
 * 修改接待费用申请
 * @param {Object} data
 * @returns
 */
export function updateReceptBill(data) {
  return request({
    url: `/recApp/upd`,
    method: 'post',
    data
  })
}

/**
 * 审批时,修改接待费用申请接口
 * @param {Object} data
 * @returns
 */
export function updApproveReceptBill(data) {
  return request({
    url: `/recApp/updApprove`,
    method: 'post',
    data
  })
}

/**
 * 提交接待费用申请
 * @param {Object} data
 * @returns
 */
export function submitAppRec(data) {
  return request({
    url: `/oaflow/submitAppRec`,
    method: 'put',
    data
  })
}
