import request from '@/utils/request'

//讲者列表
export function loadTableData(page, params) {
  return request({
    url: `/speaker/${page.current}/${page.pageSize}`,
    method: 'post',
    data: params
  })
}
//加载讲者详情
export function loadSpeakInfo(speakerid, params) {
  return request({
    url: `/speaker/${speakerid}`,
    method: 'get',
    data: {
      ...params
    }
  })
}

//更新讲者详情
export function putSpeakInfo(speakerid, data) {
  return request({
    url: `/speaker/${speakerid}`,
    method: 'put',
    data: data
  })
}

// 新增讲者
export function saveSpeakInfo(data) {
  return request({
    url: `/speaker`,
    method: 'post',
    data: data
  })
}

//删除讲者
export function deleteSpeaker(speakerid) {
  return request({
    url: `/speaker/${speakerid}`,
    method: 'delete'
  })
}

//获取附件列表
export function getSpeakerAttachs(speakerid, params) {
  return request({
    url: `/nfile/getDatasByPID/${speakerid}`,
    method: 'get',
    params: {
      ...params
    }
  })
}

//修改附件名称
export function updateSpeakerAttach(attachid, data) {
  return request({
    url: `/nfile/${attachid}`,
    method: 'put',
    data: data
  })
}

//附件下载
export function downloadSpeakerAttach(attachid) {
  return request({
    url: `/nfile/download`,
    method: 'get',
    responseType: 'blob',
    params: {
      id: attachid
    }
  })
}

//删除附件
export function deleteSpeakerAttach(attachid, params) {
  return request({
    url: `/nfile/${attachid}`,
    method: 'delete',
    data: {
      ...params
    }
  })
}
