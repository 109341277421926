<!-- 费用管理 -->
<template>
  <!-- 内容区域 -->
  <transition name="fade-transform" mode="out-in">
    <router-view />
  </transition>
</template>

<script>
export default {
  name: 'fee',
  data() {
    return {}
  }
}
</script>
