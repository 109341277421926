const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '单据号', value: `vbillno` },
      { label: '业务日期', value: `vbilldate` },
      { label: '发货组织', value: `outerCompanyName` },
      { label: '客户', value: `busiCompName` },
      { label: '开票地址电话', value: `billingAddressAndPhoneNumber` },
      { label: '税号', value: `taxNumber` },
      { label: '付款方式', value: 'payMethodName' },
      { label: '开户行', value: `bankAcountName` },
      { label: '开户账号', value: `bankAcountNumber` },
      { label: '省办', value: `pkProvName` },
      { label: '收货地址', value: `deliveryAddr` },
      { label: '收货人', value: `consignee` },
      { label: '收货电话', value: `consigneePhone` },
      { label: '收票地址', value: `billToAddr` },
      { label: '收票人', value: `checkRecipient` },
      { label: '收票电话', value: `checkRecipientPhone` },
      {
        label: '合同及汇款单上传',
        value: `contract`,
        type: 'btn'
      },
      { label: '金额合计', value: 'totalAmount' },
      { label: '创建人', value: `creatorName` },
      { label: '创建时间', value: `creationtime` },
      { label: '修改人', value: `modifierName` },
      { label: '修改时间', value: `modifytime` },
      { label: '备注', value: `note`, type: 'textarea' }
    ]
  }
]
const TABLE = [
  {
    title: '信息列表',
    active: 'TABLE',
    dataList: 'bodyDtos',
    columns: [
      {
        title: '订单日期',
        dataIndex: 'issuancedate',
        ellipsis: true,
        width: 72
      },
      {
        title: '品种编码',
        dataIndex: 'materialCode',
        ellipsis: true,
        width: 120
      },
      {
        title: '品种名称',
        dataIndex: 'materialName',
        ellipsis: true,
        width: 120
      },
      {
        title: '规格',
        dataIndex: 'materialSpec',
        ellipsis: true,
        width: 120
      },
      {
        title: '单位',
        dataIndex: 'unit',
        width: 48,
        ellipsis: true
      },
      { title: '单价', dataIndex: 'unitPrice', width: 72 },
      { title: '数量', dataIndex: 'num', width: 72 },
      { title: '金额', dataIndex: 'amount', width: 72 },
      {
        title: '批次号',
        dataIndex: 'batchNumber',
        ellipsis: true,
        width: 72
      },
      { title: '已核销数量', dataIndex: 'cancelno', width: 96 },
      { title: '已核销金额', dataIndex: 'cancelMny', width: 96 },
      {
        title: '到账日期',
        width: 72,
        dataIndex: 'gainedDate'
      }
    ]
  }
]

export { BASE, TABLE }
