<template>
  <a-menu
    :selectedKeys="selectedKeys"
    :default-open-keys="['workplace']"
    mode="inline"
    theme="dark"
    class="menu-list"
    :openKeys.sync="openKeys"
  >
    <template v-for="{ key, icon, svgicon, title, submenus } in authMenus">
      <a-sub-menu v-if="submenus && submenus.length > 0" :key="key">
        <span slot="title">
          <icon-font v-if="icon" :type="icon"></icon-font>
          <span>{{ title }}</span>
        </span>
        <a-menu-item
          v-for="{ key: subKey, title: subTitle } in submenus"
          :key="subKey"
          @click="handleMenuClick(subKey)"
          class="sub-item"
        >
          <span>{{ subTitle }}</span>
        </a-menu-item>
      </a-sub-menu>
      <template v-else>
        <a-menu-item :key="key" @click="handleMenuClick(key)">
          <icon-font v-if="icon" :type="icon"></icon-font>
          <span>
            <icon-svg v-if="svgicon" :icon="svgicon" size="14px" />
            <span>{{ title }}</span>
          </span>
        </a-menu-item>
      </template>
    </template>
  </a-menu>
</template>

<script>
import { SIDER_MENU_LIST } from '@/configs/sider-menu'
import { mapGetters } from 'vuex'
export default {
  name: 'GlobalSider',
  data() {
    return {
      // 当前选中的菜单项
      selectedKeys: ['1'],
      // 当前展开的 subMenu 项
      openKeys: ['1'],
      menus: [
        {
          key: 'workplace'
        },
        {
          key: 'user',
          submenus: [
            {
              key: 'groupManage'
            },
            {
              key: 'userManage'
            },
            {
              key: 'roleManage'
            },
            {
              key: 'postManage'
            }
          ]
        },
        {
          key: 'customer',
          submenus: [
            {
              key: 'hospitalManage'
            },
            {
              key: 'doctorManage'
            },
            {
              key: 'speakerManage'
            },
            {
              key: 'hospitalPcApp'
            },
            {
              key: 'doctorPcApp'
            },
            {
              key: 'speakerPcApp'
            },
            {
              key: 'appModify'
            },
            {
              key: 'dataMerge'
            },
            {
              key: 'chnlArea'
            }
          ]
        },
        {
          key: 'product',
          submenus: [
            {
              key: 'productManage'
            },
            {
              key: 'productKeyInfoManage'
            },
            {
              key: 'drugForm'
            }
          ]
        },

        {
          key: 'outerSpec',
          submenus: [
            {
              key: 'externalShippingCompany'
            },
            {
              key: 'extCompShippingBill'
            },
            {
              key: 'extSpecBackBill'
            },
            {
              key: 'extVerificationBill'
            },
            {
              key: 'outerInventory'
            },
            {
              key: 'outerHospSpec'
            },
            {
              key: 'outerFlowBill'
            },
            {
              key: 'outerAllocateBill'
            },
            {
              key: 'outerBackFlowVerify'
            },
            {
              key: 'outerBusiFeeCalc'
            },
            {
              key: 'genericDrugsPayAgre'
            }
          ]
        },
        {
          key: 'saleManage',
          submenus: [
            {
              key: 'monthlySalesTarget'
            },
            {
              key: 'provinceIndex'
            },
            {
              key: 'indexMonthProportion'
            },
            {
              key: 'hospitalSaleIndex'
            },
            {
              key: 'receiveMnyConfirm'
            },
            {
              key: 'chsDigitContract'
            },
            {
              key: 'chsClerkMonthInv'
            },
            {
              key: 'chsClearInv'
            },
            {
              key: 'businessRefundContract'
            },
            {
              key: 'partnerAgreementRefund'
            },
            {
              key: 'nonAcademicRefundContract'
            },
            {
              key: 'proofOfDelivery'
            },
            {
              key: 'salesArea'
            },
            {
              key: 'postDoctor'
            },
            {
              key: 'targetDoctorManage'
            },
            {
              key: 'visit'
            },
            {
              key: 'followUpVisit'
            },
            {
              key: 'buqianPcApp'
            },
            {
              key: 'outsideTime'
            },
            {
              key: 'storeManage'
            },
            {
              key: 'targetHospApprove'
            },
            {
              key: 'documentManage'
            },
            {
              key: 'messageManagement'
            }
          ]
        },
        {
          key: 'genericDrugs',
          submenus: [
            {
              key: 'genericDrugsOpeningInventory'
            },
            {
              key: 'genericDrugsFlow'
            },
            {
              key: 'genericDrugsAlloc'
            },
            {
              key: 'genericDrugsChnlIndex'
            },
            {
              key: 'genericDrugsZg'
            },
            {
              key: 'genericDrugsChnlLs'
            },
            {
              key: 'genericDrugsDigitContr'
            },
            {
              key: 'genericDrugsDevBonusArchive'
            },
            {
              key: 'genericDrugsSettleNumCalc'
            },
            {
              key: 'genericDrugsFeeCalc'
            }
          ]
        },
        {
          key: 'flowdirect',
          submenus: [
            {
              key: 'businessManage'
            },
            {
              key: 'varietyBusinessLineManage'
            },
            {
              key: 'productExceptionManage'
            },
            {
              key: 'areaManage'
            },
            {
              key: 'flowToGrab'
            },
            {
              key: 'stockGrab'
            },
            {
              key: 'warehouseGrab'
            },
            {
              key: 'synonymManage'
            },
            {
              key: 'hospAppAnalysis'
            },
            {
              key: 'ncCrmTermiClean'
            },
            {
              key: 'hospModBatch'
            }
          ]
        },
        {
          key: 'activity',
          submenus: [
            {
              key: 'activityApplication'
            },
            {
              key: 'activityReimbursement'
            },
            {
              key: 'nonMeetingApp'
            },
            {
              key: 'activityAppModify'
            },
            {
              key: 'hcvApplication'
            },
            {
              key: 'hcvWriteOff'
            },
            {
              key: 'photoManage'
            },
            {
              key: 'photoManageSimQuery'
            },
            {
              key: 'activityPhotoRight'
            },
            {
              key: 'hcvAgencyDoc'
            },
            {
              key: 'brandPresentation'
            },
            {
              key: 'activityType'
            },
            {
              key: 'datasControlByRole'
            },
            {
              key: 'billHandedOver'
            },
            {
              key: 'proxyApprove'
            },
            {
              key: 'batchSync'
            }
          ]
        },
        {
          key: 'housePropertyManage',
          submenus: [
            {
              key: 'housePropertyArchive'
            },
            {
              key: 'rentHouseApp'
            },
            {
              key: 'houseRegister'
            },
            {
              key: 'houseDormitory'
            },
            {
              key: 'housePropertyArchiveModify'
            },
            {
              key: 'houseRentFeeCalc'
            },
            {
              key: 'housePaymentDG'
            },
            {
              key: 'housePaymentDS'
            }
          ]
        },
        {
          key: 'costManage',
          submenus: [
            {
              key: 'costDeductCompensation'
            },
            {
              key: 'costSummary'
            },
            {
              key: 'localFeeApp'
            },
            {
              key: 'expenseReport'
            },
            {
              key: 'feeAppQuery'
            }
          ]
        },
        {
          key: 'queryApproveBills',
          submenus: [
            {
              key: 'queryActivityApplication'
            },
            {
              key: 'queryActivityReimbursement'
            },
            {
              key: 'nonMeetingAppQuery'
            },
            {
              key: 'queryYFPayment'
            },
            {
              key: 'queryDGPayment'
            },
            {
              key: 'queryDSPayment'
            },
            {
              key: 'jxBxQuery'
            },
            {
              key: 'monthlySalesTargetQuery'
            },
            {
              key: 'chsClerkCostDetail'
            },
            {
              key: 'recAppQuery'
            },
            {
              key: 'rentHouseAppQuery'
            },
            {
              key: 'houseRegisterQuery'
            },
            {
              key: 'houseDormitoryQuery'
            },
            {
              key: 'housePaymentDGQuery'
            },
            {
              key: 'housePaymentDSQuery'
            },
            {
              key: 'housePropertyArchiveModifyQuery'
            },
            {
              key: 'hcvApplicationQuery'
            },
            {
              key: 'hcvWriteOffQuery'
            }
          ]
        },
        {
          key: 'menuManage',
          submenus: [
            {
              key: 'menusManage'
            },
            {
              key: 'permissionManage'
            },
            {
              key: 'defDocListManage'
            },
            {
              key: 'defDocManage'
            },
            {
              key: 'shareManage'
            }
            // {
            //   key: 'reportFormSettings'
            // }
          ]
        }
      ]
    }
  },
  created() {
    const routes = this.$route.matched.slice(1)
    routes.forEach(({ name, meta }, index) => {
      if (index < routes.length - 1) {
        this.openKeys.push(name)
      } else {
        this.selectedKeys.push(meta.menuKey)
      }
    })
  },
  computed: {
    ...mapGetters(['usermenus']),
    authMenus() {
      let tpmenus = this.menus
      let ownmenus = tpmenus.filter(menu => {
        // debugger
        if (menu.submenus) {
          menu.submenus = menu.submenus.filter(submenu => {
            return this.usermenus
              ? this.usermenus.findIndex(usermenu => {
                  return usermenu.code === submenu.key
                }) !== -1
              : false
          })
          return menu.submenus.length > 0
        } else {
          return true
        }
      })
      return this.getAuthMenus(ownmenus)
    }
  },
  watch: {
    $route(nval) {
      const route = nval
      const { meta } = route
      if (meta.menuKey) {
        this.selectedKeys = []
        this.selectedKeys.push(meta.menuKey)
      }
    }
  },
  methods: {
    /**
     * 生成菜单列表
     * @param {Array} menus key 值列表，[{ key: ''},{ key: '', submenus: [ { key: '' } ] }]
     * @param {Array} menus 的配置数组，[{ key: '', title: '', route: ''} ]
     * @returns {Array} 菜单列表
     */
    getAuthMenus(menus, menuConfigs = SIDER_MENU_LIST) {
      return menus.map(({ key, submenus }) => {
        const {
          title = key,
          route,
          icon,
          svgicon,
          submenus: submenuConfigs
        } = this.getMenuConfig(key, menuConfigs)
        const baseMenu = { key, title, icon, svgicon }
        if (!submenus || !submenus.length) {
          return { ...baseMenu, route }
        }
        let authSubmenus = []
        if (submenuConfigs && submenuConfigs.length) {
          authSubmenus = this.getAuthMenus(submenus, submenuConfigs)
        } else {
          authSubmenus = submenus.map(submenu =>
            Object.assign(submenu, { title: submenu.key })
          )
        }
        return { ...baseMenu, submenus: authSubmenus }
      })
    },

    /**
     * 获取 configs 中指定 key 的 config 对象
     * @param {String} key menu 的 key 值
     * @param {Array} configs 要查找的数组
     * @returns {Object} 指定 key 的 config，如果没有，则返回 {}
     */
    getMenuConfig(key, configs) {
      return configs.find(config => config.key === key) || {}
    },

    /**
     * 处理菜单点击事件
     * @param {String} routeName 要进入的路由名
     */
    handleMenuClick(routeName) {
      this.$router.push({ name: routeName })
    }

    // /**
    //  * @param {String} value 给menuKey赋值
    //  */
    // setMenuKey(value) {
    //   this.menuKey = value
    // }
  }
}
</script>

<style lang="less" scoped>
.menu-list {
  color: rgba(255, 255, 255);
  background: #3d4864;
}
/deep/ .ant-menu-item {
  font-weight: 600;
  color: #ffffff !important;
  line-height: 48px !important;
  height: 48px !important;
  &.ant-menu-item-selected {
    color: #ffffff !important;
    border-left: 2px solid #ffffff;
  }
}

/deep/ .ant-menu-submenu.ant-menu-submenu-inline > .ant-menu-submenu-title {
  font-weight: 600;
  color: #ffffff;
  line-height: 48px !important;
  height: 48px !important;
}

/deep/.ant-menu.ant-menu-inline.ant-menu-sub {
  background: #3d4864;
  box-shadow: none;
  & .sub-item {
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
}

/deep/.ant-menu-item.ant-menu-item-selected {
  background: #4587f4 !important;
  color: #ffffff !important;
}

/deep/.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #4587f4;
  color: #ffffff !important;
}

/deep/ .ant-menu-inline .ant-menu-item:after {
  border-right: 0px;
}
</style>
