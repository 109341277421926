<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="收款信息" key="1" class="customStyle">
          <div class="ml-2 mt-3 flex flex-wrap justify-start">
            <div class="form-item" :title="collection.receName">
              <span class="form-label">
                收款单位
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="collection.receName"
                :disabled="isRead"
                @focus="chooseReceName"
              />
            </div>
            <div class="form-item" :title="collection.receBankName">
              <span class="form-label">收款银行名称</span>
              <a-input
                class="form-input"
                v-model="collection.receBankName"
                :disabled="true"
              />
            </div>
            <div class="form-item" :title="collection.receCommbineNum">
              <span class="form-label">收款银行行号</span>
              <a-input
                class="form-input"
                v-model="collection.receCommbineNum"
                :disabled="true"
              />
            </div>
            <div class="form-item" :title="collection.receAccNum">
              <span class="form-label">收款银行账号</span>
              <a-input
                class="form-input"
                v-model="collection.receAccNum"
                :disabled="true"
              />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <recename-preview
      v-if="showPreview"
      @receNameInfo="receNameInfo($event)"
      @close="showPreview = false"
    ></recename-preview>
  </div>
</template>

<script>
import RecenamePreview from './ReceNamePreview'
export default {
  name: 'collectionInfo',
  components: {
    RecenamePreview
  },
  props: {
    collection: {
      type: Object,
      required: true
    },
    status: Boolean
  },
  data() {
    return {
      showPreview: false
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  methods: {
    submit() {
      this.$emit('update:collection', this.collection)
    },

    // 打开选择收款单位框
    chooseReceName() {
      this.showPreview = true
    },

    //选择返回的收款信息
    receNameInfo(value) {
      this.collection.receName = value.receName
      this.collection.receBankName = value.receBankName
      this.collection.receCommbineNum = value.receCommbineNum
      this.collection.receAccNum = value.receAccNum
      this.collection.receCode = value.receCode
      this.submit()
    }
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
