<template>
  <section class="page">
    <h3 class="pt-4 text-center text-xl font-normal">学术会议申请单</h3>

    <table class="table-main">
      <template v-for="(item, index) in tableRaw">
        <tr v-if="item.type === 'header'" :key="index">
          <td :colspan="item.colspan" class="header-msg">{{ item.label }}</td>
        </tr>

        <tr
          v-if="item.type === 'raw' && (!item.show || item.show())"
          :key="index"
        >
          <td
            v-for="(subItem, subIndex) in item.children"
            :colspan="subItem.colspan"
            :key="subIndex"
            :class="[subIndex % 2 ? 'label-val' : 'label']"
          >
            {{
              typeof subItem.value === 'function'
                ? subItem.value()
                : subItem.value
            }}
          </td>
        </tr>
        <tr v-if="item.type === 'raw-th'" :key="index">
          <th
            v-for="subItem in item.children"
            :colspan="subItem.colspan"
            :key="subItem.value"
          >
            {{ subItem.value }}
          </th>
        </tr>

        <template v-if="item.type === 'data'">
          <tr v-for="data in datas[item.prop]" :key="data.id">
            <td
              v-for="(subItem, subIndex) in item.children"
              :colspan="subItem.colspan"
              :key="subIndex"
            >
              {{ data[subItem.value] }}
            </td>
          </tr>
        </template>

        <template v-if="item.type === 'fee'">
          <tr
            v-for="(feeItem, feeIndex) in item.children"
            :key="`${index}-${feeIndex}`"
          >
            <td
              v-for="(feeSubItem, feeSubIndex) in feeItem"
              colspan="2"
              :key="`${index}-${feeIndex}-${feeSubIndex}`"
            >
              {{
                typeof feeSubItem.value === 'function'
                  ? feeSubItem.value()
                  : feeSubItem.value
              }}
            </td>
          </tr>
        </template>
      </template>
    </table>

    <table class="table-footer">
      <tr>
        <td class="label">预付金额: {{ '￥' + (datas.budgetPreCost || 0) }}</td>
        <td class="label">合计金额：{{ '￥' + (datas.budgetTotal || 0) }}</td>
        <td class="label">
          批准总预算: {{ '￥' + (datas.budgetApprovetotal || 0) }}
        </td>
      </tr>
    </table>

    <section v-if="datas.flowInfoDTO && options.includes('flow')">
      <oa-process
        :summaryid="datas.flowInfoDTO.summaryid"
        :nextapproveman="datas.flowInfoDTO.nextapproveman"
        :curApprover="datas.flowInfoDTO.curApprover"
      />
    </section>

    <section v-if="options.includes('ps')">
      <ps :pid="datas.id"></ps>
    </section>

    <section v-show="options.includes('comment')">
      <comment :billId="datas.id" />
    </section>
  </section>
</template>

<script>
import OaProcess from '@/views/activity/modules/print/components/OaProcess'
import Comment from '@/views/activity/modules/print/components/CommentList'
import Ps from '@/views/activity/modules/print/components/PS'

export default {
  name: 'XssqPrint',
  components: {
    OaProcess,
    Comment,
    Ps
  },
  props: {
    datas: Object,
    // 所属层级列表
    conferenceList: {
      type: Array,
      default: () => []
    },
    // 产品列表
    productList: {
      type: Array,
      default: () => []
    },
    // 业务线列表
    businessTypeList: {
      type: Array,
      default: () => []
    },
    // 举办形式列表
    activityFormList: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => ['flow', 'ps', 'comment']
    }
  },
  data() {
    return {
      tableRaw: [
        { type: 'header', label: '申请人信息', colspan: '8' },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '申请人' },
            { colspan: '1', value: () => this.datas.appName },
            { colspan: '1', value: '岗位' },
            { colspan: '1', value: () => this.datas.post },
            { colspan: '1', value: '联系方式' },
            { colspan: '1', value: () => this.datas.contact },
            { colspan: '1', value: '申请日期' },
            { colspan: '1', value: () => this.datas.appdate }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '大区' },
            { colspan: '1', value: () => this.datas.regionName },
            { colspan: '1', value: '省办' },
            { colspan: '1', value: () => this.datas.provinceName },
            { colspan: '1', value: '地办' },
            { colspan: '1', value: () => this.datas.localityName },
            { colspan: '1', value: '所属层级' },
            {
              colspan: '1',
              value: () => this.findConference
            }
          ]
        },

        { type: 'header', label: '活动信息', colspan: '8' },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '会议名称' },
            { colspan: '4', value: () => this.datas.conferenceName },
            { colspan: '1', value: '会议编号' },
            { colspan: '2', value: () => this.datas.conferenceCode }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '业务线' },
            {
              colspan: '1',
              value: () => this.findBusinessType
            },
            { colspan: '1', value: '产品' },
            { colspan: '1', value: () => this.findProduct },
            { colspan: '1', value: '举办形式' },
            {
              colspan: '1',
              value: () => this.findActivityForm
            },
            { colspan: '1', value: '举办性质' },
            { colspan: '1', value: () => this.datas.activityNatrue }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '活动主题' },
            { colspan: '3', value: () => this.datas.activityTheme },
            { colspan: '1', value: '活动地点' },
            { colspan: '3', value: () => this.datas.activityPlace }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '开始日期' },
            { colspan: '1', value: () => this.datas.activityStdate },
            { colspan: '1', value: '结束日期' },
            { colspan: '1', value: () => this.datas.activityEddate },
            { colspan: '1', value: '预邀参会客户' },
            { colspan: '1', value: () => this.datas.preinvitednum },
            { colspan: '1', value: '讲者人数' },
            { colspan: '1', value: () => this.datas.speakerNum }
          ]
        },
        {
          type: 'raw',
          children: []
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '陪同人员人数' },
            { colspan: '1', value: () => this.datas.accompPersonNum },
            { colspan: '1', value: '内部人数' },
            { colspan: '1', value: () => this.datas.internalnum },
            { colspan: '1', value: '是否为走进东阳光项目' },
            { colspan: '1', value: () => this.datas.whetherDygProj },
            { colspan: '1', value: '是否会务公司' },
            { colspan: '1', value: () => this.datas.hasconfContract }
          ]
        },
        {
          type: 'raw',
          show: () => this.isShowCo,
          children: [
            { colspan: '1', value: '公对公预付金额' },
            { colspan: '1', value: () => this.datas.budgetPreCost },
            { colspan: '1', value: '主办方名称' },
            { colspan: '1', value: () => this.datas.sponsor },
            { colspan: '1', value: '成立日期' },
            { colspan: '1', value: () => this.datas.sponsorEstdate },
            { colspan: '1', value: '协办合同金额' },
            { colspan: '1', value: () => this.datas.cooperateContractMny }
          ]
        },
        {
          type: 'raw',
          show: () => this.isShowContract,
          children: [
            { colspan: '1', value: '会务公司名称' },
            { colspan: '3', value: () => this.datas.confCompany },
            { colspan: '1', value: '会务金额' },
            { colspan: '3', value: () => this.datas.contractMoney }
          ]
        },
        {
          type: 'raw',
          show: () => this.isShowOnline,
          children: [
            { colspan: '1', value: '直播协议金额' },
            { colspan: '7', value: () => this.datas.onlineLiveBroadcastingFee }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '活动背景描述' },
            { colspan: '7', value: () => this.datas.activityBackGround }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '活动目的' },
            { colspan: '7', value: () => this.datas.activityPurpose }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '活动内容概述' },
            { colspan: '7', value: () => this.datas.activityOverview }
          ]
        },
        {
          type: 'raw',
          children: [
            { colspan: '1', value: '活动预期效果' },
            { colspan: '7', value: () => this.datas.activityExpectEffect }
          ]
        },
        { type: 'header', label: '陪同人员信息', colspan: '8' },
        {
          type: 'raw-th',
          children: [
            { colspan: '1', value: '人员工号' },
            { colspan: '1', value: '人员姓名' },
            { colspan: '2', value: '所属大区' },
            { colspan: '2', value: '所属省区' },
            { colspan: '1', value: '人员岗位' },
            { colspan: '1', value: '陪同预算' }
          ]
        },
        {
          type: 'data',
          prop: 'appAccompanyingPersonsDtos',
          children: [
            { colspan: '1', value: 'personCode' },
            { colspan: '1', value: 'personName' },
            { colspan: '2', value: 'areaName' },
            { colspan: '2', value: 'provName' },
            { colspan: '1', value: 'postName' },
            { colspan: '1', value: 'accompanyBudget' }
          ]
        },
        { type: 'header', label: '讲者信息', colspan: '8' },
        {
          type: 'raw-th',
          children: [
            { colspan: '1', value: '讲者姓名' },
            { colspan: '1', value: '讲者编号' },
            { colspan: '1', value: '讲者单位' },
            { colspan: '1', value: '讲者级别' },
            { colspan: '1', value: '讲者劳务费' },
            { colspan: '2', value: '讲者课题' },
            { colspan: '1', value: '讲者时长' }
          ]
        },
        {
          type: 'data',
          prop: 'speakers',
          children: [
            { colspan: '1', value: 'name' },
            { colspan: '1', value: 'code' },
            { colspan: '1', value: 'departmentName' },
            { colspan: '1', value: 'speakerLevelName' },
            { colspan: '1', value: 'appConsultingFee' },
            { colspan: '2', value: 'topicname' },
            { colspan: '1', value: 'lengthoflecture' }
          ]
        },
        { type: 'header', label: '费用信息', colspan: '8' },
        {
          type: 'raw-th',
          children: [
            { colspan: '2', value: '项目' },
            { colspan: '2', value: '单价' },
            { colspan: '2', value: '数量' },
            { colspan: '2', value: '总价' }
          ]
        },
        {
          type: 'fee',
          children: [
            [
              { value: '交通费' },
              { value: () => this.datas.trafficExpense || 0 },
              { value: () => this.datas.trafficExpenseNum || 0 },
              { value: () => this.trafficExpenseTotal }
            ],
            [
              { value: '接送费' },
              { value: () => this.datas.trafficExpense || 0 },
              { value: () => this.datas.budgetReceptionNum || 0 },
              { value: () => this.budgetReceptionTotal }
            ],
            [
              { value: '住宿费' },
              { value: () => this.datas.budgetAccommodation || 0 },
              { value: () => this.datas.budgetAccommodationNum || 0 },
              { value: () => this.budgetAccommodationTotal }
            ],
            [
              { value: '餐费' },
              { value: () => this.datas.budgetMeals || 0 },
              { value: () => this.datas.budgetMealsNum || 0 },
              { value: () => this.budgetMealsTotal }
            ],
            [
              { value: '会务物料制作费' },
              { value: () => this.datas.meetingMaterialExpense || 0 },
              { value: () => this.datas.meetingMaterialExpenseNum || 0 },
              { value: () => this.meetingMaterialExpenseTotal }
            ],
            [
              { value: '展台布展' },
              { value: () => this.datas.exhibitionExpense || 0 },
              { value: () => this.datas.exhibitionExpenseNum || 0 },
              { value: () => this.exhibitionExpenseTotal }
            ],
            [
              { value: '第三方会务人员费用' },
              { value: () => this.datas.budgetThirdparty || 0 },
              { value: () => this.datas.budgetThirdpartyNum || 0 },
              { value: () => this.budgetThirdpartyTotal }
            ],
            [
              { value: '场地费' },
              { value: '~' },
              { value: '~' },
              { value: () => this.datas.venueExpense || 0 }
            ],
            [
              { value: '注册费' },
              { value: '~' },
              { value: '~' },
              { value: () => this.datas.registerFee || 0 }
            ],
            [
              { value: '第三方协办费用' },
              { value: '~' },
              { value: '~' },
              { value: () => this.trafficExpenseTotal }
            ],
            [
              { value: '线上平台直播费用' },
              { value: '~' },
              { value: '~' },
              { value: () => this.onlineLiveBroadcastingFee }
            ],
            [
              { value: '讲者劳务费' },
              { value: '~' },
              { value: '~' },
              { value: () => this.budgetConsultTotal }
            ],
            [
              { value: '陪同费用' },
              { value: '~' },
              { value: '~' },
              { value: () => this.accompanyExpense }
            ],
            // 废弃
            [
              { value: '展位(场地费)' },
              { value: () => this.datas.budgetBooth || 0 },
              { value: () => this.datas.budgetBoothNum || 0 },
              { value: () => this.budgetBoothTotal }
            ],
            [
              { value: '会议室(场地费)' },
              { value: () => this.datas.budgetMeetingroom || 0 },
              { value: () => this.datas.budgetMeetingroomNum || 0 },
              { value: () => this.budgetMeetingroomTotal }
            ],
            [
              { value: '制作费' },
              { value: () => this.datas.budgetMake || 0 },
              { value: () => this.datas.budgetMakeNum || 0 },
              { value: () => this.budgetMakeTotal }
            ],
            [
              { value: '设计费' },
              { value: () => this.datas.budgetDesign || 0 },
              { value: () => this.datas.budgetDesignNum || 0 },
              { value: () => this.budgetDesignTotal }
            ],
            [
              { value: '飞机费' },
              { value: () => this.datas.budgetFlight || 0 },
              { value: () => this.datas.budgetFlightNum || 0 },
              { value: () => this.budgetFlightTotal }
            ],
            [
              { value: '高铁费' },
              { value: () => this.datas.budgetTrain || 0 },
              { value: () => this.datas.budgetTrainNum || 0 },
              { value: () => this.budgetTrainTotal }
            ],
            [
              { value: '推广物料费' },
              { value: '~' },
              { value: '~' },
              { value: () => this.datas.budgetBrandpreTotal }
            ],
            [
              { value: '其他费用' },
              { value: '~' },
              { value: '~' },
              { value: () => this.datas.otherFee }
            ]
          ]
        }
      ]
    }
  },
  computed: {
    // 所属层级名称
    findConference() {
      return this.findItem(this.datas.conferenceType, this.conferenceList)
    },
    // 产品名称
    findProduct() {
      return this.findItem(this.datas.product, this.productList)
    },
    // 业务线名称
    findBusinessType() {
      return this.findItem(this.datas.businessType, this.businessTypeList)
    },
    // 举办形式名称
    findActivityForm() {
      return this.findItem(this.datas.activityForm, this.activityFormList)
    },

    // 线上显示
    isShowOnline() {
      if (!this.activityFormList) return false

      const target = this.activityFormList.find(
        item => item.value === this.datas.activityForm
      )
      if (!target) return false

      const text = target.text
      return text.indexOf('线上') > -1
    },
    // 协办显示
    isShowCo() {
      return this.datas.activityNatrue
        ? this.datas.activityNatrue.indexOf('协办') > -1
        : false
    },
    // 会务公司显示
    isShowContract() {
      return this.datas.hasconfContract === '是'
    },

    // 场地费
    venueExpenseTotal() {
      const price = Number(this.datas.venueExpense || '0')
      const num = Number(this.datas.venueExpenseNum || '0')
      return this.$np.times(price, num)
    },
    // 交通费
    trafficExpenseTotal() {
      const price = Number(this.datas.trafficExpense || '0')
      const num = Number(this.datas.trafficExpenseNum || '0')
      return this.$np.times(price, num)
    },
    // 接送费
    budgetReceptionTotal() {
      const price = Number(this.datas.budgetReception || '0')
      const num = Number(this.datas.budgetReceptionNum || '0')
      return this.$np.times(price, num)
    },
    // 住宿费
    budgetAccommodationTotal() {
      const price = Number(this.datas.budgetAccommodation || '0')
      const num = Number(this.datas.budgetAccommodationNum || '0')
      return this.$np.times(price, num)
    },
    // 餐费
    budgetMealsTotal() {
      const price = Number(this.datas.budgetMeals || '0')
      const num = Number(this.datas.budgetMealsNum || '0')
      return this.$np.times(price, num)
    },
    // 会务物料制作费
    meetingMaterialExpenseTotal() {
      const price = Number(this.datas.meetingMaterialExpense || '0')
      const num = Number(this.datas.meetingMaterialExpenseNum || '0')
      return this.$np.times(price, num)
    },
    // 展台布展
    exhibitionExpenseTotal() {
      const price = Number(this.datas.exhibitionExpense || '0')
      const num = Number(this.datas.exhibitionExpenseNum || '0')
      return this.$np.times(price, num)
    },
    // 第三方会务人员费用
    budgetThirdpartyTotal() {
      const price = Number(this.datas.budgetThirdparty || '0')
      const num = Number(this.datas.budgetThirdpartyNum || '0')
      return this.$np.times(price, num)
    },
    // 展位(场地费)
    budgetBoothTotal() {
      const price = Number(this.datas.budgetBooth || '0')
      const num = Number(this.datas.budgetBoothNum || '0')
      return this.$np.times(price, num)
    },
    // 会议室(场地费)
    budgetMeetingroomTotal() {
      const price = Number(this.datas.budgetMeetingroom || '0')
      const num = Number(this.datas.budgetMeetingroomNum || '0')
      return this.$np.times(price, num)
    },
    // 制作费
    budgetMakeTotal() {
      const price = Number(this.datas.budgetMake || '0')
      const num = Number(this.datas.budgetMakeNum || '0')
      return this.$np.times(price, num)
    },
    // 设计费
    budgetDesignTotal() {
      const price = Number(this.datas.budgetDesign || '0')
      const num = Number(this.datas.budgetDesignNum || '0')
      return this.$np.times(price, num)
    },
    // 飞机费
    budgetFlightTotal() {
      const price = Number(this.datas.budgetFlight || '0')
      const num = Number(this.datas.budgetFlightNum || '0')
      return this.$np.times(price, num)
    },
    // 高铁费
    budgetTrainTotal() {
      const price = Number(this.datas.budgetTrain || '0')
      const num = Number(this.datas.budgetTrainNum || '0')
      return this.$np.times(price, num)
    },

    /** 特殊 */
    // 公对公预付金额(活动信息 模块)(不参与费用计算合计，只在显示在: 预算金额)
    budgetPreCost() {
      return this.datas.budgetPreCost || '0'
    },
    // 讲者劳务费(讲者信息 模块)
    budgetConsultTotal() {
      return this.datas.budgetConsultTotal || '0'
    },
    // 线上平台直播费用(活动信息 模块)
    onlineLiveBroadcastingFee() {
      return this.datas.onlineLiveBroadcastingFee || '0'
    },
    // 陪同费用(陪同信息 模块)
    accompanyExpense() {
      return this.datas.accompanyExpense || '0'
    }
  },

  methods: {
    findItem(key, list) {
      const target = list.find(({ value }) => key === value)
      return target ? target.text : ''
    }
  }
}
</script>

<style lang="less" scoped>
@page {
  size: auto A4;
  margin: 16px 0 16px 0;
}

.page {
  position: relative;
  width: 24cm;
  overflow: hidden;
  padding-left: 0.66cm;
  padding-right: 0.68cm;
}
table {
  width: 100%;
  table-layout: fixed;
  &.layout-auto {
    table-layout: auto;
  }
  & th {
    border: 1px solid #000;
    height: 0.5cm;
    font-size: 12px;
    text-align: center;
  }
  & td {
    border: 1px solid #000;
    height: 0.5cm;
    font-size: 12px;
    text-align: center;
    &.label {
      font-weight: 600;
    }
    &.label-val {
      text-align: left;
      padding: 0 8px;
    }
  }
}
.table-footer td {
  border: 0px solid #000;
  text-align: left;
  font-size: 12px;
}

.header-msg {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
}
</style>
