<template>
  <div>
    <div class="app-message">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="申请人信息" key="1" class="customStyle">
          <div
            class="w-full flex flex-wrap justify-start"
            style="margin-left: 24px"
          >
            <div class="form-item">
              <span class="form-label">
                申请人
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input class="form-input" :disabled="true" v-model="username" />
            </div>
            <div class="form-item">
              <span class="form-label">
                岗位
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="userInfo.post"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                联系方式
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="userInfo.contact"
                :disabled="isRead || isXSBX"
                @change="contactChange"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                申请日期
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-date-picker
                class="form-input"
                :allowClear="false"
                :disabled="true"
                :value="moment(userInfo.appdate)"
                @change="appdateChange"
              />
            </div>
            <div class="form-item">
              <span class="form-label">大区/部门</span>
              <a-input
                class="form-input"
                v-model="regionName"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">省办</span>
              <a-input
                class="form-input"
                v-model="provinceName"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">地办</span>
              <a-input
                class="form-input"
                v-model="localityName"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                部门
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-popover :visible="visible" placement="bottomLeft">
                <template slot="title">
                  <div>
                    <a-icon type="close-circle" class="mr-1 text-red-600" />
                    部门错误
                  </div>
                </template>
                <template slot="content">
                  <div style="padding: 0 12px">
                    系统已更换组织部门，请重新选择下部门
                  </div>
                </template>
                <div :class="[visible ? 'has-error' : '']">
                  <a-select
                    class="form-input"
                    :value="userInfo.department"
                    :disabled="isRead || isXSBX"
                    @change="onChange"
                  >
                    <a-select-option
                      v-for="item in departmentList"
                      :key="item.did"
                      :value="item.did"
                    >
                      {{ item.deptname }}
                    </a-select-option>
                  </a-select>
                </div>
              </a-popover>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import store from '@/store'

import { getUserOne } from '@/api/sysadmin/users'
import { getParentDeptsById } from '@/api/sysadmin/depts/depts'
import moment from 'moment'

export default {
  name: 'UserInfo', //申请人信息和执行人信息
  props: {
    userInfo: {
      type: Object,
      required: true
    },
    type: String,
    status: Boolean,
    isXSBX: Boolean
  },
  data() {
    return {
      visible: false,

      did: '', //默认选取的第一个组织信息
      departmentList: '', //部门列表
      username: '', //申请人姓名
      regionName: '', //大区的名字
      provinceName: '', //省办的名字
      localityName: '' //地办的名字
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  created() {
    /**
     * 查看和代办
     */
    if (this.type === 'check' || this.type === 'waitwork') {
      if (!this.userInfo) {
        this.queryUserName(this.userInfo.applicant)
      }
    } else {
      // 申请单
      if (!this.isXSBX) {
        const depts = store.getters['depts']
        this.userInfo.applicant = store.getters['uid'] //申请人字段存申请人的uid
        this.userInfo.post = store.getters['vnote'] //岗位
        this.queryUserName(this.userInfo.applicant) //查询单据人员信息
        // 联系方式如果有就带出
        if (store.getters['contactinfo']) {
          this.userInfo.contact = store.getters['contactinfo']
        }
        // 自动带出大区、省办和地办
        this.userInfo.department = depts[0].did //默认第一个组织信息
        this.userInfo.departmentDTO = depts[0]
        this.departmentList = depts
        this.getParentDeptsById(depts[0].did)
        this.$emit(
          'deptCode',
          this.userInfo.departmentDTO.deptcode,
          this.userInfo.departmentDTO.deptdesc,
          this.userInfo.departmentDTO.deptNature
        )

        this.submit() //提交申请人信息
      }
    }
  },

  watch: {
    userInfo(nval) {
      const {
        applicant,
        department,
        regionDTO,
        provinceDTO,
        localityDTO,
        departmentDTO
      } = nval
      this.queryUserName(applicant)
      if (regionDTO) this.regionName = regionDTO.deptname
      if (provinceDTO) this.provinceName = provinceDTO.deptname
      if (localityDTO) this.localityName = localityDTO.deptname
      // 兼容之前的单据，如果没有部门信息的,以地办、省办、大区的顺序取值
      if (department && departmentDTO) {
        if (!this.isRead) {
          // 申请单的部门逻辑
          if (!this.isXSBX) {
            this.departmentList = store.getters['depts']
            // 切换新部门旧单据的错误提示
            if (
              !this.departmentList
                .map(i => i.did)
                .includes(this.userInfo.department)
            ) {
              setTimeout(() => (this.visible = true), 500)
            }
            // 总结单的部门逻辑(直接引用申请单)
          } else {
            this.departmentList = [departmentDTO]
          }
        } else {
          const list = []
          list.push(departmentDTO)
          this.departmentList = list
        }
      } else {
        if (regionDTO) {
          const list = []
          nval.department = regionDTO.did
          nval.departmentDTO = regionDTO
          list.push(regionDTO)
          this.departmentList = list
        }
        if (provinceDTO) {
          const list = []
          nval.department = provinceDTO.did
          nval.departmentDTO = provinceDTO
          list.push(provinceDTO)
          this.departmentList = list
        }
        if (localityDTO) {
          const list = []
          nval.department = localityDTO.did
          nval.departmentDTO = localityDTO
          list.push(localityDTO)
          this.departmentList = list
        }
      }
      this.$emit(
        'deptCode',
        nval.departmentDTO.deptcode,
        this.userInfo.departmentDTO.deptdesc,
        this.userInfo.departmentDTO.deptNature
      )
    }
  },

  methods: {
    // 申请日期函数回调
    appdateChange(date, dateString) {
      this.userInfo.appdate = dateString
      this.submit()
    },

    /**
     * 获取大区、省办和地办的三级结构
     * @param {String} did 最后一级组织的主键
     */
    getParentDeptsById(did) {
      this.visible = false
      getParentDeptsById(did).then(res => {
        const [regionDTO, provinceDTO, localityDTO] = res
        this.userInfo.regionDTO = regionDTO
        this.userInfo.provinceDTO = provinceDTO
        this.userInfo.localityDTO = localityDTO

        if (regionDTO) {
          this.userInfo.region = regionDTO.did
          this.regionName = regionDTO.deptname
        } else {
          this.userInfo.region = ''
          this.regionName = ''
        }
        if (provinceDTO) {
          this.userInfo.province = provinceDTO.did
          this.provinceName = provinceDTO.deptname
        } else {
          this.userInfo.province = ''
          this.provinceName = ''
        }
        if (localityDTO) {
          this.userInfo.locality = localityDTO.did
          this.localityName = localityDTO.deptname
        } else {
          this.userInfo.locality = ''
          this.localityName = ''
        }
        this.submit()
      })
    },

    // 联系电话
    contactChange() {
      this.$emit('updateItem') //向上发送有信息改变
      this.submit()
    },

    // 监听部门改变
    onChange(did) {
      this.userInfo.department = did
      this.userInfo.departmentDTO = this.departmentList.find(
        item => item.did === did
      )
      this.getParentDeptsById(did)
      this.submit()
      this.$emit('updateItem') //向上发送有信息改变
      this.$emit(
        'deptCode',
        this.userInfo.departmentDTO.deptcode,
        this.userInfo.departmentDTO.deptdesc,
        this.userInfo.departmentDTO.deptNature
      ) //向上发送部门信息
    },

    /**
     * 根据用户id查询用户名字
     * @param {String} uid 用户id
     */
    queryUserName(uid) {
      getUserOne(uid).then(response => {
        this.username = response.username
        this.$emit('username', this.username)
      })
    },

    // 返回userInfo数据
    submit() {
      this.$emit('update:userInfo', this.userInfo)
    },

    moment
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 25%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
