<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="会务情况" key="1" class="customStyle">
          <!-- 预算 -->
          <Table
            :columns="columns1"
            :data="conferenceCase.appConfsInfos"
            :dataTemplate="appItme"
            :isDisabled="isAppDisabled"
          >
            <span slot="inputCell" slot-scope="{ record, column }">
              <InputCell
                :record="record"
                :column="column"
                :isDisabled="isAppDisabled"
              ></InputCell>
            </span>

            <span slot="datePickerCell" slot-scope="{ record, column }">
              <DatePickerCell
                :record="record"
                :column="column"
                :isDisabled="isAppDisabled"
              ></DatePickerCell>
            </span>

            <span slot="selectCell" slot-scope="{ record, column }">
              <SelectCell
                :record="record"
                :column="column"
                :isDisabled="isAppDisabled"
              ></SelectCell>
            </span>
          </Table>

          <!-- 实际 -->
          <Table
            v-if="showAct"
            :columns="columns2"
            :data="conferenceCase.actConfsInfos"
            :dataTemplate="actItme"
            :isDisabled="isActDisabled"
            @deleteItem="calcActConferenceTotal"
          >
            <span slot="inputCell" slot-scope="{ record, column }">
              <InputCell
                :record="record"
                :column="column"
                :isDisabled="isActDisabled"
              ></InputCell>
            </span>

            <span slot="datePickerCell" slot-scope="{ record, column }">
              <DatePickerCell
                :record="record"
                :column="column"
                :isDisabled="isActDisabled"
              ></DatePickerCell>
            </span>

            <span slot="selectCell" slot-scope="{ record, column }">
              <SelectCell
                :record="record"
                :column="column"
                :isDisabled="isActDisabled"
              ></SelectCell>
            </span>

            <span>
              <span class="font-bold">实际会务金额合计：</span>
              <span class="text-red-500">
                ￥{{ conferenceCase.actConferenceTotal || 0 }}
              </span>
            </span>
          </Table>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import Table from './case/Table'
import InputCell from './case/InputCell'
import DatePickerCell from './case/DatePickerCell'
import SelectCell from './case/SelectCell'

const appItme = {
  appDate: '',
  appPlace: '',
  appTime: '',
  id: '',
  pid: '',
  teaBreak: '',
  teaBreakStandard: ''
}

const actItme = {
  actDate: '',
  actPlace: '',
  actTime: '',
  id: '',
  pid: '',
  price: '',
  teaBreak: '',
  teaBreakStandard: ''
}

export default {
  name: 'ConferenceCase',
  props: {
    conferenceCase: {
      type: Object,
      required: true
    },
    showAct: {
      type: Boolean
    },
    status: {
      type: Boolean
    },
    isManageWrite: {
      type: Boolean
    }
  },
  components: {
    Table,
    InputCell,
    SelectCell,
    DatePickerCell
  },
  data() {
    return {
      columns1: [
        {
          title: '会务申请',
          children: [
            {
              title: () => (
                <span>
                  <span class="mr-1">日期</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'appDate',
              width: 72,
              scopedSlots: {
                customRender: 'datePicker'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">时间</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'appTime',
              width: 64,
              list: [
                { text: '上午', value: '上午' },
                { text: '下午', value: '下午' }
              ],
              scopedSlots: {
                customRender: 'select'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">地点</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'appPlace',
              width: 100,
              scopedSlots: {
                customRender: 'input'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">茶歇</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'teaBreak',
              width: 64,
              list: [
                { text: '有', value: 1 },
                { text: '无', value: 0 }
              ],
              scopedSlots: {
                customRender: 'select'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">茶歇标准</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'teaBreakStandard',
              width: 64,
              scopedSlots: {
                customRender: 'input'
              }
            }
          ]
        }
      ],
      columns2: [
        {
          title: '会务实际',
          children: [
            {
              title: () => (
                <span>
                  <span class="mr-1">日期</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'actDate',
              width: 72,
              scopedSlots: {
                customRender: 'datePicker'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">时间</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'actTime',
              width: 64,
              list: [
                { text: '上午', value: '上午' },
                { text: '下午', value: '下午' }
              ],
              scopedSlots: {
                customRender: 'select'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">地点</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'actPlace',
              width: 100,
              scopedSlots: {
                customRender: 'input'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">茶歇</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'teaBreak',
              width: 64,
              list: [
                { text: '有', value: 1 },
                { text: '无', value: 0 }
              ],
              scopedSlots: {
                customRender: 'select'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">茶歇标准</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'teaBreakStandard',
              width: 64,
              scopedSlots: {
                customRender: 'input'
              }
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">会务费用</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'price',
              width: 64,
              calc: this.calcActConferenceTotal,
              scopedSlots: {
                customRender: 'input'
              }
            }
          ]
        }
      ],
      appItme,
      actItme
    }
  },

  computed: {
    isAppDisabled() {
      return this.status
    },

    isActDisabled() {
      return !this.isManageWrite && this.status
    }
  },

  methods: {
    calcActConferenceTotal() {
      const actConfsInfos = this.conferenceCase.actConfsInfos
      if (actConfsInfos && actConfsInfos.length) {
        this.conferenceCase.actConferenceTotal = actConfsInfos.reduce(
          (prev, current) => {
            return this.$np.plus(prev || 0, current.price || 0)
          },
          0
        )
        this.$emit('calcActualCost')
        return
      }

      this.conferenceCase.actConferenceTotal = 0
      this.$emit('calcActualCost')
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
