import { default as pullAllBy } from 'lodash/pullAllBy'
import { default as cloneDeep } from 'lodash/cloneDeep'

export default {
  namespaced: true,
  state: {
    unReadResult: 0,
    resultList: []
  },
  mutations: {
    SET_RESULT_LIST(state, value) {
      if (state.resultList.length) {
        state.resultList = [...state.resultList, ...value]
        return
      }
      state.resultList = value
    },

    ADD_RESULT_ITEM(state, item) {
      state.resultList = [...[item], ...state.resultList]
    },

    SET_UNREADRESULT(state, value) {
      state.unReadResult = value
    },

    // 移除结果列表的一项
    REMOVE_RESULT_ITEM(state, key) {
      pullAllBy(state.resultList, [key], 'id')
    },

    // 清除结果列表
    CLEAR_RESULT_LIST(state) {
      state.resultList = []
    }
  },

  actions: {
    removeResultItem({ commit, state }, item) {
      commit('REMOVE_RESULT_ITEM', item)
      const list = cloneDeep(state.resultList)
      if (list.length) {
        commit('CLEAR_RESULT_LIST')
        commit('SET_RESULT_LIST', list)
      } else {
        commit('SET_RESULT_LIST', [])
      }
    }
  }
}
