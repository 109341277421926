<template>
  <div>
    <a-modal
      title="选择预邀的专家/讲者"
      width="1000px"
      class="speaker-modal"
      :visible="visible"
      :centered="true"
      :keyboard="false"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <div class="speaker-content w-full">
        <div class="w-full my-3">
          <button class="button mr-2" @click="prev" :disabled="!prePage">
            上一页
          </button>
          <button class="button" @click="next" :disabled="!nextPage">
            下一页
          </button>
          <div class="search float-right">
            <a-input-search
              placeholder="按名称搜索"
              @search="onSearch"
              enterButton="搜索"
              class="w-72"
            />
          </div>
        </div>
        <a-table
          rowKey="speakerId"
          class="active-table"
          :pagination="false"
          :columns="columns"
          :dataSource="speakerList"
          :loading="loading"
          @change="getActivetySpeaker"
          :rowSelection="{
            onChange: onSelectChange,
            type: 'radio',
            selectedRowKeys: selectedRowKeys
          }"
          :rowClassName="
            (record, index) => {
              return index % 2 === 0 ? 'trBgClass' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  dblclick: () => {
                    handleClick(record)
                  },
                  click: () => {
                    sclick(record)
                  }
                }
              }
            }
          "
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column
            }"
            class="custom-filter-dropdown"
          >
            <a-input
              v-ant-ref="c => (searchInput = c)"
              placeholder="查询"
              :value="selectedKeys[0]"
              @change="
                e => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="() => handleSearch(selectedKeys, confirm, column)"
              style="width: 180px; margin-bottom: 8px; display: block"
            />
            <a-button
              type="primary"
              @click="() => handleSearch(selectedKeys, confirm, column)"
              icon="search"
              style="width: 80px; margin-right: 10px"
            >
              查询
            </a-button>
            <a-button
              @click="() => handleReset(clearFilters, column)"
              style="width: 80px"
            >
              重置
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getActivetySpeaker } from '@/api/acdemicActive/acdemic-application'
import { billSpeakerFilters } from '@/api/pubutil/tableutil'
import { loadDictTypes } from '@/api/customer/dictmanage'
import Vue from 'vue'

export default {
  name: 'ChooseSpeakerModal',
  props: {
    speakers: { type: Array, default: () => [] } //已选择的讲者信息
  },
  data() {
    return {
      visible: true,
      loading: false,
      current: 1, //当前页
      pageSize: 10, //每页显示的数据
      nextPage: true, //是否有上一页
      prePage: false, //是否有下一页
      map: [], //模糊查询的条件
      speakerLevelList: [], //过滤查询讲者的级别
      speakerList: [],
      speakerIds: '',
      selectedRowKeys: [], //选择的行id
      columns: [
        {
          title: '编号',
          dataIndex: 'code',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              })
            }
          }
        },
        {
          title: '名称',
          dataIndex: 'name',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              })
            }
          }
        },
        {
          title: '单位',
          dataIndex: 'departmentName',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              })
            }
          }
        },
        {
          title: '级别',
          dataIndex: 'speakerLevelName',
          align: 'center',
          filters: []
        }
        // {
        //   title: '咨询费',
        //   dataIndex: 'consultingFee',
        //   align: 'center'
        // }
      ]
    }
  },
  created() {
    this.getActivetySpeaker()
    loadDictTypes().then(response => {
      this.queryDoc(response, '讲者级别')
    })
  },
  methods: {
    // 按条件查询
    handleSearch(selectedKeys, confirm, column) {
      if (selectedKeys[0]) {
        this.map = [
          ...this.map,
          {
            column: column.dataIndex,
            opt: 'like',
            value: selectedKeys[0]
          }
        ]
      } else {
        this.map = this.map.filter(item => item.column !== column.dataIndex)
      }

      confirm()
      this.searchText = selectedKeys[0]
    },

    // 重置
    handleReset(clearFilters, column) {
      this.map = this.map.filter(item => item.column !== column.dataIndex)
      clearFilters()
      this.searchText = ''
    },

    // 选择单个讲者的信息
    onSelectChange(selectedRowKeys) {
      for (let speakerItem of this.speakers) {
        if (!speakerItem.speakerId) continue
        if (selectedRowKeys.includes(speakerItem.speakerId)) {
          this.$message.error('该讲者已在讲者列表中，请选择其他讲者')
          return false
        }
      }
      this.selectedRowKeys = selectedRowKeys
      this.speakerIds = selectedRowKeys[0]
      return true
    },

    // 确定
    handleOk() {
      const speaker = this.speakerList.find(
        item => this.speakerIds === item.speakerId
      )
      if (speaker !== undefined) {
        this.$emit('chooseSpeaker', speaker)
      }
      this.$emit('close')
    },

    /**
     * 选择的双击事件（直接选择，关闭弹框）
     * @param {Object} item 自选的讲者信息
     */
    handleClick(item) {
      const speakers = []
      speakers.push(item.speakerId)
      if (this.onSelectChange(speakers)) {
        this.handleOk()
      }
    },

    /**
     * 选择的单击事件（直接选择，不关闭弹框）
     * @param {Object} item 自选的讲者信息
     */
    sclick(item) {
      const speakers = []
      speakers.push(item.speakerId)
      this.onSelectChange(speakers)
    },

    // 按名字搜索
    onSearch(value) {
      if (value === '') {
        this.map = []
      } else {
        this.map = [
          {
            column: 'name',
            opt: 'like',
            value: value
          }
        ]
      }
      this.current = 1
      this.getActivetySpeaker()
    },

    // 上一页
    prev() {
      this.current = this.current - 1
      this.getActivetySpeaker()
    },

    // 下一页
    next() {
      this.current = this.current + 1
      this.getActivetySpeaker()
    },

    handleCancel() {
      this.$emit('close')
    },

    // 获取讲者的信息
    getActivetySpeaker(pagination, filters) {
      if (pagination) this.current = 1
      if (filters) {
        const list = billSpeakerFilters(filters)
        this.map = this.map.filter(item =>
          ['code', 'name', 'departmentName'].includes(item.column)
        )
        this.map = [...this.map, ...list]
      }

      this.loading = true
      this.speakerList = []
      getActivetySpeaker(this.current, this.pageSize, this.map).then(
        response => {
          this.current = response.currentPage
          this.prePage = response.prePage
          this.nextPage = response.nextPage
          response.datas.forEach(item => {
            let obj = {}
            Vue.set(obj, 'id')
            Vue.set(obj, 'speakerId', item.id)
            Vue.set(obj, 'code', item.code)
            Vue.set(obj, 'name', item.name)
            Vue.set(obj, 'departmentName', item.departmentName)
            Vue.set(obj, 'speakerLevelName', item.speakerLevelDTO?.name || '')
            Vue.set(obj, 'consultingFee', null)
            Vue.set(obj, 'pid', '')
            this.speakerList.push(obj)
          })
          this.loading = false
        }
      )
    },

    /**
     * 查询所属公司、业务类型枚举
     * @param {Array} docList //枚举列表
     * @param {String} speakerLevelName //枚举名称（讲者级别）
     */
    queryDoc(docList, speakerLevelName) {
      docList.forEach(item => {
        if (item.name === speakerLevelName)
          this.speakerLevelList = item.defDocDTOs
      })
      const speakerLevelArray = this.speakerLevelMultiple(this.speakerLevelList)
      for (let item of this.columns) {
        if (item.dataIndex === 'speakerLevelName') {
          item.filters = speakerLevelArray
        }
      }
    },

    /**
     * 将获取到的讲者级别枚举转换成{text: item.name，value:item.id}
     * @param list 讲者级别枚举项
     */
    speakerLevelMultiple(list) {
      const speakerLevelList = []
      list.forEach(item => {
        const { id, name } = item
        speakerLevelList.push({ text: name, value: id })
      })
      return speakerLevelList
    }
  }
}
</script>

<style lang="less">
.active-table .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.speaker-modal .ant-modal-header {
  padding: 8px 8px;
  background-color: #4587f4;
}
.speaker-modal .ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.speaker-modal .ant-modal-close-x {
  width: 39px;
  height: 39px;
  line-height: 39px;
  color: #ffffff;
}
.speaker-modal .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.speaker-modal .ant-modal-body {
  padding: 8px;
}
.speaker-content {
  padding: 0 8px;
  height: 58vh;
  overflow: auto;
}
.speaker-modal .ant-btn-primary,
.speaker-modal .ant-btn-primary:hover {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
}

.speaker-content .button {
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 0px 0px #f0f7fa;
  -moz-box-shadow: 0px 1px 0px 0px #f0f7fa;
  box-shadow: 0px 1px 0px 0px #f0f7fa;
  color: #606266;
  font-size: 8px;
  padding: 4px 8px 4px 8px;
  border: solid #a0aec0 1px;
  text-decoration: none;
  &.choose-btn {
    padding: 4px 15px 4px 15px;
  }
  &:focus {
    outline: 0;
  }
}

.speaker-content .button:hover {
  border: solid #606266 1px;
  text-decoration: none;
}
</style>
