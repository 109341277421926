<template>
  <section class="page">
    <h3 class="pt-4 text-center text-xl font-normal">{{ title }}</h3>
    <barcode
      :value="barCodeMsg.value"
      :width="barCodeMsg.width"
      :height="barCodeMsg.height"
      :fontSize="barCodeMsg.fontSize"
      :textAlign="barCodeMsg.textAlign"
    >
      未提交单据，条形码显示错误！
    </barcode>
    <table class="table-header">
      <tr>
        <td class="label">单据号: {{ datas.billCode }}</td>
        <td class="label">基地: 总部</td>
        <td class="label">制单人: {{ name }}</td>
        <td class="label">制单日期: {{ datas.makeTime }}</td>
      </tr>
    </table>

    <table class="table-main">
      <tr>
        <td colspan="8" class="header-msg">付款信息</td>
      </tr>
      <tr>
        <td colspan="1" class="label">付款单位</td>
        <td colspan="3" class="label-val">{{ datas.payName }}</td>
        <td colspan="1" class="label">最后付款日期</td>
        <td colspan="3" class="label-val">{{ datas.payDate }}</td>
      </tr>
      <tr>
        <td colspan="1">付款银行账号</td>
        <td colspan="3">{{ datas.payAccNum }}</td>
        <td colspan="1">付款银行开户行</td>
        <td colspan="3">{{ datas.payBankName }}</td>
      </tr>
      <tr>
        <td colspan="8" class="header-msg">收款信息</td>
      </tr>
      <tr>
        <td colspan="1">收款单位全称</td>
        <td colspan="3">{{ datas.receName }}</td>
        <td colspan="1">收款请求</td>
        <td colspan="3"></td>
      </tr>
      <tr>
        <td colspan="1">收款银行账号</td>
        <td colspan="3">{{ datas.receAccNum }}</td>
        <td colspan="1">收款银行开户行</td>
        <td colspan="3">{{ datas.receBankName }}</td>
      </tr>
      <tr>
        <td colspan="8" class="header-msg">申请事由</td>
      </tr>
      <tr>
        <td colspan="1">经办人</td>
        <td colspan="3">{{ name }}</td>
        <td colspan="1">联系电话</td>
        <td colspan="3">{{ datas.phone }}</td>
      </tr>
      <tr>
        <td colspan="1">金额大写</td>
        <td colspan="3">{{ upper }}</td>
        <td colspan="1">申请金额</td>
        <td colspan="3">{{ datas.applyMoney }}元</td>
      </tr>
      <tr>
        <td colspan="1">银行标准用途</td>
        <td colspan="3">服务费</td>
        <td colspan="1">结算方式</td>
        <td colspan="3">{{ datas.settleTypeDTO.name }}</td>
      </tr>
      <tr>
        <td colspan="1">摘要</td>
        <td colspan="7">{{ datas.conferenceName }}</td>
      </tr>
      <!-- </table> -->

      <!-- <table class="table-approver"> -->
      <tr>
        <td colspan="8" class="header-msg">审批信息</td>
      </tr>
      <tr>
        <td class="label">部门主管</td>
        <td class="label">{{ auditInfo ? auditInfo.deptManger || '' : '' }}</td>
        <td class="label">领导审批</td>
        <td class="label">{{ auditInfo ? auditInfo.leader || '' : '' }}</td>
        <td class="label">会计</td>
        <td class="label">{{ auditInfo ? auditInfo.accountant || '' : '' }}</td>
        <td class="label">财务主管</td>
        <td class="label">
          {{ auditInfo ? auditInfo.financeOffer || '' : '' }}
        </td>
      </tr>
    </table>

    <div class="divide pt-1">请沿此线剪开</div>
  </section>
</template>

<script>
import Barcode from 'vue-barcode'
import { capitalAmount } from '@/utils/util'
export default {
  name: 'DgfkPrint',
  components: {
    Barcode
  },
  props: {
    title: String,
    datas: Object,
    auditInfo: Object,
    name: String
  },
  data() {
    return {
      barCodeMsg: {
        value: this.datas.billCode,
        width: 1,
        height: 40,
        fontSize: 14,
        textAlign: 'left'
      }
    }
  },
  watch: {
    datas: {
      handler(nval) {
        this.barCodeMsg.value = nval.billCode
      },
      deep: true
    }
  },
  computed: {
    upper() {
      if (!this.datas.applyMoney) return '零'
      return this.capitalAmount(this.datas.applyMoney)
    }
  },
  methods: {
    capitalAmount
  }
}
</script>

<style lang="less" scoped>
@page {
  size: auto A4;
  margin: 16px 0 16px 0;
}

.page {
  position: relative;
  width: 21cm;
  overflow: hidden;
  padding-left: 1cm;
  padding-right: 1cm;
}
table {
  width: 100%;
  table-layout: fixed;
  & td {
    border: 1px solid #000;
    text-align: center;
    height: 0.5cm;
    font-size: 12px;
    & .label {
      width: 12.5%;
    }
    & .label-val {
      width: 37.5%;
    }
  }
}
.table-header td {
  border: 0px solid #000;
  text-align: left;
}

.header-msg {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.divide {
  position: fixed;
  left: -1cm;
  width: 23cm;
  top: 12.6cm;
  height: 1px;
  border-top: 1px dashed #000;
  font-size: 12px;
  transform: scale(0.875);
  text-align: center;
}
</style>
