<script>
import { getStrFullLength, cutStrByFullLength } from '../_uitl/util'
export default {
  name: 'Elipsis',
  props: {
    length: {
      type: [Number, String],
      required: true
    }
  },
  methods: {
    getStrDom(str, fullLength) {
      return (
        <span>
          {cutStrByFullLength(str, this.length) +
            (fullLength > this.length ? '...' : '')}
        </span>
      )
    },
    getTooltip(fullStr, fullLength) {
      return (
        <a-tooltip>
          <template slot="title">{fullStr}</template>
          {this.getStrDom(fullStr, fullLength)}
        </a-tooltip>
      )
    }
  },
  render() {
    const { length } = this.$props
    const str = this.$slots.default
      .map(vNode => vNode.text)
      .join('')
      .trim()
    const fullLength = getStrFullLength(str)
    const strDom =
      fullLength > length
        ? this.getTooltip(str, fullLength)
        : this.getStrDom(str, fullLength)
    return strDom
  }
}
</script>
