<template>
  <div
    class="fee-info-table"
    v-if="expenseInfo.expenseDetailDTOs || expenseInfo.expenseDetailDTOs.length"
  >
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          :header="isXSBX ? '实际品牌信息发布费用明细' : '品牌信息发布费用明细'"
          key="1"
          class="customStyle"
        >
          <div style="margin-left: 24px">
            <table cellspacing="0">
              <tr class="h-8" style="background-color: #f9f9f9">
                <th class="w-1/6">序号</th>
                <th>
                  合作方名称
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <th class="w-1/6">
                  合作金额
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <span class="icon-group" v-if="!isRead">
                  <a-icon type="plus-circle" class="icon" @click="addExpense" />
                  <a-icon
                    type="minus-circle"
                    class="icon mt-2"
                    @click="removeExpense()"
                  />
                </span>
              </tr>
              <tr
                v-for="(item, index) in expenseInfo.expenseDetailDTOs"
                :key="`${index}`"
                :class="[index % 2 === 0 ? 'trBgClass' : '']"
              >
                <td class="w-1/6">{{ index + 1 }}</td>
                <td>
                  <ellipsis v-if="isRead" :length="getTextLength">
                    {{ item.partnerName }}
                  </ellipsis>
                  <a-input
                    v-else
                    :title="item.partnerName"
                    v-model="item.partnerName"
                    @change="updateItem"
                  />
                </td>
                <td class="w-1/6">
                  <span v-if="isRead">
                    {{ item.copAmount }}
                  </span>
                  <a-input
                    v-else
                    v-model="item.copAmount"
                    @change="updateItem"
                    oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                  ></a-input>
                </td>
                <div v-if="!isRead"></div>
              </tr>
            </table>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import Ellipsis from '@/components/Ellipsis'
import { isEmpty } from '@/utils/util'

export default {
  name: 'ExpenseInfo', //品牌信息发布信息费用
  components: { Ellipsis },
  props: {
    expenseInfo: {
      type: Object,
      required: true
    },
    status: Boolean,
    isXSBX: Boolean
  },
  data() {
    return {
      expense: {
        seriNumber: '',
        partnerName: '',
        copAmount: ''
      }
    }
  },
  computed: {
    isRead() {
      return this.status
    },

    getTextLength() {
      const length = this.$store.state.screen.length
      const size = this.$store.state.screen.size
      const list = ['min', 'small', 'medium']
      if (list.includes(size)) {
        return 10
      }
      return length
    }
  },

  methods: {
    submit() {
      this.expenseInfo.infoPubAmount = 0
      this.expenseInfo.expenseDetailDTOs.forEach((item, index) => {
        item.seriNumber = `${index + 1}`
        this.expenseInfo.infoPubAmount = this.$np.plus(
          Number(this.expenseInfo.infoPubAmount),
          Number(item.copAmount)
        )
      })
      this.$emit('update:expenseInfo', this.expenseInfo)
    },

    updateItem() {
      this.submit()
      this.$emit('updateItem')
    },

    // 添加一条讲者信息
    addExpense() {
      const expense = this.copy(this.expense)
      this.expenseInfo.expenseDetailDTOs.push(expense)
    },

    /**
     * 删除一条讲者信息
     * @param {Integer} index 讲者列表的索引位置
     */
    removeExpense() {
      this.expenseInfo.expenseDetailDTOs.pop()
      this.updateItem()
    },

    /**
     * 深拷贝data中speaker对象
     * @param {Object} speaker data中的speaker对象
     */
    copy(speaker) {
      return JSON.parse(JSON.stringify(speaker))
    },

    isEmpty
  }
}
</script>

<style lang="less" scoped>
.fee-info-table table {
  width: 100%;
}
.fee-info-table table th {
  text-align: left;
  font-size: 14px;

  font-weight: 600;
  color: #999999;
  padding: 16px;
}
.fee-info-table table tr {
  padding: 12px;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
}
.fee-info-table table tr td {
  text-align: left;
  padding: 8px 16px;
}
.icon-group {
  position: absolute;
  right: 0px;
  display: flex;
  height: 3rem;
  flex-direction: column;
  justify-content: space-around;
  opacity: 1;
  justify-content: space-between;
}
.icon {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
