<template>
  <div>
    <div class="app-message">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="发起人附言" key="1" class="customStyle">
          <!-- 附言附件列表 -->
          <div
            style="
              margin-left: 24px;
              border: 1px solid rgba(0, 0, 0, 0.25);
              width: 99%;
            "
            class="mb-4 clearfix rounded-lg"
            v-for="{ id, addTime, comment } in commentList"
            :key="id"
          >
            <p class="mt-2 ml-4">{{ comment || '无' }}</p>
            <div class="float-right mb-2 mr-4">
              <span class="mr-4">时间 : {{ addTime }}</span>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import { getAddCommentList } from '@/api/acdemicActive/add-comment'
export default {
  name: 'PostScript',
  props: {
    pid: { type: String }
  },
  data() {
    return {
      commentList: [], //附言附件信息列表
      openCollapse: ['1'] //当前激活 tab 面板的 key数组(默认打开key=1)
    }
  },
  created() {
    if (this.pid) {
      this.getAddCommentList()
    }
  },
  watch: {
    pid(nval) {
      if (nval) {
        this.getAddCommentList()
      }
    }
  },
  methods: {
    /**
     * 获取单据的附言附件信息列表
     */
    getAddCommentList() {
      getAddCommentList(this.pid).then(res => {
        this.commentList = res.datas
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.app-message {
  position: relative;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
