const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '申请类型', value: `` },
      { label: '申请状态', value: `status` },
      { label: '申请人', value: `appName` },
      { label: '申请时间', value: `appTime` }
    ]
  }
]
const TABLE = [
  {
    title: '解绑申请',
    active: 'JBSQ',
    dataList: 'bodyDTOS',
    columns: [
      { title: '解绑医生', dataIndex: 'doctor' },
      { title: '解绑医生的医院', dataIndex: 'hospital' },
      { title: '产品名称', dataIndex: 'product' },
      { title: '医生分级', dataIndex: 'doctorGrade' },
      {
        title: '答卷信息',
        dataIndex: 'questionnaireDTOS',
        scopedSlots: {
          customRender: 'question'
        }
      },
      {
        title: '解绑信息',
        dataIndex: 'agreeUnbind',
        width: '200px',
        scopedSlots: {
          customRender: 'agreeUnbind'
        }
      }
    ]
  }
]

export { BASE, TABLE }
