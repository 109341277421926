export default {
  namespaced: true,
  state: {
    socket: null
  },
  mutations: {
    SET_SOCKET(state, value) {
      state.socket = value
    }
  },

  actions: {}
}
