import dayjs from 'dayjs'
import zhCN from 'dayjs/locale/zh-cn'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import relativeTime from 'dayjs/plugin/relativeTime'
import isIntersection from './plugins/isIntersection'

dayjs.locale(zhCN)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isIntersection)
dayjs.extend(isBetween)
dayjs.extend(relativeTime)

export default dayjs
