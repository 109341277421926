import Query from '@/views/query'
export default {
  path: 'query',
  name: 'query',
  component: Query,
  redirect: 'query/queryActivityApplication',
  meta: { title: '查询' },
  children: [
    {
      // 学术推广活动申请单查询
      path: 'queryActivityApplication',
      name: 'queryActivityApplication',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/application/ApplicationList'
        ),
      meta: {
        title: '学术推广活动申请单查询',
        menuKey: 'queryActivityApplication'
      }
    },
    {
      // 学术推广活动申请单详情
      path: 'applicationform2',
      name: 'applicationform2',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/application/ApplicationForm2'
        ),
      meta: {
        title: '学术推广活动申请单详情',
        menuKey: 'queryActivityApplication'
      }
    },
    {
      // 学术推广活动申请单详情
      path: 'applicationform',
      name: 'applicationform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/application/ApplicationForm'
        ),
      meta: {
        title: '学术推广活动申请单详情',
        menuKey: 'queryActivityApplication'
      }
    },
    {
      // 学术推广活动总结单查询
      path: 'queryActivityReimbursement',
      name: 'queryActivityReimbursement',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/reimbursement/ReimList'
        ),
      meta: {
        title: '学术推广活动总结单查询',
        menuKey: 'queryActivityReimbursement'
      }
    },
    {
      // 学术推广活动总结单详情
      path: 'reimform',
      name: 'reimform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/reimbursement/ReimForm'
        ),
      meta: {
        title: '学术推广活动总结单详情',
        menuKey: 'queryActivityReimbursement'
      }
    },
    {
      // 学术推广活动总结单详情
      path: 'reimform2',
      name: 'reimform2',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/reimbursement/ReimForm2'
        ),
      meta: {
        title: '学术推广活动总结单详情',
        menuKey: 'queryActivityReimbursement'
      }
    },

    {
      // 非会议学术项目申请单查询
      path: 'nonMeetingAppQuery',
      name: 'nonMeetingAppQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/nonmeeting/NonMeetingList'
        ),
      meta: { title: '非会议学术项目申请单查询', menuKey: 'nonMeetingAppQuery' }
    },
    {
      // 非会议学术项目申请单详情
      path: 'nonMeetingForm',
      name: 'nonMeetingForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/nonmeeting/NonMeetingForm'
        ),
      meta: { title: '非会议学术项目申请单详情', menuKey: 'nonMeetingAppQuery' }
    },

    {
      // 预付款单查询
      path: 'queryYFPayment',
      name: 'queryYFPayment',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/yfk/YfkList'
        ),
      meta: { title: '预付款单查询', menuKey: 'queryYFPayment' }
    },
    {
      // 预付款单详情
      path: 'yfkform',
      name: 'yfkform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/yfk/YfkForm'
        ),
      meta: { title: '预付款单详情', menuKey: 'queryYFPayment' }
    },
    {
      // 对公付款单查询
      path: 'queryDGPayment',
      name: 'queryDGPayment',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/dg/DgList'
        ),
      meta: { title: '对公付款单查询', menuKey: 'queryDGPayment' }
    },
    {
      // 对公付款单详情
      path: 'dgform',
      name: 'dgform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/dg/DgForm'
        ),
      meta: { title: '对公付款单详情', menuKey: 'queryDGPayment' }
    },
    {
      // 对私报销单查询
      path: 'queryDSPayment',
      name: 'queryDSPayment',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/ds/DsList'
        ),
      meta: { title: '对私报销单查询', menuKey: 'queryDSPayment' }
    },
    {
      // 对私报销单详情
      path: 'dsform',
      name: 'dsform',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/ds/DsForm'
        ),
      meta: { title: '对私报销单详情', menuKey: 'queryDSPayment' }
    },
    {
      // 对公付款会计审核
      path: 'jxBxQuery',
      name: 'jxBxQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/jsBx/JbList'
        ),
      meta: { title: '对公付款会计审核', menuKey: 'jxBxQuery' }
    },
    {
      // 月销量指标查询
      path: 'monthlySalesTargetQuery',
      name: 'monthlySalesTargetQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/monthsale/MonthSaleList'
        ),
      meta: { title: '月销量指标查询', menuKey: 'monthlySalesTargetQuery' }
    },
    {
      // 月销量指标查询详情
      path: 'monthSaleForm',
      name: 'monthSaleForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/monthsale/MonthSaleForm'
        ),
      meta: { title: '月销量指标查询详情', menuKey: 'monthlySalesTargetQuery' }
    },

    {
      // 费用明细查询
      path: 'chsClerkCostDetail',
      name: 'chsClerkCostDetail',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/preparation/PreparationList'
        ),
      meta: { title: '费用明细查询', menuKey: 'chsClerkCostDetail' }
    },

    {
      // 药连锁办事处用房租赁申请单查询
      path: 'rentHouseAppQuery',
      name: 'rentHouseAppQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/rent/RentHouseList'
        ),
      meta: {
        title: '药连锁办事处用房租赁申请单查询',
        menuKey: 'rentHouseAppQuery'
      }
    },
    {
      // 药连锁办事处用房租赁申请单查询详情
      path: 'rentHouseQueryForm',
      name: 'rentHouseQueryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/rent/RentHouseForm'
        ),
      meta: {
        title: '药连锁办事处用房租赁申请单查询详情',
        menuKey: 'rentHouseAppQuery'
      }
    },

    {
      // 药连锁自有房产登记单查询
      path: 'houseRegisterQuery',
      name: 'houseRegisterQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/registry/RegistryHouseList'
        ),
      meta: { title: '药连锁自有房产登记单查询', menuKey: 'houseRegisterQuery' }
    },
    {
      // 药连锁自有房产登记单查询详情
      path: 'houseRegisterQueryForm',
      name: 'houseRegisterQueryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/registry/RegistryHouseForm'
        ),
      meta: {
        title: '药连锁自有房产登记单查询详情',
        menuKey: 'houseRegisterQuery'
      }
    },

    {
      // 药连锁公司驻地宿舍申请单查询
      path: 'houseDormitoryQuery',
      name: 'houseDormitoryQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/dormitory/DormitorList'
        ),
      meta: {
        title: '药连锁公司驻地宿舍申请单查询',
        menuKey: 'houseDormitoryQuery'
      }
    },
    {
      // 药连锁公司驻地宿舍申请单查询详情
      path: 'houseDormitoryQueryForm',
      name: 'houseDormitoryQueryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/dormitory/DormitorForm'
        ),
      meta: {
        title: '药连锁公司驻地宿舍申请单查询详情',
        menuKey: 'houseDormitoryQuery'
      }
    },

    {
      // 药连锁房产对公付款单查询
      path: 'housePaymentDGQuery',
      name: 'housePaymentDGQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/housedg/HouseDGList'
        ),
      meta: {
        title: '药连锁房产对公付款单查询',
        menuKey: 'housePaymentDGQuery'
      }
    },
    {
      // 药连锁房产对公付款单查询详情
      path: 'houseDGQueryForm',
      name: 'houseDGQueryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/housedg/HouseDGForm'
        ),
      meta: {
        title: '药连锁房产对公付款单查询详情',
        menuKey: 'housePaymentDGQuery'
      }
    },

    {
      // 药连锁房产对私报销单查询查询
      path: 'housePaymentDSQuery',
      name: 'housePaymentDSQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/houseds/HouseDSList'
        ),
      meta: {
        title: '药连锁房产对私报销单查询',
        menuKey: 'housePaymentDSQuery'
      }
    },
    {
      // 药连锁房产对私报销单查询详情
      path: 'houseDSQueryForm',
      name: 'houseDSQueryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/houseds/HouseDSForm'
        ),
      meta: {
        title: '药连锁房产对私报销单查询详情',
        menuKey: 'housePaymentDSQuery'
      }
    },

    {
      // 药连锁房产续租/变更查询
      path: 'housePropertyArchiveModifyQuery',
      name: 'housePropertyArchiveModifyQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/housemodify/HouseModifyList'
        ),
      meta: {
        title: '药连锁房产续租/变更单查询',
        menuKey: 'housePropertyArchiveModifyQuery'
      }
    },
    {
      // 药连锁房产续租/变更单查询详情
      path: 'houseModifyQueryForm',
      name: 'houseModifyQueryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/housemodify/HouseModifyForm'
        ),
      meta: {
        title: '药连锁房产续租/变更单查询详情',
        menuKey: 'housePropertyArchiveModifyQuery'
      }
    },

    {
      // 丙肝送检公费申请查询
      path: 'hcvApplicationQuery',
      name: 'hcvApplicationQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/hcvapp/HcvAppList'
        ),
      meta: {
        title: '丙肝送检公费申请查询',
        menuKey: 'hcvApplicationQuery'
      }
    },
    {
      // 丙肝送检公费申请查询详情
      path: 'HcvAppQueryForm',
      name: 'hcvAppQueryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/hcvapp/HcvAppForm'
        ),
      meta: {
        title: '丙肝送检公费申请查询详情',
        menuKey: 'hcvApplicationQuery'
      }
    },

    {
      // 丙肝送检公费核销查询
      path: 'hcvWriteOffQuery',
      name: 'hcvWriteOffQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/hcvreim/HcvReimList'
        ),
      meta: {
        title: '丙肝送检公费核销查询',
        menuKey: 'hcvWriteOffQuery'
      }
    },
    {
      // 丙肝送检公费核销查询详情
      path: 'hcvWriteOffQueryForm',
      name: 'hcvWriteOffQueryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/hcvreim/HcvReimForm'
        ),
      meta: {
        title: '丙肝送检公费核销查询详情',
        menuKey: 'hcvWriteOffQuery'
      }
    },

    {
      // 接待申请单查询
      path: 'recAppQuery',
      name: 'recAppQuery',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/recept/ReceptList'
        ),
      meta: { title: '接待申请单查询', menuKey: 'recAppQuery' }
    },
    {
      // 接待申请单查询详情
      path: 'recAppForm',
      name: 'recAppForm',
      component: () =>
        import(
          /* webpackChunkName: "group-dictmanage" */ '@/views/query/recept/ReceptForm'
        ),
      meta: { title: '接待申请单查询详情', menuKey: 'recAppQuery' }
    }
  ]
}
