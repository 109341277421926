import { isEmpty } from '@/utils/util'

/**
 *
 * @param {*} conditions 为 map 组装like查询条件
 */
export function maptoArray(conditions) {
  let params = []
  if (conditions) {
    for (let [key, value] of conditions.entries()) {
      params.push({
        column: key,
        opt: 'like',
        value: value
      })
    }
  }
  return params
}

/**
 *
 * @param {*} conditions like过滤条件
 * @param {*} filters  暂时将like过滤条件之外的作为in 处理
 */

export function transFilters(conditions, filters) {
  let params = []
  for (let [key, value] of Object.entries(filters)) {
    if (conditions.has(key)) continue
    if (value && value.length > 0)
      params.push({
        column: key,
        opt: 'in',
        value: value
      })
  }
  return params
}
/**
 *
 * @param {*} sorter 排序字段
 */
export function transSorters(sorter) {
  let params = []
  if (sorter.columnKey && sorter.order)
    params.push({
      column: sorter.columnKey,
      opt: 'order',
      value: sorter.order
    })
  return params
}

/**
 * 学术活动申请单和总结单的过滤列表
 *@param {*} filters  暂时将like过滤条件之外的作为in 处理
 */
export function billTransFilters(filters) {
  const list = []
  const {
    'businessTypeDTO.name': businessType,
    'conferenceTypeDTO.name': conferenceType,
    'flowInfoDTO.status': status
  } = filters
  if (businessType && !isEmpty(businessType)) {
    list.push({
      column: 'businessType',
      opt: 'in',
      value: businessType
    })
  }
  if (conferenceType && !isEmpty(conferenceType)) {
    list.push({
      column: 'conferenceType',
      opt: 'in',
      value: conferenceType
    })
  }
  if (status && !isEmpty(status)) {
    list.push({
      column: 'status',
      opt: 'in',
      value: status
    })
  }

  return list
}

/**
 * 申请单和总结单选择讲者信息过滤列表
 * @param {*} filters
 */
export function billSpeakerFilters(filters) {
  const list = []
  const { speakerLevelName } = filters
  if (speakerLevelName && !isEmpty(speakerLevelName)) {
    list.push({
      column: 'speakerLevel',
      opt: 'in',
      value: speakerLevelName
    })
  }

  return list
}

/**
 * 变更申请的过滤列表
 * @param {*} filters
 */
export function modifyFilters(filters) {
  const list = []
  const { hospitalClassName, appTypeName, appStatus } = filters
  if (hospitalClassName && !isEmpty(hospitalClassName)) {
    list.push({
      column: 'hospitalClassName',
      opt: 'in',
      value: hospitalClassName
    })
  }
  if (appTypeName && !isEmpty(appTypeName)) {
    list.push({
      column: 'appTypeName',
      opt: 'in',
      value: appTypeName
    })
  }
  if (appStatus && !isEmpty(appStatus)) {
    list.push({
      column: 'appStatus',
      opt: 'in',
      value: appStatus
    })
  }

  return list
}

// 拜访补签的过滤（变更申请模块）
export function visitFilters(filters) {
  const list = []
  const { status } = filters
  if (status && !isEmpty(status)) {
    list.push({
      column: 'status',
      opt: 'in',
      value: status
    })
  }

  return list
}

// /**
//  * 拜访、随访的过滤列表
//  * @param {*} filters
//  */
// export function visitFilters(filters) {
//   const list = []
//   const {
//     visitType,
//     feedback,
//     visitTime,
//     visitStatusStr,
//     followUpVisitStatus,
//     readStatus
//   } = filters
//   if (visitType && !isEmpty(visitType)) {
//     list.push({
//       column: 'visitTypeId',
//       opt: 'in',
//       value: visitType
//     })
//   }
//   if (feedback && !isEmpty(feedback)) {
//     list.push({
//       column: 'feedbackId',
//       opt: 'in',
//       value: feedback
//     })
//   }
//   if (visitTime && !isEmpty(visitTime)) {
//     list.push({
//       column: 'visitTimeId',
//       opt: 'in',
//       value: visitTime
//     })
//   }
//   if (visitStatusStr && !isEmpty(visitStatusStr)) {
//     list.push({
//       column: 'visitStatus',
//       opt: 'in',
//       value: visitStatusStr
//     })
//   }
//   if (followUpVisitStatus && !isEmpty(followUpVisitStatus)) {
//     list.push({
//       column: 'followUpVisitStatus',
//       opt: 'in',
//       value: followUpVisitStatus
//     })
//   }
//   if (readStatus && !isEmpty(readStatus)) {
//     list.push({
//       column: 'readStatus',
//       opt: 'in',
//       value: readStatus
//     })
//   }

//   return list
// }

/**
 * 区域外时间的过滤列表
 * @param {Object} filters
 */
export function areaTimeFilters(filters) {
  const list = []
  const { status, type, time } = filters
  if (status && !isEmpty(status)) {
    list.push({
      column: 'status',
      opt: 'in',
      value: status
    })
  }
  if (type && !isEmpty(type)) {
    list.push({
      column: 'typeId',
      opt: 'in',
      value: type
    })
  }
  if (time && !isEmpty(time)) {
    list.push({
      column: 'timeId',
      opt: 'in',
      value: time
    })
  }

  return list
}

/**
 * 移交记录过滤列表
 * @param {*} filters
 */
export function proxyFilters(filters) {
  const list = []
  const { type } = filters
  if (type && !isEmpty(type)) {
    list.push({
      column: 'type',
      opt: 'in',
      value: type
    })
  }

  return list
}
