var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-4",staticStyle:{"padding-left":"32px"}},[_c('a-form',{attrs:{"size":"small","laba-width":"90px","laba-position":"right","layout":"vertical"}},[_c('a-collapse',{attrs:{"bordered":false},scopedSlots:_vm._u([{key:"expandIcon",fn:function(props){return [_c('a-icon',{attrs:{"type":"caret-right","rotate":props.isActive ? 90 : 0}})]}}]),model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_vm._l((_vm.baseMsg),function(ref){
var title = ref.title;
var active = ref.active;
var children = ref.children;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},[_vm._l((children),function(ref){
var label = ref.label;
var value = ref.value;
var type = ref.type;
var options = ref.options;
var show = ref.show;
return [(!show || show(_vm.formData))?_c('a-col',{key:label,attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":label,"prop":value,"label-col":_vm.itemlay.labelCol,"wrapper-col":_vm.itemlay.wrapperCol}},[(type === 'btn')?_c('a-button',{on:{"click":function($event){return _vm.preview(value)}}},[_vm._v(" 附件预览 ")]):(type === 'select')?_c('a-select',{attrs:{"disabled":true},model:{value:(_vm.formData[value]),callback:function ($$v) {_vm.$set(_vm.formData, value, $$v)},expression:"formData[value]"}},_vm._l((options),function(ref){
var value = ref.value;
var text = ref.text;
return _c('a-select-option',{key:value,attrs:{"value":value}},[_vm._v(" "+_vm._s(text)+" ")])}),1):_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.formData[value]),callback:function ($$v) {_vm.$set(_vm.formData, value, $$v)},expression:"formData[value]"}})],1)],1):_vm._e()]})],2)}),(_vm.formData.cashType === 'PZ')?_c('a-collapse-panel',{key:"TABLE",staticClass:"customStyle",attrs:{"header":"信息列表"}},[_c('a-tabs',{attrs:{"default-value":"month","animated":false}},[_c('a-tab-pane',{key:"month",attrs:{"tab":"月指标数量"}},[_c('a-table',{staticStyle:{"width":"480px"},attrs:{"rowKey":"bid","bordered":"","dataSource":_vm.monthData,"columns":_vm.monthColumns,"pagination":false}})],1),_c('a-tab-pane',{key:"single",attrs:{"tab":"单盒费用"}},[_c('a-table',{staticStyle:{"width":"480px"},attrs:{"rowKey":"bid","bordered":"","dataSource":_vm.singleData,"columns":_vm.singleColumns,"pagination":false}})],1)],1)],1):_vm._e()],2),(_vm.formData.flowInfoDTO)?_c('process',{attrs:{"summaryid":_vm.formData.flowInfoDTO.summaryid,"nextapproveman":_vm.formData.flowInfoDTO.nextapproveman,"curApprover":_vm.formData.flowInfoDTO.curApprover}}):_vm._e(),_c('comment',{attrs:{"billId":_vm.formData.pkDigitContrH}})],1)],1),(_vm.showPreview)?_c('preview-modal',{attrs:{"uploadName":"partner","previewFileListInfo":_vm.previewFileListInfo},on:{"close":function($event){_vm.showPreview = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }