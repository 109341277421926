<template>
  <a-row class="container p-2 bg-white">
    <!-- 单据内的发票 -->
    <a-col :span="12" class="pr-2">
      <p class="text-blue-600 text-sm tracking-wide mb-2 font-medium">
        本单据发票信息
      </p>
      <ul class="menu pl-4 py-2 mb-4 el-menu">
        <li
          v-for="{ pk, kind, nnumber } in dataSource"
          :key="pk"
          :class="['el-menu-item', { 'is-active': currPK === pk }]"
          @click="showDetail(pk, false)"
        >
          {{ kind }}_{{ nnumber }}
        </li>
      </ul>
      <invoice-preview :form="currDetail"></invoice-preview>
    </a-col>
    <!-- 重复票 -->
    <a-col :span="12" class="pl-2">
      <p class="text-blue-600 text-sm tracking-wide mb-2 font-medium">
        重复票信息
      </p>

      <ul class="menu pl-4 py-2 mb-4 el-menu">
        <li
          v-for="{ pk, kind, nnumber } in dupList"
          :key="pk"
          :class="['el-menu-item', { 'is-active': currDupPK === pk }]"
          @click="showDetail(pk, true)"
        >
          {{ kind }}_{{ nnumber }}
        </li>
      </ul>
      <invoice-preview
        ref="info"
        :form="currDupDetail"
        :isDup="true"
      ></invoice-preview>
    </a-col>
  </a-row>
</template>

<script>
import InvoicePreview from './InvoicePreview'
export default {
  name: 'RepeatInvoice',
  components: { InvoicePreview },
  props: {
    dataSource: {
      type: Array,
      required: true,
      default: () => []
    },
    // 默认选中哪个发票
    currPK: {
      type: [String, Number],
      default: 0
    }
  },

  data() {
    return {
      currDetail: {}, // 当前票据信息
      currDupDetail: {}, // 重复票据信息
      currDupPK: '' // 当前重复票据pk
    }
  },

  computed: {
    dupList() {
      return this.currDetail.exceptions || []
    }
  },

  watch: {
    // 如果传过来要打开的发票pk变了，就切换发票详情的显示
    currPK: {
      handler(val, oldVal) {
        if (!val || val === oldVal) return
        this.showDetail(val, false)
      },
      immediate: true
    }
  },

  methods: {
    /**
     * 切换详情显示
     * @param {Boolean} isDup 是否为重复票的详情
     */
    showDetail(pk, isDup = false) {
      if (isDup) {
        const item = this.dupList.find(i => i.pk === pk)
        if (!item) return
        this.currDupDetail = { ...item }
        this.currDupPK = pk
        // 更新引用重复票据的单据（门户或crm中的单据）
        this.$refs.info.refreshRelatedBill(item.pkSrc)
      } else {
        const item = this.dataSource.find(i => i.pk === pk)
        if (!item) return
        this.currDetail = { ...item }
        this.$emit('update:currPK', pk)
        // 切换重复票的显示
        if (this.currDetail.exceptions) {
          this.currDupDetail = this.currDetail.exceptions[0] || {}
          this.currDupPK = this.currDupDetail.pk
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  max-width: unset;
  width: unset;
  .menu {
    background: #f8f8f8;
    border: 1px solid #e8e8e8;
    height: 90px;
    max-height: 90px;
    overflow: auto;
    .el-menu-item {
      font-size: 12px;
      color: #777;
      height: unset;
      line-height: 1.1;
      padding: 5px 0 !important;
      background: unset;
      &.is-active {
        color: #4587f5;
      }
    }
  }
  /deep/ .el-container {
    height: 75vh;
    background: #fff;
    .el-aside {
      background-color: #fafafa;
      border-right: 1px solid #e9e9e9;
      .el-menu-item {
        white-space: normal;
        padding: 15px 0 15px 10px !important;
        line-height: 1.5;
        height: unset;
        &.is-active {
          background: #f3f9ff;
        }
      }
    }
  }
}
</style>
