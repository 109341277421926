<template>
  <div class="mt-2 mr-3" style="width: 99%">
    <!-- <div
      class="h-auto bg-gray-200 clearfix my-3 rounded-lg"
      v-for="(item, index) in handleInfoList"
      :key="`${index}`"
    >
      <div class="w-1/12 float-left h-auto">
        <div
          class="lineMax w-12 h-12 mt-2 rounded-full m-auto text-center
          text-white text-xs"
          :style="background(item.attitude)"
        >
          {{ getAttitude(item.attitude) }}
        </div>
      </div>
      <div class="float-left inline w-128">
        <div class="my-2 text-xs font-black">
          {{ item.handleman }}
          <span v-if="item.attitude === 97" class="ml-4" style="color: #4587f4">
            暂存待办
          </span>
        </div>
        <div class="my-1 text-xs">
          {{
            item.vnote === ''
              ? item.attitude === 1
                ? '同意'
                : '不同意'
              : item.vnote
          }}
        </div>
        <span class="text-xs text-gray-500 mr-2">
          {{ item.ts }}
        </span>
      </div>
    </div> -->
    <a-list
      class="comment-list"
      :header="`${handleInfoList.length} 条审批意见`"
      item-layout="horizontal"
      :data-source="handleInfoList"
    >
      <a-list-item slot="renderItem" slot-scope="item" class="w-full relative">
        <span v-if="item.showFlag" class="replay-msg">回复成功</span>
        <a-comment :author="item.handleman">
          <template slot="avatar">
            <a-avatar size="large" :style="{ backgroundColor: '#1D6FA3' }">
              <span
                v-text="
                  item.handleman.length < 4
                    ? item.handleman[0]
                    : item.handleman.slice(0, 2)
                "
              ></span>
            </a-avatar>
          </template>
          <template
            slot="actions"
            v-if="
              uid &&
              uid === $store.getters.uid &&
              !(item.showSubComment === true)
            "
          >
            <span @click="replay(item)">
              <a-icon type="rollback" class="mr-1" />
              回复
            </span>
          </template>
          <p slot="content">
            <span v-if="!item.vnote">
              {{
                item.attitude == attitudeEnum.AGREE
                  ? '同意'
                  : item.attitude == attitudeEnum.RECAPTION
                  ? '取回'
                  : ''
              }}
            </span>
            <template v-else>
              <p v-html="item.vnote"></p>
            </template>
          </p>
          <template slot="datetime">
            <span>{{ item.ts }}</span>
            <span class="ml-2">
              <a-tag :color="background(item.attitude)">
                {{ getAttitude(item.attitude) }}
              </a-tag>
            </span>
          </template>

          <!-- 回复 -->
          <a-comment v-if="item.showSubComment">
            <a-avatar slot="avatar" size="large">
              {{ $store.getters.username[0] }}
            </a-avatar>
            <div slot="content">
              <a-form-item>
                <a-textarea v-model="vnote" :rows="4" />
              </a-form-item>
              <a-form-item>
                <a-button
                  html-type="submit"
                  type="primary"
                  @click="handleReplyClick(item)"
                >
                  回复
                </a-button>
                <a-button
                  class="ml-2"
                  @click="() => (item.showSubComment = false)"
                >
                  取消
                </a-button>
                <a-button type="link" style="float: right" @click="upload">
                  <a-icon type="paper-clip" />
                  上传附件({{ fileList.length }})
                </a-button>
              </a-form-item>
            </div>
          </a-comment>

          <!-- 回复的评论 -->
          <span v-if="item.subApproveInfos && item.subApproveInfos.length">
            <a-comment
              v-for="subItem in item.subApproveInfos"
              :key="subItem.pkApproveinfo"
              :author="subItem.handleman"
            >
              <template slot="avatar">
                <a-avatar size="large" :style="{ backgroundColor: '#1D6FA3' }">
                  <span
                    v-text="
                      subItem.handleman.length < 4
                        ? subItem.handleman[0]
                        : subItem.handleman.slice(0, 2)
                    "
                  ></span>
                </a-avatar>
              </template>
              <template
                slot="actions"
                v-if="subItem.attitude == attitudeEnum.REPLY"
              >
                <span @click="preview(subItem)">
                  <a-icon type="paper-clip" class="mr-1" />
                  {{
                    `附件信息 (${
                      isXSBX
                        ? subItem.approveInfoBillFiles
                          ? subItem.approveInfoBillFiles.length
                          : 0
                        : subItem.approveInfoBillFilesForOtherBill
                        ? subItem.approveInfoBillFilesForOtherBill.length
                        : 0
                    })`
                  }}
                </span>
              </template>
              <p slot="content">
                {{ subItem.vnote }}
              </p>
              <template slot="datetime">
                <span>{{ subItem.ts }}</span>
                <span class="ml-2">
                  <a-tag :color="background(subItem.attitude)">
                    {{ getAttitude(subItem.attitude) }}
                  </a-tag>
                </span>
              </template>
            </a-comment>
          </span>
        </a-comment>
      </a-list-item>
    </a-list>

    <pay-upload-modal
      v-if="showPayUpload"
      uploadName="回复"
      :pid="billId"
      :uploadFilelistInfo="uploadFilelistInfo"
      @close="showPayUpload = false"
      @hasUpload="hasUpload($event)"
    ></pay-upload-modal>
    <pay-preview-modal
      v-if="showPayPreview"
      uploadName="回复"
      :previewFileListInfo="previewFileListInfo"
      @close="showPayPreview = false"
    ></pay-preview-modal>

    <upload-modal
      v-if="showUpload"
      uploadName="replay"
      :uploadFilelistInfo="uploadFilelistInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>
    <preview-modal
      v-if="showPreview"
      uploadName="replay"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { attitudeEnum } from '@/configs/bill-msg'
import PayPreviewModal from '@/components/Upload/PayPreviewModal'
import PayUploadModal from '@/components/Upload/PayUploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import UploadModal from '@/components/Upload/UploadModal'
import {
  saveApproveInfoReply,
  saveApproveInfoReplyForOtherBill
} from '@/api/acdemicActive/acdemic-application'
export default {
  name: 'comment',
  props: {
    uid: {
      type: String,
      default: ''
    },
    billId: {
      type: String,
      default: ''
    },
    isXSBX: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: '1'
    },
    handleInfoList: {
      type: Array,
      default: () => []
    }
  },
  components: { PreviewModal, UploadModal, PayPreviewModal, PayUploadModal },
  data() {
    return {
      moment,
      attitudeEnum,

      showPayUpload: false,
      showPayPreview: false,
      showUpload: false,
      showPreview: false,

      uploadFilelistInfo: '',
      previewFileListInfo: '',

      vnote: '',
      fileList: [],
      currentComment: {}
    }
  },
  methods: {
    background(attitude) {
      switch (attitude) {
        case attitudeEnum.AGREE:
          return '#48bb78'
        case attitudeEnum.TEMPORARY:
          return '#4587f4'
        case attitudeEnum.TRUN_OVER:
          return '#FA9042'
        case attitudeEnum.HAND_UP:
          return '#667EEA'
        case attitudeEnum.REPLY:
          return '#32CCBC'
        case attitudeEnum.SUBMIT:
          return '#4587f4'
        case attitudeEnum.RECAPTION:
          return '#ffb61e'
        case attitudeEnum.INFORM:
          return '#0396FF'
        case attitudeEnum.NOTIFY:
          return '#ffc773'
        case attitudeEnum.CANCEL:
          return '#725e82'
        case attitudeEnum.BACK:
          return '#e9e7ef'
        default:
          return '#f56565'
      }
    },

    getAttitude(attitude) {
      switch (attitude) {
        case attitudeEnum.AGREE:
          return '同意'
        case attitudeEnum.TEMPORARY:
          return '暂存待办'
        case attitudeEnum.TRUN_OVER:
          return '移交'
        case attitudeEnum.HAND_UP:
          return '挂起'
        case attitudeEnum.SUBMIT:
          return '提交'
        case attitudeEnum.REPLY:
          return '回复'
        case attitudeEnum.RECAPTION:
          return '取回'
        case attitudeEnum.INFORM:
          return '通知'
        case attitudeEnum.NOTIFY:
          return '返回上一级'
        case attitudeEnum.CANCEL:
          return '取消暂存'
        case attitudeEnum.BACK:
          return '退回基地调度'
        default:
          return '不同意'
      }
    },

    handleReplyClick(item) {
      const commentInfo = {}
      this.$set(commentInfo, 'pkSrc', item.pkSrc)
      this.$set(commentInfo, 'relatedId', item.pkApproveinfo)
      this.$set(commentInfo, 'approveInfoBillFiles', this.fileList)
      this.$set(commentInfo, 'vnote', this.vnote)
      this.$set(commentInfo, 'attitude', attitudeEnum.REPLY)
      this.$set(commentInfo, 'handleman', this.$store.getters.username)
      this.$set(commentInfo, 'approveInfoBillFilesForOtherBill', this.fileList)

      const submitReplyApi = this.isXSBX
        ? saveApproveInfoReply
        : saveApproveInfoReplyForOtherBill

      submitReplyApi(commentInfo).then(() => {
        if (!item.subApproveInfos) {
          item.subApproveInfos = []
        }
        item.showSubComment = false
        item.subApproveInfos.push(commentInfo)

        this.vnote = ''
        this.fileList = []

        item.showFlag = true
        setTimeout(() => {
          item.showFlag = false
        }, 2000)
      })
    },

    replay(item) {
      this.fileList = []
      item.showSubComment = true
    },

    // 上传后的文件信息
    hasUpload(files) {
      this.fileList = JSON.parse(files.fileListInfo)
    },

    upload() {
      this.uploadFilelistInfo = JSON.stringify(this.fileList)
      if (this.isXSBX) this.showPayUpload = true
      else this.showUpload = true
    },

    preview(subComent) {
      const fileMsg = this.isXSBX
        ? subComent.approveInfoBillFiles
        : subComent.approveInfoBillFilesForOtherBill
      this.previewFileListInfo = JSON.stringify(fileMsg)
      if (this.isXSBX) this.showPayPreview = true
      else this.showPreview = true
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-comment-content-detail p {
  margin-top: 12px;
  white-space: unset;
}

/deep/ .ant-comment-actions > li > span:hover {
  color: #4587f5;
}

/deep/ .ant-comment {
  width: 100%;
}

/deep/ .ant-form-item {
  margin-bottom: 12px;
}

.replay-msg {
  position: absolute;
  color: #48bb78;
  left: 50%;
  z-index: 1;
  opacity: 0;
  animation: goup 2s;
}

@keyframes goup {
  0% {
    top: 75%;
    opacity: 0;
  }
  50% {
    top: 50%;
    opacity: 1;
  }
  100% {
    top: 25%;
    opacity: 0;
  }
}
</style>
