<template>
  <div>
    <a-modal
      v-model="visible"
      title="附件预览"
      dialogClass="preview-file-modal clearfix"
      width="80%"
      :mask="false"
      :maskClosable="false"
      :centered="true"
      :footer="null"
      @cancel="handleClose"
    >
      <div class="file-content border w-full" v-if="showExcelFile">
        <iframe :src="url" width="100%" height="100%" frameborder="1"></iframe>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getOfficePreviewUrl } from '@/configs/filetype'

export default {
  name: 'preview',
  props: {
    fileId: {
      type: String,
      required: true
    },
    // 是否是付款申请单附件
    isPay: {
      type: Boolean,
      default: false
    },
    isDocs: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: true,
      url: '',
      showExcelFile: false //是否使用iframe展示pdf文件
    }
  },
  created() {
    const url = getOfficePreviewUrl()
    const downLoadUrl = this.isPay
      ? `api/nUploadForPayment/download?id=${this.fileId}`
      : this.isDocs
      ? `api/documentManage/download?id=${this.fileId}`
      : `api/nfile/download?id=${this.fileId}`
    this.url = `${url}/${downLoadUrl}`
    this.showExcelFile = true
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less"></style>
