<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handClose"
      @cancel="handClose"
      width="56%"
      :title="`${uploadName}附件上传`"
      :closable="false"
      :keyboard="false"
      :mask="false"
      :maskClosable="false"
      cancelText="关闭"
      dialogClass="upload-preview-file"
    >
      <div class="card-containers">
        <div class="clearfix">
          <div class="file-list-card">
            <div
              class="file-list"
              :title="file.oname"
              v-for="file in fileList"
              :key="file.id"
            >
              <a-icon
                type="close-circle"
                class="icon-close-btn"
                title="删除"
                @click="remove(file.id)"
              />
              <div class="file-info">
                <img :src="file.src" />
              </div>
              <div class="file-name">{{ file.oname }}</div>
            </div>
          </div>
          <div class="add-file" @click="chooseFile">
            <span class="file-btn">
              <input
                type="file"
                :multiple="true"
                accept="
                image/gif, image/jpeg, 
                image/png, application/pdf,application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style="display: none"
                @change="fileChange"
                ref="file"
              />
              <div>
                <a-icon style="font-size: 24px" type="cloud-upload" />
              </div>
            </span>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getBatch,
  getUpload,
  getIMGURL,
  getDeleteFile
} from '@/api/acdemicActive/payupload'

export default {
  name: 'uploadModal',
  props: {
    uploadName: {
      type: String
    },
    uploadFilelistInfo: {
      type: String
    },
    pid: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      visible: true,
      fileList: [] //上传成功的文件列表
    }
  },
  created() {
    if (this.uploadFilelistInfo) {
      this.fileList = JSON.parse(this.uploadFilelistInfo)
      if (this.fileList) {
        this.fileList.forEach((file, index) => {
          if (!['pdf', 'doc', 'docx', 'xlsx', 'xls'].includes(file.type)) {
            getIMGURL(file.id).then(response => {
              this.$set(this.fileList[index], 'src', response)
            })
          } else {
            if (!file.src) {
              if (file.type === 'pdf') {
                file.src = require('@/assets/upload/pdf.png')
              } else if (file.type === 'docx' || file.type === 'doc') {
                file.src = require('@/assets/upload/word.png')
              } else if (file.type === 'xls' || file.type === 'xlsx') {
                file.src = require('@/assets/upload/excel.png')
              }
            }
          }
        })
      }
    }
  },

  methods: {
    handClose() {
      const obj = {}
      obj.uploadName = this.uploadName
      if (this.fileList.length !== 0) {
        obj.fileListInfo = JSON.stringify(
          this.fileList.map(item => {
            return { ...item, ...{ src: '' } }
          })
        )
        this.$emit('hasUpload', obj)
      } else {
        obj.fileListInfo = '[]'
        this.$emit('hasUpload', obj)
      }
      this.$emit('close')
    },
    chooseFile() {
      this.$refs.file.value = null
      this.$refs.file.click()
    },

    // 文件上传
    fileChange() {
      let myfile = this.$refs.file
      if (myfile.files[0] === undefined) {
        this.$message.warning('未上传任何文件！')
      } else {
        // 上传单个文件
        if (myfile.files.length === 1) {
          let formData = new FormData()
          formData.append('file', myfile.files[0])
          this.uploadFile(formData)
        } else {
          // 批量上传文件
          let formData = new FormData()
          for (let item of myfile.files) {
            formData.append('files', item)
          }
          this.uploadBatchFile(formData)
        }
      }
    },

    //删除某个文件
    remove(id) {
      getDeleteFile(id).then(() => {
        for (let i = 0; i < this.fileList.length; i++) {
          if (this.fileList[i].id === id) {
            this.$message.success(`${this.fileList[i].oname}删除成功`)
            this.fileList.splice(i, 1)
          }
        }
      })
    },

    /**
     * 上传单个文件
     * @param {File} file
     * @param {String} pid 单据主键
     */
    uploadFile(file) {
      getUpload(file, this.pid)
        .then(response => {
          const file = response
          if (file.type === 'pdf') {
            file.src = require('@/assets/upload/pdf.png')
            this.fileList.push(file)
          } else if (file.type === 'docx' || file.type === 'doc') {
            file.src = require('@/assets/upload/word.png')
            this.fileList.push(file)
          } else if (file.type === 'xls' || file.type === 'xlsx') {
            file.src = require('@/assets/upload/excel.png')
            this.fileList.push(file)
          } else {
            getIMGURL(file.id).then(response => {
              file.src = response
              this.fileList.push(file)
            })
          }

          this.$message.success(`${file.oname}上传成功`)
        })
        .catch(() => {
          this.$message.error('上传失败')
        })
    },

    /**
     * 批量上传文件
     * @param {Array} fileList
     */
    uploadBatchFile(fileList) {
      getBatch(fileList, this.pid)
        .then(response => {
          const list = response
          for (let file of list) {
            if (file.type === 'pdf') {
              file.src = require('@/assets/upload/pdf.png')
              this.fileList.push(file)
            } else if (file.type === 'docx' || file.type === 'doc') {
              file.src = require('@/assets/upload/word.png')
              this.fileList.push(file)
            } else if (file.type === 'xls' || file.type === 'xlsx') {
              file.src = require('@/assets/upload/excel.png')
              this.fileList.push(file)
            } else {
              getIMGURL(file.id).then(response => {
                file.src = response
                this.fileList.push(file)
              })
            }
          }
          this.$message.success('上传成功')
        })
        .catch(() => {
          this.$message.error('上传失败')
        })
    }
  }
}
</script>

<style lang="less"></style>
