<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handClose"
      @cancel="handClose"
      width="56%"
      :title="`协办第三方资料附件上传`"
      :closable="false"
      :keyboard="false"
      :mask="false"
      :maskClosable="false"
      cancelText="关闭"
      dialogClass="upload-preview-file"
    >
      <div class="card-containers">
        <div class="clearfix">
          <div class="file-list-card">
            <div
              class="file-list"
              :title="file.oname"
              v-for="file in fileList"
              :key="file.id"
            >
              <a-icon
                type="close-circle"
                class="icon-close-btn"
                title="删除"
                @click="remove(file.id)"
              />
              <a-avatar
                v-if="file.reimFeeType"
                shape="square"
                :size="18"
                class="icon-tag"
                :style="{ backgroundColor: '#fd875c', verticalAlign: 'middle' }"
              >
                {{ file.reimFeeTypeName[0] }}
              </a-avatar>
              <div class="file-info">
                <img :src="file.src" />
              </div>
              <div class="file-name">{{ file.oname }}</div>
            </div>
          </div>
          <div class="add-file" @click="chooseFile">
            <span class="file-btn">
              <input
                type="file"
                :multiple="true"
                accept="
                image/gif, image/jpeg, image/jpg
                image/png, application/pdf,application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style="display: none"
                @change="fileChange"
                ref="file"
              />
              <div>
                <a-icon class="upload-icon" type="cloud-upload" />
              </div>
            </span>
          </div>
        </div>
      </div>
    </a-modal>

    <a-modal
      v-model="feeVisible"
      title="选择费用项目"
      class="upload-preview-file"
      @ok="changeFee"
    >
      <a-select v-model="curFeeType" style="width: 180px">
        <a-select-option
          v-for="{ text, value } in feeTypeList"
          :key="value"
          :value="value"
        >
          {{ text }}
        </a-select-option>
      </a-select>
    </a-modal>
  </div>
</template>

<script>
import { getBatch, getUpload, getIMGURL } from '@/api/acdemicActive/upload'
import { getDoc } from '@/configs/doc-config'

export default {
  name: 'CooperationUpload',
  props: {
    uploadName: {
      type: String
    },
    uploadFilelistInfo: {
      type: String
    }
  },
  data() {
    return {
      visible: true,
      feeVisible: false,
      curFeeType: '',
      feeTypeMap: {}, // 费用项目map
      feeTypeList: [], // 费用项目枚举
      fileList: [] //上传成功的文件列表
    }
  },
  created() {
    if (this.uploadFilelistInfo) {
      this.fileList = JSON.parse(this.uploadFilelistInfo)
      if (this.fileList) {
        for (let file of this.fileList) {
          if (!['pdf', 'doc', 'docx', 'xlsx', 'xls'].includes(file.type)) {
            file.src = ''
            getIMGURL(file.id).then(response => {
              file.src = response
            })
          }
        }
      }
    }

    // 获取费用项目枚举
    getDoc('052', res => {
      this.feeTypeList = res
      for (const { text, value } of this.feeTypeList) {
        this.$set(this.feeTypeMap, value, text)
      }
    })
  },
  methods: {
    handClose() {
      const obj = {}
      obj.uploadName = this.uploadName
      if (this.fileList.length !== 0) {
        obj.fileListInfo = JSON.stringify(
          this.fileList.map(item => {
            return { ...item, ...{ src: '' } }
          })
        )
        this.$emit('hasUpload', obj)
      } else {
        obj.fileListInfo = ''
        this.$emit('hasUpload', obj)
      }
      this.$emit('close')
    },
    chooseFile() {
      this.feeVisible = true
    },

    changeFee() {
      if (this.curFeeType) {
        this.$refs.file.value = null
        this.$refs.file.click()
      }

      this.feeVisible = false
    },

    // 文件上传
    fileChange() {
      let myfile = this.$refs.file
      if (myfile.files[0] === undefined) {
        this.$message.warning('未上传任何文件！')
      } else {
        // 上传单个文件
        if (myfile.files.length === 1) {
          let formData = new FormData()
          formData.append('file', myfile.files[0])
          this.uploadFile(formData)
        } else {
          // 批量上传文件
          let formData = new FormData()
          for (let item of myfile.files) {
            formData.append('files', item)
          }
          this.uploadBatchFile(formData)
        }
      }
    },

    //删除某个文件
    remove(id) {
      for (let i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].id === id) {
          this.$message.success(`${this.fileList[i].oname}删除成功`)
          this.fileList.splice(i, 1)
          this.$store.dispatch('uploadFile/pushDelFiles', id)
        }
      }
    },

    /**
     * 上传单个文件
     * @param {File} file
     */
    uploadFile(file) {
      getUpload(file)
        .then(response => {
          const file = response
          if (file.type === 'pdf') {
            file.src = require('@/assets/upload/pdf.png')
            file.reimFeeType = this.curFeeType
            file.reimFeeTypeName = this.feeTypeMap[this.curFeeType]
            this.fileList.push(file)
          } else if (file.type === 'docx' || file.type === 'doc') {
            file.src = require('@/assets/upload/word.png')
            file.reimFeeType = this.curFeeType
            file.reimFeeTypeName = this.feeTypeMap[this.curFeeType]
            this.fileList.push(file)
          } else if (file.type === 'xls' || file.type === 'xlsx') {
            file.src = require('@/assets/upload/excel.png')
            file.reimFeeType = this.curFeeType
            file.reimFeeTypeName = this.feeTypeMap[this.curFeeType]
            this.fileList.push(file)
          } else {
            getIMGURL(file.id).then(response => {
              file.src = response
              file.reimFeeType = this.curFeeType
              file.reimFeeTypeName = this.feeTypeMap[this.curFeeType]
              this.fileList.push(file)
            })
          }
          this.$store.dispatch('uploadFile/pushAddFiles', file.id)
          this.$message.success(`${file.oname}上传成功`)
        })
        .catch(() => {
          this.$message.error('上传失败')
        })
    },

    /**
     * 批量上传文件
     * @param {Array} fileList
     */
    uploadBatchFile(fileList) {
      getBatch(fileList)
        .then(response => {
          const list = response
          for (let file of list) {
            if (file.type === 'pdf') {
              file.src = require('@/assets/upload/pdf.png')
              file.reimFeeType = this.curFeeType
              file.reimFeeTypeName = this.feeTypeMap[this.curFeeType]
              this.fileList.push(file)
            } else if (file.type === 'docx' || file.type === 'doc') {
              file.src = require('@/assets/upload/word.png')
              file.reimFeeType = this.curFeeType
              file.reimFeeTypeName = this.feeTypeMap[this.curFeeType]
              this.fileList.push(file)
            } else if (file.type === 'xls' || file.type === 'xlsx') {
              file.src = require('@/assets/upload/excel.png')
              file.reimFeeType = this.curFeeType
              file.reimFeeTypeName = this.feeTypeMap[this.curFeeType]
              this.fileList.push(file)
            } else {
              getIMGURL(file.id).then(response => {
                file.src = response
                file.reimFeeType = this.curFeeType
                file.reimFeeTypeName = this.feeTypeMap[this.curFeeType]
                this.fileList.push(file)
              })
            }
            this.$store.dispatch('uploadFile/pushAddFiles', file.id)
          }
          this.$message.success('上传成功')
        })
        .catch(() => {
          this.$message.error('上传失败')
        })
    }
  }
}
</script>

<style lang="less"></style>
