<template>
  <i @click="$emit('click')" class="anticon">
    <svg class="icon-svg" :style="{ fontSize: size }" aria-hidden="true">
      <use :xlink:href="`#icon-${icon}`" />
    </svg>
  </i>
</template>

<script>
export default {
  name: 'IconSvg',
  props: {
    icon: {
      type: String,
      required: true
    },
    size: {
      type: String
    }
  }
}
</script>

<style lang="less" scoped>
.icon-svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
