<template>
  <a-form class="pl-4" labelAlign="left">
    <div class="border-b py-4 text-sm font-semibold">
      <a-icon type="profile" />
      <span class="ml-2">合同信息</span>
    </div>
    <a-row class="p-4">
      <template v-for="item in contractForm">
        <a-col
          v-if="!item.show || item.show()"
          :xl="6"
          :xxl="4"
          :key="item.prop"
        >
          <a-form-item
            v-if="!item.show || item.show()"
            :label="item.label"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :required="!item.disabled"
          >
            <a-select
              v-if="item.type === 'select'"
              v-model="contract[item.prop]"
              :disabled="isFlow || item.disabled"
            >
              <a-select-option
                v-for="doc in item.options"
                :key="doc.value"
                :value="doc.value"
              >
                {{ doc.text }}
              </a-select-option>
            </a-select>
            <a-input
              v-else
              v-model="contract[item.prop]"
              :disabled="isFlow || item.disabled"
              :oninput="item.validate ? oninput[item.validate] : ''"
            ></a-input>
          </a-form-item>
        </a-col>
      </template>
    </a-row>
    <a-row class="px-4" v-if="isContarct">
      <a-col v-for="item in contractFileForm" :xl="6" :xxl="4" :key="item.prop">
        <a-form-item>
          <div class="flex items-center">
            <span class="relative">
              <span :class="[item.notVerity ? '' : 'app-form-required']">
                {{ item.label }} :
              </span>
              <span
                class="absolute text-white cursor-pointer"
                style="right: -30px"
                @click="openFile(item)"
              >
                {{
                  contract[item.prop]
                    ? JSON.parse(contract[item.prop]).length
                    : '0'
                }}
              </span>
            </span>
            <icon-svg
              icon="icon_wenjianjia3"
              class="text-4xl ml-2 cursor-pointer"
              @click="openFile(item)"
            />
          </div>
        </a-form-item>
      </a-col>
    </a-row>
    <!-- 流程信息 -->
    <process
      :status="isFlow"
      :summaryid="contract.secondFlowInfoDTO.summaryid"
      :nextapproveman="contract.secondFlowInfoDTO.nextapproveman"
      :curApprover="contract.secondFlowInfoDTO.curApprover"
    />
    <!-- 审批意见 -->
    <comment :uid="form.applicant" :billId="contract.contractPartId"></comment>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </a-form>
</template>

<script>
// api
import { oninput } from '@/utils/validate'
import { isDisabled } from '@/utils/util'
import cloneDeep from 'lodash/cloneDeep'
// conponents
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import isEmpty from 'lodash/isEmpty'

// 是否有合同
const hasContractEnum = [
  { text: '是', value: 'true' },
  { text: '否', value: 'false' }
]

// 是否需要预付
const hasPayEnum = [
  { text: '是', value: 'true' },
  { text: '否', value: 'false' }
]
export default {
  name: 'SecondPart',
  components: { UploadModal, PreviewModal, Process, Comment },
  props: {
    type: {
      type: String,
      default: ''
    },

    pid: {
      type: String,
      default: ''
    },

    form: Object,
    labelCol: Object,
    wrapperCol: Object
  },
  data() {
    return {
      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: '',

      oninput, // 校验器
      contract: {
        hasContract: '', // 是否有合同
        needPrepaid: '', // 是否需要预付
        prepaidAmount: '', // 预付款金额
        uploadUnStampedContract: '', // 未盖章合同上传
        contractPartId: '', // 流程id
        secondFlowInfoDTO: {} // 流程信息
      },

      contractForm: [
        {
          label: '是否有合同',
          prop: 'hasContract',
          disabled: false,
          type: 'select',
          options: hasContractEnum
        },
        {
          label: '是否需要预付',
          prop: 'needPrepaid',
          disabled: false,
          type: 'select',
          options: hasPayEnum,
          show: () => this.isContarct
        },
        {
          label: '预付款金额',
          prop: 'prepaidAmount',
          disabled: false,
          validate: 'number',
          show: () => this.isContarct && this.isPay
        }
      ],

      contractFileForm: [
        {
          label: '未盖章合同上传',
          prop: 'uploadUnStampedContract',
          disabled: false
        }
      ]
    }
  },

  computed: {
    isFlow() {
      const status = this.contract.secondFlowInfoDTO
        ? this.contract.secondFlowInfoDTO.status
        : '1'
      return isDisabled(status)
    },

    isContarct() {
      if (typeof this.contract.hasContract === 'string')
        return this.contract.hasContract === 'true' ? true : false

      return this.contract.hasContract
    },

    isPay() {
      if (typeof this.contract.needPrepaid === 'string')
        return this.contract.needPrepaid === 'true' ? true : false
      return this.contract.needPrepaid
    }
  },

  watch: {
    form: {
      handler(newval) {
        if (!isEmpty(newval)) {
          // 分发数据
          this.$nextTick(() => {
            this.distributeData(this.contract)
            if (typeof this.contract.hasContract === 'boolean')
              this.contract.hasContract = this.contract.hasContract
                ? 'true'
                : 'false'
            if (typeof this.contract.needPrepaid === 'boolean')
              this.contract.needPrepaid = this.contract.needPrepaid
                ? 'true'
                : 'false'
          })
          // 提交当前组件的流程信息
          this.$emit('comp-flow-info', newval.secondFlowInfoDTO)
        }
      },
      immediate: true
    }
  },

  methods: {
    // 附件上传或预览
    openFile(item) {
      const title = item.label
      const fileProp = item.prop
      const module = this.contract

      if (this.isFlow) {
        this.previewTitle = title
        this.previewFileListInfo = module[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = module[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '未盖章合同上传':
          this.contract.uploadUnStampedContract = fileListInfo
          break
        default:
          break
      }
    },

    setBackStatus() {
      this.contract.secondFlowInfoDTO.status = '5'
      // 提交当前组件的流程信息
      this.$emit('comp-flow-info', this.contract.secondFlowInfoDTO)
    },

    distributeData(module) {
      Object.keys(module).forEach(key => this.$set(module, key, this.form[key]))
    },

    verityAppForm(data) {
      const canVerityFormItem = [...this.contractForm].filter(
        item => !item.disabled
      )

      // 项目合同校验
      for (const formItem of canVerityFormItem) {
        if (formItem.show && !formItem.show()) continue

        if (!data[formItem.prop] && data[formItem.prop] != '0') {
          this.$message.error(`${formItem.label}不能为空`)
          return false
        }
      }

      return true
    },

    mergeData(isVerity = false) {
      const contract = cloneDeep(this.contract)
      if (isVerity && !this.verityAppForm(contract)) return false
      contract.hasContract = contract.hasContract === 'true' ? true : false
      contract.needPrepaid = contract.needPrepaid === 'true' ? true : false
      return Object.assign({}, this.form, contract)
    }
  }
}
</script>

<style lang="less" scoped></style>
