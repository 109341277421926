<template>
  <div class="ml-2 mt-3">
    <a-table
      ref="table"
      rowKey="id"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      bordered
      size="small"
      :scroll="{ y: 240 }"
      :customRow="
        (record, index) => {
          return {
            on: {
              click: () => {
                chooseRowRecord(record, index)
              }
            }
          }
        }
      "
      :rowClassName="
        record => {
          return record.id === chooseRecord.id ? 'choose-active' : ''
        }
      "
    >
      <template slot="input" slot-scope="text, record, index, column">
        <slot name="inputCell" :record="record" :column="column"></slot>
      </template>
      <template slot="datePicker" slot-scope="text, record, index, column">
        <slot name="datePickerCell" :record="record" :column="column"></slot>
      </template>
      <template slot="select" slot-scope="text, record, index, column">
        <slot name="selectCell" :record="record" :column="column"></slot>
      </template>
    </a-table>
    <div class="mt-4">
      <div class="inline-block" v-if="!isDisabled">
        <a-button
          icon="plus-circle"
          class="border-blue-600 text-blue-600 hover:text-blue-300 hover:border-blue-300 focus:border-blue-600 focus:text-blue-600"
          @click="addRecord"
        >
          添加一行
        </a-button>
        <a-button
          icon="delete"
          class="ml-2 border-red-500 text-red-500 hover:text-red-300 hover:border-red-300 focus:border-red-500 focus:text-red-500"
          @click="delRecord(chooseIndex)"
        >
          删除一行
        </a-button>
      </div>
      <div class="inline-block float-right" style="line-height: 32px">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { randomKey } from '@/utils/util'
export default {
  name: 'Table',
  props: {
    columns: {
      type: Array,
      default: () => []
    },

    data: {
      type: Array,
      default: () => []
    },

    dataTemplate: {
      type: Object,
      default: () => ({})
    },

    isDisabled: Boolean
  },
  data() {
    return {
      maxTableHeight: 0,
      chooseRecord: {},
      chooseIndex: null
    }
  },

  mounted() {
    this.dom = this.$refs.table
    this.initScrollHeight()
  },

  methods: {
    addRecord() {
      const dataTemplate = JSON.parse(JSON.stringify(this.dataTemplate))
      const dataReactive = Vue.observable(dataTemplate)
      // 生成随机key，提交时会删除
      dataReactive.id = this.getRandomKey()
      this.data.push(dataReactive)
      this.maxTableHeight += 44
      this.scrollToBottom()
    },

    delRecord(index) {
      if (this.chooseIndex !== 0 && !this.chooseIndex) {
        this.$message.warning('请选择要删除的行')
        return
      }

      this.chooseIndex = null
      this.chooseRecord = {}
      this.data.splice(index, 1)
      this.maxTableHeight -= 44
      this.$emit('deleteItem')
    },

    chooseRowRecord(record, index) {
      if (this.chooseIndex === index && record.id === this.chooseRecord.id) {
        this.chooseIndex = null
        this.chooseRecord = {}
        return
      }

      this.chooseRecord = record
      this.chooseIndex = index
    },

    initScrollHeight() {
      this.maxTableHeight = 44 * this.data.length
    },

    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        this.dom.$el.querySelector('.ant-table-body').scrollTop =
          this.maxTableHeight
      })
    },

    /**
     * 生成随机key
     */
    getRandomKey() {
      const prefixKey = 'add-'
      const key = prefixKey + randomKey(20)
      return key
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .choose-active {
  background: #f0f8ff;
}
</style>
