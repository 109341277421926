<template>
  <div>
    <a-row class="flex items-center">
      <a-col :span="10">会议名称: {{ hData.conferenceName }}</a-col>
      <a-col :span="3">预计签到人数: {{ hData.expectNum }}</a-col>
      <a-col :span="3">当前签到人数: {{ hData.curentNum }}</a-col>
      <a-col :span="8" class="flex justify-end atc-sign-btn">
        <a-button v-if="!isReim" class="mr-2" @click="downloadLink">
          复制链接
        </a-button>

        <a-popover
          v-if="!isReim"
          :autoAdjustOverflow="false"
          placement="bottom"
          @visibleChange="visibleChange"
        >
          <template slot="content">
            <div>
              <div ref="qrcode"></div>
            </div>
          </template>
          <a-button class="mr-2" @click="downloadQr">下载签到码</a-button>
        </a-popover>

        <a-button class="mr-2" @click="exportExcel">导出</a-button>
        <a-button v-if="!isReim" class="mr-2" @click="refresh">刷新</a-button>
      </a-col>
    </a-row>

    <a-table
      rowKey="id"
      class="mt-4"
      :dataSource="bData"
      :columns="getColumns"
      :pagination="false"
      :loading="loading"
      :rowClassName="record => (isReim && record.whetherTimeout ? 'trBg' : '')"
      @change="getList"
    >
      <div
        slot="filterDropdown"
        slot-scope="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column
        }"
        class="custom-filter-dropdown"
      >
        <a-input
          v-ant-ref="c => (searchInput = c)"
          placeholder="查询"
          :value="selectedKeys[0]"
          @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
          @pressEnter="() => handleSearch(selectedKeys, confirm, column)"
          style="width: 180px; margin-bottom: 8px; display: block"
        />
        <a-button
          type="primary"
          @click="() => handleSearch(selectedKeys, confirm, column)"
          icon="search"
          style="width: 80px; margin-right: 10px"
        >
          查询
        </a-button>
        <a-button
          @click="() => handleReset(clearFilters, column)"
          style="width: 80px"
        >
          重置
        </a-button>
      </div>
      <a-icon
        slot="filterIcon"
        slot-scope="filtered"
        type="search"
        :style="{ color: filtered ? '#108ee9' : undefined }"
      />
      <template slot="isTimeout" slot-scope="text, record">
        <span>{{ record.whetherTimeout ? '是' : '否' }}</span>
      </template>
    </a-table>
    <div class="text-right mt-6">
      <a-pagination
        :hideOnSinglePage="true"
        :current="current"
        :pageSize="pageSize"
        :total="total"
        @change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import listMix from '@/globalMix/listMix'
import {
  getListHead,
  getListBody,
  getExportExcel
} from '@/api/acdemicActive/act-sign'
import QRCode from 'qrcodejs2'
import isEmpty from 'lodash/isEmpty'
export default {
  name: 'SignTable',
  mixins: [listMix],
  props: {
    pkSrc: {
      type: String
    },
    // 是否总结单引用的组件
    isReim: {
      type: Boolean,
      dafault: false
    }
  },

  data() {
    return {
      current: 1,
      pageSize: 10,
      total: 0,
      conditions: [{ column: 'appId', opt: '=', value: this.pkSrc }],
      loading: false,
      qrcode: {},

      hData: {}, // 表格头部数据
      bData: [], // 表格表体数据
      columns: [
        {
          title: '序号',
          dataIndex: 'rowNum'
        },
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '联系方式',
          dataIndex: 'contact',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '签到时间',
          dataIndex: 'signTime',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        }
      ]
    }
  },

  computed: {
    getColumns() {
      if (this.isReim) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.columns.push({
          title: '是否超时',
          dataIndex: 'whetherTimeout',
          scopedSlots: {
            customRender: 'isTimeout'
          }
        })
      }
      return this.columns
    }
  },

  created() {
    this.getTableData()
  },

  methods: {
    async getTableData() {
      this.getList()
      this.hData = await getListHead(this.pkSrc)
    },

    getList(_pagination) {
      if (_pagination) this.current = 1

      this.loading = true
      getListBody(
        this.pkSrc,
        this.current,
        this.pageSize,
        this.conditions
      ).then(res => {
        this.loading = false
        this.bData = res.datas
        this.total = res.totalNum
      })
    },

    generateLink() {
      const baseUrl =
        process.env.VUE_APP_MODE === 'production'
          ? 'https://crm.dyg.com.cn/sign'
          : 'https://crmtest.dyg.com.cn:12082/sign'

      return (
        baseUrl +
        '?appId=' +
        this.pkSrc +
        '&conferenceName=' +
        this.hData.conferenceName
      )
    },

    downloadLink() {
      const url = this.generateLink()
      navigator.clipboard.writeText(url).then(() => {
        this.$message.success('链接已复制')
      })
    },

    visibleChange(visible) {
      if (visible && isEmpty(this.qrcode)) {
        this.initQRcode()
      }
    },

    initQRcode() {
      this.$nextTick(() => {
        const url = this.generateLink()
        this.qrcode = new QRCode(this.$refs.qrcode, {
          width: 200,
          height: 200,
          text: url,
          correctLevel: QRCode.CorrectLevel.H
        })
      })
    },

    downloadQr() {
      const qrCanvas = this.qrcode._oDrawing._elCanvas
      const url = qrCanvas.toDataURL('image/png')
      const a = document.createElement('a')
      const handle = new MouseEvent('click')
      a.download = `${this.hData.conferenceName}签到二维码`
      a.href = url
      a.dispatchEvent(handle)
    },

    exportExcel() {
      const exportName = `${this.hData.conferenceName}电子签到表`
      getExportExcel(this.conditions, exportName)
        .then(() => {
          this.$message.success('导出成功')
        })
        .catch(error => {
          this.$message.error(error)
        })
    },

    refresh() {
      this.getTableData()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.trBg {
  background-color: rgb(255, 219, 219);
}
</style>
