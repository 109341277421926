const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '申请类型', value: `appTypeName` },
      { label: '申请人', value: `applicantName` },
      { label: '申请人编码', value: `applicantCode` },
      { label: '门店名称', value: `name` },
      { label: '门店地址', value: `storeAddress` },
      { label: '省份', value: `province` },
      { label: '市', value: `city` },
      { label: '镇/区', value: `town` }
    ]
  }
]

export { BASE }
