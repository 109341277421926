<template>
  <div>
    <a-modal
      title="选择活动类型及执行标准"
      width="800px"
      class="active-modal"
      :visible="visible"
      :keyboard="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="active-content w-full">
        <a-table
          rowKey="actID"
          class="active-table"
          :rowSelection="{
            onChange: onSelectChange,
            type: 'radio',
            selectedRowKeys: selectedRowKeys
          }"
          :pagination="false"
          :columns="columns"
          :centered="true"
          :dataSource="activeTypeList"
          :loading="loading"
          :rowClassName="
            (record, index) => {
              return index % 2 === 0 ? 'trBgClass' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  dblclick: () => {
                    handleClick(record)
                  },
                  click: () => {
                    sclick(record)
                  }
                }
              }
            }
          "
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
// import { getQueryactivity } from '@/api/acdemicActive/acdemic-application'
// import { getActByRoleList } from '@/api/acdemicActive/activity-material'
import { loadDictTypes } from '@/api/customer/dictmanage'
import { getActivityTypesWithDataControl } from '@/api/acdemicActive/activity-types'
const columns = [
  {
    title: '活动类型',
    dataIndex: 'acttype',
    align: 'left'
  }
]
export default {
  name: 'chooseActiveModal',
  props: {
    conferenceTypeName: String,
    businessType: String
  },
  data() {
    return {
      visible: true,
      loading: false,
      activeTypeList: [],
      selectedRowKeys: [], //选择的行id
      activeType: '',
      columns
    }
  },
  created() {
    //根据所属公司查找活动类型
    // this.loading = true
    // this.activeTypeList = []
    // const roles = this.getRoleIds()
    // const condition = [{ column: 'roleId', opt: 'in', value: roles }]

    // getQueryactivity().then(response => {
    //   // 所属公司类型的活动类型
    //   for (let item of response.datas) {
    //     if (!item.stdtypeDo) continue
    //     if (item.stdtypeDo.name === this.conferenceTypeName)
    //       this.activeTypeList.push(item)
    //   }

    //   // 特殊活动类型
    //   getActByRoleList(1, 200, condition).then(res => {
    //     if (res.datas && !res.datas.length) {
    //       this.loading = false
    //       return
    //     }
    //     const actByRoles = this.actByRoleIds(res.datas)
    //     for (let item of response.datas) {
    //       if (item.stdtypeDo) continue
    //       if (actByRoles.includes(item.actID)) {
    //         this.activeTypeList.push(item)
    //       }
    //     }
    //     this.loading = false
    //   })
    // })
    loadDictTypes().then(response => {
      const list = this.queryDoc(response, '标准类型(活动类型及执行标准)')
      const stdtype = list.find(item => item.name === this.conferenceTypeName)
      getActivityTypesWithDataControl(1, 200, {
        stdtype: stdtype.id,
        businessType: this.businessType
      }).then(res => {
        this.activeTypeList = res.datas
      })
    })
  },
  methods: {
    showModal() {
      this.visible = true
    },

    // 返回用户的角色id数组
    getRoleIds() {
      const roles = this.$store.getters['roles']
      const roleIds = []
      for (let role of roles) {
        roleIds.push(role.rid)
      }
      return roleIds
    },

    // 返回用户角色所属活动类型的id数组
    actByRoleIds(list) {
      const actByRoleIds = []
      for (let item of list) {
        actByRoleIds.push(item.billId)
      }
      return actByRoleIds
    },

    handleOk() {
      const activeType = this.activeTypeList.find(
        item => item.actID === this.activeType
      )
      if (activeType !== undefined) {
        this.$emit('activeType', activeType)
      }
      this.$emit('close')
    },

    /**
     * 选择的双击事件（直接选择，关闭弹框）
     * @param {Object} item 自选的活动类型及执行标准
     */
    handleClick(item) {
      const activeType = []
      activeType.push(item.actID)
      this.onSelectChange(activeType)
      this.handleOk()
    },

    /**
     * 选择的单击事件（直接选择，不关闭弹框）
     * @param {Object} item 自选的活动类型及执行标准
     */
    sclick(item) {
      const activeType = []
      activeType.push(item.actID)
      this.onSelectChange(activeType)
    },

    handleCancel() {
      this.$emit('close')
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.activeType = selectedRowKeys[0]
    },

    /**
     * 查询会议类型、业务类型枚举
     * @param {Array} docList //枚举列表
     * @param {String} meetType //枚举名称（所属公司）
     */
    queryDoc(docList, meetType) {
      for (let item of docList) {
        if (item.name === meetType) return item.defDocDTOs
      }
    }
  }
}
</script>

<style lang="less">
.active-table .ant-table-tbody > tr > td {
  padding: 8px 8px;
}

.active-modal .ant-modal-header {
  padding: 8px 8px;
  background-color: #4587f4;
}
.active-modal .ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.active-modal .ant-modal-close-x {
  width: 39px;
  height: 39px;
  line-height: 39px;
  color: #ffffff;
}
.active-modal .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.active-modal .ant-modal-body {
  padding: 8px;
}
.active-modal .ant-btn-primary,
.active-modal .ant-btn-primary:hover {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
}
.active-content {
  padding: 0 8px;
  height: 58vh;
  overflow: auto;
}

.active-content .button {
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 0px 0px #f0f7fa;
  -moz-box-shadow: 0px 1px 0px 0px #f0f7fa;
  box-shadow: 0px 1px 0px 0px #f0f7fa;
  color: #606266;
  font-size: 8px;
  padding: 4px 8px 4px 8px;
  border: solid #a0aec0 1px;
  text-decoration: none;
  &.choose-btn {
    padding: 4px 15px 4px 15px;
  }
  &:focus {
    outline: 0;
  }
}

.active-content .button:hover {
  border: solid #606266 1px;
  text-decoration: none;
}
</style>
