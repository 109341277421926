const APP_BILL = [
  {
    title: '申请人信息',
    titleVerify: [1, 2, 3, 4],
    children: [
      { label: '申请人', value: `applicantDTO`, subValue: 'username' },
      { label: '岗位', value: `post` },
      { label: '联系方式', value: `contact` },
      { label: '申请日期', value: `appdate` },
      { label: '大区/部门', value: `regionDTO`, subValue: 'deptname' },
      { label: '省办', value: `provinceDTO`, subValue: 'deptname' },
      { label: '地办', value: `localityDTO`, subValue: 'deptname' },
      { label: '部门', value: `departmentDTO`, subValue: 'deptname' }
    ]
  },
  {
    title: '活动信息',
    titleVerify: [1, 2, 3, 4],
    children: [
      { label: '会议名称', value: `conferenceName` },
      { label: '会议编号', value: `conferenceCode` },
      { label: '所属公司', value: `conferenceTypeDTO`, subValue: 'name' },
      { label: '业务类型', value: `businessTypeDTO`, subValue: 'name' },
      { label: '产品', value: `productDTO`, subValue: 'name' },
      { label: '举办类型', value: `hostingTypeDTO`, subValue: 'name' },
      { label: '活动地点', value: `activityPlace`, verify: [1, 2, 4] },
      { label: '活动主题', value: `activityTheme`, verify: [1, 2, 4] },
      { label: '活动目的', value: `activityPurpose`, verify: [1, 2, 4] },
      { label: '举办性质', value: `activityNatrue`, verify: [1, 2, 4] },
      { label: '开始日期', value: `activityStdate` },
      { label: '结束日期', value: `activityEddate` },
      { label: '讲者（人）', value: `speakerNum`, verify: [1, 2, 4] },
      { label: '预邀客户', value: `preinvitednum`, verify: [1, 2, 4] },
      { label: '内部人员', value: `internalnum`, verify: [1, 2, 4] },
      { label: '会务合同', value: `hasconfContract`, verify: [1, 2, 4] },
      { label: '其他合同', value: `otherContract`, verify: [1, 2, 4] },
      { label: '会务公司名称', value: `confCompany`, verify: [1, 2, 4] },
      { label: '会务合同金额', value: `contractMoney`, verify: [1, 2, 4] },
      { label: '主办方名称', value: `sponsor`, verify: [1, 2, 4] },
      { label: '成立日期', value: `sponsorEstdate`, verify: [1, 2, 4] },
      { label: '公对公预付金额', value: `budgetPreCost`, verify: [1, 2, 4] },
      { label: '推广物料', value: `isbrandpre`, verify: [1, 2, 4] },
      { label: '品牌信息-发布主题', value: `pubTheme`, verify: [3, 4] },
      { label: '品牌信息-发布目的', value: `pubPurpose`, verify: [3, 4] },
      {
        label: '预邀参会人员名单',
        value: `participantsList`,
        type: 'file',
        verify: [1, 2, 4]
      },
      {
        label: '自办活动议程和邀请函',
        value: `activityInvitation`,
        type: 'file',
        verify: [1, 2, 4]
      },
      {
        label: '品牌信息-发布方案',
        value: `pubScheme`,
        type: 'file',
        verify: [3, 4]
      },
      {
        label: '品牌信息-发布合同',
        value: `partnerContract`,
        type: 'file',
        verify: [3, 4]
      },
      {
        label: '已签批的合同扫描件',
        value: `contractScan`,
        type: 'file',
        verify: [1, 2, 4]
      },
      {
        label: '主办方资质证明',
        value: `sponsorQualification`,
        type: 'file',
        verify: [1, 2, 4]
      },
      {
        label: '主办方邀请函',
        value: `sponsorInvitation`,
        type: 'file',
        verify: [1, 2, 4]
      },
      {
        label: '已签批的协办协议/合同',
        value: `contract`,
        type: 'file',
        verify: [1, 2, 4]
      }
    ]
  },
  {
    title: '活动类型及执行标准',
    titleVerify: [1, 2, 4],
    children: [
      {
        label: '活动类型',
        value: `activityTypeStdDTO`,
        subValue: 'acttype',
        col: 2,
        length: 48
      },
      {
        label: '参会客户（人）',
        value: `activityTypeStdDTO`,
        subValue: 'customernum',
        col: 2,
        length: 48
      },
      {
        label: '讲者讲课时间（分钟/人）',
        value: `activityTypeStdDTO`,
        subValue: 'lectureTime',
        col: 2,
        length: 48
      },
      {
        label: '人员人数（比例）',
        value: `activityTypeStdDTO`,
        subValue: 'speakernum',
        col: 2,
        length: 48
      },
      {
        label: '场地费（元）',
        value: `activityTypeStdDTO`,
        subValue: 'venuefee',
        col: 2,
        length: 48
      },
      {
        label: '住宿费（元/人/天）',
        value: `activityTypeStdDTO`,
        subValue: 'hotelfee',
        col: 2,
        length: 48
      },
      {
        label: '餐费（元/人/天）',
        value: `activityTypeStdDTO`,
        subValue: 'mealsfee',
        col: 2,
        length: 48
      },
      {
        label: '讲者咨询费（元/人/天）',
        value: `activityTypeStdDTO`,
        subValue: 'speakerConsultationFee',
        col: 2,
        length: 48
      },
      {
        label: '飞机费（元）',
        value: `activityTypeStdDTO`,
        subValue: 'trafficExpenseAirfare',
        col: 2,
        length: 48
      },
      {
        label: '高铁费（元）',
        value: `activityTypeStdDTO`,
        subValue: 'trafficExpenseTrain',
        col: 2,
        length: 48
      },
      {
        label: '推广物料标准费（元）',
        value: `activityTypeStdDTO`,
        subValue: 'brandstd',
        col: 2,
        length: 48
      },
      {
        label: '备注（元）',
        value: `activityTypeStdDTO`,
        subValue: 'notes',
        col: 2,
        length: 48
      }
    ]
  }
]
const APP_TABLE = [
  {
    title: '讲者信息',
    titleVerify: [1, 2, 4],
    dataList: 'speakers',
    columns: [
      { title: '编号', dataIndex: 'code' },
      { title: '名称', dataIndex: 'name' },
      { title: '单位', dataIndex: 'departmentName' },
      { title: '级别', dataIndex: 'speakerLevelName' },
      { title: '咨询费', dataIndex: 'appConsultingFee' },
      { title: '课题名称', dataIndex: 'topicname' },
      { title: '讲课时长/分钟', dataIndex: 'lengthoflecture' }
    ]
  },
  {
    title: '推广物料信息',
    titleVerify: [1, 2, 4],
    dataList: 'brandpreInfoDTOs',
    columns: [
      { title: '编号', dataIndex: 'materialCode' },
      { title: '类型', dataIndex: 'type' },
      { title: '物料名称', dataIndex: 'materialName' },
      { title: '物料标识', dataIndex: 'materialIdentification' },
      { title: '起订量单价', dataIndex: 'price' },
      { title: '申请数量', dataIndex: 'brandpreNumApp' },
      {
        title: '金额',
        dataIndex: 'brandpreMoneyamount',
        scopedSlots: { customRender: 'countPrice' }
      },
      { title: '备注', dataIndex: 'notes' }
    ]
  },
  {
    title: '品牌信息发布费用明细',
    titleVerify: [3, 4],
    dataList: 'expenseDetailDTOs',
    columns: [
      { title: '序号', dataIndex: 'seriNumber' },
      { title: '合作方名称', dataIndex: 'partnerName' },
      { title: '合作金额', dataIndex: 'copAmount' }
    ]
  }
]
const APP_FEE = {
  title: '费用信息',
  columns: [
    {
      title: '项目',
      dataIndex: 'projectName'
    },
    {
      title: '单价',
      dataIndex: 'price'
    },
    {
      title: '数量',
      dataIndex: 'num'
    },
    {
      title: '总价',
      dataIndex: 'total'
    }
  ],
  dataList: [
    {
      id: 'Booth',
      projectName: '展位(场地费)',
      price: 'budgetBooth',
      num: 'budgetBoothNum',
      total: ''
    },
    {
      id: 'Meetingroom',
      projectName: '会议室(场地费)',
      price: 'budgetMeetingroom',
      num: 'budgetMeetingroomNum',
      total: ''
    },
    {
      id: 'Make',
      projectName: '制作费',
      price: 'budgetMake',
      num: 'budgetMakeNum',
      total: ''
    },
    {
      id: 'Design',
      projectName: '设计费',
      price: 'budgetDesign',
      num: 'budgetDesignNum',
      total: ''
    },
    {
      id: 'Flight',
      projectName: '飞机费',
      price: 'budgetFlight',
      num: 'budgetFlightNum',
      total: ''
    },
    {
      id: 'Train',
      projectName: '高铁费',
      price: 'budgetTrain',
      num: 'budgetTrainNum',
      total: ''
    },
    {
      id: 'Reception',
      projectName: '接送费',
      price: 'budgetReception',
      num: 'budgetReceptionNum',
      total: ''
    },
    {
      id: 'Accommodation',
      projectName: '住宿费',
      price: 'budgetAccommodation',
      num: 'budgetAccommodationNum',
      total: ''
    },
    {
      id: 'Meals',
      projectName: '餐费',
      price: 'budgetMeals',
      num: 'budgetMealsNum',
      total: ''
    },
    {
      id: 'Thirdparty',
      projectName: '第三方会务人员费用',
      price: 'budgetThirdparty',
      num: 'budgetThirdpartyNum',
      total: ''
    },
    {
      id: 'Service',
      projectName: '会务服务费、税费',
      price: '--',
      num: '--',
      total: 'budgetServiceTotal'
    },
    {
      id: 'Cooperation',
      projectName: '协办费',
      price: '--',
      num: '--',
      total: 'budgetCooperationTotal'
    },
    {
      id: 'Consult',
      projectName: '讲者咨询费',
      price: '--',
      num: '--',
      total: 'budgetConsultTotal'
    },
    {
      id: 'Brandpre',
      projectName: '推广物料费',
      price: '--',
      num: '--',
      total: 'budgetBrandpreTotal'
    },
    {
      id: 'OtherFee',
      projectName: '其他费用',
      price: '--',
      num: '--',
      total: 'otherFee'
    },
    {
      id: 'RegisterFee',
      projectName: '注册费',
      price: '--',
      num: '--',
      total: 'registerFee'
    },
    {
      id: 'InfoPubAmount',
      projectName: '信息发布费',
      price: '--',
      num: '--',
      total: 'infoPubAmount'
    },
    {
      id: 'OnlineLiveBroadcastingFee',
      projectName: '线上直播平台费用',
      price: '--',
      num: '--',
      total: 'onlineLiveBroadcastingFee'
    }
  ]
}

export { APP_BILL, APP_TABLE, APP_FEE }
