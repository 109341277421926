var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{staticClass:"active-select-modal",attrs:{"bordered":"","title":"选择活动类型及执行标准","width":"1000px","keyboard":false},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"w-full"},[_c('a-table',{attrs:{"bordered":"","rowKey":"actID","pagination":false,"columns":_vm.columns,"centered":true,"dataSource":_vm.activeTypeList,"loading":_vm.loading,"rowSelection":{
          onChange: _vm.onSelectChange,
          type: 'radio',
          selectedRowKeys: _vm.selectedRowKeys
        },"rowClassName":function (record, index) {
            return index % 2 === 1 ? 'trBgClass' : ''
          },"customRow":function (record) {
            return {
              on: {
                click: function () {
                  _vm.sclick(record)
                }
              }
            }
          }}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }