import request from '@/utils/request'

/**
 * 分页查询所有公告(公告管理节点)
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getAnuceList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `anuce/mng/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 公告新增
 * @param {Object} data
 * @returns
 */
export function SaveAnuce(data) {
  return request({
    method: 'post',
    url: `anuce/save`,
    data: data
  })
}

/**
 * 根据ID来查询公告
 * @param {String} id
 * @returns
 */
export function getAnuceById(id) {
  return request({
    method: 'get',
    url: `anuce/mng/${id}`
  })
}

/**
 * 设置一条消息已读
 * @param {String} id
 * @returns
 */
export function getReadAnuceById(id) {
  return request({
    method: 'post',
    url: `anuce/read/${id}`
  })
}

/**
 * 分页查询所有公告(只查自己未读、已读、全部相关消息)
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Integer} read 未读(0)、已读(1)、全部(2)
 * @returns
 */
export function getReadAnuceList(pageindex, pagenum, read = 0) {
  return request({
    method: 'post',
    url: `anuce/${pageindex}/${pagenum}`,
    param: { read }
  })
}
