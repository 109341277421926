<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="审批意见" key="1" class="customStyle">
          <div class="w-full" style="margin-left: 24px">
            <comment :handleInfoList="handleInfoList"></comment>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import { getHandleInfo } from '@/api/acdemicActive/acdemic-application'
import Comment from './CommentItem'
export default {
  name: 'CommentList',
  components: {
    Comment
  },
  props: {
    billId: String
  },
  data() {
    return {
      handleInfoList: []
    }
  },
  watch: {
    billId(nval) {
      if (nval) {
        this.getHandleInfo(nval)
      }
    }
  },
  methods: {
    getHandleInfo(billId) {
      getHandleInfo(billId).then(response => {
        this.handleInfoList = response
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
