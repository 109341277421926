<template>
  <div class="text-xs" style="padding-left: 32px">
    <recept-table
      :type="type"
      :pid="pid"
      ref="recept"
      @status="nowStatus($event)"
    ></recept-table>
  </div>
</template>

<script>
import ReceptTable from '@/views/activity/academicApplication/recept/ReceptMsgTable'

export default {
  name: 'JDSQBill',
  components: {
    ReceptTable
  },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    },

    getData() {
      return this.$refs.recept.getSubmitData()
    }
  }
}
</script>

<style lang="less" scoped></style>
