var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-table',{attrs:{"columns":_vm.getColumns,"rowKey":function (record) { return record.pk; },"dataSource":_vm.billList,"pagination":false,"loading":_vm.loading,"components":_vm.components,"scroll":_vm.total >= 10 ? { y: 405 } : {},"rowSelection":_vm.chooseValue === '2'
        ? {
            columnWidth: '36px',
            onChange: _vm.onSelectChange,
            type: 'radio',
            selectedRowKeys: _vm.selectedRowKeys
          }
        : null,"customRow":function (record) {
        return {
          on: {
            dblclick: function () {
              _vm.openModal(record)
            },
            click: function () {
              _vm.openModal(record)
            }
          }
        }
      }},on:{"change":_vm.getList},scopedSlots:_vm._u([{key:"filterDropdown",fn:function(ref){
      var setSelectedKeys = ref.setSelectedKeys;
      var selectedKeys = ref.selectedKeys;
      var confirm = ref.confirm;
      var clearFilters = ref.clearFilters;
      var column = ref.column;
return _c('div',{staticClass:"custom-filter-dropdown"},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:(function (c) { return (_vm.searchInput = c); }),expression:"c => (searchInput = c)"}],ref:"input",staticStyle:{"width":"180px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":"查询待办标题","value":selectedKeys[0]},on:{"change":function (e) { return setSelectedKeys(e.target.value ? [e.target.value] : []); },"pressEnter":function () { return _vm.handleSearch(selectedKeys, confirm, column); }}}),_c('a-button',{staticStyle:{"width":"80px","margin-right":"12px"},attrs:{"type":"primary","icon":"search"},on:{"click":function () { return _vm.handleSearch(selectedKeys, confirm); }}},[_vm._v(" 查询 ")]),_c('a-button',{staticStyle:{"width":"80px"},on:{"click":function () { return _vm.handleReset(clearFilters, column); }}},[_vm._v(" 重置 ")])],1)}},{key:"subjectItem",fn:function(subject, record){return [_c('a',{on:{"click":function($event){return _vm.openModal(record)}}},[_vm._v(" "+_vm._s(subject)+" ")])]}},{key:"dateItem",fn:function(date){return [_c('span',[_vm._v(_vm._s(_vm.getTime(date)))])]}},{key:"receiveTimeItem",fn:function(receiveTime){return [_c('span',[_vm._v(" "+_vm._s(receiveTime === null ? '无' : _vm.getTime(receiveTime))+" ")])]}},{key:"billtypeItem",fn:function(billtype){return [_c('span',[_vm._v(" "+_vm._s(_vm.getBillTypeName(billtype))+" ")])]}},{key:"sendnameItem",fn:function(curname){return [_c('span',[_vm._v(" "+_vm._s(curname || '无')+" ")])]}},{key:"status",fn:function(text){return _c('span',{},[_c('a-badge',{attrs:{"status":_vm._f("statusTypeFilter")(text),"text":_vm._f("statusFilter")(text)}})],1)}}])},[_c('a-icon',{style:({ color: _vm.searchText ? '#4587f5' : undefined }),attrs:{"slot":"filterIcon","type":"search"},slot:"filterIcon"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }