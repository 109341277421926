const BASE = [
  {
    title: '基本信息',
    active: 'BASE',
    children: [
      { label: '单据号', value: `vbillno` },
      { label: '业务日期', value: `vbilldate` },
      { label: '年度', value: 'yeardStr' },
      { label: '商业公司', value: `busiCompName` },
      { label: '品种编码', value: 'specCode' },
      { label: '品种名称', value: 'specName' },
      { label: '规格', value: 'specSpec' },
      {
        label: '兑付方式',
        value: `cashType`,
        type: 'select',
        options: [
          { value: 'GF', text: '高返' },
          { value: 'PZ', text: '票折' }
        ]
      },
      { label: '生效月份', value: 'effectiveMonthStr' },
      { label: '失效月份', value: 'expireMonthStr' },
      { label: '省办', value: 'chnlareaName' },
      {
        label: '高返单盒费用',
        value: 'refundPerBoxCost',
        show: formData => {
          return formData.cashType === 'GF'
        }
      },
      {
        label: '协议上传',
        value: `contrFile`,
        type: 'btn',
        show: formData => {
          return formData.cashType === 'PZ'
        }
      }
    ]
  }
]
const TABLE = {
  monthColumns: [
    { title: '月份', dataIndex: 'monthStr', align: 'center' },
    {
      title: '月指标',
      dataIndex: 'monthIndex',
      width: 300,
      align: 'center'
    }
  ],
  singleColumns: [
    {
      title: '完成率大于等于',
      dataIndex: 'finishRateBtOrEq',
      align: 'center'
    },
    { title: '完成率小于', dataIndex: 'finishRatelt', align: 'center' },
    {
      title: '票折单盒费用',
      width: 160,
      dataIndex: 'perBoxCost',
      align: 'center'
    }
  ]
}

export { BASE, TABLE }
