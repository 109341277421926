import request from '@/utils/request'

/**
 * 分页查询数字合同信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getHeaderContractList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `chs/digitContract/head/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 根据表头id获取数字合同表体列表(分页)
 * @param {String} id
 * @param {Integer} pageindex
 * @param {Interger} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getBodyContractList(id, pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `chs/digitContract/body/${pageindex}/${pagenum}`,
    params: { hid: id },
    data: conditions
  })
}

/**
 * 删除数字合同
 * @param {String} id
 * @returns
 */
export function delContract(id) {
  return request({
    method: 'post',
    url: `chs/digitContract/del/${id}`
  })
}

/**
 * 根据ID来查询数字合同信息
 * @param {String} id
 * @returns
 */
export function getContract(id) {
  return request({
    method: 'get',
    url: `chs/digitContract/head/${id}`
  })
}

/**
 * 保存数字合同
 * @param {Object} data
 * @returns
 */
export function saveContract(data) {
  return request({
    method: 'post',
    url: `chs/digitContract/save`,
    data
  })
}

/**
 * 更新数字合同
 * @param {Object} data
 * @returns
 */
export function updateContract(data) {
  return request({
    method: 'post',
    url: `chs/digitContract/update`,
    data
  })
}

/**
 * 提交数字合同
 * @param {Object} data
 * @returns
 */
export function submitContract(data) {
  return request({
    method: 'put',
    url: `oaflow/submitNcDigitContract`,
    data
  })
}

/**
 * 取消审批
 * @param {String} id
 * @returns
 */
export function repealContract(id) {
  return request({
    method: 'post',
    url: `chs/digitContract/unapprove/${id}`
  })
}

// nc常用的档案参照

/**
 * nc会计月份
 * accperiodmonth nc会计月份
 * bankDoc 银行档案
 * bankType 银行类型
 * chnlManager 学术渠道经理
 * ncChnlStd nc标准终端
 * ncSpeac nc产品规格清单
 * ncBusiComp 商业公司
 * ncOrgs nc组织
 * ncMaterial 物料
 * @param {String} type 'accperiodmonth' | 'bankDoc' | 'bankType' | 'chnlManager' | 'ncChnlStd' | 'ncSpeac' | 'ncBusiComp' | 'ncOrgs' | 'ncMaterial'
 * @param {Interger} pageindex
 * @param {Interger} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getNcDoc(type, pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `nc/common/${type}/${pageindex}/${pagenum}`,
    data: conditions
  })
}
