<template>
  <div class="text-xs">
    <mbyu
      :type="type"
      :pid="pid"
      @status="nowStatus($event)"
      @close="$emit('close')"
    ></mbyu>
  </div>
</template>

<script>
import Mbyu from './mbyu/MBYU'

export default {
  name: 'MBYUBill',
  components: {
    Mbyu
  },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
