<template>
  <a-icon
    v-show="invoiceList.length"
    class="ml-1 cursor-pointer"
    type="folder"
    style="color: #4587f5; font-size: 20px"
    @click="onClick"
  />
</template>

<script>
export default {
  name: 'FeeFloderIcon',
  props: {
    invoiceList: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    onClick() {
      this.$emit('handleClick', this.invoiceList)
    }
  }
}
</script>

<style lang="less" scoped></style>
