import request from '@/utils/request'

//活动类型执行标准列表
export function loadTableData(
  { current, pageSize, onlyEnable = false, stdtype },
  data = {}
) {
  return request({
    url: `/activityType/getActivityTypes`,
    method: 'post',
    params: {
      pageindex: current,
      pagenum: pageSize,
      onlyEnable,
      stdtype
    },
    data: data,
    customConfig: {
      withTimestamp: false,
      withSign: false
    }
  })
}

/**
 * 所有查询所有活动类型及执行标准,有数据控制
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getActivityTypesWithDataControl(pageindex, pagenum, condition) {
  return request({
    url: `/activityType/getActivityTypesWithDataControl`,
    method: 'post',
    params: {
      pageindex,
      pagenum
    },
    data: condition,
    customConfig: {
      withTimestamp: false,
      withSign: false
    }
  })
}

export function getActType(actID) {
  return request({
    url: `/activityType/getActivityType`,
    method: 'get',
    params: { actID: actID }
  })
}
export function saveActTypeInfo(data) {
  return request({
    url: `/activityType/addActivityType`,
    method: 'post',
    data: data
  })
}

//更新活动类型详情
export function updateActTypeInfo(data) {
  return request({
    url: `/activityType/updateActivityTypes`,
    method: 'put',
    data: data
  })
}

//删除活动类型
export function deleteActType(params) {
  return request({
    url: `/activityType/delActivityTypes`,
    method: 'delete',
    params: {
      ...params
    }
  })
}
