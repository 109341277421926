<template>
  <a-input
    :disabled="isDisabled"
    :value="record[column.dataIndex]"
    @change="onChangeValue($event, record, column)"
  ></a-input>
</template>

<script>
export default {
  name: 'InputCell',
  props: {
    record: {
      type: Object,
      default: () => ({})
    },
    column: {
      type: Object,
      default: () => ({})
    },
    isDisabled: Boolean
  },

  methods: {
    onChangeValue(e, record, column) {
      record[column.dataIndex] = e.target.value
      if (column.calc) column.calc()
    }
  }
}
</script>

<style lang="less" scoped></style>
