<template>
  <a-modal
    title="选择活动地点"
    width="85%"
    :visible="visible"
    :dialogStyle="{ top: '48px' }"
    @ok="handleOk"
    @cancel="$emit('close')"
  >
    <div class="w-full relative" style="height: calc(100vh - 256px)">
      <div class="w-full h-full map" id="map-container"></div>
      <div class="absolute" style="width: 300px; left: 36px; top: 36px">
        <a-auto-complete
          size="large"
          style="width: 100%"
          placeholder="搜索地址"
          option-label-prop="title"
          @select="onAddrSelect"
          @change="addrChange"
        >
          <template slot="dataSource">
            <a-select-option
              v-for="item in dataSource"
              :key="`crm_${item.id}`"
              :title="item.name"
            >
              <p class="font-bold">
                {{ item.name }}
                <span class="ml-2 text-gray-400">({{ item.district }})</span>
              </p>
              <p class="text-gray-500 text-xs">{{ item.address }}</p>
            </a-select-option>
          </template>
          <a-input>
            <a-button
              slot="suffix"
              style="margin-right: -12px"
              class="search-btn"
              size="large"
              type="primary"
            >
              <a-icon type="search" />
            </a-button>
          </a-input>
        </a-auto-complete>
      </div>
      <div class="absolute right-0 top-0 bg-white">
        <div class="infinite-container">
          <a-list :data-source="poisList">
            <a-list-item
              slot="renderItem"
              slot-scope="item"
              class="list-render-item"
              :class="[poiId === item.id ? 'ardio-active' : '']"
              @click="choosePois(item.id, item.location)"
            >
              <a-list-item-meta :description="item.address">
                <span slot="title">{{ item.name }}</span>
              </a-list-item-meta>
              <a-icon type="check-circle" v-if="poiId === item.id" />
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import AMap from 'AMap'
import AMapUI from 'AMapUI'
import { default as debounce } from 'lodash/debounce'
import { getTips } from '@/api/gaode'
export default {
  name: 'Amap',
  data() {
    return {
      visible: true,
      poiId: '', //选择的地址的id值
      isSearch: false,

      currentCity: {}, // 当前用的城市信息
      selectTargetItem: {}, // 搜索的提示选项
      positionResult: {}, // 搜索poi结果对象

      positionPicker: null, // 拖拽地图对象
      poisList: [], //搜索的pois的值
      dataSource: [] // 搜索的值列表
    }
  },
  mounted() {
    const _this = this
    _this.$nextTick(() => {
      AMapUI.loadUI(['misc/PositionPicker'], PositionPicker => {
        let map = new AMap.Map('map-container', {
          zoom: 15,
          resizeEnable: true,
          features: ['bg', 'point', 'road', 'building']
        })

        _this.positionPicker = new PositionPicker({
          mode: 'dragMap',
          map: map
        })

        AMap.plugin('AMap.CitySearch', function () {
          let citySearch = new AMap.CitySearch()
          citySearch.getLocalCity(function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              // 查询成功，result即为当前所在城市信息
              _this.currentCity = result
            }
          })
        })

        _this.positionPicker.on('success', positionResult => {
          // 过滤掉初始化地图后的第一次默认拖放
          this.positionResult = positionResult
          const { regeocode } = positionResult
          const { pois } = regeocode
          _this.poisList = pois
          _this.poiId = this.poisList[0].id
        })

        _this.positionPicker.on('fail', () => {
          // 海上或海外无法获得地址信息
          _this.$message.error('获取地理位置失败')
        })

        // 启动拖放
        _this.positionPicker.start()

        // 注册地图点击事件
        map.on('click', () => {
          _this.isSearch = false
        })
        // 注册地图移动事件
        map.on('touchstart', () => {
          document.getElementById('map-container').click()
          _this.isSearch = false
        })
        // 注册地图拖拽事件
        map.on('dragstart', () => {
          document.getElementById('map-container').click()
          _this.isSearch = false
        })
      })
    })
  },

  methods: {
    handleOk() {
      this.$emit('confirm', this.positionResult)
      this.$emit('close')
    },

    onAddrSelect(id) {
      this.selectTargetItem = this.dataSource.find(
        item => `crm_${item.id}` === id
      )
      const [lat, log] = this.selectTargetItem.location.split(',')
      const position = new AMap.LngLat(Number(lat), Number(log), false)
      this.positionPicker.start(position)
    },

    addrChange: debounce(function (value) {
      if (value && !value.startsWith('crm_')) {
        getTips({ keywords: value, datatype: 'all' }).then(res => {
          if (res.info === 'OK' || res.status === '1') {
            this.dataSource = res.tips
          } else {
            this.dataSource = []
          }
        })
      } else {
        this.dataSource = []
      }
    }, 500),

    choosePois(id, location) {
      this.positionPicker.start(location)
      this.poiId = id
    }
  }
}
</script>

<style lang="less" scoped>
.map {
  display: block;
  transition: 0.3s;
}

.infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 12px;
  height: calc(100vh - 256px);
  width: 300px;
}

.list-render-item {
  cursor: pointer;
  padding: 12px;
  border-radius: 8px;
  &:hover {
    background-color: #e8e8e8;
  }
  &.ardio-active {
    background-color: #4587f5;
    color: #fff !important;
    & /deep/ .ant-list-item-meta-title {
      color: rgba(255, 255, 255) !important;
    }
    & /deep/ .ant-list-item-meta-description {
      color: rgba(255, 255, 255, 0.85) !important;
    }
  }
}
</style>
