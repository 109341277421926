const getters = {
  token: state => state.user.user.token,
  username: state => state.user.user.username,
  uid: state => state.user.user.uid,
  usermenus: state => state.user.user.menus,
  userCode: state => state.user.user.userCode,
  vnote: state => state.user.user.vnote,
  contactinfo: state => state.user.user.contactinfo,
  depts: state => state.user.user.depts,
  roles: state => state.user.user.roles,
  userPost: state => state.user.user.userPost,
  protal: state => state.user.user.protal,

  resultList: state => state.notice.resultList,
  unReadResult: state => state.notice.unReadResult,
  anuceList: state => state.anuce.anuceList,
  unReadAnuce: state => state.anuce.unReadAnuce,
  socket: state => state.socket.socket,

  docList: state => state.doc.docList
}

export default getters
