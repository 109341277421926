<template>
  <div class="relative">
    <div class="example" v-if="loading">
      <a-spin class="loading-icon" size="large" :tip="tip" />
    </div>

    <replay-notify
      :show="
        showNotification &&
        !$route.query.todo &&
        type !== 'waitwork' &&
        status === '96'
      "
      @replay="replay"
    />

    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      class="button-group py-4"
    >
      <a-col :span="24" class="text-right">
        <a-button
          type="primary"
          class="ml-3 float-left"
          @click="back"
          v-if="!showAvatar"
        >
          返回列表
        </a-button>

        <a-button v-if="!isRead" @click="upload">上传附件</a-button>
        <a-button v-else @click="preview">查看附件</a-button>

        <a-radio-group
          class="ml-4"
          buttonStyle="solid"
          :value="value"
          @change="changeButton"
        >
          <a-radio-button value="a" :disabled="isRead" @click="save">
            保存
          </a-radio-button>
          <a-radio-button value="b" :disabled="isRead" @click="submit">
            提交
          </a-radio-button>
          <a-radio-button
            value="c"
            :disabled="isRead || message.id === ''"
            @click="del"
          >
            删除
          </a-radio-button>
        </a-radio-group>
        <a-button
          type="primary"
          class="ml-4"
          :disabled="
            status === '1' ||
            status === '5' ||
            status === '15' ||
            status === '0' ||
            status === '98' ||
            status === '99' ||
            status === '96'
          "
          @click="getBack"
        >
          撤销
        </a-button>

        <a-button
          type="primary"
          style="margin-left: 1.5em; margin-right: 1.5em"
          @click="tpprint"
        >
          贴票打印
        </a-button>
        <a-button type="primary" style="margin-right: 1.5em" @click="print">
          审批后打印
        </a-button>
      </a-col>
    </a-row>
    <div class="text-right mt-4" v-else>
      <a-button type="primary" class="mr-4" @click="openReim(message.pkSrc)">
        总结单
      </a-button>
      <a-button type="primary" class="mr-4" @click="preview">
        {{ hasFile ? '查看附件' : '未上传附件' }}
      </a-button>
      <a-button type="primary" class="mr-4" @click="tpprint">贴票打印</a-button>
      <a-button type="primary" class="mr-4" @click="print">审批后打印</a-button>
      <a-button
        type="primary"
        class="mr-4"
        @click="openCjkModal"
        v-if="type === 'waitwork' && isBookkeeper && status !== '0'"
      >
        冲支款
      </a-button>
    </div>
    <div class="bill-content">
      <application-info
        :application.sync="application"
        :type="type"
        :status="isRead"
        :conferenceName="message.conferenceName"
        :pkSrc="message.pkSrc"
        :uid="uid"
        :username="username"
        payType="private"
      ></application-info>
      <invoice-info
        :pkSrc="message.pkSrc"
        :invoice.sync="invoice"
        :isFatch="isBillFinish && !isRead"
        :status="isRead"
      ></invoice-info>
      <collection-private-info
        :collection.sync="collection"
        :status="isRead"
      ></collection-private-info>
      <payment-info :payment.sync="payment" :status="isRead"></payment-info>
      <private-cjk :rewriteCjk="rewriteCjk"></private-cjk>
      <receipts-info
        :receipts.sync="receipts"
        :uid="uid"
        :status="status"
        :username="username"
      ></receipts-info>
      <post-script
        billType="DSFK"
        :pid="this.pid"
        :uid="message.billMaker"
        :status="status"
      ></post-script>
      <oa-process
        v-if="message.flowInfoDTO"
        :status="isRead"
        :summaryid="message.flowInfoDTO.summaryid"
        :nextapproveman="message.flowInfoDTO.nextapproveman"
        :curApprover="message.flowInfoDTO.curApprover"
      ></oa-process>
      <comment
        ref="comment"
        :uid="message.billMaker"
        :billId="message.id"
        :isXSBX="true"
        :status="status"
      ></comment>
      <print-wrapper ref="printWrapper" v-show="printVisible">
        <dsfk-print
          :datas="message"
          :name="username"
          :auditInfo="auditInfo"
          title="学术会议对私付款单"
        ></dsfk-print>
      </print-wrapper>
      <print-wrapper ref="tpprintWrapper" v-if="tpprintVisible">
        <qr-code
          :datas="message"
          :name="username"
          title="学术会议对私付款单"
        ></qr-code>
      </print-wrapper>
    </div>
    <cjk-modal
      v-if="showCjkModal"
      :username="username"
      :hpk="message.pkNc"
      :payMentId="message.id"
      @close="showCjkModal = false"
      @cjk="rewrite($event)"
    ></cjk-modal>
    <upload-modal
      v-if="showUpload"
      uploadName="对私付款"
      :pid="message.id"
      :uploadFilelistInfo="uploadFilelistInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>
    <preview-modal
      v-if="showPreview"
      uploadName="对私付款"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </div>
</template>

<script>
import store from '@/store'

import { getUserOne } from '@/api/sysadmin/users'
import { getOACancel } from '@/api/acdemicActive/acdemic-application'
import { isDisabled, payVerifier } from '@/utils/util'
import { getCrmHttp } from '../../modules/util'

import {
  getApplication,
  getInvoice,
  getCollection,
  getPayMsg,
  getReceipts,
  getRewriteCjk
} from '@/views/activity/modules/util.js'

import {
  getAddPayment,
  getDelPayment,
  getPayment,
  getUpdatePayment,
  getSubmitDSFK,
  getPrintInfo,
  getPrivateBankAcc
} from '@/api/acdemicActive/acdemic-payment'

import ApplicationInfo from '@/views/activity/modules/expense/ApplicationInfo'
import InvoiceInfo from '@/views/activity/modules/expense/InvoiceInfo'
import CollectionPrivateInfo from '@/views/activity/modules/expense/CollectionPrivateInfo'
import PaymentInfo from '@/views/activity/modules/expense/PaymentInfo'
import PrivateCjk from '@/views/activity/modules/expense/PrivateCjk'
import ReceiptsInfo from '@/views/activity/modules/expense/ReceiptsInfo'
import OaProcess from '@/views/activity/modules/activebill/OaProcess'
import PostScript from '@/views/activity/modules/activebill/PostScript'
import Comment from '@/views/activity/modules/activebill/Comment'
import DsfkPrint from '@/views/activity/modules/print/DsfkPrint'
import QrCode from '@/views/activity/modules/print/QrCode'
import PrintWrapper from 'vue-easy-print'
import CjkModal from '@/views/activity/academicExpense/private/CjkModal'
import PreviewModal from '@/components/Upload/PayPreviewModal'
import UploadModal from '@/components/Upload/PayUploadModal'
import ReplayNotify from '@/components/ReplayNotify'
export default {
  name: 'AcademicApplicationImprest',
  components: {
    ApplicationInfo,
    InvoiceInfo,
    CollectionPrivateInfo,
    PaymentInfo,
    PrivateCjk,
    ReceiptsInfo,
    OaProcess,
    Comment,
    DsfkPrint,
    PostScript,
    QrCode,
    PrintWrapper,
    CjkModal,
    PreviewModal,
    UploadModal,
    ReplayNotify
  },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      value: 'a',
      showNotification: false,
      loading: false,
      tip: '加载中...',
      isBillFinish: false, // 单据请求是否完成
      showUpload: false, //文件上传的弹窗
      showPreview: false, //文件预览的弹窗
      showCjkModal: false, //冲支款窗口
      uploadFilelistInfo: [], //上传的文件信息
      previewFileListInfo: [], //预览的文件信息
      uid: store.getters['uid'], //单据申请人的uid
      isBookkeeper: false,
      username: '', //单据申请人的名字
      auditInfo: {}, //打印的审批信息
      printVisible: false, //打印单据默认关闭
      tpprintVisible: false, //贴票打印默认关闭
      status: '', //单据状态
      statusMsg: '', //单据的状态信息
      application: {}, //传入申请信息组件的数据
      invoice: {}, // 传入票据信息
      collection: {}, //传入收款信息组件的数据
      payment: {}, //传入付款信息组件的数据
      receipts: {}, // 传入单据信息组件的数据
      rewriteCjk: {},
      message: {
        applyMan: '', //经办人
        applyMoney: '', //申请金额
        billCode: '', //单据号
        ajkye: '', //支款余额
        acjkTotal: '', //冲支款金额
        billDate: '', // 制单日期
        billMaker: '', //制单人
        billType: '', //单据类型
        incompatible: null, // 单位不一致
        checkUnitInfo: [], // 发票不一致的列表
        conferenceName: '', //会议名称
        id: '',
        makeTime: '', //制单时间
        modifer: '', //修改人
        modiftime: '', //修改时间
        note: '', //付款说明
        payId: '', //付款单位id
        payAccNum: '', //付款银行账号
        payBankName: '', //付款银行名称
        payCode: '', //付款单位编码
        payCommbineNum: '', //付款银行行号
        payDate: '', //最后付款日期
        payName: '', //付款单位名称
        payStatus: '', //支付状态
        phone: '', //经办人电话
        pkSrc: '', //来源pk
        pkNc: '', //冲支款的pkNc
        receAccNum: '', //收款银行账号
        receBankName: '', //收款银行名称
        receCode: '', //收款单位编码
        receCommbineNum: '', //收款银行行号
        receName: '', //收款单位名称
        refundSign: '', // 退款标记
        refundDetailNo: '', // 交易明细退款号
        settleType: '', //结算方式
        listBillFiles: [], //附件信息
        settleTypeDTO: {
          name: ''
        },
        flowInfoDTO: {
          status: '1'
        }
      }
    }
  },
  computed: {
    getUid() {
      return this.$store.getters.uid
    },
    isRead() {
      return isDisabled(this.status)
    },
    showAvatar() {
      return this.$store.getters['protal']
    },
    hasFile() {
      if (this.message.listBillFiles && this.message.listBillFiles.length)
        return true
      return false
    }
  },
  created() {
    this.loading = true
    if (this.type === 'check' || this.type === 'waitwork') {
      getPayment(this.pid)
        .then(response => {
          this.getUserOne(response.applyMan)
          this.message = Object.assign(this.message, response)
          this.invoice = getInvoice(this.message)
          this.application = getApplication(response)
          this.collection = getCollection(response)
          this.payment = getPayMsg(response)
          this.receipts = getReceipts(response)
          this.rewriteCjk = getRewriteCjk(response)
          if (!response.flowInfoDTO) {
            this.status = '1'
          } else {
            this.status = response.flowInfoDTO.status
          }

          this.showNotification = this.status === '96'
          this.$emit('status', this.status)
          this.$emit('receipts', this.receipts)

          setTimeout(() => {
            this.loading = false
          }, 500)
        })
        .finally(() => {
          this.isBillFinish = true
        })
    } else {
      setTimeout(() => {
        this.isBillFinish = true
      }, 0)

      this.message.pkSrc = this.$route.query.pkSrc
      this.message.conferenceName = this.$route.query.conferenceName
      this.message.billType = 'DSFK'
      this.status = '1'
      this.application = getApplication(this.message)
      this.invoice = getInvoice(this.message)
      this.collection = getCollection(this.message)
      this.payment = getPayMsg(this.message)
      this.receipts = getReceipts(this.message)
      this.rewriteCjk = getRewriteCjk(this.message)

      this.getUserOne(this.uid)
      // 默认带出收款人信息
      getPrivateBankAcc(1, 1, {
        userCode: this.$store.getters['userCode']
      }).then(res => {
        const privateAcc = res.datas[0]
        if (privateAcc) {
          const {
            userCode,
            accountName,
            receAccNum,
            receBankName,
            receCommbineNum
          } = privateAcc
          this.collection.receCode = userCode
          this.collection.receName = accountName
          this.collection.receAccNum = receAccNum
          this.collection.receBankName = receBankName
          this.collection.receCommbineNum = receCommbineNum
        } else {
          this.$message.warn('收款人信息获取失败')
        }
      })
      this.$emit('status', this.status)

      setTimeout(() => {
        this.loading = false
      }, 300)
    }
  },
  mounted() {
    // 判断该角色是否为会计的角色
    const roles = this.$store.getters['roles']
    for (let item of roles) {
      if (item.code === 'cs_11') {
        this.isBookkeeper = true
        break
      }
    }
  },
  methods: {
    changeButton() {
      this.value = 'a'
    },

    // 上传附件
    upload() {
      this.uploadFilelistInfo = JSON.stringify(this.message.listBillFiles)
      this.showUpload = true
    },

    // 预览附件
    preview() {
      if (!this.hasFile) {
        this.$message.warning('未上传附件信息')
        return
      }

      this.previewFileListInfo = JSON.stringify(this.message.listBillFiles)
      this.showPreview = true
    },

    // 上传后的文件信息
    hasUpload(files) {
      const fileList = JSON.parse(files.fileListInfo)
      if (this.message.id) {
        for (let item of fileList) {
          item.pid = this.message.id
        }
      }
      this.message.listBillFiles = fileList
    },

    getUserOne(uid) {
      getUserOne(uid).then(subResponse => {
        this.username = subResponse.username
        this.$emit('username', this.username)
      })
    },
    //保存
    save() {
      this.assign()
      if (payVerifier(this.message, 'DSFK')) {
        this.loading = true
        this.tip = '正在保存...'
        if (this.message.id) {
          this.message.modifer = store.getters['uid']
          getUpdatePayment(this.message)
            .then(() => {
              this.$message.success('保存成功')
              this.loading = false
              if (this.showAvatar) {
                window.close()
              }
              this.$router.go(-1)
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          getAddPayment(this.message)
            .then(response => {
              this.message.id = response
              this.$message.success('保存成功')
              this.loading = false
              if (this.showAvatar) {
                window.close()
              }
              this.$router.go(-1)
            })
            .catch(() => {
              this.loading = false
            })
        }
      }
    },

    //提交
    submit() {
      this.assign()
      if (payVerifier(this.message, 'DSFK')) {
        this.loading = true
        this.tip = '正在提交...'
        if (this.message.id) this.message.modifer = store.getters['uid']
        getSubmitDSFK(this.message)
          .then(() => {
            this.$message.success('提交成功')
            this.loading = false
            if (this.showAvatar) {
              window.close()
            }
            this.$router.go(-1)
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    // 删除预付款单
    del() {
      this.$confirm({
        title: '是否要删除该对私付款单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getDelPayment(this.pid).then(() => {
            this.$message.success('删除成功')
            if (this.showAvatar) {
              window.close()
            }
            this.$router.go(-1)
          })
        },
        onCancel: () => {}
      })
    },

    //撤销
    getBack() {
      this.$confirm({
        title: '是否要撤销该对私付款单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel(this.message.flowInfoDTO.summaryid).then(() => {
            this.$message.success('撤销成功')
            this.status = '5'
            this.$emit('status', this.status)
          })
        },
        onCancel: () => {}
      })
    },

    //打开冲支款窗口
    openCjkModal() {
      this.showCjkModal = true
    },

    // 回写冲借款信息
    rewrite({ ajkye, acjkTotal }) {
      this.rewriteCjk.ajkye = ajkye
      this.rewriteCjk.acjkTotal = acjkTotal
    },

    // 返回
    back() {
      this.$router.go(-1)
    },

    replay() {
      this.$refs.comment.showLastComment()
      this.showNotification = false
      this.$nextTick(() => {
        this.$store.commit('rightContent/SCROLL_TO_END')
      })
    },

    // 贴票打印
    tpprint() {
      this.tpprintVisible = true
      setTimeout(() => {
        this.$nextTick(() => this.$refs['tpprintWrapper'].print())
      }, 500)
    },

    //打印
    print() {
      const summaryid = this.message.flowInfoDTO
        ? this.message.flowInfoDTO.summaryid
        : ''
      if (summaryid) {
        getPrintInfo(summaryid).then(res => {
          this.auditInfo = res
          this.printVisible = true
          this.$nextTick(() => this.$refs['printWrapper'].print())
        })
      } else {
        this.printVisible = true
        this.$nextTick(() => this.$refs['printWrapper'].print())
      }
    },

    openReim(pk) {
      if (!pk) return this.$message.error('无法获取总结单单')
      const http = getCrmHttp() + '/redirect'
      const token = this.$store.getters['token']
      const uid = this.$store.getters['uid']
      const url = `${http}?token=${token}&uid=${uid}&route=activityReimbursementForm&pid=${pk}&appDate=${this.message.relatedAppDate}&type=check&todo=true`
      const newWindow = window.open('', '_blank')
      newWindow.location.href = url
    },

    // 合并各个模块信息
    assign() {
      Object.assign(
        this.message,
        this.invoice,
        this.application,
        this.payment,
        this.collection,
        this.receipts
      )
    },

    isDisabled
  },

  destroyed() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="less" scoped>
.example {
  text-align: center;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.loading-icon {
  margin-top: 20%;
}
/deep/ .wrapper-style.ant-anchor-wrapper {
  padding-left: 0px;
  margin-left: 0px;
}
/deep/ .wrapper-style .ant-anchor-ink {
  display: none;
}
/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
  &:hover {
    background: #4587f4;
    border-color: #4587f4;
    color: #ffffff;
  }
}

/deep/.ant-btn-primary {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
  &:hover {
    color: #fff;
    background-color: #4587f4;
    border-color: #4587f4;
  }
  &:focus {
    color: #fff;
    background-color: #4587f4;
    border-color: #4587f4;
  }
}
</style>
