import { isArrayBuffer } from 'lodash'
import { decryptAES } from '@/utils/cryption'

const docType = [
  { doc: 'document', type: 'application/octet-stream' },
  { doc: 'excel', type: 'application/vnd.ms-excel' },
  { doc: 'csv', type: 'text/csv' }
]

const jsonDocType = 'application/json'

function isJsonDocType(type) {
  return type === jsonDocType
}

function validateDocType() {
  return docType.map(i => i.type)
}

function findDocType(type) {
  const target = docType.find(i => i.doc === type)
  return target ? target.type : 'application/vnd.ms-excel'
}

function getSuffixOfFile(type) {
  return type === 'csv' ? 'csv' : 'xlsx'
}

/**
 * 将blob或ArrayBuffer读成json
 * @param {} data
 */
function ReadBlobToText(data) {
  return new Promise((resolve, reject) => {
    if (isArrayBuffer(data)) {
      let uint8Msg = new Uint8Array(data)
      // 解码成字符串
      let decoded = String.fromCharCode.apply(null, uint8Msg)
      // 解决中文乱码
      let decodedString = decodeURIComponent(escape(decoded))
      resolve(decodedString)
    } else {
      let content = ''
      const reader = new FileReader()
      //等到完成
      reader.onloadend = function () {
        content = reader.result
        resolve(content)
      }
      //确保处理错误状态
      reader.onerror = function (e) {
        reject(e)
      }
      reader.readAsText(data)
    }
  })
}

export async function validateExcelDoc(streamData, isLoop = false) {
  const { data, headers } = streamData || {}
  const contentType = data.type || headers['content-type'].split(';')[0]

  if (isJsonDocType(contentType)) {
    const text = await ReadBlobToText(data)
    const result = ['production', 'prod'].includes(process.env.VUE_APP_MODE)
      ? JSON.parse(decryptAES(text))
      : JSON.parse(text)

    return isLoop
      ? Promise.resolve({ ...result })
      : Promise.reject({ type: 'TypeJson', data: result })
  }

  if (!validateDocType().includes(contentType)) {
    return Promise.reject({ type: 'TypeError' })
  }

  return Promise.resolve(data)
}

export function downLoadFile(blobData, type, name) {
  const link = document.createElement('a')
  let blob = new Blob([blobData], { type: findDocType(type) })
  link.style.display = 'none'
  link.href = URL.createObjectURL(blob)
  link.setAttribute('download', name + `.${getSuffixOfFile(type)}`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
