<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="活动信息" key="1" class="customStyle">
          <div
            class="w-full flex flex-wrap justify-start"
            style="margin-left: 24px"
          >
            <div class="form-item">
              <span class="form-label">会议名称</span>
              <a-input
                :title="activityInfo.conferenceName"
                v-model="activityInfo.conferenceName"
                class="form-input"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">会议编号</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityInfo.conferenceCode"
                v-model="activityInfo.conferenceCode"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                所属公司
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="conferenceTypeName"
                :value="conferenceTypeName"
              />
            </div>
            <div class="form-item" v-if="conferenceTypeName === '总部'">
              <span class="form-label">
                举办类型
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityInfo.hostingType"
                :disabled="isRead"
                @change="activityHostingType"
              >
                <a-select-option :key="item.id" v-for="item in hostingList">
                  {{ item.name !== null ? item.name : '' }}
                </a-select-option>
              </a-select>
            </div>
            <div class="form-item">
              <span class="form-label">
                业务类型
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityInfo.businessType"
                :disabled="isRead"
                @change="businessTypeChange"
              >
                <a-select-option
                  :key="item.id"
                  v-for="item in businessTypeList"
                >
                  {{ item.name || '' }}
                </a-select-option>
              </a-select>
            </div>
            <div
              class="form-item"
              v-if="checkBusinessTypeName(activityInfo.businessTypeDTO)"
            >
              <span class="form-label">
                产品
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityInfo.product"
                :disabled="isRead"
                @change="productChange"
              >
                <a-select-option :key="item.id" v-for="item in productList">
                  {{ item.name || '' }}
                </a-select-option>
              </a-select>
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                活动地点
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :title="activityInfo.activityPlace"
                v-model="activityInfo.activityPlace"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                活动主题
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :title="activityInfo.activityTheme"
                v-model="activityInfo.activityTheme"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                活动目的
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :title="activityInfo.activityPurpose"
                v-model="activityInfo.activityPurpose"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                举办性质
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityInfo.activityNatrue"
                :disabled="isRead || conferenceTypeName === '地区'"
                @change="activityNatrueChange"
              >
                <a-select-option value="协办">协办</a-select-option>
                <a-select-option value="自办">自办</a-select-option>
                <a-select-option value="协办+自办">协办+自办</a-select-option>
              </a-select>
            </div>
            <div class="form-item">
              <span class="form-label">
                开始日期
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-date-picker
                v-if="activityInfo.activityStdate"
                class="form-input"
                :allowClear="false"
                :value="moment(activityInfo.activityStdate)"
                :disabled="isRead"
                :disabledDate="disabledStDate"
                @change="activityStdateChange"
              />
              <a-date-picker
                v-else
                class="form-input"
                :allowClear="false"
                :disabled="isRead"
                :disabledDate="disabledStDate"
                @change="activityStdateChange"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                结束日期
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-date-picker
                v-if="activityInfo.activityEddate"
                class="form-input"
                :allowClear="false"
                :value="moment(activityInfo.activityEddate)"
                :disabled="isRead"
                :disabledDate="disabledEndDate"
                @change="activityEddateChange"
              />
              <a-date-picker
                v-else
                class="form-input"
                :allowClear="false"
                :disabled="isRead"
                :disabledDate="disabledEndDate"
                @change="activityEddateChange"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                讲者(人)
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                v-model="activityInfo.speakerNum"
                class="form-input"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                预邀客户(人)
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                v-model="activityInfo.preinvitednum"
                class="form-input"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                内部人员(人)
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                v-model="activityInfo.internalnum"
                class="form-input"
                oninput="value=value.replace(/[^\d]/g,'')"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                会务合同
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityInfo.hasconfContract"
                :disabled="isRead"
                @change="hasconfContractChange"
              >
                <a-select-option value="是">是</a-select-option>
                <a-select-option value="否">否</a-select-option>
              </a-select>
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                其他合同
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityInfo.otherContract"
                :disabled="isRead"
                @change="otherContractChange"
              >
                <a-select-option value="是">是</a-select-option>
                <a-select-option value="否">否</a-select-option>
              </a-select>
            </div>
            <div
              class="form-item"
              v-if="
                [1, 2, 4].includes(getHostingType) &&
                activityInfo.hasconfContract === '是'
              "
            >
              <span class="form-label">
                会务公司名称
                <span style="color: #4587f4">(会务合同:是)</span>
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :title="activityInfo.confCompany"
                v-model="activityInfo.confCompany"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="activityInfo.hasconfContract === '是'">
              <span class="form-label">
                会务合同金额
                <span style="color: #4587f4">(会务合同:是)</span>
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="activityInfo.contractMoney"
                :disabled="isRead"
                oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                @change="updateItem"
              />
            </div>
            <div
              class="form-item"
              v-if="[1, 2, 4].includes(getHostingType) && hasAssiocate"
            >
              <span class="form-label">
                主办方名称
                <span style="color: #4587f4">(举办性质：协办)</span>
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :title="activityInfo.sponsor"
                v-model="activityInfo.sponsor"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div
              class="form-item"
              v-if="[1, 2, 4].includes(getHostingType) && hasAssiocate"
            >
              <span class="form-label">
                成立日期
                <span style="color: #4587f4">(举办性质：协办)</span>
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-date-picker
                v-if="activityInfo.sponsorEstdate"
                class="form-input"
                :allowClear="false"
                :value="moment(activityInfo.sponsorEstdate)"
                :disabled="isRead"
                @change="sponsorEstdateChange"
              />
              <a-date-picker
                v-else
                class="form-input"
                :allowClear="false"
                :disabled="isRead"
                @change="sponsorEstdateChange"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                公对公预付金额
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="activityInfo.budgetPreCost"
                :disabled="isRead || (!isRead && hasPreCost)"
                oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[1, 2, 4].includes(getHostingType)">
              <span class="form-label">
                推广物料
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                class="form-input"
                :value="activityInfo.isbrandpre"
                :disabled="isRead"
                @change="isbrandpreChange"
              >
                <a-select-option value="是">是</a-select-option>
                <a-select-option value="否">否</a-select-option>
              </a-select>
            </div>
            <div class="form-item" v-if="[3, 4].includes(getHostingType)">
              <span class="form-label">
                品牌信息-发布主题
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :title="activityInfo.pubTheme"
                v-model="activityInfo.pubTheme"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
            <div class="form-item" v-if="[3, 4].includes(getHostingType)">
              <span class="form-label">
                品牌信息-发布目的
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                v-model="activityInfo.pubPurpose"
                :title="activityInfo.pubPurpose"
                :disabled="isRead"
                @change="updateItem"
              />
            </div>
          </div>

          <div
            class="mt-4 w-full flex flex-wrap justify-start"
            style="margin-left: 24px"
          >
            <div
              class="form-item form-upload"
              v-if="[1, 2, 4].includes(getHostingType)"
            >
              <span class="form-label">
                预邀参会人员名单
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                :
              </span>
              <span class="text-center ml-4">
                <span v-if="!isRead">
                  <icon-font
                    :type="
                      activityInfo.participantsList
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      uploadFile(
                        'participantsList',
                        activityInfo.participantsList
                      )
                    "
                  ></icon-font>
                </span>
                <span v-else-if="activityInfo.participantsList">
                  <icon-font
                    :type="
                      activityInfo.participantsList
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      preview(activityInfo.participantsList, 'participantsList')
                    "
                  />
                </span>
                <span v-else class="text-blue-400">无上传的附件</span>
              </span>
            </div>
            <div
              class="form-item form-upload"
              v-if="[1, 2, 4].includes(getHostingType)"
            >
              <span class="form-label">
                自办活动议程和邀请函
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                :
              </span>
              <span class="text-center ml-4">
                <span v-if="!isRead">
                  <icon-font
                    :type="
                      activityInfo.activityInvitation
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      uploadFile(
                        'activityInvitation',
                        activityInfo.activityInvitation
                      )
                    "
                  ></icon-font>
                </span>
                <span v-else-if="activityInfo.activityInvitation">
                  <icon-font
                    :type="
                      activityInfo.activityInvitation
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      preview(
                        activityInfo.activityInvitation,
                        'activityInvitation'
                      )
                    "
                  />
                </span>
                <span v-else class="text-blue-400">无上传的附件</span>
              </span>
            </div>
            <div
              class="form-item form-upload"
              v-if="[3, 4].includes(getHostingType)"
            >
              <span class="form-label">
                品牌信息-发布方案
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                :
              </span>
              <span class="text-center ml-4">
                <span v-if="!isRead">
                  <icon-font
                    :type="
                      activityInfo.pubScheme
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="uploadFile('pubScheme', activityInfo.pubScheme)"
                  ></icon-font>
                </span>
                <span v-else-if="activityInfo.pubScheme">
                  <icon-font
                    :type="
                      activityInfo.pubScheme
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="preview(activityInfo.pubScheme, 'pubScheme')"
                  />
                </span>
                <span v-else class="text-blue-400">无上传的附件</span>
              </span>
            </div>
            <div
              class="form-item form-upload"
              v-if="[3, 4].includes(getHostingType)"
            >
              <span class="form-label">
                品牌信息-发布合同
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                :
              </span>
              <span class="text-center ml-4">
                <span v-if="!isRead">
                  <icon-font
                    :type="
                      activityInfo.partnerContract
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      uploadFile(
                        'partnerContract',
                        activityInfo.partnerContract
                      )
                    "
                  ></icon-font>
                </span>
                <span v-else-if="activityInfo.partnerContract">
                  <icon-font
                    :type="
                      activityInfo.partnerContract
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      preview(activityInfo.partnerContract, 'partnerContract')
                    "
                  />
                </span>
                <span v-else class="text-blue-400">无上传的附件</span>
              </span>
            </div>
            <div
              class="form-item form-upload"
              v-if="
                [1, 2, 4].includes(getHostingType) &&
                (activityInfo.hasconfContract === '是' ||
                  activityInfo.otherContract === '是')
              "
            >
              <span class="form-label">
                已签批合同扫描件
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                :
              </span>
              <span class="text-center ml-4">
                <span v-if="!isRead">
                  <icon-font
                    :type="
                      activityInfo.contractScan
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      uploadFile('contractScan', activityInfo.contractScan)
                    "
                  ></icon-font>
                </span>
                <span v-else-if="activityInfo.contractScan">
                  <icon-font
                    :type="
                      activityInfo.contractScan
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="preview(activityInfo.contractScan, 'contractScan')"
                  />
                </span>
                <span v-else class="text-blue-400">无上传的附件</span>
              </span>
            </div>
            <div
              class="form-item form-upload"
              v-if="[1, 2, 4].includes(getHostingType) && hasAssiocate"
            >
              <span class="form-label">
                主办方资质证明
                <span style="color: #4587f4">(举办性质：协办)</span>
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                :
              </span>
              <span class="text-center ml-4">
                <span v-if="!isRead">
                  <icon-font
                    :type="
                      activityInfo.sponsorQualification
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      uploadFile(
                        'sponsorQualification',
                        activityInfo.sponsorQualification
                      )
                    "
                  ></icon-font>
                </span>
                <span v-else-if="activityInfo.sponsorQualification">
                  <icon-font
                    :type="
                      activityInfo.sponsorQualification
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      preview(
                        activityInfo.sponsorQualification,
                        'sponsorQualification'
                      )
                    "
                  />
                </span>
                <span v-else class="text-blue-400">无上传的附件</span>
              </span>
            </div>
            <div
              class="form-item form-upload"
              v-if="[1, 2, 4].includes(getHostingType) && hasAssiocate"
            >
              <span class="form-label">
                主办方邀请函
                <span style="color: #4587f4">(举办性质：协办)</span>
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                :
              </span>
              <span class="text-center ml-4">
                <span v-if="!isRead">
                  <icon-font
                    :type="
                      activityInfo.sponsorInvitation
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      uploadFile(
                        'sponsorInvitation',
                        activityInfo.sponsorInvitation
                      )
                    "
                  ></icon-font>
                </span>
                <span v-else-if="activityInfo.sponsorInvitation">
                  <icon-font
                    :type="
                      activityInfo.sponsorInvitation
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="
                      preview(
                        activityInfo.sponsorInvitation,
                        'sponsorInvitation'
                      )
                    "
                  />
                </span>
                <span v-else class="text-blue-400">无上传的附件</span>
              </span>
            </div>
            <div
              class="form-item form-upload"
              v-if="[1, 2, 4].includes(getHostingType) && hasAssiocate"
            >
              <span class="form-label">
                已签批协办协议/合同
                <span style="color: #4587f4">(举办性质：协办)</span>
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                :
              </span>
              <span class="text-center ml-4">
                <span v-if="!isRead">
                  <icon-font
                    :type="
                      activityInfo.contract
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="uploadFile('contract', activityInfo.contract)"
                  ></icon-font>
                </span>
                <span v-else-if="activityInfo.contract">
                  <icon-font
                    :type="
                      activityInfo.contract
                        ? 'icon_wenjianjia2'
                        : 'icon_wenjianjia1'
                    "
                    class="text-2xl"
                    @click="preview(activityInfo.contract, 'contract')"
                  />
                </span>
                <span v-else class="text-blue-400">无上传的附件</span>
              </span>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadName"
      :uploadFilelistInfo="uploadFilelistInfo"
      @close="showUploadClose"
      @hasUpload="hasUpload($event)"
    ></upload-modal>
    <preview-modal
      v-if="showPreview"
      :uploadName="uploadName"
      :previewFileListInfo="previewFileListInfo"
      @close="closePreview"
    ></preview-modal>
  </div>
</template>

<script>
import moment from 'moment'
import PreviewModal from '@/components/Upload/PreviewModal'
import UploadModal from '@/components/Upload/UploadModal'
import { getComBusiRelationList } from '@/api/acdemicActive/acdemic-application'
import { loadDictTypes } from '@/api/customer/dictmanage'
import { checkBusinessTypeName } from '@/utils/util'
export default {
  name: 'ActivityInfo', //活动信息
  components: {
    PreviewModal,
    UploadModal
  },
  props: {
    activityInfo: {
      type: Object,
      required: true
    },
    appdate: { type: String, default: '' },
    conferenceTypeName: String,
    deptCode: String,
    deptdesc: [Number, String],
    deptNature: String,
    status: Boolean
  },
  data() {
    return {
      hostingTypeName: '', // 举办的类型名称
      docList: [], //所有的枚举类型列表
      meetTypeList: [], //所属公司列表
      businessTypeList: [], //业务类型列表
      productList: [], //产品列表
      hostingList: [], // 举办类型列表
      comBusiRelationList: [], //所属公司与业务类型的关系列表
      showUpload: false, //文件上传的弹框默认关闭
      showPreview: false, //预览文件的弹框默认关闭
      uploadName: '', //传入上传附件modal的字段名
      uploadFilelistInfo: '', //传入上传附件的文件信息
      previewFileListInfo: '' //传入预览附件的文件信息
    }
  },
  computed: {
    isRead() {
      return this.status
    },
    hasAssiocate() {
      if (!this.activityInfo.activityNatrue) return false
      return this.activityInfo.activityNatrue.indexOf('协办') !== -1
    },
    hasPreCost() {
      if ([3, 4].includes(this.getHostingType)) return false

      if (
        !(
          this.activityInfo.activityNatrue === '自办' &&
          this.activityInfo.hasconfContract === '否' &&
          this.activityInfo.otherContract === '否'
        )
      ) {
        return false
      } else {
        return this.notPreCost()
      }
    },

    getHostingType() {
      const hostingType = this.activityInfo.hostingTypeDTO
      if (!hostingType) return 1

      let active = false
      let purtive = false
      if (hostingType.name.indexOf('学术推广活动') !== -1) active = true
      if (hostingType.name.indexOf('品牌信息发布') !== -1) purtive = true

      if (active && !purtive) return 2 //举办类型为学术推广
      if (!active && purtive) return 3 //举办类型为品牌信息
      if (active && purtive) return 4 //举办类型为学术推广+品牌信息

      return 1 //举办类型为空
    }
  },
  async created() {
    await loadDictTypes().then(response => {
      this.queryDocByName(
        true,
        response,
        '标准类型(活动类型及执行标准)',
        '产品',
        '举办类型'
      )
    })
    await getComBusiRelationList(1, 50).then(res => {
      const { datas } = res
      this.comBusiRelationList = datas
      if (this.activityInfo.conferenceType) {
        this.getComBusiRelation(this.activityInfo.conferenceType)
      }
    })
    if (this.activityInfo.conferenceType) {
      this.$emit(
        'chooseConferenceTypeName',
        this.queryConferenceType(this.activityInfo.conferenceType)
      )
    }
  },
  watch: {
    deptCode(nval, oval) {
      if (!this.isRead && oval) {
        this.activityInfo.conferenceType = ''
        this.activityInfo.businessType = ''
        this.activityInfo.product = ''
        this.activityInfo.conferenceTypeDTO = null
        this.activityInfo.businessTypeDTO = null
        this.activityInfo.productDTO = null
      }

      this.queryDocByName(
        false,
        this.docList,
        '标准类型(活动类型及执行标准)',
        '产品',
        '举办类型'
      )
    },
    conferenceTypeName(nval) {
      if (nval === '地区') {
        this.activityInfo.activityNatrue = '自办'
      }
    }
  },
  methods: {
    // 预付金额为0
    notPreCost() {
      if (!this.isRead) {
        this.activityInfo.budgetPreCost = 0
      }
      this.updateItem()
      return true
    },

    // 举办性质改变回调
    activityNatrueChange(value) {
      this.activityInfo.activityNatrue = value
      this.updateItem()
    },

    // 开始日期函数回调
    activityStdateChange(date, dateString) {
      this.activityInfo.activityStdate = dateString
      this.updateItem()
    },

    // 结束日期函数回调
    activityEddateChange(date, dateString) {
      this.activityInfo.activityEddate = dateString
      this.updateItem()
    },

    //是否有会务合同改变回调
    hasconfContractChange(value) {
      this.activityInfo.hasconfContract = value
      if (value === '否' && this.activityInfo.otherContract === '否') {
        this.activityInfo.contractMoney = 0
      }
      this.updateItem()
    },

    // 其他合同改变回调
    otherContractChange(value) {
      this.activityInfo.otherContract = value
      if (value === '否' && this.activityInfo.hasconfContract === '否') {
        this.activityInfo.contractMoney = 0
      }
      this.updateItem()
    },

    // 成立日期改变回调函数
    sponsorEstdateChange(date, dateString) {
      this.activityInfo.sponsorEstdate = dateString
      this.updateItem()
    },

    //推广物料改变回调
    isbrandpreChange(value) {
      this.activityInfo.isbrandpre = value
      this.updateItem()
    },

    /**
     * @param {String} str //传入的字段名称
     * @param {String} fileInfo //传入的文件信息
     */
    uploadFile(str, filesInfo) {
      this.showUpload = true
      this.uploadName = str
      this.uploadFilelistInfo = filesInfo
    },

    // 传递预览文件信息
    preview(previewFileListInfo, str) {
      this.previewFileListInfo = previewFileListInfo
      this.uploadName = str
      this.showPreview = true
    },

    //文件信息添加到对应的字段
    hasUpload(file) {
      if (file.uploadName === 'participantsList') {
        this.activityInfo.participantsList = file.fileListInfo
      }
      if (file.uploadName === 'activityInvitation')
        this.activityInfo.activityInvitation = file.fileListInfo
      if (file.uploadName === 'contractScan')
        this.activityInfo.contractScan = file.fileListInfo
      if (file.uploadName === 'sponsorQualification')
        this.activityInfo.sponsorQualification = file.fileListInfo
      if (file.uploadName === 'sponsorInvitation')
        this.activityInfo.sponsorInvitation = file.fileListInfo
      if (file.uploadName === 'contract')
        this.activityInfo.contract = file.fileListInfo
      if (file.uploadName === 'pubScheme')
        this.activityInfo.pubScheme = file.fileListInfo
      if (file.uploadName === 'partnerContract')
        this.activityInfo.partnerContract = file.fileListInfo
      this.updateItem()
    },

    //关闭文件上传
    showUploadClose() {
      this.showUpload = false
    },

    // 关闭预览附件的窗口
    closePreview() {
      this.showPreview = false
    },

    //更改信息
    updateItem() {
      this.$emit('updateItem')
      this.submit()
    },

    //返回activityInfo数据
    submit() {
      this.$emit('update:activityInfo', this.activityInfo)
    },

    // 所属公司改变回调
    // conferenceChange(value) {
    //   this.activityInfo.conferenceType = value
    //   const target = this.meetTypeList.find(item => item.id === value)
    //   if (target) {
    //     this.activityInfo.conferenceTypeDTO = target
    //     this.activityInfo.businessType = ''
    //     this.getComBusiRelation(value)
    //   }
    //   this.$emit('chooseConferenceTypeName', this.queryConferenceType(value))
    //   this.updateItem()
    // },

    // 举办类型改变回调
    activityHostingType(value) {
      const target = this.hostingList.find(item => item.id === value)
      this.activityInfo.hostingType = value
      this.activityInfo.hostingTypeDTO = target
      this.hostingTypeName = target.name
      this.$emit('chooseHostingTypeName', this.hostingTypeName)
      this.updateItem()
    },

    // 产品改变回调
    productChange(value) {
      this.activityInfo.product = value
      const target = this.productList.find(item => item.id === value)
      if (target) {
        this.activityInfo.productDTO = target
      }
      this.updateItem()
    },

    // 查询所属公司
    queryConferenceType(value) {
      let meetName = ''
      const item = this.meetTypeList.find(item => item.id === value)
      if (item !== undefined) {
        meetName = item.name
        return meetName
      }
      return meetName
    },

    // 业务类型改变回调
    businessTypeChange(value) {
      this.activityInfo.businessType = value
      const target = this.businessTypeList.find(item => item.id === value)
      if (target) {
        this.activityInfo.businessTypeDTO = target
      }
      this.updateItem()
    },

    /**
     * 查询所属公司类型、业务类型枚举
     * @param {Boolean} isCreated // 是否是初始化
     * @param {Array} docList //枚举列表
     * @param {String} meetType //枚举名称（所属公司）
     * @param {String} businessType //枚举名称（业务类型）
     * @param {String} hostingType //枚举类型(举办类型)
     */
    queryDocByName(isCreated, docList, meetType, product, hostingType) {
      this.docList = docList
      docList.forEach(item => {
        if (item.name === product) this.productList = item.defDocDTOs
        if (item.name === hostingType) this.hostingList = item.defDocDTOs
        if (item.name === meetType) {
          this.meetTypeList = item.defDocDTOs

          if (this.isRead) return

          let target
          if (this.deptNature !== '总部') {
            const level = Number(this.deptdesc)
            switch (level) {
              case 1:
                target = this.meetTypeList.find(item => item.name === '大区')
                break
              case 2:
                target = this.meetTypeList.find(item => item.name === '省区')
                break
              default:
                target = this.meetTypeList.find(item => item.name === '地区')
            }
          } else {
            target = this.meetTypeList.find(item => item.name === '总部')
          }

          this.activityInfo.conferenceType = target.id
          this.activityInfo.conferenceTypeDTO = target

          if (!isCreated) {
            this.getComBusiRelation(target.id)
          }

          this.$emit('chooseConferenceTypeName', target.name)
        }
      })
    },

    /**
     * 根据所属公司查询业务类型
     * @param {id} 所属公司的id
     * (338338159bab461793d60c421a88f7a2)停用的儿科消化精神线的枚举id
     */
    getComBusiRelation(id) {
      this.businessTypeList = []
      for (let item of this.comBusiRelationList) {
        const { conferenceType, businessType, businessTypeDTO } = item
        if (
          id === conferenceType &&
          businessType !== '338338159bab461793d60c421a88f7a2'
        )
          this.businessTypeList.push(businessTypeDTO)
      }
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledStDate(time) {
      return (
        time && time >= moment(this.activityInfo.activityEddate).endOf('day')
      )
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledEndDate(time) {
      return (
        time &&
        time <=
          moment(this.activityInfo.activityStdate).add(-1, 'day').endOf('day')
      )
    },

    moment,
    checkBusinessTypeName
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 25%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
}

.form-upload {
  display: flex;
  flex-direction: row;
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
