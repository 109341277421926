<template>
  <div class="relative">
    <div class="example" v-if="loading">
      <a-spin class="loading-icon" size="large" :tip="tip" />
    </div>

    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      class="button-group py-4"
    >
      <a-col :span="24" class="text-right">
        <a-button
          type="primary"
          class="ml-3 float-left"
          @click="$router.go(-1)"
          v-if="!showAvatar"
        >
          返回列表
        </a-button>

        <!-- <a-button v-if="!isRead" @click="upload">上传附件</a-button> -->
        <!-- <a-button v-else @click="preview">查看附件</a-button> -->

        <a-radio-group
          class="ml-4"
          :value="value"
          buttonStyle="solid"
          @change="changeButton"
        >
          <a-radio-button value="a" :disabled="isRead" @click="save">
            保存
          </a-radio-button>
          <a-radio-button value="b" :disabled="isRead" @click="submit">
            提交
          </a-radio-button>
          <a-radio-button
            value="c"
            :disabled="isRead || message.id === ''"
            @click="del"
          >
            删除
          </a-radio-button>
        </a-radio-group>
        <a-button
          type="primary"
          class="ml-4"
          :disabled="
            status === '1' ||
            status === '5' ||
            status === '15' ||
            status === '0' ||
            status === '98' ||
            status === '99'
          "
          @click="getBack"
        >
          撤销
        </a-button>
      </a-col>
    </a-row>
    <div class="bill-content">
      <bill-msg
        :billMsg.sync="billMsg"
        :status="isRead"
        :state="status"
      ></bill-msg>
      <application-msg
        :application.sync="application"
        :status="isRead"
      ></application-msg>
      <house-case
        :houseCase="houseCase"
        :showAct="showAct"
        :status="isRead"
        :isManageWrite="isCopyMsgFromApp"
        @calcActualCost="calcActualCost"
      ></house-case>
      <cater-case
        :caterCase="caterCase"
        :showAct="showAct"
        :status="isRead"
        :isManageWrite="isCopyMsgFromApp"
        @calcActualCost="calcActualCost"
      ></cater-case>
      <conference-case
        :conferenceCase="conferenceCase"
        :showAct="showAct"
        :status="isRead"
        :isManageWrite="isCopyMsgFromApp"
        @calcActualCost="calcActualCost"
      ></conference-case>
      <golf-case
        :golfCase="golfCase"
        :showAct="showAct"
        :status="isRead"
        :isManageWrite="isCopyMsgFromApp"
        @calcActualCost="calcActualCost"
      ></golf-case>
      <recept-case
        :receptCase.sync="receptCase"
        :showAct="showAct"
        :status="isRead"
        :isManageWrite="isCopyMsgFromApp"
        @calcActualCost="calcActualCost"
      ></recept-case>
      <remark :remarkMsg.sync="remarkMsg" :status="isRead"></remark>
      <post-script
        billType="YFKD"
        :pid="this.pid"
        :uid="message.billMaker"
        :status="status"
      ></post-script>
      <oa-process
        v-if="message.flowInfoDTO"
        :status="isRead"
        :summaryid="message.flowInfoDTO.summaryid"
        :nextapproveman="message.flowInfoDTO.nextapproveman"
        :curApprover="message.flowInfoDTO.curApprover"
      ></oa-process>
      <comment :uid="message.applicant" :billId="message.id"></comment>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import Vue from 'vue'
import { getUserOne } from '@/api/sysadmin/users'
import { getOACancel } from '@/api/acdemicActive/acdemic-application'
import { isDisabled, randomKey } from '@/utils/util'
import { receptValidate } from '@/views/activity/modules/recept-validate'
import { default as cloneDeep } from 'lodash/cloneDeep'
// import { getCrmHttp } from '../../modules/util'

import {
  getBillMsgByJDSQ,
  getApplicationByJDSQ,
  getHouseCaseByJDSQ,
  getCaterCaseByJDSQ,
  getConferenceCaseByJDSQ,
  getReceptCaseByJDSQ,
  getRemarkByJDSQ,
  getGolfeCaseByJDSQ
} from '@/views/activity/modules/util.js'
import BillMsg from '@/views/activity/modules/recept/BillMsg'
import ApplicationMsg from '@/views/activity/modules/recept/ApplicationMsg'
import HouseCase from '@/views/activity/modules/recept/HouseCase'
import CaterCase from '@/views/activity/modules/recept/CaterCase'
import ConferenceCase from '@/views/activity/modules/recept/ConferenceCase'
import GolfCase from '@/views/activity/modules/recept/GolfCase'
import ReceptCase from '@/views/activity/modules/recept/ReceptCase'
import Remark from '@/views/activity/modules/recept/Remark'
import PostScript from '@/views/activity/modules/activebill/PostScript'
import OaProcess from '@/views/activity/modules/activebill/OaProcess'
import Comment from '@/views/activity/modules/activebill/Comment'

import {
  addReceptBill,
  delReceptBill,
  getReceptBill,
  updateReceptBill,
  submitAppRec
  // getPrintInfo
} from '@/api/acdemicActive/activity-recept'

export default {
  name: 'AcademicApplicationImprest',
  components: {
    BillMsg,
    ApplicationMsg,
    HouseCase,
    CaterCase,
    ConferenceCase,
    ReceptCase,
    GolfCase,
    Remark,
    PostScript,
    OaProcess,
    Comment
  },
  props: {
    type: String,
    pid: String
  },
  data() {
    return {
      value: 'a',
      loading: false,
      tip: '正在加载...',
      uid: store.getters['uid'],
      billMsg: {}, // 传入单据信息组件的数据
      application: {}, //传入申请信息组件的数据
      houseCase: {}, // 传入住房情况的数据
      caterCase: {}, // 传入餐饮情况的数据
      conferenceCase: {}, // 传入会务情况的数据
      golfCase: {}, //传入高尔夫情况的数据
      receptCase: {}, // 传入接待情况的数据
      remarkMsg: {}, // 传入备注的数据
      status: '', //单据状态
      hotelManager: false,
      message: {
        id: '',
        writeOrNot: false,
        billno: '', // 单据编码
        applicant: '', // 申请人
        applicantName: '', // 申请人名称
        vnote: '', // 岗位
        appDate: '', // 申请日期
        dept: '', // 部门
        deptCode: '', //部门编码
        deptName: '', // 部门名称
        contact: '', // 联系方式
        actualCost: '', // 实际花销金额合计

        confName: '', // 关联会议名称
        arriveTime: '', // 到店时间
        leaveTime: '', // 离店时间
        payType: '', // 付款方式
        corp: '', // 所属公司
        peopleNum: '', // 团队人数
        appRemarks: '', // 备注

        appRoomsInfos: [], // 申请用房信息
        actRoomsInfos: [], // 实际用房信息
        roomsBudget: '', // 预算用房金额合计
        actRoomsCostTotal: '', // 实际用房金额合计

        appMealsInfos: [], // 申请餐饮信息
        actMealsInfos: [], // 实际餐饮信息
        actMealsTotal: '', // 实际用餐金额合计

        appConfsInfos: [], //申请会务信息
        actConfsInfos: [], //实际会务信息
        actConferenceTotal: '', //申请会务信息

        appGolfInfos: [], // 申请高尔夫信息
        actGolfInfos: [], // 实际高尔夫信息
        golfBudgetTotal: '', //预算高尔夫金额
        actGolfTotal: '', //实际高尔夫金额

        transPrice: '', // 接送单价
        transNum: '', // 接送次数
        settlementExpenses: '', // 接送费用
        otherCost: '', // 其他费用
        transTotal: '', // 费用合计

        transRemarks: '', // 备注
        flowInfoDTO: {
          status: '1'
        }
      }
    }
  },
  computed: {
    isRead() {
      return isDisabled(this.status)
    },
    showAvatar() {
      return this.$store.getters['protal']
    },

    // 是否展示实际信息
    showAct() {
      return this.hotelManager || this.message.writeOrNot
    },

    isCopyMsgFromApp() {
      return this.hotelManager && !this.message.writeOrNot
    }
  },
  async created() {
    this.loading = true
    if (this.type === 'check' || this.type === 'waitwork') {
      getReceptBill(this.pid).then(response => {
        // 获取申请人名称
        if (response.applicantName) {
          this.$emit('username', response.applicantName)
        } else {
          this.getUserOne(response.applicant)
        }
        Object.assign(this.message, response)

        if (this.isCopyMsgFromApp) this.copyMsgFromApp()

        this.initModuleInfo(this.message)
        this.status = this.getStatus(this.message.flowInfoDTO)
        this.$emit('status', this.status)
        this.closeLoading(500)
      })
    } else {
      const userInfo = await this.getUserOne(this.$store.getters.uid)
      this.message.applicant = this.$store.getters.uid
      this.message.applicantName = userInfo.username
      this.message.contact = userInfo.contactinfo
      this.message.vnote = userInfo.vnote
      this.message.appDate = this.$date().format('YYYY-MM-DD')

      this.message.pkSrc = this.$route.query.pkSrc
      this.message.confName = this.$route.query.conferenceName

      this.initModuleInfo(this.message)
      this.status = this.getStatus(this.message.flowInfoDTO)
      this.$emit('status', this.status)
      this.closeLoading(500)
    }
  },

  mounted() {
    const roles = this.$store.getters['roles']
    for (let item of roles) {
      // 判断是否为饭店管理人员
      if (item.code === '013') {
        this.hotelManager = true
      }
    }
  },

  methods: {
    changeButton() {
      this.value = 'a'
    },

    closeLoading(time) {
      setTimeout(() => {
        this.loading = false
      }, time)
    },

    // 分解各个模块信息
    initModuleInfo(data) {
      this.billMsg = getBillMsgByJDSQ(data)
      this.application = getApplicationByJDSQ(data)
      this.houseCase = getHouseCaseByJDSQ(data)
      this.caterCase = getCaterCaseByJDSQ(data)
      this.conferenceCase = getConferenceCaseByJDSQ(data)
      this.golfCase = getGolfeCaseByJDSQ(data)
      this.receptCase = getReceptCaseByJDSQ(data)
      this.remarkMsg = getRemarkByJDSQ(data)
    },

    copyMsgFromApp() {
      this.houseCopyMsg()
      this.caterCopyMsg()
      this.conferenceCopyMsg()
      this.golfCopyMsg()
    },

    // 住房申请的copy规则
    houseCopyMsg() {
      if (!this.message.appRoomsInfos) return
      this.message.actRoomsInfos = cloneDeep(this.message.appRoomsInfos)
      this.message.actRoomsCostTotal = this.message.roomsBudget
      this.message.actualCost = this.$np.plus(
        this.message.actualCost || 0,
        this.message.actRoomsCostTotal || 0
      )
    },
    // 餐饮情况的copy规则
    caterCopyMsg() {
      if (!this.message.appMealsInfos) return

      const infos = cloneDeep(this.message.appMealsInfos)
      this.message.actMealsInfos = infos.map(item => {
        return Vue.observable({
          actDate: item.appDate,
          actPeopleNum: item.appPeopleNum,
          actTime: item.appTime,
          actMealType: item.appMealType,
          actMealStandard: item.appMealStandard,
          id: `add-${randomKey(20)}`,
          pid: item.pid,
          price: '',
          tableNum: ''
        })
      })
    },
    // 会务情况的copy规则
    conferenceCopyMsg() {
      if (!this.message.appConfsInfos) return

      const infos = cloneDeep(this.message.appConfsInfos)
      this.message.actConfsInfos = infos.map(item => {
        return Vue.observable({
          actDate: item.appDate,
          actPlace: item.appPlace,
          actTime: item.appTime,
          teaBreak: item.teaBreak,
          teaBreakStandard: item.teaBreakStandard,
          id: `add-${randomKey(20)}`,
          pid: item.pid,
          price: ''
        })
      })

      this.message.actGolfTotal = this.message.golfBudgetTotal
      this.message.actualCost = this.$np.plus(
        this.message.actualCost || 0,
        this.message.actGolfTotal || 0
      )
    },

    // 高尔夫情况的copy规则
    golfCopyMsg() {
      if (!this.message.appGolfInfos) return

      const infos = cloneDeep(this.message.appGolfInfos)
      this.message.actGolfInfos = infos.map(item => {
        return Vue.observable({
          dateTime: item.dateTime,
          golfType: item.golfType,
          golfTime: item.golfTime,
          id: `add-${randomKey(20)}`,
          pid: item.pid,
          pepleNum: item.pepleNum,
          fee: item.fee
        })
      })
    },

    getStatus(flowInfoDTO) {
      return flowInfoDTO ? flowInfoDTO.status : '1'
    },

    async getUserOne(uid) {
      const res = await getUserOne(uid)
      this.$emit('username', res.username)
      return res
    },

    calcActualCost() {
      this.billMsg.actualCost = this.$np.plus(
        this.houseCase.actRoomsCostTotal || 0,
        this.caterCase.actMealsTotal || 0,
        this.conferenceCase.actConferenceTotal || 0,
        this.receptCase.transTotal || 0,
        this.golfCase.actGolfTotal || 0
      )
    },

    back() {
      this.$router.go(-1)
    },

    //保存
    save() {
      this.loading = true
      this.tip = '正在保存...'

      const data = this.getSubmitData()

      if (data.id) {
        updateReceptBill(data)
          .then(() => {
            this.$message.success('保存成功')
            this.loading = false
            if (this.showAvatar) {
              window.close()
            }
            this.$router.go(-1)
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        addReceptBill(data)
          .then(response => {
            data.id = response
            this.$message.success('保存成功')
            this.loading = false
            if (this.showAvatar) {
              window.close()
            }
            this.$router.go(-1)
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    //提交
    submit() {
      const data = this.getSubmitData()

      if (receptValidate(data)) {
        this.loading = true
        this.tip = '正在提交...'

        submitAppRec(data)
          .then(() => {
            this.$message.success('提交成功')
            this.loading = false
            if (this.showAvatar) {
              window.close()
            }
            this.$router.go(-1)
          })
          .catch(() => {
            this.loading = false
          })
      }
    },

    // 删除接待申请单
    del() {
      this.$confirm({
        title: '是否要删除该接待申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delReceptBill(this.pid).then(() => {
            this.$message.success('删除成功')
            if (this.showAvatar) {
              window.close()
            }
            this.$router.go(-1)
          })
        },
        onCancel: () => {}
      })
    },

    //撤销
    getBack() {
      this.$confirm({
        title: '是否要撤销该对接款申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel(this.message.flowInfoDTO.summaryid).then(() => {
            this.$message.success('撤销成功')
            this.status = '5'
            // 撤销设为饭店人员的实际未写入
            this.message.writeOrNot = false
            this.message.actRoomsInfos = []
            this.message.actMealsInfos = []
            this.message.actConfsInfos = []
            this.message.actGolfInfos = []
            this.houseCase.actRoomsCostTotal = ''
            this.caterCase.actMealsTotal = ''
            this.conferenceCase.actConferenceTotal = ''
            this.golfCase.actGolfTotal = ''

            // 接送情况
            this.receptCase.transPrice = ''
            this.receptCase.transNum = ''
            this.receptCase.settlementExpenses = ''
            this.receptCase.otherCost = ''
            this.receptCase.transTotal = ''
            this.calcActualCost()
            this.isDisabled(this.status)
          })
        },
        onCancel: () => {}
      })
    },

    // 合并各个模块信息（将住房情况、餐饮情况和会务情况新增的id清空）
    assign() {
      Object.assign(
        this.message,
        this.billMsg,
        this.application,
        this.houseCase,
        this.caterCase,
        this.golfCase,
        this.conferenceCase,
        this.receptCase,
        this.remarkMsg
      )
    },

    // 住房情况、餐饮情况、会务情况和高尔夫情况新增的id清空
    clearAddKey(data) {
      const clearProps = [
        'appRoomsInfos',
        'actRoomsInfos',
        'appMealsInfos',
        'actMealsInfos',
        'appConfsInfos',
        'actConfsInfos',
        'appGolfInfos',
        'actGolfInfos'
      ]

      for (let prop of clearProps) {
        if (!data[prop]) continue

        for (let item of data[prop]) {
          if (item.id.startsWith('add-')) {
            item.id = ''
          }
        }
      }
    },

    // 获取提交的数据
    getSubmitData() {
      this.assign()
      const data = cloneDeep(this.message)
      this.clearAddKey(data)

      return data
    },

    isDisabled
  }
}
</script>

<style lang="less" scoped>
.example {
  text-align: center;
  background: #ffffff;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.loading-icon {
  margin-top: 15%;
}
/deep/ .wrapper-style.ant-anchor-wrapper {
  padding-left: 0px;
  margin-left: 0px;
}
/deep/ .wrapper-style .ant-anchor-ink {
  display: none;
}
</style>
