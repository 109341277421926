<template>
  <div class="select-cell">
    <div v-if="editable" class="select-cell-input-wrapper">
      <!-- <a-select
        showSearch
        placeholder="讲者姓名"
        optionFilterProp="children"
        style="width: 180px"
        @change="handleChange"
        :filterOption="filterOption"
      >
        <a-select-option
          v-for="(item, index) in speakerList"
          :key="`${index}`"
          :value="item.id"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-icon type="check" class="select-cell-icon-check" @click="check" /> -->
      <choose-speaker-modal
        :speakers="speakers"
        @close="closeActiveSpeakerModal"
        @chooseSpeaker="chooseSpeaker($event)"
      ></choose-speaker-modal>
    </div>
    <div v-else class="select-cell-text-wrapper">
      <div class="inline-block">
        {{ code || ' ' }}
      </div>
      <a-icon
        type="zoom-in"
        v-if="!isRead"
        class="select-cell-icon"
        @click="select"
      />
    </div>
  </div>
</template>
<script>
import ChooseSpeakerModal from '@/views/activity/academicApplication/ChooseSpeakerModal'
export default {
  name: 'SpeakerSelect',
  components: {
    ChooseSpeakerModal
  },
  props: {
    code: String,
    status: Boolean,
    speakers: Array
  },
  data() {
    return {
      speakerId: this.id,
      editable: false
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  methods: {
    select() {
      this.editable = true
    },

    // 关闭选择讲者的弹框
    closeActiveSpeakerModal() {
      this.editable = false
    },

    chooseSpeaker(speaker) {
      this.$emit('change', speaker)
    }
  }
}
</script>

<style lang="less">
.select-cell {
  position: relative;
  height: 2rem;
}

.select-cell-input-wrapper,
.select-cell-text-wrapper {
  padding-right: 24px;
  height: 2rem;
}

.select-cell-text-wrapper {
  padding: 5px 24px 5px 24px;
  & .text-hidden {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.select-cell-icon,
.select-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.select-cell-icon {
  line-height: 18px;
  font-size: 16px;
  margin-right: 2px;
  display: none;
}

.select-cell-icon-check {
  line-height: 28px;
}

.select-cell .select-cell-icon {
  display: inline-block;
}

.select-cell-icon:hover,
.select-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
