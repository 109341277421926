import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/************* 目标医生接口 *********************/
/**
 * 分页获取PC端查询目标医生的列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {String} version
 * @param {Array} conditions
 */
export function getTargetDoctorList(pageindex, pagenum, version, conditions) {
  return request({
    method: 'post',
    url: `targetDoctor/pc/${pageindex}/${pagenum}`,
    data: [{ column: 'version', opt: '=', value: version }, ...conditions]
  })
}

/**
 * 获取目标医生版本信息
 */
export function getTargetDoctorVersion() {
  return request({
    method: 'get',
    url: `targetDoctor/pc/versions`
  }).then(res => {
    return res
  })
}

/**
 * 根据目标医生的id查看详情
 * @param {String} id
 */
export function getTargetDoctorById(id) {
  return request({
    method: 'get',
    url: `targetDoctor/pc/${id}`
  })
}

/**
 * 查询审批详情
 * @param {String} id
 */
export function getTDHistoryById(id) {
  return request({
    method: 'get',
    url: `targetDoctor/app/history/${id}`
  })
}

/**
 * 查询申请解绑的详情
 * @param {String} id
 */
export function getTDHistoryUnbindById(id) {
  return request({
    method: 'post',
    url: `targetDoctor/app/unbind/${id}`
  })
}

/**
 * 导出目标医生数据
 * @param {String} version
 */
export function getExportExcel(version) {
  return request({
    method: 'post',
    url: `targetDoctor/exportExcel/`,
    data: [{ column: 'version', opt: '=', value: version }],
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `${version}版目标医生信息`)
  })
}

/************* 目标医生分级设置接口 *********************/

/**
 * 分页获取PC端查询目标医生分级设置的列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getGradeSettingList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `gradeSetting/pc/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 保存目标医生分级设置信息
 * @param {Object} data
 */
export function getSaveGradeSetting(data) {
  return request({
    method: 'post',
    url: `gradeSetting`,
    data: data
  })
}

/**
 * 修改目标医生分级设置信息
 * @param {Object} data
 */
export function getUpdateGradeSetting(data) {
  return request({
    method: 'put',
    url: `gradeSetting`,
    data: data
  })
}

/**
 * 根据id查询目标医生分级设置详情
 * @param {String} id
 */
export function getGradeSettingById(id) {
  return request({
    method: 'get',
    url: `gradeSetting/${id}`
  })
}

/**
 * 删除医生分级设置信息
 * @param {String} id
 */
export function getDelGradeSetting(id) {
  return request({
    method: 'delete',
    url: `gradeSetting/${id}`
  })
}

/**
 * 获取已选择的产品线
 */
export function getProductLine() {
  return request({
    method: 'get',
    url: `gradeSetting/pc/productLine`
  })
}
