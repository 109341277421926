export const BILL_MSG = [
  { status: '1', color: 'pink', icon: 'icon_shenpizhongnew', text: '待发' },
  { status: '0', color: 'green', icon: 'icon_tongguo', text: '审批通过' },
  { status: '3', color: 'blue', icon: 'icon_daishenpi', text: '待处理' },
  { status: '4', color: 'orange', icon: 'icon_shenpizhong', text: '处理中' },
  { status: '5', color: 'pink', icon: 'icon_shenpizhongnew', text: '撤销' },
  { status: '15', color: 'red', icon: 'icon_butongguo', text: '审批终止' },
  // { status: '6', color: 'orange1', icon: 'icon_tuihui1', text: '回退' },
  { status: '97', color: 'blue', icon: 'icon_daishenpi', text: '暂存待办' },
  { status: '98', color: 'orange1', icon: 'icon_tuihui1', text: '回退' },
  { status: '96', color: 'red2', icon: 'icon_daishenpi', text: '挂起' },
  { status: '99', color: 'red', icon: 'icon_zuofei1', text: '作废' }
]

export const attitudeEnum = {
  AGREE: 1, // 同意
  SUBMIT: 6, // 提交
  RECAPTION: 7, // 取回
  TEMPORARY: 97, // 暂存待办
  TRUN_OVER: 78, // 移交
  HAND_UP: 96, // 挂起
  REPLY: 95, // 回复
  INFORM: 20, // 通知
  NOTIFY: 21, // 返回上一级
  CANCEL: -77, // 取消暂存
  BACK: 90 // 退回基地调度
}

export function getBillMsg(status) {
  // 兼容有些申请单status为空的情况
  if (!status) return BILL_MSG[0]

  const bill = BILL_MSG.find(item => item.status === status)
  if (bill) return bill

  return
}

export function getStatusText(status) {
  if (!status) return '待发'
  const billStatus = BILL_MSG.find(item => item.status === status)
  if (!billStatus) {
    return '未知单据状态'
  }
  return billStatus.text
}

export function getStatusColor(status) {
  if (!status) return 'pink'
  const billStatus = BILL_MSG.find(item => item.status === status)
  if (!billStatus) {
    return 'black'
  }
  return billStatus.color
}
