import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/**
 * 药连锁自有房产登记申请单列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 */
export function getRegisterHouseList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `houseRegister/getHouseRegisterList/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 更新药连锁自有房产登记申请单
 * @param {Object} data
 * @returns
 */
export function updateRegisterHouse(data) {
  return request({
    method: 'post',
    url: `houseRegister/updateHouseRegister`,
    data
  })
}

/**
 * 保存药连锁自有房产登记申请单
 * @param {Object} data
 * @returns
 */
export function saveRegisterHouse(data) {
  return request({
    method: 'post',
    url: `houseRegister/saveHouseRegister`,
    data
  })
}

/**
 * 提交药连锁自有房产登记申请单
 * @param {Object} data
 * @returns
 */
export function submitRegisterHouse(data) {
  return request({
    method: 'post',
    url: `oaflow/submitHouseRegister`,
    data
  })
}

/**
 * 保存药连锁自有房产登记申请单
 * @param {String} id
 * @returns
 */
export function delRegisterHouse(id) {
  return request({
    method: 'post',
    url: `houseRegister/delHouseRegister/${id}`
  })
}

/**
 * 获取药连锁自有房产登记申请单
 * @param {String} id
 * @returns
 */
export function getRegisterHouse(id) {
  return request({
    method: 'get',
    url: `houseRegister/${id}`
  })
}

/**
 * 导出药连锁自有房产登记申请单
 * @param {Array} data 导出的条件
 */
export function getExportExcel(data = []) {
  return request({
    url: `/houseRegister/exportData`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'csv', `药连锁自有房产登记申请单`)
  })
}
