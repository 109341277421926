import { default as pullAllBy } from 'lodash/pullAllBy'
import { default as cloneDeep } from 'lodash/cloneDeep'

export default {
  namespaced: true,
  state: {
    unReadAnuce: 0,
    anuceList: []
  },
  mutations: {
    SET_ANUCE_LIST(state, value) {
      if (state.anuceList.length) {
        state.anuceList = [...state.anuceList, ...value]
        return
      }
      state.anuceList = value
    },

    ADD_ANUCE_ITEM(state, item) {
      state.anuceList = [...[item], ...state.anuceList]
    },

    SET_UNREADANUCE(state, value) {
      state.unReadAnuce = value
    },

    // 移除公告列表的一项
    REMOVE_ANUCE_ITEM(state, key) {
      pullAllBy(state.anuceList, [key], 'id')
    },

    // 清除公告列表
    CLEAR_ANUCE_LIST(state) {
      state.anuceList = []
    }
  },

  actions: {
    removeResultItem({ commit, state }, item) {
      commit('REMOVE_ANUCE_ITEM', item)
      const list = cloneDeep(state.anuceList)
      if (list.length) {
        commit('CLEAR_ANUCE_LIST')
        commit('SET_ANUCE_LIST', list)
      } else {
        commit('SET_ANUCE_LIST', [])
      }
    }
  }
}
