import { render, staticRenderFns } from "./AcademicImprestTable.vue?vue&type=template&id=45e1a540&scoped=true"
import script from "./AcademicImprestTable.vue?vue&type=script&lang=js"
export * from "./AcademicImprestTable.vue?vue&type=script&lang=js"
import style0 from "./AcademicImprestTable.vue?vue&type=style&index=0&id=45e1a540&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45e1a540",
  null
  
)

export default component.exports