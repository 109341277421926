export const APP_BILL = [
  {
    title: '申请人信息',
    icon: 'user',
    children: [
      { label: '申请人', value: `applicantDTO`, subValue: 'username' },
      { label: '岗位', value: `post` },
      { label: '联系方式', value: `contact` },
      { label: '申请日期', value: `appdate` },
      { label: '大区/部门', value: `regionDTO`, subValue: 'deptname' },
      { label: '省办', value: `provinceDTO`, subValue: 'deptname' },
      { label: '地办', value: `localityDTO`, subValue: 'deptname' },
      { label: '费用承担部门', value: `departmentDTO`, subValue: 'deptname' },
      { label: '所属级别', value: `conferenceTypeDTO`, subValue: 'name' }
    ]
  },
  {
    title: '活动信息',
    icon: 'gift',
    children: [
      { label: '会议名称', value: `conferenceName` },
      { label: '会议编号', value: `conferenceCode` },
      { label: '业务类型', value: `businessTypeDTO`, subValue: 'name' },
      { label: '产品', value: `productDTO`, subValue: 'name' },
      { label: '举办形式', value: `activityFormDto`, subValue: 'name' },
      { label: '举办性质', value: `activityNatrue` },
      { label: '活动主题', value: `activityTheme` },
      { label: '活动地点', value: `activityPlace` },
      { label: '开始日期', value: `activityStdate` },
      { label: '结束日期', value: `activityEddate` },
      { label: '预邀客户', value: `preinvitednum` },
      { label: '讲者人数', value: `speakerNum` },
      { label: '陪同人员人数', value: `accompPersonNum` },
      { label: '内部人员', value: `internalnum` },
      {
        label: '是否为走进东阳光项目',
        value: `whetherDygProj`,
        show: function () {
          return this.isShowDygProj
        }
      },
      {
        label: '主办方名称',
        value: `sponsor`,
        show: function () {
          return this.isShowCo
        }
      },
      {
        label: '主办方成立日期',
        value: `sponsorEstdate`,
        show: function () {
          return this.isShowCo
        }
      },
      {
        label: '协办合同金额',
        value: `cooperateContractMny`,
        type: 'number',
        show: function () {
          return this.isShowCo
        }
      },
      {
        label: '线上直播平台服务报价金额',
        value: `onlineLiveBroadcastingFee`,
        show: function () {
          return this.isShowOnline
        }
      },
      { label: '是否需要会务公司', value: `hasconfContract` },
      {
        label: '会议公司名称',
        value: `confCompany`,
        show: function () {
          return this.isShowContract
        }
      },
      {
        label: '会务合同金额',
        value: `contractMoney`,
        type: 'number',
        show: function () {
          return this.isShowContract
        }
      },
      { label: '是否为总部统一筹划项目', value: `whetherHeadQuartersProj` },
      {
        label: '总部项目名称',
        value: `headQuartersProjectName`,
        show: function () {
          return this.isShowWHQProj
        }
      },
      { label: '公对公预付金额', value: `budgetPreCost`, type: 'number' },
      {
        label: '预邀客户名单',
        value: `participantsList`,
        type: 'file'
      },
      {
        label: '邀请函/会议议程',
        value: `activityInvitation`,
        type: 'file'
      },
      {
        label: '主办方资质证明',
        value: `sponsorQualification`,
        type: 'file',
        show: function () {
          return this.isShowCo
        }
      },
      {
        label: '主办方邀请函/招商函',
        value: `sponsorInvitation`,
        type: 'file',
        show: function () {
          return this.isShowCo
        }
      },
      {
        label: '协办合同',
        value: `contract`,
        type: 'file',
        show: function () {
          return this.isShowCo
        }
      },
      {
        label: '线上直播平台服务协议/报价单',
        value: `onlineStreamingPlatformAgreement`,
        type: 'file',
        show: function () {
          return this.isShowOnline
        }
      },
      {
        label: '会务合同',
        value: `contractScan`,
        type: 'file',
        show: function () {
          return this.isShowContract
        }
      },

      { label: '活动背景描述', value: `activityBackGround`, col: 24 },
      { label: '活动目的', value: `activityPurpose`, col: 24 },
      { label: '活动内容概述', value: `activityOverview`, col: 24 },
      { label: '活动预期效果', value: `activityExpectEffect`, col: 24 }
    ]
  },
  {
    title: '活动类型及执行标准',
    icon: 'schedule',
    children: [
      {
        label: '活动类型',
        value: `activityTypeStdDTO`,
        subValue: 'acttype',
        col: 2,
        length: 48
      },
      {
        label: '参会客户人数（不含讲者）',
        value: `activityTypeStdDTO`,
        subValue: 'customernum',
        col: 2,
        length: 48
      },
      {
        label: '内部人员数',
        value: `activityTypeStdDTO`,
        subValue: 'insidersNum',
        col: 2,
        length: 48
      },
      {
        label: '讲者人数',
        value: `activityTypeStdDTO`,
        subValue: 'speakernum',
        col: 2,
        length: 48
      },
      {
        label: '讲者劳务费(元/人)',
        value: `activityTypeStdDTO`,
        subValue: 'speakerConsultationFee',
        col: 2,
        length: 48
      },
      {
        label: '讲者讲课时间 (分钟/人)',
        value: `activityTypeStdDTO`,
        subValue: 'lectureTime',
        col: 2,
        length: 48
      },
      {
        label: '交通费(元)',
        value: `activityTypeStdDTO`,
        subValue: 'trafficExpense',
        col: 2,
        length: 48
      },
      {
        label: '接送费(元)',
        value: `activityTypeStdDTO`,
        subValue: 'transportExpense',
        col: 2,
        length: 48
      },
      {
        label: '场地费(元/天)',
        value: `activityTypeStdDTO`,
        subValue: 'venuefee',
        col: 2,
        length: 48
      },
      {
        label: '住宿费(元/人/天)',
        value: `activityTypeStdDTO`,
        subValue: 'hotelfee',
        col: 2,
        length: 48
      },
      {
        label: '餐费(元/人/天)',
        value: `activityTypeStdDTO`,
        subValue: 'mealsfee',
        col: 2,
        length: 48
      },
      {
        label: '会议时长(天)',
        value: `activityTypeStdDTO`,
        subValue: 'meetingLength',
        col: 2,
        length: 48
      },
      {
        label: '备注',
        value: `activityTypeStdDTO`,
        subValue: 'notes',
        col: 2,
        length: 48
      }
    ]
  }
]

export const APP_TABLE = {
  accompany: [
    {
      title: '陪同人员信息',
      icon: 'team',
      dataList: 'appAccompanyingPersonsDtos',
      columns: [
        {
          title: '序号',
          dataIndex: 'number',
          width: 72,
          scopedSlots: { customRender: 'number' }
        },
        { title: '陪同人员工号', dataIndex: 'personCode' },
        { title: '人员姓名', dataIndex: 'personName' },
        { title: '所属大区', dataIndex: 'areaName' },
        { title: '所属省区', dataIndex: 'provName' },
        { title: '所属岗位', dataIndex: 'postName' },
        {
          title: '陪同预算',
          dataIndex: 'accompanyBudget',
          scopedSlots: { customRender: 'budget' }
        }
      ]
    }
  ],
  speaker: [
    {
      title: '讲者信息',
      icon: 'usergroup-add',
      dataList: 'speakers',
      columns: [
        {
          title: '序号',
          dataIndex: 'number',
          width: 72,
          scopedSlots: { customRender: 'number' }
        },
        { title: '讲者编号', dataIndex: 'code' },
        { title: '讲者名称', dataIndex: 'name' },
        { title: '讲者单位', dataIndex: 'departmentName' },
        { title: '讲者级别', dataIndex: 'speakerLevelName' },
        { title: '讲者劳务费', dataIndex: 'appConsultingFee' },
        { title: '讲者课题', dataIndex: 'topicname' },
        {
          title: '讲课时长(分钟)',
          dataIndex: 'lengthoflecture',
          scopedSlots: { customRender: 'lengthoflecture' }
        }
      ]
    }
  ]
}

export const getAppFee = () => {
  const columns = [
    {
      title: '项目',
      dataIndex: 'projectName',
      width: 200
    },
    {
      title: '单价',
      align: 'center',
      dataIndex: 'price',
      scopedSlots: { customRender: 'price' }
    },
    {
      title: '数量',
      align: 'center',
      dataIndex: 'num',
      scopedSlots: { customRender: 'num' }
    },
    {
      title: '总价',
      align: 'center',
      dataIndex: 'total',
      scopedSlots: { customRender: 'total' }
    },
    {
      title: '附件上传',
      dataIndex: 'upload',
      scopedSlots: { customRender: 'file' },
      width: 200
    }
  ]

  const dataList = [
    {
      id: 'traffic',
      projectName: '交通费',
      price: 'trafficExpense',
      num: 'trafficExpenseNum',
      priceProp: 'trafficExpense',
      numProp: 'trafficExpenseNum',
      total: ''
    },
    {
      id: 'budgetReception',
      projectName: '接送费',
      price: 'budgetReception',
      num: 'budgetReceptionNum',
      priceProp: 'budgetReception',
      numProp: 'budgetReceptionNum',
      total: ''
    },
    {
      id: 'accommodation',
      projectName: '住宿费',
      price: 'budgetAccommodation',
      num: 'budgetAccommodationNum',
      priceProp: 'budgetAccommodation',
      numProp: 'budgetAccommodationNum',
      total: ''
    },
    {
      id: 'meals',
      projectName: '餐费',
      price: 'budgetMeals',
      num: 'budgetMealsNum',
      priceProp: 'budgetMeals',
      numProp: 'budgetMealsNum',
      total: ''
    },
    {
      id: 'meetingMaterial',
      projectName: '会务物料制作费',
      price: 'meetingMaterialExpense',
      num: 'meetingMaterialExpenseNum',
      priceProp: 'meetingMaterialExpense',
      numProp: 'meetingMaterialExpenseNum',
      total: '',
      fileProp: 'meetingMaterialExpenseDocs'
    },
    {
      id: 'exhibition',
      projectName: '展台布展',
      price: 'exhibitionExpense',
      num: 'exhibitionExpenseNum',
      priceProp: 'exhibitionExpense',
      numProp: 'exhibitionExpenseNum',
      total: ''
    },
    {
      id: 'thirdparty',
      projectName: '第三方会务人员费用',
      price: 'budgetThirdparty',
      num: 'budgetThirdpartyNum',
      priceProp: 'budgetThirdparty',
      numProp: 'budgetThirdpartyNum',
      total: ''
    },
    {
      id: 'venue',
      projectName: '场地费',
      price: 'venueExpense',
      num: 'venueExpenseNum',
      priceProp: 'venueExpense',
      numProp: 'venueExpenseNum',
      total: ''
    },
    {
      id: 'registerFee',
      projectName: '注册费',
      price: '--',
      num: '--',
      total: 'registerFee',
      totalProp: 'registerFee'
    },
    {
      id: 'cooperation',
      projectName: '第三方协办费用',
      price: '--',
      num: '--',
      total: 'budgetCooperationTotal',
      totalProp: 'budgetCooperationTotal'
    },
    {
      id: 'onlineLiveBroadcastingFee',
      projectName: '线上平台直播费用',
      price: '--',
      num: '--',
      total: 'onlineLiveBroadcastingFee',
      totalProp: 'onlineLiveBroadcastingFee'
    },
    {
      id: 'consult',
      projectName: '讲者劳务费',
      price: '--',
      num: '--',
      total: 'budgetConsultTotal',
      totalProp: 'budgetConsultTotal'
    },
    {
      id: 'accompany',
      projectName: '陪同费用',
      price: '--',
      num: '--',
      total: 'accompanyExpense',
      totalProp: 'accompanyExpense'
    },
    {
      id: 'service',
      projectName: '会务服务费、税费',
      price: '--',
      num: '--',
      total: 'budgetServiceTotal',
      totalProp: 'budgetServiceTotal'
    }
  ]

  return { columns, dataList }
}
