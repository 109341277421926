<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          header="活动类型及执行标准"
          key="1"
          class="customStyle"
        >
          <div
            class="w-full flex flex-wrap justify-start"
            style="margin-left: 24px"
          >
            <div class="form-item">
              <span class="form-label">
                活动类型
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                class="form-input"
                :disabled="isRead"
                :title="activityTypeInfo.acttype"
                v-model="activityTypeInfo.acttype"
                @click="checkIsConferenceTypeName"
              />
            </div>
            <div class="form-item">
              <span class="form-label">参会客户(人)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.customernum"
                v-model="activityTypeInfo.customernum"
              />
            </div>
            <div class="form-item">
              <span class="form-label">讲者讲课时间(分钟/人)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.lectureTime"
                v-model="activityTypeInfo.lectureTime"
              />
            </div>
            <div class="form-item">
              <span class="form-label">人员人数(比例)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.speakernum"
                v-model="activityTypeInfo.speakernum"
              />
            </div>
            <div class="form-item">
              <span class="form-label">场地费(元)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.venuefee"
                v-model="activityTypeInfo.venuefee"
              />
            </div>
            <div class="form-item">
              <span class="form-label">住宿费(元/人/天)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.hotelfee"
                v-model="activityTypeInfo.hotelfee"
              />
            </div>
            <div class="form-item">
              <span class="form-label">餐费(元/人/天)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.mealsfee"
                v-model="activityTypeInfo.mealsfee"
              />
            </div>
            <div class="form-item">
              <span class="form-label">讲者咨询费(元/人/天)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.speakerConsultationFee"
                v-model="activityTypeInfo.speakerConsultationFee"
              />
            </div>
            <div class="form-item">
              <span class="form-label">飞机费(元)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.trafficExpenseAirfare"
                v-model="activityTypeInfo.trafficExpenseAirfare"
              />
            </div>
            <div class="form-item">
              <span class="form-label">高铁费(元)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.trafficExpenseTrain"
                v-model="activityTypeInfo.trafficExpenseTrain"
              />
            </div>
            <div class="form-item">
              <span class="form-label">推广物料标准费(元/人)</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.brandstd"
                v-model="activityTypeInfo.brandstd"
              />
            </div>
            <div class="form-item">
              <span class="form-label">备注</span>
              <a-input
                class="form-input"
                :disabled="true"
                :title="activityTypeInfo.notes"
                v-model="activityTypeInfo.notes"
              />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <choose-active-modal
      v-if="showActiveModel"
      @close="closeActiveModel"
      @activeType="activeType($event)"
      :conferenceTypeName="conferenceTypeName"
      :businessType="businessType"
    ></choose-active-modal>
  </div>
</template>

<script>
import ChooseActiveModal from '@/views/activity/academicApplication/ChooseActiveModal'
export default {
  name: 'ActivityType', //活动类型及执行标准
  components: {
    ChooseActiveModal
  },
  props: {
    activityType: {
      type: Object,
      required: true
    },
    businessType: {
      type: String,
      default: '',
      required: true
    },
    conferenceTypeName: String,
    status: Boolean
  },
  data() {
    return {
      showActiveModel: false, //活动类型弹窗默认关闭
      activeTypeList: [], //活动类型及执行标准列表
      activityTypeInfo: {
        acttype: '',
        speakernum: '',
        customernum: '',
        lectureTime: '',
        venuefee: '',
        hotelfee: '',
        mealsfee: '',
        speakerConsultationFee: '',
        trafficExpenseAirfare: '',
        trafficExpenseTrain: '',
        brandstd: '',
        notes: ''
      }
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  watch: {
    'activityType.activityTypeStdDTO': {
      handler(nval) {
        if (nval !== null) {
          this.activityTypeInfo = this.activityType.activityTypeStdDTO
        }
      },
      deep: true
    }
  },
  methods: {
    //检查是否选择了所属公司
    checkIsConferenceTypeName() {
      if (!this.conferenceTypeName) this.$message.error('请选择所属公司')
      if (!this.businessType) this.$message.error('请选择业务类型')
      else this.showActiveModel = true
    },

    // 关闭活动类型model
    closeActiveModel() {
      this.showActiveModel = false
    },

    // 在model选择活动类型返回的数据
    activeType(value) {
      this.activityType.activitytype = value.actID
      this.activityType.activityTypeStdDTO = value
      this.$emit('updateItem')
      this.$emit('update:activityType', this.activityType)
    }
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 25%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
