export default {
  namespaced: true,
  state: {
    size: 'min',
    length: 10
  },
  mutations: {
    SET_SIZE(state, value) {
      state.size = value
    },
    SET_LENGTH(state, value) {
      state.length = value
    }
  },
  actions: {
    setSizeLen({ commit }, screenMsg) {
      commit('SET_SIZE', screenMsg.size)
      commit('SET_LENGTH', screenMsg.length)
    }
  }
}
