<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="备注" key="1" class="customStyle">
          <div
            class="w-full flex flex-wrap justify-start"
            style="padding-left: 24px"
          >
            <div
              class="w-full rounded-lg p-2"
              style="border: 1px solid rgba(0, 0, 0, 0.25); height: 120px"
            >
              {{ note }}
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Note', //备注
  props: {
    remarks: {
      type: String,
      default: ''
    }
  },
  watch: {
    remarks(nval) {
      if (nval) {
        setTimeout(() => {
          this.note = nval
        }, 1000)
      }
    }
  },
  created() {
    if (this.remarks) {
      setTimeout(() => {
        this.note = this.remarks
      }, 1000)
    }
  },
  data() {
    return {
      note: ''
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
