<template>
  <a-form class="pl-4" labelAlign="left">
    <div class="border-b py-4 text-sm font-semibold">
      <a-icon type="schedule" />
      <span class="ml-2">项目成果</span>
    </div>

    <a-row class="p-4">
      <a-col :xl="6" :xxl="4" v-for="item in achievementForm" :key="item.prop">
        <a-form-item
          :label="item.label"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :required="!item.disabled"
        >
          <a-input
            v-model="achievement[item.prop]"
            :disabled="isFlow || item.disabled"
          ></a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="px-4">
      <template v-for="item in achievementFileForm">
        <a-col
          v-if="!item.show || item.show()"
          :xl="6"
          :xxl="4"
          :key="item.prop"
        >
          <a-form-item v-if="!item.show || item.show()">
            <div class="flex items-center">
              <span class="relative">
                <span :class="[item.notVerity ? '' : 'app-form-required']">
                  {{ item.label }} :
                </span>
                <span
                  class="absolute text-white cursor-pointer"
                  style="right: -30px"
                  @click="openFile(item)"
                >
                  {{
                    achievement[item.prop]
                      ? JSON.parse(achievement[item.prop]).length
                      : '0'
                  }}
                </span>
              </span>
              <icon-svg
                icon="icon_wenjianjia3"
                class="text-4xl ml-2 cursor-pointer"
                @click="openFile(item)"
              />
            </div>
          </a-form-item>
        </a-col>
      </template>
    </a-row>

    <!-- 流程信息 -->
    <process
      :status="isFlow"
      :summaryid="achievement.thirdFlowInfoDTO.summaryid"
      :nextapproveman="achievement.thirdFlowInfoDTO.nextapproveman"
      :curApprover="achievement.thirdFlowInfoDTO.curApprover"
    />
    <!-- 审批意见 -->
    <comment
      :uid="form.applicant"
      :billId="achievement.outcomePartId"
    ></comment>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </a-form>
</template>

<script>
// api
import { oninput } from '@/utils/validate'
import { isDisabled } from '@/utils/util'
// components
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
import isEmpty from 'lodash/isEmpty'

export default {
  name: 'ThirdPart',
  components: { UploadModal, PreviewModal, Process, Comment },
  props: {
    type: {
      type: String,
      default: ''
    },

    pid: {
      type: String,
      default: ''
    },

    form: {
      type: Object,
      default: () => ({})
    },

    labelCol: Object,
    wrapperCol: Object
  },
  data() {
    return {
      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: '',

      oninput,
      achievement: {
        budgetMeals: '', // 项目实际费用
        uploadStampedContract: '', // 最终盖章合同
        projectOutcome: '', // 项目成果
        outcomePartId: '', // 流程id
        thirdFlowInfoDTO: {} // 流程信息
      },
      achievementForm: [
        {
          label: '项目实际费用',
          prop: 'budgetMeals',
          disabled: false,
          validate: 'number'
        }
      ],
      achievementFileForm: [
        {
          label: '最终盖章合同',
          prop: 'uploadStampedContract',
          disabled: false,
          show: () => this.isShowUploadStampedContract
        },
        { label: '项目成果', prop: 'projectOutcome', disabled: false }
      ]
    }
  },

  computed: {
    isFlow() {
      const status = this.achievement.thirdFlowInfoDTO
        ? this.achievement.thirdFlowInfoDTO.status
        : '1'
      return isDisabled(status)
    },

    isShowUploadStampedContract() {
      return this.form.hasContract
    }
  },

  watch: {
    form: {
      handler(newval) {
        if (!isEmpty(newval)) {
          // 分发数据
          this.$nextTick(() => {
            this.distributeData(this.achievement)
          })
          // 提交当前组件的流程信息
          this.$emit('comp-flow-info', newval.thirdFlowInfoDTO)
        }
      },
      immediate: true
    }
  },

  methods: {
    // 附件上传或预览
    openFile(item) {
      const title = item.label
      const fileProp = item.prop
      const module = this.achievement

      if (this.isFlow) {
        this.previewTitle = title
        this.previewFileListInfo = module[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = module[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '最终盖章合同':
          this.achievement.uploadStampedContract = fileListInfo
          break
        case '项目成果':
          this.achievement.projectOutcome = fileListInfo
          break
        default:
          break
      }
    },

    setBackStatus() {
      this.achievement.thirdFlowInfoDTO.status = '5'
      // 提交当前组件的流程信息
      this.$emit('comp-flow-info', this.achievement.thirdFlowInfoDTO)
    },

    distributeData(module) {
      Object.keys(module).forEach(key => this.$set(module, key, this.form[key]))
    },

    verityAppForm(data) {
      const canVerityFormItem = [...this.achievementForm].filter(
        item => !item.disabled
      )

      // 项目申请校验
      for (const formItem of canVerityFormItem) {
        if (formItem.show && !formItem.show()) continue

        if (!data[formItem.prop] && data[formItem.prop] != '0') {
          this.$message.error(`${formItem.label}不能为空`)
          return false
        }
      }

      return true
    },

    mergeData(isVerity = false) {
      if (isVerity && !this.verityAppForm(this.achievement)) return false
      return Object.assign({}, this.form, this.achievement)
    }
  }
}
</script>

<style lang="less" scoped></style>
