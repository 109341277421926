<template>
  <div class="fee-info-table">
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="费用信息" key="1" class="customStyle">
          <div style="margin-left: 24px">
            <table cellspacing="0">
              <tr
                class="th-first h-8 bg-gray-400"
                style="background-color: #f9f9f9"
              >
                <th
                  class="w-2/14"
                  rowspan="2"
                  style="border-right: 1px solid #e9e9e9"
                >
                  项目
                </th>
                <th colspan="3" style="border-right: 1px solid #e9e9e9">
                  预算
                </th>
                <th colspan="3">实际花销</th>
              </tr>
              <tr class="h-8 bg-gray-400" style="background-color: #f9f9f9">
                <th class="w-2/14">单价</th>
                <th class="w-2/14">数量</th>
                <th class="w-2/14" style="border-right: 1px solid #e9e9e9">
                  总价
                </th>
                <th class="w-2/14">
                  单价
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <th class="w-2/14">
                  数量
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <th class="w-2/14">
                  总价
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
              </tr>
              <!-- 展位 -->
              <tr v-if="conferenceTypeName !== '地区'" class="trBgClass">
                <td class="w-2/14 tr-header">
                  展位(场地费)
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.booth
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.booth, '展位(场地费)')
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetBooth }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetBoothNum }}
                </td>
                <td class="w-2/14 tr-footer">{{ reimbudgetBoothTotal }}</td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetBooth,
                      feeReimInfo.budgetBooth
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetBooth"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetBooth }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetBoothNum,
                      feeReimInfo.budgetBoothNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetBoothNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetBoothNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetBoothTotal, budgetBoothTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetBoothTotal }}
                </td>
              </tr>
              <!-- 会议室 -->
              <tr>
                <td class="w-2/14 tr-header">
                  会议室(场地费)
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.meetingroom
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.meetingroom, '会议室(场地费)')
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetMeetingroom }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetMeetingroomNum }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ reimbudgetMeetingroomTotal }}
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetMeetingroom,
                      feeReimInfo.budgetMeetingroom
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetMeetingroom"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetMeetingroom }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetMeetingroomNum,
                      feeReimInfo.budgetMeetingroomNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetMeetingroomNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetMeetingroomNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetMeetingroomTotal, budgetMeetingroomTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetMeetingroomTotal }}
                </td>
              </tr>
              <!-- 制作费 -->
              <tr class="trBgClass">
                <td class="w-2/14 tr-header">
                  制作费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.make
                      )
                    "
                    @handleClick="openPreviewModal(feeEnumIds.make, '制作费')"
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetMake }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetMakeNum }}
                </td>
                <td class="w-2/14 tr-footer">{{ reimbudgetMakeTotal }}</td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(feeReimInfo.reimbudgetMake, feeReimInfo.budgetMake)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetMake"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetMake }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetMakeNum,
                      feeReimInfo.budgetMakeNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetMakeNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetMakeNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetMakeTotal, budgetMakeTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetMakeTotal }}
                </td>
              </tr>
              <!-- 设计费 -->
              <tr>
                <td class="w-2/14 tr-header">
                  设计费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.design
                      )
                    "
                    @handleClick="openPreviewModal(feeEnumIds.design, '设计费')"
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetDesign }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetDesignNum }}
                </td>
                <td class="w-2/14 tr-footer">{{ reimbudgetDesignTotal }}</td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetDesign,
                      feeReimInfo.budgetDesign
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetDesign"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetDesign }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetDesignNum,
                      feeReimInfo.budgetDesignNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetDesignNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetDesignNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetDesignTotal, budgetDesignTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetDesignTotal }}
                </td>
              </tr>
              <!-- 飞机费 -->
              <tr class="trBgClass">
                <td class="w-2/14 tr-header">
                  飞机费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.flight
                      )
                    "
                    @handleClick="openPreviewModal(feeEnumIds.flight, '飞机费')"
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetFlight }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetFlightNum }}
                </td>
                <td class="w-2/14 tr-footer">{{ reimbudgetFlightTotal }}</td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetFlight,
                      feeReimInfo.budgetFlight
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetFlight"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetFlight }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetFlightNum,
                      feeReimInfo.budgetFlightNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetFlightNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetFlightNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetFlightTotal, budgetFlightTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetFlightTotal }}
                </td>
              </tr>
              <!-- 高铁费 -->
              <tr>
                <td class="w-2/14 tr-header">
                  高铁费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.train
                      )
                    "
                    @handleClick="openPreviewModal(feeEnumIds.train, '高铁费')"
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetTrain }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetTrainNum }}
                </td>
                <td class="w-2/14 tr-footer">{{ reimbudgetTrainTotal }}</td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetTrain,
                      feeReimInfo.budgetTrain
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetTrain"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetTrain }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetTrainNum,
                      feeReimInfo.budgetTrainNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetTrainNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetTrainNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetTrainTotal, budgetTrainTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetTrainTotal }}
                </td>
              </tr>
              <!-- 接送费用 -->
              <tr class="trBgClass">
                <td class="w-2/14 tr-header">
                  接送费用
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.reception
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.reception, '接送费用')
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetReception }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetReceptionNum }}
                </td>
                <td class="w-2/14 tr-footer">{{ reimbudgetReceptionTotal }}</td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetReception,
                      feeReimInfo.budgetReception
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetReception"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetReception }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetReceptionNum,
                      feeReimInfo.budgetReceptionNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetReceptionNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetReceptionNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetReceptionTotal, budgetReceptionTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetReceptionTotal }}
                </td>
              </tr>
              <!-- 住宿费 -->
              <tr>
                <td class="w-2/14 tr-header">
                  住宿费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.accommodation
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.accommodation, '住宿费')
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetAccommodation }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetAccommodationNum }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ reimbudgetAccommodationTotal }}
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetAccommodation,
                      feeReimInfo.budgetAccommodation
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetAccommodation"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetAccommodation }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.budgetAccommodationNum,
                      feeReimInfo.reimbudgetAccommodationNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetAccommodationNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetAccommodationNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(
                      reimbudgetAccommodationTotal,
                      budgetAccommodationTotal
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetAccommodationTotal }}
                </td>
              </tr>
              <!-- 餐费 -->
              <tr class="trBgClass">
                <td class="w-2/14 tr-header">
                  餐费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.meals
                      )
                    "
                    @handleClick="openPreviewModal(feeEnumIds.meals, '餐费')"
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetMeals }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetMealsNum }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ reimbudgetMealsTotal }}
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.budgetMeals,
                      feeReimInfo.reimbudgetMeals
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetMeals"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetMeals }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.budgetMealsNum,
                      feeReimInfo.reimbudgetMealsNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetMealsNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetMealsNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetMealsTotal, budgetMealsTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetMealsTotal }}
                </td>
              </tr>
              <!-- 第三方会务人员费用 -->
              <tr>
                <td class="w-2/14 tr-header">
                  第三方会务人员费用
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.thirdparty
                      )
                    "
                    @handleClick="
                      openPreviewModal(
                        feeEnumIds.thirdparty,
                        '第三方会务人员费用'
                      )
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetThirdparty }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetThirdpartyNum }}
                </td>
                <td class="w-2/14 tr-footer">
                  {{ reimbudgetThirdpartyTotal }}
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetThirdparty,
                      feeReimInfo.budgetThirdparty
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetThirdparty"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetThirdparty }}
                  </span>
                </td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetThirdpartyNum,
                      feeReimInfo.budgetThirdpartyNum
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetThirdpartyNum"
                      oninput="value=value.replace(/[^\d]/g,'')"
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetThirdpartyNum }}
                  </span>
                </td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(reimbudgetThirdpartyTotal, budgetThirdpartyTotal)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ budgetThirdpartyTotal }}
                </td>
              </tr>
              <!-- 会务服务费、税费 -->
              <tr class="trBgClass">
                <td class="last-td w-2/14 tr-header">
                  会务服务费、税费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.service
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.service, '会务服务费、税费')
                    "
                  />
                </td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetServiceTotal }}
                </td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td
                  class="last-td w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetServiceTotal,
                      feeReimInfo.budgetServiceTotal
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.budgetServiceTotal"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetServiceTotal }}
                  </span>
                </td>
              </tr>
              <!-- 协办费用 -->
              <tr v-if="conferenceTypeName !== '地区'">
                <td class="w-2/14 tr-header">
                  协办费用
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.cooperation
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.cooperation, '协办费用')
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetCooperationTotal }}
                </td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">--</td>
                <td
                  class="w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.budgetCooperationTotal,
                      feeReimInfo.reimbudgetCooperationTotal
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div
                    style="padding: 0 4px"
                    v-if="[1, 2, 4].includes(getHostingType) && !isRead"
                  >
                    <a-input
                      v-model="feeReimInfo.budgetCooperationTotal"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.budgetCooperationTotal }}
                  </span>
                </td>
              </tr>
              <!-- 讲者咨询费 -->
              <tr class="trBgClass">
                <td class="w-2/14 tr-header">
                  讲者咨询费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.consult
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.consult, '讲者咨询费')
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetConsultTotal }}
                </td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">--</td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetConsultTotal,
                      feeReimInfo.budgetConsultTotal
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ feeReimInfo.budgetConsultTotal }}
                </td>
              </tr>
              <!-- 推广物料 -->
              <tr>
                <td class="w-2/14 tr-header">
                  推广物料费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.brandpre
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.brandpre, '推广物料费')
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimbudgetBrandpreTotal }}
                </td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">--</td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(
                      feeReimInfo.reimbudgetBrandpreTotal,
                      feeReimInfo.budgetBrandpreTotal
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ feeReimInfo.budgetBrandpreTotal }}
                </td>
              </tr>
              <tr class="trBgClass">
                <td class="last-td w-2/14 tr-header">
                  其他费用
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.otherFee
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.otherFee, '其他费用')
                    "
                  />
                </td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">
                  {{ feeReimInfo.reimOtherFee }}
                </td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td
                  class="last-td w-2/14"
                  :class="[
                    isChange(feeReimInfo.reimOtherFee, feeReimInfo.otherFee)
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.otherFee"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.otherFee }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="last-td w-2/14 tr-header">
                  注册费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.registerFee
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.registerFee, '注册费')
                    "
                  />
                </td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">
                  {{ feeReimInfo.reimRegisterFee }}
                </td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td
                  class="last-td w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimRegisterFee,
                      feeReimInfo.registerFee
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.registerFee"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.registerFee }}
                  </span>
                </td>
              </tr>
              <tr v-if="[3, 4].includes(getHostingType)">
                <td class="w-2/14 tr-header">
                  信息发布费
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item => item.reimFeeType === feeEnumIds.infoPubAmount
                      )
                    "
                    @handleClick="
                      openPreviewModal(feeEnumIds.infoPubAmount, '信息发布费')
                    "
                  />
                </td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">
                  {{ feeReimInfo.reimInfoPubAmount }}
                </td>
                <td class="w-2/14 tr-footer">--</td>
                <td class="w-2/14 tr-footer">--</td>
                <td
                  class="w-2/14 tr-footer"
                  :class="[
                    isChange(
                      feeReimInfo.reimInfoPubAmount,
                      feeReimInfo.infoPubAmount
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  {{ feeReimInfo.infoPubAmount }}
                </td>
              </tr>
              <tr class="trBgClass" v-if="[1, 2, 4].includes(getHostingType)">
                <td class="last-td w-2/14 tr-header">
                  线上直播平台费用
                  <fee-floder-icon
                    :invoiceList="
                      billInfos.filter(
                        item =>
                          item.reimFeeType ===
                          feeEnumIds.onlineLiveBroadcastingFee
                      )
                    "
                    @handleClick="
                      openPreviewModal(
                        feeEnumIds.onlineLiveBroadcastingFee,
                        '线上直播平台费用'
                      )
                    "
                  />
                </td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">
                  {{ feeReimInfo.reimOnlineLiveBroadcastingFee }}
                </td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td class="last-td w-2/14 tr-footer">--</td>
                <td
                  class="last-td w-2/14"
                  :class="[
                    isChange(
                      feeReimInfo.reimOnlineLiveBroadcastingFee,
                      feeReimInfo.onlineLiveBroadcastingFee
                    )
                      ? 'activeClass'
                      : ''
                  ]"
                >
                  <div style="padding: 0 4px" v-if="!isRead">
                    <a-input
                      v-model="feeReimInfo.onlineLiveBroadcastingFee"
                      oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                      @change="updateItem"
                    />
                  </div>
                  <span style="padding: 0 16px" v-else>
                    {{ feeReimInfo.onlineLiveBroadcastingFee }}
                  </span>
                </td>
              </tr>
              <tr style="border: none">
                <td></td>
                <td></td>
                <td class="te-footer-text text-sm font-semibold float-right">
                  <span class="text-sm font-semibold">预算总金额：</span>
                </td>
                <td class="tr-footer">
                  <span class="text-sm font-semibold">
                    <span class="text-red-600">{{ budgetFee }}</span>
                  </span>
                </td>
                <td></td>
                <td class="te-footer-text text-sm font-semibold float-right">
                  <span class="text-sm font-semibold">实际花销合计：</span>
                </td>
                <td class="tr-footer">
                  <span class="text-sm font-semibold">
                    <span class="text-red-600">
                      {{ feeReimInfo.budgetTotal }}
                    </span>
                  </span>
                </td>
              </tr>
            </table>
          </div>
          <div class="text-sm font-semibold mt-6">
            <div
              class="text-right"
              style="background: #ddebf7; margin-left: 24px"
            >
              <div class="px-6 py-2 rounded">
                已预付金额:
                <span class="font-semibold text-red-600">
                  {{ feeReimInfo.budgetPreCost }}
                </span>
                <a-divider type="vertical" />
                <span>
                  批准报销金额 ：
                  <span class="font-semibold text-red-600">
                    {{ feeReimInfo.reimAmountApprove }}
                  </span>
                </span>
                <a-divider type="vertical" />
                <span>
                  财务批准报销金额 ：
                  <span class="font-semibold text-red-600">
                    {{ feeReimInfo.financialApprovalAmount }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <preview-modal
      v-if="showPreview"
      :uploadName="title"
      :previewFileListInfo="previewFileListInfo"
      @close="closePreview"
    ></preview-modal>
  </div>
</template>

<script>
import FeeFloderIcon from './components/FeeFloderIcon.vue'
import feeReimInfoMinxin from './mixins/feeReimInfoMixin'
import PreviewModal from '@/components/Upload/PreviewModal'
import { feeEnumIds } from '@/views/activity/modules/util'
export default {
  components: { FeeFloderIcon, PreviewModal },
  name: 'FeeInfo', //费用信息
  mixins: [feeReimInfoMinxin],
  props: {
    feeReimInfo: {
      type: Object,
      required: true
    },
    cooperationInfo: {
      type: String,
      default: '[]'
    },
    conferenceTypeName: [String, Number], //会议名称
    budgetConsultTotal: [String, Number], //专家讲者咨询费
    brandpreMoneytotal: [String, Number], //推广物料提示物费
    status: Boolean,
    hostingTypeName: String, //举办类型
    infoPubAmount: [String, Number] // 品牌信息-信息发布费用
  },
  data() {
    return {
      visible: false,
      budgetFee: 0, //预算总金额

      feeEnumIds, //总结单费用新对应的枚举id
      billInfos: [],
      showInvoicesList: [],

      showPreview: false,
      title: '',
      previewFileListInfo: '' //传入预览附件的文件信息
    }
  },
  watch: {
    cooperationInfo(newVal) {
      if (newVal && newVal !== '[]') {
        this.billInfos = JSON.parse(newVal)
      }
    }
  },
  computed: {
    getHostingType() {
      if (!this.hostingTypeName) return 1

      let active = false
      let purtive = false
      if (this.hostingTypeName.indexOf('学术推广活动') !== -1) active = true
      if (this.hostingTypeName.indexOf('品牌信息发布') !== -1) purtive = true

      if (active && !purtive) return 2 //举办类型为学术推广
      if (!active && purtive) return 3 //举办类型为品牌信息
      if (active && purtive) return 4 //举办类型为学术推广+品牌信息

      return 1 //举办类型为空
    }
  },
  methods: {
    submit() {
      this.calculate()
      this.$emit('update:feeReimInfo', this.feeReimInfo)
    },
    isChange(val1, val2) {
      if (val1 === val2) return false
      else return true
    },
    updateItem() {
      this.$emit('updateItem')
      this.submit()
    },
    openPreviewModal(pk, title) {
      this.previewFileListInfo = JSON.stringify(
        this.billInfos.filter(i => i.reimFeeType === pk)
      )
      this.title = title
      this.showPreview = true
    },

    // 关闭预览附件的窗口
    closePreview() {
      this.showPreview = false
    }
  }
}
</script>

<style lang="less" scoped>
.fee-info-table table {
  width: 100%;
}
.fee-info-table table th {
  text-align: left;
  font-size: 14px;

  font-weight: 600;
  color: #999999;
  padding: 4px 16px;
}

.fee-info-table table tr {
  padding: 12px;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
}

.fee-info-table table tr td {
  text-align: left;
  font-size: 14px;

  font-weight: 500;
  color: #333333;
}

.tr-header {
  padding: 9px 16px;
}

.value-null {
  padding: 9px 24px !important;
}

.tr-footer {
  padding: 5px 16px;
}

.te-footer-text {
  padding: 5px 0;
}

.activeClass {
  color: red !important;
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

/deep/.ant-divider {
  background: #a0aec0;
  margin: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
