<template>
  <div class="h-full card">
    <div class="text-base leadingloose">修改账号密码</div>
    <section class="form-header" v-if="edit">
      <a-radio-group :value="value" buttonStyle="solid" @change="changeButton">
        <a-radio-button value="a" @click="submitForm">保存</a-radio-button>
        <a-radio-button value="b" @click="cancel">取消</a-radio-button>
      </a-radio-group>
    </section>
    <div class="mt-8 flex justify-center">
      <div class="inline-block m-auto" style="width: 450px">
        <a-form
          :form="userform"
          ref="userform"
          :rules="rules"
          size="small"
          laba-width="120px"
          laba-position="right"
          inline
        >
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="用户名"
                prop="username"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input
                  v-model="username"
                  :disabled="true"
                  style="width: 223px"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="旧密码"
                prop="password"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input
                  v-decorator="[
                    'password',
                    {
                      rules: rules.password
                    }
                  ]"
                  type="password"
                  :disabled="!edit"
                  style="width: 223px"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="新密码"
                prop="newPassword"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input
                  v-decorator="[
                    'newPassword',
                    {
                      rules: rules.newPassword
                    }
                  ]"
                  style="width: 223px"
                  type="password"
                  :disabled="!edit"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item
                label="确认新密码"
                prop="name"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input
                  v-decorator="[
                    'newPasswordx',
                    {
                      rules: this.rules.newPasswordx
                    }
                  ]"
                  style="width: 223px"
                  type="password"
                  :disabled="!edit"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import { changePwd } from '@/api/sysadmin/users'
import { mapMutations, mapGetters } from 'vuex'

const valnewx = (rule, value, callback) => {
  if (value) {
    callback()
  } else {
    callback()
  }
}
export default {
  name: 'userform',
  data() {
    return {
      edit: true, //编辑控制
      loading: false,
      value: 'a',
      userform: this.$form.createForm(this),
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 }
      },
      rules: {
        password: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' }
        ],
        newPasswordx: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: valnewx }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['uid', 'username'])
  },
  methods: {
    ...mapMutations('user', ['SET_USER']),

    //保存按钮一直是蓝色状态
    changeButton() {
      this.value = 'a'
    },

    //取消修改密码
    cancel() {
      this.$router.go(-1)
    },

    submitForm() {
      new Promise((resolve, reject) => {
        this.userform.validateFields((err, values) => {
          return err ? reject(values) : resolve(values)
        })
      }).then(values => {
        this.loading = true
        let params = {
          uid: this.uid
        }
        //更新
        changePwd(Object.assign(params, values))
          .then(() => {
            this.$notify.success({
              duration: 2,
              message: '成功',
              description: '修改成功'
            })
            this.edit = false
            this.loading = false
            this.$router.push({
              name: 'userManage',
              params: {}
            })
          })
          .catch(error => {
            this.$message.error(`修改失败：${error.message}。`)
            this.loading = false
          })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.form-header {
  margin: 1.5rem 32px;
}
/deep/ .ant-form-item-label {
  font-size: 14px;

  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
}

/deep/.ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #4587f4;
  border-color: #4587f4;
  color: #ffffff;
}
</style>
