<template>
  <div class="editable-cell">
    <div class="editable-cell-input-wrapper">
      <div v-if="valType === 'float'">
        <a-input
          :value="value === '0' || value === 0 ? '' : value"
          oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
          ref="input"
          @change="handleChange"
          @pressEnter="check"
          @blur="check"
        />
      </div>
      <div v-else-if="valType === 'int'">
        <a-input
          :value="value === '0' || value === 0 ? '' : value"
          ref="input"
          oninput="value=value.replace(/[^\d]/g,'')"
          @change="handleChange"
          @pressEnter="check"
          @blur="check"
        />
      </div>
      <div v-else>
        <a-input
          :value="value"
          ref="input"
          @change="handleChange"
          @pressEnter="check"
          @blur="check"
        />
      </div>
    </div>
    <!-- <div v-else class="editable-cell-text-wrapper" @click="edit">
      <div v-if="valType !== 'string'" class="inline-block">
        {{ value ? value : '0' }}
      </div>
      <div v-else class="inline-block text-hidden" :title="value">
        {{ value || '' }}
      </div>
    </div> -->
  </div>
</template>
<script>
export default {
  props: {
    text: [Number, String],
    valType: String, //'float','int','string'三种值
    status: Boolean
  },
  data() {
    return {
      value: '',
      editable: false
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },
  created() {
    if (this.text) {
      this.value = this.text.toString()
    }
  },
  watch: {
    text(nval) {
      this.value = nval.toString()
    }
  },
  methods: {
    handleChange(e) {
      const value = e.target.value
      this.value = value
    },
    check() {
      this.editable = false
      if (this.valType !== 'string') {
        if (!this.value) this.value = '0'
        else this.value = Number(this.value)
      }
      this.$emit('change', this.value)
    },
    edit() {
      if (!this.status) {
        this.editable = true
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      }
    }
  }
}
</script>

<style lang="less">
.editable-cell {
  position: relative;
  height: 2rem;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding: 0px 4px;
  height: 2rem;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 24px;
  & .text-hidden {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  font-size: 18px;
  margin-right: 2px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
