export default {
  namespaced: true,
  state: {
    chooseData: {}
  },
  mutations: {
    SET_CHOOSE_DATA(state, value) {
      state.chooseData = value
    }
  }
}
