<template>
  <div class="text-xs">
    <digit :pid="pid" @status="nowStatus($event)"></digit>
  </div>
</template>

<script>
import Digit from './digit/DIGIT'

export default {
  name: 'DigitBill',
  components: {
    Digit
  },
  props: {
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
