<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handClose"
      @cancel="handClose"
      width="56%"
      :title="`${uploadName}附件预览`"
      :closable="false"
      :keyboard="false"
      :mask="false"
      :maskClosable="false"
      cancelText="关闭"
      dialogClass="upload-preview-file"
    >
      <div class="card-containers">
        <div class="clearfix">
          <div class="file-list-card" v-if="fileList && fileList.length !== 0">
            <div
              class="file-list"
              :title="file.oname"
              v-for="file in fileList"
              :key="file.id"
            >
              <div class="file-info">
                <div class="file-mask">
                  <span class="file-type">
                    <a-icon
                      type="eye"
                      class="text-preview"
                      title="预览文件"
                      @click="preview(file)"
                    />
                    <a-icon
                      type="arrow-down"
                      class="text-download"
                      title="下载文件"
                      @click="download(file)"
                    />
                  </span>
                </div>
                <img :src="file.src" />
              </div>
              <div class="file-name">{{ file.oname }}</div>
            </div>
          </div>
          <div v-else class="text-center">
            <icon-svg icon="preview_null" size="150px" />
            <p class="text-gray-500 text-xs">暂无附件</p>
          </div>
        </div>
      </div>
    </a-modal>
    <pdf-preview
      v-if="showPreviewPDF"
      :fileId="fileId"
      @close="closePdfPreview"
    ></pdf-preview>
    <image-preview
      v-if="showPreviewImage"
      :imageList="imageList"
      :imageIndex="imageIndex"
      @close="closeImagePreview"
    ></image-preview>
    <excel-preview
      v-if="showPreviewExcel"
      :fileId="fileId"
      :isPay="true"
      @close="closeExcelPreview"
    ></excel-preview>
  </div>
</template>

<script>
import {
  getIMGURL,
  getPDFURL,
  getWORDURL,
  getEXCELURL
} from '@/api/acdemicActive/payupload'
import PdfPreview from '@/components/Preview/PayPdfPreview'
import ImagePreview from '@/components/Preview/ImagePreview'
import ExcelPreview from '@/components/Preview/ExcelPreview'

import { saveAs } from 'file-saver'

export default {
  name: 'uploadModal',
  props: {
    uploadName: {
      type: String
    },
    previewFileListInfo: {
      type: String
    }
  },
  components: {
    PdfPreview,
    ImagePreview,
    ExcelPreview
  },
  data() {
    return {
      visible: true,
      showPreviewPDF: false, //默认打开pdf的modal是关闭的
      showPreviewImage: false, //默认打开image的modal是关闭的
      showPreviewExcel: false, //默认打开excel的modal是关闭的
      fileId: '', //传入pdf预览框的文件id
      fileList: [], //上传成功的文件列表
      imageList: [], //所有图片的集合列表
      imageIndex: 0 //打开图片预览的索引
    }
  },
  created() {
    if (this.previewFileListInfo) {
      this.imageList = []
      this.fileList = JSON.parse(this.previewFileListInfo)
      this.fileList.forEach((file, index) => {
        if (!['pdf', 'doc', 'docx', 'xlsx', 'xls'].includes(file.type)) {
          getIMGURL(file.id).then(response => {
            this.$set(this.fileList[index], 'src', response)
            this.imageList.push(file)
          })
        } else {
          if (!file.src) {
            if (file.type === 'pdf') {
              file.src = require('@/assets/upload/pdf.png')
            } else if (file.type === 'docx' || file.type === 'doc') {
              file.src = require('@/assets/upload/word.png')
            } else if (file.type === 'xls' || file.type === 'xlsx') {
              file.src = require('@/assets/upload/excel.png')
            }
          }
        }
      })
    }
  },
  methods: {
    handClose() {
      this.$emit('close')
    },
    //预览pdf
    preview(file) {
      if (['doc', 'docx', 'xls', 'xlsx', 'pdf'].includes(file.type)) {
        this.fileId = file.id
        this.showPreviewExcel = true
      } else {
        for (let i = 0; i < this.imageList.length; i++) {
          if (this.imageList[i].id === file.id) {
            this.imageIndex = i
            break
          }
        }
        this.showPreviewImage = true
      }
    },

    //关闭pdf预览窗口
    closePdfPreview() {
      this.showPreviewPDF = false
    },

    //关闭image预览窗口
    closeImagePreview() {
      this.showPreviewImage = false
    },

    // 关闭excel预览窗口
    closeExcelPreview() {
      this.showPreviewExcel = false
    },

    //文件下载
    download(file) {
      if (file.type === 'pdf') {
        getPDFURL(file.id)
          .then(response => {
            this.createDownload(response, file.oname)
          })
          .catch(() => {
            this.$message('下载失败')
          })
      } else if (file.type === 'doc' || file.type === 'docx') {
        getWORDURL(file.id)
          .then(response => {
            this.createDownload(response, file.oname)
          })
          .catch(() => {
            this.$message('下载失败')
          })
      } else if (file.type === 'xlsx' || file.type === 'xls') {
        getEXCELURL(file.id)
          .then(response => {
            this.createDownload(response, file.oname)
          })
          .catch(() => {
            this.$message('下载失败')
          })
      } else {
        this.createDownload(file.src, file.oname)
      }
    },

    /**
     * 文件下载
     * @param {String} src //blob对象的url
     * @param {String} fileName 下载的文件名称
     */
    createDownload(src, fileName) {
      saveAs(src, fileName)
    }
  }
}
</script>

<style lang="less"></style>
