<template>
  <a-modal
    :visible="visible"
    title="住房信息"
    width="800px"
    closable
    wrapClassName="house"
    :maskClosable="false"
    :bodyStyle="{ padding: '0' }"
    @ok="submit"
    @cancel="$emit('close')"
  >
    <div class="modal-content">
      <div class="m-4">
        <div class="w-full my-3">
          <div class="search">
            <a-input
              v-model.trim="searchStr"
              :placeholder="`查询房产`"
              addonBefore="搜索"
              @input="filterByName"
              class="w-full"
            />
          </div>
        </div>
        <a-table
          rowKey="id"
          :dataSource="data"
          :columns="columns"
          :pagination="false"
          :loading="loading"
          :rowClassName="
            record => {
              return chooseArchiver.id === record.id ? 'bg-blue-200' : ''
            }
          "
          :customRow="
            (record, index) => {
              return {
                on: {
                  click: () => (chooseArchiver = record),
                  dblclick: () => {
                    chooseArchiver = record
                    submit()
                  }
                }
              }
            }
          "
        ></a-table>
      </div>
      <div class="text-right mt-4">
        <a-pagination
          size="small"
          showQuickJumper
          :hideOnSinglePage="true"
          :current="current"
          :pageSize="pageSize"
          :total="total"
          @change="pageChange"
          @showSizeChange="showSizeChange"
        />
      </div>
    </div>
  </a-modal>
</template>

<script>
import listMix from '@/globalMix/listMix'
import { Drag, resetDrag } from '@/utils/directives/drag'
import { getArchiveHouseList } from '@/api/house/archive-house'
import { default as isEmpty } from 'lodash/isEmpty'
import { default as debounce } from 'lodash/debounce'
import { default as pullAllBy } from 'lodash/pullAllBy'
export default {
  name: 'HouseArchivesModal',
  mixins: [listMix],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      current: 1,
      pageSize: 12,
      total: 0,
      loading: false,
      data: [],
      conditions: this.isAll
        ? []
        : [
            {
              column: 'useType',
              opt: '=',
              value: '47d70bbfeb114acf81a81186c56df3ff'
            },
            {
              column: 'houseStatusId',
              opt: '=',
              value: '3ae17821634e453b880065b99c52e4c7'
            }
          ],
      searchStr: '',
      chooseArchiver: {}, // 选择住房档案
      columns: [
        {
          title: '住房编码',
          dataIndex: 'houseCode',
          width: 150,
          ellipsis: true
        },
        {
          title: '房产类型',
          dataIndex: 'houseTypeName',
          width: 150,
          ellipsis: true
        },
        {
          title: '管理部门',
          dataIndex: 'houseDeptName',
          width: 150,
          ellipsis: true
        },
        {
          title: '地址',
          dataIndex: 'address',
          ellipsis: true
        }
      ]
    }
  },

  created() {
    this.getList()
  },

  destroyed() {
    this.$nextTick(() => {
      resetDrag()
    })
  },

  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          Drag('.house')
        })
      }
    }
  },

  methods: {
    getList() {
      this.loading = true
      getArchiveHouseList(this.current, this.pageSize, this.conditions)
        .then(res => {
          this.data = res.datas
          this.total = res.totalNum
        })
        .finally(() => (this.loading = false))
    },

    submit() {
      if (isEmpty(this.chooseArchiver)) {
        this.$message.error('请选择房产信息')
        return
      }

      this.$emit('choose', this.chooseArchiver)
      this.$emit('close')
    },

    // 过滤查询信息
    filterByName: debounce(function () {
      this.current = 1

      pullAllBy(this.conditions, [{ column: 'search' }], 'column')

      this.conditions.push({
        column: 'search',
        opt: 'like',
        value: this.searchStr
      })

      this.getList()
    }, 500)
  }
}
</script>

<style lang="less" scoped>
.modal-content {
  height: 560px;
  padding: 0;
  &.choose-active {
    background-color: #4587f5;
    color: #fff;
  }
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
