const TABLE = [
  {
    title: '省办费用申请信息',
    active: 'FeeInfo',
    dataList: 'bodyDOs',
    columns: [
      { title: '业务员', dataIndex: 'clerkName' },
      { title: '业务员编码', dataIndex: 'clerkCode' },
      { title: '月份', dataIndex: 'yearmth' },
      { title: '工资金额', dataIndex: 'wagesTotal' },
      { title: '工资余额', dataIndex: 'wagesRemaining' },
      { title: '本次申请工资', dataIndex: 'wagesAppThisMonth' },
      { title: '贴票金额', dataIndex: 'ticketTotal' },
      { title: '贴票余额', dataIndex: 'ticketRemaining' },
      { title: '本次申请贴票', dataIndex: 'ticketRemainingThisMonth' }
    ]
  }
]

export { TABLE }
