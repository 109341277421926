<template>
  <div>
    <a-modal
      :title="`${waitWork.subject}--申请人：${waitWork.sendname}`"
      v-model="visible"
      dialogClass="work-model clearfix"
      :maskClosable="false"
      width="90%"
      @cancel="handleClose"
      :footer="null"
    >
      <div class="content-header">
        <div>
          <span class="bill-name">
            {{ getType(waitWorkForm.billtype, waitWorkForm.unbindApp) }}
          </span>
        </div>
        <div class="flex justify-center content-center">
          <icon-font :type="icon" class="text-2xl"></icon-font>
          <span class="bill-status" :class="color">{{ text }}</span>
        </div>
      </div>
      <div
        ref="dom"
        class="content-left content-body overflow-auto"
        :style="{
          width:
            getType(waitWorkForm.billtype, waitWorkForm.unbindApp) ===
            '解绑申请'
              ? '100%'
              : ''
        }"
      >
        <content-left
          ref="cLeft"
          :pk="waitWorkForm.pk"
          :billtype="waitWorkForm.billtype"
          :unbindApp="waitWorkForm.unbindApp"
          :appDate="waitWorkForm.appDate"
          :summaryId="waitWorkForm.objectId"
          @status="nowStatus($event)"
          @close="close"
        ></content-left>
      </div>
      <div
        v-if="
          getType(waitWorkForm.billtype, waitWorkForm.unbindApp) !== '解绑申请'
        "
      >
        <div class="content-right content-body">
          <content-right
            @optype="changeOptype"
            @note="changeNote"
            @speakerLevel="changeSpeakerLevel"
            @budgetApproveTotal="changeBudgetApproveTotal"
            @approvedSum="changeApprovedSum"
            @reimAmountApprove="getReimAmountApprove($event)"
            @submit="submit"
            :billtype="waitWorkForm.billtype"
            :pid="waitWorkForm.pk"
            :chooseValue="chooseValue"
            :tabKey="tabKey"
            :status="status"
            ref="contentRight"
          ></content-right>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ContentLeft from '@/views/workplace/detailwaitworkmodal/ContentLeft'
import ContentRight from '@/views/workplace/detailwaitworkmodal/ContentRight'

import {
  getHandle,
  getTemporaryStorage
} from '@/api/acdemicActive/acdemic-application'

import { updApproveReceptBill } from '@/api/acdemicActive/activity-recept'
import { receptActValidate } from '@/views/activity/modules/recept-validate'

import { getBillMsg } from '@/configs/bill-msg'
import { getType } from '@/configs/bill-config'

export default {
  name: 'detailWaitWorkModal',
  props: ['waitWork', 'chooseValue', 'tabKey'],
  components: {
    ContentLeft,
    ContentRight
  },
  provide() {
    return {
      workForm: this.waitWorkForm,
      chooseValue: this.chooseValue
    }
  },
  data() {
    return {
      visible: true,
      status: '', //单据状态
      icon: 'icon_shenpizhongnew', //图标
      color: '', //图标旁的字体颜色
      text: '', //图标旁的字体
      optype: 1, //审批状态
      note: '', //审批备注说明
      handleForm: {},
      waitWorkForm: this.waitWork, //单据类型
      budgetApproveTotal: '', //批准总预算
      reimAmountApprove: 0, //批准报销金额
      approvedSum: '', // （丙肝送检公费申请单）批准核销金额
      speakerLevel: '', //讲者级别的id

      hotelManager: false //是否为饭店管理人员
    }
  },

  mounted() {
    this.$store.commit('rightContent/SET_SCROLL_DOM', this.$refs.dom)

    const roles = this.$store.getters['roles']
    for (let item of roles) {
      // 判断是否为饭店管理人员
      if (item.code === '013') {
        this.hotelManager = true
      }
    }
  },

  destroyed() {
    this.$store.commit('rightContent/REMOVE_SCROLL_DOM')
  },

  methods: {
    getType,

    nowStatus(status) {
      const { icon, color, text } = getBillMsg(status)

      this.icon = icon
      this.color = color
      this.text = text
      this.status = status
    },

    // 关闭窗口
    handleClose() {
      this.$emit('close')
    },
    changeOptype(value) {
      this.optype = value
    },
    changeNote(value) {
      this.note = value
    },
    changeSpeakerLevel(value) {
      this.speakerLevel = value
    },
    changeBudgetApproveTotal(value) {
      this.budgetApproveTotal = value
    },
    changeApprovedSum(value) {
      this.approvedSum = value
    },
    onChange(e) {
      this.chooseDisagree = e.target.value
    },

    close() {
      this.handleClose()
      this.$emit('refresh')
    },

    submit() {
      this.handleForm = this.waitWork
      this.handleForm.note = this.note
      // 申请同意
      if (this.optype === 1) {
        this.handleForm.optype = this.optype
        // 学术申请的
        if (
          this.waitWork.billtype === 'XSSQ' ||
          this.waitWork.billtype === 'XSHYBG'
        ) {
          this.handleForm.activityAppBrandpreInfoDTOs =
            this.activityAppBrandpreInfoDTOs
          this.handleForm.brandpreMoneytotal = this.brandpreMoneytotal
          if (
            this.budgetApproveTotal === '' ||
            this.budgetApproveTotal === null
          ) {
            this.$message.error('请输入批准预算')
          } else {
            this.handleForm.budgetApproveTotal = this.budgetApproveTotal

            // 批准预算
            if (
              this.status !== '0' &&
              this.handleForm.budgetApproveTotal == 0
            ) {
              this.$confirm({
                content: '批准金额为0, 是否继续?',
                okText: '是',
                cancelText: '否',
                onOk: () => {
                  this.submitAudit(this.handleForm)
                },
                onCancel() {}
              })
            } else {
              this.submitAudit(this.handleForm)
            }
          }
        }
        // 学术报销
        if (this.waitWork.billtype === 'XSBX') {
          this.handleForm.reimAmountApprove = this.reimAmountApprove
          if (this.status !== '0' && this.handleForm.reimAmountApprove == 0) {
            this.$confirm({
              content: '批准金额为0, 是否继续?',
              okText: '是',
              cancelText: '否',
              onOk: () => {
                this.submitAudit(this.handleForm)
              },
              onCancel() {}
            })
          } else {
            this.submitAudit(this.handleForm)
          }
        }
        if (this.waitWork.billtype === 'JZBG') {
          this.handleForm.speakerLevel = this.speakerLevel
          this.submitAudit(this.handleForm)
        }

        if (this.waitWork.billtype === 'JDSQ') {
          if (!this.hotelManager) {
            this.submitAudit(this.handleForm)
          } else {
            const { optype } = this.handleForm
            const data = this.$refs.cLeft.getJdsqData()

            if (receptActValidate(data)) {
              if (optype != 97) data.writeOrNot = true
              else data.writeOrNot = false

              updApproveReceptBill(data).then(() => {
                this.submitAudit(this.handleForm)
              })
            }
          }
        }

        // 仿制药数字合同和销售管理数字合同-同一审批人跳过审批
        if (
          this.waitWork.billtype === 'DIGIT_CONTRACT' ||
          this.waitWork.billtype === 'BILL_GENERIC_DRUGS_DIGIT_CONTR'
        ) {
          this.handleForm.isSkip = true
          this.submitAudit(this.handleForm)
        }

        if (this.waitWork.billtype === 'GFHX') {
          this.handleForm.approvedSum = this.approvedSum
        }

        if (
          this.waitWork.billtype === 'YFKD' ||
          this.waitWork.billtype === 'DGFK' ||
          this.waitWork.billtype === 'DSFK' ||
          this.waitWork.billtype === 'YYBG' ||
          this.waitWork.billtype === 'YSBG' ||
          this.waitWork.billtype === 'MBYS' ||
          this.waitWork.billtype === 'BFBQ' ||
          this.waitWork.billtype === 'MDSQ' ||
          this.waitWork.billtype === 'QYWSJ' ||
          this.waitWork.billtype === 'LCOSTAPP' ||
          this.waitWork.billtype === 'MBYYSQ' ||
          this.waitWork.billtype === 'CLERK_MONTH_INV' ||
          this.waitWork.billtype === 'CLEAR_INV' ||
          this.waitWork.billtype === 'PARTNER_AGREE_REFUND' ||
          this.waitWork.billtype === 'BILL_BUSINESS_REFUND_CONTRACT' ||
          this.waitWork.billtype === 'NON_ACADEMIC_REFUND' ||
          this.waitWork.billtype === 'BILL_NON_MEETING_APP' ||
          this.waitWork.billtype === 'BILL_OUTER_COMP_BILL' ||
          this.waitWork.billtype === 'BILL_GENERIC_DRUGS_DIGIT_CONTR' ||
          this.waitWork.billtype === 'ZFSQ' ||
          this.waitWork.billtype === 'FCBG' ||
          this.waitWork.billtype === 'ZFDJ' ||
          this.waitWork.billtype === 'YGSSSQ' ||
          this.waitWork.billtype === 'ZFDG' ||
          this.waitWork.billtype === 'ZFDS' ||
          this.waitWork.billtype === 'GFSJ' ||
          this.waitWork.billtype === 'GFHX'
        ) {
          this.submitAudit(this.handleForm)
        }
      } else {
        // 申请不同意
        this.handleForm.optype = this.optype
        this.submitAudit(this.handleForm)
      }
    },
    // 提交审批处理
    submitAudit(auditForm) {
      this.$refs.contentRight.openLoading()
      // 代理审批添加一个参数
      if (this.tabKey === 'proxy') auditForm.needProxyApprove = true

      if (auditForm.optype != 97) {
        getHandle(auditForm)
          .then(response => {
            if (response) this.$message.success(response)
            else this.$message.success('审批成功')
            this.handleClose()
            this.$emit('refresh')
            this.$refs.contentRight.closeLoading()
          })
          .catch(() => {
            this.$message.error('审批失败')
            this.$refs.contentRight.closeLoading()
          })
      } else {
        getTemporaryStorage(auditForm)
          .then(response => {
            if (response) this.$message.success(response)
            else this.$message.success('已成功提交暂存代办')
            this.handleClose()
            this.$emit('refresh')
            this.$refs.contentRight.closeLoading()
          })
          .catch(() => {
            this.$message.error('提交暂存代办失败')
            this.$refs.contentRight.closeLoading()
          })
      }
    },
    brandpreInfo(value) {
      this.brandpreInfoList = value
    },
    getReimAmountApprove(value) {
      this.reimAmountApprove = value
    }
  }
}
</script>

<style lang="less">
.work-model {
  top: 3vh !important ;
}
.work-model .ant-modal-header {
  padding: 8px 40px;
  background-color: #4587f4;
}
.work-model .ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.work-model .ant-modal-close-x {
  width: 36px;
  height: 36px;
  padding-right: 8px;
  line-height: 39px;
  color: #ffffff;
}
.work-model .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.work-model .ant-modal-body {
  padding: 0px;
  height: 86vh;
}
.work-model .content-header {
  height: 8vh;
  display: flex;
  align-items: Center;
  justify-content: space-between;
  padding: 16px 32px 16px 42px;
  border-bottom: 1px solid #f5f5f5;
  & .bill-name {
    font-size: 16px;

    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
  & .bill-status {
    font-size: 16px;

    font-weight: 600;
    margin-left: 10px;
  }
}
.content-left {
  width: 80%;
  height: 76vh;
  float: left;
  border-right: 1px solid #f5f5f5;
}
.content-right {
  width: 19%;
  height: 76vh;
  margin-left: 4px;
  float: left;
}
</style>
