/* eslint-disable prettier/prettier */
export default {
  data() {
    return {
      standardInfo: {},
      standardVisible: false,
      standard: {
        activitytype: '',
        activityTypeStdDTO: {
          actID: '', //活动类型id
          acttype: '', // 活动类型
          customernum: '', // 参会客户
          speakernumLt: '', // 讲者人数-大于
          speakernumGt: '', // 讲者人数-小于
          insidersNum: '', // 内部人员数
          speakerConsultationFee: '', // 讲者劳务费(元/人)
          lectureTime: '', // 讲者讲课时间 (分钟/人)
          trafficExpense: '', // 交通费（元）
          transportExpense: '', // 接送费(y元)
          venuefee: '', // 场地费（元/天）
          hotelfee: '', // 住宿费（元/人/天）
          mealsfee: '', // 餐费（元/人/天）
          meetingLength: '', // 会议时长（天）
          notes: '' // 备注
        }
      },

      standardForm: [
        { label: '活动类型', prop: 'acttype', disabled: true },
        { label: '参会客户人数（不含讲者）', prop: 'customernum', disabled: true },
        { label: '内部人员数', prop: 'insidersNum', disabled: true },
        { label: '讲者人数', prop: 'speakernum', propGt: 'speakernumGt', propLt: 'speakernumLt', type: 'between', disabled: true },
        { label: '讲者劳务费(元/人)', prop: 'speakerConsultationFee', disabled: true },
        { label: '讲者讲课时间 (分钟/人)', prop: 'lectureTime', disabled: true },
        { label: '交通费(元)', prop: 'trafficExpense', disabled: true },
        { label: '接送费(元)', prop: 'transportExpense', disabled: true },
        { label: '场地费(元/天)', prop: 'venuefee', disabled: true },
        { label: '住宿费(元/人/天)', prop: 'hotelfee', disabled: true },
        { label: '餐费(元/人/天)', prop: 'mealsfee', disabled: true },
        { label: '会议时长(天)', prop: 'meetingLength', disabled: true }
      ],

      standardTextareaForm: [
        { label: '备注', prop: 'notes', disabled: true},
      ]
    }
  },

  computed: {
    getConferenceType() {
      return this.user.conferenceType
    }
  },

  watch: {
    // 所属级别变化,清空已选的活动类型
    getConferenceType(val){
      if(!this.isInit && val) {
        this.standard.activitytype = ''
        this.standard.activityTypeStdDTO = {}
      }
    }
  },

  methods: {
    // 查看活动类型及执行标准详情
    viewActivityTypeDetail(info) {
      this.standardInfo = info
      this.standardVisible = true
    },

    // 活动类型弹窗
    showActiveVisible() {
      if(!this.getConferenceType) {
        this.$message.error('请先选择所属层级')
        return
      }
      this.$refs.activemodel.visible = true
    },
    chooseActive(item) {
      this.standard.activityTypeStdDTO = item
      this.$set(this.standard, 'activitytype', item.actID)
    }
  }
}
