// 业务员扣补费用
import request from '@/utils/request'

/**
 * 获取省办费用申请列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getLocalCostAppList(
  pageindex,
  pagenum,
  conditions,
  { year = null, mth = null }
) {
  return request({
    url: `localCostApp/head/${pageindex}/${pagenum}`,
    method: 'post',
    params: { year, mth },
    data: conditions
  })
}

/**
 * 获取业务员费用汇总数据参照
 * @param {Array} conditions
 * @returns
 */
export function getRefCostAggList(pkClerk) {
  return request({
    url: `localCostApp/refCostAgg/${1}/${20}`,
    method: 'post',
    data: [{ column: 'pkClerk', opt: '=', value: pkClerk }]
  })
}

/**
 * 根据id来删除费用汇总信息(表头)
 * @param {String} id
 * @returns
 */
export function delLocalCostAppById(id) {
  return request({
    url: `localCostApp/head/${id}`,
    method: 'delete'
  })
}

/**
 * 保存省办费用申请
 * @param {String} yearMth
 * @param {String} data
 * @returns
 */
export function saveLocalCostApp(yearMth, data) {
  return request({
    url: `localCostApp/save`,
    method: 'post',
    params: { yearMth },
    data
  })
}

/**
 * 根据ID来查询省办费用申请
 * @param {String} id
 * @returns
 */
export function getLocalCostAppById(id) {
  return request({
    url: `localCostApp/head/${id}`,
    method: 'get'
  })
}

/**
 * 获取省办费用申请列表（表体）
 * @param {String} id
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getLocalCostAppBodyList(id, pageindex, pagenum, conditions) {
  return request({
    url: `localCostApp/body/${pageindex}/${pagenum}`,
    method: 'post',
    params: { hid: id },
    data: conditions
  })
}

/**
 * 删除地办汇总信息(表体)
 * @param {String} bid
 * @returns
 */
export function delLocalCostApp(bid) {
  return request({
    url: `localCostApp/body/${bid}`,
    method: 'delete'
  })
}

/**
 * 获取nc业务员参照列表
 * @param {Integer} pageindex
 * @param {Array} name
 * @returns
 */
export function getClerkRefNcList() {
  return request({
    url: `clerkRefNc/ul/${1}/${200}`,
    method: 'post',
    data: { search: '' }
  })
}

/**
 * 提交地办费用信息
 * @param {Object} data
 * @returns
 */
export function submitLocalCostApp(data, yearmth = null) {
  return request({
    url: `oaflow/submitLocalCostApp`,
    method: 'put',
    data: yearmth ? { yearmth, costAggRefs: data } : data
  })
}

/**
 * 获取关账按钮状态
 * @returns
 */
export function getLocalCostAppStatus() {
  return request({
    url: `localCostApp/status/get`,
    method: 'get'
  })
}

/**
 * 关账按钮
 * @param {Boolean} status
 * @returns
 */
export function setLocalCostAppStatus(status) {
  return request({
    url: `localCostApp/status/set`,
    method: 'post',
    params: { status }
  })
}

/**
 * 业务员费用汇总数据参照
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getLocalCostAppQueryList(pageindex, pagenum, conditions) {
  return request({
    url: `localCostApp/query/${pageindex}/${pagenum}`,
    method: 'post',
    data: conditions
  })
}
