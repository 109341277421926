import store from '@/store'
import { loadDictTypes } from '@/api/customer/dictmanage'

// 枚举类型表，与后台的枚举一一对应
const docTble = {
  '001': '医院类型',
  '002': '医院级别',
  '003': '医院性质',
  '004': '科室',
  '005': '职称',
  '006': '行政职务',
  '007': '学术头衔',
  '008': '重合订阅指数',
  '009': '医院状态',
  '010': '医生状态',
  '011': '等级医院终端',
  '012': '全科终端',
  '013': 'OTC终端',
  '015': '证件类型',
  '016': '单位级别',
  '017': '学会任职',
  '018': '讲者级别',
  '019': '讲者状态',
  '020': '学历',
  '022': '标准类型(活动类型及执行标准)',
  '023': 'HEC终端归属',
  '024': 'HEC终端分类',
  '025': '岗位类型',
  '026': '所属产品线',
  '027': '拜访时间',
  '028': '客户反馈',
  '029': '拜访时长',
  '030': '拜访类型',
  '031': '医院变更类型',
  '032': '医生变更类型',
  '033': '讲者变更类型',
  '034': '讲者职称',
  '035': '区域外时间类型',
  '036': '举办类型',
  '037': '时间',
  '038': '共享对象',
  '039': '周计划拜访医院类型',
  '040': '门店',
  '041': '流向获取方式',
  '042': '入库获取方式',
  '043': '库存获取方式',
  '044': '流向抓取频率',
  '045': '入库抓取频率',
  '046': '品种业务线',
  '047': '单位',
  '048': '出售类型',
  '049': '标准终端类型',
  '050': '付款方式',
  '051': '产品组',
  '052': '费用项目',
  '053': '举办形式',
  '054': '非会议类学术项目',
  '055': '非学术类学术项目类型',
  '056': '发货付款方式',
  '060': '省规分类',
  '061': '房产使用类型',
  '062': '房产付款方式',
  '063': '房产状态',
  '064': '房产分类',
  '065': '宿舍申请类型',
  '066': '户型',
  '067': '费用类型',
  '068': '入住状态',
  '069': '房产变更类型',
  '070': '学术会议总部统一筹划项目名称',

  100: '结算方式',
  200: '业务类型',
  300: '产品',
  400: '品种业务线'
}

let docs = store.getters.docList

function filterDoc(code) {
  let docItems = []
  const target = docs.find(item => item.code === code)
  if (target) {
    // 过滤掉未启用的
    docItems = multiple(target.defDocDTOs.filter(item => item.enable))
  }

  return docItems
}

/**
 * 将获取到的flitles枚举转换成{text: item.name，id:item.id}
 * @param list
 */
function multiple(list) {
  const multipleList = []
  list.forEach(item => {
    const { id, name, code } = item
    multipleList.push({ text: name, value: id, code })
  })
  return multipleList
}

let isLoadingData = false
let codeStack = []
let callbackStack = []
async function getDocList(code, cb) {
  if (!(docs && docs.length)) {
    codeStack.push(code)
    callbackStack.push(cb)
    if (!isLoadingData) {
      isLoadingData = true

      docs = await loadDictTypes()
      store.commit('doc/SET_DOC_LIST', docs)
      while (callbackStack.length) {
        const code = codeStack.pop()
        const cb = callbackStack.pop()
        cb(filterDoc(code))
      }

      isLoadingData = false
    }
  } else {
    cb(filterDoc(code))
  }
}

export function getDoc(code = '', cb) {
  if (code in docTble) {
    getDocList(code, cb)
  }
  return []
}
