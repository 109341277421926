<template>
  <div>
    <a-modal
      v-model="visible"
      @ok="handClose"
      @cancel="handClose"
      width="1024px"
      :title="`讲者差异信息`"
      :bodyStyle="{ padding: '0 24px 24px 24px' }"
      :closable="false"
      :keyboard="false"
      :mask="false"
      :maskClosable="false"
      cancelText="关闭"
      dialogClass="upload-preview-file"
    >
      <a-divider orientation="left">删除的讲者信息</a-divider>
      <a-table
        :rowKey="record => record.speakerId"
        :dataSource="getDiffData.deleteInfo"
        :columns="getColumns"
        :pagination="false"
        :scroll="{ y: 480 }"
        :rowClassName="
          (_record, index) => {
            return index % 2 === 0 ? '' : 'trBgClass'
          }
        "
      >
        <span slot="number" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'DiffAccomanyPersonModal',

  props: {
    columns: {
      type: Array,
      default: () => []
    },
    diffData: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      visible: true
    }
  },

  computed: {
    getColumns() {
      const columns = cloneDeep(this.columns)
      return columns
    },

    getDiffData() {
      const diffData = cloneDeep(this.diffData)
      return diffData
    }
  },

  methods: {
    handClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less"></style>
