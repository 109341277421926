import Generic from '@/views/generic'
export default {
  path: 'generic',
  name: 'generic',
  component: Generic,
  redirect: 'generic/genericDrugsOpeningInventory',
  meta: { title: '仿制药管理' },
  children: [
    {
      // 商业期初库存表
      path: 'genericDrugsOpeningInventory',
      name: 'genericDrugsOpeningInventory',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/inventory'
        ),
      meta: { title: '商业期初库存表', menuKey: 'genericDrugsOpeningInventory' }
    },
    {
      // 商业期初库存表详情
      path: 'inventoryForm',
      name: 'inventoryForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/inventory/InventoryForm'
        ),
      meta: {
        title: '商业期初库存表详情',
        menuKey: 'genericDrugsOpeningInventory'
      }
    },

    {
      // 仿制药流向单
      path: 'genericDrugsFlow',
      name: 'genericDrugsFlow',
      component: () =>
        import(/* webpackChunkName: "group-generic" */ '@/views/generic/flow'),
      meta: { title: '仿制药流向单', menuKey: 'genericDrugsFlow' }
    },
    {
      // 仿制药流向单详情
      path: 'flowForm',
      name: 'flowForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/flow/FlowForm'
        ),
      meta: {
        title: '仿制药流向单详情',
        menuKey: 'genericDrugsFlow'
      }
    },

    {
      // 仿制药调拨单
      path: 'genericDrugsAlloc',
      name: 'genericDrugsAlloc',
      component: () =>
        import(/* webpackChunkName: "group-generic" */ '@/views/generic/alloc'),
      meta: { title: '仿制药调拨单', menuKey: 'genericDrugsAlloc' }
    },
    {
      // 仿制药调拨单详情
      path: 'allocForm',
      name: 'allocForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/alloc/AllocForm'
        ),
      meta: {
        title: '仿制药调拨单详情',
        menuKey: 'genericDrugsAlloc'
      }
    },

    {
      // 仿制药省总规
      path: 'genericDrugsZg',
      name: 'genericDrugsZg',
      component: () =>
        import(/* webpackChunkName: "group-generic" */ '@/views/generic/zg'),
      meta: { title: '仿制药省总规', menuKey: 'genericDrugsZg' }
    },
    {
      // 仿制药省总规详情
      path: 'zgForm',
      name: 'zgForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/zg/ZgForm'
        ),
      meta: {
        title: '仿制药省总规详情',
        menuKey: 'genericDrugsZg'
      }
    },

    {
      // 仿制药数字合同
      path: 'genericDrugsDigitContr',
      name: 'genericDrugsDigitContr',
      component: () =>
        import(/* webpackChunkName: "group-generic" */ '@/views/generic/digit'),
      meta: { title: '仿制药数字合同', menuKey: 'genericDrugsDigitContr' }
    },
    {
      // 仿制药数字合同详情
      path: 'digitForm',
      name: 'digitForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/digit/DigitForm'
        ),
      meta: {
        title: '仿制药数字合同详情',
        menuKey: 'genericDrugsDigitContr'
      }
    },

    {
      // 仿制药终端月指标
      path: 'genericDrugsChnlIndex',
      name: 'genericDrugsChnlIndex',
      component: () =>
        import(/* webpackChunkName: "group-generic" */ '@/views/generic/chnl'),
      meta: { title: '仿制药终端月指标', menuKey: 'genericDrugsChnlIndex' }
    },
    {
      // 仿制药终端月指标详情
      path: 'chnlForm',
      name: 'chnlForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/chnl/ChnlForm'
        ),
      meta: {
        title: '仿制药终端月指标详情',
        menuKey: 'genericDrugsChnlIndex'
      }
    },

    {
      // 仿制药连锁终端清单
      path: 'genericDrugsChnlLs',
      name: 'genericDrugsChnlLs',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/chnlLs'
        ),
      meta: { title: '仿制药连锁终端清单', menuKey: 'genericDrugsChnlLs' }
    },
    {
      // 仿制药连锁终端清单详情
      path: 'chnllsForm',
      name: 'chnllsForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/chnlLs/ChnlLsForm'
        ),
      meta: {
        title: '仿制药连锁终端清单详情',
        menuKey: 'genericDrugsChnlLs'
      }
    },

    {
      // 仿制药费用计提
      path: 'genericDrugsDevBonusArchive',
      name: 'genericDrugsDevBonusArchive',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/archive'
        ),
      meta: {
        title: '仿制药开发奖金档案',
        menuKey: 'genericDrugsDevBonusArchive'
      }
    },
    {
      // 仿制药费用计提详情
      path: 'archiveForm',
      name: 'archiveForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/archive/ArchiveForm'
        ),
      meta: {
        title: '仿制药开发奖金档案详情',
        menuKey: 'genericDrugsDevBonusArchive'
      }
    },

    {
      // 仿制药费用计提
      path: 'genericDrugsFeeCalc',
      name: 'genericDrugsFeeCalc',
      component: () =>
        import(/* webpackChunkName: "group-generic" */ '@/views/generic/fee'),
      meta: { title: '仿制药费用计提', menuKey: 'genericDrugsFeeCalc' }
    },
    {
      // 仿制药费用计提详情
      path: 'feeCalcForm',
      name: 'feeCalcForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/fee/FeeCalcForm'
        ),
      meta: {
        title: '仿制药费用计提详情',
        menuKey: 'genericDrugsFeeCalc'
      }
    },

    {
      // 仿制药结算数量计提
      path: 'genericDrugsSettleNumCalc',
      name: 'genericDrugsSettleNumCalc',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/settle'
        ),
      meta: {
        title: '仿制药结算数量计提',
        menuKey: 'genericDrugsSettleNumCalc'
      }
    },
    {
      // 仿制药费用计提详情
      path: 'settleCalcForm',
      name: 'settleCalcForm',
      component: () =>
        import(
          /* webpackChunkName: "group-generic" */ '@/views/generic/settle/SettleCalcForm'
        ),
      meta: {
        title: '仿制药结算数量计提详情',
        menuKey: 'genericDrugsSettleNumCalc'
      }
    }
  ]
}
