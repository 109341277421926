<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="单据信息" key="1" class="customStyle">
          <div class="ml-2 mt-3 flex flex-wrap justify-start">
            <div class="form-item">
              <span class="form-label">单据编码</span>
              <a-input
                class="form-input"
                :disabled="true"
                :value="billMsg.billno"
              />
            </div>
            <div class="form-item">
              <span class="form-label">申请人</span>
              <a-input
                class="form-input"
                :disabled="true"
                :value="billMsg.applicantName"
              />
            </div>
            <div class="form-item">
              <span class="form-label">岗位</span>
              <a-input
                class="form-input"
                :disabled="true"
                :value="billMsg.vnote"
              />
            </div>
            <div class="form-item">
              <span class="form-label">申请日期</span>
              <a-input
                class="form-input"
                :disabled="true"
                :value="billMsg.appDate"
              />
            </div>
            <div class="form-item">
              <span class="form-label">
                部门
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-select
                v-model="billMsg.dept"
                class="form-input"
                :disabled="isRead"
              >
                <a-select-option
                  v-for="item in depts"
                  :key="item.did"
                  :value="item.did"
                >
                  {{ item.deptname }}
                </a-select-option>
              </a-select>
            </div>
            <div class="form-item">
              <span class="form-label">
                联系方式
                <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
              </span>
              <a-input
                v-model="billMsg.contact"
                class="form-input"
                :disabled="isRead"
              />
            </div>
            <div class="form-item">
              <span class="form-label">实际花销金额合计</span>
              <a-input
                class="form-input"
                :disabled="true"
                :value="billMsg.actualCost"
              />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BillMsg',
  props: {
    billMsg: {
      type: Object,
      required: true
    },
    status: Boolean,
    state: {
      type: String
    }
  },
  data() {
    return {
      depts: []
    }
  },
  computed: {
    isRead() {
      return this.status
    }
  },

  watch: {
    // 处理在流程中撤销的情况
    state: {
      handler(nval) {
        if (!nval) return
        this.initDeptment()
      }
    }
  },

  methods: {
    /**
     * 初始化部门，申请单据与申请人相同
     * 新增、撤销、查看（包括在审批流中单据）
     */
    initDeptment() {
      this.depts = []

      // 申请人和当前查看人是同一人
      if (this.$store.getters.uid === this.billMsg.applicant) {
        this.deptsFromStore()
        return
      }

      // 在流程中、终止或作废
      if (this.isRead) {
        this.deptsFromBill()
        return
      }
    },

    // 从sotre中获取部门信息（新增和撤销）
    deptsFromStore() {
      const depts = this.$store.getters.depts
      if (depts && depts.length) {
        this.depts = depts.map(item => {
          return { did: item.did, deptname: item.deptname }
        })
        this.billMsg.dept = depts[0].did
      }
    },

    // 从单据中获取部门信息（只有一个部门信息）（查看）
    deptsFromBill() {
      this.depts = [{ did: this.billMsg.dept, deptname: this.billMsg.deptName }]
    }
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
