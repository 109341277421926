const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '申请类型', value: `appTypeName` },
      { label: '申请状态', value: `appStatus` },
      { label: 'NC编码', value: `ncCode` },
      { label: '医院编码', value: `hospitalCode` },
      { label: '医院名称', value: `hospitalName` },
      { label: '医院别名', value: `hospitalAlias` },
      { label: '省份', value: `province` },
      { label: '市', value: `city` },
      { label: '县（区）', value: `county` },
      { label: '地址', value: `address` },
      { label: '医院级别', value: `hospitalClassName` },
      { label: '医院类型', value: `hospitalTypeName` },
      { label: '医院性质', value: `hospitalNatureName` },
      { label: 'HEC终端分类', value: `hecClassName` },
      { label: '邮编', value: `postcode` },
      { label: '床位数', value: `bednum` },
      { label: '年门诊量', value: `annuOutpatientVolume` },
      { label: '电话', value: `telephoneNumber` },
      { label: '进院产品', value: `hospitalProduct` },
      { label: '学术地办', value: `local` },
      { label: '业务区域', value: `chnlareaName` },
      { label: '标准终端类型', value: `chnlmemtypeStdName` },
      { label: '医院状态', value: `hospitalStatusName` },
      { label: '备注', value: `remarks` },
      { label: '创建时间', value: `creationtime` },
      { label: '创建人', value: `creatorName` },
      { label: '修改时间', value: `modifytime` },
      { label: '修改人', value: `modifierName` }
    ]
  }
]
const TABLE = [
  {
    title: '差异信息',
    active: 'DIFF',
    dataList: 'differDTOs',
    columns: [
      { title: '字段名称', dataIndex: 'fieldName' },
      { title: '数据变更前', dataIndex: 'beforeValue' },
      { title: '数据变更后', dataIndex: 'afterValue' }
    ]
  }
]

const PRODUCT_TABLE = [
  {
    title: '维护医院产品关系',
    active: 'HMSI',
    dataList: 'hospitalModifySAProductInfoDTOList',
    columns: [
      { title: '岗位', dataIndex: 'postName' },
      { title: '岗位编码', dataIndex: 'postCode' },
      { title: '岗位用户', dataIndex: 'postUserName' },
      { title: '岗位用户编码', dataIndex: 'postUserCode' },
      {
        title: '指标分配比例',
        dataIndex: 'indicatorProportion',
        scopedSlots: {
          customRender: 'indicator'
        }
      },
      {
        title: '销售分配比例',
        dataIndex: 'salesProportion',
        scopedSlots: {
          customRender: 'sales'
        }
      }
    ]
  }
]

export { BASE, TABLE, PRODUCT_TABLE }
