import { render, staticRenderFns } from "./YYBGBill.vue?vue&type=template&id=157ae8a6&scoped=true"
import script from "./YYBGBill.vue?vue&type=script&lang=js"
export * from "./YYBGBill.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "157ae8a6",
  null
  
)

export default component.exports