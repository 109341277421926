<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          header="住房情况(单价单位:元/间)"
          key="1"
          class="customStyle"
        >
          <!-- 预算 -->
          <Table
            :columns="appColumns"
            :data="houseCase.appRoomsInfos"
            :dataTemplate="roomItme"
            :isDisabled="isAppDisabled"
            @deleteItem="calcRoomsCostTotal('app')"
          >
            <span slot="inputCell" slot-scope="{ record, column }">
              <InputCell
                :record="record"
                :column="column"
                :isDisabled="isAppDisabled"
              ></InputCell>
            </span>

            <span slot="datePickerCell" slot-scope="{ record, column }">
              <DatePickerCell
                :record="record"
                :column="column"
                :isDisabled="isAppDisabled"
              ></DatePickerCell>
            </span>

            <span>
              <span class="font-bold">预算用房金额合计：</span>
              <span class="text-red-500">
                ￥{{ houseCase.roomsBudget || 0 }}
              </span>
            </span>
          </Table>
          <!-- 实际 -->
          <Table
            v-if="showAct"
            :columns="actColumns"
            :data="houseCase.actRoomsInfos"
            :dataTemplate="roomItme"
            :isDisabled="isActDisabled"
            @deleteItem="calcRoomsCostTotal('act')"
          >
            <span slot="inputCell" slot-scope="{ record, column }">
              <InputCell
                :record="record"
                :column="column"
                :isDisabled="isActDisabled"
              ></InputCell>
            </span>

            <span slot="datePickerCell" slot-scope="{ record, column }">
              <DatePickerCell
                :record="record"
                :column="column"
                :isDisabled="isActDisabled"
              ></DatePickerCell>
            </span>

            <span>
              <span class="font-bold">实际用房金额合计：</span>
              <span class="text-red-500">
                ￥{{ houseCase.actRoomsCostTotal || 0 }}
              </span>
            </span>
          </Table>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import Table from './case/Table'
import InputCell from './case/InputCell'
import DatePickerCell from './case/DatePickerCell'

const roomItme = {
  dateTime: '',
  id: '',
  mainSingleNum: '',
  mainSinglePrice: '',
  mainStandardNum: '',
  mainStandardPrice: '',
  pid: '',
  qjStandardNum: '',
  qjStandardPrice: '',
  sundry: ''
}

export default {
  name: 'HouseCase',
  components: { Table, InputCell, DatePickerCell },
  props: {
    houseCase: {
      type: Object,
      required: true
    },
    showAct: {
      type: Boolean
    },
    status: {
      type: Boolean
    },
    isManageWrite: {
      type: Boolean
    }
  },
  data() {
    return {
      appColumns: [
        {
          title: '申请用房',
          children: [
            {
              title: () => (
                <span>
                  <span class="mr-1">日期</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'dateTime',
              scopedSlots: {
                customRender: 'datePicker'
              }
            },
            {
              title: '主楼单间',
              children: [
                {
                  title: () => (
                    <span>
                      <span class="mr-1">单价</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'mainSinglePrice',
                  calc: this.calcRoomsCostTotal.bind(this, 'app'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                },
                {
                  title: () => (
                    <span>
                      <span class="mr-1">数量</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'mainSingleNum',
                  calc: this.calcRoomsCostTotal.bind(this, 'app'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                }
              ]
            },
            {
              title: '主楼标间',
              children: [
                {
                  title: () => (
                    <span>
                      <span class="mr-1">单价</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'mainStandardPrice',
                  calc: this.calcRoomsCostTotal.bind(this, 'app'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                },
                {
                  title: () => (
                    <span>
                      <span class="mr-1">数量</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'mainStandardNum',
                  calc: this.calcRoomsCostTotal.bind(this, 'app'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                }
              ]
            },
            {
              title: '清江楼标',
              children: [
                {
                  title: () => (
                    <span>
                      <span class="mr-1">单价</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'qjStandardPrice',
                  calc: this.calcRoomsCostTotal.bind(this, 'app'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                },
                {
                  title: () => (
                    <span>
                      <span class="mr-1">数量</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'qjStandardNum',
                  calc: this.calcRoomsCostTotal.bind(this, 'app'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                }
              ]
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">杂项收费</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'sundry',
              calc: this.calcRoomsCostTotal.bind(this, 'app'),
              scopedSlots: {
                customRender: 'input'
              }
            }
          ]
        }
      ],
      actColumns: [
        {
          title: '实际用房',
          children: [
            {
              title: () => (
                <span>
                  <span class="mr-1">日期</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'dateTime',
              scopedSlots: {
                customRender: 'datePicker'
              }
            },
            {
              title: '主楼单间',
              children: [
                {
                  title: () => (
                    <span>
                      <span class="mr-1">单价</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'mainSinglePrice',
                  calc: this.calcRoomsCostTotal.bind(this, 'act'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                },
                {
                  title: () => (
                    <span>
                      <span class="mr-1">数量</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'mainSingleNum',
                  calc: this.calcRoomsCostTotal.bind(this, 'act'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                }
              ]
            },
            {
              title: '主楼标间',
              children: [
                {
                  title: () => (
                    <span>
                      <span class="mr-1">单价</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'mainStandardPrice',
                  calc: this.calcRoomsCostTotal.bind(this, 'act'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                },
                {
                  title: () => (
                    <span>
                      <span class="mr-1">数量</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'mainStandardNum',
                  calc: this.calcRoomsCostTotal.bind(this, 'act'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                }
              ]
            },
            {
              title: '清江楼标',
              children: [
                {
                  title: () => (
                    <span>
                      <span class="mr-1">单价</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'qjStandardPrice',
                  calc: this.calcRoomsCostTotal.bind(this, 'act'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                },
                {
                  title: () => (
                    <span>
                      <span class="mr-1">数量</span>
                      <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                    </span>
                  ),
                  dataIndex: 'qjStandardNum',
                  calc: this.calcRoomsCostTotal.bind(this, 'act'),
                  scopedSlots: {
                    customRender: 'input'
                  }
                }
              ]
            },
            {
              title: () => (
                <span>
                  <span class="mr-1">杂项收费</span>
                  <sup style="color:red;top:-0.3em;font-size:100%">*</sup>
                </span>
              ),
              dataIndex: 'sundry',
              calc: this.calcRoomsCostTotal.bind(this, 'act'),
              scopedSlots: {
                customRender: 'input'
              }
            }
          ]
        }
      ],
      roomItme
    }
  },

  computed: {
    isAppDisabled() {
      return this.status
    },

    isActDisabled() {
      return !this.isManageWrite && this.status
    }
  },

  methods: {
    calcRoomsCostTotal(type) {
      const TotalStr = type === 'app' ? 'roomsBudget' : 'actRoomsCostTotal'
      const roomsInfos = this.houseCase[`${type}RoomsInfos`]

      if (roomsInfos && roomsInfos.length) {
        this.houseCase[TotalStr] = roomsInfos.reduce((prev, current) => {
          return this.$np.plus(prev || 0, this.rowTotal(current))
        }, 0)

        type === 'act' && this.$emit('calcActualCost')
        return
      }

      this.houseCase[TotalStr] = 0
      type === 'act' && this.$emit('calcActualCost')
    },

    rowTotal(row) {
      const {
        mainSinglePrice,
        mainSingleNum,
        mainStandardPrice,
        mainStandardNum,
        qjStandardPrice,
        qjStandardNum,
        sundry
      } = row

      const mainSingleTotal = this.$np.times(
        mainSinglePrice || 0,
        mainSingleNum || 0
      )

      const mainStandardTotal = this.$np.times(
        mainStandardPrice || 0,
        mainStandardNum || 0
      )

      const qjStandardTotal = this.$np.times(
        qjStandardPrice || 0,
        qjStandardNum || 0
      )

      const total = this.$np.plus(
        mainSingleTotal,
        mainStandardTotal,
        qjStandardTotal,
        sundry || 0
      )

      return total
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
