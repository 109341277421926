import { getParentDeptsById } from '@/api/sysadmin/depts/depts'

export default {
  data() {
    return {
      formUserList: [
        /* eslint-disable prettier/prettier */
        { label: '申请人', prop: 'applicantName', disabled: true },
        { label: '岗位', prop: 'postName', disabled: true },
        { label: '联系方式', prop: 'contact', disabled: () => this.getCommonDisabled },
        { label: '申请日期', prop: 'appdate', disabled: true },
        { label: '大区', prop: 'region', disabled: true },
        { label: '省办', prop: 'province', disabled: true }
      ],
    }
  },

  async created() {
    if (this.curType === 'add') {
      const depts = this.$store.getters.depts // 部门
      this.form.applicantId = this.$store.getters.uid
      this.form.applicantName = this.$store.getters.username
      this.form.contact = this.$store.getters.contactinfo
      this.form.postName = this.$store.getters.vnote
      this.form.appdate = this.$date().format('YYYY-MM-DD')
      await this.getParentDept(depts[0].did)
    }
  },

  methods: {
    /**
     * 获取大区、省办和地办的三级结构
     * @param {String} did 最后一级组织的主键
     */
    getParentDept(did) {
      getParentDeptsById(did).then(res => {
        const [regionDTO, provinceDTO] = res

        if (regionDTO) {
          this.form.regionId = regionDTO.did
          this.form.region = regionDTO.deptname
        }

        if (provinceDTO) {
          this.form.provinceId = provinceDTO.did
          this.form.province = provinceDTO.deptname
        }
      })
    },
  }
}
