<template>
  <div class="text-center overflow-hidden">
    <div class="mb-1 mt-4 text-base">
      {{ form.outerCompanyName }}
    </div>
    <div class="my-4 text-base">发货申请单</div>
    <a-row class="px-12">
      <a-col
        v-for="{ title, prop, value, span } in columns"
        :key="prop"
        :span="span"
        class="flex"
      >
        <label v-if="title">{{ title }}</label>
        <span class="flex-1" :class="[title ? 'justify-center' : 'text-left']">
          {{
            prop
              ? prop === 'vbilldate'
                ? $date(form[prop]).format('YYYY年MM月DD日')
                : form[prop]
              : value()
          }}
        </span>
      </a-col>
    </a-row>

    <div class="px-12 mt-2">
      <table class="w-full">
        <tr>
          <th v-for="tiele in thTitles" :key="tiele">{{ tiele }}</th>
        </tr>
        <tr v-for="item in results" :key="item.bid">
          <td>{{ item.idx }}</td>
          <td>{{ item.materialName }}</td>
          <td>{{ item.materialSpec }}</td>
          <td>{{ item.unit }}</td>
          <td>{{ item.num }}</td>
          <td>{{ item.unitPrice }}</td>
          <td>{{ item.amount }}</td>
          <td v-if="item.idx === 1" :rowspan="`${results.length}`">
            {{ form.note }}
          </td>
        </tr>
        <tr>
          <td>合计</td>
          <td colspan="4">{{ upperAmountTotal }}</td>
          <td></td>
          <td>{{ amountTotal }}</td>
          <td></td>
        </tr>
      </table>
      <div class="my-16 text-left">收货资料</div>

      <a-row :gutter="[0, 48]" class="text-left">
        <a-col
          v-for="({ title, text, prop, value }, index) in infos"
          :key="index"
          :span="index % 2 ? 8 : 16"
        >
          <div class="flex justify-between" v-if="title">
            <label
              class="inline-block"
              :style="{ width: index % 2 ? '96px' : '160px' }"
            >
              <div
                :style="{
                  width: index % 2 ? '54px' : '76px',
                  textAlignLast: 'justify',
                  display: 'inline-block'
                }"
              >
                {{ title }}
              </div>
            </label>
            <div class="flex-1 border-b border-black relative">
              <div class="absolute bottom-0 w-full text-center">
                {{ prop ? form[prop] : value ? value() : '' }}
              </div>
            </div>
          </div>
          <div class="text-center" v-else>{{ text }}</div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { capitalAmount } from '@/utils/util'

export default {
  name: 'GoodsPrint',
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    datas: {
      type: Array,
      default: () => []
    },
    tel: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      amountTotal: 0, // 金额总计
      upperAmountTotal: '', // 金额总计大写

      results: [],

      columns: [
        { title: '客户', prop: 'creatorName', span: 6 },
        { value: () => this.tel, span: 10 },
        { title: '申请日期', prop: 'vbilldate', span: 8 }
      ],

      infos: [
        { title: '收货单位', prop: 'busiCompName' },
        { title: '收货人', prop: 'consignee' },
        { text: '(须与开票单位一致)' },
        { title: '电话', prop: 'consigneePhone' },
        { title: '收货地址', prop: 'deliveryAddr' },
        { title: '邮编', prop: '' },
        { title: '发货申请人', prop: 'creatorName' },
        { title: '联系电话', value: () => this.tel },
        { title: '制单人', prop: '' },
        { title: '发货审核', prop: '' },
        { title: '业务总监批准', prop: '' },
        { title: '财务批准', prop: '' },
        { title: '总经理批准', prop: '' }
      ],

      thTitles: ['序号', '品名', '规格', '单位', '数量', '单价', '金额', '备注']
    }
  },

  watch: {
    datas(val) {
      if (!val || !val.length) return

      this.amountTotal = 0

      for (let index = 0; index < this.datas.length; index++) {
        this.results.push({ idx: index + 1, note: '', ...this.datas[index] })
        this.amountTotal = this.$np.plus(
          this.amountTotal,
          Number(this.datas[index].amount || '0')
        )
      }

      this.upperAmountTotal = `RMB${capitalAmount(this.amountTotal)}`
    }
  }
}
</script>

<style lang="less" scoped>
table {
  border-width: 4px;
  border-style: double;
  border-color: black;
  /* 消除单元格之间的空隙 */
  border-collapse: collapse;
}

th,
td {
  font-size: 12px;
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  text-align: center;
  padding: 8px;
}

label::after {
  content: ':';
  margin: 0 4px;
}
</style>
