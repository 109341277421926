import request from '@/utils/request'

/**
 * 保存活动申请变更申请单数据
 * @param {Object} data
 */
export function saveActivityModify(data) {
  return request({
    method: 'post',
    url: 'activityAppModify',
    data: data
  })
}

/**
 * 根据id查询活动申请变更申请单
 */
export function getActivityModifyById(id) {
  return request({
    method: 'get',
    url: `activityAppModify/${id}`
  })
}

/**
 * 查询所有活动申请变更申请单
 */
export function getActivityModifyList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    data: conditions,
    url: `activityAppModify/${pageindex}/${pagenum}`
  })
}

/**
 * 删除活动申请的表单
 * @param {String} pid 活动申请的表单id
 */
export function delActivityModify(id) {
  return request({
    method: 'get',
    params: { id },
    url: `activityAppModify/del`
  })
}

/**
 * 更新活动申请变更申请单数据
 * @param {Object} data
 */
export function updateActivityModify(id, data) {
  return request({
    method: 'post',
    url: `activityAppModify/${id}`,
    data: data
  })
}

/**
 * 更新是否有活动申请变更申请单
 * @param {Object} data
 */
export function checkActivityModify(id) {
  return request({
    method: 'get',
    url: `activityAppModify/checkInProcessByActivityAppId`,
    params: { activityAppId: id }
  })
}

/**
 * 提交活动申请变更单数据
 * @param {Object} data
 */
export function submitActivityModify(data) {
  return request({
    method: 'post',
    url: `oaflow/submitActivityAppModify`,
    data: data
  })
}
