var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-4",staticStyle:{"padding-left":"32px"}},[_c('a-row',{staticClass:"text-right"},[_c('a-button',{staticStyle:{"margin-right":"6%"},on:{"click":_vm.print}},[_vm._v("打印")])],1),_c('a-form',{attrs:{"size":"small","laba-width":"90px","laba-position":"right","layout":"vertical"}},[_c('a-collapse',{attrs:{"bordered":false},scopedSlots:_vm._u([{key:"expandIcon",fn:function(props){return [_c('a-icon',{attrs:{"type":"caret-right","rotate":props.isActive ? 90 : 0}})]}}]),model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_vm._l((_vm.baseMsg),function(ref){
var title = ref.title;
var active = ref.active;
var children = ref.children;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},[_vm._l((children),function(ref){
var label = ref.label;
var value = ref.value;
var type = ref.type;
var options = ref.options;
var show = ref.show;
return [(!show || show(_vm.formData))?_c('a-col',{key:label,attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":label,"prop":value,"label-col":_vm.itemlay.labelCol,"wrapper-col":_vm.itemlay.wrapperCol}},[(type === 'select')?_c('a-select',{attrs:{"disabled":true},model:{value:(_vm.formData[value]),callback:function ($$v) {_vm.$set(_vm.formData, value, $$v)},expression:"formData[value]"}},_vm._l((options),function(ref){
var value = ref.value;
var text = ref.text;
return _c('a-select-option',{key:value,attrs:{"value":value}},[_vm._v(" "+_vm._s(text)+" ")])}),1):_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.formData[value]),callback:function ($$v) {_vm.$set(_vm.formData, value, $$v)},expression:"formData[value]"}})],1)],1):_vm._e()]})],2)}),_vm._l((_vm.tableMsg),function(ref){
var title = ref.title;
var active = ref.active;
var dataList = ref.dataList;
var columns = ref.columns;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},[_c('a-table',{attrs:{"rowKey":"srcBillno","bordered":"","dataSource":_vm.formData[dataList],"columns":columns,"pagination":false,"scroll":{ x: 1920 },"components":_vm.components,"rowClassName":function (record, index) {
                return index % 2 === 0 ? 'trBgClass' : ''
              }}})],1)})],2),(_vm.formData.flowInfoDTO)?_c('post-script',{attrs:{"billType":"PARTNER_AGREE_REFUND","pid":_vm.formData.id,"uid":_vm.formData.creator}}):_vm._e(),(_vm.formData.flowInfoDTO)?_c('process',{attrs:{"summaryid":_vm.formData.flowInfoDTO.summaryid,"nextapproveman":_vm.formData.flowInfoDTO.nextapproveman,"curApprover":_vm.formData.flowInfoDTO.curApprover}}):_vm._e(),_c('comment',{attrs:{"billId":_vm.formData.id},on:{"list":_vm.getList}})],1),_c('print-wrapper',{directives:[{name:"show",rawName:"v-show",value:(_vm.printVisible),expression:"printVisible"}],ref:"printWrapper"},[_c('print-form',{attrs:{"form":_vm.formData,"datas":_vm.datas,"creator":_vm.creator,"curAuditor":_vm.curAuditor,"auditTime":_vm.auditTime,"title":"红票折让申请表"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }