<!-- 基本模板片段 -->
<template>
  <a-layout>
    <a-layout-content>
      <transition name="fade-transform" mode="out-in">
        <router-view></router-view>
      </transition>
    </a-layout-content>
  </a-layout>
</template>
<script>
export default {
  name: 'User',
  data() {
    return {}
  },
  created() {},
  methods: {
    // action: 切换navtab
    changeTab() {}
  }
}
</script>
<style lang="less" scoped>
.tab {
  height: 36px;
  line-height: 36px;
  background: #fff;
  .btn {
    width: 100%;
    height: 100%;
    color: #333;
    float: left;
    text-align: center;
    cursor: pointer;
    &.active {
      color: #fff;
      background: #99c8f7;
      // border-bottom: 1px solid #53a8ff;
    }
  }
}
.fade {
  background: #fff;
}
</style>
