import { getParentDeptsById } from '@/api/sysadmin/depts/depts'

export default {
  data() {
    return {
      formUserList: [
        /* eslint-disable prettier/prettier */
        { label: '申请人', prop: 'applicantName', disabled: true },
        { label: '岗位', prop: 'post', disabled: true },
        { label: '联系方式', prop: 'contact', disabled: () => this.getCommonDisabled },
        { label: '申请日期', prop: 'appdate', disabled: true },
        { label: '大区', prop: 'regionName', disabled: true },
        { label: '省办', prop: 'provinceName', disabled: true },
        { label: '地办', prop: 'localityName', disabled: true },
        { label: '部门', prop: 'applicantDepartment', disabled: () => this.getCommonDisabled, type: 'select', options: []},
      ],
    }
  },

  async created() {
    if (this.curType === 'add') {
      const depts = this.$store.getters.depts // 部门
      this.form.applicant = this.$store.getters.uid
      this.form.applicantName = this.$store.getters.username
      this.form.contact = this.$store.getters.contactinfo
      this.form.post = this.$store.getters.vnote
      this.form.applicantDepartment = depts[0].did
      this.form.appdate = this.$date().format('YYYY-MM-DD')
      this.formUserList[7].options = this.changeDeptProps(depts)
      await this.getParentDept(depts[0].did)
    }
  },

  methods: {
    changeDeptProps(depts) {
      return depts.map(item => ({ text: item.deptname, value: item.did }))
    },

    /**
     * 获取大区、省办和地办的三级结构
     * @param {String} did 最后一级组织的主键
     */
    getParentDept(did) {
      getParentDeptsById(did).then(res => {
        const [regionDTO, provinceDTO, localityDTO] = res
        this.form.regionDTO = regionDTO
        this.form.provinceDTO = provinceDTO
        this.form.localityDTO = localityDTO

        if (regionDTO) {
          this.form.region = regionDTO.did
          this.form.regionName = regionDTO.deptname
        }

        if (provinceDTO) {
          this.form.province = provinceDTO.did
          this.form.provinceName = provinceDTO.deptname
        }

        if (localityDTO) {
          this.form.locality = localityDTO.did
          this.form.localityName = localityDTO.deptname
        }
      })
    },

    onUserChange(value, item) {
      // 费用承担部门改变
      if (item.prop === 'applicantDepartment') {
        this.form.region = ''
        this.form.regionName = ''
        this.form.province = ''
        this.form.provinceName = ''
        this.form.locality = ''
        this.form.localityName = ''
        this.getParentDept(value)
      }
    }
  }
}
