import Vue from 'vue'
import 'vxe-table/lib/style.css'

import {
  VXETable,
  // 表格
  Icon,
  Table,
  Header,
  Footer,
  Column
} from 'vxe-table'

Vue.use(Table).use(Header).use(Column).use(Icon).use(Footer)

VXETable.setup({
  TABLE_SORT_ASC: 'vxe-icon--caret-top',
  TABLE_SORT_DESC: 'vxe-icon--caret-bottom'
})
