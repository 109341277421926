<template>
  <div class="text-xs">
    <business :pid="pid" @status="nowStatus($event)"></business>
  </div>
</template>

<script>
import Business from './business/BUSINESS'

export default {
  name: 'BUSINESSBill',
  components: {
    Business
  },
  props: {
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
