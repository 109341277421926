<!-- 销售模块 -->
<template>
  <!-- 内容区域 -->
  <div class="h-full">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="['Partner', 'Nonacademic', 'Business']">
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'sale',
  data() {
    return {}
  }
}
</script>
