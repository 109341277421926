<template>
  <div>
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel header="单据信息" key="1" class="customStyle">
          <div class="ml-2 mt-3 flex flex-wrap justify-start">
            <div class="form-item">
              <span class="form-label">支款余额</span>
              <a-input
                class="form-input"
                v-model="rewriteCjk.ajkye"
                :disabled="true"
              />
            </div>
            <div class="form-item">
              <span class="form-label">冲支款金额</span>
              <a-input
                class="form-input"
                v-model="rewriteCjk.acjkTotal"
                :disabled="true"
              />
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivateCjk',
  props: {
    rewriteCjk: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.form-item {
  margin-bottom: 24px;
  width: 33.33%;
  display: flex;
  flex-direction: column;
  .form-label {
    padding-bottom: 8px;

    font-weight: 400;
    color: rgba(102, 102, 102, 1);
  }
  .form-input {
    width: 83.3%;
  }
  &:nth-last-child(1) {
    margin-bottom: 0px;
  }
  &:nth-last-child(2) {
    margin-bottom: 0px;
  }
  &:nth-last-child(3) {
    margin-bottom: 0px;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
