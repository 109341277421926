var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-2 mt-3"},[_c('a-table',{ref:"table",attrs:{"rowKey":"id","columns":_vm.columns,"data-source":_vm.data,"pagination":false,"bordered":"","size":"small","scroll":{ y: 240 },"customRow":function (record, index) {
        return {
          on: {
            click: function () {
              _vm.chooseRowRecord(record, index)
            }
          }
        }
      },"rowClassName":function (record) {
        return record.id === _vm.chooseRecord.id ? 'choose-active' : ''
      }},scopedSlots:_vm._u([{key:"input",fn:function(text, record, index, column){return [_vm._t("inputCell",null,{"record":record,"column":column})]}},{key:"datePicker",fn:function(text, record, index, column){return [_vm._t("datePickerCell",null,{"record":record,"column":column})]}},{key:"select",fn:function(text, record, index, column){return [_vm._t("selectCell",null,{"record":record,"column":column})]}}],null,true)}),_c('div',{staticClass:"mt-4"},[(!_vm.isDisabled)?_c('div',{staticClass:"inline-block"},[_c('a-button',{staticClass:"border-blue-600 text-blue-600 hover:text-blue-300 hover:border-blue-300 focus:border-blue-600 focus:text-blue-600",attrs:{"icon":"plus-circle"},on:{"click":_vm.addRecord}},[_vm._v(" 添加一行 ")]),_c('a-button',{staticClass:"ml-2 border-red-500 text-red-500 hover:text-red-300 hover:border-red-300 focus:border-red-500 focus:text-red-500",attrs:{"icon":"delete"},on:{"click":function($event){return _vm.delRecord(_vm.chooseIndex)}}},[_vm._v(" 删除一行 ")])],1):_vm._e(),_c('div',{staticClass:"inline-block float-right",staticStyle:{"line-height":"32px"}},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }