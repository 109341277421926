import request from '@/utils/request'
import { validateExcelDoc, downLoadFile } from '@/utils/exportExcel'

/********产品模块api */
/**
 * 查询所有产品信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} data
 */
export function getProductList(pageindex, pagenum, data = []) {
  return request({
    url: `/product/${pageindex}/${pagenum}`,
    method: 'post',
    data: data
  })
}

/**
 * 选择框获取产品列表
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Integer} searchStr
 */
export function getChooseProductList(pageindex, pagenum, searchStr) {
  return request({
    url: `/product/chooseProductList`,
    method: 'post',
    data: {
      pageindex,
      pagenum,
      search: searchStr
    }
  })
}

/**
 * 根据id查询产品信息
 * @param {String} id
 */
export function getProductById(id) {
  return request({
    url: `/product/${id}`,
    method: 'get'
  })
}

/**
 * 新增产品信息
 * @param {Object} data 新增产品信息
 */
export function getAddProduct(data) {
  return request({
    url: `/product`,
    method: 'post',
    data: data
  })
}
/**
 * 修改产品信息
 * @param {Object} data 修改的产品信息
 */
export function getSaveProduct(data) {
  return request({
    url: `/product`,
    method: 'put',
    data: data
  })
}

/**
 * 删除产品
 * @param {String} id 删除产品信息的id
 */
export function getDelProduct(id) {
  return request({
    url: `/product/${id}`,
    method: 'delete'
  })
}

/**
 * 编辑附件信息接口
 * @param {Object} data
 */
export function getEditAnnex(data) {
  return request({
    url: `product/editAnnex`,
    method: 'post',
    data: data
  })
}

/**
 * 导出产品信息
 * @param {Array} data 导出的条件
 */
export function getExportProduct(data = []) {
  return request({
    url: `/product/exportExcel`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `产品线信息`)
  })
}

/********************产品关键信息 api*/
/**
 * 查询所有产品关键信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} data
 */
export function getProductInfoList(pageindex, pagenum, data = []) {
  return request({
    url: `/productKeyInfo/${pageindex}/${pagenum}`,
    method: 'post',
    data: data
  })
}

/**
 * 根据id查询产品关键信息
 * @param {String} id
 */
export function getProductInfoById(id) {
  return request({
    url: `/productKeyInfo/${id}`,
    method: 'get'
  })
}

/**
 * 新增产品信息
 * @param {Object} data 新增产品关键信息
 */
export function getAddProductInfo(data) {
  return request({
    url: `/productKeyInfo`,
    method: 'post',
    data: data
  })
}
/**
 * 修改产品信息
 * @param {Object} data 修改的产品关键信息
 */
export function getSaveProductInfo(data) {
  return request({
    url: `/productKeyInfo`,
    method: 'put',
    data: data
  })
}

/**
 * 删除产品
 * @param {String} id 删除产品关键信息的id
 */
export function getDelProductInfo(id) {
  return request({
    url: `/productKeyInfo/${id}`,
    method: 'delete'
  })
}

/**
 * 导出产品关键信息
 * @param {Array} data 导出的条件
 */
export function getExportProductInfo(data = []) {
  return request({
    url: `/productKeyInfo/exportExcel`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `产品关键信息`)
  })
}

/********************剂型管理 api*/
/**
 * 查询所有剂型
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} data
 */
export function getDrugformList(pageindex, pagenum, data = []) {
  return request({
    url: `/drugForm/${pageindex}/${pagenum}`,
    method: 'post',
    data: data
  })
}

/**
 * 根据id查询剂型
 * @param {String} id
 */
export function getDrugformById(id) {
  return request({
    url: `/drugForm/${id}`,
    method: 'get'
  })
}

/**
 * 新增剂型
 * @param {Object} data 新增剂型
 */
export function getAddDrugform(data) {
  return request({
    url: `/drugForm`,
    method: 'post',
    data: data
  })
}
/**
 * 修改剂型
 * @param {Object} data 修改的剂型信息
 */
export function getSaveDrugform(data) {
  return request({
    url: `/drugForm/${data.id}`,
    method: 'put',
    data: data
  })
}

/**
 * 删除剂型
 * @param {String} id 剂型的id
 */
export function getDelDrugform(id) {
  return request({
    url: `/drugForm/${id}`,
    method: 'delete'
  })
}

/**
 * 从nc同步产品信息
 * @returns
 */
export function getSyncDrugform() {
  return request({
    url: `/drugForm/sync`,
    method: 'post'
  })
}

/**
 * 导出剂型信息
 * @param {Array} data 导出的条件
 */
export function getExportDrugform(data = []) {
  return request({
    url: `/drugForm/exportExcel`,
    method: 'post',
    data: data,
    responseType: 'arraybuffer'
  }).then(async res => {
    let data
    try {
      data = await validateExcelDoc(res)
    } catch ({ type, data }) {
      switch (type) {
        case 'TypeJson':
          return Promise.reject(data.resultMsg)
        default:
          return Promise.reject('导出失败')
      }
    }

    downLoadFile(data, 'excel', `产品管理信息`)
  })
}
