<template>
  <a-modal
    title="选择陪同人员"
    width="1000px"
    class="person-select-modal"
    centered
    destroyOnClose
    v-model="visible"
    @ok="handleOk"
  >
    <div class="w-full">
      <div class="w-full my-3">
        <a-button @click="prev" :disabled="!prePage">上一页</a-button>
        <a-button class="ml-2" @click="next" :disabled="!nextPage">
          下一页
        </a-button>
        <div class="search float-right">
          <a-input-search
            v-model="searchText"
            placeholder="按名称搜索"
            @search="onSearch"
            enterButton="搜索"
            class="w-72"
          />
        </div>
      </div>
      <a-table
        rowKey="personId"
        bordered
        :pagination="false"
        :columns="columns"
        :dataSource="accList"
        :loading="loading"
        @change="getAccPersonList"
        :rowSelection="{
          onChange: onSelectChange,
          type: 'radio',
          selectedRowKeys: selectedRowKeys
        }"
        :rowClassName="
          (record, index) => {
            return index % 2 === 1 ? 'trBgClass' : ''
          }
        "
        :customRow="
          record => {
            return {
              on: {
                click: () => {
                  sclick(record)
                }
              }
            }
          }
        "
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column
          }"
          class="custom-filter-dropdown"
        >
          <a-input
            v-ant-ref="c => (searchInput = c)"
            placeholder="查询"
            :value="selectedKeys[0]"
            @change="
              e => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="() => handleSearch(selectedKeys, confirm, column)"
            style="width: 180px; margin-bottom: 8px; display: block"
          />
          <a-button
            type="primary"
            @click="() => handleSearch(selectedKeys, confirm, column)"
            icon="search"
            style="width: 80px; margin-right: 10px"
          >
            查询
          </a-button>
          <a-button
            @click="() => handleReset(clearFilters, column)"
            style="width: 80px"
          >
            重置
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        />
      </a-table>
    </div>
  </a-modal>
</template>

<script>
import { getAccompanyPersonList } from '@/api/acdemicActive/acdemic-application'

export default {
  name: 'ChooseAccompanyModal',
  props: {
    //已选择的讲者信息
    accPersonList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      current: 1, //当前页
      pageSize: 10, //每页显示的数据
      nextPage: true, //是否有上一页
      prePage: false, //是否有下一页
      map: [], //模糊查询的条件
      accList: [],
      accIds: '',
      searchText: '',
      selectedRowKeys: [], //选择的行id
      columns: [
        {
          title: '编号',
          dataIndex: 'personCode',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '名称',
          dataIndex: 'personName',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '所属大区',
          dataIndex: 'areaName',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '所属省区',
          dataIndex: 'provName',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        },
        {
          title: '岗位',
          dataIndex: 'postName',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          }
        }
      ]
    }
  },
  created() {
    this.getAccPersonList()
  },

  methods: {
    // 按条件查询
    handleSearch(selectedKeys, confirm, column) {
      if (selectedKeys[0]) {
        this.map = [
          ...this.map,
          {
            column: column.dataIndex,
            opt: 'like',
            value: selectedKeys[0]
          }
        ]
      } else {
        this.map = this.map.filter(item => item.column !== column.dataIndex)
      }

      confirm()
    },

    // 重置
    handleReset(clearFilters, column) {
      this.map = this.map.filter(item => item.column !== column.dataIndex)
      clearFilters()
    },

    // 选择单个讲者的信息
    onSelectChange(selectedRowKeys) {
      for (let accItem of this.accPersonList) {
        if (!accItem.personId) continue
        if (selectedRowKeys[0] === accItem.personId) {
          this.$message.error('该人员已在陪同信息列表中，请选择其他人员')
          return
        }
      }
      this.selectedRowKeys = selectedRowKeys
      this.accIds = selectedRowKeys[0]
    },

    // 确定
    handleOk() {
      this.visible = false
      const person = this.accList.find(item => this.accIds === item.personId)
      if (person) {
        // 解决model闪烁的问题
        setTimeout(() => this.$emit('chooseAccPerson', person), 500)
      }
    },

    /**
     * 选择的单击事件（直接选择，不关闭弹框）
     * @param {Object} item 自选的讲者信息
     */
    sclick(item) {
      const accPerson = []
      accPerson.push(item.personId)
      this.onSelectChange(accPerson)
    },

    // 按名字搜索
    onSearch(value) {
      if (value === '') {
        this.map = []
      } else {
        this.map = [
          {
            column: 'personName',
            opt: 'like',
            value: value
          }
        ]
      }
      this.current = 1
      this.getAccPersonList()
    },

    // 上一页
    prev() {
      this.current = this.current - 1
      this.getAccPersonList()
    },

    // 下一页
    next() {
      this.current = this.current + 1
      this.getAccPersonList()
    },

    // 获取讲者的信息
    getAccPersonList(pagination) {
      if (pagination) this.current = 1
      this.loading = true
      getAccompanyPersonList(this.current, this.pageSize, this.map).then(
        response => {
          this.current = response.currentPage
          this.prePage = response.prePage
          this.nextPage = response.nextPage
          this.accList = response.datas
          this.loading = false
        }
      )
    }
  }
}
</script>

<style lang="less">
.person-select-modal .ant-modal-body {
  padding: 8px 16px;
}
</style>
