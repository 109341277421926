<template>
  <div class="question-content">
    <p class="question-item">1、请选择此一生所关联的产品对应的产品线</p>
    <p class="answer-item">{{ productLine }}</p>

    <div v-for="(item, index) in questionList" :key="index">
      <p class="question-item">{{ index + 2 }}、{{ item.question }}</p>
      <p class="answer-item">{{ item.answer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  props: {
    productLine: {
      type: String,
      required: true
    },
    questionList: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="less" scoped>
.question-item {
  margin: 12px 0;
}
.answer-item {
  padding-left: 24px;
}
</style>
