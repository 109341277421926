<!-- 仿制药管理模块 -->
<template>
  <!-- 内容区域 -->
  <div>
    <transition name="fade-transform" mode="out-in">
      <keep-alive
        :include="[
          'Inventory',
          'ZG',
          'Flow',
          'Alloc',
          'Chnl',
          'Digit',
          'Fee',
          'Settle',
          'Chnlls'
        ]"
      >
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'generic'
}
</script>
