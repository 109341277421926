<template>
  <a-form class="pl-4" labelAlign="left">
    <div class="border-b py-4 text-sm font-semibold">
      <a-icon type="user" />
      <span class="ml-2">申请人信息</span>
    </div>

    <a-row class="p-4">
      <a-col :xl="6" :xxl="4" v-for="item in userForm" :key="item.prop">
        <a-form-item
          :label="item.label"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :required="!item.disabled"
        >
          <a-input
            v-model="user[item.prop]"
            :disabled="isFlow || item.disabled"
          ></a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <div class="border-b py-4 text-sm font-semibold">
      <a-icon type="project" />
      <span class="ml-2">项目信息</span>
    </div>

    <a-row class="p-4">
      <a-col v-for="item in activityForm" :xl="6" :xxl="4" :key="item.prop">
        <a-form-item
          :label="item.label"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :required="!item.disabled"
        >
          <a-select
            v-if="item.type === 'select'"
            v-model="activity[item.prop]"
            :disabled="isFlow || item.disabled"
            @change="onSelectChange($event, item.prop)"
          >
            <a-select-option
              v-for="doc in item.options"
              :key="doc.value"
              :value="doc.value"
            >
              {{ doc.text }}
            </a-select-option>
          </a-select>
          <a-input
            v-else
            v-model="activity[item.prop]"
            :disabled="isFlow || item.disabled"
            :oninput="item.validate ? oninput[item.validate] : ''"
          ></a-input>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="px-4">
      <a-col v-for="item in activityFileForm" :xl="6" :xxl="4" :key="item.prop">
        <a-form-item v-if="!item.show || item.show()">
          <div class="flex items-center">
            <span class="relative">
              <span :class="[item.notVerity ? '' : 'app-form-required']">
                {{ item.label }} :
              </span>
              <span
                class="absolute text-white cursor-pointer"
                style="right: -30px"
                @click="openFile(item)"
              >
                {{
                  activity[item.prop]
                    ? JSON.parse(activity[item.prop]).length
                    : '0'
                }}
              </span>
            </span>
            <icon-svg
              icon="icon_wenjianjia3"
              class="text-4xl ml-2 cursor-pointer"
              @click="openFile(item)"
            />
          </div>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row class="p-4">
      <a-col :span="24" v-for="item in activityTextareaForm" :key="item.prop">
        <a-form-item
          :label="item.label"
          :label-col="labelTextAreaCol"
          :wrapper-col="wrapperTextAreaCol"
          :required="item.prop !== 'note' ? !item.disabled : false"
        >
          <a-textarea
            v-model="activity[item.prop]"
            placeholder="800字内"
            :disabled="isFlow || item.disabled"
            :maxLength="800"
            :autoSize="{ minRows: 1, maxRows: 6 }"
          ></a-textarea>
        </a-form-item>
      </a-col>
    </a-row>
    <!-- 流程信息 -->
    <process
      :status="isFlow"
      :summaryid="user.firstFlowInfoDTO.summaryid"
      :nextapproveman="user.firstFlowInfoDTO.nextapproveman"
      :curApprover="user.firstFlowInfoDTO.curApprover"
    />
    <!-- 审批意见 -->
    <comment :uid="form.applicant" :billId="user.appPartId"></comment>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>
  </a-form>
</template>

<script>
// api
import { getDoc } from '@/configs/doc-config'
import { getUserInfoById } from '@/api/acdemicActive/non-meeting'
import { isDisabled } from '@/utils/util'
import { oninput } from '@/utils/validate'
import isEmpty from 'lodash/isEmpty'
// components
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'

export default {
  name: 'FirstPart',
  components: { UploadModal, PreviewModal, Process, Comment },
  props: {
    type: {
      type: String,
      default: ''
    },

    pid: {
      type: String,
      default: ''
    },

    form: Object,
    labelCol: Object,
    wrapperCol: Object,
    labelTextAreaCol: Object,
    wrapperTextAreaCol: Object
  },
  data() {
    return {
      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: '',

      projectChooseEmun: [],
      projectTypeEmun: [],

      oninput,
      user: {
        serialNumber: '', // 流水号
        appDate: '', // 申请日期
        applicant: '', // 申请人id
        applicantName: '', // 申请人名称
        vnote: '', //岗位
        contact: '', // 联系方式
        userDepartmentId: '', // 部门id
        userDepartmentName: '', // 部门名称
        appPartId: '', // 流程id
        firstFlowInfoDTO: {} // 流程信息
      },

      activity: {
        partnerName: '', // 合作商名称
        projectChoose: '', // 项目选择
        projectType: '', // 项目类型
        priceSheet: '', // 报价单
        projectBudget: '', // 项目预算
        relatedSupportAttachments: '', // 相关支持附件
        projectDescript: '', // 项目描述
        note: '' // 备注
      },

      userForm: [
        { label: '流水号', prop: 'serialNumber', disabled: true },
        { label: '申请人', prop: 'applicantName', disabled: true },
        { label: '岗位', prop: 'vnote', disabled: true },
        { label: '联系方式', prop: 'contact', disabled: false },
        { label: '申请日期', prop: 'appDate', disabled: true },
        { label: '部门', prop: 'userDepartmentName', disabled: true }
      ],

      activityForm: [
        { label: '合作商名称', prop: 'partnerName', disabled: false },
        {
          label: '项目预算',
          prop: 'projectBudget',
          disabled: false,
          validate: 'number'
        },
        {
          label: '项目选择',
          prop: 'projectChoose',
          disabled: false,
          type: 'select',
          options: []
        },
        {
          label: '项目类型',
          prop: 'projectType',
          disabled: false,
          type: 'select',
          options: []
        }
      ],

      activityFileForm: [
        { label: '报价单', prop: 'priceSheet', disabled: false },
        {
          label: '相关支持附件',
          prop: 'relatedSupportAttachments',
          disabled: false
        }
      ],

      activityTextareaForm: [
        { label: '项目描述', prop: 'projectDescript', disabled: false },
        { label: '备注', prop: 'note', disabled: false }
      ]
    }
  },

  computed: {
    isFlow() {
      const status = this.user.firstFlowInfoDTO
        ? this.user.firstFlowInfoDTO.status
        : '1'
      return isDisabled(status)
    }
  },

  async created() {
    const routeType = this.type || this.$route.query.type

    if (routeType === 'add') {
      const userInfo = await getUserInfoById(this.$store.getters.uid)

      this.user.applicant = this.$store.getters.uid
      this.user.applicantName = this.$store.getters.username
      this.user.contact = this.$store.getters.contactinfo
      this.user.vnote = this.$store.getters.vnote
      this.user.appDate = this.$date().format('YYYY-MM-DD')
      this.user.userDepartmentId = userInfo.userDepartmentId
      this.user.userDepartmentName = userInfo.userDepartmentName

      // 非会议类学术项目枚举
      getDoc('054', res => {
        this.projectChooseEmun = res
        this.activityForm[2].options = res
      })
      // 非学术类学术项目类型枚举
      getDoc('055', res => {
        this.projectTypeEmun = res
      })
    }
  },

  watch: {
    form: {
      handler(newval) {
        if (!isEmpty(newval)) {
          // 数据分发
          this.$nextTick(() => {
            this.distributeData(this.user)
            this.distributeData(this.activity)
          })

          this.$emit('comp-flow-info', newval.firstFlowInfoDTO)
          // 非会议类学术项目枚举
          getDoc('054', res => {
            this.projectChooseEmun = res
            this.activityForm[2].options = res
          })

          // 非学术类学术项目类型枚举
          getDoc('055', res => {
            this.projectTypeEmun = res
            this.activityForm[3].options = this.projectTypeEmun.filter(item =>
              item.code.startsWith(newval.projectChooseCode)
            )
          })
        }
      },
      immediate: true
    }
  },

  methods: {
    onSelectChange(value, prop) {
      if (prop === 'projectChoose') {
        const target = this.projectChooseEmun.find(item => item.value === value)
        // 更改后置空
        this.activity.projectType = ''
        // 重新修改项目类型枚举
        this.activityForm[3].options = this.projectTypeEmun.filter(item =>
          item.code.startsWith(target.code)
        )
      }
    },

    // 附件上传或预览
    openFile(item) {
      const title = item.label
      const fileProp = item.prop
      const module = this.activity

      if (this.isFlow) {
        this.previewTitle = title
        this.previewFileListInfo = module[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = module[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '相关支持附件':
          this.activity.relatedSupportAttachments = fileListInfo
          break
        case '报价单':
          this.activity.priceSheet = fileListInfo
          break
        default:
          break
      }
    },

    setBackStatus() {
      this.user.firstFlowInfoDTO.status = '5'
      this.$emit('comp-flow-info', this.user.firstFlowInfoDTO)
    },

    distributeData(module) {
      Object.keys(module).forEach(key => this.$set(module, key, this.form[key]))
    },

    verityAppForm(data) {
      const canVerityFormItem = [
        ...this.userForm,
        ...this.activityForm,
        ...this.activityTextareaForm
      ].filter(item => !item.disabled)

      canVerityFormItem.pop()

      // 项目申请校验
      for (const formItem of canVerityFormItem) {
        if (formItem.show && !formItem.show()) continue

        if (!data[formItem.prop] && data[formItem.prop] != '0') {
          this.$message.error(`${formItem.label}不能为空`)
          return false
        }
      }

      return true
    },

    mergeData(isVerity = false) {
      const data = Object.assign({}, this.form, this.user, this.activity)
      if (!isVerity) return data
      return this.verityAppForm(data) ? data : false
    }
  }
}
</script>

<style lang="less" scoped></style>
