<!-- 外部品种模块 -->
<template>
  <!-- 内容区域 -->
  <div>
    <transition name="fade-transform" mode="out-in">
      <keep-alive
        :include="['FlowBill', 'AllotBill', 'VerificationBill', 'ComponyBill']"
      >
        <router-view />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'outer'
}
</script>
