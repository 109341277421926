var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-list',{attrs:{"item-layout":"horizontal","data-source":_vm.anuceList},scopedSlots:_vm._u([{key:"renderItem",fn:function(item){return _c('a-list-item',{staticClass:"list-item",on:{"click":function($event){return _vm.handldClick(item)}}},[_c('a-list-item-meta',[_c('a',{staticClass:"font-bold",attrs:{"slot":"title"},on:{"click":function($event){$event.stopPropagation();return _vm.handldClick(item)}},slot:"title"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"anuce-content",attrs:{"slot":"description"},slot:"description"},[_vm._v(" "+_vm._s(_vm.matchReg(item.msg))+" ")]),_c('div',{staticClass:"flex justify-between mt-2",staticStyle:{"font-size":"12px"},attrs:{"slot":"description"},slot:"description"},[_c('span',{staticClass:"text-blue-300"},[_vm._v(" "+_vm._s(_vm.$date(item.creationtime).fromNow())+" ")]),_c('a-tag',{attrs:{"color":item.readSign ? '#48bb78' : '#ed8936'}},[_vm._v(" "+_vm._s(item.readSign ? '已读' : '未读')+" ")])],1)])],1)}}])},[_c('div',{style:({
        textAlign: 'center',
        marginTop: '12px',
        height: '32px',
        lineHeight: '32px'
      }),attrs:{"slot":"loadMore"},slot:"loadMore"},[(_vm.showLoadingMore)?_c('a-spin'):_vm._e(),(!_vm.showLoadingMore && !_vm.isEnd)?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onLoadMore}},[_vm._v(" 查看更多 ")]):_vm._e()],1)]),_c('a-modal',{staticStyle:{"top":"32px"},attrs:{"width":"1024px","destroyOnClose":"","title":_vm.anuce.title,"maskClosable":false,"footer":false,"bodyStyle":{
      padding: '24px',
      height: 'calc(100vh - 124px)',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"flex-1 border rounded-lg p-4",domProps:{"innerHTML":_vm._s(_vm.anuce.msg)}}),_c('div',{staticClass:"text-left mt-4"},[_c('a',{style:({ color: _vm.hasFile ? '#4587f5' : '#666' }),on:{"click":_vm.openFile}},[_c('a-icon',{attrs:{"type":"link"}}),_vm._v(" 公告附件 ")],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showMsg),expression:"showMsg"}],staticClass:"ml-4 text-xs text-orange-500"},[_c('a-icon',{attrs:{"type":"exclamation-circle"}}),_vm._v(" 未上传公告附件 ")],1)]),_c('div',{staticClass:"text-right mt-4"},[_c('span',[_vm._v("发布日期:")]),_c('span',[_vm._v(_vm._s(_vm.anuce.creationtime))])])]),_c('a-modal',{attrs:{"title":"公告附件","width":"800px","maskClosable":false,"footer":false,"bodyStyle":{
      padding: 0,
      paddingLeft: '24px',
      paddingRight: '24px'
    }},model:{value:(_vm.fileVisible),callback:function ($$v) {_vm.fileVisible=$$v},expression:"fileVisible"}},[_c('FileUpload',{attrs:{"isEdit":true,"annex":_vm.enclosures}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }