const BASE = [
  {
    title: '基本类型',
    active: 'BASE',
    children: [
      { label: '单据号', value: `vbillno` },
      { label: '业务日期', value: `vbilldate` },
      { label: '业务员', value: `clerkName` },
      { label: '业务区域', value: `areaName` },
      { label: '收款人', value: `receiver` },
      { label: '银行账号', value: `bankaccount` },
      { label: '开户行', value: `accbankName` },
      { label: '银行类别名称', value: `banktypeName` },
      { label: '开发者', value: `developerName` },
      { label: '开发者收款账号', value: `devaccount` },
      { label: '开发者开户行', value: `devaccbankName` },
      { label: '银行类别', value: `devBanktypeName` }
    ]
  }
]
const TABLE = [
  {
    title: '信息列表',
    active: 'TABLE',
    dataList: 'bodyDtos',
    columns: [
      { title: '行号', dataIndex: 'rowno', width: 48 },
      {
        title: '终端编码',
        dataIndex: 'chnlmemberStdCode',
        ellipsis: true,
        width: 120
      },
      {
        title: '终端名称',
        dataIndex: 'chnlmemberStdName',
        ellipsis: true,
        width: 120
      },
      { title: '品种编码', dataIndex: 'specCode', ellipsis: true, width: 120 },
      { title: '品种名称', dataIndex: 'specName', ellipsis: true, width: 120 },
      {
        title: '规格',
        dataIndex: 'specSpecifications',
        ellipsis: true,
        width: 120
      },
      { title: '开票价', dataIndex: 'billprice', width: 72 },
      { title: '单盒费用', dataIndex: 'priceperbox', width: 72 },
      { title: '二次议价', dataIndex: 'twiceprice', width: 72 },
      {
        title: '生效日期',
        dataIndex: 'effectiveDateStr',
        ellipsis: true,
        width: 72
      },
      {
        title: '失效日期',
        dataIndex: 'expirationDateStr',
        ellipsis: true,
        width: 72
      },
      { title: '开发者开发奖金', dataIndex: 'devbonus', width: 96 },
      { title: '业务员开发奖金', dataIndex: 'clerkbonus', width: 96 }
    ]
  }
]

export { BASE, TABLE }
