<template>
  <div>
    <div style="padding-left: 32px" class="my-4">
      <a-form
        size="small"
        laba-width="90px"
        laba-position="right"
        layout="vertical"
      >
        <a-collapse v-model="activeKey" :bordered="false">
          <template v-slot:expandIcon="props">
            <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
          </template>
          <a-collapse-panel
            class="customStyle"
            v-for="{ title, active, children } in baseMsg"
            :header="title"
            :key="active"
          >
            <a-col
              v-for="{ label, value, span } in children"
              :span="span || 6"
              :key="label"
            >
              <a-form-item
                :label="label"
                :prop="value"
                :label-col="itemlay.labelCol"
                :wrapper-col="itemlay.wrapperCol"
              >
                <a-input :disabled="true" v-model="formData[value]"></a-input>
              </a-form-item>
            </a-col>
          </a-collapse-panel>
          <a-collapse-panel
            v-for="{ title, active, dataList, columns } in tableMsg"
            :header="title"
            :key="active"
            class="customStyle"
          >
            <a-table
              rowKey="pkDigitContrB"
              bordered
              :dataSource="formData[dataList]"
              :columns="columns"
              :pagination="false"
              :scroll="{ x: 1920 }"
              :components="components"
              :rowClassName="
                (record, index) => {
                  return index % 2 === 0 ? 'trBgClass' : ''
                }
              "
            >
              <template slot="coreChain" slot-scope="text, record">
                <a-checkbox :checked="!!record.coreChain" disabled />
              </template>
            </a-table>
          </a-collapse-panel>
        </a-collapse>
        <process
          v-if="formData.flowInfoDTO"
          :summaryid="formData.flowInfoDTO.summaryid"
          :nextapproveman="formData.flowInfoDTO.nextapproveman"
          :curApprover="formData.flowInfoDTO.curApprover"
        ></process>
        <comment :billId="formData.pkDigitContrH"></comment>
      </a-form>
    </div>
  </div>
</template>

<script>
import Comment from '@/views/activity/modules/activebill/Comment'
import Process from '@/views/custmanage/modify/components/Process'
import { BASE, TABLE } from './digit-config'
import { getDigit } from '@/api/generic/digit'
import { getResizeableTitle } from '@/utils/table'
export default {
  name: 'DIGIT',
  components: { Process, Comment },
  props: {
    type: String,
    pid: String
  },
  data() {
    this.components = getResizeableTitle(TABLE[0].columns)
    return {
      formData: {},
      baseMsg: BASE,
      tableMsg: TABLE,
      activeKey: ['BASE', 'TABLE'],
      itemlay: {
        labelCol: { span: 12 },
        wrapperCol: { span: 20 }
      }
    }
  },
  created() {
    getDigit(this.pid).then(res => {
      this.formData = res
      this.$emit('status', this.formData?.flowInfoDTO?.status || '待发')
    })
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-collapse-borderless > .ant-collapse-item {
  margin: 12px 0;
  border-bottom: 0px;
}

/deep/ .ant-collapse-borderless {
  position: relative;
  background-color: #ffffff;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

/deep/.ant-table-thead {
  & > tr > th {
    padding: 8px 4px !important;
    font-size: 12px;
  }
}

/deep/.ant-table-tbody > tr > td {
  padding: 8px 4px !important;
  font-size: 12px;
}
</style>
