<template>
  <div class="m-4">
    <div class="title" v-if="showAvatar">药连锁办事处用房租赁申请详情</div>
    <a-row
      v-if="!$route.query.todo && type !== 'waitwork'"
      align="middle"
      class="text-right"
    >
      <a-button
        type="primary"
        :disabled="!passSpecialTodoAndSelf && !isSpecialTodo && isFlow"
        :loading="dataLoading"
        @click="save"
      >
        保存
      </a-button>
      <a-button
        type="primary"
        class="ml-2"
        :loading="dataLoading"
        :disabled="isFlow"
        @click="submit"
      >
        提交
      </a-button>
      <a-button
        type="danger"
        class="ml-2"
        :disabled="isFlow || !form.id"
        :loading="dataLoading"
        @click="del"
      >
        删除
      </a-button>
      <a-button
        class="ml-2"
        :loading="dataLoading"
        :disabled="backDisabled"
        @click="getBack"
      >
        撤销
      </a-button>
      <a-divider type="vertical"></a-divider>
      <a-button
        :loading="dataLoading"
        @click="$router.push({ name: 'rentHouseApp' })"
      >
        返回
      </a-button>
    </a-row>

    <base-form>
      <base-form-title title="申请人信息" icon="user" />
      <base-form-items
        :formData="form"
        :formBaseList="formUserList"
        @select-change="onUserChange"
      ></base-form-items>

      <base-form-title title="预租房基本信息" icon="home" />
      <base-form-items
        :formData="form"
        :formBaseList="formBaseList"
        :formTextAreaList="formBaseTextAreaList"
        @open-modal="openModal"
      ></base-form-items>

      <template v-if="form.rentalAddress || isSpecialTodo">
        <base-form-title title="报销凭证" icon="home" />
        <base-form-items
          :formData="form"
          :formBaseList="formReimList"
          :formFileList="formReimFileList"
          @open-file="openFile"
        ></base-form-items>
      </template>

      <!-- 流程信息 -->
      <process
        v-if="form.flowInfoDO"
        :status="isFlow"
        :summaryid="form.flowInfoDO.summaryid"
        :nextapproveman="form.flowInfoDO.nextapproveman"
        :curApprover="form.flowInfoDO.curApprover"
      />
      <!-- 审批意见 -->
      <comment :uid="form.applicant" :billId="form.id"></comment>
    </base-form>

    <!-- 附件上传 -->
    <upload-modal
      v-if="showUpload"
      :uploadName="uploadTitle"
      :uploadFilelistInfo="uploadFileListInfo"
      @close="showUpload = false"
      @hasUpload="hasUpload($event)"
    ></upload-modal>

    <!-- 附件预览 -->
    <preview-modal
      v-if="showPreview"
      :uploadName="previewTitle"
      :previewFileListInfo="previewFileListInfo"
      @close="showPreview = false"
    ></preview-modal>

    <DeptmentModal
      v-if="showModal"
      :visible="showModal"
      @choose-dept="chooseDept"
      @close="showModal = false"
    ></DeptmentModal>
  </div>
</template>

<script>
import BaseForm from '@/components/FieldSet/BaseForm'
import BaseFormTitle from '@/components/FieldSet/BaseFormTitle'
import BaseFormItems from '@/components/FieldSet/BaseFormItems'
import UploadModal from '@/components/Upload/UploadModal'
import PreviewModal from '@/components/Upload/PreviewModal'
import DeptmentModal from '@/components/Modal/DeptmentModal'
import Process from '@/components/flow/Process'
import Comment from '@/components/flow/Comment'
// api
import moment from 'moment'
import { default as cloneDeep } from 'lodash/cloneDeep'
import { isDisabled } from '@/utils/util'
import { getDoc } from '@/configs/doc-config'
import {
  saveRentHouse,
  updateRentHouse,
  delRentHouse,
  getRentHouse,
  submitRentHouse
} from '@/api/house/rent-house'
import { getDeleteFiles } from '@/api/acdemicActive/upload'
import {
  getOACancel2,
  getDeptByUid
} from '@/api/acdemicActive/acdemic-application'

// mixin
import userMixin from '../mixins/user-mixin'
import verityMixin from '../mixins/verity-mixin'

const commonOptions = [
  { text: '是', value: 'true' },
  { text: '否', value: 'false' }
]

export default {
  name: 'RentHouseForm',
  props: {
    type: {
      type: String,
      default: ''
    },
    pid: {
      type: String,
      default: ''
    }
  },
  components: {
    BaseForm,
    BaseFormTitle,
    BaseFormItems,
    UploadModal,
    PreviewModal,
    DeptmentModal,
    Process,
    Comment
  },
  mixins: [userMixin, verityMixin],
  data() {
    return {
      curType: this.type || this.$route.query.type || 'add',
      curPid: this.pid || this.$route.query.pid,
      showModal: false,
      useTypeEmun: [], // 使用类型枚举

      // 上传
      showUpload: false,
      uploadTitle: '',
      uploadFileListInfo: '',
      // 预览
      showPreview: false,
      previewTitle: '',
      previewFileListInfo: '',

      dataLoading: false, // 提交数据

      /* eslint-disable prettier/prettier */
      form: {
        appdate: '', // 申请日期
        applicant: '', // 申请人id
        applicantName: '', // 申请人名称
        post: '', //岗位
        contact: '', // 联系方式
        region: '', // 大区id
        regionName: '', // 大区名称
        province: '', // 省办id
        provinceName: '', // 省办名称
        locality: '', // 地办id
        localityName: '', // 地办名称
        applicantDepartment: '', //部门id

        applicationCode: '', // 申请单号
        useType: '', // 使用类型
        address: '', // 预租地址
        officeNum: '', // 办事处在职人数
        floorArea: '', // 租用面积（m²）
        avgRentalPrice: '', // 当地小区/写字楼均价（元/月）
        realRentalPrice: '', // 实际月租金（元/月）
        personPayRent: '', // 是否涉及员工承担
        companyRentalPrice: '', // 公司承担金（元/月）
        personRentalPrice: '', // 员工承担金（元/月）
        payType: '', // 付款方式
        refinedDecoration: '', // 是否精装修
        includeFurniture: '', // 是否已包含家具
        houseDeptId: '', // 房产所属部门
        houseDeptName: '', // 房产所属部门名称
        otherNote: '', // 房产其他信息描述
        rentalAddress: '', // 租赁地址
        tenant: '', // 承租方
        operator: '', // 承租经办人
        houseOwner: '', // 房屋产权所有人
        contractTerm: '', // 合同期限（月）
        contractStartDate: '', // 合同开始日期
        contractEndDate: '', // 合同结束时间
        rentalContractId: '', // 租赁合同
        housePropertyId: '', // 房产证复印件
        houseOwnerIDCardId: '', // 房东身份证
        
        flowInfoDO: { status: '1' } // 流程信息
      },

      formBaseList: [
        { label: '申请单号', prop: 'applicationCode', disabled: true },
        { label: '使用类型', prop: 'useType', disabled: () => this.getCommonDisabled, type: 'select', options: [] },
        { label: '预租地址', prop: 'address', disabled: () => this.getCommonDisabled },
        { label: '办事处在职人数', prop: 'officeNum', disabled: () => this.getCommonDisabled, validate: 'number' },
        { label: '租用面积（m²）', prop: 'floorArea', disabled: () => this.getCommonDisabled, validate: 'digit' },
        { label: '当地小区/写字楼均价（元/月）', prop: 'avgRentalPrice', disabled: () => this.getCommonDisabled, validate: 'digit' },
        { label: '实际月租金（元/月）', prop: 'realRentalPrice', disabled: () => this.getCommonDisabled, validate: 'digit' },
        { label: '是否涉及员工承担', prop: 'personPayRent', disabled: true, type: 'select', options: commonOptions },
        { label: '公司承担金（元/月）', show:() => this.isPersonPayRent, prop: 'companyRentalPrice', disabled: true, validate: 'digit' },
        { label: '员工承担金（元/月）', show:() => this.isPersonPayRent, prop: 'personRentalPrice', disabled: true, validate: 'digit' },
        { label: '付款方式', prop: 'payType', disabled: () => this.getCommonDisabled, type: 'select', options: [] },
        { label: '是否精装修', prop: 'refinedDecoration', disabled: () => this.getCommonDisabled, type: 'select', options: commonOptions },
        { label: '是否已包含家具', prop: 'includeFurniture', disabled: () => this.getCommonDisabled, type: 'select', options: commonOptions },
        { label: '房产所属部门', prop: 'houseDeptName', type: 'search', disabled: () => this.getCommonDisabled }
      ],

      formBaseTextAreaList: [
        { label: '房产其他信息描述（交通、房龄、大体布局等情况）', prop: 'otherNote', disabled: () => this.getCommonDisabled },
      ],

      formReimList: [
        { label: '租赁地址', prop: 'rentalAddress', disabled: () => this.getSpecialDisable },
        { label: '承租方', prop: 'tenant', disabled: () => this.getSpecialDisable },
        { label: '承租经办人', prop: 'operator', disabled: () => this.getSpecialDisable },
        { label: '房屋产权所有人', prop: 'houseOwner', disabled: () => this.getSpecialDisable },
        { label: '合同期限（月）', prop: 'contractTerm', disabled: () => this.getSpecialDisable, validate: 'number' },
        { label: '合同开始日期', prop: 'contractStartDate', type: 'date', disabled: () => this.getSpecialDisable, disabledDate: () => this.disabledStDate },
        { label: '合同结束时间', prop: 'contractEndDate', type: 'date', disabled: () => this.getSpecialDisable, disabledDate: () => this.disabledEndDate },
      ],

      formReimFileList: [
        { label: '租赁合同', disabled: true, notVerity: true, prop: 'rentalContractId' },
        { label: '房产证复印件', disabled: true, notVerity: true, prop: 'housePropertyId' },
        { label: '房东身份证', disabled: true, notVerity: true, prop: 'houseOwnerIDCardId' },
      ]
    }
  },

  computed: {
    showAvatar() {
      return this.$store.getters['protal']
    },

    // 是否在流程中(审批终止和审批结束也算在流程中)
    isFlow() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      return isDisabled(status)
    },

    // 允许回退
    backDisabled() {
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      const canBackStatus = ['0', '1', '5', '15', '98', '99']
      return canBackStatus.includes(status)
    },

    getCommonDisabled() {
      return this.isFlow || false
    },

    // 不是特殊待办
    getSpecialDisable() {
      return this.type === 'waitwork' || !this.passSpecialTodoAndSelf && (!(this.isSpecialTodo || !this.isFlow))
    },

    isSpecialTodo() {
      return this.form.flowInfoDO && this.form.flowInfoDO.nodePolicy === '特殊审批'
    },

    isPersonPayRent() {
      return this.form.personPayRent === '是'
    },
    // 经过特殊审批
    isPassSpecialTodo() {
      return !!this.form.rentalAddress
    },

    passSpecialTodoAndSelf() {
      // 经过特殊审批并且是本人，并且审批没有通过时
      const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
      const passSpecialTodoAndSelf = this.isPassSpecialTodo && this.form.applicant === this.$store.getters['uid'] && status !== '0'

      return passSpecialTodoAndSelf
    }
  },

  created() {
    getDoc('061', res => {
      this.formBaseList[1].options = res
      this.useTypeEmun = res
    })
    getDoc('062', res => (this.formBaseList[10].options = res))

    if(this.curType !== 'add') {
      getRentHouse(this.curPid).then(res => {
        // 类型转换
        res.personPayRent = String(res.personPayRent) // 是否涉及员工承担
        res.refinedDecoration = String(res.refinedDecoration) // 是否精装修
        res.includeFurniture = String(res.includeFurniture) // 是否已包含家具

        if(res.houseDeptId && res.houseDeptDO) {
          this.$set(res, 'houseDeptName', res.houseDeptDO.deptname)
        }

        // 申请人部门列表
        getDeptByUid(res.applicant).then(depts => {
          this.formUserList[7].options = this.changeDeptProps(depts)
        })

        this.form = res

        // 单据状态(向上通知)
        const status = this.form.flowInfoDO ? this.form.flowInfoDO.status : '1'
        this.$emit('status', status)
      })
    }
  },

  watch: {
    'form.useType'(nval) {
      const target = this.useTypeEmun.find(i => i.value === nval)

      if (!target) return 

      if(target.text === '办公') {
        this.form.personPayRent = '否'
        this.form.companyRentalPrice = ''
        this.form.personRentalPrice = ''
      }

      if(target.text === '住房') {
        this.form.personPayRent = '是'
      }
    },

    'form.realRentalPrice'(nval) {
      this.form.companyRentalPrice = this.$np.round(this.$np.times(nval || "0", 0.8), 2)
      this.form.personRentalPrice = this.$np.round(this.$np.times(nval || "0", 0.2), 2)
    }
  },

  methods: {
    moment,

    chooseDept(item) {
      this.form.houseDeptId = item.did 
      this.form.houseDeptName = item.deptname 
    },

    openModal(item) {
      switch (item.prop) {
        case 'houseDeptName':
          this.showModal = true
          break
        default:
          break
      }
    },

    // 附件上传或预览
    openFile(item) {
      const title = item.label
      const fileProp = item.prop

      if (!this.passSpecialTodoAndSelf && !(this.isSpecialTodo && this.type !== 'waitwork')) {
        this.previewTitle = title
        this.previewFileListInfo = this.form[fileProp]
        this.showPreview = true
      } else {
        this.uploadTitle = title
        this.uploadFileListInfo = this.form[fileProp]
        this.showUpload = true
      }
    },

    // 上传完成
    hasUpload({ uploadName, fileListInfo }) {
      switch (uploadName) {
        case '租赁合同':
          this.form.rentalContractId = fileListInfo
          break
        case '房产证复印件':
          this.form.housePropertyId = fileListInfo
          break
        case '房东身份证':
          this.form.houseOwnerIDCardId = fileListInfo
          break
        default:
          break
      }
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
     disabledStDate(time) {
      return (
        time && time >= moment(this.form.contractEndDate).endOf('day')
      )
    },

    /**
     * 结束时间大于开始时间
     * @params {moment} time
     */
    disabledEndDate(time) {
      return (
        time &&
        time <=
          moment(this.form.contractStartDate).add(-1, 'day').endOf('day')
      )
    },

    filterData() {
      const submitData = cloneDeep(this.form)

      submitData.personPayRent = Boolean(submitData.personPayRent) // 是否涉及员工承担
      submitData.refinedDecoration = Boolean(submitData.refinedDecoration) // 是否精装修
      submitData.includeFurniture = Boolean(submitData.includeFurniture) // 是否已包含家具

      return submitData
    },

    //保存
    save(callback) {
      this.saveForm(callback)
    },

    saveForm() {
      const saveApi = this.form.id ? updateRentHouse : saveRentHouse

      const submitData = this.filterData()

      if (this.verityAppForm(this.form.id ? false : 'save')) {
        this.dataLoading = true
        saveApi(submitData)
          .then(() => {
            this.delUploadFile()
            this.$message.success('保存成功')
            this.$router.replace({ name: 'rentHouseApp' })
          }).finally(() => {
            this.dataLoading = false
          })
      } 
    },

    //提交
    submit() {
      const submitData = this.filterData()

      if (this.verityAppForm(true)) {
        this.dataLoading = true
        submitRentHouse(submitData).then(() => {
          this.delUploadFile()
          this.$message.success('提交成功')
          this.$router.replace({ name: 'rentHouseApp' })
        }).finally(() => {
            this.dataLoading = false
          })
      }
    },

    //删除
    del() {
      this.$confirm({
        title: '是否要删除该药连锁办事处用房租赁申请单？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          delRentHouse(this.form.id).then(() => {
            this.$message.success('删除成功')
            this.$router.replace({ name: 'rentHouseApp' })
          })
        },
        onCancel: () => {}
      })
    },

    getBack() {
      this.$confirm({
        title: '是否要撤销该药连锁办事处用房租赁申请？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk: () => {
          getOACancel2(
            this.form.id,
            'ZFSQ',
            this.form.flowInfoDO.summaryid
          ).then(() => {
            this.$message.success('撤销成功')
            this.form.flowInfoDO.status = '5'
            this.$emit('status', '5')
          })
        },
        onCancel: () => {}
      })
    },

    //保存和更新时删除文件
    delUploadFile() {
      const delFiles = this.$store.state.uploadFile.delFiles
      if (delFiles.length !== 0) {
        const fileList = Array.from(new Set([...delFiles]))
        getDeleteFiles(fileList)
          .then(() => {})
          .catch(() => {
            this.$message.error('附件删除失效')
          })
      }
      this.$store.dispatch('uploadFile/clearFiles')
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  padding: 0 16px;
  font-size: 16px;
  font-weight: bold;
}
</style>
