<template>
  <div>
    <a-modal
      title="选择推广物料"
      width="1000px"
      class="brandre-modal"
      :visible="visible"
      :centered="true"
      :keyboard="false"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="brandre-content w-full">
        <div class="w-full my-3">
          <button class="button mr-2" @click="prev" :disabled="!prePage">
            上一页
          </button>
          <button class="button" @click="next" :disabled="!nextPage">
            下一页
          </button>
          <div class="search float-right">
            <a-input-search
              placeholder="按名称搜索"
              @search="onSearch"
              enterButton="搜索"
              class="w-72"
            />
          </div>
        </div>
        <a-table
          rowKey="brandpreId"
          class="active-table"
          :pagination="false"
          :columns="columns"
          :dataSource="brandpreList"
          :loading="loading"
          @change="getActivityBrandPre"
          :rowSelection="{
            onChange: onSelectChange,
            type: 'radio',
            selectedRowKeys: selectedRowKeys
          }"
          :rowClassName="
            (record, index) => {
              return index % 2 === 0 ? 'trBgClass' : ''
            }
          "
          :customRow="
            record => {
              return {
                on: {
                  dblclick: () => {
                    handleClick(record)
                  },
                  click: () => {
                    sclick(record)
                  }
                }
              }
            }
          "
        >
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column
            }"
            class="custom-filter-dropdown"
          >
            <a-input
              v-ant-ref="c => (searchInput = c)"
              placeholder="查询"
              :value="selectedKeys[0]"
              @change="
                e => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="() => handleSearch(selectedKeys, confirm, column)"
              style="width: 180px; margin-bottom: 8px; display: block"
            />
            <a-button
              type="primary"
              @click="() => handleSearch(selectedKeys, confirm, column)"
              icon="search"
              style="width: 80px; margin-right: 10px"
            >
              查询
            </a-button>
            <a-button
              @click="() => handleReset(clearFilters, column)"
              style="width: 80px"
            >
              重置
            </a-button>
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
        </a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getActivityBrandPre } from '@/api/acdemicActive/acdemic-application'
import Vue from 'vue'

export default {
  name: 'chooseSpeakerModal',
  props: {
    conferenceTypeName: String
  },
  data() {
    return {
      visible: true,
      loading: false,
      current: 1, //当前页
      pageSize: 20, //每页显示的数据
      nextPage: true, //是否有上一页
      prePage: false, //是否有下一页
      map: [
        {
          column: 'isEnable',
          opt: '=',
          value: true
        }
      ], //模糊查询的条件（默认查询启用的物料）
      brandpreList: [],
      brandpreIds: '',
      selectedRowKeys: [], //选择的行id
      columns: [
        {
          title: '物料编号',
          dataIndex: 'materialCode',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              })
            }
          }
        },
        {
          title: '物料名称',
          dataIndex: 'materialName',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              })
            }
          }
        },
        {
          title: '物料标识',
          dataIndex: 'materialIdentification',
          align: 'center',
          scopedSlots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'customRender'
          },
          onFilterDropdownVisibleChange: visible => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus()
              })
            }
          }
        },
        {
          title: '起订量单价',
          dataIndex: 'price',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'notes',
          align: 'center'
        }
      ]
    }
  },
  created() {
    if (this.conferenceTypeName !== '总部') {
      this.map = [
        ...this.map,
        {
          column: 'notes',
          opt: '!=',
          value: '仅总部'
        }
      ]
    }
    this.getActivityBrandPre()
  },
  methods: {
    // 按条件查询
    handleSearch(selectedKeys, confirm, column) {
      if (selectedKeys[0]) {
        this.map = [
          ...this.map,
          {
            column: column.dataIndex,
            opt: 'like',
            value: selectedKeys[0]
          }
        ]
      } else {
        this.map = this.map.filter(item => item.column !== column.dataIndex)
      }

      confirm()
      this.searchText = selectedKeys[0]
    },

    // 重置
    handleReset(clearFilters, column) {
      this.map = this.map.filter(item => item.column !== column.dataIndex)
      clearFilters()
      this.searchText = ''
    },

    // 选择单个讲者的信息
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      this.brandpreIds = selectedRowKeys[0]
    },

    // 确定
    handleOk() {
      const brandpre = this.brandpreList.find(
        item => item.brandpreId === this.brandpreIds
      )
      if (brandpre !== undefined) {
        this.$emit('chooseBrandpre', brandpre)
      }
      this.$emit('close')
    },

    /**
     * 选择的双击事件（直接选择，关闭弹框）
     * @param {Object} item 自选的物料推广
     */
    handleClick(item) {
      const brandpres = []
      brandpres.push(item.brandpreId)
      this.onSelectChange(brandpres)
      this.handleOk()
    },

    /**
     * 选择的单击事件（直接选择，不关闭弹框）
     * @param {Object} item 自选的物料推广
     */
    sclick(item) {
      const brandpres = []
      brandpres.push(item.brandpreId)
      this.onSelectChange(brandpres)
    },

    //按名字搜索
    onSearch(value) {
      if (value === '') {
        this.map = []
      } else {
        this.map = [
          {
            column: 'materialName',
            opt: 'like',
            value: value
          }
        ]
      }
      this.current = 1
      this.getActivityBrandPre()
    },

    // 上一页
    prev() {
      this.current = this.current - 1
      this.getActivityBrandPre()
    },

    // 下一页
    next() {
      this.current = this.current + 1
      this.getActivityBrandPre()
    },

    handleCancel() {
      this.$emit('close')
    },
    getActivityBrandPre(pagination) {
      if (pagination) this.current = 1
      this.loading = true
      this.brandpreList = []
      getActivityBrandPre(this.current, this.pageSize, this.map).then(
        response => {
          this.current = response.currentPage
          this.prePage = response.prePage
          this.nextPage = response.nextPage
          response.datas.forEach(item => {
            let obj = {}
            Vue.set(obj, 'id', '')
            Vue.set(obj, 'brandpreId', item.id)
            Vue.set(obj, 'pid', '')
            Vue.set(obj, 'materialCode', item.materialCode)
            Vue.set(obj, 'materialIdentification', item.materialIdentification)
            Vue.set(obj, 'materialName', item.materialName)
            Vue.set(obj, 'price', item.price)
            Vue.set(obj, 'type', item.type)
            Vue.set(obj, 'notes', item.notes)
            Vue.set(obj, 'brandpreNumApp', 0)
            Vue.set(obj, 'brandpreNumApprove', 0)
            Vue.set(obj, 'brandpreMoneyamount', 0)
            this.brandpreList.push(obj)
          })
          this.loading = false
        }
      )
    }
  }
}
</script>

<style lang="less">
.active-table .ant-table-tbody > tr > td {
  padding: 8px 8px;
}
.brandre-modal .ant-modal-header {
  padding: 8px 8px;
  background-color: #4587f4;
}
.brandre-modal.ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.brandre-modal .ant-modal-close-x {
  width: 36px;
  height: 36px;
  line-height: 39px;
  color: #ffffff;
}
.brandre-modal .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.brandre-modal .ant-modal-body {
  padding: 8px;
}
.brandre-modal .ant-btn-primary,
.brandre-modal .ant-btn-primary:hover {
  color: #fff;
  background-color: #4587f4;
  border-color: #4587f4;
}
.brandre-content {
  padding: 0 8px;
  height: 58vh;
  overflow: auto;
}

.brandre-content .button {
  -webkit-border-radius: 6;
  -moz-border-radius: 6;
  border-radius: 6px;
  -webkit-box-shadow: 0px 1px 0px 0px #f0f7fa;
  -moz-box-shadow: 0px 1px 0px 0px #f0f7fa;
  box-shadow: 0px 1px 0px 0px #f0f7fa;
  color: #606266;
  font-size: 8px;
  padding: 4px 8px 4px 8px;
  border: solid #a0aec0 1px;
  text-decoration: none;
  &.choose-btn {
    padding: 4px 15px 4px 15px;
  }
  &:focus {
    outline: 0;
  }
}

.brandre-content .button:hover {
  border: solid #606266 1px;
  text-decoration: none;
}
</style>
