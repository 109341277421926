<template>
  <a-layout>
    <a-layout-sider
      v-model="collapsed"
      v-if="!showAvatar"
      :trigger="null"
      width="210px"
      class="sider fixed left-0 h-screen overflow-y-auto"
      style="background: #3d4864"
      collapsible
    >
      <div class="logo flex items-center h-16 px-4">
        <!-- <img src="@/assets/img_logo.png" class="mr-3" style="" /> -->
        <icon-font type="icon_hec-logo" class="text-5xl"></icon-font>
        <div v-if="!collapsed" class="truncate">
          <div class="text-base text-title">东阳光CRM平台</div>
          <!-- <p class="text-white">忠诚 规矩 规划 创新</p> -->
        </div>
      </div>
      <global-sider :inlineCollapsed="collapsed" />
    </a-layout-sider>
    <a-layout
      class="min-h-screen overflow-x-hidden"
      ref="rightDom"
      :style="{ marginLeft: mainLayoutLeft }"
    >
      <global-header
        :collapsed="collapsed"
        @toggle="collapsed = !collapsed"
        v-if="!showAvatar"
      />
      <div
        class="bread-crumb w-full bg-white"
        style="height: 40px; margin-top: 2px"
        v-if="!showAvatar"
      >
        <global-tag-views></global-tag-views>
      </div>
      <a-layout-content
        id="content"
        class="h-full relative"
        style="margin: 18px 18px 0 18px; padding: 0 32px 32px 0"
        :class="[$route.path === '/workplace' ? '' : 'bg-white']"
        :style="{
          padding: [
            ['/workplace', '/auditbill'].includes($route.path)
              ? '0'
              : '0 32px 32px 0'
          ]
        }"
      >
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
      </a-layout-content>
      <a-layout-footer class="py-2 text-xs text-center">
        东阳光客户关系管理系统 Copyright
        <a-icon type="copyright" />
        {{ new Date().getFullYear() }} by 集团信息部
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import store from '@/store'
import GlobalSider from '@/components/GlobalSider'
import GlobalHeader from '@/components/GlobalHeader'
import GlobalTagViews from '@/components/GlobalTagViews'
import { getMenus } from '@/api/user/menu-manage'
import { watermark } from 'watermark-dom'
export default {
  name: 'BasicLayout',
  components: {
    GlobalSider,
    GlobalHeader,
    GlobalTagViews
  },
  data() {
    return {
      collapsed: ['min', 'small', 'medium'].includes(
        this.$store.state.screen.size
      )
    }
  },
  computed: {
    screen() {
      return ['min', 'small', 'medium'].includes(this.$store.state.screen.size)
    },
    mainLayoutLeft() {
      return !store.getters['protal']
        ? this.collapsed
          ? '80px'
          : '210px'
        : '0px'
    },
    showAvatar() {
      return store.getters['protal']
    }
  },
  created() {
    getMenus()
  },

  mounted() {
    this.$store.commit(
      'rightContent/SET_DOM',
      this.$refs.rightDom ? this.$refs.rightDom.$el : null
    )

    const $tar = document.getElementById('content')
    const MutationObserver =
      window.MutationObserver ||
      window.webkitMutationObserver ||
      window.MozMutationObserver

    let recordHeight = 0
    let mutationObserver = new MutationObserver(function () {
      let height = window.getComputedStyle($tar).getPropertyValue('height')
      if (height === recordHeight) {
        return
      }
      recordHeight = height
      watermark.load({
        // eslint-disable-next-line camelcase
        watermark_txt: `${store.getters.userCode},${store.getters.username}`,
        // eslint-disable-next-line camelcase
        watermark_color: '#000', //水印字体颜色
        // eslint-disable-next-line camelcase
        watermark_fontsize: '14px', //水印字体大小
        // eslint-disable-next-line camelcase
        watermark_alpha: 0.08, //水印透明度，要求设置在大于等于0.005
        // eslint-disable-next-line camelcase
        watermark_angle: 45 //水印倾斜度数
      })
      // 之后更新外部容器等操作
    })

    mutationObserver.observe($tar, {
      childList: true, // 子节点的变动（新增、删除或者更改）
      attributes: true, // 属性的变动
      characterData: true, // 节点内容或节点文本的变动
      subtree: true // 是否将观察器应用于该节点的所有后代节点
    })
  },
  watch: {
    screen(nval) {
      this.collapsed = nval
    }
  }
}
</script>

<style lang="less" scoped>
.sider {
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  &::-webkit-scrollbar {
    display: none;
  }
}
.logo {
  color: rgba(255, 255, 255);
  background: #3d4864;
}
.text-title {
  height: 25px;
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  line-height: 25px;
}
</style>
