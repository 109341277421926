<template>
  <div class="border-b py-4 text-sm font-semibold">
    <slot>
      <a-icon :type="icon" />
      <span class="ml-2">{{ title }}</span>
    </slot>

    <slot name="title"></slot>
  </div>
</template>

<script>
export default {
  name: 'FormTitle',
  props: {
    // 可以使用antd的icon组件
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped></style>
