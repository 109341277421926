const OUTTIME_BILL = [
  {
    title: '单据信息',
    active: 'BASE',
    children: [
      { label: '编码', value: `code` },
      { label: '申请人', value: `creatorName` },
      { label: '申请人岗位', value: `postName` },
      { label: '申请状态', value: `flowStatus` },
      { label: '区域外时间类型', value: `type` },
      { label: '日期', value: `date` },
      { label: '时间', value: `time` },
      { label: '备注', value: `remarks` }
    ]
  }
]

export { OUTTIME_BILL }
