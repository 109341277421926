import moment from 'moment'

// 判断是否为空对象
function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

/**
 * 去掉对象中的参数值为""、null、undefined、[]和{}
 * @param {Object} obj
 */
function dealObjectValue(obj) {
  let param = {}
  if (obj === null || obj === undefined || obj === '') return param
  for (let key in obj) {
    if (obj[key] || obj[key] === 0 || obj[key] === false) {
      if (Array.isArray(obj[key]) && obj[key].length === 0) continue
      if (isEmptyObject(obj[key])) continue
      param[key] = obj[key]
    }
  }
  return param
}

/**
 * 查询（全部子菜单）
 * 将查询的条件的数据封装成[{column: "applicant", opt: "=", value: ""},...list]的格式
 * @param {Object} queryParams 查询的条件的数据
 */
export function queryCondition(queryParams) {
  const queryParamsKeys = Object.keys(queryParams)
  if (queryParamsKeys.length === 0) return []
  else {
    const paramsList = []
    const dealParams = dealObjectValue(queryParams)
    const dealQueryParamsKeys = Object.keys(dealParams)
    dealQueryParamsKeys.forEach(item => {
      if (Array.isArray(dealParams[item])) {
        if (moment.isMoment(dealParams[item][0])) {
          const dateList = []
          for (let subItem of dealParams[item]) {
            dateList.push(subItem.format('YYYY-MM-DD'))
          }
          paramsList.push({
            column: item,
            opt: 'between',
            value: dateList
          })
        } else if (item === 'hasContract') {
          paramsList.push({
            column: item,
            opt: 'in',
            value: dealParams[item].map(i => Boolean(i))
          })
        } else if (item === 'applyMoney') {
          // 申请金额的单独处理
          const [minNum, maxNum] = dealParams[item]
          //只有最小值时
          if (minNum && !maxNum) {
            paramsList.push({
              column: 'applyMoney',
              opt: '>=',
              value: minNum
            })
            // 只有最大值时
          } else if (!minNum && maxNum) {
            paramsList.push({
              column: 'applyMoney',
              opt: '<=',
              value: maxNum
            })
          } else {
            // 有最大值和最小值时
            if (Number(minNum) > Number(maxNum)) {
              const min = maxNum
              const max = minNum
              paramsList.push({
                column: 'applyMoney',
                opt: '>=',
                value: min
              })
              paramsList.push({
                column: 'applyMoney',
                opt: '<=',
                value: max
              })
            } else {
              paramsList.push({
                column: 'applyMoney',
                opt: '>=',
                value: minNum
              })
              paramsList.push({
                column: 'applyMoney',
                opt: '<=',
                value: maxNum
              })
            }
          }
        } else {
          paramsList.push({
            column: item,
            opt: 'in',
            value: dealParams[item]
          })
        }
      } else if (typeof dealParams[item] === 'boolean') {
        paramsList.push({
          column: item,
          opt: '=',
          value: dealParams[item]
        })
      } else {
        paramsList.push({
          column: item,
          opt: 'like',
          value: dealParams[item]
        })
      }
    })
    return paramsList
  }
}

/**
 * 销售管理(拜访 子菜单)
 * 将查询的条件的数据封装成[{column: "applicant", opt: "=", value: ""},...list]的格式
 * @param {Object} queryParams 查询的条件的数据
 */
export function queryVisitCondition(queryParams) {
  const queryParamsKeys = Object.keys(queryParams)
  if (queryParamsKeys.length === 0) return []
  else {
    const paramsList = []
    const dealParams = dealObjectValue(queryParams)
    const dealQueryParamsKeys = Object.keys(dealParams)
    dealQueryParamsKeys.forEach(item => {
      if (Array.isArray(dealParams[item])) {
        if (moment.isMoment(dealParams[item][0])) {
          const dateList = []
          for (let subItem of dealParams[item]) {
            dateList.push(subItem.format('YYYY-MM-DD'))
          }
          paramsList.push({
            column: item,
            opt: 'between',
            value: dateList
          })
        } else {
          paramsList.push({
            column: item,
            opt: 'in',
            value: dealParams[item]
          })
        }
      } else {
        if (['regionId', 'provinceId', 'localityId'].includes(item)) {
          paramsList.push({
            column: item,
            opt: '=',
            value: dealParams[item]
          })
        } else {
          paramsList.push({
            column: item,
            opt: 'like',
            value: dealParams[item]
          })
        }
      }
    })
    return paramsList
  }
}

/**
 * 月销售指标管理
 * 将查询的条件的数据封装成[{column: "applicant", opt: "=", value: ""},...list]的格式
 * @param {Object} queryParams 查询的条件的数据
 */
export function queryMonthSaleCondition(queryParams) {
  const queryParamsKeys = Object.keys(queryParams)
  if (queryParamsKeys.length === 0) return []
  else {
    const paramsList = []
    const dealParams = dealObjectValue(queryParams)
    const dealQueryParamsKeys = Object.keys(dealParams)
    dealQueryParamsKeys.forEach(item => {
      if (moment.isMoment(dealParams[item])) {
        if (item === 'billDate') {
          paramsList.push({
            column: item,
            opt: 'like',
            value: dealParams[item].format('YYYY-MM-DD')
          })
        } else {
          paramsList.push({
            column: item,
            opt: 'like',
            value: dealParams[item].format('YYYY-MM')
          })
        }
      } else {
        paramsList.push({
          column: item,
          opt: 'like',
          value: dealParams[item]
        })
      }
    })
    return paramsList
  }
}

/**
 * 制剂核算考核管理
 * 将查询的条件的数据封装成[{column: "applicant", opt: "=", value: ""},...list]的格式
 * @param {Object} queryParams 查询的条件的数据
 */
export function queryChsRefCondition(queryParams) {
  const queryParamsKeys = Object.keys(queryParams)
  if (queryParamsKeys.length === 0) return []
  else {
    const paramsList = []
    const dealParams = dealObjectValue(queryParams)
    const dealQueryParamsKeys = Object.keys(dealParams)
    dealQueryParamsKeys.forEach(item => {
      paramsList.push({
        column: item,
        opt: 'like',
        value: dealParams[item]
      })
    })
    return paramsList
  }
}

export function billStatus(status) {
  if (status === '1')
    return {
      status: '1',
      icon: 'icon_shenpizhongnew',
      color: 'pink',
      text: '待发'
    }
  if (status === '5')
    return {
      status: '5',
      icon: 'icon_shenpizhongnew',
      color: 'pink',
      text: '撤销'
    }
  if (status === '3')
    return {
      status: '3',
      icon: 'icon_daishenpi',
      color: 'blue',
      text: '待处理'
    }
  if (status === '4')
    return {
      status: '4',
      icon: 'icon_shenpizhong',
      color: 'orange',
      text: '处理中'
    }
  if (status === '15')
    return {
      status: '15',
      icon: 'icon_butongguo',
      color: 'red',
      text: '审批终止'
    }
  if (status === '98')
    return {
      status: '98',
      icon: 'icon_tuihui1',
      color: 'orange1',
      text: '回退'
    }
  if (status === '99')
    return {
      status: '99',
      icon: 'icon_zuofei1',
      color: 'red',
      text: '作废'
    }
  if (status === '96')
    return {
      status: '96',
      icon: 'icon_daishenpi',
      color: 'blue',
      text: '挂起'
    }
  if (status === '97')
    return {
      status: '97',
      icon: 'icon_daishenpi',
      color: 'blue',
      text: '暂存待办'
    }
  if (status === '0')
    return {
      status: '0',
      icon: 'icon_tongguo',
      color: 'green',
      text: '审批通过'
    }
}
