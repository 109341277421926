import request from '@/utils/request'
/*************** 消息模块 *****************/

/**
 * 分页查询所有消息(只查询未读的)
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getMessageList(pageindex, pagenum = 20) {
  return request({
    method: 'post',
    url: `msg/page/${pageindex}/${pagenum}`,
    data: [{ column: 'unread', opt: '=', value: 'true' }]
  })
}

/**
 *设置一条消息为已读
 * @param {String} id
 */
export function getIsRead(id) {
  return request({
    method: 'put',
    url: `msg/list/unread/${id}`
  })
}

/**
 * 设置全部消息为已读
 */
export function getAllIsRead() {
  return request({
    method: 'put',
    url: `msg/list/unread`
  })
}

/**
 * 分页查询所有消息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getAllMessageList(pageindex, pagenum = 20) {
  return request({
    method: 'post',
    url: `msg/page/${pageindex}/${pagenum}`
  })
}

/**
 * 分页查询所有消息(只查询已读的)
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 */
export function getUnReadMessageList(pageindex, pagenum = 20) {
  return request({
    method: 'post',
    url: `msg/page/${pageindex}/${pagenum}`,
    data: [{ column: 'unread', opt: '=', value: 'false' }]
  })
}
