import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import getters from './getters'
import user from './modules/user'
import activity from './modules/activity'
import tagsView from './modules/tagsView'
import screen from './modules/screen'
import uploadFile from './modules/uploadFile'
import notice from './modules/notice'
import anuce from './modules/anuce'
import socket from './modules/socket'
import doc from './modules/doc'
import partner from './modules/partner'
import nonacademic from './modules/nonacademic'
import rightContent from './modules/rightContent'
import { session } from '@/utils/storage'

Vue.use(Vuex)

const persistedState = createPersistedState({
  key: 'sync',
  paths: ['user.user'],
  storage: {
    getItem: key => session.get(key.toUpperCase()),
    setItem: (key, value) => session.set(key.toUpperCase(), value),
    removeItem: key => session.remove(key.toUpperCase())
  }
})

export default new Vuex.Store({
  plugins: [persistedState],
  getters,
  modules: {
    user,
    activity,
    tagsView,
    uploadFile,
    screen,
    notice,
    anuce,
    socket,
    doc,
    partner,
    nonacademic,
    rightContent
  }
})
