import RouteView from '@/layouts/RouteView'
export default {
  path: 'exception',
  component: RouteView,
  redirect: '404',
  name: 'exception',
  meta: { title: '错误信息' },
  children: [
    {
      path: '404',
      name: '404',
      component: () =>
        import(
          /* webpackChunkName: 'exception' */ '@/views/exceptions/Developing'
        ),
      meta: { title: '404' }
    }
  ]
}
