var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{staticClass:"speaker-modal",attrs:{"title":"选择预邀的专家/讲者","width":"1000px","visible":_vm.visible,"centered":true,"keyboard":false},on:{"cancel":_vm.handleCancel,"ok":_vm.handleOk}},[_c('div',{staticClass:"speaker-content w-full"},[_c('div',{staticClass:"w-full my-3"},[_c('button',{staticClass:"button mr-2",attrs:{"disabled":!_vm.prePage},on:{"click":_vm.prev}},[_vm._v(" 上一页 ")]),_c('button',{staticClass:"button",attrs:{"disabled":!_vm.nextPage},on:{"click":_vm.next}},[_vm._v(" 下一页 ")]),_c('div',{staticClass:"search float-right"},[_c('a-input-search',{staticClass:"w-72",attrs:{"placeholder":"按名称搜索","enterButton":"搜索"},on:{"search":_vm.onSearch}})],1)]),_c('a-table',{staticClass:"active-table",attrs:{"rowKey":"speakerId","pagination":false,"columns":_vm.columns,"dataSource":_vm.speakerList,"loading":_vm.loading,"rowSelection":{
          onChange: _vm.onSelectChange,
          type: 'radio',
          selectedRowKeys: _vm.selectedRowKeys
        },"rowClassName":function (record, index) {
            return index % 2 === 0 ? 'trBgClass' : ''
          },"customRow":function (record) {
            return {
              on: {
                dblclick: function () {
                  _vm.handleClick(record)
                },
                click: function () {
                  _vm.sclick(record)
                }
              }
            }
          }},on:{"change":_vm.getActivetySpeaker},scopedSlots:_vm._u([{key:"filterDropdown",fn:function(ref){
          var setSelectedKeys = ref.setSelectedKeys;
          var selectedKeys = ref.selectedKeys;
          var confirm = ref.confirm;
          var clearFilters = ref.clearFilters;
          var column = ref.column;
return _c('div',{staticClass:"custom-filter-dropdown"},[_c('a-input',{directives:[{name:"ant-ref",rawName:"v-ant-ref",value:(function (c) { return (_vm.searchInput = c); }),expression:"c => (searchInput = c)"}],staticStyle:{"width":"180px","margin-bottom":"8px","display":"block"},attrs:{"placeholder":"查询","value":selectedKeys[0]},on:{"change":function (e) { return setSelectedKeys(e.target.value ? [e.target.value] : []); },"pressEnter":function () { return _vm.handleSearch(selectedKeys, confirm, column); }}}),_c('a-button',{staticStyle:{"width":"80px","margin-right":"10px"},attrs:{"type":"primary","icon":"search"},on:{"click":function () { return _vm.handleSearch(selectedKeys, confirm, column); }}},[_vm._v(" 查询 ")]),_c('a-button',{staticStyle:{"width":"80px"},on:{"click":function () { return _vm.handleReset(clearFilters, column); }}},[_vm._v(" 重置 ")])],1)}},{key:"filterIcon",fn:function(filtered){return _c('a-icon',{style:({ color: filtered ? '#108ee9' : undefined }),attrs:{"type":"search"}})}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }