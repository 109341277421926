export default {
  namespaced: true,
  state: {
    brandpreInfoDTOs: {}
  },
  mutations: {
    SET_BRANDPREINFODTOS(state, value) {
      state.brandpreInfoDTOs = value
    }
  }
}
