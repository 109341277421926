// 发票替换字段
export const keyMap = {
  // eslint-disable-next-line prettier/prettier
  'buyer_tax_id': 'buyerTaxId',
  // eslint-disable-next-line prettier/prettier
  'check_code': 'checkCode',
  // eslint-disable-next-line prettier/prettier
  'item_names': 'itemNames',
  // eslint-disable-next-line prettier/prettier
  'machine_code': 'machineCode',
  // eslint-disable-next-line prettier/prettier
  'pk_file': 'pkFile',
  // eslint-disable-next-line prettier/prettier
  'pk_group': 'pkGroup',
  // eslint-disable-next-line prettier/prettier
  'pretax_amount': 'pretaxAmount',
  // eslint-disable-next-line prettier/prettier
  'seller_tax_id': 'sellerTaxId',
  // eslint-disable-next-line prettier/prettier
  'service_name': 'serviceName',
  // eslint-disable-next-line prettier/prettier
  'train_number': 'trainNumber',
  // eslint-disable-next-line prettier/prettier
  'class_name': 'className',
  // eslint-disable-next-line prettier/prettier
  'flight_number': 'flightNumber',
}

// key替换成驼峰式
export function convertKey(array) {
  for (let i = 0; i < array.length; i++) {
    let obj = array[i]
    for (let key in obj) {
      let newKey = keyMap[key]
      if (newKey) {
        obj[newKey] = obj[key]
        delete obj[key]
      }
    }
  }

  return array
}

/**
 * 是否重复票据对应关系
 */
export const DUP_OPTIONS = [
  { value: 0, label: '否', color: 'text-green-600' },
  { value: 1, label: '是', theme: 'danger', color: 'text-red-600' },
  { value: 2, label: '疑似重复', theme: 'warning', color: 'text-orange-600' }
]

/**
 * 来源方式对应关系
 */
export const SOURCE_OPTIONS = [
  { value: 0, label: '自动识别', color: 'text-green-500' },
  { value: 1, label: '手记一笔', color: 'text-blue-500' },
  { value: 2, label: '手动修改', color: 'text-orange-500' }
]
