<template>
  <div class="fee-info-table" v-if="isXSBX || speakers">
    <div class="app-message mt-3">
      <a-collapse defaultActiveKey="1" :bordered="false">
        <template v-slot:expandIcon="props">
          <a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          :header="isXSBX ? '实际到场的讲者信息' : '讲者信息'"
          key="1"
          class="customStyle"
        >
          <div style="margin-left: 24px">
            <table cellspacing="0">
              <tr class="h-8" style="background-color: #f9f9f9">
                <th>讲者编号</th>
                <th>名称</th>
                <th>单位</th>
                <th>级别</th>
                <th class="w-2/14">
                  咨询费
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <th>
                  课题名称
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <th class="w-2/14">
                  讲课时长/分钟
                  <sup style="color: red; top: -0.3em; font-size: 100%">*</sup>
                </th>
                <div class="icon-group" v-if="!isRead && isXSBX">
                  <a-icon type="plus-circle" class="icon" @click="addSpeaker" />
                  <a-icon
                    type="minus-circle"
                    class="icon mt-2"
                    @click="removeSpeaker(index)"
                  />
                </div>
              </tr>
              <tr
                v-for="(item, index) in speakerInfo.speakers"
                :key="`${index}`"
                :class="[index % 2 === 0 ? 'trBgClass' : '']"
              >
                <td>
                  <speaker-select
                    :code="item.code"
                    :speakers="speakerInfo.speakers"
                    :status="isRead"
                    @change="changeSpeaker(item, $event)"
                  ></speaker-select>
                </td>
                <td>{{ item.name }}</td>
                <td>
                  <ellipsis :length="getTextLength">
                    {{ item.departmentName }}
                  </ellipsis>
                </td>
                <td>{{ item.speakerLevelName }}</td>
                <td>
                  <span v-if="isRead || !item.speakerId">
                    {{ item.appConsultingFee }}
                  </span>
                  <a-input
                    v-else
                    v-model="item.appConsultingFee"
                    @change="updateItem"
                    oninput="
            this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''
          "
                  ></a-input>
                </td>
                <td>
                  <ellipsis
                    :length="getTextLength"
                    v-if="isRead || !item.speakerId"
                  >
                    {{ item.topicname }}
                  </ellipsis>
                  <a-input
                    v-else
                    v-model="item.topicname"
                    @change="updateItem"
                  ></a-input>
                </td>
                <td>
                  <span v-if="isRead || !item.speakerId">
                    {{ item.lengthoflecture }}
                  </span>
                  <a-input
                    v-else
                    v-model="item.lengthoflecture"
                    @change="updateItem"
                    oninput="value=value.replace(/[^\d]/g,'')"
                  ></a-input>
                </td>
                <div v-if="!isRead && isXSBX"></div>
              </tr>
            </table>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import Ellipsis from '@/components/Ellipsis'
import SpeakerSelect from './SpeakerSelect'
import { isEmpty } from '@/utils/util'

export default {
  name: 'SpeakerInfo', //讲者信息
  components: {
    SpeakerSelect,
    Ellipsis
  },
  props: {
    speakerInfo: {
      type: Object,
      required: true
    },
    status: Boolean,
    isXSBX: Boolean,
    speakerNum: [String, Number]
  },
  data() {
    return {
      speaker: {
        id: '',
        pid: '',
        speakerId: '',
        code: '',
        name: '',
        departmentName: '',
        speakerLevelName: '',
        appConsultingFee: '',
        topicname: '',
        lengthoflecture: ''
      }
    }
  },
  computed: {
    isRead() {
      return this.status
    },
    speakers() {
      return Number(this.speakerNum)
    },
    getTextLength() {
      const length = this.$store.state.screen.length
      const size = this.$store.state.screen.size
      const list = ['min', 'small', 'medium']
      if (list.includes(size)) {
        return 10
      }
      return length
    }
  },
  watch: {
    // speakerInfo(nval) {
    //   if (nval.speakers.length === 0 || nval.speakers === null) {
    //     const speaker = this.copy(this.speaker)
    //     this.speakerInfo.speakers.push(speaker)
    //   }
    // }
    speakerNum(nval, oval) {
      if (!nval) nval = 0
      if (!oval) oval = 0
      if (!nval) {
        this.speakerInfo.speakers = []
        this.submit()
      }
      if (Number(nval) > Number(oval)) {
        const length = nval - this.speakerInfo.speakers.length
        for (let item = 0; item < length; item++) {
          const speaker = this.copy(this.speaker)
          this.speakerInfo.speakers.push(speaker)
          this.submit()
        }
      }
      if (Number(nval) < Number(oval)) {
        const length = this.speakerInfo.speakers.length - nval
        for (let item = 0; item < length; item++) {
          this.speakerInfo.speakers.pop()
          this.submit()
        }
      }
    }
  },
  created() {
    if (this.isXSBX && this.speakerInfo.speakers) {
      if (
        this.speakerInfo.speakers.length === 0 ||
        this.speakerInfo.speakers === null
      ) {
        const speaker = this.copy(this.speaker)
        this.speakerInfo.speakers.push(speaker)
      }
    }
  },
  methods: {
    submit() {
      this.speakerInfo.budgetConsultTotal = 0
      this.speakerInfo.speakers.forEach(item => {
        this.speakerInfo.budgetConsultTotal = this.$np.plus(
          Number(this.speakerInfo.budgetConsultTotal),
          Number(item.appConsultingFee)
        )
      })
      this.$emit('update:speakerInfo', this.speakerInfo)
    },

    updateItem() {
      this.$emit('updateItem')
      this.submit()
    },

    // 添加一条讲者信息
    addSpeaker() {
      const speaker = this.copy(this.speaker)
      this.speakerInfo.speakers.push(speaker)
    },

    /**
     * 删除一条讲者信息
     * @param {Integer} index 讲者列表的索引位置
     */
    removeSpeaker(index) {
      this.speakerInfo.speakers.splice(index, 1)
      this.showTable = false
      this.$nextTick(() => {
        this.showTable = true
      })
      this.updateItem()
    },

    // 讲者信息改变
    changeSpeaker(item, value) {
      item.pid = value.pid
      item.id = value.id
      item.speakerId = value.speakerId
      item.code = value.code
      item.name = value.name
      item.departmentName = value.departmentName
      item.speakerLevelName = value.speakerLevelName
      item.appConsultingFee = value.consultingFee
      this.updateItem()
    },

    /**
     * 深拷贝data中speaker对象
     * @param {Object} speaker data中的speaker对象
     */
    copy(speaker) {
      return JSON.parse(JSON.stringify(speaker))
    },

    isEmpty
  }
}
</script>

<style lang="less" scoped>
.fee-info-table table {
  width: 100%;
}
.fee-info-table table th {
  text-align: left;
  font-size: 14px;

  font-weight: 600;
  color: #999999;
  padding: 16px;
}
.fee-info-table table tr {
  padding: 12px;
  border-bottom: 1px solid rgba(233, 233, 233, 1);
}
.fee-info-table table tr td {
  text-align: left;
  padding: 4px 16px;
}
.icon-group {
  position: absolute;
  right: 0px;
  display: flex;
  height: 3rem;
  flex-direction: column;
  justify-content: space-around;
  opacity: 1;
  justify-content: space-between;
}
.icon {
  cursor: pointer;
  &:hover {
    color: #409eff;
  }
}

/deep/.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 0px;
}

/deep/.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 14px;

  font-weight: 600;
  color: rgba(102, 102, 102, 1);
}

/deep/.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  line-height: 0;
  margin-top: 1px;
  color: #4587f4;
}

/deep/.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.customStyle {
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0;
  overflow: hidden;
}
</style>
