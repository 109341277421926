var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-4",staticStyle:{"padding-left":"32px"}},[_c('a-form',{attrs:{"size":"small","laba-width":"90px","laba-position":"right","layout":"vertical"}},[_c('a-collapse',{attrs:{"bordered":false},scopedSlots:_vm._u([{key:"expandIcon",fn:function(props){return [_c('a-icon',{attrs:{"type":"caret-right","rotate":props.isActive ? 90 : 0}})]}}]),model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_vm._l((_vm.baseMsg),function(ref){
var title = ref.title;
var active = ref.active;
var children = ref.children;
return _c('a-collapse-panel',{key:active,staticClass:"customStyle",attrs:{"header":title}},_vm._l((children),function(ref){
var label = ref.label;
var value = ref.value;
return _c('a-col',{key:label,attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":label,"prop":value,"label-col":_vm.itemlay.labelCol,"wrapper-col":_vm.itemlay.wrapperCol}},[(value === 'gender')?_c('a-input',{attrs:{"disabled":true,"value":_vm.formData[value]
                    ? _vm.formData[value] === 'W'
                      ? '女'
                      : '男'
                    : '无'}}):(value === 'appStatus')?_c('a-input',{attrs:{"disabled":true,"value":_vm.getStatusText(_vm.formData[value])}}):(value === 'qualificationInfo')?_c('a',{staticStyle:{"color":"#4587f5"},attrs:{"href":_vm.formData[value],"target":"_blank","title":_vm.formData[value]}},[_vm._v(" "+_vm._s(_vm.formData[value])+" ")]):_c('a-input',{attrs:{"disabled":true},model:{value:(_vm.formData[value]),callback:function ($$v) {_vm.$set(_vm.formData, value, $$v)},expression:"formData[value]"}})],1)],1)}),1)}),_c('a-collapse-panel',{key:"FILE",staticClass:"customStyle",staticStyle:{"margin-bottom":"36px"},attrs:{"header":"附件信息"}},[_c('div',{staticClass:"form-item form-upload"},[_c('span',{staticClass:"form-label",staticStyle:{"color":"rgba(0, 0, 0, 0.85)"}},[_vm._v(" 资质附件: ")]),(_vm.formData.enclosures && _vm.formData.enclosures.length)?_c('span',[_c('icon-font',{staticClass:"text-xl ml-4",attrs:{"type":_vm.formData.enclosures.length
                    ? 'icon_wenjianjia2'
                    : 'icon_wenjianjia1'},on:{"click":function($event){return _vm.preview(_vm.formData.enclosures, 'speaker')}}})],1):_c('span',{staticClass:"text-blue-400"},[_vm._v("无上传的附件")])])])],2),(_vm.formData.flowInfoDTO)?_c('process',{attrs:{"summaryid":_vm.formData.flowInfoDTO.summaryid,"nextapproveman":_vm.formData.flowInfoDTO.nextapproveman,"curApprover":_vm.formData.flowInfoDTO.curApprover}}):_vm._e(),_c('comment',{attrs:{"billId":_vm.formData.id}})],1)],1),(_vm.showPreview)?_c('preview-modal',{attrs:{"uploadName":_vm.uploadName,"previewFileListInfo":_vm.previewFileListInfo},on:{"close":function($event){_vm.showPreview = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }