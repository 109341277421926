import request from '@/utils/request'

/**
 * 分页查询清算盘存信息
 * @param {Integer} pageindex
 * @param {Integer} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getHeaderClearInvList(pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `chs/clearInv/head/${pageindex}/${pagenum}`,
    data: conditions
  })
}

/**
 * 根据表头id获取清算盘存表体列表(分页)
 * @param {String} id
 * @param {Integer} pageindex
 * @param {Interger} pagenum
 * @param {Array} conditions
 * @returns
 */
export function getBodyClearInvList(id, pageindex, pagenum, conditions) {
  return request({
    method: 'post',
    url: `chs/clearInv/body/${pageindex}/${pagenum}`,
    params: { hid: id },
    data: conditions
  })
}

/**
 * 删除清算盘存
 * @param {String} id
 * @returns
 */
export function delClearInv(id) {
  return request({
    method: 'post',
    url: `chs/clearInv/del/${id}`
  })
}

/**
 * 根据ID来查询清算盘存信息
 * @param {String} id
 * @returns
 */
export function getClearInv(id) {
  return request({
    method: 'get',
    url: `chs/clearInv/head/${id}`
  })
}

/**
 * 保存清算盘存
 * @param {Object} data
 * @returns
 */
export function saveClearInv(data) {
  return request({
    method: 'post',
    url: `chs/clearInv/save`,
    data
  })
}

/**
 * 更新清算盘存
 * @param {Object} data
 * @returns
 */
export function updateClearInv(data) {
  return request({
    method: 'post',
    url: `chs/clearInv/update`,
    data
  })
}

/**
 * 提交清算盘存
 * @param {Object} data
 * @returns
 */
export function submitClearInv(data) {
  return request({
    method: 'put',
    url: `oaflow/submitNcClearInv`,
    data
  })
}

/**
 * 取消审批
 * @param {String} id
 * @returns
 */
export function repealClearInv(id) {
  return request({
    method: 'post',
    url: `chs/clearInv/unapprove/${id}`
  })
}
