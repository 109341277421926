<template>
  <div>
    <a-modal
      v-bind="$attrs"
      v-model="visible"
      :mask="false"
      :closable="closable"
      :maskClosable="false"
      class="post-modal clearfix"
      @cancel="$emit('close')"
    >
      <div slot="footer">
        <a-button
          type="primary"
          style="background-color: #4587f4; color: #ffffff"
          @click="$emit('handldClick')"
        >
          确定
        </a-button>
        <a-button @click="$emit('close')">取消</a-button>
      </div>
      <slot></slot>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    closable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: true
    }
  }
}
</script>

<style lang="less">
.post-modal {
  top: 20vh !important ;
}
.post-modal .ant-modal-header {
  padding: 8px 8px;
  background-color: #4587f4;
}
.post-modal .ant-modal-content {
  border-radius: 4px 4px 0 0;
  border: 1px solid #4587f4;
}
.post-modal .ant-modal-close-x {
  width: 36px;
  height: 36px;
  line-height: 39px;
  color: #ffffff;
}
.post-modal .ant-modal-header .ant-modal-title {
  color: #ffffff;
  font-size: 14px;
}
.post-modal .ant-modal-body {
  padding: 16px;
}
.post-modal .ant-modal-footer {
  border-top: none;
  text-align: center;
}
</style>
