<template>
  <div class="text-xs" style="padding-left: 32px">
    <RegisterHouseForm
      :type="type"
      :pid="pid"
      @status="nowStatus($event)"
    ></RegisterHouseForm>
  </div>
</template>

<script>
import RegisterHouseForm from '@/views/house/registerHouse/RegisterHouseForm'

export default {
  name: 'REGISTER_HOUSE_BILL',
  components: {
    RegisterHouseForm
  },
  props: {
    type: String,
    pid: String
  },

  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
