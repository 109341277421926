<template>
  <div class="text-xs">
    <outercomp :pid="pid" @status="nowStatus($event)"></outercomp>
  </div>
</template>

<script>
import Outercomp from './outercomp/OUTERCOMP'

export default {
  name: 'OUTERCOMPBill',
  components: {
    Outercomp
  },
  props: {
    pid: String
  },
  data() {
    return {
      status: ''
    }
  },
  methods: {
    nowStatus(status) {
      this.$emit('status', status)
    }
  }
}
</script>

<style lang="less" scoped></style>
