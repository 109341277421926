var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"title":((_vm.waitWork.subject) + "--申请人：" + (_vm.waitWork.sendname)),"dialogClass":"work-model clearfix","maskClosable":false,"width":"90%","footer":null},on:{"cancel":_vm.handleClose},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"content-header"},[_c('div',[_c('span',{staticClass:"bill-name"},[_vm._v(" "+_vm._s(_vm.getType(_vm.waitWorkForm.billtype, _vm.waitWorkForm.unbindApp))+" ")])]),_c('div',{staticClass:"flex justify-center content-center"},[_c('icon-font',{staticClass:"text-2xl",attrs:{"type":_vm.icon}}),_c('span',{staticClass:"bill-status",class:_vm.color},[_vm._v(_vm._s(_vm.text))])],1)]),_c('div',{ref:"dom",staticClass:"content-left content-body overflow-auto",style:({
        width:
          _vm.getType(_vm.waitWorkForm.billtype, _vm.waitWorkForm.unbindApp) ===
          '解绑申请'
            ? '100%'
            : ''
      })},[_c('content-left',{ref:"cLeft",attrs:{"pk":_vm.waitWorkForm.pk,"billtype":_vm.waitWorkForm.billtype,"unbindApp":_vm.waitWorkForm.unbindApp,"appDate":_vm.waitWorkForm.appDate,"summaryId":_vm.waitWorkForm.objectId},on:{"status":function($event){return _vm.nowStatus($event)},"close":_vm.close}})],1),(
        _vm.getType(_vm.waitWorkForm.billtype, _vm.waitWorkForm.unbindApp) !== '解绑申请'
      )?_c('div',[_c('div',{staticClass:"content-right content-body"},[_c('content-right',{ref:"contentRight",attrs:{"billtype":_vm.waitWorkForm.billtype,"pid":_vm.waitWorkForm.pk,"chooseValue":_vm.chooseValue,"tabKey":_vm.tabKey,"status":_vm.status},on:{"optype":_vm.changeOptype,"note":_vm.changeNote,"speakerLevel":_vm.changeSpeakerLevel,"budgetApproveTotal":_vm.changeBudgetApproveTotal,"approvedSum":_vm.changeApprovedSum,"reimAmountApprove":function($event){return _vm.getReimAmountApprove($event)},"submit":_vm.submit}})],1)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }