<template>
  <a-form class="pl-4" labelAlign="left">
    <slot></slot>
  </a-form>
</template>

<script>
export default {
  name: 'BaseForm'
}
</script>

<style lang="less" scoped></style>
