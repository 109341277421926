import request from '@/utils/request'

/**
 * 获取用户列表
 */
export function getUserList() {
  return request({
    method: 'get',
    url: 'user/pageAll',
    customConfig: {
      withToken: true
    }
  })
}
/**
 * 获取单个用户
 * @param {String} uid 用户ID
 */
export function getUserOne(uid) {
  return request({
    method: 'get',
    url: 'user/getOne',
    params: { uid },
    customConfig: {
      withToken: true
    }
  })
}
/**
 * 添加用户
 * @param {Boolean} isenable
 * @param {String} nickname
 * @param {String} salt
 * @param {String} uid
 * @param {String} userName
 * @param {String} userCode
 * @param {String} password
 * @param {String} vnote
 */
export function addUser(
  isenable,
  nickname,
  salt,
  uid,
  userName,
  userCode,
  password,
  vnote
) {
  const data = {
    isenable,
    nickname,
    salt,
    uid,
    userName,
    userCode,
    password,
    vnote
  }
  return request({
    method: 'get',
    url: 'user/addUser',
    data: data,
    customConfig: {
      withToken: true
    }
  })
}
/**
 * 修改用户
 * @param {Boolean} isenable
 * @param {String}  nickname
 * @param {String}  salt
 * @param {String}  uid
 * @param {String}  userName
 * @param {String}  userCode
 * @param {String}  password
 * @param {String}  vnote
 */
export function putUserUdp(
  isenable,
  nickname,
  salt,
  uid,
  userName,
  userCode,
  password,
  vnote
) {
  const data = {
    isenable,
    nickname,
    salt,
    uid,
    userName,
    userCode,
    password,
    vnote
  }
  return request({
    method: 'put',
    url: 'user/upd',
    data: data,
    customConfig: {
      withToken: true
    }
  })
}
/**
 * 修改密码
 * 用户id，原密码，新密码
 * @param {String} uid 用户id
 * @param {String} password 原密码
 * @param {String} newPassword 新密码
 */
export function changePwd(params) {
  return request({
    method: 'put',
    url: 'user/chanagePwd',
    params: {
      ...params
    },
    customConfig: {
      widthToken: true
    }
  })
}
/**
 * 删除用户
 * @param {String} userId 用户ID
 */
export function putDelUser(userId) {
  return request({
    method: 'put',
    url: 'user/deluser',
    params: userId
  })
}
