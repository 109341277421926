import Vue from 'vue'
import './antd'
import './plugin'
import './vxe'
import dayjs from '@/utils/dayjs'
import NP from '@/utils/number'
import websocket from 'vue-native-websocket'

const webSocketUrl =
  process.env.VUE_APP_MODE.toLowerCase() === 'production'
    ? 'wss://crm.dyg.com.cn'
    : 'wss://crmtest.dyg.com.cn:7090'

Vue.prototype.$date = dayjs
Vue.prototype.$np = NP

Vue.use(websocket, webSocketUrl, {
  connectManually: true
})
