var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center overflow-hidden"},[_c('div',{staticClass:"mb-1 mt-4 text-base"},[_vm._v(" "+_vm._s(_vm.form.outerCompanyName)+" ")]),_c('div',{staticClass:"my-4 text-base"},[_vm._v("发货申请单")]),_c('a-row',{staticClass:"px-12"},_vm._l((_vm.columns),function(ref){
var title = ref.title;
var prop = ref.prop;
var value = ref.value;
var span = ref.span;
return _c('a-col',{key:prop,staticClass:"flex",attrs:{"span":span}},[(title)?_c('label',[_vm._v(_vm._s(title))]):_vm._e(),_c('span',{staticClass:"flex-1",class:[title ? 'justify-center' : 'text-left']},[_vm._v(" "+_vm._s(prop ? prop === 'vbilldate' ? _vm.$date(_vm.form[prop]).format('YYYY年MM月DD日') : _vm.form[prop] : value())+" ")])])}),1),_c('div',{staticClass:"px-12 mt-2"},[_c('table',{staticClass:"w-full"},[_c('tr',_vm._l((_vm.thTitles),function(tiele){return _c('th',{key:tiele},[_vm._v(_vm._s(tiele))])}),0),_vm._l((_vm.results),function(item){return _c('tr',{key:item.bid},[_c('td',[_vm._v(_vm._s(item.idx))]),_c('td',[_vm._v(_vm._s(item.materialName))]),_c('td',[_vm._v(_vm._s(item.materialSpec))]),_c('td',[_vm._v(_vm._s(item.unit))]),_c('td',[_vm._v(_vm._s(item.num))]),_c('td',[_vm._v(_vm._s(item.unitPrice))]),_c('td',[_vm._v(_vm._s(item.amount))]),(item.idx === 1)?_c('td',{attrs:{"rowspan":("" + (_vm.results.length))}},[_vm._v(" "+_vm._s(_vm.form.note)+" ")]):_vm._e()])}),_c('tr',[_c('td',[_vm._v("合计")]),_c('td',{attrs:{"colspan":"4"}},[_vm._v(_vm._s(_vm.upperAmountTotal))]),_c('td'),_c('td',[_vm._v(_vm._s(_vm.amountTotal))]),_c('td')])],2),_c('div',{staticClass:"my-16 text-left"},[_vm._v("收货资料")]),_c('a-row',{staticClass:"text-left",attrs:{"gutter":[0, 48]}},_vm._l((_vm.infos),function(ref,index){
var title = ref.title;
var text = ref.text;
var prop = ref.prop;
var value = ref.value;
return _c('a-col',{key:index,attrs:{"span":index % 2 ? 8 : 16}},[(title)?_c('div',{staticClass:"flex justify-between"},[_c('label',{staticClass:"inline-block",style:({ width: index % 2 ? '96px' : '160px' })},[_c('div',{style:({
                width: index % 2 ? '54px' : '76px',
                textAlignLast: 'justify',
                display: 'inline-block'
              })},[_vm._v(" "+_vm._s(title)+" ")])]),_c('div',{staticClass:"flex-1 border-b border-black relative"},[_c('div',{staticClass:"absolute bottom-0 w-full text-center"},[_vm._v(" "+_vm._s(prop ? _vm.form[prop] : value ? value() : '')+" ")])])]):_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(text))])])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }